import React from 'react'
import Select from 'react-select'
import clsx from 'clsx'

interface Option {
  value: string
  label: string
}

interface SearchableSelectProps {
  options: Option[]
  value: string
  onChange: (name: string, value: any) => void
  name: string
  touched?: boolean
  errors?: string
  placeholder?: string
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  options,
  value,
  onChange,
  name,
  touched,
  errors,
  placeholder = 'Select...',
}) => {
  return (
    <div>
      {options.length > 0 ? (
        <Select
          name={name}
          value={options.find((option) => option.value === value)}
          onChange={(selectedOption: Option | null) => {
            if (selectedOption) {
              onChange(selectedOption.label, selectedOption.value)
            } else {
              onChange('', null) // handle the case when the selection is cleared
            }
          }}
          isClearable={true}
          options={options}
          placeholder={placeholder}
          components={{DropdownIndicator: null, IndicatorSeparator: null}}
          classNamePrefix='react-select'
          className={clsx(
            'react-select-container',
            {'is-invalid': touched && errors},
            {'is-valid': touched && !errors}
          )}
        />
      ) : (
        <span className='indicator-progress' style={{display: 'block'}}>
          Please wait...{' '}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
      {touched && errors && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{errors}</div>
        </div>
      )}
    </div>
  )
}

export default SearchableSelect
