import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {AktivnePaleteModel, ParamsModelAktivnePalete} from '../models/AktivnePaleteModel'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/aktivnePalete`
const PALETA_PDF_API = `${API_URL}/paletaPDF`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: `Bearer ${auth?.access_token ?? ''}`}

export async function getAktivniPalete(params: ParamsModelAktivnePalete) {
  return await axios.get<Array<AktivnePaleteModel>>(API, {headers: headers, params: params})
}
export async function getPaletaPdf(params: ParamsModelAktivnePalete) {
  return await axios.get<Array<AktivnePaleteModel>>(PALETA_PDF_API, {
    headers: headers,
    params: params,
  })
}
