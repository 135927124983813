/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, forwardRef, useImperativeHandle, useRef} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../../_websoft/helpers'
import {ErrorMessage, Field, useFormik} from 'formik'
import SmjenePanelaDropdown from '../../../paneli/components/SmjenePanelaDropdown/SmjenePanelaDropdown'
import MasinePanelaDropdown from '../../../paneli/components/MasinePanelaDropdown/MasinePanelaDropdown'
import moment from 'moment'
import * as Yup from 'yup'
import {getPanelNalogDetalji} from '../../../../api/thermoPrometRolne'
import notifyToast from '../../../../components/Toast/Toast'

interface Props {
    setData: Function
}

export type Ref = any

const Step1 = forwardRef<Ref, Props>(({setData}, ref) => {
    const initialValues = {
        brojNalog: undefined,
        godina: moment().format('YY'),
        stavka: undefined,
        Smjena: undefined,
        Masina: undefined,
    }
    const validationSchema = Yup.object({
        brojNalog: Yup.string().required('required'),
        godina: Yup.string().required('required'),
        stavka: Yup.string().required('required'),
        Smjena: Yup.string().required('required'),
        Masina: Yup.string().required('required'),
    })

    useImperativeHandle(ref, () => ({
        handleContinue,
    }))

    const handleContinue = () => {
        formik.submitForm()
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            getPanelNalogDetalji({nalog: formik.values.brojNalog, godina: formik.values.godina, stavka: formik.values.stavka})
                .then((response) => {
                    if (response.data['data']) {
                        const data = response.data['data']
                        setData(data, formik.values)
                    }
                })
                .catch((error) => notifyToast(false, error.response.data.message))
        },
    })

    return (
        <div className='w-100'>
            <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                    UPIŠI BROJ NALOGA
                    <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip' title=' '></i>
                </h2>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className='fv-row'>
                    <div className='row'>
                        <div className='col'>
                            <img src={toAbsoluteUrl('/media/logos/panel.png')} alt='' width='200'/>
                        </div>
                        <div className='col'>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Broj Naloga'
                                {...formik.getFieldProps('brojNalog')}
                            />
                            {formik.touched.brojNalog && formik.errors.brojNalog && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.brojNalog}</div>
                                </div>
                            )}
                        </div>
                        <div className='col'>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Godina'
                                {...formik.getFieldProps('godina')}
                            />
                            {formik.touched.godina && formik.errors.godina && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.godina}</div>
                                </div>
                            )}
                        </div>
                        <div className='col'>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Stavka'
                                {...formik.getFieldProps('stavka')}
                            />
                            {formik.touched.stavka && formik.errors.stavka && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.stavka}</div>
                                </div>
                            )}
                        </div>
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='accountType'/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col offset-lg-3'>
                            <SmjenePanelaDropdown
                                onChange={(name, value) => formik.setFieldValue('Smjena', value)}
                            />
                            {formik.touched.Smjena && formik.errors.Smjena && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.Smjena}</div>
                                </div>
                            )}
                        </div>
                        <div className='col'>
                            <MasinePanelaDropdown
                                onChange={(name, value) => formik.setFieldValue('Masina', value)}
                            />
                            {formik.touched.Masina && formik.errors.Masina && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.Masina}</div>
                                </div>
                            )}
                        </div>
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='accountType'/>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
})

export {Step1}
