import React, {useState} from 'react'
import {KTSVG} from '../../../_websoft/helpers'
import {ParamsModelArtikli} from '../../models/ArtikliModel'
import KategorijaAritkliDropdown from '../KategorijaArtikliDropdown/KategorijaArtikliDropdown'
import ArtikliDropdown from '../ArtikliDropdown/ArtikliDropdown'
import RoditeljiDropdown from '../RoditeljiDropdown/RoditeljiDropdown'

const ArtikliFilteri = ({onFilter}) => {
  const [filters, setfilters] = useState<Partial<ParamsModelArtikli>>({})
  return (
    <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
      <div className='mb-5'>
        <div
          className='accordion-header py-3 d-flex'
          data-bs-toggle='collapse'
          data-bs-target='#kt_accordion_2_item_2'
        >
          <span className='accordion-icon'>
            <KTSVG className='svg-icon svg-icon-4' path='/media/icons/duotune/arrows/arr064.svg' />
          </span>
          <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>FILTERI</h3>
        </div>
        <div
          id='kt_accordion_2_item_2'
          className='fs-6 ps-10'
          data-bs-parent='#kt_accordion_2'
        >
          <div className='d-flex justify-content-between py-5'>
            <div className='col-2 fv-row'>
              <KategorijaAritkliDropdown
                onChange={(name, value) => setfilters({...filters, kategorija: value})}
              />
            </div>
            <div className='col-2 fv-row'>
              <ArtikliDropdown
                onChange={(name, value) => setfilters({...filters, artikal: value})}
              />
            </div>
            <div className='col-2 fv-row'>
              <RoditeljiDropdown
                onChange={(name, value) => setfilters({...filters, roditelj: value})}
              />
            </div>
            <div className='col-2 fv-row'>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setfilters({...filters, aktivan: parseFloat(e.target.value)})}
              >
                <option value={undefined}>Status</option>
                <option value={1}>Aktivan</option>
                <option value={0}>Arhiviran </option>
              </select>
            </div>
            <button type='button' className='btn btn-primary' onClick={() => onFilter(filters)}>
              Potvrdi
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArtikliFilteri
