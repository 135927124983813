import {PageLink, PageTitle} from "../../../_websoft/layout/core";
import React from "react";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import RepromaterijaliInvLista from "./RepromaterijaliInvLista";
import TrakeInvLista from "./TrakeInvLista";
import RepromaterijaliTrakeDetails from "./RepromaterijaliTrakeDetails";
import InventureRolneIzvjestaj from "./InventuraRolneIzvjestaj";

const InventureRoutesBreadCrubms: Array<PageLink> = [
    {
        title: 'Inventure',
        path: '/inventure/repromaterijali',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const InventureRoutes: React.FC = () => {
    return (
        <Routes>
            <Route
                element={
                    <>
                        <Outlet />
                    </>
                }
            >
                <Route
                    path='rolne'
                    element={
                        <>
                            <PageTitle breadcrumbs={InventureRoutesBreadCrubms}>Inventure rolni</PageTitle>
                            <RepromaterijaliInvLista />
                        </>
                    }
                />
                <Route
                    path='rolne-trake/dodaj'
                    element={
                        <>
                            <PageTitle breadcrumbs={InventureRoutesBreadCrubms}>Dodaj inventuru</PageTitle>
                            <RepromaterijaliTrakeDetails />
                        </>
                    }
                />
                <Route
                    path='rolne-trake/izmjena/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={InventureRoutesBreadCrubms}>Izmijeni inventuru</PageTitle>
                            <RepromaterijaliTrakeDetails />
                        </>
                    }
                />
                <Route
                    path='rolne/izvjestaj/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={InventureRoutesBreadCrubms}>Inventure rolni</PageTitle>
                            <InventureRolneIzvjestaj />
                        </>
                    }
                />
                <Route
                    path='rolni'
                    element={
                        <>
                            <PageTitle breadcrumbs={InventureRoutesBreadCrubms}>Inventure rolni</PageTitle>
                            <RepromaterijaliInvLista />
                        </>
                    }
                />
                <Route
                    path='trake'
                    element={
                        <>
                            <PageTitle breadcrumbs={InventureRoutesBreadCrubms}>Dodaj</PageTitle>
                            <TrakeInvLista />
                        </>
                    }
                />
                <Route index element={<Navigate to='/inventura/repromaterijali' />} />
            </Route>
        </Routes>
    )
}
export default InventureRoutes;
