/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {KTCard, KTCardBody} from '../../../../../_websoft/helpers'
import {uposleniciIzvjestajRadUNormi} from '../../../../api/uposleniciIzvjestaj'
import {useLocation} from 'react-router-dom'
import moment from 'moment'
import notifyToast from '../../../../components/Toast/Toast'

const RadUProizvodnji = () => {
    const location = useLocation()
    const [data, setData] = useState<any>()
    const [galanterijaArray, setGalanterijaArray] = useState<any>([])
    const [nalogArray, setnalogArray] = useState<any>([])

    //  Get data from api
    const updatePage = () => {
        const params = {
            uposlenikId: location.state.uposlenikId,
            datumOd: location.state.datumOd,
            datumDo: location.state.datumDo,
        }
        uposleniciIzvjestajRadUNormi(params)
            .then((response) => {
                console.log({response})
                const {galanterija, nalog} = response.data.data
                setData(response.data.data)
                // Convert the object to an array
                const galanterijaList = Object.values(galanterija[0]).filter(
                    (item) => typeof item === 'object'
                )
                console.log({galanterijaList})
                setGalanterijaArray(galanterijaList)
                const nalogList = Object.values(nalog[0]).filter((item) => typeof item === 'object')
                console.log({nalogList})
                setnalogArray(nalogList)
            })
            .catch((error) => notifyToast(false, error.response.data.message))
    }

    const extractDateAndTime = (data) => {
        const date = moment(data).format('DD-MM-YYYY')
        const time = moment(data).format('h:mm')

        return {date, time}
    }

    useEffect(() => {
        updatePage()
    }, [])

    return (
        <KTCard>
            <KTCardBody>
                {data && (
                    <div>
                        <div className='row'>
                            <div className='alert alert-danger'>
                                <div className='row'>
                                    <span className='badge badge-success w-auto p-5 m-1'>
                                        <h5>Sati u normi: {data.ukupnoRadioSati}</h5>
                                    </span>
                                    <span className='badge badge-success w-auto p-5 m-1'>
                                        <h5>Nominalni Sati: {data.ukupnoRadioSatiBezHonorarniIVikend}</h5>
                                    </span>
                                    <span
                                        className={`badge w-auto p-5 m-1 ${data.procenatProizvodnje >= 70 ? 'badge-success' : 'badge-danger'}`}>
                                        <h5>Proizvodnja:{data.procenatProizvodnje}  %</h5>
                                    </span>
                                    <span
                                        className={`badge w-auto p-5 m-1 ${data.projekNormeGalanterija >= 80 ? 'badge-success' : 'badge-danger'}`}>
                                        <h5>Galanterija: {data.projekNormeGalanterija}</h5>
                                    </span>
                                    <span
                                        className={`badge w-auto p-5 m-1 ${data.projekNormeRadniNalozi >= 80 ? 'badge-success' : 'badge-danger'}`}>
                                        <h5>R.Nalozi: {data.projekNormeRadniNalozi}</h5>
                                    </span>
                                    <span
                                        className={`badge w-auto p-5 m-1 ${data.projekNormi >= 80 ? 'badge-success' : 'badge-danger'}`}>
                                        <h5>Prosjek Norme: {data.projekNormi} %</h5>
                                    </span>
                                </div>
                                <div className='row'>
                                    <span
                                        className={`badge w-auto p-5 m-1 ${data.projekNormi >= 80 ? 'badge-success' : 'badge-danger'}`}>
                                        <h5>Subota(h): {data.totalSatiSubota}</h5>
                                    </span>
                                    <span
                                        className={`badge w-auto p-5 m-1 ${data.projekNormi >= 80 ? 'badge-success' : 'badge-danger'}`}>
                                        <h5>Nedjelja(h): {data.totalSatiNedjelja}</h5>
                                    </span>
                                    <span
                                        className={`badge w-auto p-5 m-1 ${data.projekNormi >= 80 ? 'badge-success' : 'badge-danger'}`}>
                                        <h5>Honorarni(h): {data.honorarniSati}</h5>
                                    </span>
                                    <span
                                        className={`badge w-auto p-5 m-1 ${data.projekNormi >= 80 ? 'badge-success' : 'badge-danger'}`}>
                                        <h5>ERV sistem(h): {data.ukupnoPrijavljenSati}</h5>
                                    </span>
                                </div>
                            </div>
                            Nominalni sati: Ukupni sati - Honorarni - Sati vikendom
                        </div>
                        <h2 className='my-10'>Galanterija Table</h2>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'>
                                <th className='text-white'>ID</th>
                                <th className='text-white'>Datum</th>
                                <th className='text-white'>Artikal/Operacija</th>
                                <th className='text-white'>Početak</th>
                                <th className='text-white'>Kraj</th>
                                <th className='text-white'>Količina</th>
                                <th className='text-white'>Norma</th>
                            </tr>
                            </thead>
                            <tbody>
                            {galanterijaArray &&
                                galanterijaArray.map((item, index) => {
                                    const {start, dan, artikal, operacije, kraj, uradjenoKomada, ispunjenostNorme} =
                                        item
                                    const {date, time: startTime} = extractDateAndTime(start)
                                    const {time: endTime} = extractDateAndTime(kraj)
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                {date}
                                                <br/>
                                                {dan}
                                            </td>
                                            <td>
                                                {artikal} / {operacije}
                                            </td>
                                            <td>{startTime}</td>
                                            <td>{endTime}</td>
                                            <td>{uradjenoKomada}</td>
                                            <td>{ispunjenostNorme}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                        {/*<div className='row'>
                          <span className='badge badge-light-primary w-auto p-5 m-1'>
                            Prosjek Norme: {data.galanterija[0].prosjekNorme}
                          </span>
                                        <span className='badge badge-light-primary w-auto p-5 m-1'>
                            Sati U Normi: {data.galanterija[0].satiUNormi}
                          </span>
                                        <span className='badge badge-light-primary w-auto p-5 m-1'>
                            Dana U Normi: {data.galanterija[0].danaUNormi}
                          </span>
                            <span className='badge badge-light-primary w-auto p-5 m-1'>
                            Radni Subota:
                                {data.galanterija[0].radniSubota.map((subota, index) => (
                                    <div key={index}>
                                        {Object.values(subota).map((date, dateIndex) => (
                                            <span key={dateIndex}>{date as string}</span> // Type assertion here
                                        ))}
                                    </div>
                                ))}
                            </span>
                            <span className='badge badge-light-primary w-auto p-5 m-1'>
                              <div>
                                  Radni Nedjelja:
                                  {data.galanterija[0].radniNedjelja.map((nedjelja, index) => (
                                      <div key={index}>
                                          {Object.values(nedjelja).map((date, dateIndex) => (
                                              <span key={dateIndex}>{date as string}</span> // Type assertion here
                                          ))}
                                      </div>
                                  ))}
                                </div>
                            </span>
                            <span className='badge badge-light-primary w-auto p-5 m-1'>
                                Radni Dana U Mjesecu: {data.galanterija[0].radniDanaUMjesecu}
                              </span>
                            <span className='badge badge-light-primary w-auto p-5 m-1'>
                                Procenat Proizvodnje: {data.galanterija[0].procenatProizvodnje}
                              </span>
                        </div>*/}

                        <hr/>
                        <h2 className='my-10'>Nalog Table</h2>
                        {nalogArray && nalogArray.map((item, index) =>
                                item instanceof Object && (
                                    <>
                                        <table className='table align-middle table-bordered gy-5 no-footer'>
                                            <thead>
                                            <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'>
                                                <th className='text-white'>BrojNaloga</th>
                                                <th className='text-white'>Početak</th>
                                                <th className='text-white'>Kraj</th>
                                                <th className='text-white'>Radio Minuta</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{item.BrojNaloga ?? ''}</td>
                                                <td>{item.Pocetak ? moment(item.Pocetak).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                                <td>{item.Kraj ? moment(item.Kraj).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                                <td>{item.VrijemeNalogaMinuta ?? ''}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        {item.Stavke && item.Stavke.length > 0 && (
                                            <table className='table align-middle table-bordered gy-5 no-footer'>
                                                <thead>
                                                <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-secondary p-2'>
                                                    <th>Šifra</th>
                                                    <th>Artikal</th>
                                                    <th>Količina</th>
                                                    <th>Norma/Min</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {item.Stavke.map((stavka, sIndex) => (
                                                    <tr key={sIndex}>
                                                        <td>{stavka.Sifra}</td>
                                                        <td>{stavka.Naziv}</td>
                                                        <td>{stavka.Kolicina}</td>
                                                        <td>{stavka.PotrebnoVrijemeMin}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        )}
                                    </>
                                )
                        )}

                    </div>
                )}
            </KTCardBody>
        </KTCard>
    )
}

export default RadUProizvodnji
