import { ToastOptions, toast } from 'react-toastify';

// Function to detect if the device is mobile or tablet in portrait mode
const isMobileOrTabletPortrait = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  return (width <= 900 && height > width); // Adjust the breakpoint as needed
};

const notifyToast = (isSuccess: boolean, msg: string) => {
  const attributes: ToastOptions = {
    position: isMobileOrTabletPortrait() ? 'top-left' : 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  };

  if (isSuccess) {
    toast.success(msg, attributes);
  } else {
    toast.error(msg, attributes);
  }
};

export default notifyToast;
