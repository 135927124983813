import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {KreirajQRcodeModel, ParamsModelKreirajQRcode} from '../models/KreirajQRcodeModel'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/kreirajQRcode`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: `Bearer ${auth?.access_token ?? ''}`}

export async function getQrCodeData(params: ParamsModelKreirajQRcode) {
  return await axios.get<Array<KreirajQRcodeModel>>(API, {headers: headers, params: params})
}
