/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {KTCard, KTCardBody} from '../../../_websoft/helpers'
import TablePagination from '../../components/TablePagination/TablePagination'
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import TableSearch from '../../components/TableSearch/TableSearch'
import {ToastContainer} from 'react-bootstrap'
import notifyToast from '../../components/Toast/Toast'
import moments from 'moment'
import {
  IntervencijaIzvrsioci,
  ParamsModelIntervencijeIzvrsioci,
} from '../../models/IntervencijaIzvrsioci'
import {PaginationModel} from '../../models/PaginationModel'
import {getAllIntervencijeIzvrsioci} from '../../api/intervencijaIzvrsioci'
import IntervencijeFilteri from '../../components/IntervencijeFilteri/IntervencijeFilteri'

const IntervencijaIzvrsiociLista = () => {
  const [data, setData] = useState<IntervencijaIzvrsioci[]>([])
  const [loading, setLoading] = useState(true)
  const [pagination, setpagination] = useState<PaginationModel>({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filter, setfilter] = useState('')
  const [params, setparams] = useState<ParamsModelIntervencijeIzvrsioci>({
    per_page: 10,
    page: 1,
  })

  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')

  const updatePage = () => {
    setLoading(true)
    getAllIntervencijeIzvrsioci(params)
      .then((response) => {
        console.log(response.data['data'])
        const data = response.data['data']
        setData(data)
        const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
        setpagination({
          curPage: response.data['current_page'],
          totalPage: totalPage,
          perPage: params.per_page,
        })
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error)
      })
      .finally(() => {
        setLoading(false)
      })
    console.log(data)
  }

  const onFilter = (filter: ParamsModelIntervencijeIzvrsioci) => {
    setparams({
      per_page: pagination.perPage,
      page: 1,
      datumOd: filter.datumOd,
      datumDo: filter.datumDo,
      uposlenik: filter.uposlenik,
      sredstvo: filter.sredstvo,
    })
  }

  useEffect(() => {
    updatePage()
  }, [params])

  useEffect(() => {
    const {id} = sorting[0] ?? ''
    data.sort((a, b) => a[id] - b[id])
  }, [sorting])

  const columns = [
    {
      id: 'Id',
      header: 'Id',
      accessorKey: 'Id',
    },
    {
      id: 'Uposlenik',
      header: 'Uposlenik',
      accessorKey: 'uposlenik.UposlenikImePrezime',
    },
    {
      id: 'Intervencija',
      header: 'Uposlenik',
      accessorKey: 'intervencija.BrojIntervencije',
    },
    {
      id: 'Datum',
      header: 'Datum',
      accessorFn: (row) => `${moments(row.intervencija.Datum).format('DD-MM-YYYY')}`,
    },
    {
      id: 'Sredstvo',
      header: 'Sredstvo',
      accessorKey: 'intervencija.sredstvo.SredstvoNaziv',
    },
    {
      id: 'OpisRadova',
      header: 'Opis radova',
      accessorKey: 'intervencija.OpisRadova',
    },
    {
      id: 'Status',
      header: 'Status',
      accessorKey: 'intervencija.status.Naziv',
    }
  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })

  return (
    <KTCard>
      <ToastContainer />
      <KTCardBody className='py-4'>
        <IntervencijeFilteri onFilter={onFilter} />
        <div className='d-flex justify-content-between'>
          <div data-kt-user-table-toolbar='base'>
            <TableSearch onSearch={(text) => setGlobalFilter(text)} />
          </div>
          <div data-kt-user-table-toolbar='base'>
            <ExportButton table={table} filename={'intervencija_izvrsioci'} />
          </div>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3 '>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className='text-white'
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* @ts-ignore */}
                        {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <TablePagination
          updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
          pagination={pagination}
        />
      </KTCardBody>
    </KTCard>
  )
}

export default IntervencijaIzvrsiociLista
