import {KTCard, KTCardBody} from "../../../../_websoft/helpers";
import {ToastContainer} from "react-toastify";
import React, {useState} from "react";
import {useFormik} from "formik";
import notifyToast from "../../../components/Toast/Toast";
import clsx from "clsx";
import * as Yup from "yup";
import axios from 'axios'
import {UposlenikModel} from "../../../models/UposlenikModel";
import {getAuth} from "../../../modules/auth";
import {useNavigate} from "react-router-dom";

const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: `Bearer ${auth?.access_token ?? ''}`}
const API_URL = process.env.REACT_APP_API_URL

interface ZavrsiNalogProps {
    data: any; // You can replace 'any' with a more specific type based on the structure of your data
}

const ZavrsiNalog: React.FC<ZavrsiNalogProps> = ({data}) => { // Destructuring to get data from props
    const navigate = useNavigate();
    const API = `${API_URL}/artikliZapisiProizvodnje`

    const initialValues = {
        kolicina: '',
        kvar: '',
        opisKvara: '',
        urgentnaPrijava: '',
        endTime: '',
    }
    const [loading, setLoading] = useState(false)

    const validationSchema = Yup.object({
        kolicina: Yup.string().required('Required')
    })
    const now = new Date();
    const unixTimestamp = Math.floor(now.getTime() / 1000);

    const getFormData = (values) => {
        const formData = new FormData()
        formData.append('kolicina', values.kolicina)
        formData.append('kvar', values.kvar)
        formData.append('opisKvara', values.opisKvara)
        formData.append('urgentnaPrijava', values.urgentnaPrijava)
        formData.append('endTime', values.unixTimestamp)
        return formData
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                const unixTimestamp = Math.floor(new Date().getTime() / 1000);
                const updatedValues = {
                    ...values,
                    endTime: unixTimestamp,
                    idOperacije: data[0].idOperacije,
                    idMasine:data[0].idMasine
                };
                const response = await axios.put(`${API}/${data[0].id}`, updatedValues, { headers: headers });
                 if (response.data.error === 0) {
                    notifyToast(true, response.data.message)
                     setTimeout(() => {
                         navigate('/terminali/pocetna');
                     }, 4000);
                } else {
                    notifyToast(false, response.data.message || 'An unexpected error occurred.')
                }
            } catch (error) {
                console.error('Error updating Zapis data:', error)
            }finally {
                setLoading(false)
                setSubmitting(false)
            }
        },
    })
    const [showBlock, setBlock] = useState(false);
    const handleInputChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        if (selectedValue === "1") {
            setBlock(true);
        }else{
            setBlock(false);
        }
    }
    return (
        <KTCard>
            <ToastContainer/>
            <KTCardBody className='py-4'>
                <span className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                    {data[0].uposlenik.UposlenikImePrezime}
                </span>
                <i className="ki-duotone ki-verify fs-1 text-primary"><span className="path1"></span><span
                    className="path2"></span></i>
                <div className="row mt-3">
                    <div className="col-lg-6 col-sm-12  mt-3">
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center"><i
                                className="ki-duotone ki-arrow-up fs-3 text-success me-2"><span
                                className="path1"></span><span
                                className="path2"></span></i>
                                <div className="fs-2 fw-bolder">{data[0].operacije.naziv}</div>
                            </div>
                            <div className="fw-bold fs-6 text-gray-400">Operacija</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mt-3">
                        <div className="border border-gray-300 border-dashed rounded min-w-250px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center"><i
                                className="ki-duotone ki-arrow-up fs-3 text-success me-2"><span
                                className="path1"></span><span
                                className="path2"></span></i>
                                <div className="fs-2 fw-bolder">{data[0].sredstva.SredstvoNaziv}</div>
                            </div>
                            <div className="fw-bold fs-6 text-gray-400">Sredstvo</div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='row'>
                            <div className='col-6 mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                    Količina urađenih komada
                                </label>

                                <div className='col-lg-6 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Upišite broj komada'
                                        {...formik.getFieldProps('kolicina')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.kolicina && formik.errors.kolicina},
                                            {
                                                'is-valid': formik.touched.kolicina && !formik.errors.kolicina,
                                            }
                                        )}
                                    />
                                    {formik.touched.kolicina && formik.errors.kolicina && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.kolicina}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <label className='col-form-label required fw-bold fs-6'>Rad zaustavljen kvarom na
                                mašini</label>
                            <div className='col-lg-6 fv-row'>
                                <select
                                    {...formik.getFieldProps('kvar')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {'is-invalid': formik.touched.kvar && formik.errors.kvar},
                                        {'is-valid': formik.touched.kvar && !formik.errors.kvar}
                                    )}
                                    onChange={(e) => {
                                        formik.handleChange(e); // This updates Formik's state
                                        handleInputChange(e); // Any additional logic you have in your custom handler
                                    }}
                                >
                                    <option value="0">Ne</option>
                                    <option value="1">Da</option>
                                </select>
                            </div>
                            <div className='col-lg-6 fv-row'>

                            </div>
                            {showBlock && (
                                <div className='col-lg-6 fv-row mt-4'>
                                    <label className='col-form-label required fw-bold fs-6'>Upišite informacije o
                                        kvaru</label>
                                    <textarea
                                        placeholder='Opis kvara'
                                        {...formik.getFieldProps('opisKvara')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.opisKvara && formik.errors.opisKvara},
                                            {
                                                'is-valid': formik.touched.opisKvara && !formik.errors.opisKvara,
                                            }
                                        )}
                                    />
                                    {formik.touched.opisKvara && formik.errors.opisKvara && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.opisKvara}</div>
                                        </div>
                                    )}
                                    <label className='col-form-label fw-bold mt-4 fs-6'>Urgentna prijava kvara</label>
                                    <div className='mt-2 mb-4'>
                                        <select
                                            {...formik.getFieldProps('urgentnaPrijava')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.urgentnaPrijava && formik.errors.urgentnaPrijava},
                                                {
                                                    'is-valid': formik.touched.urgentnaPrijava && !formik.errors.urgentnaPrijava,
                                                }
                                            )}
                                        >
                                            <option value='0'>Ne</option>
                                            <option value='1'>Da</option>
                                        </select>
                                        {formik.touched.urgentnaPrijava && formik.errors.urgentnaPrijava && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.urgentnaPrijava}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={loading || formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && 'Potvrdi'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                             </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </KTCardBody>
        </KTCard>
    )
}

export default ZavrsiNalog;
