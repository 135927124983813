import React, { useEffect, useState } from 'react';
import { KTIcon } from '../../../_websoft/helpers';
import { ToastContainer } from 'react-toastify';
import TitleComponent from '../../components/TitleComponent/TitleComponent';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import notifyToast from "../../components/Toast/Toast";
import { nalogIzvjestaj } from "../../api/izvjestaj";
import NalogIzvjestajPdf from "./components/NalogIzvjestaj/NalogIzvjestajPdf";
import { usePDF } from "@react-pdf/renderer";
import { PaketLezarina } from "../../models/PaketLezarina";
import { NalogIzvjestajModel } from "../../models/IzvjestajModel";
import PlaceholderDocument from "./components/PdfPageTemplate/PlaceholderDocument";

const Izvjestaji = () => {
    const [brojNaloga, setBrojNaloga] = useState('');
    const [stavka, setStavka] = useState('');
    const navigate = useNavigate();
    const [nalog, setNalog] = useState<NalogIzvjestajModel[]>([]);
    const [instance, updateInstance] = usePDF({
        document: nalog.length > 0 ? <NalogIzvjestajPdf nalog={nalog[0]} /> : <PlaceholderDocument />
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const params = {
                brojNaloga: brojNaloga,
                stavka: stavka
            };
            const response = await nalogIzvjestaj(params);
            const data = response.data;

            // Check if the data is an array and handle appropriately
            const nalogData = Array.isArray(data) ? data : [data];

            if (nalogData.length > 0 && typeof nalogData[0] === 'object') {
                setNalog(nalogData);
                console.log(nalogData, 'Received data');
                updateInstance(<NalogIzvjestajPdf nalog={nalogData[0]} />);
            } else {
                console.error('Data structure is not as expected', data);
                notifyToast(false, 'Nema obračunata lezarina na odabrani datum');
            }
        } catch (error) {
            console.error('Error when calculating lezarina:', error);
            notifyToast(false, 'Failed to fetch lezarina data');
        }
    };

    useEffect(() => {
        if (nalog.length > 0 && !instance.loading && instance.url) {
            window.open(instance.url, '_blank')?.focus();
        }
    }, [instance, nalog]);

    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer />
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name='Izvještaji' />
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    <div className='row'>
                        <div className='col-lg-4 col-sm-12 p-3 mb-6'>
                            <Link to='glavni-izvjestaj'>
                                <button type='button' className='btn btn-primary'>
                                    <KTIcon iconName='plus' className='fs-2'/>
                                    Glavni Izvještaj
                                </button>

                            </Link>
                        </div>
                        <div className='col-lg-4 col-sm-12 p-3 mb-6'>
                            <Link to='glavni-izvjestaj-v2'>
                                <button type='button' className='btn btn-primary'>
                                    <KTIcon iconName='plus' className='fs-2'/>
                                    Glavni Izvještaj V2
                                </button>
                            </Link><br/><br/>
                            <span>
                                 Stara verzija do 07/2024
                            </span>
                        </div>
                    </div>
                    <div className='separator mt-1 mb-4'></div>
                    <div className='row'>
                        <div className='col-lg-4 col-sm-12 p-3 mb-6'>
                            <button
                                type='button'
                                className='btn btn-success'
                                data-bs-toggle='modal'
                                data-bs-target='#nalogModal'
                            >
                                <KTIcon iconName='plus' className='fs-2'/>
                                Nalog - Detaljan Izvještaj
                            </button>
                        </div>
                        <div className='col-lg-4 col-sm-12 p-3 mb-6'>
                            <button
                                type='button'
                                className='btn btn-info'
                                data-bs-toggle='modal'
                                data-bs-target='#nalogModal'
                            >
                                <KTIcon iconName='plus' className='fs-2'/>
                                KK - Dnevni izvještaj
                            </button>
                        </div>
                        <div className='col-lg-4 col-sm-12 p-3 mb-6'>
                            <button
                                type='button'
                                className='btn btn-warning'
                                data-bs-toggle='modal'
                                data-bs-target='#nalogModal'
                            >
                                <KTIcon iconName='plus' className='fs-2'/>
                                KK - Periodični izvještaj
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bootstrap Modal */}
            <div
                className='modal fade'
                id='nalogModal'
                tabIndex={-1}
                aria-labelledby='nalogModalLabel'
                aria-hidden='true'
            >
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='nalogModalLabel'>Nalog Izvještaj</h5>
                            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className='modal-body'>
                                <div className='mb-3'>
                                    <label htmlFor='brojNaloga' className='form-label'>Broj Naloga</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='brojNaloga'
                                        value={brojNaloga}
                                        onChange={(e) => setBrojNaloga(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor='stavka' className='form-label'>Stavka</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='stavka'
                                        value={stavka}
                                        onChange={(e) => setStavka(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>Close</button>
                                <button type='button' onClick={handleSubmit} className='btn btn-primary'>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Izvjestaji;
