import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {ParamsModelTroskoviSredstava, TroskoviSredstava} from '../models/TroskoviSredstava'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/sredstvoTrosak`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: `Bearer ${auth?.access_token ?? ''}`}

export async function getAllTroskovi(params: ParamsModelTroskoviSredstava) {
  return await axios.get<Array<TroskoviSredstava>>(API, {headers: headers, params: params})
}

export async function saveTrosak(Data: any) {
  try {
    const response = await axios.post(API, Data, {headers: headers})
    if (response.data && response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function fetchTrosak(Id: number): Promise<Partial<TroskoviSredstava>> {
  try {
    const response = await axios.get(`${API}/${Id}`)
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateTrosak(Data: any, id: number) {
  try {
    const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
    if (response.data && response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to odjel.')
    }
  } catch (error) {
    console.error('Error Updates data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function deleteTrosak(Id: number) {
  try {
    const response = await axios.delete(`${API}/${Id}`, {headers: headers})
    if (response.data && response.data.error === 0) {
      return response
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to delete.')
    }
  } catch (error) {
    console.error('Error deleting data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
