import React, {useMemo} from 'react'
import {PakingListeThermoboardModel} from '../../models/ThermoBoardNalozi'

import {Font, Page, View, Image, Text, Document, StyleSheet} from '@react-pdf/renderer'
// Register font
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
})
// Create styles
const styles = StyleSheet.create({
  pdf: {width: '100vh', height: '500px'},
  page: {
    flexDirection: 'column',
    flex: 1,
    height: '300px',
    padding: 10,
  },
  section: {
    paddingHorizontal: 20,
    // flex: '1',
    position: 'absolute',
    bottom: 30,
    // left: 30,
  },
  section2: {
    margin: 2,
    marginBottom: 80,
    marginLeft: 80,
    padding: 2,
    flex: '1',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },

  qrCode: {
    margin: 10,
    padding: 10,
    width: '90px',
  },

  text: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Roboto',
    padding: 5,
  },
  boldtext: {
    fontSize: 20,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  boldtextPosition: {
    marginTop: '15px',
    marginRight: '20px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #212121',
    borderRight: '1px solid #212121',
    borderLeft: '1px solid #212121',
  },
  bold: {
    fontWeight: 'bold',
  },
})
interface Props {
  qrData: PakingListeThermoboardModel[]
  qrList: Array<string>
}
const ThermoboardPakingListePdf: React.FC<Props> = ({qrData, qrList}) => {
  const data = useMemo(() => ({qrData}), [qrData])
  console.log('data', data.qrData)

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Document>
      {data.qrData &&
        data.qrData.map((page, i) => (
          <Page size='A5' style={styles.page} orientation='portrait'>
            <View style={styles.section}>
              <View style={styles.section2}>
                <Text style={[styles.boldtext,styles.boldtextPosition,{top: '46'}]}>{page.Id}</Text>
                <Text style={[styles.boldtext, {position: 'absolute', right: '-20', top: '62'}]}>
                  {page.kvadratura}
                </Text>
              </View>
              {/* @ts-ignore */}
              <Image style={styles.qrCode} source={{uri: qrList[i]}} />
            </View>
          </Page>
        ))}
    </Document>
  )
}

export default ThermoboardPakingListePdf
