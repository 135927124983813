/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_websoft/helpers'
import BarCodeLogin from './components/BarcodeLogin'
import {loginWithBarcode} from './core/_requests'
import {useAuth} from './core/Auth'
import notifyToast from '../../components/Toast/Toast'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'

const AuthLayout = () => {
  const {saveAuth, setCurrentUser} = useAuth()
  const [showModal, setShowModal] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [barcode, setBarcode] = useState('')
  const [loading, setloading] = useState(false)

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    if (showModal) {
      inputRef.current?.focus()
    }
  }, [showModal])
  const handleInputChange = (e) => {
    setBarcode(e.target.value)
  }

  const callApiToLogin = async () => {
    // Replace with your API call logic
    console.log('Calling API with barcode:', barcode)

    try {
      setloading(true)
      const {data: loginData} = await loginWithBarcode(barcode)
      saveAuth({...loginData, loginType: 'rfid'})
      setCurrentUser({...loginData, loginType: 'rfid'})
    } catch (error: any) {
      console.error('Error: ', error.response.data.message)
      saveAuth(undefined)
      notifyToast(false, error.response.data.message)
    } finally {
      setloading(false)
    }
    // Example: await yourApiFunction({ barcode: code });
  }
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='d-flex flex-center flex-wrap px-5'>
            {/* begin::Links */}

            <div className='d-flex fw-semibold text-primary fs-base'>
              <button className='btn btn-primary px-5' onClick={openModal}>
                BARCODE LOGIN
              </button>
            </div>
            {showModal && (
              <>
                <div className='modal fade show' style={{display: 'block'}} tabIndex={-1}>
                  <div className='modal-dialog modal-dialog-centered'>
                    <BarCodeLogin
                      closeModal={closeModal}
                      inputRef={inputRef}
                      barcode={barcode}
                      handleInputChange={handleInputChange}
                      handleSubmit={callApiToLogin}
                      loading={loading}
                    />
                  </div>
                </div>
                <div className='modal-backdrop fade show'></div>
              </>
            )}
            {showModal && <div className='modal-backdrop fade show'></div>}
            {/* end::Links */}
          </div>
        )}
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <Link to='/' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/custom-1.png')} className='h-75px' />
          </Link>
          {/* end::Logo */}

          {/* begin::Image */}
          <img
            className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/misc/auth-screens.png')}
            alt=''
          />
          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>Websoft - ERP solution</h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='text-white fs-base text-center'>
            Zapamtite da sudbina svakoga{' '}
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              ovisi o ponašanju svakoga.
            </a>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
