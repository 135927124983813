import React from 'react';

const TreeNode = ({ node, level = 0 }) => {
    const isParent = node.children && node.children.length > 0;
    const rowStyle = {
        color: isParent ? 'red' : 'black', // Set color for parent rows
        fontWeight: isParent ? 'bold' : 'normal' // Bold font for parent rows
    };

    const cellStyle = {
        paddingLeft: `${level * 40}px` // Adjust indentation as needed
    };

    const artAktivanText = node.ArtAktivan === 1 ? 'Da' : 'Ne';
    const artAktivanStyle = node.ArtAktivan !== 1 ? { color: 'red' } : {};

    return (
        <>
            <tr style={rowStyle}>
                <td style={cellStyle}>&nbsp;{node.ArtSifra}</td>
                <td style={cellStyle}>{node.ArtNaziv}</td>
                <td style={cellStyle}>{node.ArtStanjeSA}</td>
                <td style={{...cellStyle, ...artAktivanStyle}}>{artAktivanText}</td>
            </tr>
            {isParent && (
                <>
                    {node.children.map((child) => (
                        <TreeNode key={child.ArtId} node={child} level={level + 1} />
                    ))}
                </>
            )}
        </>
    );
};

export default TreeNode;
