/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {KTCard, KTCardBody} from '../../../_websoft/helpers'
import TablePagination from '../../components/TablePagination/TablePagination'
import {DrugaKlasaModel, ParamsModelDrugaKlasa} from '../../models/DrugaKlasaModel'
import {PaginationModel} from '../../models/PaginationModel'
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  deleteDrugaKlasaDimenzije,
  getDrugaKlasaDimenzije,
  skartDimenzijeDodijeliPaket,
} from '../../api/drugaKlasaDimenzije'
import notifyToast from '../../components/Toast/Toast'
import {TableActionButton} from '../../components/TableActionBtn/TableActionButton'
import {ToastContainer} from 'react-toastify'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import PaneliSkartDropdown from './components/PaneliSkartDropdown/PaneliSkartDropdown'
import {error} from 'console'
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox/IndeterminateCheckbox'
import PaneliSkartAllDropdown from './components/PaneliSkartAllDropdown/PaneliSkartAllDropdown'

const DrugaKlasaDimenzije = () => {
  const [data, setData] = useState<DrugaKlasaModel[]>([])
  const [loading, setLoading] = useState(true)
  const [pagination, setpagination] = useState<PaginationModel>({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setparams] = useState<ParamsModelDrugaKlasa>({per_page: 10, page: 1})
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [isModalOpen, setisModalOpen] = useState(false)
  const [rowSelection, setRowSelection] = useState({})
  const [paketID, setpaketID] = useState()
  const updatePage = () => {
    setLoading(true)
    getDrugaKlasaDimenzije(params)
      .then((response) => {
        const data = response.data['data']
        setData(data)
        const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
        setpagination({
          curPage: response.data['current_page'],
          totalPage: totalPage,
          perPage: params.per_page,
        })
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updatePaketid = () => {
    setLoading(true)
    const idList = table.getSelectedRowModel().rows.map((item) => item.original.id)
    const params = {
      stavkaId: idList,
      paketId: paketID,
    }
    skartDimenzijeDodijeliPaket(params)
      .then((response) => {
        updatePage()
        notifyToast(true, response.message)
      })
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    updatePage()
  }, [params])

  useEffect(() => {
    const {id} = sorting[0] ?? ''
    data.sort((a, b) => a[id] - b[id])
  }, [sorting])

  const columns = [
    {
      id: 'id',
      header: ({table}) => (
        <div className='row p-2'>
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      cell: ({row, getValue}) => (
        <div className='col'>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    {
      id: 'Panel ID',
      header: 'Panel ID',
      accessorKey: 'id',
    },
    {
      id: 'Dimenzije',
      header: 'Dimenzije',
      accessorFn: (row) => `${row.duzina} / ${row.sirina}`,
    },
    {
      id: 'm2',
      header: 'm2',
      accessorFn: (row) => ((row.duzina * row.sirina) / 1000).toFixed(2),
    },

    {
      id: 'Tip panela',
      header: 'Tip panela',
      accessorKey: 'tip_panela.Naziv',
    },
    {
      id: 'Boja',
      header: 'Boja',
      accessorFn: (row) => `${row.boja_lica.BojaNaziv} / ${row.boja_nalicja.BojaNaziv}`,
    },
    {
      id: 'Status Paketa',
      header: 'Status Paketa',
      accessorFn: (row) => {
        if (row.paket) {
          return `${row.paket.limitKomada} / ${row.paket.trenutnoKomada}`
        }
        return 'Odaberi paket'
      },
      cell: ({getValue}) => {
        const value = getValue()
        return value === 'Odaberi paket' ? (
          <span style={{color: 'red'}}>{value}</span>
        ) : (
          <span>{value}</span>
        )
      },
    },
    {
      id: 'Izolacija',
      header: 'Izolacija',
      accessorKey: 'izolacija_panela.naziv',
    },
    {
      id: 'Smjena',
      header: 'Smjena / Kreirao Zapis',
      accessorFn: (row) => `${row.glavna_knjiga.smjena} / ${row.uposlenik.UposlenikImePrezime}`,
    },
    {
      id: 'Klasa',
      header: 'Klasa',
      accessorFn: (row) => (row.paket ? row.paket.klasa : 'N/A'),
    },
    {
      header: 'Akcija',
      accessorKey: 'Akcija',
      cell: ({row}) => (
        <TableActionButton onDelete={() => onDelete(row.original.id)} onEdit={() => {}} />
      ),
    },
  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
      rowSelection: rowSelection,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
  })
  const onDelete = (id) => {
    deleteDrugaKlasaDimenzije(id)
      .then((response) => {
        notifyToast(true, response)
        setparams({per_page: pagination.perPage, page: pagination.curPage})
      })
      .catch((error) => {
        notifyToast(false, error.data['message'])
      })
  }

  return (
    <KTCard>
      <ToastContainer />

      <KTCardBody className='py-4'>
        <div className='row'>
          {/* Filter Paket */}
          {/* paneliSkartAllDropdown */}
          <div className='col-lg-4 mb-6'>
            <label className='col-auto col-form-label required fw-bold fs-6'>Odaberi Paket</label>
            <div className='col-lg-8 fv-row'>
              <PaneliSkartAllDropdown
                value={params.tipPanela}
                onChange={(name, value) => {
                  setparams({...params, tipPanela: value})
                }}
                selectName='paketID'
              />
            </div>
          </div>
          {/* Update Paket */}
          <div className='row'>
            {/* paneliSkartDropdown */}
            <div className='col-lg-4 mb-6'>
              <label className='col-auto col-form-label required fw-bold fs-6'>
                Izmijeni Paket
              </label>
              <div className='col-lg-8 fv-row'>
                <PaneliSkartDropdown
                  value={paketID}
                  onChange={(name, value) => {
                    setpaketID(value)
                  }}
                  selectName='paketID'
                />
              </div>
            </div>
            <div className='col-lg-4 align-self-center'>
              <button
                className='btn btn-primary'
                onClick={updatePaketid}
                disabled={!table.getSelectedRowModel().rows.length}
              >
                Potvrdi
              </button>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <div data-kt-user-table-toolbar='base'>
            <TableSearch onSearch={(text) => setGlobalFilter(text)} />
          </div>
          <div data-kt-user-table-toolbar='base'>
            <ExportButton table={table} filename={'Druga_klasa_dimenzije'} />
          </div>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3 '>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className='text-white'
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* @ts-ignore */}
                        {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <TablePagination
          updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
          pagination={pagination}
        />
      </KTCardBody>
    </KTCard>
  )
}

export default DrugaKlasaDimenzije
