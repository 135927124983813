import { Document, Page, Text, View } from '@react-pdf/renderer';

const PlaceholderDocument = () => (
    <Document>
        <Page>
            <View>
                <Text>No Data</Text>
            </View>
        </Page>
    </Document>
);

export default PlaceholderDocument;
