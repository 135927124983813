/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import SearchableSelect from '../SearchableSelect/SearchableSelect'
import {getPoslovniceDropdown} from '../../api/poslovnice'
import {Option} from '../../models/Sektor'

// UposlenikDropdownProps for the component
interface DropdownProps {
    value?: string | number
    initialValue?: string | number
    onChange?: (name: string, value: any) => void
    errors?: string | undefined
    touched?: boolean
    selectName?: string
}

const UposlenikDropdown: React.FC<DropdownProps> = ({
                                                        value,
                                                        initialValue,
                                                        onChange,
                                                        errors,
                                                        touched,
                                                        selectName,
                                                    }) => {
    const [poslovniceDropdown, setPoslovniceDropdown] = useState<Array<Option>>([])
    const internalValue = initialValue ?? null

    useEffect(() => {
        console.log('Initial val ', value)
        const fetchData = async () => {
            try {
                const response = await getPoslovniceDropdown()
                setPoslovniceDropdown(mapData(response.data))
            } catch (error) {
                console.error('Error fetching uposlenik dropdown data:', error)
            }
        }
        fetchData()
    }, [])

    const finalValue = value ?? internalValue

    const mapData = (data) => {
        if (!data) {
            return []
        }
        return data.map((item) => ({label: item.Naziv, value: item.Id.toString()}))
    }

    return (
        <SearchableSelect
            options={poslovniceDropdown}
            value={finalValue?.toString() ?? ''}
            onChange={onChange!}
            name={selectName ?? 'Poslovnice'}
            errors={errors}
            placeholder='Odaberi lokaciju...'
            touched={touched}
        />
    )
}

export default UposlenikDropdown
