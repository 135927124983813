import axios from 'axios'
import {getAuth} from '../modules/auth'
import {UposlenikModel} from '../models/UposlenikModel'
import {RolnaDetalji} from '../models/RolnaDetalji'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/rolnaDetalji`

const auth: UposlenikModel = getAuth()!

const headers = {
  Accept: 'application/json',
  Authorization: auth?.access_token ?? '',
}
export async function getRolnaDetalji(id: number) {
  const params = {rolnaId: id}
  return await axios.get<RolnaDetalji>(API, {headers: headers, params: params})
}
