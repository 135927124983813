import React, {useEffect, useMemo, useState} from 'react'
import {Modal, ModalHeader} from 'react-bootstrap'
import {getDimenzijePaketa} from '../../../../api/skartPaketi'
import notifyToast from '../../../../components/Toast/Toast'
import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import {DimenzijePaketaModel} from '../../../../models/SkartPaketiModel'

const DimenzijePaketaModal = ({isModalOpen, setisModalOpen, paketId}) => {
  const [loading, setLoading] = useState(false)
  const id = useMemo(() => paketId, [paketId])
  const [data, setData] = useState<DimenzijePaketaModel[]>([])

  useEffect(() => {
    setLoading(true)
    const params = {paketId: id}
    getDimenzijePaketa(params)
      .then((response) => {
        const data = response.data['data']
        setData(data)
      })
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => setLoading(false))
  }, [id])

  const columns = [
    {
      id: 'stavke',
      header: 'Stavka',
      accessorFn: (row) => `${row.glavna_knjiga.id} / SMJENA: ${row.glavna_knjiga.smjena}`,
    },
    {
      id: 'Duzina',
      header: 'Duzina',
      accessorKey: 'duzina',
    },
    {
      id: 'Sirina',
      header: 'Sirina',
      accessorKey: 'sirina',
    },
    {
      id: 'M2',
      header: 'M2',
      accessorFn: (row) => ((row.duzina  / 1000 * row.sirina  / 1000)).toFixed(2),
    },
  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Modal className='modal-lg' show={isModalOpen} onHide={() => setisModalOpen(false)}>
      <ModalHeader closeButton={true} className='h3'>
        Dimenzije Panela
      </ModalHeader>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className='table-responsive mt-3 p-10'>
          <table className='table align-middle table-bordered gy-5 no-footer'>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      className='text-white'
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {/* @ts-ignore */}
                      {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='text-gray-600'>
              {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </Modal>
  )
}

export default DimenzijePaketaModal
