import React, {useMemo} from 'react'
import {KreirajQRcodeTrake} from '../../models/KreirajQRcodeTrake'
import {Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer'
import logo from '../../../_websoft/assets/images/logo.jpg'
// Register font
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
})
// Create styles
const styles = StyleSheet.create({
  pdf: {width: '100vh', height: '500px'},
  page: {
    flexDirection: 'column',
    flex: 1,
    height: '300px',
    padding: 10,
  },
  section: {
    margin: 2,
    padding: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  section2: {
    margin: 2,
    padding: 2,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  section3: {
    margin: 2,
    padding: 2,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  qrCode: {
    margin: 2,
    padding: 2,
    width: '100px',
  },
  logo: {
    margin: 2,
    padding: 2,
    width: '200px',
  },

  text: {
    marginVertical: 2,
    marginHorizontal: 12,
    fontSize: 16,
    textAlign: 'justify',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  boldtext: {
    marginVertical: 2,
    marginHorizontal: 2,
    fontSize: 20,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
})
interface Props {
  qrData: Array<KreirajQRcodeTrake>
  qrList: Array<string>
}
const TrakePdf: React.FC<Props> = ({qrData, qrList}) => {
  const data = useMemo(() => ({qrData}), [qrData])

  return (
    <Document>
      {data.qrData &&
        data.qrData.map((page, i) => (
          <Page size='A6' style={styles.page} orientation='landscape'>
            <Image src={logo} style={styles.logo} />
            <View style={styles.section}>
              {/* @ts-ignore */}
              <Image style={styles.qrCode} source={{uri: qrList[i]}} />
              <View style={styles.section2}>
                <Text style={styles.boldtext}>Traka #: {page.Id}</Text>
                <Text style={styles.boldtext}>Rolna #: {page.BrojRolne}</Text>
              </View>
            </View>
            <View style={styles.section3}>
              <Text style={styles.text}>Debljina: {page.repromaterijal.ReprMatDebljina}</Text>
              <Text style={styles.text}>Širina: {page.Sirina}</Text>
              <Text style={styles.text}>
                Boja: {page.repromaterijal.boja_lica?.BojaNaziv} / {page.repromaterijal.boja_nalicja?.BojaNaziv}
              </Text>
              <Text style={styles.text}>Težina: {page.Tezina}   /   Dužina: {page.TrenutnaDuzina}</Text>
              <Text style={styles.text}></Text>
            </View>
          </Page>
        ))}
    </Document>
  )
}

export default TrakePdf
