import {useFormik} from 'formik'
import React, {useState} from 'react'
import {saveZapisiStavke} from '../../../../api/zapisiProizvodnje'
import notifyToast from '../../../../components/Toast/Toast'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'

const Pakirnica = ({id, brojNaloga}) => {
  const initialValues = {
    'TEMPERTATURA-AMBIJENTA-PAKIRNICE': undefined,
    'LIM-IMA-VIDLJIVE-NEDOSTATKE-NA-BOJI-GORNJI-LIM-PAKIRNICA': undefined,
    'LIM-IMA-VIDLJIVE-NEDOSTATKE-NA-BOJI-DONJI-LIM-PAKIRNICA': undefined,
    'VIDLJIVI-NEDOSTATCI-LJEPILO-BRTVENA-TRAKA-PAKIRNICA': undefined,
    'PAKETI-NE-ODSTUPAKU-OD-PRAVOSTI-I-DOZVOLJENE-ZAKRIVLJENOSTI': undefined,
  }
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response
        let data: any = []
        // Format data as per required format of api.
        Object.entries(values).map(([key, value]) => {
          if (value) {
            const dataSet = {
              IdZapisa: id,
              BrojNaloga: brojNaloga,
              MetaKey: key,
              MetaValue: value,
            }
            data.push(dataSet)
          }
        })
        response = await saveZapisiStavke(data)

        const responseData = response

        if (responseData.error === 0) {
          notifyToast(true, responseData.message)
        } else {
          notifyToast(false, responseData.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error.response.data.message)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  return (
    <div>
      {loading ? (
        <LoadingComponent />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div id='pakirnica'>
            <div className='row mt-3'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  {' '}
                  Temperatura ambijenta pakirnica{' '}
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Temp pakirnice'
                  {...formik.getFieldProps('TEMPERTATURA-AMBIJENTA-PAKIRNICE')}
                />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  Lim ima vidljive nedostatke na boji GORNJI LIM
                </label>
                <select
                  className='form-control'
                  {...formik.getFieldProps(
                    'LIM-IMA-VIDLJIVE-NEDOSTATKE-NA-BOJI-GORNJI-LIM-PAKIRNICA'
                  )}
                >
                  <option value=''>ODABERI</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  Lim ima vidljive nedostatke na boji DONJI LIM
                </label>
                <select
                  className='form-control'
                  {...formik.getFieldProps(
                    'LIM-IMA-VIDLJIVE-NEDOSTATKE-NA-BOJI-DONJI-LIM-PAKIRNICA'
                  )}
                >
                  <option value=''>ODABERI</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  Vidljivi nedostatci ljepilo/brtvena traka
                </label>
                <select
                  className='form-control'
                  {...formik.getFieldProps('VIDLJIVI-NEDOSTATCI-LJEPILO-BRTVENA-TRAKA-PAKIRNICA')}
                >
                  <option value=''>ODABERI</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  Paketi ne odstupaju od pravosti i dozvoljene zakrivljenosti
                </label>
                <select
                    className='form-control'
                    {...formik.getFieldProps('PAKETI-NE-ODSTUPAKU-OD-PRAVOSTI-I-DOZVOLJENE-ZAKRIVLJENOSTI')}
                >
                  <option value=''>ODABERI</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-lg-3 offset-9'>
              <button type='submit' className='btn btn-block btn-primary'>
                Potvrdi
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

export default Pakirnica
