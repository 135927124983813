import React, {useEffect, useState} from "react";
import {EfikasnostObucavanja, ParamsModel} from "../../models/ObucavanjaModel";
import {PaginationModel} from "../../models/PaginationModel";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from "@tanstack/react-table";
import {getAllPrijedloziPoboljsanja} from "../../api/prijedloziZaPoboljsanja";
import notifyToast from "../../components/Toast/Toast";
import moment from 'moment';
import {KTCard, KTCardBody, KTIcon} from "../../../_websoft/helpers";
import {ToastContainer} from "react-toastify";
import TableSearch from "../../components/TableSearch/TableSearch";
import ExportButton from "../../components/TableExportToExcel/ExportButton";
import {Link} from "react-router-dom";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import TablePagination from "../../components/TablePagination/TablePagination";
import {getAllZapisiViljuskara} from "../../api/viljuskari";

const ViljuskariZapisiLista:React.FC = () => {
    const [data, setData] = useState<EfikasnostObucavanja[]>([])
    const [loading, setLoading] = useState(true)
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [params, setparams] = useState<ParamsModel>({per_page: 10, page: 1})
    const {per_page, page} = params
    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')

    const updatePage = () => {
        setLoading(true)
        getAllZapisiViljuskara(per_page, page)
            .then((response) => {
                setData(response.data['data'])
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                setpagination({
                    curPage: response.data['current_page'],
                    totalPage: totalPage,
                    perPage: params.per_page,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        updatePage()
    }, [params])

    useEffect(() => {
        const {id} = sorting[0] ?? ''
        data.sort((a, b) => a[id] - b[id])
    }, [sorting])

    const columns = [
        {
            id: 'Id',
            header: 'Id',
            accessorKey: 'Id',
        },
        {
            id: 'INV-broj',
            header: 'INV-broj',
            accessorKey: 'sredstvo.SredstvoInvBroj',
        },
        {
            id: 'Sredstvo',
            header: 'Sredstvo',
            accessorKey: 'sredstvo.SredstvoNaziv',
        },
        {
            id: 'Uposlenik',
            header: 'Uposlenik',
            accessorKey: 'uposlenik.UposlenikImePrezime',
        },
        {
            id: 'Pocetak',
            header: 'Pocetak',
            accessorFn: (row) => `${moment(row.Pocetak).format('DD-MM-YYYY HH:mm')} `,
        },
        {
            id: 'Kraj',
            header: 'Kraj',
            accessorFn: (row) => `${moment(row.Kraj).format('DD-MM-YYYY HH:mm')} `,
        },
        {
            id: 'Sati Početak',
            header: 'Sati Početak',
            accessorKey: 'BrojSatiPocetak',
        },
        {
            id: 'Sati Kraj',
            header: 'Sati Kraj',
            accessorKey: 'BrojSatiKraj',
        },
        {
            id: 'Voda',
            header: 'Voda',
            accessorFn: (row) => {
                if (row.ProvjerioVodu === 1) {
                    return <i className="bi bi-check-circle" style={{ color: 'green', fontSize:'20px' }}></i>;
                } else {
                    return <i className="bi bi-x-circle" style={{ color: 'red', fontSize:'20px' }}></i>;
                }
            },
            cell: (info) => info.getValue(),
        },
        {
            id: 'Ulje',
            header: 'Ulje',
            accessorFn: (row) => {
                if (row.ProvjerioUlje === 1) {
                    return <i className="bi bi-check-circle" style={{ color: 'green', fontSize:'20px' }}></i>;
                } else {
                    return <i className="bi bi-x-circle" style={{ color: 'red', fontSize:'20px' }}></i>;
                }
            },
            cell: (info) => info.getValue(),
        },
        {
            id: 'Hidrol',
            header: 'Hidrol',
            accessorFn: (row) => {
                if (row.ProvjerioHidrol === 1) {
                    return <i className="bi bi-check-circle" style={{ color: 'green', fontSize:'20px' }}></i>;
                } else {
                    return <i className="bi bi-x-circle" style={{ color: 'red', fontSize:'20px'  }}></i>;
                }
            },
            cell: (info) => info.getValue(),

        }
    ]
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
    })

    return (
        <KTCard>
            <ToastContainer />
            <KTCardBody className='py-4'>
                <div className='d-flex justify-content-between'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)} />
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <ExportButton table={table} filename={'zapisnici_obucavanja'} />
                      {/*  <Link to='/uposlenici/efikasnost-obucavanja/dodaj'>
                            <button type='button' className='btn btn-primary'>
                                <KTIcon iconName='plus' className='fs-2' />
                                Dodaj
                            </button>
                        </Link>*/}
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent />
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}
export default ViljuskariZapisiLista;
