/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {GlavnaKnjigaModel, ParamsModelGlavnaKnjiga} from '../../models/GlavnaKnjigaModel'
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {PaginationModel} from '../../models/PaginationModel'
import {deleteGlavnaKnjiga, getGlavnaKnjiga} from '../../api/glavnaKnjigaProizvodnje'
import notifyToast from '../../components/Toast/Toast'
import {TableActionButton} from '../../components/TableActionBtn/TableActionButton'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import {ToastContainer} from 'react-toastify'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import TablePagination from '../../components/TablePagination/TablePagination'
import moments from 'moment'
import KreirajDimenzijeModal from './components/KreirajDimenzijeModal/KreirajDimenzijeModal'
import {Link, useNavigate} from 'react-router-dom'

const GlavnaKnjiga = () => {
  const [data, setData] = useState<GlavnaKnjigaModel[]>([])
  const [loading, setLoading] = useState(true)
  const [pagination, setpagination] = useState<PaginationModel>({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setparams] = useState<ParamsModelGlavnaKnjiga>({per_page: 10, page: 1})
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [isModalOpen, setisModalOpen] = useState(false)
  const [stavkaID, setstavkaID] = useState()
  const updatePage = () => {
    setLoading(true)
    getGlavnaKnjiga(params)
      .then((response) => {
        const data = response.data['data']
        setData(data)
        const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
        setpagination({
          curPage: response.data['current_page'],
          totalPage: totalPage,
          perPage: params.per_page,
        })
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Set cell color according to condtions.
  const getCellColor = (cell, row) => {
    // Color for Tr.roba
    if (cell.column.id === 'Status') return row.original.Aktivan === 1 ? '#008000' : '#FF0000'
    // Color default
    return '#FFFFFF'
  }

  useEffect(() => {
    updatePage()
  }, [params])

  useEffect(() => {
    const {id} = sorting[0] ?? ''
    data.sort((a, b) => a[id] - b[id])
  }, [sorting])

  const columns = [
    {
      id: '#',
      header: '#',
      accessorKey: 'id',
    },
    {
      id: 'Tip',
      header: 'Tip panela',
      accessorKey: 'tip_panela.Naziv',
    },

    {
      id: 'Datum',
      header: 'Datum',
      accessorFn: (row) => `${moments(row.datum).format('DD-MM-YYYY')}`,
    },
    {
      id: 'Proizvedeno',
      header: 'Proizvedeno',
      accessorKey: 'proizvedeno',
    },
    {
      id: 'Skart',
      header: 'Škart',
      accessorKey: 'skart',
    },
    {
      id: 'MetaraPoMinuti',
      header: 'Vrijeme po duznom m',
      accessorKey: 'tip_panela.MetaraPoMinuti',
    },
    {
      id: 'Skart',
      header: 'Škart',
      accessorKey: 'skart',
    },
    {
      id: 'Upisao',
      header: 'Upisao',
      accessorKey: 'uposlenik.UposlenikImePrezime',
    },
    {
      id: 'Smjena',
      header: 'Smjena',
      accessorKey: 'smjena',
    },
    {
      id: 'Zastoj',
      header: 'Zastoj',
      cell: ({row}) =>
        row.original.zastoj === 1 ? (
          <div>
            <span className='text-danger'>DA</span> {row.original.zastojTrajao}
          </div>
        ) : (
          <div className='text-success'>NE</div>
        ),
    },
    {
      id: 'izmjenaAlata',
      header: 'Izmjena Alata',
      accessorKey: 'izmjenaAlata',
    },
    {
      id: 'dimenzije',
      header: 'dimenzije',
      cell: ({row}) => (
        <button
          className='btn btn-primary'
          onClick={async () => {
            await setstavkaID(row.original.id)
            setisModalOpen(true)
          }}
        >
          Kreiraj dimenzije
        </button>
      ),
    },
    {
      header: 'Akcija',
      accessorKey: 'Akcija',
      cell: ({row}) => (
        <TableActionButton
          onDelete={() => onDelete(row.original.id)}
          onEdit={() => {
            onEdit(row.original.id)
          }}
        />
      ),
    },
  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })
  const onDelete = (id) => {
    deleteGlavnaKnjiga(id)
      .then((response) => {
        notifyToast(true, response.message)
        setparams({per_page: pagination.perPage, page: pagination.curPage})
      })
      .catch((error) => {
        notifyToast(false, error.data['message'])
      })
  }
  const navigate = useNavigate()

  const onEdit = (rowData) => {
    navigate(`/paneli/glavna-knjiga/izmjena/${rowData}`)
  }

  return (
    <KTCard>
      <ToastContainer />
      {stavkaID && (
        <KreirajDimenzijeModal
          isModalOpen={isModalOpen}
          setisModalOpen={setisModalOpen}
          stavkaID={stavkaID}
        />
      )}
      <KTCardBody className='py-4'>
        <div className='d-flex justify-content-between'>
          <div data-kt-user-table-toolbar='base'>
            <TableSearch onSearch={(text) => setGlobalFilter(text)} />
          </div>
          <div data-kt-user-table-toolbar='base'>
            <ExportButton table={table} filename={'Glavna_knjiga_proizvodnje'} />
            <Link to='dodaj'>
              <button type='button' className='btn btn-primary'>
                <KTIcon iconName='plus' className='fs-2' />
                Dodaj
              </button>
            </Link>
          </div>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3 '>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className='text-white'
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* @ts-ignore */}
                        {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <TablePagination
          updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
          pagination={pagination}
        />
      </KTCardBody>
    </KTCard>
  )
}

export default GlavnaKnjiga
