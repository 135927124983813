import {useLocation} from 'react-router-dom'

function TitleComponent({name}) {
  const location = useLocation()
  let actionWord
  if (location.pathname.includes('izmjena')) {
    actionWord = 'Izmjena'
  } else if (location.pathname.includes('dodaj')) {
    actionWord = 'Dodaj'
  } else if (location.pathname.includes('lista')) {
    actionWord = 'Lista'
  } else {
    actionWord = ''
  }

  const title = `${actionWord} ${name}`

  return <h3 className='fw-bolder m-0'>{title}</h3>
}

export default TitleComponent
