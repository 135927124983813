import React, {useState} from 'react'
import {Modal, ModalHeader} from 'react-bootstrap'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import {KreirajPakete} from '../../../../api/proizvodnja'
import notifyToast from '../../../../components/Toast/Toast'

const KreirajPaketeModal = ({isModalOpen, setisModalOpen, idProizvodnje}) => {
  const [loading, setLoading] = useState(false)
  const [prikolice, setprikolice] = useState<string>('')
  const [niz, setniz] = useState<string>('')

  const handleOnBtnClick = () => {
    setLoading(true)
    const idList = idProizvodnje.map((item) => item.original.id)
    const data = {
      idProizvodnje: idList[0], //value from checkbox
      prikolica: prikolice, //
      paketNiz: niz ? niz.split(',') : [],
    }
    KreirajPakete(data)
      .then((response) => {
        notifyToast(true, response.message)
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error.response.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <Modal className='modal-lg' show={isModalOpen} onHide={() => setisModalOpen(false)}>
      <ModalHeader closeButton={true} className='h3'>
        Izaberite tip pakovanja
      </ModalHeader>

      {loading ? (
        <LoadingComponent />
      ) : (
        <div className='p-10'>
          <div className='col'>
            <label className='col-lg-8 col-form-label fw-bold fs-6'>
              <span>Prije formiranja paketa,potrebno je izabrati visinu prikolice</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <select
                className='form-control form-control-lg form-control-solid'
                value={prikolice}
                onChange={(e) => setprikolice(e.target.value)}
                disabled={niz.length > 0}
              >
                <option value=''>Odaberi...</option>
                <option value='1'> Pr - H = 2700</option>
                <option value='2'> Pr - H = 3000</option>
              </select>
            </div>
          </div>
          <div className='col-4'>
            <label className='col-lg-8 col-form-label fw-bold fs-6'>
              <span>Niz</span>
            </label>
            <input
              type='text'
              placeholder='Niz'
              className='form-control form-control-lg form-control-solid'
              value={niz}
              onChange={(e) => setniz(e.target.value)}
              disabled={prikolice.length > 0}
            />
          </div>
          <div className='py-5'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={loading || (!prikolice && !niz)}
              onClick={handleOnBtnClick}
            >
              {!loading && 'Potvrdi'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default KreirajPaketeModal
