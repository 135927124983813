/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {KTCard, KTCardBody} from '../../../../_websoft/helpers'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../../components/TitleComponent/TitleComponent'
import {getFmkNalogaStavkaData, spremiFmkNalog} from '../../../api/fmkNalozi'
import {FmkNalogiStavkeModel} from '../../../models/FmkNaloziModel'
import notifyToast from '../../../components/Toast/Toast'
import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import IndeterminateCheckbox from '../../../components/IndeterminateCheckbox/IndeterminateCheckbox'
import KcerkeSifreDropdown from '../../fmk-nalozi/components/KcerkeSifreDropdown'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
import QrCodeScanner from '../../../components/QrCodeScanner/QrCodeScanner'

const SpremiNalog = () => {
  const [nalog, setNalog] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [dataNaloga, setDataNaloga] = useState<FmkNalogiStavkeModel['nalog'][]>([])
  const [dataStavka, setDataStavka] = useState<FmkNalogiStavkeModel['stavka']>([])
  const [rowSelection, setRowSelection] = useState({})

  // Call api on entering order no.
  const onBtnClick = () => {
    setLoading(true)
    getFmkNalogaStavkaData({nalog})
      .then((response) => {
        console.log({response})
        if (response.nalog) {
          const naloga: any = []
          naloga.push(response.nalog)
          setDataNaloga(naloga)
          setDataStavka(response.stavka)
        }
      })
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => setLoading(false))
  }

  // Set cell color according to condtions.
  const getCellColor = (cell, row) => {
    // Color
    if (cell.column.id === 'Status') {
      return row.original.statusi.Boja
    }
    // Color default
    return '#FFFFFF'
  }

  // Rows where dropdown is displayed are valid rows for update.
  const [validRows, setvalidRows] = useState<any>([])

  // on Potvrdi
  const onClickPotvrdi = () => {
    setLoading(true)
    // Payload for api.
    const payload = table2.getSelectedRowModel().rows.map((row) => {
      const item = row.original
      const dataSet = {
        Id: item.Id,
        WebsoftSifra: item.ArtikalWebsoft ?? '',
      }
      return dataSet
    })
    spremiFmkNalog(payload)
      .then((response) => {
        notifyToast(true, response.message)
        onBtnClick()
      })
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => {
        setLoading(false)
        setRowSelection({})
      })
  }
  const columns1 = [
    {
      id: 'Kupac',
      header: 'Kupac',
      accessorKey: 'Kupac',
    },

    {
      id: 'Kreirao',
      header: 'Nalog Kreirao',
      accessorKey: 'Korisnik',
    },
    {
      id: 'Nalog br',
      header: 'Nalog br',
      accessorKey: 'BrojNaloga',
    },
  ]
  const table1 = useReactTable({
    data: dataNaloga,
    columns: columns1,
    getCoreRowModel: getCoreRowModel(),
  })

  const columns2 = [
    {
      id: 'id',
      header: ({table}) => (
        <div className='row p-2'>
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      cell: ({row, getValue}) => (
        <div className='col'>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    {
      id: 'Šifra',
      header: 'Šifra',
      accessorKey: 'IdArtikla',
    },
    {
      id: 'ArtikalWebsoft',
      header: 'Artikal Websoft',
      cell: TableCell,
    },
    {
      id: 'Naziv',
      header: 'Naziv',
      accessorKey: 'Naziv',
    },

    {
      id: 'Mjera',
      header: 'JM',
      accessorKey: 'IdJedMjere',
    },
    {
      id: 'Kolicina',
      header: 'Količina',
      accessorKey: 'Kolicina',
    },
    {
      id: 'Status',
      header: 'Status',
      accessorKey: 'statusi.Naziv',
    },
  ]
  const table2 = useReactTable({
    data: dataStavka,
    columns: columns2,
    // @ts-ignore
    initialState: {hiddenColumns: ['ArtikalWebsoft']},
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: rowSelection,
    },
    meta: {
      updateData: (rowIndex: number, columnId: string, value: string) => {
        setDataStavka((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          })
        )
      },
    },
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
  })

  return (
    <KTCard>
      <ToastContainer />

      <KTCardBody className='py-4'>
        <TitleComponent name='Spremi Nalog' />
        <div className='row'>
          {/* begin: BrojNaloga */}
          <div className='col-1 align-self-center mt-5'>
            <QrCodeScanner
              onScan={(text) => {
                console.log({text})
                setNalog(text)
              }}
            />
          </div>
          <div className='col-lg-2 col-md-4 col-6 mb-6'>
            <label className='col-lg-6 col-form-label required fw-bold fs-6'>Broj Nalog</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={nalog}
              onChange={(e) => setNalog(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && onBtnClick()}
            />
          </div>
          <div className='col-4 align-self-center mt-5'>
            <button className='btn btn-primary' onClick={onBtnClick} disabled={loading}>
              Potvrdi
            </button>
          </div>
          {/* end: BrojNaloga */}
        </div>
        <hr />
        <div className='p-5'>
          {loading ? (
            <LoadingComponent />
          ) : (
            <div className='table-responsive mt-3'>
              <table className='table align-middle table-bordered gy-5 no-footer'>
                <thead>
                  {table1.getHeaderGroups().map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-success p-2'
                    >
                      {headerGroup.headers.map((header) => (
                        <th
                          className='text-white'
                          key={header.id}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {/* @ts-ignore */}
                          {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className='text-gray-600'>
                  {table1.getRowModel().rows && table1.getRowModel().rows.length > 0 ? (
                    table1.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          {loading ? (
            <LoadingComponent />
          ) : (
            <div className='table-responsive mt-3 '>
              <table className='table align-middle table-bordered gy-5 no-footer'>
                <thead>
                  {table2.getHeaderGroups().map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                    >
                      {headerGroup.headers.map((header) => (
                        <th
                          className='text-white'
                          key={header.id}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {/* @ts-ignore */}
                          {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className='text-gray-600'>
                  {table2.getRowModel().rows && table2.getRowModel().rows.length > 0 ? (
                    table2.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={loading}
                  onClick={onClickPotvrdi}
                >
                  {!loading && 'Potvrdi'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export default SpremiNalog

const TableCell = ({getValue, row, column, table}) => {
  const initialValue = getValue()
  const [value, setValue] = useState(initialValue)
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    table.options.meta?.updateData(row.index, column.id, value)
  }, [value])

  return (
    <KcerkeSifreDropdown
      sifra={row.original.IdArtikla}
      value={value}
      onChange={(name, value) => setValue(value)}
    />
  )
}
