import axios from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {PaketLezarina} from "../models/PaketLezarina";

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/paneli/panelLezarinaPaketa`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}


export async function getAllLezarinaPaketa(pageNo: number, perPage: number) {
    const params = {per_page: perPage, page: pageNo}
    return await axios.get<Array<PaketLezarina>>(API, {headers: headers, params: params})
}

export async function getLezarinaPaketaZaKupcaNaDatum(kupacId, datum){
    try {
        const response = await axios.get(`${API_URL}/paneli/getLezarinaPaketaZaKupcaNaDatum?kupacId=${kupacId}&datum=${datum}`)
        if (response.data && response.data.error === 0) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}