interface BarCodeLoginProps {
  closeModal: () => void
  inputRef: React.RefObject<HTMLInputElement>
  barcode: string
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>
  ) => void
  loading: boolean
  handleSubmit: Function
}
const BarCodeLogin = ({
  closeModal,
  inputRef,
  barcode,
  handleInputChange,
  loading,
  handleSubmit,
}) => {
  return (
    <div className='modal-content'>
      <div className='modal-header'>
        <h5 className='modal-title'>Barcode Login</h5>
        <button type='button' className='btn-close' onClick={closeModal}></button>
      </div>
      <div className='modal-body'>
        <input
          type='text'
          className='form-control'
          placeholder='Enter Barcode'
          ref={inputRef}
          value={barcode}
          onChange={handleInputChange}
          onKeyDown={handleInputChange}
        />
      </div>
      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button
          type='button'
          className='btn btn-primary'
          disabled={!barcode || loading}
          onClick={handleSubmit}
        >
          {!loading && 'Potvrdi'}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export default BarCodeLogin
