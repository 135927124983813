import axios, {AxiosResponse} from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {ParamsRepromaterijaliGreske, RepromaterijaliGreske} from "../models/RepromaterijaliGreske";
import {ParamsReklamacijiRolneModel, ReklamacijeRolni} from "../models/ReklamacijeRolni";
import {RepromaterijalIzmjena, RepromaterijalUlazi, RepromaterijalUlaziUpdate} from "../models/RepromaterijaliModel";

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/repromaterijali/repromaterijalUlaz`
const API_DROPDOWN = `${API_URL}/repromaterijali/ulazDropdown`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getAllUlaziRepromaterijala(params: ParamsRepromaterijaliGreske) {
    return await axios.get<Array<RepromaterijalUlazi>>(API, {headers: headers, params: params})
}

export async function getUlazDropdown() {
    try {
        return await axios.get<Array<Option>>(API_DROPDOWN, {headers: headers})
    } catch (error) {
        console.error('Error fetching Ulaz Dropdown data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}


export async function saveUlaz(Data: any) {
    try {
        const response = await axios.post(API, Data, {headers: headers})
        if (response.data.error === 0) {
            return response.data
        } else {
            return new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        return error
    }
}

export async function fetchUlaz(Id: number): Promise<Partial<RepromaterijalUlazi>> {
    try {
        const response = await axios.get(`${API}/${Id}`)
        if (response.data && response.data.error === 0) {
            return response.data.data
        } else {
            return response.data.message || 'Failed to fetch.'
        }
    } catch (error: any) {
        return error
    }
}

export async function updateUlaz(Data: any, id: number) {
    try {
        const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
        if (response.data.error === 0) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            return response.data.message || 'Failed to fetch.'
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        return error
    }
}

export async function deleteUlaz(Id: number) {
    try {
        const response = await axios.delete(`${API}/${Id}`, {headers: headers})
        if (response.data) {
            return response
        } else {
            // Handle any other type of response structure or errors returned from the server here
            return response.data.message || 'Failed to fetch.'
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        return error
    }
}

export async function updateUlazRepromaterijala(
    Data: Partial<RepromaterijalUlaziUpdate>
): Promise<any> {
    try {
        const response = await axios.put(`${API_URL}/updateUlaz`, Data, {
            headers: headers,
        })

        if (response.data && response.data.error === 0) {
            return response.data
        }
    } catch (error) {
        console.error('Error updating Repromaterijal data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}