/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {SkartPaketiModel} from '../../models/SkartPaketiModel'
import {fetchSkartPaketi, saveSkartPaketi, updateSkartPaketi} from '../../api/skartPaketi'
import notifyToast from '../../components/Toast/Toast'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {ToastContainer} from 'react-toastify'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import clsx from 'clsx'

const DrugaKlasaPaketiDetails = () => {
  const {id} = useParams()
  const IdFromURL = Number(id)

  const [loading, setLoading] = useState(true)

  const initialValues = {
    limitKomada: undefined,
    lokacija: undefined,
    komentar: undefined,
    klasa: undefined,
    trenutnoKomada: undefined,
  }
  const [initialData, setInitialData] = useState<Partial<SkartPaketiModel>>(initialValues)

  useEffect(() => {
    if (IdFromURL) {
      fetchSkartPaketi(IdFromURL)
        .then((data) => {
          setInitialData(data)
          formik.resetForm({values: data}) // <-- reset formik state here
        })
        .catch((error) => notifyToast(false, error.response.data.message))
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [IdFromURL])

  const validationSchema = Yup.object({
    limitKomada: Yup.number().integer().required('required'),
    lokacija: Yup.string().required('required'),
    komentar: Yup.string().notRequired(),
    klasa: Yup.string().required('required'),
    trenutnoKomada: Yup.number().notRequired(),
  })

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      try {
        let response
        if (IdFromURL) {
          response = await updateSkartPaketi(IdFromURL, values)
          setTimeout(() => {
            navigate(-1)
          }, 3000)
        } else {
          response = await saveSkartPaketi(values)
          setTimeout(() => {
            navigate(-1)
          }, 3000)
        }

        if (response.error === 0) {
          notifyToast(true, response.message)
        } else {
          notifyToast(false, response.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <ToastContainer />
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <TitleComponent name='Druga Klasa Paketi' />
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        {loading ? (
          <LoadingComponent />
        ) : (
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className='card-body border-top p-9 row'>
              {/* limitKomada */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>Limit (Kom)</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Limit Komada'
                    {...formik.getFieldProps('limitKomada')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.limitKomada && formik.errors.limitKomada},
                      {
                        'is-valid': formik.touched.limitKomada && !formik.errors.limitKomada,
                      }
                    )}
                  />
                  {formik.touched.limitKomada && formik.errors.limitKomada && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.limitKomada}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* lokacija */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>Lokacija</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='text'
                    placeholder='Lokacija'
                    {...formik.getFieldProps('lokacija')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.lokacija && formik.errors.lokacija},
                      {
                        'is-valid': formik.touched.lokacija && !formik.errors.lokacija,
                      }
                    )}
                  />
                  {formik.touched.lokacija && formik.errors.lokacija && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.lokacija}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* klasa */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-8 col-form-label fw-bold fs-6'>
                  <span className='required'>Klasa</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    {...formik.getFieldProps('klasa')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.klasa && formik.errors.klasa,
                      },
                      {
                        'is-valid': formik.touched.klasa && !formik.errors.klasa,
                      }
                    )}
                  >
                    <option value=''>Odaberi...</option>
                    <option value='2/1'>2/1 Druga klasa, najbolji paneli</option>
                    <option value='2/2'>2/2 Druga klasa, srednja vrijednost panela</option>
                    <option value='2/3'>2/3 Druga klasa, paneli niže vrijednosti</option>
                    <option value='1/1'>1/1 Prva klasa, višak proizvodnje</option>
                    <option value='1/2'>1/2 Prva klasa, neznatna oštećenja</option>
                  </select>
                  {formik.touched.klasa && formik.errors.klasa && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.klasa}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* komentar */}
              <div className='col-lg-8 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label  fw-bold fs-6'>Komentar</label>
                <div className='col fv-row'>
                  <textarea
                    placeholder='komentar'
                    {...formik.getFieldProps('komentar')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.komentar && formik.errors.komentar},
                      {
                        'is-valid': formik.touched.komentar && !formik.errors.komentar,
                      }
                    )}
                  />
                  {formik.touched.komentar && formik.errors.komentar && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.komentar}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* trenutnoKomada */}
              {IdFromURL ? (
                <div className='col-lg-4 col-md-6 mb-6'>
                  <label className='col-lg-6 col-form-label  fw-bold fs-6'>Trenutno Komada</label>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      placeholder='Trenutno Komada'
                      {...formik.getFieldProps('trenutnoKomada')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid':
                            formik.touched.trenutnoKomada && formik.errors.trenutnoKomada,
                        },
                        {
                          'is-valid':
                            formik.touched.trenutnoKomada && !formik.errors.trenutnoKomada,
                        }
                      )}
                    />
                    {formik.touched.trenutnoKomada && formik.errors.trenutnoKomada && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.trenutnoKomada}</div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/* End */}
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={loading || formik.isSubmitting || !formik.isValid}
              >
                {!loading && 'Potvrdi'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default DrugaKlasaPaketiDetails
