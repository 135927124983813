import React, {useState, useEffect} from 'react'
import SearchableSelect from '../SearchableSelect/SearchableSelect'
import {getBojeDropdown} from '../../api/boje'
import {Option} from '../../models/BojaModel'
import {getOdjel} from "../../api";

// Option type as per the API response

// SektorDropdownProps for the component
interface DropdownProps {
    value?: string | number
    initialValue?: string | number
    onChange?: (name: string, value: any) => void
    errors?: string | undefined
    touched?: boolean
    selectName?: string
}

const mapDropdown = (data) => {
    return data.map((item) => ({label: item.Naziv, value: item.Id.toString()}))
}

const OdjeliDropdown: React.FC<DropdownProps> = ({
                                                   value,
                                                   initialValue,
                                                   onChange,
                                                   errors,
                                                   touched,
                                                   selectName,
                                               }) => {
    const [odjelDropdown, setOdjelDropdown] = useState<Array<Option>>([])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [internalValue, setInternalValue] = useState<string | number | null>(initialValue ?? null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getOdjel()
                setOdjelDropdown(mapDropdown(response.data))
            } catch (error) {
                console.error('Error fetching sektor dropdown data:', error)
            }
        }
        fetchData()
    }, [])

    const finalValue = typeof value !== 'undefined' ? value : internalValue

    return (
        <SearchableSelect
            options={odjelDropdown}
            value={finalValue?.toString() ?? ''}
            onChange={onChange!}
            name={selectName ?? 'Odjel'}
            errors={errors}
            placeholder='Odaberi odjel...'
            touched={touched}
        />
    )
}

export default OdjeliDropdown
