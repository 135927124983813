/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import SearchableSelect from '../SearchableSelect/SearchableSelect'
import {Option} from '../../models/Sektor'
import {getKategorijeDropdown} from "../../api/ProdajaNajam";

interface DropdownProps {
    value?: string | number
    initialValue?: string | number
    onChange?: (name: string, value: any) => void
    errors?: string | undefined
    touched?: boolean
    selectName?: string
}

const KategorijeProdajeNajam: React.FC<DropdownProps> = ({
                                                                value,
                                                                initialValue,
                                                                onChange,
                                                                errors,
                                                                touched,
                                                                selectName,
                                                            }) => {
    const [data, setData] = useState<Array<Option>>([])
    const internalValue = initialValue ?? null

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getKategorijeDropdown()
                setData(mapData(response.data))
            } catch (error) {
                console.error('Error fetching dropdown data:', error)
            }
        }
        fetchData()
    }, [])

    const finalValue = value ?? internalValue

    const mapData = (data) => {
        if (!data) {
            return []
        }
        return data.map((item) => ({label: item.Naziv, value: item.Id.toString()}))
    }

    return (
        <SearchableSelect
            options={data}
            value={finalValue?.toString() ?? ''}
            onChange={onChange!}
            name={selectName ?? 'data'}
            errors={errors}
            placeholder='Odaberi Kategoriju.'
            touched={touched}
        />
    )
}

export default KategorijeProdajeNajam

