/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import useDebounce from '../../hooks/useDebounce'

interface MinMaxModel {
  min?: number
  max?: number
}
const MinMaxFilter = ({minMaxValues}) => {
  const [initialData, setinitalData] = useState<MinMaxModel>({min: undefined, max: undefined})

  const debouncedRolneId = useDebounce(initialData)
  useEffect(() => {
    minMaxValues(initialData.min, initialData.max)
  }, [debouncedRolneId])

  return (
    <div className='d-flex'>
      <input
        type='number'
        placeholder='Min'
        className={clsx('w-50px ')}
        onChange={(e) => {
          setinitalData({...initialData, min: parseFloat(e.target.value)})
        }}
      />

      <input
        type='number'
        placeholder='Max'
        className={clsx('w-50px')}
        onChange={(e) => {
          setinitalData({...initialData, max: parseFloat(e.target.value)})
        }}
      />
    </div>
  )
}

export default MinMaxFilter
