import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {RolneUgovori} from "../../models/RolneUgovori";
import {
    fetchRepromaterijaliUgovori,
    saveRepromaterijaliUgovori,
    updateRepromaterijaliUgovori
} from "../../api/rolneUgovori";
import * as Yup from "yup";
import {useFormik} from "formik";
import notifyToast from "../../components/Toast/Toast";
import {ToastContainer} from "react-toastify";
import TitleComponent from "../../components/TitleComponent/TitleComponent";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import DobavljacPartnerDropdown from "../../components/DobavljacPartnerDropdown/DobavljacPartnerDropdown";
import clsx from "clsx";
import GarancijaRepromaterijalaDropdown
    from "../../components/GarancijaRepromaterijalaDropdown/GarancijaRepromaterijalaDropdown";
import KategorijaRepromaterijalaDropdown
    from "../../components/KategorijaRepromaterijalaDropdown/KategorijaRepromaterijalaDropdown";
import VrstaMaterijalaDropdown from "../../components/VrstaMaterijalaDropdown/VrstaMaterijalaDropdown";
import {PaneliKontrolaKvalitetaModel} from "../../models/PaneliKontrolaKvalitetaModel";
import {
    fetchPaneliKontrolaKvaliteta,
    savePaneliKontrolaKvaliteta,
    updatePaneliKontrolaKvaliteta
} from "../../api/paneliKontrolaKvaliteta";
import UposlenikDropdown from "../../components/UposlenikDropdown/UposlenikDropdown";
import SmjenePanelaDropdown from "./components/SmjenePanelaDropdown/SmjenePanelaDropdown";


const KontrolaKvalitetaDetails: React.FC = () => {

    const {id} = useParams()
    const IdFromURL = Number(id)

    const [loading, setLoading] = useState(true)

    const initialValues = {
        UzorakNaDuznomM: ' ',
        UzorakNaredio: ' ',
        BrojNaloga: ' ',
        Stavka: ' ',
        Godina: '24',
        GustocaUzorka1: ' ',
        GustocaUzorka2: ' ',
        GustocaUzorka3: ' ',
        A8GustinaPjene: ' ',
        A1ZateznaCvrstocaU1: ' ',
        A1ZateznaCvrstocaU2: ' ',
        A1ZateznaCvrstocaU3: ' ',
        A1ZatezniModulEcc: ' ',
        A2PritisnaCvrstocaUzorak1: ' ',
        A2PritisnaCvrstocaUzorak2: ' ',
        A2PritisnaCvrstocaUzorak3: ' ',
        A2ModulEct: ' ',
        A3CvrstocaSmicanja1: ' ',
        A3CvrstocaSmicanja2: ' ',
        A3CvrstocaSmicanja3: ' ',
        A3ModulSmicanja: ' ',
        A4CvrstocaNaSmicanje: ' ',
        A4ModulSmicanja: ' ',
        A5NaponGuzvanja: ' ',
        A10Lamda: ' ',
        A10GustocaUzorka: ' ',
        TestA1Zadovoljava: ' ',
        TestA2Zadovoljava: ' ',
        TestA3Zadovoljava: ' ',
        TestA4Zadovoljava: ' ',
        TestA5Zadovoljava: ' ',
        TestA10Zadovoljava: ' ',
        Komentar: ' ',
        UzorakZadovoljava: ' ',
        RadilaSmjena: ' ',
        UposlenikId: ' '
    }

    const [initialData, setInitialData] = useState<Partial<PaneliKontrolaKvalitetaModel>>(initialValues)

    useEffect(() => {
        if (IdFromURL) {
            setLoading(true)
            fetchPaneliKontrolaKvaliteta(IdFromURL)
                .then((data) => {
                    setInitialData(data)
                    formik.resetForm({values: data}) // <-- reset formik state here
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [IdFromURL])

    const validationSchema = Yup.object({
        BrojNaloga: Yup.string().min(5, 'Must be at least 5 characters').required('Required'),
        Stavka: Yup.number().required('Required'),
    })
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: initialData,
        //validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                let response
                if (IdFromURL) {
                    response = await updatePaneliKontrolaKvaliteta(values, IdFromURL)
                    setTimeout(() => {
                        navigate(-1)
                    }, 3000)

                } else {
                    response = await savePaneliKontrolaKvaliteta(values)
                    setTimeout(() => {
                        navigate(-1)
                    }, 3000)
                }
                const responseData = response
                if (responseData.error === 0) {
                    notifyToast(true, responseData.message)
                } else {
                    notifyToast(false, responseData.message || 'An unexpected error occurred.')
                }
            } catch (error) {
                console.error('Error: ', error)
                notifyToast(false, 'Error')
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        },
    })
    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer/>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name=''/>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body border-top p-9'>
                            <div className='row mb-6'>
                                <div className='col-lg-3 fv-row'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                        <span className='required'>Uzorak uzet na dužnom metru</span>
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Uzorak Na Duznom M'
                                        {...formik.getFieldProps('UzorakNaDuznomM')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.UzorakNaDuznomM && formik.errors.UzorakNaDuznomM},
                                            {
                                                'is-valid': formik.touched.UzorakNaDuznomM && !formik.errors.UzorakNaDuznomM,
                                            }
                                        )}
                                    />
                                </div>
                                <div className='col-lg-3 fv-row'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                        <span className='required'>Uposlenik zahtjeva uzorak</span>
                                    </label>
                                    <UposlenikDropdown
                                        value={formik.values.UzorakNaredio}
                                        onChange={(name, value) => formik.setFieldValue('UzorakNaredio', value)}
                                        errors={formik.errors.UzorakNaredio}
                                        touched={formik.touched.UzorakNaredio}
                                        selectName='Uposlenik'
                                    />
                                </div>
                            </div>
                            <>
                                {!IdFromURL && (
                                    <div className='row mb-6'>
                                        <div className='col-lg-3 fv-row'>
                                            <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                                <span className='required'>Broj naloga (proizvodnja)</span>
                                            </label>
                                            <input
                                                type='text'
                                                placeholder='Broj Naloga'
                                                {...formik.getFieldProps('BrojNaloga')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {'is-invalid': formik.touched.BrojNaloga && formik.errors.BrojNaloga},
                                                    {
                                                        'is-valid': formik.touched.BrojNaloga && !formik.errors.BrojNaloga,
                                                    }
                                                )}
                                            />
                                            {formik.touched.BrojNaloga && formik.errors.BrojNaloga && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.BrojNaloga}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='col-lg-2 fv-row'>
                                            <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                                <span className='required'>Godina</span>
                                            </label>
                                            <input
                                                type='text'
                                                placeholder='Godina'
                                                {...formik.getFieldProps('Godina')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {'is-invalid': formik.touched.Godina && formik.errors.Godina},
                                                    {
                                                        'is-valid': formik.touched.Godina && !formik.errors.Godina,
                                                    }
                                                )}
                                            />
                                        </div>
                                        <div className='col-lg-2 fv-row'>
                                            <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                                <span className='required'>Stavka</span>
                                            </label>
                                            <input
                                                type='text'
                                                placeholder='Stavka'
                                                {...formik.getFieldProps('Stavka')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {'is-invalid': formik.touched.Stavka && formik.errors.Stavka},
                                                    {
                                                        'is-valid': formik.touched.Stavka && !formik.errors.Stavka,
                                                    }
                                                )}
                                            />
                                            {formik.touched.Stavka && formik.errors.Stavka && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.Stavka}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                            <div className='separator'></div>
                            <div className='row mb-6'>
                                <div className='col-lg-4 fv-row'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                        <span className=''>Gustoća Uzorka 1</span>
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Masa Uzorka'
                                        {...formik.getFieldProps('GustocaUzorka1')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.GustocaUzorka1 && formik.errors.GustocaUzorka1},
                                            {
                                                'is-valid': formik.touched.GustocaUzorka1 && !formik.errors.GustocaUzorka1,
                                            }
                                        )}
                                    />
                                    {formik.touched.GustocaUzorka1 && formik.errors.GustocaUzorka1 && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.GustocaUzorka1}</div>
                                        </div>
                                    )}
                                </div>
                                <div className='col-lg-4 fv-row'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                        <span className=''>Gustoća Uzorka 2</span>
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Masa Uzorka'
                                        {...formik.getFieldProps('GustocaUzorka2')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.GustocaUzorka2 && formik.errors.GustocaUzorka2},
                                            {
                                                'is-valid': formik.touched.GustocaUzorka2 && !formik.errors.GustocaUzorka2,
                                            }
                                        )}
                                    />
                                    {formik.touched.GustocaUzorka2 && formik.errors.GustocaUzorka2 && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.GustocaUzorka2}</div>
                                        </div>
                                    )}
                                </div>
                                <div className='col-lg-4 fv-row'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                        <span className=''>Gustoća Uzorka 3</span>
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Gustoća Uzorka'
                                        {...formik.getFieldProps('GustocaUzorka3')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.GustocaUzorka3 && formik.errors.GustocaUzorka3},
                                            {
                                                'is-valid': formik.touched.GustocaUzorka3 && !formik.errors.GustocaUzorka3,
                                            }
                                        )}
                                    />
                                    {formik.touched.GustocaUzorka3 && formik.errors.GustocaUzorka3 && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.GustocaUzorka3}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='separator mb-6'></div>
                            <div className='row mb-6'>
                                <div className='col-lg-4'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                        <span className="badge bg-secondary fs-2">Test A1</span>
                                    </label>
                                    <div className='col-lg-12 fv-row'>
                                        <label>Zatezna čvrstoća - uzorak 1 (MPa)</label>
                                        <input
                                            type='text'
                                            placeholder='Zatezna čvrstoća - uzorak 1  (MPa)'
                                            {...formik.getFieldProps('A1ZateznaCvrstocaU1')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A1ZateznaCvrstocaU1 && formik.errors.A1ZateznaCvrstocaU1},
                                                {
                                                    'is-valid': formik.touched.A1ZateznaCvrstocaU1 && !formik.errors.A1ZateznaCvrstocaU1,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-12 mt-5 fv-row'>
                                        <label>Zatezna čvrstoća - uzorak 2 (MPa)</label>
                                        <input
                                            type='text'
                                            placeholder='Zatezna čvrstoća - uzorak 2  (MPa)'
                                            {...formik.getFieldProps('A1ZateznaCvrstocaU2')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A1ZateznaCvrstocaU2 && formik.errors.A1ZateznaCvrstocaU2},
                                                {
                                                    'is-valid': formik.touched.A1ZateznaCvrstocaU2 && !formik.errors.A1ZateznaCvrstocaU2,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-12  mt-5  fv-row'>
                                        <label>Zatezna čvrstoća - uzorak 3 (MPa)</label>
                                        <input
                                            type='text'
                                            placeholder='Zatezna čvrstoća - uzorak 3  (MPa)'
                                            {...formik.getFieldProps('A1ZateznaCvrstocaU3')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A1ZateznaCvrstocaU3 && formik.errors.A1ZateznaCvrstocaU3},
                                                {
                                                    'is-valid': formik.touched.A1ZateznaCvrstocaU3 && !formik.errors.A1ZateznaCvrstocaU3,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-12  mt-5  fv-row'>
                                        <label>Zatezni modul Ecc</label>
                                        <input
                                            type='text'
                                            placeholder='Zatezni modul Ecc'
                                            {...formik.getFieldProps('A1ZatezniModulEcc')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A1ZatezniModulEcc && formik.errors.A1ZatezniModulEcc},
                                                {
                                                    'is-valid': formik.touched.A1ZatezniModulEcc && !formik.errors.A1ZatezniModulEcc,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-12 mt-5 fv-row'>
                                        <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                            <span className=' '>Test A1 zadovoljava</span>
                                        </label>
                                        <div className='col-lg-12 fv-row'>
                                            <select
                                                {...formik.getFieldProps('TestA1Zadovoljava')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {'is-invalid': formik.touched.TestA1Zadovoljava && formik.errors.TestA1Zadovoljava},
                                                    {
                                                        'is-valid': formik.touched.TestA1Zadovoljava && !formik.errors.TestA1Zadovoljava,
                                                    }
                                                )}
                                            >
                                                <option value=''>Odaberi...</option>
                                                <option value='Da'>Da</option>
                                                <option value='Ne'>Ne</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                        <span
                                            className="badge bg-secondary fs-2">Test A2</span>
                                    </label>
                                    <div className='col-lg-12 fv-row'>
                                        <label>Pritisna čvrstoća uzorak 1 (MPa)</label>
                                        <input
                                            type='text'
                                            placeholder='Pritisna čvrstoća  uzorak1  (MPa)'
                                            {...formik.getFieldProps('A2PritisnaCvrstocaUzorak1')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A2PritisnaCvrstocaUzorak1 && formik.errors.A2PritisnaCvrstocaUzorak1},
                                                {
                                                    'is-valid': formik.touched.A2PritisnaCvrstocaUzorak1 && !formik.errors.A2PritisnaCvrstocaUzorak1,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-12  mt-5  fv-row'>
                                        <label>Pritisna čvrstoća uzorak 2 (MPa)</label>
                                        <input
                                            type='text'
                                            placeholder='Pritisna čvrstoća uzorak 2 (MPa)'
                                            {...formik.getFieldProps('A2PritisnaCvrstocaUzorak2')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A2PritisnaCvrstocaUzorak2 && formik.errors.A2PritisnaCvrstocaUzorak2},
                                                {
                                                    'is-valid': formik.touched.A2PritisnaCvrstocaUzorak2 && !formik.errors.A2PritisnaCvrstocaUzorak2,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-12 mt-5 fv-row'>
                                        <label>Pritisna čvrstoća uzorak 3 (MPa)</label>
                                        <input
                                            type='text'
                                            placeholder='Pritisna čvrstoća uzorak 3 (MPa)'
                                            {...formik.getFieldProps('A2PritisnaCvrstocaUzorak3')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A2PritisnaCvrstocaUzorak3 && formik.errors.A2PritisnaCvrstocaUzorak3},
                                                {
                                                    'is-valid': formik.touched.A2PritisnaCvrstocaUzorak3 && !formik.errors.A2PritisnaCvrstocaUzorak3,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-12 mt-5 fv-row'>
                                        <label>Zatezni modul Ect</label>
                                        <input
                                            type='text'
                                            placeholder='Zatezni modul Ect'
                                            {...formik.getFieldProps('A2ModulEct')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A2ModulEct && formik.errors.A2ModulEct},
                                                {
                                                    'is-valid': formik.touched.A2ModulEct && !formik.errors.A2ModulEct,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-12 mt-5 fv-row'>
                                        <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                            <span className=' '>Test A2 zadovoljava</span>
                                        </label>
                                        <div className='col-lg-12 fv-row'>
                                            <select
                                                {...formik.getFieldProps('TestA2Zadovoljava')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {'is-invalid': formik.touched.TestA2Zadovoljava && formik.errors.TestA2Zadovoljava},
                                                    {
                                                        'is-valid': formik.touched.TestA2Zadovoljava && !formik.errors.TestA2Zadovoljava,
                                                    }
                                                )}
                                            >
                                                <option value=''>Odaberi...</option>
                                                <option value='Da'>Da</option>
                                                <option value='Ne'>Ne</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 fv-row'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                        <span className="badge bg-secondary fs-2"> Test A3</span>
                                    </label>
                                    <div className='col-lg-12 fv-row'>
                                        <label>Čvrstoća smicanja 1 fcV (MPa)</label>
                                        <input
                                            type='text'
                                            placeholder='Čvrstoća smicanja fcV (MPa)'
                                            {...formik.getFieldProps('A3CvrstocaSmicanja1')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A3CvrstocaSmicanja1 && formik.errors.A3CvrstocaSmicanja1},
                                                {
                                                    'is-valid': formik.touched.A3CvrstocaSmicanja1 && !formik.errors.A3CvrstocaSmicanja1,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-12  mt-5 fv-row'>
                                        <label>Čvrstoća smicanja 2 fcV (MPa)</label>
                                        <input
                                            type='text'
                                            placeholder='Čvrstoća smicanja fcV (MPa)'
                                            {...formik.getFieldProps('A3CvrstocaSmicanja2')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A3CvrstocaSmicanja2 && formik.errors.A3CvrstocaSmicanja2},
                                                {
                                                    'is-valid': formik.touched.A3CvrstocaSmicanja2 && !formik.errors.A3CvrstocaSmicanja2,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-12 mt-5 fv-row'>
                                        <label>Čvrstoća smicanja 3 fcV (MPa)</label>
                                        <input
                                            type='text'
                                            placeholder='Čvrstoća smicanja fcV (MPa)'
                                            {...formik.getFieldProps('A3CvrstocaSmicanja3')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A3CvrstocaSmicanja3 && formik.errors.A3CvrstocaSmicanja3},
                                                {
                                                    'is-valid': formik.touched.A3CvrstocaSmicanja3 && !formik.errors.A3CvrstocaSmicanja3,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-12 mt-5 fv-row'>
                                        <label>Modul smicanja Gc (Mpa)</label>
                                        <input
                                            type='text'
                                            placeholder='Modul smicanja'
                                            {...formik.getFieldProps('A3ModulSmicanja3')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A3ModulSmicanja && formik.errors.A3ModulSmicanja},
                                                {
                                                    'is-valid': formik.touched.A3ModulSmicanja && !formik.errors.A3ModulSmicanja,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-12 mt-5 fv-row'>
                                        <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                            <span className=' '>Test A3 zadovoljava</span>
                                        </label>
                                        <div className='col-lg-12 fv-row'>
                                            <select
                                                {...formik.getFieldProps('TestA3Zadovoljava')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {'is-invalid': formik.touched.TestA3Zadovoljava && formik.errors.TestA3Zadovoljava},
                                                    {
                                                        'is-valid': formik.touched.TestA3Zadovoljava && !formik.errors.TestA3Zadovoljava,
                                                    }
                                                )}
                                            >
                                                <option value=''>Odaberi...</option>
                                                <option value='Da'>Da</option>
                                                <option value='Ne'>Ne</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="separator"></div>
                            <div className='row mb-6'>
                                <div className='col-lg-4 mb-6'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                            <span
                                                className="badge bg-secondary fs-2"> Test A4</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label>Čvrstoća na smicanje</label>
                                        <input
                                            type='text'
                                            placeholder='vrstoća na smicanje'
                                            {...formik.getFieldProps('A4CvrstocaNaSmicanje')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A4CvrstocaNaSmicanje && formik.errors.A4CvrstocaNaSmicanje},
                                                {
                                                    'is-valid': formik.touched.A4CvrstocaNaSmicanje && !formik.errors.A4CvrstocaNaSmicanje,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-8 mt-5 fv-row'>
                                        <label>Modul smicanja</label>
                                        <input
                                            type='text'
                                            placeholder='Modul smicanja'
                                            {...formik.getFieldProps('A4ModulSmicanja')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A4ModulSmicanja && formik.errors.A4ModulSmicanja},
                                                {
                                                    'is-valid': formik.touched.A4ModulSmicanja && !formik.errors.A4ModulSmicanja,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-8 mt-5 fv-row'>
                                        <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                            <span className=' '>Test A4 zadovoljava</span>
                                        </label>
                                        <div className='col-lg-12 fv-row'>
                                            <select
                                                {...formik.getFieldProps('TestA4Zadovoljava')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {'is-invalid': formik.touched.TestA4Zadovoljava && formik.errors.TestA4Zadovoljava},
                                                    {
                                                        'is-valid': formik.touched.TestA4Zadovoljava && !formik.errors.TestA4Zadovoljava,
                                                    }
                                                )}
                                            >
                                                <option value=''>Odaberi...</option>
                                                <option value='Da'>Da</option>
                                                <option value='Ne'>Ne</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 mt-5 mb-6'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                        <span className="badge bg-secondary fs-2">Test A5 </span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label>Napon gužvanja</label>
                                        <input
                                            type='text'
                                            placeholder='Napon gužvanja'
                                            {...formik.getFieldProps('A5NaponGuzvanja')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A5NaponGuzvanja && formik.errors.A5NaponGuzvanja},
                                                {
                                                    'is-valid': formik.touched.A5NaponGuzvanja && !formik.errors.A5NaponGuzvanja,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-8 mt-5 fv-row'>
                                        <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                            <span className=' '>Test A5 zadovoljava</span>
                                        </label>
                                        <div className='col-lg-12 fv-row'>
                                            <select
                                                {...formik.getFieldProps('TestA5Zadovoljava')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {'is-invalid': formik.touched.TestA5Zadovoljava && formik.errors.TestA5Zadovoljava},
                                                    {
                                                        'is-valid': formik.touched.TestA5Zadovoljava && !formik.errors.TestA5Zadovoljava,
                                                    }
                                                )}
                                            >
                                                <option value=''>Odaberi...</option>
                                                <option value='Da'>Da</option>
                                                <option value='Ne'>Ne</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 mt-5 mb-6'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                        <span className="badge bg-secondary fs-2">Test A10</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <label>λ</label>
                                        <input
                                            type='text'
                                            placeholder='λ'
                                            {...formik.getFieldProps('A10Lamda')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A10Lamda && formik.errors.A10Lamda},
                                                {
                                                    'is-valid': formik.touched.A10Lamda && !formik.errors.A10Lamda,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-6 mt-5 fv-row'>
                                        <label>Gustoća uzorka (kg/m3)</label>
                                        <input
                                            type='text'
                                            placeholder='Gustoća uzorka  (kg/m3)'
                                            {...formik.getFieldProps('A10GustocaUzorka')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.A10GustocaUzorka && formik.errors.A10GustocaUzorka},
                                                {
                                                    'is-valid': formik.touched.A10GustocaUzorka && !formik.errors.A10GustocaUzorka,
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-8 mt-5 fv-row'>
                                        <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                            <span className=' '>Test A10 zadovoljava</span>
                                        </label>
                                        <div className='col-lg-12 fv-row'>
                                            <select
                                                {...formik.getFieldProps('TestA10Zadovoljava')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {'is-invalid': formik.touched.TestA10Zadovoljava && formik.errors.TestA10Zadovoljava},
                                                    {
                                                        'is-valid': formik.touched.TestA10Zadovoljava && !formik.errors.TestA10Zadovoljava,
                                                    }
                                                )}
                                            >
                                                <option value=''>Odaberi...</option>
                                                <option value='Da'>Da</option>
                                                <option value='Ne'>Ne</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='separator'></div>
                            <div className='row mb-6'>
                                <div className='col-lg-3 mt-5 fv-row'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                        <span className=' '>Radila smjena</span>
                                    </label>
                                    <SmjenePanelaDropdown
                                        {...formik.getFieldProps('RadilaSmjena')}
                                        onChange={(name, value) => formik.setFieldValue('RadilaSmjena', value)}
                                    />
                                    {formik.touched.RadilaSmjena && formik.errors.RadilaSmjena && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.RadilaSmjena}</div>
                                        </div>
                                    )}
                                </div>
                                <div className='col-lg-8 mt-5 fv-row'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                    <span className=' '>Komentar</span>
                                    </label>
                                    <div className='col-lg-6 fv-row'>
                                    <textarea
                                        placeholder='Komentar'
                                        {...formik.getFieldProps('Komentar')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.Komentar && formik.errors.Komentar},
                                            {
                                                'is-valid': formik.touched.Komentar && !formik.errors.Komentar,
                                            }
                                        )}
                                    />
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={loading || formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && 'Potvrdi'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                            <span
                                                className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}
export default KontrolaKvalitetaDetails;
