import { Table } from '@tanstack/react-table';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

// Move this outside the exportExcel function
export interface HeaderFormatData {
  Promet1OdPreformatted?: string;
  Promet1DoPreformatted?: string;
  Promet2OdPreformatted?: string;
  Promet2DoPreformatted?: string;
  Promet3OdPreformatted?: string;
  Promet3DoPreformatted?: string;
}

export default async function exportExcel(
    table: Table<any>,
    filename: string,
    applyFilters = true,
    extraData?: HeaderFormatData // This is now recognized
) {

  const wb = new Workbook();
  const ws = wb.addWorksheet('Sheet 1');

  function mapHeaderToString(headerId: string, data?: HeaderFormatData): string {
    switch (headerId) {
      case 'promet1':
        return data?.Promet1OdPreformatted && data?.Promet1DoPreformatted ? `Promet 1 (${data.Promet1OdPreformatted} / ${data.Promet1DoPreformatted})` : 'Promet 1';
      case 'promet2':
        return data?.Promet2OdPreformatted && data?.Promet2DoPreformatted ? `Promet 2 (${data.Promet2OdPreformatted} / ${data.Promet2DoPreformatted})` : 'Promet 2';
      case 'promet3':
        return data?.Promet3OdPreformatted && data?.Promet3DoPreformatted ? `Promet 3 (${data.Promet3OdPreformatted} / ${data.Promet3DoPreformatted})` : 'Promet 3';
      default:
        return headerId;
    }
  }

  const lastHeaderGroup = table.getHeaderGroups().at(-1);
  if (!lastHeaderGroup) {
    console.error('No header groups found', table.getHeaderGroups());
    return;
  }

// Now, you can safely use lastHeaderGroup
  ws.columns = lastHeaderGroup.headers
      .filter((h) => h.column.getIsVisible())
      .map((header) => {
        const headerString = mapHeaderToString(header.id, extraData);
        return {
          header: headerString,
          key: header.id,
          width: 20,
        };
      });
  // Choose rows based on whether filters should be applied
  const exportRows = applyFilters ? table.getFilteredRowModel().rows : table.getCoreRowModel().rows;

  // Add each row's visible cells to the worksheet
  exportRows.forEach((row) => {
    const cells = row.getVisibleCells();
    const values = cells.map((cell) => cell.getValue() ?? '');
    ws.addRow(values);
  });

  // Instead of writing to CSV, write to XLSX buffer
  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), `${filename}.xlsx`);
}
