import clsx from 'clsx'
import {Form, useFormik} from 'formik'
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react'
import useDebounce from '../../../../hooks/useDebounce'
import {sredstvoInfo} from '../../../../api/fmkNalozi'
import notifyToast from '../../../../components/Toast/Toast'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import RadniNaloziOdjeliDropdown from '../../../../components/RadniNaloziOdjeliDropdown/RadniNaloziOdjeliDropdown'
import {startRadnogNaloga} from '../../../../api/radniNalozi'
import {Table} from '../../../../models/Table'
import {FmkNalogiStavkeModel} from '../../../../models/FmkNaloziModel'
import * as Yup from 'yup'
import {useNavigate} from "react-router-dom";
import UposlenikDropdown from "../../../../components/UposlenikDropdown/UposlenikDropdown";
import {KTIcon} from "../../../../../_websoft/helpers";
import {Sredstva} from "../../../../models/Sredstva";

interface Props {
    nalogaId: string
    selectedStavka: FmkNalogiStavkeModel['stavka']
}
interface SredstvoInfoModel {
    SredstvoNaziv:string
}
export type Ref = any
const RadniNalogComponent = forwardRef<Ref, Props>(({nalogaId, selectedStavka}, ref) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [sredstvoInfoData, setsredstvoInfoData] = useState<SredstvoInfoModel | null>(null);

    const initialValues = {
        invbroj: '',
        Odjel: '',
        Izvrsilac: [''], // Initialized with one empty string, indicating one dropdown to start with
    };

    const validationSchema = Yup.object({
        invbroj: Yup.string().required('Inv broj is required'),
        Odjel: Yup.string().required('Odjel is required'),
     });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            saveData(values);
        },
    });

    useImperativeHandle(ref, () => ({
        saveData: () => formik.submitForm(),
    }));

    const saveData = (values) => {
        setLoading(true);
        const payloadData = {
            BrojNaloga: nalogaId,
            MasinaId: values.invbroj,
            OdjelId: values.Odjel,
            stavke: selectedStavka,
            izvrsilac: values.Izvrsilac, // Ensure this matches the API's expected format
        };

        startRadnogNaloga(payloadData)
            .then((response) => {
                notifyToast(true, response.message);
                setTimeout(() => {
                    navigate(-1);
                }, 3000);
            })
            .catch((error) => {
                notifyToast(false, error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const debouncedInv = useDebounce(formik.values.invbroj)
    useEffect(() => {
        if (debouncedInv) {
            sredstvoInfo({invBroj: debouncedInv})
                .then((response) => {
                    setsredstvoInfoData(response!)
                })
                .catch((error) => notifyToast(false, error.response.data.message))
        }
    }, [debouncedInv])

    return (
        <div className='w-100'>
            <div className='pb-10 pb-lg-12'>
                <h2 className='fw-bolder text-dark'>Radni Nalog: {nalogaId}</h2>
            </div>
            {loading ? (
                <LoadingComponent/>
            ) : (
                <Form onSubmit={formik.handleSubmit} noValidate>
                    <div className='row mb-10'>
                        {/* begin: Odjel */}
                        <div className='col-lg-3 col-sm-12'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Odjel</label>
                            <div className='col-lg-8 fv-row'>
                                <RadniNaloziOdjeliDropdown
                                    value={formik.values.Odjel}
                                    onChange={(name, value) => formik.setFieldValue('Odjel', value)}
                                    selectName='Odjel'
                                    errors={formik.values.Odjel} //@ts-ignore
                                    touched={formik.touched.Odjel}
                                />
                            </div>
                        </div>
                        {/* end: Odjel */}
                        <div className='col-lg-3 col-sm-12'>
                            <label className='col-lg-6 col-form-label fw-bold fs-6'>Inv. broj mašine</label>
                            <input
                                type='text'
                                placeholder='Inv Broj'
                                {...formik.getFieldProps('invbroj')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {'is-invalid': formik.touched.invbroj && formik.errors.invbroj},
                                    {
                                        'is-valid': formik.touched.invbroj && !formik.errors.invbroj,
                                    }
                                )}
                            />
                            {formik.touched.invbroj && formik.errors.invbroj && (
                                <div className='fv-plugins-message-container'>
                                    {/* @ts-ignore */}
                                    <div className='fv-help-block'>{formik.errors.invbroj}</div>
                                </div>
                            )}
                        </div>
                        <div className='col-lg-6 col-sm-12 mt-5'>
                            {sredstvoInfoData ? (
                                <label className='form-label border rounded border-success p-2 mt-5'>
                                    {sredstvoInfoData?.SredstvoNaziv}
                                </label>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className='row mt-6'>
                            <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                                Ostali Izvršioci  <code>Voditelj izrade naloga (onaj koji upisuje) ne upisuje sebe, on je automatski upisan</code>
                            </label>
                            <div className='row mb-6'>
                                {Array.from({length: formik.values.Izvrsilac!.length}, (_, i) => (
                                    <div className='row'>
                                        <div className='col-lg-6 fv-row mb-6'>
                                            <UposlenikDropdown
                                                value={formik.values.Izvrsilac![i]}
                                                onChange={(name, value) => {
                                                    var al = formik.values.Izvrsilac ?? []
                                                    al.splice(i, 1, value)
                                                    formik.setFieldValue('Izvrsilac', al)
                                                }}
                                                touched={formik.touched.Izvrsilac}
                                                selectName='Izvrsilac'
                                            />
                                        </div>

                                        <div className='col-lg-2'>
                                            <button
                                                type='button'
                                                className='btn btn-danger'
                                                onClick={() => {
                                                    var al = formik.values.Izvrsilac ?? []
                                                    al.splice(i, 1)
                                                    formik.setFieldValue('Izvrsilac', al)
                                                }}
                                            >
                                                <KTIcon iconName='minus' className='fs-2'/>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <div className='col-lg-2'>
                                    <button
                                        type='button'
                                        className='btn btn-primary'
                                        onClick={() => {
                                            // setIzvrsilacCount(IzvrsilacCount + 1)
                                            var al = [...(formik.values.Izvrsilac ?? [])];
                                            // Add a new empty string to the array to represent a new dropdown
                                            al.push('');
                                            // Update the formik state with the new array
                                            formik.setFieldValue('Izvrsilac', al);
                                        }}
                                    >
                                        <KTIcon iconName='plus' className='fs-2'/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    )
})

export default RadniNalogComponent
