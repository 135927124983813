import React, {FC, forwardRef, useImperativeHandle} from 'react'
import {KTIcon} from '../../../../../_websoft/helpers'
import {ErrorMessage, Field} from 'formik'
import PrometRolne from '../../terminali-components/PrometRolne'
import FmkNalog from '../../terminali-components/FmkNalog'
import {PanelNalogDetaljiModel} from '../../../../models/ThermoPrometRolne'
import notifyToast from '../../../../components/Toast/Toast'
import {saveZapisiProizvodnje} from '../../../../api/zapisiProizvodnje'
import {useNavigate} from 'react-router-dom'
interface Props {
  data: PanelNalogDetaljiModel
  values: {stavka: string; Smjena: string; Masina: string}
}
export type Ref = any
const Step2 = forwardRef<Ref, Props>(({data, values}, ref) => {
  useImperativeHandle(ref, () => ({
    saveData,
  }))
  const navigate = useNavigate()
  const saveData = () => {
    const payload = {
      IdProizvodnje: data.id,
      BrojNaloga: data.brojNaloga,
      Stavka: values.stavka,
      Smjena: values.Smjena,
      Masina: values.Masina,
    }
    saveZapisiProizvodnje(payload)
      .then((response) => {
        notifyToast(true, response.message)
        const id = response.data.Id
        setTimeout(() => {
          if (id) navigate(`/terminali/termopaneli/aktivni-nalozi/${id}`)
        }, 2000)
      })
      .catch((error) => notifyToast(false, error.response.data.message))
  }
  return (
    <div className='w-100'>
      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-3'>
          Nalog
          <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip'></i>
        </label>
      </div>
      <div className='row mt-5'>
        <div className='col-6'>
          <table className='table table-bordered'>
            <tr className='p-4'>
              <th className='p-2'>ID: {data.id}</th>
              <td></td>
            </tr>
            <tr>
              <th className='p-2'>NALOG: {data.brojNaloga}</th>
              <td></td>
            </tr>
            <tr>
              <th className='p-2'>STAVKA: {data.stavka}</th>
              <td></td>
            </tr>
            <tr>
              <th className='p-2'>ARTIKAL: {data.artikal}</th>
              <td></td>
            </tr>
            <tr>
              <th className='p-2'>KUPAC: {data.kupac}</th>
              <td></td>
            </tr>
            <tr>
              <th className='p-2'>KOLIČINA: {data.kolicina}</th>
              <td></td>
            </tr>
            <tr>
              <th className='p-2'>NALOG KREIRAO: {data.kreiraoKorisnik}</th>
              <td></td>
            </tr>
            <tr>
              <th className='p-2'>BOJA GORNJEG LIMA: {data.boja_gore?.BojaNaziv}</th>
              <td></td>
            </tr>
            <tr>
              <th className='p-2'>BOJA DONJEG LIMA: {data.boja_dole?.BojaNaziv}</th>
              <td></td>
            </tr>
            <tr>
              <th className='p-2'>DEBLJINA GORNJEG LIMA: {data.debljina_gore?.debljina}</th>
              <td></td>
            </tr>
            <tr>
              <th className='p-2'>DEBLJINA DONJEG LIMA: {data.debljina_dole?.debljina}</th>
              <td></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  )
})

export {Step2}
