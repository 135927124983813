/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {ArtikliModel} from '../../models/ArtikliModel'
import {fetchArtikli, saveArtikli, updateArtikli} from '../../api'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import notifyToast from '../../components/Toast/Toast'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import clsx from 'clsx'
import RoditeljiDropdown from '../../components/RoditeljiDropdown/RoditeljiDropdown'
import BojaDropdown from '../../components/BojaDropdown/BojaDropdown'
import JmRepromaterijalaDropdown from '../../components/JmRepromaterijalaDropdown/JmRepromaterijalaDropdown'
import ImageComponent from '../../components/ImageComponet/ImageComponent'
import KategorijaAritkliDropdown from '../../components/KategorijaArtikliDropdown/KategorijaArtikliDropdown'
import {ToastContainer} from 'react-toastify'
import PakovanjaDropdown from '../../components/PakovanjaDropdown/PakovanjaDropdown'

const ArtikliDetails: React.FC = () => {
    const {id} = useParams()
    const IdFromURL = Number(id)

    const [loading, setLoading] = useState(true)

    const initialValues = {
        ArtSifra: undefined,
        ArtKategorija: undefined,
        ArtRoditelj: undefined,
        ArtNaziv: undefined,
        ArtBoja: undefined,
        ArtPakovanje: undefined,
        ArtMjera: undefined,
        ArtMinKolic: undefined,
        ArtSlika: undefined,
        ArtOpis: undefined,
        ArtAktivan: undefined,
        ArtPresetorijum: undefined,
        ArtTrgRoba: undefined,
    }
    const [initialData, setInitialData] = useState<Partial<ArtikliModel>>(initialValues)
    const [file, setfile] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        if (IdFromURL) {
            fetchArtikli(IdFromURL)
                .then((data) => {
                    setInitialData(data)
                    formik.resetForm({values: data}) // <-- reset formik state here
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [IdFromURL])

    const validationSchema = Yup.object({
        ArtSifra: Yup.string().required('Required'),
        ArtKategorija: Yup.string().required('Required'),
        ArtBoja: Yup.number().required('Required'),
        ArtMjera: Yup.number().required('Required'),
        ArtAktivan: Yup.number().required('Required'),
        ArtPresetorijum: Yup.number().required('Required'),
        ArtPakovanje: Yup.number().required('Required'),
        ArtTrgRoba: Yup.boolean().required('Required'),
    })
    const getFormData = (values) => {
        const formData = new FormData()
        if (file) {
            formData.append('ArtikalImage', file)
        }
        values.ArtSifra && formData.append('ArtSifra', values.ArtSifra)
        values.ArtKategorija && formData.append('ArtKategorija', values.ArtKategorija)
        values.ArtRoditelj && formData.append('ArtRoditelj', values.ArtRoditelj)
        values.ArtNaziv && formData.append('ArtNaziv', values.ArtNaziv)
        values.ArtBoja && formData.append('ArtBoja', values.ArtBoja)
        values.ArtPakovanje && formData.append('ArtPakovanje', values.ArtPakovanje)
        values.ArtMjera && formData.append('ArtMjera', values.ArtMjera)
        values.ArtMinKolic && formData.append('ArtMinKolic', values.ArtMinKolic)
        values.ArtAktivan && formData.append('ArtAktivan', values.ArtAktivan)
        values.ArtPresetorijum && formData.append('ArtPresetorijum', values.ArtPresetorijum)
        values.ArtOpis && formData.append('ArtOpis', values.ArtOpis)
        values.ArtSlika && formData.append('ArtSlika', values.ArtSlika)
        values.ArtTrgRoba && formData.append('ArtTrgRoba', values.ArtTrgRoba)
        return formData
    }
    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            const formdata = getFormData(values)
            try {
                let response
                if (IdFromURL) {
                    response = await updateArtikli(IdFromURL, formdata)
                } else {
                    response = await saveArtikli(formdata)
                }

                if (response.error === 0) {
                    notifyToast(true, response.message)
                } else {
                    notifyToast(false, response.message || 'An unexpected error occurred.')
                }
            } catch (error: any) {
                console.error('Error: ', error)
                notifyToast(false, error.response.data.message)
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
                setTimeout(() => {
                    navigate(-1)
                }, 1000)
            }
        },
    })
    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer/>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name=''/>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className='card-body border-top p-9 row'>
                            {/* Šifra Artikla */}
                            <div className='col-6 mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                    Šifra Artikla
                                </label>

                                <div className='col-lg-6 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Šifra Artikla'
                                        {...formik.getFieldProps('ArtSifra')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.ArtSifra && formik.errors.ArtSifra},
                                            {
                                                'is-valid': formik.touched.ArtSifra && !formik.errors.ArtSifra,
                                            }
                                        )}
                                    />
                                    {formik.touched.ArtSifra && formik.errors.ArtSifra && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.ArtSifra}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Roditelj */}
                            <div className='col-6 mb-6'>
                                <div className='col'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Roditelj</label>
                                    <div className='col-lg-8 fv-row'>
                                        <RoditeljiDropdown
                                            value={formik.values.ArtRoditelj}
                                            onChange={(name, value) => formik.setFieldValue('ArtRoditelj', value)}
                                            errors={formik.errors.ArtRoditelj}
                                            touched={formik.touched.ArtRoditelj}
                                            selectName='ArtRoditelj'
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Naziv */}
                            <div className='col-6 mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Naziv</label>

                                <div className='col-lg-6 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Naziv'
                                        {...formik.getFieldProps('ArtNaziv')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.ArtNaziv && formik.errors.ArtNaziv},
                                            {
                                                'is-valid': formik.touched.ArtNaziv && !formik.errors.ArtNaziv,
                                            }
                                        )}
                                    />
                                    {formik.touched.ArtNaziv && formik.errors.ArtNaziv && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.ArtNaziv}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* ArtBoja */}
                            <div className='col-6 mb-6'>
                                <div className='col'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Boja</label>
                                    <div className='col-lg-8 fv-row'>
                                        <BojaDropdown
                                            value={formik.values.ArtBoja}
                                            onChange={(name, value) => formik.setFieldValue('ArtBoja', value)}
                                            errors={formik.errors.ArtBoja}
                                            touched={formik.touched.ArtBoja}
                                            selectName='ArtBoja'
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Jedinica Mjere */}
                            <div className='col-6 mb-6'>
                                <div className='col'>
                                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                                        Jedinica Mjere
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <JmRepromaterijalaDropdown
                                            value={formik.values.ArtMjera}
                                            onChange={(name, value) => formik.setFieldValue('ArtMjera', value)}
                                            errors={formik.errors.ArtMjera}
                                            touched={formik.touched.ArtMjera}
                                            selectName='ArtMjera'
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Kategorija */}
                            <div className='col-6 mb-6'>
                                <div className='col'>
                                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                                        Kategorija
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <KategorijaAritkliDropdown
                                            value={formik.values.ArtKategorija}
                                            onChange={(name, value) => formik.setFieldValue('ArtKategorija', value)}
                                            errors={formik.errors.ArtKategorija}
                                            touched={formik.touched.ArtKategorija}
                                            selectName='ArtKategorija'
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Vrsta Pakovanja */}
                            <div className='col-6 mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                    Vrsta Pakovanja
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <PakovanjaDropdown
                                        value={formik.values.ArtPakovanje}
                                        onChange={(name, value) => formik.setFieldValue('ArtPakovanje', value)}
                                        errors={formik.errors.ArtPakovanje}
                                        touched={formik.touched.ArtPakovanje}
                                        selectName='ArtPakovanje'
                                    />
                                </div>
                            </div>
                            {/* Minimalna Kolicina */}
                            <div className='col-6 mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                    Minimalna Količina
                                </label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Minimalna Kolicina'
                                        {...formik.getFieldProps('ArtMinKolic')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.ArtMinKolic && formik.errors.ArtMinKolic},
                                            {
                                                'is-valid': formik.touched.ArtMinKolic && !formik.errors.ArtMinKolic,
                                            }
                                        )}
                                    />
                                    {formik.touched.ArtMinKolic && formik.errors.ArtMinKolic && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.ArtMinKolic}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* Presetorium */}
                            <div className='col-6 mb-6'>
                                <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                                    Galanterija / Koristi se u galanteriji
                                </label>
                                <select
                                    {...formik.getFieldProps('ArtPresetorijum')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {'is-invalid': formik.touched.ArtPresetorijum && formik.errors.ArtPresetorijum},
                                        {
                                            'is-valid': formik.touched.ArtPresetorijum && !formik.errors.ArtPresetorijum,
                                        }
                                    )}
                                >
                                    <option value=''>Odaberi</option>
                                    <option value='0'>Ne</option>
                                    <option value='1'>Da</option>
                                </select>
                                {formik.touched.ArtPresetorijum && formik.errors.ArtPresetorijum && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.ArtPresetorijum}</div>
                                    </div>
                                )}
                            </div>
                            {/* ArtTrgRoba */}
                            <div className='col-6 mb-6'>
                                <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                                    Trgovačka roba
                                </label>
                                <select
                                    {...formik.getFieldProps('ArtTrgRoba')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {'is-invalid': formik.touched.ArtTrgRoba && formik.errors.ArtTrgRoba},
                                        {
                                            'is-valid': formik.touched.ArtTrgRoba && !formik.errors.ArtTrgRoba,
                                        }
                                    )}
                                >
                                    <option value=''>Odaberi</option>
                                    <option value='1'>Da</option>
                                    <option value='0'>Ne</option>
                                </select>
                                {formik.touched.ArtTrgRoba && formik.errors.ArtTrgRoba && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.ArtTrgRoba}</div>
                                    </div>
                                )}
                            </div>

                            {/* Napomena */}
                            <div className='col mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Napomena</label>

                                <div className='col-lg-8 fv-row'>
                  <textarea
                      placeholder='Napomena'
                      {...formik.getFieldProps('ArtOpis')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.ArtOpis && formik.errors.ArtOpis},
                          {
                              'is-valid': formik.touched.ArtOpis && !formik.errors.ArtOpis,
                          }
                      )}
                  />
                                    {formik.touched.ArtOpis && formik.errors.ArtOpis && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.ArtOpis}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* Slika */}
                            <div className='row mb-6'>
                                <div className='col-6 mb-6'>
                                    <ImageComponent
                                        label='Slika artikla'
                                        setFile={(val) => {
                                            setfile(val)
                                        }}
                                        slikaUrl={formik.values.ArtSlika}
                                        isFileValid={(val) => formik.setErrors({ArtSlika: val})}
                                    />
                                </div>
                                {/* Status Artikla */}
                                <div className='col-6 mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                        Status artikla
                                    </label>
                                    <select
                                        {...formik.getFieldProps('ArtAktivan')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.ArtAktivan && formik.errors.ArtAktivan},
                                            {
                                                'is-valid': formik.touched.ArtAktivan && !formik.errors.ArtAktivan,
                                            }
                                        )}
                                    >
                                        <option value=''>Odaberi status...</option>
                                        <option value='0'>Neaktivan</option>
                                        <option value='1'>Aktivan</option>
                                    </select>
                                    {formik.touched.ArtAktivan && formik.errors.ArtAktivan && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.ArtAktivan}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={loading || formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && 'Potvrdi'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                                )}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}
export default ArtikliDetails
