import React, {useMemo} from 'react'
import {PaketiDrugaKlasaQrModel} from '../../models/SkartPaketiModel'
import {Font, Page, View, Image, Text, Document, StyleSheet} from '@react-pdf/renderer'
// Register font
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
})
// Create styles
const styles = StyleSheet.create({
  pdf: {width: '100vh', height: '500px'},
  page: {
    flexDirection: 'column',
    flex: 1,
    height: '300px',
    padding: 10,
  },
  section: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  section2: {
    margin: 2,
    padding: 2,
    flexDirection: 'column',
    justifyContent: 'center',
    border: '2px solid #212121',
  },
  section3: {
    margin: 2,
    padding: 2,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  qrCode: {
    margin: 2,
    padding: 2,
    width: '100px',
  },

  text: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Roboto',
    padding: 5,
  },
  boldtext: {
    fontSize: 20,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  table: {
    marginTop: 20,
    width: '100%',
    paddingHorizontal: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #212121',
    borderRight: '1px solid #212121',
    borderLeft: '1px solid #212121',
  },
  bold: {
    fontWeight: 'bold',
  },
  row1: {
    width: '10%',
    paddingTop: 8,
    paddingBottom: 8,
  },
  row2: {
    width: '40%',
    borderRight: '1px solid #212121',
    borderLeft: '1px solid #212121',
    paddingTop: 8,
    paddingBottom: 8,
  },
  row3: {
    width: '50%',
    paddingTop: 8,
    paddingBottom: 8,
  },
  row4: {
    width: '50%',
    borderRight: '1px solid #212121',
    paddingTop: 8,
    paddingBottom: 8,
  },
  row5: {
    width: '50%',
    paddingTop: 8,
    paddingBottom: 8,
  },
  bottom: {
    borderBottom: '1px solid #212121',
  },
  alignEnd: {
    alignSelf: 'flex-end',
  },
})
interface Props {
  qrData: PaketiDrugaKlasaQrModel
  qrList: Array<string>
}
const DrugaKlasaPakingListePdf: React.FC<Props> = ({qrData, qrList}) => {
  const data = useMemo(() => ({qrData}), [qrData])

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Document>
      {data.qrData && (
        <Page size='A5' style={styles.page} orientation='landscape'>
          <View style={styles.section}>
            <View style={styles.section2}>
              <Text style={styles.boldtext}>[PAKING LISTA]</Text>
              <Text style={[styles.boldtext, styles.alignEnd]}>
                PAKET- {qrData.details[0].paketId}
              </Text>
              <Text style={[styles.boldtext, styles.alignEnd]}>
                KLASA- {qrData.details[0].klasa}
              </Text>
            </View>
            {/* @ts-ignore */}
            <Image style={styles.qrCode} source={{uri: qrList[0]}} />
          </View>
          {/* Table */}
          <View style={styles.table}>
            <View style={[styles.row, styles.bold]}>
              <Text style={[styles.row1, styles.text, styles.bold]}>#</Text>
              <Text style={[styles.row2, styles.text, styles.bold]}>Tip Panela</Text>
              <Text style={[styles.row3, styles.text, styles.bold]}>Dimenzije</Text>
            </View>

            {qrData.details.map((val, i) => (
              <View>
                <View style={[styles.row, styles.bold]} wrap={false}>
                  <Text style={[styles.row1, styles.text]}>{i + 1}</Text>
                  <Text style={[styles.row2, styles.boldtext]}>{val.tipPanelaNaziv}</Text>
                  <Text style={[styles.row3, styles.text]}>{val.dimensions.join(', ')}</Text>
                </View>
                <View style={styles.row} wrap={false}>
                  <Text style={[styles.row4, styles.text, styles.bold]}>KOM:{val.count}</Text>
                  <Text style={[styles.row5, styles.text, styles.bold]}>
                    Količina: {(val.quantity / 1000).toFixed(2)} [m2]
                  </Text>
                </View>
              </View>
            ))}
            <View style={[styles.row, styles.bottom]} wrap={false}>
              <Text style={[styles.row4, styles.text, styles.bold]}>
                Ukupno komada: {qrData.totals.totalCount}
              </Text>
              <Text style={[styles.row5, styles.text, styles.bold]}>
                Ukupna Količina: {qrData.totals.totalQuantity} [m2]
              </Text>
            </View>
          </View>
        </Page>
      )}
    </Document>
  )
}

export default DrugaKlasaPakingListePdf
