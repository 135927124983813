/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, forwardRef, useEffect, useMemo, useState} from 'react'
import {Field} from 'formik'
import PrometRolne from '../../terminali-components/PrometRolne'
import FmkNalog from '../../terminali-components/FmkNalog'
import {FmkNalogiStavkeModel} from '../../../../models/FmkNaloziModel'
import PrometTraka from '../../terminali-components/PrometTraka'
import {ToastContainer} from 'react-toastify'
interface Props {
  naloga: string
  dataStavka: FmkNalogiStavkeModel
}
export type Ref = HTMLButtonElement

const Step2 = forwardRef<Ref, Props>(({naloga, dataStavka}, ref) => {
  const nalogaId = useMemo(() => naloga, [naloga])
  const stavka = useMemo(() => dataStavka, [dataStavka])
  const [selectedStavka, setSelectedStavka] = useState<FmkNalogiStavkeModel['stavka']>([])

  const [selectedComponent, setselectedComponent] = useState<number>(0)

  const components = [
    {component: <></>},
    {
      // @ts-ignore
      component: <PrometRolne ref={ref} nalogaId={nalogaId} selectedStavka={selectedStavka} />,
    },
    {
      component: <PrometTraka ref={ref} nalogaId={nalogaId} selectedStavka={selectedStavka} />,
    },
  ]

  return (
    <div className='w-100'>
      <ToastContainer />
      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-3'>
          Odaberi rolnu / traku
          <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip'></i>
        </label>

        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <input
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='1-1'
              id='kt_account_team_size_select_1'
              onChange={() => setselectedComponent(1)}
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_1'
            >
              <span className='fw-bolder fs-3'>ROLNA</span>
            </label>
          </div>
          <div className='col'>
            <input
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='2-10'
              id='kt_account_team_size_select_2'
              onChange={() => setselectedComponent(2)}
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_2'
            >
              <span className='fw-bolder fs-3'>TRAKA</span>
            </label>
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-12'>{components[selectedComponent].component}</div>
        <div className='col-12'>
          <FmkNalog data={stavka} setStavkaData={setSelectedStavka} />
        </div>
      </div>
    </div>
  )
})

export {Step2}
