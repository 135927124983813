import {useFormik} from 'formik'
import React, {useState} from 'react'
import notifyToast from '../../../../components/Toast/Toast'
import {saveZapisiStavke} from '../../../../api/zapisiProizvodnje'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'

const KamenaVuna = ({id, brojNaloga}) => {
  const initialValues = {
    'PROIZVODJAC-VUNE': undefined,
    'SIRINA-VUNE': undefined,
    'DUZINA-VUNE': undefined,
    'DEBLJINA-VUNE': undefined,
    'KOLICINA-LJEPILA-DOLE': undefined,
    'KOLICINA-LJEPILA-GORE': undefined,
    'KOLICINA-POLIOLA-U-LJEPILU-DOLE': undefined,
    'PROIZVODJAC-POLIOLA-U-LJEPILU-DOLE': undefined,
    'KOLICINA-POLIOLA-U-LJEPILU-GORE': undefined,
    'PROIZVODJAC-POLIOLA-U-LJEPILU-GORE': undefined,
    'TIP-GRABLJI': undefined,
    'ZAMJENA-GRABLJI': undefined,
    'VISINA-PRESE': undefined,
    'GRAMAZA': undefined,
  }

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response
        let data: any = []
        // Format data as per required format of api.
        Object.entries(values).map(([key, value]) => {
          if (value) {
            const dataSet = {
              IdZapisa: id,
              BrojNaloga: brojNaloga,
              MetaKey: key,
              MetaValue: value,
            }
            data.push(dataSet)
          }
        })
        response = await saveZapisiStavke(data)

        const responseData = response

        if (responseData.error === 0) {
          notifyToast(true, responseData.message)
        } else {
          notifyToast(false, responseData.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error.response.data.message)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  return (
    <div>
      {loading ? (
        <LoadingComponent />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div id='kamena-vuna'>
            <div className='row mt-3'>
              <div className='col-lg-3'>
                <label className='col-form-label fw-bold fs-6'> Proizvođač vune </label>
                <select className='form-control' {...formik.getFieldProps('PROIZVODJAC-VUNE')}>
                  <option value=''>ODABERI</option>
                  <option value='KNAUF'>KNAUF</option>
                  <option value='FIBRAN'>FIBRAN</option>
                  <option value='ERYAP'>ERYAP</option>
                  <option value='IZOCAM'>IZOCAM</option>
                  <option value='ROCKWOOL'>ROCKWOOL</option>
                  <option value='BETEK'>BETEK</option>
                </select>
              </div>
              <div className='col-lg-3'>
                <label className='col-form-label fw-bold fs-6'> Širina vune [mm]</label>
                <input
                  type='number'
                  className='form-control'
                  placeholder='Širina vune'
                  {...formik.getFieldProps('SIRINA-VUNE')}
                />
              </div>
              <div className='col-lg-3'>
                <label className='col-form-label fw-bold fs-6'> Dužina vune [mm]</label>
                <input
                  type='number'
                  className='form-control'
                  placeholder='Dužina vune'
                  {...formik.getFieldProps('DUZINA-VUNE')}
                />
              </div>
              <div className='col-lg-3'>
                <label className='col-form-label fw-bold fs-6'> Debljina vune [mm] </label>
                <input
                  type='number'
                  className='form-control'
                  placeholder='Debljina vune'
                  {...formik.getFieldProps('DEBLJINA-VUNE')}
                />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  {' '}
                  Količina ljepila dole [g/min]{' '}
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Količina ljepila dole'
                  {...formik.getFieldProps('KOLICINA-LJEPILA-DOLE')}
                />
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  {' '}
                  Količina ljepila gore [g/min]{' '}
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Količina ljepila gore'
                  {...formik.getFieldProps('KOLICINA-LJEPILA-GORE')}
                />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  {' '}
                  Količina poliola u ljepilu dole [g/min]{' '}
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Količina poliola u ljepilu dole'
                  {...formik.getFieldProps('KOLICINA-POLIOLA-U-LJEPILU-DOLE')}
                />
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> Proizvođač </label>
                <select
                  className='form-control'
                  {...formik.getFieldProps('PROIZVODJAC-POLIOLA-U-LJEPILU-DOLE')}
                >
                  <option value=''>ODABERI</option>
                  <option value='KLEBERIT'>KLEBERIT</option>
                  <option value='BASF'>BASF</option>
                  <option value='TEST'>TEST</option>
                </select>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  {' '}
                  Količina poliola u ljepilu gore [g/min]{' '}
                </label>
                <input
                  type='number'
                  className='form-control'
                  placeholder='Količina poliola u ljepilu gore'
                  {...formik.getFieldProps('KOLICINA-POLIOLA-U-LJEPILU-GORE')}
                />
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> Proizvođač </label>
                <select
                  className='form-control'
                  {...formik.getFieldProps('PROIZVODJAC-POLIOLA-U-LJEPILU-GORE')}
                >
                  <option value=''>ODABERI</option>
                  <option value='KLEBERIT'>KLEBERIT</option>
                  <option value='BASF'>BASF</option>
                  <option value='TEST'>TEST</option>
                </select>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> Tip grablji </label>
                <select className='form-control' {...formik.getFieldProps('TIP-GRABLJI')}>
                  <option value=''>ODABERI</option>
                  <option value='SIKA-ALTERNATIVA'>SIKA-ALTERNATIVA</option>
                  <option value='KLEBERIT-ALTERNATIVA'>KLEBERIT-ALTERNATIVA</option>
                  <option value='SIKA'>SIKA</option>
                  <option value='KLEBERIT'>KLEBERIT</option>
                  <option value='BASF'>BASF</option>
                </select>
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> Zamjena grablji [min] </label>
                <input
                  type='number'
                  className='form-control'
                  placeholder='Zamjena grablji'
                  {...formik.getFieldProps('ZAMJENA-GRABLJI')}
                />
              </div>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-lg-6'>
              <label className='col-form-label fw-bold fs-6'> Visina prese[mm] </label>
              <input
                  type='number'
                  className='form-control'
                  placeholder='Visina prese'
                  {...formik.getFieldProps('VISINA-PRESE')}
              />
            </div>
            <div className='col-lg-6'>
              <label className='col-form-label fw-bold fs-6'> Gramaza </label>
              <input
                  type='number'
                  className='form-control'
                  placeholder='Gramaza'
                  {...formik.getFieldProps('GRAMAZA')}
              />
            </div>
        </div>
        <div className='row mt-4'>
        <div className='col-lg-3 offset-9'>
        <button type='submit' className='btn btn-block btn-primary'>
        Potvrdi
        </button>
        </div>
        </div>
        </form>
        )}
</div>
)
}

export default KamenaVuna
