import React, {useMemo, useState} from "react";
import {ToastContainer} from "react-toastify";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import QrCodeScanner from "../../../components/QrCodeScanner/QrCodeScanner";
import clsx from "clsx";
import {KTIcon} from "../../../../_websoft/helpers";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {krajRadnogNaloga} from "../../../api/radniNalozi";
import notifyToast from "../../../components/Toast/Toast";
import {fetchTable} from "../../../api/tableRS";
import {saveTablePromet} from "../../../api/tablePromet";

const PrometTrake: React.FC = () => {

    const initialValues = {
        BrojListe: '',
        trake: [],
    }
    const [loading, setLoading] = useState(false)
    const validationSchema = Yup.object({
        // trakaId: Yup.string().required('required'),
    })
    const [initialData, setInitialData] = useState<any>(initialValues)
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            await handleApiCalls(values);
            setSubmitting(false); // Indicate that submission is complete
        },
    });
    const handleApiCalls = async (values) => {
        setLoading(true);

        const promises = values.trake.map(traka => {
            const apiData = {
                TrakaID: traka.TrakaID,
                NaKolicinu: traka.NaKolicinu,
                BrojListe: values.BrojListe,
            };

            return saveTablePromet(apiData)
                .then(response => {
                    notifyToast(true, response.message);
                    return response;
                })
                .catch(error => {
                    console.error(error);
                    notifyToast(false, error.message);
                    return error;
                });
        });

        const results = await Promise.allSettled(promises);
        // Handle the results here if needed

        setTimeout(() => {
            navigate(-1);
        }, 3000);

        setLoading(false);
    };
    const getTrakeData = (trakeId, rowId) =>
        fetchTable(trakeId)
            .then((response) => {
                console.log({response})
                formik.values.trake![rowId].duzina = response.TrenutnaDuzina
                formik.values.trake![rowId].tezina = response?.Tezina
                formik.setFieldValue('trake', formik.values.trake)
            })
            .catch((error) => notifyToast(false, error.message))
    return (
        <div className='w-100'>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    <ToastContainer/>
                    <div className='pb-10 pb-lg-12'>
                        <h2 className='fw-bolder text-dark'>Promet trake</h2>
                    </div>
                    {loading ? (
                        <LoadingComponent/>
                    ) : (
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className='row mb-10'>
                                {/* begin : dynamic rows */}
                                <div className='row mb-6'>
                                    <div className='col-2'>
                                        <QrCodeScanner
                                            onScan={(data) => {
                                                const text = data.split(';')[0]
                                                formik.setFieldValue('BrojListe', text)
                                            }}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <input
                                            type='text'
                                            placeholder='Broj Naloga'
                                            className={clsx('form-control form-control-lg form-control-solid')}
                                            {...formik.getFieldProps('BrojListe')} // Use getFieldProps to bind the input
                                        />
                                    </div>
                                </div>
                                <hr/>
                                <div className='row mb-6'>
                                    {Array.from({length: formik.values.trake!.length}, (_, i) => (
                                        <div className='row'>
                                            {/* begin: Broj Trake */}
                                            <div className='col-lg-6 col-md-6 mb-6'>
                                                <label className='col-lg-8 col-form-label required fw-bold fs-6'>
                                                    Broj Trake
                                                </label>
                                                <div className='row justify-content-between'>
                                                    <div className='col-2'>
                                                        <QrCodeScanner
                                                            onScan={(data) => {
                                                                const text = data.split(';')[0]
                                                                formik.values.trake![i].TrakaId = text
                                                                formik.setFieldValue('trake', formik.values.trake)
                                                                getTrakeData(formik.values.trake![i].TrakaId, i)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='col-8'>
                                                        <input
                                                            type='number'
                                                            placeholder='Broj Trake'
                                                            className={clsx('form-control form-control-lg form-control-solid')}
                                                            value={formik.values.trake![i].TrakaID}
                                                            onChange={(e) => {
                                                                formik.values.trake![i].TrakaID = e.target.value
                                                                formik.setFieldValue('trake', formik.values.trake)
                                                                getTrakeData(formik.values.trake![i].TrakaID, i)
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <label className='col-lg-8 col-form-label  fw-bold fs-6'>
                                                    Duzina: {formik.values.trake![i].duzina} Tezina:{' '}
                                                    {formik.values.trake![i].tezina}
                                                </label>
                                            </div>
                                            {/* end: Broj Traka */}
                                            {/* begin: Tezina */}
                                            <div className='col-lg-3 col-md-6 mb-6'>
                                                <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                                                    Krajnja Težina
                                                </label>
                                                <div className='col fv-row'>
                                                    <input
                                                        type='number'
                                                        placeholder='Tezina'
                                                        className={clsx('form-control form-control-lg form-control-solid')}
                                                        value={formik.values.trake![i].NaKolicinu}
                                                        onChange={(e) => {
                                                            formik.values.trake![i].NaKolicinu = e.target.value
                                                            formik.setFieldValue('trake', formik.values.trake)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {/* end: Tezina */}

                                            <div className='col-lg-2 align-self-center mt-5'>
                                                <button
                                                    type='button'
                                                    className='btn btn-danger btn-sm'
                                                    onClick={() => {
                                                        var trake = formik.values.trake ?? []

                                                        trake.splice(i, 1)

                                                        formik.setFieldValue('trake', trake)
                                                    }}
                                                >
                                                    <KTIcon iconName='minus' className='fs-2'/>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <div className='col-lg-2 mb-6'>
                                        <button
                                            type='button'
                                            className='btn btn-primary btn-sm'
                                            onClick={() => {
                                                var trake = formik.values.trake ?? []
                                                trake.push({
                                                    TrakaId: '',
                                                    KrajnjaKolicina: '',
                                                })
                                                formik.setFieldValue('trake', trake)
                                            }}
                                        >
                                            <KTIcon iconName='plus' className='fs-2'/>
                                        </button>
                                    </div>
                                </div>
                                {/* end : dynamic rows */}
                            </div>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={loading || formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && 'Potvrdi'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    )

}
export default PrometTrake;
