import React, { useEffect, useState } from 'react';
import { getPripremePrometa } from "../../../api/paneliPromet";
import { PripremaPrometaModel } from "../../../models/PripremaPrometaModel";

const PrometRepromaterijalaPaneli: React.FC = () => {
    const [data, setData] = useState<PripremaPrometaModel[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getPripremePrometa(); // Now correctly typed
                if (Array.isArray(response.data)) {
                    setData(response.data); // Directly set the nested data array
                } else {
                    console.error('Unexpected response format:', response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className='row'>
            {data.map((item) => (
                <div className='col-xl-4 col-md-6 mt-2' key={item.Id}>
                    <a
                        href={item.TrakaId ? `promet/traka/${item.TrakaId}` : `promet/rolna/${item.RolnaId}`}
                        className={`card ${item.TrakaId ? 'bg-danger' : 'bg-primary'} hoverable card-xl-stretch mb-xl-8`}
                    >
                        <div className='card-body'>
                            <div className='text-white fw-bold fs-2 mb-2 mt-5'>
                                {item.TrakaId ? `Traka: ${item.TrakaId}` : `Rolna: ${item.RolnaId}`}
                            </div>
                            <div className='text-white'>
                                {item.uposlenik.UposlenikImePrezime}
                            </div>
                        </div>
                    </a>
                </div>
            ))}
        </div>
    );
}

export default PrometRepromaterijalaPaneli;
