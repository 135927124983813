import axios from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
const ROLNE_API = `${API_URL}/statusDropdown?kategorija=rolne`
const SREDSTVO_API = `${API_URL}/statusDropdown?kategorija=sredstvo`
const INTERVENCIJA_API = `${API_URL}/statusDropdown?kategorija=intervencija`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getRolneStatusDropdown() {
  try {
    return await axios.get<Array<Option>>(ROLNE_API, {headers: headers})
  } catch (error) {
    console.error('Error fetching Rolne Dropdown  data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
export async function getSredstvoStatusDropdown() {
  try {
    return await axios.get<Array<Option>>(SREDSTVO_API, {headers: headers})
  } catch (error) {
    console.error('Error fetching Sredstvo Dropdown  data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
export async function getIntervencijaStatusDropdown() {
  try {
    return await axios.get<Array<Option>>(INTERVENCIJA_API, {headers: headers})
  } catch (error) {
    console.error('Error fetching Dropdown  data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}