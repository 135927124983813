import React, {useEffect, useMemo, useState} from 'react'
import {getZapisiNalogInfo} from '../../../../api/zapisiProizvodnje'
import {ZapisiProizvodnjeModel, ZapisiStavkeModel} from '../../../../models/ZapisiProizvodnjeModel'
import moments from 'moment'

const KomponentiPregled = ({data, stavkeData}) => {
  return (
    <div className='card pb-4'>
      <div className='card-body'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='mb-3'>
              <div className='d-flex flex-column bg-primary border rounded p-2'>
                <div className='bg-info text-white py-2 px-3 rounded-top'>
                  Broj naloga: {data?.BrojNaloga}
                </div>
                <div className='bg-info text-white border-top border-white border-opacity-25 py-2 px-3'>
                  Stavka: {data?.Stavka}
                </div>
                <div className='bg-info text-white border-top border-white border-opacity-25 py-2 px-3'>
                  Smjena: {data?.Smjena}
                </div>
                <div className='bg-info text-white border-top border-white border-opacity-25 py-2 px-3 rounded-bottom'>
                  Mašina: {data?.Masina}
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='mb-3'>
              <div className='d-flex flex-column bg-light border rounded p-2'>
                <div className='bg-info text-white py-2 px-3 rounded-top'>
                  Kupac: {data?.paneli_proizvodnja?.kupac ?? ''}
                </div>
                <div className='bg-info text-white border-top border-white border-opacity-25 py-2 px-3'>
                  Artikal:{data?.paneli_proizvodnja?.artikal ?? ''}
                </div>
                <div className='bg-info text-white border-top border-white border-opacity-25 py-2 px-3'>
                  Komercijalista: {data?.paneli_proizvodnja?.kreiraoKorisnik ?? ''}
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2>KOMPONENTE</h2>
        <div className='row mt-2'>
          {stavkeData.map((item) => (
            <div className='col-lg-6'>
              <div className='mb-3'>
                <span className='text-danger'>{moments(item.Kreirano).format('DD-MM-YYYY')}</span>
                <span className='text-primary'> | {item.MetaKey}</span>
                <span className='text-danger'> | {item.MetaValue} </span>
                <span className='text-primary'> | {item.uposlenik.UposlenikImePrezime}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default KomponentiPregled
