/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from 'react'
import {Modal, ModalHeader} from 'react-bootstrap'
import {getPaketiNaloga, getStanjeOtpremePaketa} from '../../api/proizvodnja'
import {PaketiNalogaModel, StanjeOtpremePaketaModel} from '../../models/ProizvodnjaModel'
import notifyToast from '../Toast/Toast'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {KTIcon} from '../../../_websoft/helpers'

const PakModal = ({isModalOpen, setisModalOpen, stavkaId}) => {
  const stavka = useMemo(() => stavkaId, [stavkaId])
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')

  const [stanjeOtpremePaketa, setStanjeOtpremePaketa] = useState<
    StanjeOtpremePaketaModel | undefined
  >(undefined)
  const [paketiNaloga, setPaketiNaloga] = useState<PaketiNalogaModel[]>([])
  const [stanjeLoading, setstanjeLoading] = useState(false)
  const [paketiLoading, setpaketiLoading] = useState(false)
  const getStanjePaketa = () => {
    if (stavka) {
      setstanjeLoading(true)
      getStanjeOtpremePaketa(stavka)
        .then((data) => {
          setStanjeOtpremePaketa(data)
        })
        .catch((error) => notifyToast(false, error.response.data.message))
        .finally(() => setstanjeLoading(false))
    }
  }
  const getPaketi = () => {
    if (stavka) {
      setpaketiLoading(true)
      getPaketiNaloga(stavka)
        .then((data) => {
          setPaketiNaloga(data)
        })
        .catch((error) => notifyToast(false, error.response.data.message))
        .finally(() => setpaketiLoading(false))
    }
  }
  useEffect(() => {
    getStanjePaketa()
    getPaketi()
  }, [stavka])

  useEffect(() => {
    const {id} = sorting[0] ?? ''
    paketiNaloga.sort((a, b) => a[id] - b[id])
  }, [sorting])

  // Set cell color according to condtions.
  const getCellColor = (cell, row) => {
    // Color for Tr.roba
    if (cell.column.id === 'isp')
      return row.original.isporucenDatum === null ? '#FF0000' : '#008000'
    // Color default
    return '#FFFFFF'
  }

  const columns = [
    {
      id: 'Paketi',
      header: 'Paketi',
      accessorFn: (row) => `Paket- ${row.nazivPanela}`,
    },
    {
      id: 'duzina',
      header: 'Duzina',
      accessorKey: 'duzina',
    },
    {
      id: 'sirina',
      header: 'Sirina',
      accessorKey: 'sirinaPaketa',
    },
    {
      id: 'kolcina',
      header: 'Kolcina [kom]',
      accessorKey: 'brPanela',
    },
    {
      id: 'otp',
      header: 'Otp.',
      cell: ({row}) => (
        <KTIcon
          iconName={row.original.paketOtpremljen === 0 ? 'cross' : 'check'}
          className={row.original.paketOtpremljen === 0 ? 'text-danger fs-2' : 'text-success fs-2'}
        />
      ),
    },
    {
      id: 'isp',
      header: 'Isp.',
      accessorFn: (row) => (row.isporucenDatum === null ? 'Ne' : `Da - ${row.isporucenDatum}`),
    },
  ]
  const table = useReactTable({
    data: paketiNaloga,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })
  return (
    <Modal className='modal-lg' show={isModalOpen} onHide={() => setisModalOpen(false)}>
      <ModalHeader closeButton={true} className='h3'>
        Stavke paketa
      </ModalHeader>
      {stanjeLoading ? (
        <LoadingComponent />
      ) : (
        <div className='row p-10'>
          <div className='col-lg-6 mb-6'>
            <div className='row'>
              <h5 className='col-lg-3'>Kupac:</h5>
              <div className='col-lg-9'>{stanjeOtpremePaketa?.kolicinaNaloga?.kupac}</div>
            </div>
            <div className='row'>
              <h5 className='col-lg-3'>#Nalog:</h5>
              <div className='col-lg-3'>{stanjeOtpremePaketa?.kolicinaNaloga?.brojNaloga}</div>
            </div>
            <div className='row'>
              <h5 className='col-lg-3'>#Ponuda:</h5>{' '}
              <div className='col-lg-3'>{stanjeOtpremePaketa?.kolicinaNaloga?.brojPonude}</div>
            </div>
            <div className='row'>
              <h5 className='col-lg-4'>Otpremljeno [m2]:</h5>
              <div className='col-lg-3'>
                {parseFloat(stanjeOtpremePaketa?.kolicinaOtpPaketa?.toString() ?? '0').toFixed(2)}
              </div>
            </div>
          </div>
          <div className='col-lg-6 mb-6'>
            <div className='row'>
              <h5 className='col-lg-9'>Kolicina prema nalogu[m2]:</h5>
              <div className='col-lg-3'>
                {parseFloat(
                  stanjeOtpremePaketa?.kolicinaNaloga?.kolicina?.toString() ?? '0'
                ).toFixed(2)}
              </div>
            </div>
            <div className='row'>
              <h5 className='col-lg-9'>Kolicina prema paketima[m2]:</h5>
              <div className='col-lg-3'>{stanjeOtpremePaketa?.kolicinaPaketa?.toFixed(2)}</div>
            </div>
            <div className='row'>
              <h5 className='col-lg-9'>Razlika[m2]:</h5>
              <div className='col-lg-3'>
                {(
                  parseFloat(stanjeOtpremePaketa?.kolicinaNaloga?.kolicina ?? '0') -
                  parseFloat(stanjeOtpremePaketa?.kolicinaPaketa?.toString() ?? '0')
                ).toFixed(2)}
              </div>
            </div>
            <div className='row'>
              <h5 className='col-lg-9'>Za otpremiti[m2]:</h5>
              <div className='col-lg-3'>
                {(
                  parseFloat(stanjeOtpremePaketa?.kolicinaPaketa?.toString() ?? '0') -
                  parseFloat(stanjeOtpremePaketa?.kolicinaOtpPaketa?.toString() ?? '0')
                ).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      )}
      {paketiLoading ? (
        <LoadingComponent />
      ) : (
        <div className='table-responsive mt-3 '>
          <table className='table align-middle table-bordered gy-5 no-footer'>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      className='text-white'
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder ? null : (
                        <span>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {/* @ts-ignore */}
                          {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='text-gray-600'>
              {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </Modal>
  )
}

export default PakModal
