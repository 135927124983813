import React, {useState} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import {isporuciPaketeQRApi} from "../../../api/paketiPanela";
import notifyToast from "../../../components/Toast/Toast";
import {ToastContainer} from "react-toastify";
import TitleComponent from "../../../components/TitleComponent/TitleComponent";
import QrCodeScanner from "../../../components/QrCodeScanner/QrCodeScanner";
import IsporuciPaket from "./IsporuciPaket";
import {isporuciSkartPaket} from "../../../api/skartPaketi";

const IsporuciPaketDrugaKlasa:React.FC = () => {
    const initialValues = {
        paketId: undefined,
        kupac: undefined,
    }
    const [loading, setLoading] = useState(false)

    const validationSchema = Yup.object({
        paketId: Yup.string().required('Required'),
        kupac: Yup.string().required('Required'),
    })
    const handleQrScan = (data: string) => {
        formik.setFieldValue('paketId', data)
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                console.log('usao')
                let response
                response = await isporuciSkartPaket(values)
                if (response.error === 0) {
                    notifyToast(true, response.message)
                } else {
                    notifyToast(false, response.message || 'Greška, provjerite status paketa')
                }
            } catch (error: any) {
                console.error('Error: ', error)
                notifyToast(false, error.response.data.message)
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        },
    })
    return (
        <div className='row'>
            <ToastContainer/>
            <div className='card mb-5 mb-xl-10 col-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <TitleComponent name='Isporuči paket 2 KLASA'/>
                    </div>
                </div>
                <div id='kt_account_profile_details' className='collapse show'>

                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className='card-body border-top p-9 row'>
                            {/* Artikal */}
                            <div className='col-12 mb-6'>
                                <label className='col-auto col-form-label required fw-bold fs-6'>Skeniraj</label>
                                <div className='row mb-5'>
                                    <div className='col-3 fv-row'>
                                        <QrCodeScanner onScan={handleQrScan}/>
                                    </div>
                                    <div className='col-6 fv-row'>
                                        <input
                                            type='text'
                                            placeholder='Paket'
                                            {...formik.getFieldProps('paketId')}
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-5'>
                                    <div className='col-6 offset-3 fv-row mt-5'>
                                        <input
                                            type='text'
                                            placeholder='Kupac'
                                            {...formik.getFieldProps('kupac')}
                                            className='form-control'
                                        />
                                    </div>
                                </div>


                                <div className='row mt-5'>
                                    <div className='col-lg-8 mt-5 fv-row'>
                                        <div className="d-grid gap-2">
                                            <button
                                                type='submit'
                                                className='btn btn-block btn-primary'
                                                disabled={loading || formik.isSubmitting || !formik.isValid}
                                            >
                                                {!loading && 'Potvrdi'}
                                                {loading && (
                                                    <span className='indicator-progress' style={{display: 'block'}}>
                                                    Please wait...{' '}
                                                        <span
                                                            className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default IsporuciPaketDrugaKlasa;

