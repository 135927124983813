import axios from 'axios'
import {UserModel} from './_models'
import {UposlenikModel} from '../../../models/UposlenikModel'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const RFID_URL = `${API_URL}/rfidLogin`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(Username: string, Password: string) {
  return axios.post<UposlenikModel>(
    LOGIN_URL,
    {
      Username,
      Password,
    },
    {headers: {Accept: 'application/json'}}
  )
}

// Server should return AuthModel
// Login with rfid
export function loginWithBarcode(rfid: string) {
  return axios.post<UposlenikModel>(
    RFID_URL,
    {
      rfid,
    },
    {headers: {Accept: 'application/json'}}
  )
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
