import React from 'react'
import {PageLink, PageTitle} from '../../../_websoft/layout/core'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import PartneriDashboard from './PartneriDashboard'

const PartneriBreadCrumbs: Array<PageLink> = [
  {
    title: 'Partneri',
    path: '/partneri/pocetna',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const PartneriRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='pocetna'
          element={
            <>
              <PageTitle breadcrumbs={PartneriBreadCrumbs}>Partneri Dashboard</PageTitle>
              <PartneriDashboard />
            </>
          }
        />

        <Route path='*' element={<Navigate to='pocetna' />} />
      </Route>
    </Routes>
  )
}

export default PartneriRoutes
