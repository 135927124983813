import React, { useState, useEffect } from 'react';
import { stabloArtikala } from "../../api/artikli";
import Tree from '../../components/TreeNode/Tree';
import {ToastContainer} from "react-toastify";
import {Modal, ModalHeader} from "react-bootstrap"; // Ensure this path is correct
import { KTCard, KTCardBody, KTIcon } from "../../../_websoft/helpers";
const TreePage: React.FC = () => {
    const [data, setData] = useState<null | any[]>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<null | Error>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const result = await stabloArtikala();
                setData(result);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <KTCard>
            <ToastContainer />
            <KTCardBody className='py-4'>
                <div className="container">
                    <h1 className="mb-5">Slablo artikala</h1>
                    {data && <Tree data={data} />}
                </div>
            </KTCardBody>
        </KTCard>
    );
}

export default TreePage;
