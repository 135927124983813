import React from 'react'
import {KTCard, KTCardBody} from '../../../../_websoft/helpers'
import {ToastContainer} from 'react-bootstrap'

const TerminaliRasjecanje = () => {
  return (
    <KTCard>
      <ToastContainer />
      <KTCardBody className='py-4'>
        <div className='row'>
          <div className='col-xl-4 mt-2'>
            <a
              href='dashboard-rasjecanje/rasjecanje/1'
              className='card bg-success hoverable card-xl-stretch mb-xl-8'
            >
              <div className='card-body'>
                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
                <div className='text-white fw-bold fs-2 mb-2 mt-5 text-uppercase'>Rasjecanje</div>
                <div className='fw-semibold text-white'>Pregled</div>
              </div>
            </a>
          </div>
          <div className='col-xl-4 mt-2'>
            <a
              href='dashboard-rasjecanje/odmotavanje/2'
              className='card bg-info hoverable card-xl-stretch mb-xl-8'
            >
              <div className='card-body'>
                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
                <div className='text-white fw-bold fs-2 mb-2 mt-5 text-uppercase'>Odmotavanje</div>
                <div className='fw-semibold text-white'>Pregled</div>
              </div>
            </a>
          </div>
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export default TerminaliRasjecanje
