import React, {useState} from 'react'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import {ToastContainer} from 'react-toastify'
import {KTIcon} from '../../../_websoft/helpers'
import RolneQrCodeModal from '../../components/QrCodeGenerator/RolneQrCodeModal'
import TrakeQrCodeModal from '../../components/QrCodeGenerator/TrakeQrCodeModal'
import PaleteQrCodeModal from '../../components/QrCodeGenerator/PaleteQrCodeModal'

const KreirajQRcode = () => {
  const [rolneModalOpen, setrolneModalOpen] = useState(false)
  const [trakeModalOpen, settrakeModalOpen] = useState(false)
  const [paleteModalOpen, setpaleteModalOpen] = useState(false)
  return (
    <div className='card mb-5 mb-xl-10'>
      <ToastContainer />
      <RolneQrCodeModal setisModalOpen={setrolneModalOpen} isModalOpen={rolneModalOpen} />
      <TrakeQrCodeModal setisModalOpen={settrakeModalOpen} isModalOpen={trakeModalOpen} />
      <PaleteQrCodeModal setisModalOpen={setpaleteModalOpen} isModalOpen={paleteModalOpen} />
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <TitleComponent name='Kreiraj QR kod naljepnice' />
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9 row'>
          <div className='col-lg-4 col-sm-12 p-3 mb-6'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => setrolneModalOpen(true)}
            >
              <KTIcon iconName='plus' className='fs-2' />
              Rolne
            </button>
          </div>
          <div className='col-lg-4 col-sm-12 p-3'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => settrakeModalOpen(true)}
            >
              <KTIcon iconName='plus' className='fs-2' />
              Trake (Rš)
            </button>
          </div>
          <div className='col-lg-4 col-sm-12 p-3'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={() => setpaleteModalOpen(true)}
            >
              <KTIcon iconName='plus' className='fs-2' />
              Palete (Rš)
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KreirajQRcode
