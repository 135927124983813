import {KTCard} from '../../../../_websoft/helpers'
import {ToastContainer} from 'react-toastify'
import React, {useState} from 'react'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
import {useFormik} from 'formik'
import ArtikliGalanterija from '../../../components/ArtikliGalanterija/ArtikliGalanterija'
import notifyToast from '../../../components/Toast/Toast'
import {getAuth} from '../../../modules/auth'
import {UposlenikModel} from '../../../models/UposlenikModel'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import TitleComponent from '../../../components/TitleComponent/TitleComponent'

const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: `Bearer ${auth?.access_token ?? ''}`}
const API_URL = process.env.REACT_APP_API_URL

const StartNalog: React.FC = () => {
  const navigate = useNavigate()
  const API_URL = process.env.REACT_APP_API_URL
  const auth: UposlenikModel = getAuth()!
  const API = `${API_URL}/artikliZapisiProizvodnje`
  const [loading, setLoading] = useState(false)

  const initialValues = {
    idOperacije: '',
    idMasine: '',
  }
  const getFormData = (values) => {
    const formData = new FormData()
    formData.append('idOperacije', values.idOperacije)
    formData.append('idMasine', values.idMasine)
    return formData
  }
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      try {
        const response = await axios.post(`${API}`, values, {headers: headers})
        if (response.data.error === 0) {
          notifyToast(true, response.data.message)
          setTimeout(() => {
            navigate('/terminali/pocetna')
          }, 4000)
        } else {
          notifyToast(false, response.data.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  interface OperacijeItem {
    Id: number
    Naziv: string
  }
  const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}
  const [dropdownOperacije, setDropdownOperacije] = useState<OperacijeItem[]>([])
  const handleDropdownChange = async (name, value) => {
    setLoading(true)
    try {
      const response = await axios.get(`${API_URL}/operacijeArtikla?artikal=${value}`)
      // Process the response data for the next API call
      const payload = response.data.map((item) => ({
        Id: item.Id,
        Naziv: item.Naziv,
      }))
      setDropdownOperacije(payload)
    } catch (error) {
      console.error('Error in API call', error)
    } finally {
      setLoading(false)
    }
  }
  const [dropdownMasine, setDropdownMasine] = useState<OperacijeItem[]>([])
  const handleOperacijeChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value
    setLoading(true)
    try {
      const response = await axios.get(`${API_URL}/sredstvaZaOperacije?operacija=${selectedValue}`)
      const payload = response.data.map((item) => ({
        Id: item.Id,
        Naziv: item.Naziv,
      }))
      setDropdownMasine(payload)
      // Make the next API call here if needed
    } catch (error) {
      console.error('Error in API call', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <KTCard>
      <ToastContainer />
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <TitleComponent name='Start operacija' />
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/* Naziv */}
            <div className='row'>
              <label className='col-form-label required fw-bold fs-6'>Odaberi Artikal</label>
              <div className='col-lg-8 fv-row'>
                <ArtikliGalanterija onChange={handleDropdownChange} />
              </div>
            </div>
            {dropdownOperacije.length > 0 && (
              <div className='col-lg-8 fv-row mt-4'>
                <select
                  className='form-control'
                  {...formik.getFieldProps('idOperacije')}
                  onChange={(e) => {
                    formik.handleChange(e)
                    handleOperacijeChange(e)
                  }}
                >
                  <option key='' value=''>
                    Odaberi...
                  </option>
                  {dropdownOperacije.map((item) => (
                    <option key={item.Id} value={item.Id}>
                      {item.Naziv}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {dropdownMasine.length > 0 && (
              <div className='col-lg-8 fv-row mt-4'>
                <select
                  className='form-control'
                  {...formik.getFieldProps('idMasine')}
                  onChange={(e) => {
                    formik.handleChange(e)
                  }}
                >
                  <option key='' value=''>
                    Odaberi...
                  </option>
                  {dropdownMasine.map((item) => (
                    <option key={item.Id} value={item.Id}>
                      {item.Naziv}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          {loading && <LoadingComponent />}
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && 'Potvrdi'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </KTCard>
  )
}
export default StartNalog
