import React, {FC} from 'react';
import {Page, Text, View, Document, StyleSheet, Font, Image} from '@react-pdf/renderer';
import {PaketLezarina} from "../../../../models/PaketLezarina";
import {splitDataForPages} from "../../../../models/PaketLezarina";
import moment from 'moment'
import header from '../../../../../_websoft/assets/images/Header_S.jpg'
import footer from '../../../../../_websoft/assets/images/Footer_S.jpg'
import memorandum from '../../../../../_websoft/assets/images/Memorandum-landscape-footer.jpg'
import PageTemplateLandscape from "../PdfPageTemplate/PageTemplateLandscape";
import PageTemplateLandscapeNoFooter from "../PdfPageTemplate/PageTmplLandscapeNoFooter";

Font.register({
    family: 'Roboto',
    src: 'https://path/to/roboto-regular-webfont.ttf',
});
// Define styles for the document
const styles = StyleSheet.create({
    page: {
        fontFamily: "Roboto",
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#000000',
        borderBottomStyle: 'solid',
        alignItems: 'center',
        height: 30,
        fontSize: 12,
    },
    tableRow2: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#000000',
        borderBottomStyle: 'solid',
        alignItems: 'center',
        height: 30,
        fontSize: 12,
        backgroundColor: "#d3d3d3",
    },
    tableColumnHeader: {
        width: '15%',
        textAlign: 'center',
    },
    header: {
        marginTop: 100,
        marginLeft:70,
        marginBottom: 10,
        fontSize: 22,
    },
    header2: {
        marginLeft:70,
        fontSize: 16,
    },
    tableColumn: {
        width: '20%',
        textAlign: 'center',
    },
    tabela: {
        marginTop: 40,
        paddingLeft: 50,
        paddingRight: 50,
    },
    totalRow: {
        flexDirection: 'row',
        borderTopWidth: 2,
        borderTopColor: '#000',
    },
    totalText: {
        width: '100%', // This will span the width of 6 columns
        textAlign: 'right',
        marginTop:5,
    },
});

interface Props {
    qrData: { data: PaketLezarina[] };
    inoKupac: string;
}

const LezarinaPdf: FC<Props> = ({qrData, inoKupac}) => {
    if (!qrData.data.length) return null; // Handle the case where data might be empty

    const itemsPerPage = 9;  // Adjust based on your content size
    const pagesData = splitDataForPages(qrData.data, itemsPerPage);

    const firstItem = qrData.data[0];
    const totalCijena = qrData.data.reduce((sum, item) => sum + parseFloat(item.totalCijena?.toString() || '0'), 0);
    const totalJedicinaCijena = qrData.data.reduce((sum, item) => sum + parseFloat(item.cijena || '0'), 0);

    return (
        <Document style={styles.page}>
            {pagesData.map((pageData, index) => (
                <PageTemplateLandscape key={index}>
                    <Text style={styles.header}>Obračun ležarine</Text>
                    <Text style={styles.header2}>Kupac: {qrData.data[0].kupac || 'N/A'}</Text>
                    <Text style={styles.header2}>Utovareni
                        paketi: {moment(qrData.data[0].datum || '').format('DD-MM-YYYY HH:mm')}</Text>
                    {/* Add the table header here */}
                    {inoKupac === "NE" ? (
                    <View style={styles.tabela}>
                        <View style={styles.tableRow2}>
                            <Text style={styles.tableColumnHeader}>Ponuda</Text>
                            <Text style={styles.tableColumnHeader}>Nalog</Text>
                            <Text style={styles.tableColumnHeader}>Završen</Text>
                            <Text style={styles.tableColumnHeader}>Paket</Text>
                            <Text style={styles.tableColumnHeader}>Količina (m²)</Text>
                            <Text style={styles.tableColumnHeader}>Dana</Text>
                            <Text style={styles.tableColumnHeader}>Cijena</Text>
                            <Text style={styles.tableColumnHeader}>PDV</Text>
                            <Text style={styles.tableColumnHeader}>Iznos [BAM]</Text>
                        </View>
                        {pageData.map((item, itemIndex) => (
                            <View key={itemIndex} style={styles.tableRow}>
                                <Text style={styles.tableColumn}>{item.brojPonude || 'N/A'}</Text>
                                <Text style={styles.tableColumn}>{`${item.brojNaloga || ' '} / ${item.stavka || ' '}`}</Text>
                                <Text style={styles.tableColumn}>{moment(item.zavrsenDatum).format('DD-MM-YYYY')}</Text>
                                <Text style={styles.tableColumn}>Paket {item.paket || 'N/A'}</Text>
                                <Text style={styles.tableColumn}>{item.kvadratura || 'N/A'}</Text>
                                <Text style={styles.tableColumn}>{item.lezaoDana || 'N/A'}</Text>
                                <Text style={styles.tableColumn}>{item.cijena || 'N/A'}</Text>
                                <Text style={styles.tableColumn}>{item.pdv || 'N/A'}</Text>
                                <Text style={styles.tableColumn}>{item.totalCijena || '0.00'}</Text>
                            </View>
                        ))}
                        {index === pagesData.length - 1 && (
                            <View style={styles.totalRow}>
                                <Text style={styles.totalText}>Total cijena:</Text>
                                <Text style={[styles.tableColumn, { marginTop: 5 }]}>
                                    {totalCijena.toFixed(2)} [BAM]
                                </Text>
                            </View>
                        )}
                    </View>
                    ) : (
                        <View style={styles.tabela}>
                            <View style={styles.tableRow2}>
                                <Text style={styles.tableColumnHeader}>Ponuda</Text>
                                <Text style={styles.tableColumnHeader}>Nalog</Text>
                                <Text style={styles.tableColumnHeader}>Završen</Text>
                                <Text style={styles.tableColumnHeader}>Paket</Text>
                                <Text style={styles.tableColumnHeader}>Količina (m²)</Text>
                                <Text style={styles.tableColumnHeader}>Dana</Text>
                                <Text style={styles.tableColumnHeader}>Cijena [€]</Text>
                            </View>
                            {pageData.map((item, itemIndex) => (
                                <View key={itemIndex} style={styles.tableRow}>
                                    <Text style={styles.tableColumn}>{item.brojPonude || 'N/A'}</Text>
                                    <Text style={styles.tableColumn}>{`${item.brojNaloga || ' '} / ${item.stavka || ' '}`}</Text>
                                    <Text style={styles.tableColumn}>{moment(item.zavrsenDatum).format('DD-MM-YYYY')}</Text>
                                    <Text style={styles.tableColumn}>Paket {item.paket || 'N/A'}</Text>
                                    <Text style={styles.tableColumn}>{item.kvadratura || 'N/A'}</Text>
                                    <Text style={styles.tableColumn}>{item.lezaoDana || 'N/A'}</Text>
                                    <Text style={styles.tableColumn}>{item.cijena || 'N/A'}</Text>
                                </View>
                            ))}
                            {index === pagesData.length - 1 && (
                                <View style={styles.totalRow}>
                                    <Text style={styles.totalText}>Total cijena:</Text>
                                    <Text style={[styles.tableColumn, { marginTop: 5 }]}>
                                        {totalJedicinaCijena.toFixed(2)} [€]
                                    </Text>
                                </View>
                            )}
                        </View>
                        )}
                </PageTemplateLandscape>
            ))}

        </Document>
    );
};

export default LezarinaPdf;
