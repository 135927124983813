import React, { useEffect, useState } from "react";
import { PaneliKontrolaKvalitetaModel, ParamsModelKontrola } from "../../models/PaneliKontrolaKvalitetaModel";
import { PaginationModel } from "../../models/PaginationModel";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import { deletePaneliKontrolaKvaliteta, getPaneliKontrolaKvaliteta } from "../../api/paneliKontrolaKvaliteta";
import notifyToast from "../../components/Toast/Toast";
import moments from "moment";
import { TableActionButton } from "../../components/TableActionBtn/TableActionButton";
import { Link, useNavigate } from "react-router-dom";
import { KTCard, KTCardBody, KTIcon } from "../../../_websoft/helpers";
import { ToastContainer } from "react-toastify";
import TableSearch from "../../components/TableSearch/TableSearch";
import ExportButton from "../../components/TableExportToExcel/ExportButton";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import TablePagination from "../../components/TablePagination/TablePagination";
import { ParamsModelReklamacije, ReklamacijeModel } from "../../models/ReklamacijeModel";
import {deletePaneliReklamacije, getAllPaneliReklamacije} from "../../api/reklamacijePanela";

const ImageModal = ({ images, show, onClose }) => {
    return (
        <div
            className={`modal fade ${show ? "show d-block" : "d-none"}`}
            tabIndex={-1}
            role="dialog"
            aria-labelledby="imageModalLabel"
            aria-hidden="true"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                {images.map((image, index) => (
                                    <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                        <img src={image} className="d-block w-100" alt={`Slika ${index + 1}`} />
                                    </div>
                                ))}
                            </div>
                            <button
                                className="carousel-control-prev"
                                type="button"
                                data-bs-target="#carouselExampleControls"
                                data-bs-slide="prev"
                            >
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                                className="carousel-control-next"
                                type="button"
                                data-bs-target="#carouselExampleControls"
                                data-bs-slide="next"
                            >
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ReklamacijeLista: React.FC = () => {
    const [data, setData] = useState<ReklamacijeModel[]>([]);
    const [loading, setLoading] = useState(true);
    const [params, setparams] = useState<ParamsModelReklamacije>({ per_page: 10, page: 1 });
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const [showModal, setShowModal] = useState(false);
    const [currentImages, setCurrentImages] = useState<string[]>([]);

    const handleOpenModal = (images: string[]) => {
        setCurrentImages(images);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [sorting, setSorting] = useState<SortingState>([]);
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [columnFilters, setColumnFilters] = useState([]);
    const [rowSelection, setRowSelection] = useState({});

    const updatePage = () => {
        setLoading(true);
        getAllPaneliReklamacije(params)
            .then((response) => {
                setData(response.data["data"]);
                const totalPage = Math.floor(response.data["total"] / response.data["per_page"] + 1);
                setpagination({
                    curPage: response.data["current_page"],
                    totalPage: totalPage,
                    perPage: params.per_page,
                });
            })
            .catch((error) => {
                console.error("Error: ", error);
                notifyToast(false, error);
            })
            .finally(() => {
                setLoading(false);
                setRowSelection({});
            });
    };

    useEffect(() => {
        updatePage();
    }, [params]);

    useEffect(() => {
        const { id } = sorting[0] ?? "";
        data.sort((a, b) => a[id] - b[id]);
    }, [sorting]);

    const columns = [
        {
            header: "Id",
            accessorKey: "Id",
        },
        {
            header: "Broj računa",
            accessorKey: "BrojRacuna",
        },
        {
            header: "Kupac",
            accessorKey: "paneli_proizvodnja.kupac",
        },
        {
            header: "Radni Nalog",
            accessorKey: "RadniNalog",
        },
        {
            header: "Količina",
            accessorKey: "paneli_proizvodnja.kolicina",
        },
        {
            header: "Rekl. Kolicina",
            accessorKey: "ReklamiranaKolicina",
        },
        {
            header: "Troskovi",
            accessorKey: "TroskoviReklamacije",
        },
        {
            header: "Datum",
            accessorFn: (row) => `${moments(row.Kreirano).format("DD-MM-YYYY")}`,
        },
        {
            header: "Kreirao",
            accessorKey: "uposlenik.UposlenikImePrezime",
        },
        {
            header: "Dokumenti", // New column for documents
            id: "dokumenti", // Provide an ID for the column
            cell: ({ row }) => {
                const documents = [
                    row.original.ZahtjevZaReklamacijuDoc,
                    row.original.ZapisnikSaTerenaDoc,
                    row.original.OpravdanostReklamacijeDoc,
                    row.original.ZapisnikReklamacijeDoc,
                    row.original.KnjiznoOdobenjeDoc,
                ];

                return (
                    <div className="d-flex">
                        {documents.map((doc, index) => (
                            <a
                                key={index}
                                href={doc || "#"}
                                target={doc ? "_blank" : "_self"}
                                rel="noopener noreferrer"
                            >
                                <i
                                    className={`bi bi-file-pdf fs-2 me-3 ${doc ? "text-success" : "text-danger"}`} // Add more spacing between icons
                                ></i>
                            </a>
                        ))}
                    </div>
                );
            },
        },
        {
            header: "Slike", // New column for images
            id: "slike",
            cell: ({ row }) => {
                const images = [
                    row.original.Slika1,
                    row.original.Slika2,
                    row.original.Slika3,
                    row.original.Slika4,
                    row.original.Slika5,
                    row.original.Slika6,
                    row.original.Slika7,
                    row.original.Slika8,
                    row.original.Slika9,
                    row.original.Slika10,
                ].filter(Boolean); // Filter out empty or undefined images

                return (
                    <>
                        {images.length > 0 && (
                            <img
                                src={images[0]}
                                alt="Slika1"
                                className="img-thumbnail"
                                style={{ width: "100px", cursor: "pointer" }}
                                onClick={() => handleOpenModal(images)} // Pass the images to the handler
                            />
                        )}
                    </>
                );
            },
        },
        {
            header: "Akcija",
            accessorKey: "Action",
            cell: ({ row }) => (
                <TableActionButton
                    onDelete={() => onDelete(row.original.Id)}
                    onEdit={() => {
                        onEdit(row.original.Id);
                    }}
                />
            ),
        },
    ];

    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
            columnFilters: columnFilters,
            rowSelection: rowSelection,
        },
        // @ts-ignore
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onRowSelectionChange: setRowSelection,
        enableRowSelection: true,
    });

    const onDelete = (id) => {
        deletePaneliReklamacije(id)
            .then((response) => {
                updatePage();
                console.log(response.data.message);
                notifyToast(true, response.data["message"]);
            })
            .catch((error) => {
                notifyToast(false, error.data["message"]);
            });
    };

    const navigate = useNavigate();

    const onEdit = (rowData) => {
        navigate(`/paneli/reklamacije/izmjena/${rowData}`);
    };

    return (
        <KTCard>
            <ToastContainer />
            <KTCardBody className="py-4">
                <div className="d-flex justify-content-between">
                    <div data-kt-user-table-toolbar="base">
                        <TableSearch onSearch={(text) => setGlobalFilter(text)} />
                    </div>
                    <div data-kt-user-table-toolbar="base">
                        <ExportButton table={table} filename={"ugovori"} />
                        <Link to="/paneli/reklamacije/dodaj">
                            <button type="button" className="btn btn-primary">
                                <KTIcon iconName="plus" className="fs-2" />
                                Dodaj
                            </button>
                        </Link>
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent />
                ) : (
                    <div className="table-responsive mt-3 ">
                        <table className="table align-middle table-bordered gy-5 no-footer">
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className="text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2"
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className="text-white"
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{ asc: "🔼", desc: "🔽" }[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className="text-gray-600">
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div className="d-flex text-center w-100 align-content-center justify-content-center">
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({ ...params, page: page, per_page: per_page })}
                    pagination={pagination}
                />
            </KTCardBody>
            <ImageModal images={currentImages} show={showModal} onClose={handleCloseModal} />
        </KTCard>
    );
};

export default ReklamacijeLista;
