import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import notifyToast from '../../../../components/Toast/Toast'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import clsx from 'clsx'
import {GlavniIzvjestajModel, ShiftsModel, ZapisiModel} from '../../../../models/IzvjestajModel'
import {getGlavniIzvjestaj} from '../../../../api/izvjestaj'
import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import {KTCard, KTCardBody} from '../../../../../_websoft/helpers'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../../../components/TitleComponent/TitleComponent'

const calculateStimulationValue = (dozvoljenSkartLimova, skartLimovaMinusDozvoljeno) => {
    let stimulationValue = 0;
    let diffPercent = 0;

    if (skartLimovaMinusDozvoljeno < 0) {
        diffPercent = Math.round(Math.abs(skartLimovaMinusDozvoljeno) / Math.abs(dozvoljenSkartLimova) * 100);

        if (diffPercent >= 10 && diffPercent < 20) {
            stimulationValue = 2;
        } else if (diffPercent >= 20 && diffPercent < 30) {
            stimulationValue = 3;
        } else if (diffPercent >= 30 && diffPercent < 40) {
            stimulationValue = 4;
        } else if (diffPercent >= 40) {
            stimulationValue = 5;
        }
    } else if (skartLimovaMinusDozvoljeno > 0) {
        diffPercent = skartLimovaMinusDozvoljeno / dozvoljenSkartLimova * 100;

        if (diffPercent >= 10 && diffPercent < 20) {
            stimulationValue = -2;
        } else if (diffPercent >= 20 && diffPercent < 30) {
            stimulationValue = -3;
        } else if (diffPercent >= 30 && diffPercent < 40) {
            stimulationValue = -4;
        } else if (diffPercent >= 40) {
            stimulationValue = -5;
        }
    }

    return stimulationValue;
}

const GlavniIzvjestaj = () => {
    const [loading, setLoading] = useState(false)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')
    const initialValues = {
        datumOd: undefined,
        datumDo: undefined,
    }
    const validationSchema = Yup.object({
        datumOd: Yup.string().required('Required'),
        datumDo: Yup.string().required('Required'),
    })

    const [data, setdata] = useState<Array<ShiftsModel>>([])
    const [GlavniIzvjestaj, setGlavniIzvjestaj] = useState<GlavniIzvjestajModel>()

    const [zapisiData, setzapisiData] = useState<Array<ZapisiModel>>([])

    useEffect(() => {
        if (data.length) {
            const flattenedZapisi = data.map((shift) => shift.zapisi).flat()

            console.log(flattenedZapisi)
            setzapisiData(flattenedZapisi)
        }
    }, [data])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                let response
                response = await getGlavniIzvjestaj(values)

                const responseData = response.data

                if (responseData.error === 0) {
                    setdata(response.data['data']['shifts'])
                    setGlavniIzvjestaj(response.data['data'])
                    notifyToast(true, responseData.message)
                } else {
                    notifyToast(false, responseData.message || 'An unexpected error occurred.')
                }
            } catch (error: any) {
                console.error('Error: ', error)
                notifyToast(false, error.response.data.message)
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        },
    })

    const columns = [
        {id: 'TipPanela', header: 'Tip Panela', accessorKey: 'naziv'},
        {id: 'Smjena', header: 'Smjena', accessorKey: 'smjena'},
        {id: 'Proizvedeno', header: 'Brutto M2', accessorKey: 'proizvedeno'},
        {id: 'Skart', header: 'Skart', accessorKey: 'skart'},
        {id: 'Skart V2', header: 'Skart V2', accessorKey: 'skartV2'},
        {id: 'SkartProcenat', header: 'Skart %', accessorKey: 'skartProcenat'},
        {
            id: 'SkartProcenatV2', header: 'Skart V2 %', accessorKey: 'skartProcenatV2', cell: (info) => {
                const originalRow = info.row.original;
                const skartProcenat = originalRow.skartProcenat;
                const skartProcenatV2 = originalRow.skartProcenatV2;

                return (
                    <span style={{color: skartProcenat !== skartProcenatV2 ? 'red' : 'black'}}>
                    {skartProcenatV2}
                </span>
                );
            }
        },
        {id: 'SkartRavniLimova', header: 'Skart Ravni Limova', accessorKey: 'skartRavniLimova'},
        {id: 'SkartTrapeza', header: 'Skart Trapeza', accessorKey: 'skartTrapeza'},
        {id: 'BrojStiklanja', header: 'Broj Stiklanja', accessorKey: 'brojStiklanja'},
        {id: 'Netto', header: 'Netto M2', accessorFn: (row) => `${(row.proizvedeno - row.skart).toFixed(2)}`},
        {id: 'MIN', header: 'M/MIN', accessorKey: 'metaraPoMinuti'},
        {
            id: 'Proizvodnje',
            header: 'Vrijeme proizvodnje',
            accessorFn: (row) => (row.proizvedeno / row.metaraPoMinuti / 60).toFixed(2)
        },
        {id: 'izmjenaAlata', header: 'Izmjena alata', accessorKey: 'izmjenaAlata'},
        {id: 'zastojTrajao', header: 'Zastoj trajao', accessorKey: 'zastojTrajao'},
    ]

    const table = useReactTable({
        data: zapisiData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),

        state: {
            sorting: sorting,
            globalFilter: globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
    })

    const GetCustomColumn = ({row}) => {
        const item = data.filter((val) => val.smjena === row.original.smjena)[0]
        const stimulationValue = calculateStimulationValue(item.dozvoljenSkartLimova, item.skartLimovaMinusDozvoljeno)

        return (
            <>
                <tr style={{backgroundColor: '#ACE6E1'}}>
                    <td colSpan={2}><h2>Smjena {item.smjena}</h2></td>
                    <td>
                        <h4>Brutto: <hr/>{item.bruttoProizvedeno} m2</h4>
                    </td>
                    <td>
                        <h4>Škart: <hr/>{item.skart} m2</h4>
                    </td>
                    <td>
                        <h4>Škart V2: <hr/>{item.skartV2} m2</h4>
                    </td>
                    <td>
                        <h4>Škart: <hr/>{item.skartProcenat} %</h4>
                    </td>
                    <td>
                        <h4>Škart V2 %: <hr/>{item.skartV2Procenat} m2</h4>
                    </td>
                    <td>
                        <h4>Škart Ravni Lim: <hr/>{item.skartRavniLimova} m2</h4>
                    </td>
                    <td>
                        <h4>Škart Trapez: <hr/>{item.skartTrapeza} m2</h4>
                    </td>
                    <td>
                        <h4>Štiklanje: <hr/>{item.brojStiklanja} kom.</h4>
                    </td>
                    <td>
                        <h4>Netto: <hr/>{item.nettoProizvedeno} m2</h4>
                    </td>
                    <td>[][][]</td>
                    <td>
                        <h4>Vrijeme: <hr/>{item.vrijemeZaProizvodnju.toFixed(2)} h</h4>
                    </td>
                    <td>
                        <h4>Izmjena: <hr/>( {item.izmjenaAlata} X ) - {item.izmjenaAlataVrijeme} h</h4>
                    </td>
                    <td>
                        <h4>Zastoj: <hr/>{item.zastoj} h</h4>
                    </td>
                </tr>
                <tr style={{backgroundColor: '#ACC5E6'}}>
                    <td colSpan={2}><h1>Smjena {item.smjena}</h1></td>
                    <td colSpan={2} width='50%'>
                        <h4>Škart limova: <hr/>{item.totalSkartLimova}</h4>
                    </td>
                    <td colSpan={3} width='50%'>
                        <h4>Štiklanje <hr/>[ {item.brojStiklanja} ]</h4>
                    </td>
                    <td colSpan={3} width='50%'>
                        <h4>Broj zastoja <hr/>[ {item.brojZastoja} ]</h4>
                    </td>
                    <td colSpan={3} width='50%'>
                        <h4>Dozvoljeni škart lima <hr/>[ {item.dozvoljenSkartLimova} ]</h4>
                    </td>
                    <td colSpan={3} width='50%'>
                        <h4>Stimulacija za lim <hr/>[ {stimulationValue} ]</h4>
                    </td>
                </tr>
                <tr style={{backgroundColor: '#ACC5E6'}}>
                    <td colSpan={2}><h1>Smjena {item.smjena}</h1></td>
                    <td colSpan={2} width='50%'>
                        <h4>Smjena radila: <hr/>{item.totalDana} radnih dana ({item.totalSati} h)</h4>
                    </td>
                    <td colSpan={3} width='50%'>
                        <h4>Vrijeme potrebno za proizvodnju <hr/>[ {item.vrijemeZaProizvodnju.toFixed(2)} h ]</h4>
                    </td>
                    <td colSpan={4} width='50%'>
                        <h4>Utrošeno vrijeme za proizvodnju (zastoj + izmjena alata): <hr/>[ {item.utrosenoVrijeme} h]
                        </h4>
                    </td>
                    <td colSpan={4} width='50%'>
                        <h4>Procenat proizvodnje: <hr/> {item.procenatProizvodnje} %</h4>
                    </td>
                </tr>
            </>
        )
    }

    const GetCustomColumn2 = () => {
        return (
            <>
                <tr>
                    <td style={{backgroundColor: '#385B66'}} colSpan={13}>
                        <h1 style={{textAlign: 'center', color: 'white'}}>TOTAL</h1>
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}><h3>Brutto: {GlavniIzvjestaj?.totals?.bruttoTotal?.toFixed(2)} [m2]</h3></td>
                    <td colSpan={3}><h3>Netto: {GlavniIzvjestaj?.totals?.nettoTotal?.toFixed(2)} [m2]</h3></td>
                    <td colSpan={3}><h3>Škart: {GlavniIzvjestaj?.totals?.skartTotal?.toFixed(2)} [m2]</h3></td>
                    <td colSpan={3}><h3>Škart: {GlavniIzvjestaj?.totals?.skartProcenatTotal} %</h3></td>
                </tr>
                <tr>
                    <td colSpan={3}><h3>Škart Ravni
                        Limova: {GlavniIzvjestaj?.totals?.skartRavniLimovaTotal?.toFixed(2)} [m2]</h3></td>
                    <td colSpan={3}><h3>Škart
                        Trapeza: {GlavniIzvjestaj?.totals?.skartTrapezaTotal?.toFixed(2)} [m2]</h3></td>
                    <td colSpan={7}><h3>Broj
                        štiklanja: {GlavniIzvjestaj?.totals?.brojStiklanjaTotal?.toFixed(2)} [kom]</h3></td>
                </tr>
                <tr>
                    <td colSpan={6}><h3>Izmjena Alata: {GlavniIzvjestaj?.totals?.izmjenaAlataTotal} [kom]</h3></td>
                    <td colSpan={7}><h3>Ukupan Zastoj: {GlavniIzvjestaj?.totals?.zastojTotal} [h]</h3></td>
                </tr>
                <tr>
                    <td style={{backgroundColor: '#E6D2AC'}} colSpan={15}>
                        <h1 style={{textAlign: 'center'}}>STIMULACIJA V1 (STARI MODEL)</h1>
                    </td>
                </tr>
                <tr style={{backgroundColor: 'lightblue', width: '100%'}}>
                    <td colSpan={2}><h2>Smjena A: {GlavniIzvjestaj?.shiftResults.A}</h2></td>
                    <td colSpan={2}><h2>Smjena B: {GlavniIzvjestaj?.shiftResults.B} %</h2></td>
                    <td colSpan={2}><h2>Smjena C: {GlavniIzvjestaj?.shiftResults.C} %</h2></td>
                    <td colSpan={3}><h2>Smjena D: {GlavniIzvjestaj?.shiftResults.D} %</h2></td>
                    <td colSpan={3}><h2>Smjena E: {GlavniIzvjestaj?.shiftResults.E} %</h2></td>
                    <td colSpan={3}><h2>Smjena F: {GlavniIzvjestaj?.shiftResults.F} %</h2></td>
                </tr>
                <tr>
                    <td style={{backgroundColor: '#E6D2AC'}} colSpan={15}>
                        <h1 style={{textAlign: 'center'}}>STIMULACIJA V2 (Skart V2, Stimulacija Smjene, Stimulacija
                            Lima)</h1>
                    </td>
                </tr>
                <tr style={{backgroundColor: 'lightblue', width: '100%'}}>
                    <td colSpan={2}>
                        <h2>
                            Smjena A: {
                            GlavniIzvjestaj && GlavniIzvjestaj.stimulacijaSmjene && GlavniIzvjestaj.stimulacijaLimovi
                                ? (GlavniIzvjestaj.stimulacijaSmjene.A + (GlavniIzvjestaj.shiftResultsSkartV2.A - GlavniIzvjestaj.shiftResults.A) + (GlavniIzvjestaj.stimulacijaSmjene.A * GlavniIzvjestaj.stimulacijaLimovi.A) / 100).toFixed(2)
                                : '0.00'
                        } %
                        </h2>
                    </td>
                    <td colSpan={2}>
                        <h2>
                            Smjena B: {
                            GlavniIzvjestaj && GlavniIzvjestaj.stimulacijaSmjene && GlavniIzvjestaj.stimulacijaLimovi
                                ? (GlavniIzvjestaj.stimulacijaSmjene.B + (GlavniIzvjestaj.stimulacijaSmjene.B * GlavniIzvjestaj.stimulacijaLimovi.B) / 100).toFixed(2)
                                : '0.00'
                        } %
                        </h2>
                    </td>
                    <td colSpan={3}>
                        <h2>
                            Smjena C: {
                            GlavniIzvjestaj && GlavniIzvjestaj.stimulacijaSmjene && GlavniIzvjestaj.stimulacijaLimovi
                                ? (GlavniIzvjestaj.stimulacijaSmjene.C + (GlavniIzvjestaj.stimulacijaSmjene.C * GlavniIzvjestaj.stimulacijaLimovi.C) / 100).toFixed(2)
                                : '0.00'
                        } %
                        </h2>
                    </td>
                    <td colSpan={3}>
                        <h2>
                            Smjena D: {
                            GlavniIzvjestaj && GlavniIzvjestaj.stimulacijaSmjene && GlavniIzvjestaj.stimulacijaLimovi
                                ? (GlavniIzvjestaj.stimulacijaSmjene.D + (GlavniIzvjestaj.stimulacijaSmjene.D * GlavniIzvjestaj.stimulacijaLimovi.D) / 100).toFixed(2)
                                : '0.00'
                        } %
                        </h2>
                    </td>
                    <td colSpan={3}>
                        <h2>
                            Smjena E: {
                            GlavniIzvjestaj && GlavniIzvjestaj.stimulacijaSmjene && GlavniIzvjestaj.stimulacijaLimovi
                                ? (GlavniIzvjestaj.stimulacijaSmjene.E + (GlavniIzvjestaj.stimulacijaSmjene.E * GlavniIzvjestaj.stimulacijaLimovi.E) / 100).toFixed(2)
                                : '0.00'
                        } %
                        </h2>
                    </td>
                    <td colSpan={2}>
                        <h2>
                            Smjena F: {
                            GlavniIzvjestaj && GlavniIzvjestaj.stimulacijaSmjene && GlavniIzvjestaj.stimulacijaLimovi
                                ? (GlavniIzvjestaj.stimulacijaSmjene.F + (GlavniIzvjestaj.stimulacijaSmjene.F * GlavniIzvjestaj.stimulacijaLimovi.F) / 100).toFixed(2)
                                : '0.00'
                        } %
                        </h2>
                    </td>
                </tr>
            </>
        )
    }

    return (
        <KTCard>
            <ToastContainer/>
            <KTCardBody className='py-4'>
                <div className='card-title m-0'>
                    <TitleComponent name='Glavni Izvjestaj'/>
                </div>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className='col'>
                        {/* Rolne form */}
                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                            <div className='card-body border-top p-9'>
                                <div className='row'>
                                    {/* datumOd */}
                                    <div className='col-6 mb-6'>
                                        <label className='col-auto col-form-label fw-bold fs-6'>datumOd</label>

                                        <div className='col-auto fv-row'>
                                            <input
                                                type='date'
                                                placeholder='datumOd'
                                                {...formik.getFieldProps('datumOd')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {
                                                        'is-invalid': formik.touched.datumOd && formik.errors.datumOd,
                                                    },
                                                    {
                                                        'is-valid': formik.touched.datumOd && !formik.errors.datumOd,
                                                    }
                                                )}
                                            />
                                            {formik.touched.datumOd && formik.errors.datumOd && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.datumOd}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* datumDo */}
                                    <div className='col-6 mb-6'>
                                        <label className='col-auto col-form-label fw-bold fs-6'>datumDo</label>

                                        <div className='col-auto fv-row'>
                                            <input
                                                type='date'
                                                placeholder='datumDo'
                                                {...formik.getFieldProps('datumDo')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {
                                                        'is-invalid': formik.touched.datumDo && formik.errors.datumDo,
                                                    },
                                                    {
                                                        'is-valid': formik.touched.datumDo && !formik.errors.datumDo,
                                                    }
                                                )}
                                            />
                                            {formik.touched.datumDo && formik.errors.datumDo && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.datumDo}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                        <button
                                            type='submit'
                                            className='btn btn-primary'
                                            disabled={loading || formik.isSubmitting || !formik.isValid}
                                        >
                                            {!loading && 'Potvrdi'}
                                            {loading && (
                                                <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                                                    <span
                                                        className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {/* Table */}
                        <div className='table-responsive mt-3 '>
                            <table className='table align-middle table-bordered gy-5 no-footer'>
                                <thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr
                                        key={headerGroup.id}
                                        className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                    >
                                        {headerGroup.headers.map((header) => (
                                            <th
                                                className='text-white'
                                                key={header.id}
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {/* @ts-ignore */}
                                                {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>
                                <tbody className='text-gray-600'>
                                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                    table.getRowModel().rows.map((row, i, arr) =>
                                        i > 0 &&
                                        (i === arr.length - 1 ||
                                            arr[i].original.smjena !== arr[i + 1].original.smjena) ? (
                                            <>
                                                <tr key={row.id}>
                                                    {row.getVisibleCells().map((cell) => (
                                                        <td key={cell.id}>
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </td>
                                                    ))}
                                                </tr>
                                                <GetCustomColumn row={arr[i]}/>
                                                {i === arr.length - 1 && <GetCustomColumn2/>}
                                            </>
                                        ) : (
                                            <tr key={row.id}>
                                                {row.getVisibleCells().map((cell) => (
                                                    <td key={cell.id}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </td>
                                                ))}
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan={7}>
                                            <div
                                                className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                No matching records found
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                        {/* Pagination */}
                        {/* <TablePagination
            updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
            pagination={pagination}
          /> */}
                        {/* End */}
                    </div>
                )}
            </KTCardBody>
        </KTCard>
    )
}

export default GlavniIzvjestaj
