import React, {useEffect, useState} from 'react'
import KupciPregledPdf from '../KupciPregledPdf/KupciPregledPdf'
import {usePDF} from '@react-pdf/renderer'
import {KTCard, KTCardBody} from '../../../../../_websoft/helpers'
import {useLocation, useParams} from 'react-router-dom'
import {getKupciPregled} from '../../../../api/proizvodnja'
import notifyToast from '../../../../components/Toast/Toast'

const KupciPdfProcessor = () => {
  const {id} = useParams()
  const location = useLocation()
  const kupac: string = location.state.kupac
  const [data, setdata] = useState({})
  useEffect(() => {
    if (id) {
      //   setloading(true)
      getKupciPregled(id)
        .then((response) => {
          setdata(response)

          updateInstance(<KupciPregledPdf pregledData={response} kupac={kupac} />)
        })
        .catch((error) => {
          console.error(error)
          notifyToast(false, error?.response.data.message ?? error)
        })
      // .finally(() => setloading(false))
    }
  }, [id])
  const [instance, updateInstance] = usePDF({})

  return (
    <KTCard>
      <KTCardBody>
        {instance && instance.url ? (
          <a href={instance.url} target='_blank' rel='noreferrer' className='btn btn-success m-6'>
            Otvori PDF
          </a>
        ) : (
          <div className='btn btn-dark m-6'>
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </div>
        )}
      </KTCardBody>
    </KTCard>
  )
}

export default KupciPdfProcessor
