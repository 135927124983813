/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import SearchableSelect from '../SearchableSelect/SearchableSelect'
import {getSredstvoStatusDropdown} from '../../api'
import {Option} from '../../models/Sektor'

// skladisteRepromaterijala Dropdown Props for the component
interface DropdownProps {
    value?: string | number
    initialValue?: string | number
    onChange?: (name: string, value: any) => void
    errors?: string | undefined
    touched?: boolean
    selectName?: string
}

const StatusSredstvaDropdown: React.FC<DropdownProps> = ({
                                                                       value,
                                                                       initialValue,
                                                                       onChange,
                                                                       errors,
                                                                       touched,
                                                                       selectName,
                                                                   }) => {
    const [statusDropdown, setStatusDropdown] = useState<
        Array<Option>
        >([])
    const internalValue = initialValue ?? null

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getSredstvoStatusDropdown()
                setStatusDropdown(mapData(response.data))
            } catch (error) {
                console.error('Error fetching Skladiste dropdown data:', error)
            }
        }
        fetchData()
    }, [])

    const finalValue = value ?? internalValue

    const mapData = (data) => {
        if (!data) {
            return []
        }
        return data.map((item) => ({label: item.Naziv, value: item.Id.toString()}))
    }

    return (
        <SearchableSelect
            options={statusDropdown}
            value={finalValue?.toString() ?? ''}
            onChange={onChange!}
            name={selectName ?? 'statusDropdown'}
            errors={errors}
            placeholder='Odaberi Status Sredstva'
            touched={touched}
        />
    )
}

export default StatusSredstvaDropdown

