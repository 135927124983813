import {ToastContainer} from 'react-toastify'
import React, {useState, useEffect} from 'react'
import {KTCard, KTCardBody} from '../../../_websoft/helpers'
import {imaLiAktivanZapis} from '../../api/radniNalozi'
import notifyToast from '../../components/Toast/Toast'
import {useNavigate} from 'react-router-dom'
import {useAuth} from "../../modules/auth";

const TerminaliDashboard: React.FC = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const onRadniKrajClick = () => {
        setLoading(true)
        imaLiAktivanZapis()
            .then((response) => {
                if (response.data.Id) {
                    navigate(`/terminali/radni-nalizi/${response.data.Id}`)
                }
            })
            .catch((error) => {
                notifyToast(false, error.message)
                navigate(`/terminali/radni-nalog-start/pocetna`)
            })
            .finally(() => setLoading(false))
    }

    const [uposlenikId, setUposlenikId] = useState('');

    useEffect(() => {
        const storedData = localStorage.getItem('kt-auth-react-v');
        if (storedData) {
            const dataObject = JSON.parse(storedData);
            setUposlenikId(dataObject.UposlenikId);
        }
    }, []);

    const handleMojeAktivnosti = () => {
        const now = new Date();
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const lastDayOfPreviousMonth = new Date(now.getFullYear(), now.getMonth(), 0);
        let yesterday = new Date(now.setDate(now.getDate() - 1));

        // Check if today is the first day of the month
        if (now.getDate() === firstDayOfMonth.getDate()) {
            // Set yesterday to the first day of the current month, as per your requirement
            yesterday = firstDayOfMonth;
        } else if (yesterday.getDate() === lastDayOfPreviousMonth.getDate()) {
            // If actual yesterday was the last day of the previous month, adjust if needed
            // This else-if is optional based on your logic needs
        }

        const datumOd = `${firstDayOfMonth.getFullYear()}-${String(firstDayOfMonth.getMonth() + 1).padStart(2, '0')}-${String(firstDayOfMonth.getDate()).padStart(2, '0')}`;
        const datumDo = `${yesterday.getFullYear()}-${String(yesterday.getMonth() + 1).padStart(2, '0')}-${String(yesterday.getDate()).padStart(2, '0')}`;

        const uposlenik = uposlenikId

        navigate('/terminali/uposlenici/izvjestaj', {
            state: {
                uposlenikId: uposlenik,
                datumOd: datumOd,
                datumDo: datumDo,
            },
        })
    }
    return (
        <KTCard>
            <ToastContainer/>
            <KTCardBody className='py-4'>
                <div className='row'>
                    <div className='col-xl-4 col-sm-12 m-5'>
                        <div className='row p-3' style={{ border: '1px solid #000', position: 'relative' }}>
                            <div className='col-xl-6 mt-2'>
                                <button
                                    onClick={onRadniKrajClick}
                                    disabled={loading}
                                    className='card hoverable  mb-xl-8'
                                    style={{width: '100%', backgroundColor: '#54bda3'}}
                                >
                                    <div className='card-body' style={{textAlign: 'start'}}>
                                        <div className='text-white fw-bold fs-2 mb-2 mt-5'>Start <hr/> </div>
                                        <div className='fw-semibold text-white'>Radni Nalog</div>
                                    </div>
                                </button>
                            </div>
                            <div className='col-xl-6 mt-2'>
                                <button
                                    className='card hoverable card-xl-stretch mb-xl-8'
                                    onClick={onRadniKrajClick}
                                    disabled={loading}
                                    style={{width: '100%', backgroundColor: '#ee3e3e'}}
                                >
                                    <div className='card-body' style={{textAlign: 'start'}}>
                                        <div className='text-white fw-bold fs-2 mb-2 mt-5'>Kraj <hr/> </div>
                                        <div className='fw-semibold text-white'>Radni Nalog</div>
                                    </div>
                                </button>
                            </div>
                            <div className='col-xl-12 mt-2'>
                                <a
                                    href='radni-nalozi/pocetna'
                                    className='card hoverable card-xl-stretch mb-xl-8'
                                    style={{backgroundColor: '#354f60'}}
                                >
                                    <div className='card-body'>
                                        <div className='text-white fw-bold fs-2 mb-2 mt-5'>Radni nalozi  <hr/></div>
                                        <div className='fw-semibold text-white'>Spremni nalog, Isporuči nalog</div>
                                    </div>
                                </a>
                            </div>
                            <div className='d-flex justify-content-center' style={{ position: 'absolute', bottom: '-10px', left: '50%', transform: 'translateX(-50%)' }}>
                                <i className="fas fa-solid fa-file-circle-check" style={{ backgroundColor: '#FFF', borderRadius: '50%', fontSize: '34px', color: '#000', paddingTop: '10px' }}></i>
                            </div>
                        </div>
                        <div className='col-12 mt-10'>
                            <a
                                href='prijava-kvara/dodaj'
                                className='card hoverable card-xl-stretch mb-xl-8'
                                style={{backgroundColor: '#545454'}}>
                                <div className='card-body'>
                                    <div className='text-white fw-bold fs-2 mb-2 mt-5'>Kreiraj prijavu kvara</div>
                                    <div className='fw-semibold text-white'>Urgentna / redovna prijava</div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 m-5'>
                        <div className='row p-3' style={{ border: '1px solid #000', position: 'relative' }}>
                            <div className='col-12 mt-2'>
                                <a href='repromaterijali' className='card hoverable card-xl-stretch mb-xl-8'
                                   style={{backgroundColor: '#504165'}}>
                                    <div className='card-body'>
                                        <div className='text-white fw-bold fs-2 mb-2 mt-5'>Repromaterijali <hr/></div>
                                        <div className='fw-semibold text-white'>Lista, Promet, Rolne u dolasku</div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-12 mt-2'>
                                <a href='termopaneli' className='card hoverable card-xl-stretch mb-xl-8'
                                   style={{backgroundColor: '#30293b'}}>
                                    <div className='card-body'>
                                        <div className='text-white fw-bold fs-2 mb-2 mt-5'>Termoizolacioni paneli <hr/></div>
                                        <div className='fw-semibold text-white'>
                                            Proizvodnja, Trebovanje, Glavna knjiga, Nalozi, Izvještaji
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-12 mt-2'>
                                <a href='artikal/pocetna' className='card hoverable card-xl-stretch mb-xl-8'
                                   style={{backgroundColor: '#30293b'}}>
                                    <div className='card-body'>
                                        <div className='text-white fw-bold fs-2 mb-2 mt-5'>Artikli <hr/></div>
                                        <div className='fw-semibold text-white'>Lista artikala, palete, promet</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-sm-12 m-5'>
                        <div className='row p-3' style={{ border: '1px solid #000', position: 'relative' }}>
                            <div className='col-12 mt-2'>
                                <a
                                    href='dashboard-rasjecanje'
                                    className='card hoverable card-xl-stretch mb-xl-8'
                                    style={{backgroundColor: '#4cb9c6'}}
                                >
                                    <div className='card-body align-text-start'>
                                        <div className='text-white fw-bold fs-2 mb-2 mt-5'>Rasjecanje / Odmotavanje <hr/></div>
                                        <div className='fw-semibold text-white'></div>
                                    </div>
                                </a>
                            </div>
                            <div className='col-12 mt-2'>
                                <a
                                    href='galanterija/pocetna'
                                    className='card hoverable card-xl-stretch mb-xl-8'
                                    style={{backgroundColor: '#538794'}}
                                >
                                    <div className='card-body'>
                                        <div className='text-white fw-bold fs-2 mb-2 mt-5'>Galanterija <hr/></div>
                                        <div className='fw-semibold text-white'>Artikli</div>
                                    </div>
                                </a>
                            </div>
                            <div className='d-flex justify-content-center' style={{ position: 'absolute', bottom: '-10px', left: '50%', transform: 'translateX(-50%)' }}>
                                <i className="fas fa-solid fa-file-circle-check" style={{ backgroundColor: '#FFF', borderRadius: '50%', fontSize: '34px', color: '#000', paddingTop: '10px' }}></i>
                            </div>
                        </div>
                        <div className='col-12 mt-11'>
                            <a
                                href='prijedlozi-za-poboljsanje/dodaj'
                                className='card hoverable card-xl-stretch mb-xl-8'
                                style={{backgroundColor: '#7e2b2b'}}
                            >
                                <div className='card-body'>
                                    <div className='text-white fw-bold fs-2 mb-2 mt-5'>Prijedlozi za poboljšanje<hr/></div>
                                    <div className='fw-semibold text-white'>Kreiraj prijedlog</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='row mt-1'>
                    <div className='col-lg-3 mt-2'>
                        <a
                            href='prijava-kvara/pocetna'
                            className='card hoverable card-xl-stretch mb-xl-8'
                            style={{backgroundColor: '#8e8e8e'}}
                        >
                            <div className='card-body'>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Prijave kvarova<hr/></div>
                                <div className='fw-semibold text-white'>Kvarovi, Intervencije, Preuzmi kvar</div>
                            </div>
                        </a>
                    </div>
                    <div className='col-lg-3 mt-2'>
                        <a
                            href='zahtjev-za-nabavu/dodaj'
                            className='card  hoverable card-xl-stretch mb-xl-8'
                            style={{backgroundColor: '#307a70'}}>
                            <div className='card-body'>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Kreiraj zahtjev za nabavu<hr/></div>
                                <div className='fw-semibold text-white'>Zahtjevi za nabavu</div>
                            </div>
                        </a>
                    </div>
                    <div className='col-lg-3 mt-2'>
                        <a
                            href='viljuskari/pocetna'
                            className='card  hoverable card-xl-stretch mb-xl-8'
                            style={{backgroundColor: '#54bda3'}}>
                            <div className='card-body'>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Viljuskari<hr/></div>
                                <div className='fw-semibold text-white'>Kreiraj zapis rada / intervencije</div>
                            </div>
                        </a>
                    </div>
                    <div className='col-lg-3 mt-2'>
                        <a
                            onClick={handleMojeAktivnosti}
                            className='card hoverable card-xl-stretch mb-xl-8'
                            style={{cursor: 'pointer', backgroundColor: '#c24a22'}}
                        >
                            <div className='card-body'>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Moje aktivnosti [tekući mjesec]<hr/></div>
                                <div className='fw-semibold text-white'>Galanterija, Proizvodnja jedn. limova</div>
                            </div>
                        </a>
                    </div>
                </div>
            </KTCardBody>
        </KTCard>
    )
}
export default TerminaliDashboard
