/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {PaleteIzlaziModel} from '../../models/PaleteIzlaziModel'
import {fetchPaleteIzlazi, savePaleteIzlazi, updatePaleteIzlazi} from '../../api/PaleteIzlazi'
import * as Yup from 'yup'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import ArtikliDropdown from '../../components/ArtikliDropdown/ArtikliDropdown'
import clsx from 'clsx'
import {useFormik} from 'formik'
import PaleteIzlaziTableComponent from '../../components/PaleteIzlaziTableComponent/PaleteIzlaziTableComponent'
import {getStanjePalete} from '../../api'
import {StanjePaleteModel} from '../../models/StanjePaleteModel'
import useDebounce from '../../hooks/useDebounce'
import DokumentiDropdown from '../../components/DokumentiDropdown/DokumentiDropdown'

const PaleteIzlaziDetails = () => {
    const {id} = useParams()
    const IdFromURL = Number(id)

    const [loading, setLoading] = useState(true)
    const [refreshTable, setRefreshTable] = useState(false)

    const initialValues = {
        ArtPaletaArtikal: undefined,
        ArtPaletaBrojPalete: undefined,
        ArtPaletaDatum: undefined,
        ArtPaletaIzlaz: undefined,
    }
    const [initialData, setInitialData] = useState<Partial<PaleteIzlaziModel>>(initialValues)
    const [stanjePalete, setStanjePalete] = useState<StanjePaleteModel>()
    useEffect(() => {
        if (IdFromURL) {
            fetchPaleteIzlazi(IdFromURL)
                .then((data) => {
                    setInitialData(data)
                    formik.resetForm({values: data}) // <-- reset formik state here
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [IdFromURL])

    const validationSchema = Yup.object({
        ArtPaletaArtikal: Yup.string().required('Required'),
        ArtPaletaDatum: Yup.string().required('Required'),

        ArtPaletaBrojPalete: Yup.number().required('Required'),
        ArtPaletaDokument: Yup.number().required('Required'),
        ArtPaletaIzlaz: Yup.number()
            .max(stanjePalete?.ArtPaletaTrenutnoStanje ?? 0)
            .required(),
    })

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
            setLoading(true)
            try {
                let response
                if (IdFromURL) {
                    response = await updatePaleteIzlazi(IdFromURL, values)
                } else {
                    response = await savePaleteIzlazi(values)
                    setRefreshTable(true);
                    resetForm();
                }

                if (response.error === 0) {
                    notifyToast(true, response.message)
                } else {
                    notifyToast(false, response.message || 'An unexpected error occurred.')
                }
            } catch (error: any) {
                console.error('Error: ', error)
                notifyToast(false, error.response.data.message)
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        },
    })

    const brojPaleteDebounce = useDebounce(formik.values.ArtPaletaBrojPalete)
    useEffect(() => {
        if (formik.values.ArtPaletaArtikal && formik.values.ArtPaletaBrojPalete) {
            getStanjePalete({
                artikal: formik.values.ArtPaletaArtikal,
                paleta: formik.values.ArtPaletaBrojPalete,
            })
                .then((response) => {
                    const data = response.data['data']
                    setStanjePalete(data)
                })
                .catch((error) => {
                    notifyToast(false, error.response.data.message)
                    setStanjePalete(undefined)
                })
        }
    }, [formik.values.ArtPaletaArtikal, brojPaleteDebounce])

    return (
        <div className='row'>
            <ToastContainer/>
            <div className='card mb-5 mb-xl-10 col-6'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <TitleComponent name=''/>
                    </div>
                </div>
                <div id='kt_account_profile_details' className='collapse show'>
                    {loading ? (
                        <LoadingComponent/>
                    ) : (
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className='card-body border-top p-9 row'>
                                {/* Artikal */}
                                <div className='col-8 mb-6'>
                                    <div className='col'>
                                        <label className='col-auto col-form-label required fw-bold fs-6'>Artikal</label>
                                        <div className='col-auto fv-row'>
                                            <ArtikliDropdown
                                                value={formik.values.ArtPaletaArtikal}
                                                onChange={(name, value) => formik.setFieldValue('ArtPaletaArtikal', value)}
                                                errors={formik.errors.ArtPaletaArtikal}
                                                touched={formik.touched.ArtPaletaArtikal}
                                                selectName='ArtPaletaArtikal'
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Broj palete */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>
                                        Broj palete
                                    </label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='number'
                                            placeholder='Broj palete'
                                            {...formik.getFieldProps('ArtPaletaBrojPalete')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.ArtPaletaBrojPalete && formik.errors.ArtPaletaBrojPalete,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.ArtPaletaBrojPalete &&
                                                        !formik.errors.ArtPaletaBrojPalete,
                                                }
                                            )}
                                        />
                                        {formik.touched.ArtPaletaBrojPalete && formik.errors.ArtPaletaBrojPalete && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.ArtPaletaBrojPalete}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Stanje Palete */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>
                                        Stanje Palete
                                    </label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='text'
                                            disabled={true}
                                            placeholder='Stanje Palete'
                                            value={stanjePalete?.ArtPaletaTrenutnoStanje ?? ''}
                                            className={clsx('form-control form-control-lg form-control-solid')}
                                        />
                                    </div>
                                    {!stanjePalete && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>No Value for Stanje Palete</div>
                                        </div>
                                    )}
                                </div>
                                {/* Dokument */}
                                <div className='row mb-6'>
                                    <div className='col-6'>
                                        <label className='col-auto col-form-label required fw-bold fs-6'>
                                            Dokument
                                        </label>
                                        <div className='col-auto fv-row'>
                                            <DokumentiDropdown
                                                value={formik.values.ArtPaletaDokument}
                                                onChange={(name, value) => formik.setFieldValue('ArtPaletaDokument', value)}
                                                errors={formik.errors.ArtPaletaDokument}
                                                touched={formik.touched.ArtPaletaDokument}
                                                selectName='ArtPaletaDokument'
                                            />
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <label className='col-auto col-form-label required fw-bold fs-6'>
                                            Dokument/Nalog
                                        </label>
                                        <div className='col-auto fv-row'>
                                            <input
                                                type='number'
                                                placeholder='Broj dokumenta'
                                                {...formik.getFieldProps('ArtPaletaBrDokumenta')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {
                                                        'is-invalid':
                                                            formik.touched.ArtPaletaBrojPalete && formik.errors.ArtPaletaBrojPalete,
                                                    },
                                                    {
                                                        'is-valid':
                                                            formik.touched.ArtPaletaBrDokumenta &&
                                                            !formik.errors.ArtPaletaBrDokumenta,
                                                    }
                                                )}
                                            />
                                            {formik.touched.ArtPaletaBrDokumenta && formik.errors.ArtPaletaBrDokumenta && (
                                                <div className='fv-plugins-message-container'>
                                                    <div
                                                        className='fv-help-block'>{formik.errors.ArtPaletaBrDokumenta}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* Datum */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>Datum</label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='date'
                                            placeholder='Datum'
                                            {...formik.getFieldProps('ArtPaletaDatum')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.ArtPaletaDatum && formik.errors.ArtPaletaDatum,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.ArtPaletaDatum && !formik.errors.ArtPaletaDatum,
                                                }
                                            )}
                                        />
                                        {formik.touched.ArtPaletaDatum && formik.errors.ArtPaletaDatum && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.ArtPaletaDatum}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Kolcina Izlaz */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>
                                        Količina izlaza
                                    </label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='number'
                                            placeholder='Kolicina izlaza'
                                            {...formik.getFieldProps('ArtPaletaIzlaz')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.ArtPaletaIzlaz && formik.errors.ArtPaletaIzlaz,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.ArtPaletaIzlaz && !formik.errors.ArtPaletaIzlaz,
                                                }
                                            )}
                                        />
                                        {formik.touched.ArtPaletaIzlaz && formik.errors.ArtPaletaIzlaz && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.ArtPaletaIzlaz}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Napomena */}
                                <div className='col-12 mb-6'>
                                    <label className='col-auto col-form-label fw-bold fs-6'>Napomena</label>

                                    <div className='col-lg-12 fv-row'>
                    <textarea
                        placeholder='Napomena'
                        {...formik.getFieldProps('ArtPaletaNapomena')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid':
                                    formik.touched.ArtPaletaNapomena && formik.errors.ArtPaletaNapomena,
                            },
                            {
                                'is-valid':
                                    formik.touched.ArtPaletaNapomena && !formik.errors.ArtPaletaNapomena,
                            }
                        )}
                    />
                                        {formik.touched.ArtPaletaNapomena && formik.errors.ArtPaletaNapomena && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.ArtPaletaNapomena}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={loading || formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && 'Potvrdi'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
            <div className='col-6'>
                <PaleteIzlaziTableComponent/>
            </div>
        </div>
    )
}

export default PaleteIzlaziDetails
