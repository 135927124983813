import React, { useEffect, useState } from "react";
import { ProdajaNajamModel } from "../../models/ProdajaNajamModel";
import { PaginationModel } from "../../models/PaginationModel";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from "@tanstack/react-table";
import { deleteDebljina, getAllDebljine } from "../../api/panelDebljina";
import notifyToast from "../../components/Toast/Toast";
import { TableActionButton } from "../../components/TableActionBtn/TableActionButton";
import { Link, useNavigate } from "react-router-dom";
import { KTCard, KTCardBody, KTIcon } from "../../../_websoft/helpers";
import { ToastContainer } from "react-toastify";
import TableSearch from "../../components/TableSearch/TableSearch";
import ExportButton from "../../components/TableExportToExcel/ExportButton";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import TablePagination from "../../components/TablePagination/TablePagination";
import {deleteProdajaNajamModel, getAllProdajaNajam} from "../../api/ProdajaNajam";
import { Modal, ModalHeader } from "react-bootstrap";
import parse from 'html-react-parser';
import ProdajaNajamFilteri from "./ProdajaNajamFilteri";
import moment from "moment";

const categoryMap = {
    1: 'Stalna sredstva',
    2: 'Rezervni dijelovi',
    3: 'Neusklađeni proizvodi',
    4: 'Limpres',
    5: 'Razno',
};

const subcategoryMap = {
    1: 'Profilacija',
    2: 'Paneli',
    3: 'Oluci i cijevi',
    4: 'Opšavi',
    5: 'Ostalo',
};

const ProdajaNajamLista: React.FC = () => {
    const [data, setData] = useState<ProdajaNajamModel[]>([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });
    const [params, setparams] = useState<Partial<ProdajaNajamModel>>({});

    const [sorting, setSorting] = useState<SortingState>([]);
    const [globalFilter, setGlobalFilter] = React.useState('');
    const [isModalOpen, setisModalOpen] = useState(false);
    const [image, setimage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');


    const handlePdfClick = (content) => {
        setimage('');
        setModalContent(content);
        setisModalOpen(true);
    };

    const updatePage = (pageNo: number, perPage: number) => {
        setLoading(true);
        const newParams = { ...params, page: pageNo, per_page: perPage };
        getAllProdajaNajam(newParams)
            .then((response) => {
                setData(response.data['data']);
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1);
                setpagination({
                    curPage: response.data['current_page'],
                    totalPage: totalPage,
                    perPage: perPage,
                });
            })
            .catch((error) => {
                console.error('Error: ', error);
                notifyToast(false, error);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        updatePage(pagination.curPage, pagination.perPage);
    }, [params]);

    useEffect(() => {
        const { id } = sorting[0] ?? '';
        data.sort((a, b) => a[id] - b[id]);
    }, [sorting]);

    const onImageClick = (value) => {
        setisModalOpen(true);
        setModalContent('');
        setimage(value);
    };

    const columns = [
        {
            header: 'Id',
            accessorKey: 'Id',
        },
        {
            header: 'Šifra',
            accessorKey: 'SifraArtikla',
        },
        {
            header: 'Naziv',
            accessorKey: 'Naziv',
        },
        {
            header: 'Kategorija',
            accessorKey: 'Kategorija',
            cell: ({ row }) => (
                <div>
                    <div><b>{categoryMap[row.original.Kategorija]}</b></div>
                    {row.original.Kategorija === 3 && (
                        <div><i>{subcategoryMap[row.original.SubKategorija]}</i></div>
                    )}
                </div>
            ),
        },
        {
            header: 'Opis',
            accessorKey: 'Opis',
            cell: ({ row }) => <div>{parse(row.original.Opis)}</div>,
        },
        {
            header: 'Cijena sa PDV-om',
            accessorKey: 'Cijena',
        },
        {
            header: 'Web prodaja',
            accessorKey: 'WebProdaja',
            cell: ({ row }) => (
                <div className='text-center'>
                    {row.original.WebProdaja === 1 ? (
                        <i className="bi bi-check2-all" style={{ fontSize: '24px',color: 'green' }}></i>
                    ) : (
                        <i className="bi bi-x" style={{ fontSize: '24px', color: 'red' }}></i>
                    )}
                </div>
            ),
        },
        {
            header: 'Komentar',
            accessorKey: 'KomentariRukovodioca',
            cell: ({ row }) => row.original.KomentariRukovodioca && (
                <div className='text-center'>
                    <i className="bi bi-file-earmark-pdf-fill" style={{ fontSize: '24px',color: 'green' }}
                       onClick={() => handlePdfClick(row.original.KomentariRukovodioca)}></i>
                </div>
            ),
        },
        {
            header: 'Datum',
            accessorKey: 'Kreirano',
            cell: ({ row }) => moment(row.original.Kreirano).format('DD-MM-YYYY'),
        },
        {
            id: 'Slika',
            header: 'Slike',
            cell: ({ row }) => (
                <div className="image-container">
                    {row.original.Slika && (
                        <img
                            className='image-input-wrapper w-50px h-50px rounded'
                            src={row.original.Slika}
                            alt=''
                            onClick={() => onImageClick(row.original.Slika)}
                        />
                    )}
                    {row.original.Slika2 && (
                        <img
                            className='image-input-wrapper w-50px h-50px rounded'
                            src={row.original.Slika2}
                            alt=''
                            onClick={() => onImageClick(row.original.Slika2)}
                        />
                    )}
                    {row.original.Slika3 && (
                        <img
                            className='image-input-wrapper w-50px h-50px rounded'
                            src={row.original.Slika3}
                            alt=''
                            onClick={() => onImageClick(row.original.Slika3)}
                        />
                    )}
                </div>
            ),
        },
        {
            header: 'Akcija',
            accessorKey: 'Action',
            cell: ({row}) => (
                <TableActionButton
                    onDelete={() => onDelete(row.original.Id)}
                    onEdit={() => {
                        onEdit(row.original.Id);
                    }}
                />
            ),
        },
    ];

    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
    });

    const onDelete = (id) => {
        deleteProdajaNajamModel(id)
            .then((response) => {
                updatePage(pagination.curPage, pagination.perPage);
                notifyToast(true, response.message);
            })
            .catch((error) => {
                notifyToast(false, error.data['message']);
            });
    };

    const navigate = useNavigate();

    const onEdit = (rowData) => {
        navigate(`/prodaja-najam/izmjena/${rowData}`);
    };

    const onFilter = (filter: Partial<ProdajaNajamModel>) => {
        setparams({
            Realizovano: filter.Realizovano ? Number(filter.Realizovano) : undefined,
            Kategorija: filter.kategorija ? Number(filter.kategorija) : undefined,
            SubKategorija: filter.subkategorija ? Number(filter.subkategorija) : undefined,
        });
    };

    return (
        <KTCard>
            <ToastContainer />
            <Modal show={isModalOpen} onHide={() => setisModalOpen(false)} size="lg">
                <ModalHeader closeButton={true} />
                    <Modal.Body>
                        {image && <img src={image} className='img-fluid' />}
                        {modalContent && <p>{parse(modalContent)}</p>}
                </Modal.Body>
            </Modal>

            <KTCardBody className='py-4'>
                <ProdajaNajamFilteri onFilter={onFilter} />
                <div className="separator mb-8"></div>
                <div className='d-flex justify-content-between'>

                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)} />
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <ExportButton table={table} filename={'debljine_panela'} />
                        <Link to='/prodaja-najam/dodaj'>
                            <button type='button' className='btn btn-primary'>
                                <KTIcon iconName='plus' className='fs-2' />
                                Dodaj
                            </button>
                        </Link>
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent />
                ) : (
                    <div className='table-responsive mt-5'>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination updatePage={updatePage} pagination={pagination} />
            </KTCardBody>
        </KTCard>
    )
}
export default ProdajaNajamLista;
