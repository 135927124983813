import React, {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_websoft/helpers'
import {MenuComponent} from '../../../_websoft/assets/ts/components'
import {Modal, ModalBody, ModalHeader} from 'react-bootstrap'

type Props = {
  onEdit: Function
  onDelete: Function
}

const TableActionButton: FC<Props> = ({onEdit, onDelete}) => {
  const [isModalOpen, setisModalOpen] = useState(false)
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      {/* Modal for delete warning */}
      <Modal show={isModalOpen} onHide={() => setisModalOpen(false)}>
        <ModalHeader closeButton={true} className='h3 bg-warning'>
          Da li želite obrisati stavku?
        </ModalHeader>
        <ModalBody className='d-flex  justify-content-between'>
          <button className='btn btn-secondary' onClick={() => setisModalOpen(false)}>
            NE
          </button>
          <button
            className='btn btn-primary'
            onClick={() => {
              setisModalOpen(false)
              onDelete()
            }}
          >
            DA
          </button>
        </ModalBody>
      </Modal>
      <button
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Akcija
        <KTIcon iconName='down' className='fs-5 m-0' />
      </button>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <div className='menu-link px-3 d-flex justify-content-around' onClick={() => onEdit()}>
            <KTIcon iconName='pencil' className='fs-2 text-primary' />
            Izmjena
          </div>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <div
            className='menu-link px-3 d-flex justify-content-around'
            data-kt-users-table-filter='delete_row'
            onClick={() => setisModalOpen(true)}
          >
            <KTIcon iconName='eraser' className='fs-2 text-danger' />
            Obrisi
          </div>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {TableActionButton}
