import axios from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {ParamsRepromaterijaliGreske, RepromaterijaliGreske} from "../models/RepromaterijaliGreske";
import {ParamsReklamacijiRolneModel, ReklamacijeRolni} from "../models/ReklamacijeRolni";

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/repromaterijaliGreske`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getAllGreske(params: ParamsRepromaterijaliGreske) {
    return await axios.get<Array<RepromaterijaliGreske>>(API, {headers: headers, params: params})
}

export async function saveGreska(Data: any) {
    try {
        const response = await axios.post(API, Data, {headers: headers})
        if (response.data.error === 0) {
            return response.data
        } else {
            return new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        return error
    }
}

export async function fetchGreska(Id: number): Promise<Partial<RepromaterijaliGreske>> {
    try {
        const response = await axios.get(`${API}/${Id}`)
        if (response.data && response.data.error === 0) {
            return response.data.data
        } else {
            return response.data.message || 'Failed to fetch.'
        }
    } catch (error: any) {
        return error
    }
}

export async function updateGreska(Data: any, id: number) {
    try {
        const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
        if (response.data.error === 0) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            return response.data.message || 'Failed to fetch.'
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        return error
    }
}

export async function deleteGreska(Id: number) {
    try {
        const response = await axios.delete(`${API}/${Id}`, {headers: headers})
        if (response.data) {
            return response
        } else {
            // Handle any other type of response structure or errors returned from the server here
            return response.data.message || 'Failed to fetch.'
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        return error
    }
}
