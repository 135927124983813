import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {PripremaPrometaModel} from "../models/PripremaPrometaModel";

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/paneli/pripremaPrometaRolne`
const API_ROLNE = `${API_URL}/paneli/getNaloziZaRolnu`
const API_TRAKE = `${API_URL}/paneli/getNaloziZaTraku`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export interface ApiResponsePaneli<T> {
    data: T;
    error: number;
    message: string;
}
export async function getPripremePrometa() {
    const response = await axios.get<ApiResponsePaneli<PripremaPrometaModel[]>>(API, { headers });
    return response.data;
}

export async function getNaloziZaRolnu(params) {
    const response = await axios.get<ApiResponsePaneli<PripremaPrometaModel[]>>(API_ROLNE, { headers, params });
    return response.data;
}

export async function getNaloziZaTraku(params) {
    const response = await axios.get<ApiResponsePaneli<PripremaPrometaModel[]>>(API_TRAKE, { headers, params });
    return response.data;
}
