import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {TipPanelaDropdownModel} from '../models/ProizvodnjaModel'

const API_URL = process.env.REACT_APP_API_URL
const DROPDOWN_API = `${API_URL}/getTipPanelaDropdown`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

// Get request for dropdown data.
export async function getTipPanelaDropdown() {
  try {
    return await axios.get<Array<TipPanelaDropdownModel>>(DROPDOWN_API, {headers: headers})
  } catch (error) {
    console.error('Error fetching Dropdown data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
