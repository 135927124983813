import React, {useEffect, useState} from 'react'
import {Modal, ModalBody, ModalHeader} from 'react-bootstrap'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import {ZapisiProizvodnjeModel} from '../../models/ZapisiProizvodnjeModel'
import {naloziUProizvodnji} from '../../api/proizvodnja'
import notifyToast from '../Toast/Toast'

const NaloziUProizvodnji = ({isModalOpen, setisModalOpen}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<ZapisiProizvodnjeModel[]>([])

  const getProizvodnji = () => {
    setLoading(true)
    naloziUProizvodnji()
      .then((response) => {
        setData(response.data['data'])
      })
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => setLoading(false))
  }
  useEffect(() => {
    getProizvodnji()
  }, [])

  const columns = [
    {
      id: 'Nalog',
      header: 'Nalog',
      accessorKey: 'BrojNaloga',
    },
    {
      id: 'Smjena',
      header: 'Smjena',
      accessorKey: 'Smjena',
    },
    {
      id: 'Linija',
      header: 'Linija',
      accessorKey: 'Masina',
    },
    {
      id: 'Kupac',
      header: 'Kupac',
      accessorKey: 'paneli_proizvodnja.kupac',
    },
    {
      id: 'Komercijalista',
      header: 'Komercijalista',
      accessorKey: 'paneli_proizvodnja.kreiraoKorisnik',
    },
  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })
  return (
    <Modal className='modal-xl' show={isModalOpen} onHide={() => setisModalOpen(false)}>
      <ModalHeader closeButton={true} className='h3'>
        Nalozi U Proizvodnji
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3 '>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className='text-white'
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder ? null : (
                          <span>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {/* @ts-ignore */}
                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                          </span>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default NaloziUProizvodnji
