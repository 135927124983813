import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import React from 'react'

const KrajTable = ({data}) => {
  const odjelMap = {
    '1': 'Profilacija',
    '2': ' Opšavi',
    '3': ' Oluci',
  }
  console.log('ss', odjelMap[1])
  const columns = [
    {
      id: 'Odjel',
      header: 'Odjel',
      accessorFn: (row) => odjelMap[row.OdjelId],
    },

    {
      id: 'Sifra',
      header: 'Sifra',
      accessorKey: 'SifraArtikla',
    },
    {
      id: 'NazivArtikla',
      header: 'Naziv',
      accessorKey: 'NazivArtikla',
    },
    {
      id: 'Količina',
      header: 'Količina',
      accessorKey: 'Kolicina',
    },
    {
      id: 'Uposlenik',
      header: 'Uposlenik',
      accessorKey: 'uposlenik.UposlenikImePrezime',
    },
  ]
  const table = useReactTable({
    data: data,
    columns,

    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className='table-responsive'>
      <div className='table-responsive mt-3 '>
        <table className='table align-middle table-bordered gy-5 no-footer'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-primary p-2'
              >
                {headerGroup.headers.map((header) => (
                  <th
                    className='text-white'
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {/* @ts-ignore */}
                    {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='text-gray-600'>
            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default KrajTable
