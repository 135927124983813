/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {KTCard, KTCardBody} from '../../../../_websoft/helpers'
import {ToastContainer} from 'react-toastify'
import StartNalog from './StartNalog'
import ZavrsiNalog from './ZavrsiNalog'
import {getAuth} from '../../../modules/auth'
import {UposlenikModel} from '../../../models/UposlenikModel'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'

const OtvorenNalog = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const auth: UposlenikModel = getAuth()!
  const [errorMessage, setErrorMessage] = useState('')
  const [nalogComponent, setNalogComponent] = useState<JSX.Element | null>(null)
  const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}
  const [loading, setLoading] = useState(false)

  const DANAS = `${API_URL}/imaLiUposlenikOtvorenZapisZaDanas`
  const JUCER = `${API_URL}/imaLiUposlenikOtvorenZapisZaJucer`
  useEffect(() => {
    checkUposlenikZapis()
  }, [])

  const checkUposlenikZapis = async () => {
    try {
      const response = await axios.get(JUCER, {headers: headers})
      console.log(response)
      if (response.data.error === 0) {
        checkUposlenikZapisZaDanas()
      } else {
        setErrorMessage('Obratite se poslovodji')
      }
    } catch (error) {
      setErrorMessage('Greška pri komunikaciji s serverom')
      console.error('Error in API call', error)
    }
  }

  const checkUposlenikZapisZaDanas = async () => {
    try {
      const response = await axios.get(DANAS, {headers: headers})
      if (response.data.error === 1) {
        setNalogComponent(<ZavrsiNalog data={response.data.data} />)
      } else {
        setNalogComponent(<StartNalog />)
      }
      setLoading(false)
    } catch (error) {
      setErrorMessage('er')
      setLoading(false)
      console.error('Error in the API call', error)
    }
  }
  return (
    <KTCard>
      <ToastContainer />
      <KTCardBody className='py-4'>
        <div className='row'>
          {errorMessage && (
            <div className='col-lg-6 alert alert-danger'>
              <div>{errorMessage}</div>
            </div>
          )}
          {loading ? <LoadingComponent /> : nalogComponent}
        </div>
      </KTCardBody>
    </KTCard>
  )
}
export default OtvorenNalog
