import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_websoft/layout/core'
import ProdajaNajamLista from "./ProdajaNajamLista";
import ProdajaNajamDetails from "./ProdajaNajamDetails";

const ProdajaBreadCrumbs: Array<PageLink> = [
    {
        title: 'Prodaja & Najam',
        path: '/prodaja-najam',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ProdajaNajamRoutes: React.FC = () => {
    return (
        <Routes>
            <Route
                element={
                    <>
                        <Outlet />
                    </>
                }
            >
                <Route
                    path='lista'
                    element={
                        <>
                            <PageTitle breadcrumbs={ProdajaBreadCrumbs}>Prodaja & najam</PageTitle>
                            <ProdajaNajamLista />
                        </>
                    }
                />
                <Route
                    path='dodaj'
                    element={
                        <>
                            <PageTitle breadcrumbs={ProdajaBreadCrumbs}>Prodaja & najam</PageTitle>
                            <ProdajaNajamDetails />
                        </>
                    }
                />
                <Route
                    path='izmjena/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={ProdajaBreadCrumbs}>Prodaja & najam</PageTitle>
                            <ProdajaNajamDetails />
                        </>
                    }
                />
                <Route index element={<Navigate to='/prodaja-najam' />} />
            </Route>
        </Routes>
    )
}

export default ProdajaNajamRoutes
