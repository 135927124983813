import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_websoft/layout/core'

import {
    ListsWidget1,
    ListsWidget2,
    ListsWidget3,
    ListsWidget4,
    ListsWidget5,
    ListsWidget6,
    MixedWidget10,
    MixedWidget11,
    MixedWidget2,
    StatisticsWidget5,
    TablesWidget10, TablesWidget12, TablesWidget13, TablesWidget2,
    TablesWidget5,
} from '../../../_websoft/partials/widgets'

const DashboardPage = () => (
    <>
        {/* begin::Row */}
        <div className='row g-5 g-xl-8'>
            <div className='col-xl-4'>
                <StatisticsWidget5
                    className='card-xl-stretch mb-xl-8'
                    svgIcon='element-3'
                    color='tile'
                    iconColor='primary'
                    title='Uposlenici'
                    description='Pregledi'
                    titleColor='gray-900'
                    descriptionColor='gray-400'
                    href='uposlenici/lista'
                />
            </div>

            <div className='col-xl-4'>
                <StatisticsWidget5
                    className='card-xl-stretch mb-xl-8'
                    svgIcon='element-11'
                    color='primary'
                    iconColor='white'
                    title='Paneli'
                    description='Pregled proizvodnje'
                    titleColor='white'
                    descriptionColor='white'
                    href='paneli/lista'
                />
            </div>

            <div className='col-xl-4'>
                <StatisticsWidget5
                    className='card-xl-stretch mb-5 mb-xl-8'
                    svgIcon='left'
                    color='dark'
                    iconColor='gray-100'
                    title='FMK Nalozi'
                    description='Pregled'
                    titleColor='gray-100'
                    descriptionColor='gray-100'
                    href='fmk-nalozi/lista'
                />
            </div>
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        <div className='row gy-5 g-xl-8'>
            <div className='col-xxl-6'>
                <MixedWidget2
                    className='card-xl-stretch mb-xl-8'
                    chartColor='primary'
                    chartHeight='200px'
                    strokeColor='#cb1e46'
                />
            </div>
            {/*    <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div> */}
            <div className='col-xxl-6'>
                <MixedWidget10
                    className='card-xxl-stretch-50 mb-5 mb-xl-8'
                    chartColor='primary'
                    chartHeight='150px'
                />
                <MixedWidget11
                    className='card-xxl-stretch-50 mb-5 mb-xl-8'
                    chartColor='primary'
                    chartHeight='175px'
                />
            </div>
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        {/* <div className='row g-5 g-xl-8'>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>*/}
        <div className='row g-5 g-xl-8'>
            {/* begin::Col */}
            <div className='col-xl-4'>
                <ListsWidget1 className='card-xl-stretch mb-xl-8'/>
            </div>
            {/* end::Col */}
            <div className='col-xl-8'>
                <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8'/>
            </div>
            {/* begin::Col
      <div className='col-xl-8'>
        <TablesWidget5 className='card-xl-stretch mb-5 mb-xl-8' />
      </div>*/}
            {/* end::Col */}
        </div>
        {/* end::Row */}
    </>
)

const DashboardWrapper = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <DashboardPage/>
        </>
    )
}

export {DashboardWrapper}
