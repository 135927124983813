/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {ParamsModelArhiviranje} from '../../models/PaketLezarina'
import {PaginationModel} from '../../models/PaginationModel'
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table'
import notifyToast from '../../components/Toast/Toast'
import {KTCard, KTCardBody} from '../../../_websoft/helpers'
import {ToastContainer} from 'react-toastify'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import TablePagination from '../../components/TablePagination/TablePagination'
import {getAllNaloziZaArhiviranje, pokreniServisZaArhivuNaloga} from '../../api/naloziPanelaZaArhiviranje'
import PakModal from '../../components/PakModal/PakModal'
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox/IndeterminateCheckbox'
import {updateArhivirajNalog} from '../../api/proizvodnja'

const NaloziZaArhiviranje: React.FC = () => {
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState(true)
    const [stavkaId, setstavkaId] = useState<number>()
    const [isModalOpen, setisModalOpen] = useState(false)
    const [rowSelection, setRowSelection] = useState({})
    const [arhivirajNalog, setArhivirajNalog] = useState<string>()

    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })
    const [params, setparams] = useState<ParamsModelArhiviranje>({per_page: 10, page: 1})

    //   On Arhiviraj Nalog
    const onClickArhivirajNalog = () => {
        setLoading(true)
        const stavkaList = table.getSelectedRowModel().rows.map((item) => item.original.IdProizvodnje)
        console.log({stavkaList})
        const params = {
            stavkaId: stavkaList,
            status: arhivirajNalog,
        }
        updateArhivirajNalog(params)
            .then((response) => {
                notifyToast(true, response.message)
                updatePage()
            })
            .catch((error) => notifyToast(false, error.response.data.message))
            .finally(() => {
                setLoading(false)
            })
    }

    const onPakClick = (id) => {
        setisModalOpen(true)
        setstavkaId(id)
    }

    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')

    const startArhivirajNaloge = () => {
        setLoading(true)
        pokreniServisZaArhivuNaloga()
            .then((response) => {
                notifyToast(true, response.data.message)
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const updatePage = () => {
        setLoading(true)
        getAllNaloziZaArhiviranje(params)
            .then((response) => {
                setData(response.data['data'])
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                setpagination({
                    curPage: response.data['current_page'],
                    totalPage: totalPage,
                    perPage: params.per_page,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
                setRowSelection({})
            })
    }

    useEffect(() => {
        updatePage()
    }, [params])

    useEffect(() => {
        const {id} = sorting[0] ?? ''
        data.sort((a, b) => a[id] - b[id])
    }, [sorting])

    const columns = [
        {
            id: 'id',
            header: ({table}) => (
                <div className='row p-2'>
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </div>
            ),
            cell: ({row, getValue}) => (
                <div className='col'>
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),
        },
        {
            header: 'Id',
            accessorKey: 'Id',
        },
        {
            header: 'Id Proizvodnje',
            accessorKey: 'IdProizvodnje',
        },
        {
            header: 'Broj Naloga',
            accessorKey: 'BrojNaloga',
        },
        {
            header: 'Stavka',
            accessorKey: 'Stavka',
        },
        {
            header: 'Kupac',
            accessorKey: 'proizvodnja.kupac',
        },
        {
            header: 'Komercijalista',
            accessorKey: 'proizvodnja.kreiraoKorisnik',
        },
        {
            id: 'pak',
            header: 'Paketi',
            cell: ({row}) => (
                <button
                    className='btn btn-primary'
                    onClick={() => {
                        onPakClick(row.original.IdProizvodnje)
                    }}
                >
                    Paketi
                </button>
            ),
        },
    ]
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
            rowSelection: rowSelection,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
        onRowSelectionChange: setRowSelection,
        enableRowSelection: true,
    })

    return (
        <KTCard>
            <ToastContainer/>
            <PakModal isModalOpen={isModalOpen} setisModalOpen={setisModalOpen} stavkaId={stavkaId}/>
            <KTCardBody className='py-4'>
                <div className='row'>
                    <div className='col-lg-4 col-md-4 mb-6'>
                        <label className='col-auto col-form-label fw-bold fs-6'>
                            <span className='required'>Arhiviraj nalog</span>
                        </label>
                        <select
                            className='form-select form-select-solid fw-bolder'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            data-kt-user-table-filter='role'
                            data-hide-search='true'
                            onChange={(e) => setArhivirajNalog(e.target.value)}
                        >
                            <option value='0'>Odaberi...</option>
                            <option value='1'>Da</option>
                            <option value='0'>Ne</option>
                        </select>
                    </div>
                    <div className='col-lg-2 col-md-2 mb-6 align-self-end'>
                        <button
                            type='button'
                            className='btn btn-success'
                            disabled={!arhivirajNalog || !table.getSelectedRowModel().rows.length || loading}
                            onClick={onClickArhivirajNalog}
                        >
                            Potvrdi
                        </button>
                    </div>
                    <div className='col-lg-2 offset-lg-4 col-md-2 mb-6 align-self-end'>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={startArhivirajNaloge}
                        >
                            Pokreni proces za arhivu
                        </button>
                    </div>
                </div>
                <div className='separator mt-3 mb-3'></div>
                <div className='d-flex justify-content-between'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)}/>
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <ExportButton table={table} filename={'arhiviraj_naloge'}/>
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({page: page, per_page: per_page})}
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}
export default NaloziZaArhiviranje
