/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {fetchPrometArtikla, savePrometArtikla, updatePrometArtikla} from '../../api/PrometArtikla'
import {PrometArtiklaModel} from '../../models/PrometArtiklaModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import ArtikliDropdown from '../../components/ArtikliDropdown/ArtikliDropdown'
import clsx from 'clsx'
import DokumentiDropdown from '../../components/DokumentiDropdown/DokumentiDropdown'
import UposlenikDropdown from '../../components/UposlenikDropdown/UposlenikDropdown'
import PrometArtiklaTableComponent from './components/PrometArtiklaTableComponent/PrometArtiklaTableComponent'

const PrometArtiklaDetails = () => {
  const {id} = useParams()
  const IdFromURL = Number(id)

  const [loading, setLoading] = useState(true)

  const initialValues = {
    Artikal: undefined,
    Datum: undefined,
    VrstaDokumenta: undefined,
    VrstaDokumentaBroj: undefined,
    Ulaz: undefined,
    Izlaz: undefined,
    Preuzeo: undefined,
    Napomene: undefined,
  }
  const [initialData, setInitialData] = useState<Partial<PrometArtiklaModel>>(initialValues)

  useEffect(() => {
    if (IdFromURL) {
      fetchPrometArtikla(IdFromURL)
        .then((data) => {
          setInitialData(data)
          formik.resetForm({values: data}) // <-- reset formik state here
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [IdFromURL])

  const validationSchema = Yup.object({
    Artikal: Yup.number().required('Required'),
    Datum: Yup.string().required('Required'),
    VrstaDokumenta: Yup.number().required('Required'),
    VrstaDokumentaBroj: Yup.string().required('Required'),
  })

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response
        if (IdFromURL) {
          response = await updatePrometArtikla(IdFromURL, values)
        } else {
          response = await savePrometArtikla(values)
        }
        if (response.error === 0) {
          notifyToast(true, response.message)
        }else{
          notifyToast(false, response.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  return (
    <div className='row'>
      <ToastContainer />
      <div className='card mb-5 mb-xl-10 col-6'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <TitleComponent name='Promet' />
          </div>
        </div>
        <div id='kt_account_profile_details' className='collapse show'>
          {loading ? (
            <LoadingComponent />
          ) : (
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className='card-body border-top p-9 row'>
                {/* Artikal */}
                <div className='col-6 mb-6'>
                  <div className='col'>
                    <label className='col-auto col-form-label required fw-bold fs-6'>Artikal</label>
                    <div className='col-auto fv-row'>
                      <ArtikliDropdown
                        value={formik.values.Artikal}
                        onChange={(name, value) => formik.setFieldValue('Artikal', value)}
                        errors={formik.errors.Artikal}
                        touched={formik.touched.Artikal}
                        selectName='Artikal'
                      />
                    </div>
                  </div>
                </div>
                {/* Datum */}
                <div className='col-6 mb-6'>
                  <label className='col-auto col-form-label required fw-bold fs-6'>Datum</label>

                  <div className='col-auto fv-row'>
                    <input
                      type='date'
                      placeholder='Datum'
                      {...formik.getFieldProps('Datum')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.Datum && formik.errors.Datum,
                        },
                        {
                          'is-valid': formik.touched.Datum && !formik.errors.Datum,
                        }
                      )}
                    />
                    {formik.touched.Datum && formik.errors.Datum && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Datum}</div>
                      </div>
                    )}
                  </div>
                </div>
                {/* VrstaDokumenta */}
                <div className='col-6 mb-6'>
                  <div className='col'>
                    <label className='col-auto col-form-label required fw-bold fs-6'>
                      Odaberi dokument
                    </label>
                    <div className='col-auto fv-row'>
                      <DokumentiDropdown
                        value={formik.values.VrstaDokumenta}
                        onChange={(name, value) => formik.setFieldValue('VrstaDokumenta', value)}
                        errors={formik.errors.VrstaDokumenta}
                        touched={formik.touched.VrstaDokumenta}
                        selectName='VrstaDokumenta'
                      />
                    </div>
                  </div>
                </div>
                {/* Broj naloga */}
                <div className='col-6 mb-6'>
                  <label className='col-auto col-form-label required fw-bold fs-6'>
                    Broj naloga
                  </label>

                  <div className='col-auto fv-row'>
                    <input
                      type='text'
                      placeholder='Broj naloga'
                      {...formik.getFieldProps('VrstaDokumentaBroj')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid':
                            formik.touched.VrstaDokumentaBroj && formik.errors.VrstaDokumentaBroj,
                        },
                        {
                          'is-valid':
                            formik.touched.VrstaDokumentaBroj && !formik.errors.VrstaDokumentaBroj,
                        }
                      )}
                    />
                    {formik.touched.VrstaDokumentaBroj && formik.errors.VrstaDokumentaBroj && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.VrstaDokumentaBroj}</div>
                      </div>
                    )}
                  </div>
                </div>
                {/* Ulaz */}
                <div className='col-6 mb-6'>
                  <label className='col-auto col-form-label  fw-bold fs-6'>Ulaz</label>

                  <div className='col-auto fv-row'>
                    <input
                      type='number'
                      placeholder='Ulaz'
                      {...formik.getFieldProps('Ulaz')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.Ulaz && formik.errors.Ulaz},
                        {
                          'is-valid': formik.touched.Ulaz && !formik.errors.Ulaz,
                        }
                      )}
                    />
                    {formik.touched.Ulaz && formik.errors.Ulaz && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Ulaz}</div>
                      </div>
                    )}
                  </div>
                </div>
                {/* Izlaz */}
                <div className='col-6 mb-6'>
                  <label className='col-auto col-form-label  fw-bold fs-6'>Izlaz</label>

                  <div className='col-auto fv-row'>
                    <input
                      type='number'
                      placeholder='Izlaz'
                      {...formik.getFieldProps('Izlaz')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.Izlaz && formik.errors.Izlaz},
                        {
                          'is-valid': formik.touched.Izlaz && !formik.errors.Izlaz,
                        }
                      )}
                    />
                    {formik.touched.Izlaz && formik.errors.Izlaz && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Izlaz}</div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Preuzeo */}
                <div className='col-6 mb-6'>
                  <div className='col'>
                    <label className='col-auto col-form-label fw-bold fs-6'>Preuzeo</label>
                    <div className='col-auto fv-row'>
                      <UposlenikDropdown
                        value={formik.values.Preuzeo}
                        onChange={(name, value) => formik.setFieldValue('Preuzeo', value)}
                        errors={formik.errors.Preuzeo}
                        touched={formik.touched.Preuzeo}
                        selectName='Preuzeo'
                      />
                    </div>
                  </div>
                </div>

                {/* Napomena */}
                <div className='col mb-6'>
                  <label className='col-auto col-form-label fw-bold fs-6'>Napomena</label>

                  <div className='col-auto fv-row'>
                    <textarea
                      placeholder='Napomena'
                      {...formik.getFieldProps('Napomene')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.Napomene && formik.errors.Napomene,
                        },
                        {
                          'is-valid': formik.touched.Napomene && !formik.errors.Napomene,
                        }
                      )}
                    />
                    {formik.touched.Napomene && formik.errors.Napomene && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Napomene}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={loading || formik.isSubmitting || !formik.isValid}
                >
                  {!loading && 'Potvrdi'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      <div className='col-6'>
        <PrometArtiklaTableComponent />
      </div>{' '}
    </div>
  )
}

export default PrometArtiklaDetails
