import React, {useEffect, useRef, useState} from "react";
import {useFormik} from "formik";
import {saveZapisiStavke} from "../../../../api/zapisiProizvodnje";
import notifyToast from "../../../../components/Toast/Toast";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import clsx from "clsx";
import useDebounce from "../../../../hooks/useDebounce";
import {rolnaDetaljiProizvodnjaV2} from "../../../../api/fmkNalozi";
import QrCodeScanner from "../../../../components/QrCodeScanner/QrCodeScanner";

interface ProfilacijaRolneProps {
    id: any;
    brojNaloga: any;
}

const ProfilacijaRolne: React.FC<ProfilacijaRolneProps> = ({id, brojNaloga}) => {
    const initialValues = {
        'ROLNA-G': '',
        'POTVRDJUJEM-KORISTENJE-ROLNE-G': '',
        'ROLNA-D': '',
        'POTVRDJUJEM-KORISTENJE-ROLNE-D': '',
    }
    const [loading, setLoading] = useState(false);

    const [rolneGId, setRolneGId] = useState('');
    const debouncedRolneGId = useDebounce(rolneGId);
    const [rolnaGDetalji, setRolnaGDetalji] = useState('');

    const [rolneDId, setRolneDId] = useState('');
    const debouncedRolneDId = useDebounce(rolneDId);
    const [rolnaDDetalji, setRolnaDDetalji] = useState('');

    // For Rolne G
    useEffect(() => {
        if (debouncedRolneGId !== '') {
            rolnaDetaljiProizvodnjaV2({rolnaId: debouncedRolneGId})
                .then((response) => {
                    const msg = `Tezina: ${response?.data.ReprMatTrenutnaKolicina || 'N/A'} | Debljina: ${response?.data.ReprMatDebljina || 'N/A'} 
                                    | Širina: ${response?.data.ReprMatSirina || 'N/A'} | Proizvođač: ${response?.data.proizvodjac.PartnerNaziv || 'N/A'} 
                                    | Boja: ${response?.data.boja_lica.BojaNaziv || 'N/A'} 
                                    | Šarža: ${response?.data?.stavka?.ugovor?.BrojUgovora ?? 'N/A'} / ${response?.data?.stavka?.ManualId ?? 'N/A'}`;
                    setRolnaGDetalji(msg);
                })
                .catch((e) => {
                    setRolnaGDetalji('Rolna nije dostupna.');
                });
        }
    }, [debouncedRolneGId]);

    // For Rolne D
    useEffect(() => {
        if (debouncedRolneDId !== '') {
            rolnaDetaljiProizvodnjaV2({rolnaId: debouncedRolneDId})
                .then((response) => {
                    const msg = `Tezina: ${response?.data.ReprMatTrenutnaKolicina || 'N/A'} | Debljina: ${response?.data.ReprMatDebljina || 'N/A'} 
                                    | Širina: ${response?.data.ReprMatSirina || 'N/A'} | Proizvođač: ${response?.data.proizvodjac.PartnerNaziv || 'N/A'} 
                                    | Boja: ${response?.data.boja_lica.BojaNaziv || 'N/A'} 
                                    | Šarža: ${response?.data?.stavka?.ugovor?.BrojUgovora ?? 'N/A'} / ${response?.data?.stavka?.ManualId ?? 'N/A'}`;
                    setRolnaDDetalji(msg);
                })
                .catch((e) => {
                    setRolnaDDetalji('Rolna nije dostupna.');
                });
        }
    }, [debouncedRolneDId]);

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
            setLoading(true);
            try {
                const data: { IdZapisa: any; BrojNaloga: any; MetaKey: string; MetaValue: any }[] = [];
                // Format data as per required format of api.
                Object.entries(values).forEach(([key, value]) => {
                    if (value) {
                        const dataSet = {
                            IdZapisa: id,
                            BrojNaloga: brojNaloga,
                            MetaKey: key,
                            MetaValue: value,
                        };
                        data.push(dataSet);
                    }
                });
                const response = await saveZapisiStavke(data);

                const responseData = response;

                if (responseData.error === 0) {
                    console.log(responseData.message,'success');
                    notifyToast(true, responseData.message);
                    resetForm(); // Reset form after successful submission
                    setRolnaGDetalji(''); // Clear Rolna G details
                    setRolnaDDetalji(''); // Clear Rolna D details
                } else {
                    notifyToast(false, responseData.message || 'An unexpected error occurred.');
                }
            } catch (error: any) {
                console.error('Error: ', (error as any).response?.data?.message);
                notifyToast(false, (error as any).response?.data?.message);
                setStatus(error);
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div>
            {loading ? (
                <LoadingComponent/>
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div id='profilacija-rolne'>
                        <div className='row mt-4'>
                            <div className='col-lg-1 col-sm-2'>
                                <div className='mt-10'>
                                    <QrCodeScanner
                                        onScan={(data) => {
                                            const text = data.split(';')[0];
                                            setRolneGId(text);
                                            formik.setFieldValue('ROLNA-G', text);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4 col-sm-4'>
                                <label className='col-form-label fw-bold fs-6'>ROLNA G</label>
                                <input
                                    type='text'
                                    className='form-control text-danger'
                                    id='ROLNA-G'
                                    {...formik.getFieldProps('ROLNA-G')}
                                    placeholder='Upišite broj rolne'
                                    onChange={(e) => {
                                        setRolneGId(e.target.value);
                                        formik.setFieldValue('ROLNA-G', e.target.value);
                                    }}
                                />
                            </div>
                            <div className='col-lg-6  col-sm-6'>
                                <label className='col-form-label fw-bold fs-6'>
                                    POTVRĐUJEM KORIŠTENJE ROLNE G
                                </label>
                                <select
                                    className='form-control'
                                    {...formik.getFieldProps('POTVRDJUJEM-KORISTENJE-ROLNE-G')}
                                >
                                    <option value=''>ODABERI</option>
                                    <option value='DA'>DA</option>
                                    <option value='NE'>NE</option>
                                </select>
                            </div>
                            <div
                                className={clsx('col-lg-12 mt-5 p-3 align-self-center', {
                                    'border border-1 border-warning rounded': rolnaGDetalji,
                                })}
                            >
                                <span className='text-primary fs-2' id='ReprMatSeriski'>
                                    {rolnaGDetalji}
                                </span>
                            </div>
                        </div>

                        <div className='row mt-4 '>
                            <div className='col-lg-1 col-sm-2'>
                                <div className='mt-10'>
                                    <QrCodeScanner
                                        onScan={(data) => {
                                            const text = data.split(';')[0];
                                            setRolneDId(text);
                                            formik.setFieldValue('ROLNA-D', text);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4 col-sm-4'>
                                <label className='col-form-label fw-bold fs-6'>ROLNA D</label>
                                <input
                                    type='text'
                                    className='form-control text-danger'
                                    id='ROLNA-D'
                                    {...formik.getFieldProps('ROLNA-D')}
                                    placeholder='Upišite broj rolne'
                                    onChange={(e) => {
                                        setRolneDId(e.target.value);
                                        formik.setFieldValue('ROLNA-D', e.target.value);
                                    }}
                                />
                            </div>
                            <div className='col-lg-6 col-sm-6'>
                                <label className='col-form-label fw-bold fs-6'>
                                    POTVRĐUJEM KORIŠTENJE OVE ROLNE
                                </label>
                                <select
                                    className='form-control'
                                    {...formik.getFieldProps('POTVRDJUJEM-KORISTENJE-ROLNE-D')}
                                >
                                    <option value=''>ODABERI</option>
                                    <option value='DA'>DA</option>
                                    <option value='NE'>NE</option>
                                </select>
                            </div>
                            <div
                                className={clsx('col-lg-12 mt-5 p-3 align-self-center', {
                                    'border border-1 border-warning rounded': rolnaDDetalji,
                                })}
                            >
                                <span className='text-primary fs-2' id='ReprMatSeriski'>
                                    {rolnaDDetalji}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-lg-3 offset-9'>
                            <button type='submit' className='btn btn-block btn-primary'>
                                Potvrdi
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
}
export default ProfilacijaRolne;
