/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import SearchableSelect from '../SearchableSelect/SearchableSelect'
import {Option} from '../../models/Sektor'
import {getSredstvaDropdown} from '../../api/sredstva'

// SektorDropdownProps for the component
interface DropdownProps {
  value?: string | number
  initialValue?: string | number
  onChange?: (name: string, value: any) => void
  errors?: string | undefined
  touched?: boolean
  selectName?: string
}

const SredstvaDropdown: React.FC<DropdownProps> = ({
  value,
  initialValue,
  onChange,
  errors,
  touched,
  selectName,
}) => {
  const [sredstvaDropdown, setSredstvaDropdown] = useState<Array<Option>>([])
  const internalValue = initialValue ?? null

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSredstvaDropdown()
        setSredstvaDropdown(mapData(response.data))
      } catch (error) {
        console.error('Error fetching sektor dropdown data:', error)
      }
    }
    fetchData()
  }, [])

  const finalValue = value ?? internalValue

  const mapData = (data) => {
    if (!data) {
      return []
    }
    return data.map((item) => ({label: item.Naziv, value: item.Id.toString()}))
  }

  return (
    <SearchableSelect
      options={sredstvaDropdown}
      value={finalValue?.toString() ?? ''}
      onChange={onChange!}
      name={selectName ?? 'DokSredsvo'}
      errors={errors}
      placeholder='Odaberi sredstvo...'
      touched={touched}
    />
  )
}

export default SredstvaDropdown
