import React, {useState, useEffect} from 'react'
import {
    flexRender,
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    getFilteredRowModel,
} from '@tanstack/react-table'
import TablePagination from '../../../components/TablePagination/TablePagination'
import notifyToast from '../../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import {KTCard, KTCardBody, KTIcon} from '../../../../_websoft/helpers'
import TableSearch from '../../../components/TableSearch/TableSearch'
import ExportButton from '../../../components/TableExportToExcel/ExportButton'
import {Link} from 'react-router-dom'
import {TableActionButton} from '../../../components/TableActionBtn/TableActionButton'
import {useNavigate} from 'react-router-dom'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
import {PaginationModel} from '../../../models/PaginationModel'
import {PanelSifre} from "../../../models/PanelSifarniciModel";
import {deleteSirinaPanela, getAllSirinePanela} from "../../../api/paneliSirine";

const SirineLista:React.FC = () => {
    const [data, setData] = useState<PanelSifre[]>([])
    const [loading, setLoading] = useState(true)
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })

    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')

    const updatePage = (pageNo, perPage) => {
        setLoading(true)
        getAllSirinePanela(pageNo, perPage)
            .then((response) => {
                setData(response.data['data'])
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                setpagination({
                    curPage: response.data['current_page'],
                    totalPage: totalPage,
                    perPage: perPage,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getAllSirinePanela(pagination.curPage, pagination.perPage).then((response) => {
            setData(response.data['data'])
            const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
            setpagination({
                curPage: response.data['current_page'],
                totalPage: totalPage,
                perPage: pagination.perPage,
            })
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        const {id} = sorting[0] ?? ''
        data.sort((a, b) => a[id] - b[id])
    }, [sorting])

    const columns = [
        {
            header: 'Id',
            accessorKey: 'id',
        },
        {
            header: 'Šifra',
            accessorKey: 'sirina',
        },
        {
            header: 'Koeficijent',
            accessorKey: 'koeficijent',
        },
        {
            header: 'Akcija',
            accessorKey: 'Action',
            cell: ({row}) => (
                <TableActionButton
                    onDelete={() => onDelete(row.original.id)}
                    onEdit={() => {
                        onEdit(row.original.id)
                    }}
                />
            ),
        },
    ]
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
    })
    const onDelete = (id) => {
        deleteSirinaPanela(id)
            .then((response) => {
                updatePage(pagination.curPage, pagination.perPage)
                notifyToast(true, response.message)
            })
            .catch((error) => {
                notifyToast(false, error.data['message'])
            })
    }
    const navigate = useNavigate()

    const onEdit = (rowData) => {
        navigate(`/paneli/sirine/izmjena/${rowData}`)
    }

    return (
        <KTCard>
            <ToastContainer />
            <KTCardBody className='py-4'>
                <div className='d-flex justify-content-between'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)} />
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <ExportButton table={table} filename={'sifre_panela'} />
                        <Link to='/paneli/sirine/dodaj'>
                            <button type='button' className='btn btn-primary'>
                                <KTIcon iconName='plus' className='fs-2' />
                                Dodaj Širinu
                            </button>
                        </Link>
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent />
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600 fw-bold'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination updatePage={updatePage} pagination={pagination} />
            </KTCardBody>
        </KTCard>
    )
}
export default SirineLista;
