/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import SearchableSelect from '../../../../components/SearchableSelect/SearchableSelect'
import {Option} from '../../../../models/Sektor'
import {getKupciPanelaDropdown} from '../../../../api/kupciPanelaDropdown'

//  Props for the component
interface DropdownProps {
  value?: string | number
  initialValue?: string | number
  onChange?: (name: string, value: any) => void
  errors?: string | undefined
  touched?: boolean
  selectName?: string
}

const KupciPanelaDropdown: React.FC<DropdownProps> = ({
  value,
  initialValue,
  onChange,
  errors,
  touched,
  selectName,
}) => {
  const [KupciPanelaDropdown, setKupciPanelaDropdown] = useState<Array<Option>>([])
  const internalValue = initialValue ?? null

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getKupciPanelaDropdown()
        setKupciPanelaDropdown(mapData(response.data))
      } catch (error) {
        console.error('Error fetching dropdown data:', error)
      }
    }
    fetchData()
  }, [])

  const finalValue = value ?? internalValue

  const mapData = (data) => {
    if (!data) {
      return []
    }
    return data.map((item) => ({label: item.Naziv, value: item.Id?.toString()}))
  }

  return (
    <SearchableSelect
      options={KupciPanelaDropdown}
      value={finalValue?.toString() ?? ''}
      onChange={onChange!}
      name={selectName ?? 'KupciPanelaDropdown'}
      errors={errors}
      placeholder='Odaberi kupca.'
      touched={touched}
    />
  )
}

export default KupciPanelaDropdown
