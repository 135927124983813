import axios from 'axios'
import {Option, UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {FmkNalogiStavkeModel, SredstvoInfoModel} from '../models/FmkNaloziModel'
const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/fmkNalozi/zadnjiNalozi`
const STATUS_API = `${API_URL}/statusDropdown?kategorija=nalozi`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getFMKStatusDropdown() {
  try {
    return await axios.get<Array<Option>>(STATUS_API, {headers: headers})
  } catch (error) {
    console.error('Error fetching Rolne Dropdown  data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function zadnjiNalozi() {
  try {
    const response = await axios.get(`${API}`, {headers: headers})
    if (response.status === 200) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      return null
    }
  } catch (error) {
    console.error('Error in API call:', error)
    throw error
  }
}

export async function getFmkNalozi(params: any) {
  try {
    const response = await axios.get(`${API_URL}/fmkNalozi/fmkNalozi`, {
      headers: headers,
      params,
    })
    if (response.status === 200) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      return null
    }
  } catch (error) {
    console.error('Error in API call:', error)
    throw error
  }
}

export async function saveFMKNalozi() {
  try {
    const response = await axios.post(`${API_URL}/fmkNalozi/fmkNalozi`, {headers: headers})

    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateFMKNalozi(Data: any) {
  try {
    const response = await axios.post(`${API_URL}/fmkNalozi/updateFmkNalog`, Data, {
      headers: headers,
    })
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      return response.data
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function getFmkNalogaStavkaData(params: any) {
  try {
    const response = await axios.get<FmkNalogiStavkeModel>(`${API_URL}/fmkNalozi/getNalogData`, {
      headers: headers,
      params,
    })
    if (response.status === 200 && response.data) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      throw Error
    }
  } catch (error) {
    console.error('Error in API call:', error)
    throw error
  }
}

export async function getKcerkeSifreDropdown(params: any) {
  try {
    const response = await axios.get(`${API_URL}/getKcerkeSifre`, {
      headers: headers,
      params,
    })
    if (response.status === 200) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      return null
    }
  } catch (error) {
    console.error('Error in API call:', error)
    throw error
  }
}

export async function izlazStavkiFmkNaloga(data: any) {
  try {
    const response = await axios.post(`${API_URL}/fmkNalozi/izlazStavkiFmkNaloga`, data, {
      headers: headers,
    })
    if (response.status === 200) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      return null
    }
  } catch (error) {
    console.error('Error in API call:', error)
    throw error
  }
}
export async function spremiFmkNalog(data: any) {
  try {
    const response = await axios.post(`${API_URL}/fmkNalozi/spremiFmkNalog`, data, {
      headers: headers,
    })
    if (response.status === 200) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      return null
    }
  } catch (error) {
    console.error('Error in API call:', error)
    throw error
  }
}
export async function isporuciFmkNalog(data: any) {
  try {
    const response = await axios.post(`${API_URL}/fmkNalozi/isporuciFmkNalog`, data, {
      headers: headers,
    })
    if (response.status === 200) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      return null
    }
  } catch (error) {
    console.error('Error in API call:', error)
    throw error
  }
}

export async function rolnaDetaljiProizvodnja(params: any) {
  try {
    const response = await axios.get(`${API_URL}/rolnaDetaljiProizvodnja`, {
      headers: headers,
      params,
    })
    if (response.status === 200) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      return null
    }
  } catch (error) {
    console.error('Error in API call:', error)
    throw error
  }
}

export async function rolnaDetaljiProizvodnjaV2(params: any) {
  try {
    const response = await axios.get(`${API_URL}/rolnaDetaljiProizvodnjaV2`, {
      headers: headers,
      params,
    })
    if (response.status === 200) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      return null
    }
  } catch (error) {
    console.error('Error in API call:', error)
    throw error
  }
}

export async function rolnaDetaljiProizvodnjaV3(params: any) {
  try {
    const response = await axios.get(`${API_URL}/rolnaDetaljiProizvodnjaV3`, {
      headers: headers,
      params,
    })
    if (response.status === 200) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      return null
    }
  } catch (error) {
    console.error('Error in API call:', error)
    throw error
  }
}

export async function sredstvoInfo(params: any) {
  try {
    const response = await axios.get<SredstvoInfoModel>(`${API_URL}/sredstvoInfo`, {
      headers: headers,
      params,
    })
    if (response.status === 200) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      return null
    }
  } catch (error) {
    console.error('Error in API call:', error)
    throw error
  }
}

export async function repromaterijalPromet(data: any) {
  try {
    const response = await axios.post(`${API_URL}/repromaterijalPromet`, data, {
      headers: headers,
    })
    if (response.status === 200) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      return null
    }
  } catch (error) {
    console.error('Error in API call:', error)
    throw error
  }
}

export async function radniNaloziOdjeli() {
  try {
    const response = await axios.get(`${API_URL}/fmkNalozi/radniNaloziOdjeli`, {
      headers: headers,
    })
    if (response.status === 200) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      return null
    }
  } catch (error) {
    console.error('Error in API call:', error)
    throw error
  }
}
export async function arhivirajNalog(data) {
  try {
    const response = await axios.put(`${API_URL}/fmkNalozi/arhivirajNalog`, data, {
      headers: headers,
    })
    if (response.status === 200 && response.data.error === 0) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      throw new Error(response.data.message)
    }
  } catch (error: any) {
    console.error('Error in API call:', error)
    throw new Error(error.response.data.message)
  }
}

export async function naloziUProizvodnji(data) {
  try {
    const response = await axios.put(`${API_URL}/fmkNalozi/naloziUProizvodnji`, data, {
      headers: headers,
    })
    if (response.status === 200 && response.data.error === 0) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      throw new Error(response.data.message)
    }
  } catch (error: any) {
    console.error('Error in API call:', error)
    throw new Error(error.response.data.message)
  }
}

export async function naloziSpremniZaIsporuku(data) {
  try {
    const response = await axios.put(`${API_URL}/fmkNalozi/naloziSpremniZaIsporukuFMK`, data, {
      headers: headers,
    })
    if (response.status === 200 && response.data.error === 0) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      throw new Error(response.data.message)
    }
  } catch (error: any) {
    console.error('Error in API call:', error)
    throw new Error(error.response.data.message)
  }
}


export async function stavkeNalogaUProizvodnji(params: any) {
  try {
    const response = await axios.get(`${API_URL}/fmkNalozi/stavkeNalogaUProizvodnji`, {
      headers: headers,
      params,
    })
    if (response.status === 200) {
      return response.data
    } else {
      console.error('API call returned an error:', response)
      return Error(response.data.message)
    }
  } catch (error: any) {
    console.error('Error in API call:', error)
    throw new Error(error.response.data.message)
  }
}
