import React, {forwardRef, useMemo, useState} from 'react'
import {useFormik} from 'formik'

import * as Yup from 'yup'
import {krajRadnogNaloga} from '../../../../api/radniNalozi'
import notifyToast from '../../../../components/Toast/Toast'
import {fetchTable} from '../../../../api/tableRS'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import QrCodeScanner from '../../../../components/QrCodeScanner/QrCodeScanner'
import clsx from 'clsx'
import {KTIcon} from '../../../../../_websoft/helpers'
import {useNavigate} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'

interface Props {
  nalogaId: string
}
export type Ref = any
const KrajTraka = forwardRef<Ref, Props>(({nalogaId}, ref) => {
  const nalog = useMemo(() => nalogaId, [nalogaId])
  const initialValues = {
    trake: [],
  }
  const [loading, setLoading] = useState(false)
  const validationSchema = Yup.object({
    // trakaId: Yup.string().required('required'),
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialData, setInitialData] = useState<any>(initialValues)
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      const payloadData = {
        IdNaloga: nalog,
        BrojRolne: '',
        KrajnjaKolicina: '',
        Lokacija: '',
        trake: formik.values.trake,
      }

      krajRadnogNaloga(payloadData)
        .then((response) => {
          notifyToast(true, response.message)
          setTimeout(() => {
            navigate(-1)
          }, 3000)
        })
        .catch((error) => {
          console.log(error)
          notifyToast(false, error.message)
        })
        .finally(() => setLoading(false))
    },
  })

  const getTrakeData = (trakeId, rowId) =>
    fetchTable(trakeId)
      .then((response) => {
        console.log({response})
        formik.values.trake![rowId].duzina = response.TrenutnaDuzina
        formik.values.trake![rowId].tezina = response?.Tezina
        formik.values.trake![rowId].sirina = response?.Sirina
        formik.values.trake![rowId].rolna = response?.BrojRolne
        formik.values.trake![rowId].proizvodjac = response!.repromaterijal!.proizvodjac!.PartnerNaziv
        formik.setFieldValue('trake', formik.values.trake)
      })
      .catch((error) => notifyToast(false, error.message))

  return (
    <div className='w-100'>
      <ToastContainer />
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Trake Info</h2>
      </div>
      {loading ? (
        <LoadingComponent />
      ) : (
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className='row mb-10'>
            {/* begin : dynamic rows */}
            <div className='row mb-6'>
              {Array.from({length: formik.values.trake!.length}, (_, i) => (
                <div className='row'>
                  {/* begin: Broj Trake */}
                  <div className='col-lg-6 col-md-6 mb-6'>
                    <label className='col-lg-8 col-form-label required fw-bold fs-6'>
                      Broj Trake
                    </label>
                    <div className='row justify-content-between'>
                      <div className='col-2'>
                        <QrCodeScanner
                          onScan={(data) => {
                            const text = data.split(';')[0]
                            formik.values.trake![i].TrakaId = text
                            formik.setFieldValue('trake', formik.values.trake)
                            getTrakeData(formik.values.trake![i].TrakaId, i)
                          }}
                        />
                      </div>
                      <div className='col-8'>
                        <input
                          type='number'
                          placeholder='Broj Trake'
                          className={clsx('form-control form-control-lg form-control-solid')}
                          value={formik.values.trake![i].TrakaId}
                          onChange={(e) => {
                            formik.values.trake![i].TrakaId = e.target.value
                            formik.setFieldValue('trake', formik.values.trake)
                            getTrakeData(formik.values.trake![i].TrakaId, i)
                          }}
                        />
                      </div>
                    </div>
                      <h2 className='col-lg-12 col-form-label fw-bold fs-6'>
                          Dužina: <span className="text-danger">{formik.values.trake![i].duzina}</span>
                          {' '} Težina:{' '} <span className="text-danger">{formik.values.trake![i].tezina}</span>
                          {' '} Širina:{' '} <span className="text-danger">{formik.values.trake![i].sirina}</span>
                          {' '} Broj rolne:{' '} <span className="text-danger">{formik.values.trake![i].rolna}</span>
                          {' '} Proizvođač:{' '} <span className="text-danger">{formik.values.trake![i].proizvodjac}</span>
                      </h2>
                  </div>
                  {/* end: Broj Traka */}
                  {/* begin: Tezina */}
                  <div className='col-lg-3 col-md-6 mb-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                      Krajnja Težina
                    </label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Tezina'
                        className={clsx('form-control form-control-lg form-control-solid')}
                        value={formik.values.trake![i].KrajnjaKolicina}
                        onChange={(e) => {
                            const newValue = Number(e.target.value);
                            const maxTezina = formik.values.trake![i].tezina;
                            // Check if the new value exceeds tezina
                            if (newValue <= maxTezina) {
                                // Update the value as it's within the allowed range
                                formik.setFieldValue(`trake[${i}].KrajnjaKolicina`, newValue);
                            } else {
                                // Optionally, reset to max allowed value if exceeded
                                formik.setFieldValue(`trake[${i}].KrajnjaKolicina`, maxTezina);
                                // Or you could also choose to not update the value at all
                                // This would effectively prevent the user from entering a value higher than tezina
                            }
                        }}
                      />
                    </div>
                  </div>
                  {/* end: Tezina */}

                  <div className='col-lg-2 align-self-center mt-5'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm'
                      onClick={() => {
                        var trake = formik.values.trake ?? []

                        trake.splice(i, 1)

                        formik.setFieldValue('trake', trake)
                      }}
                    >
                      <KTIcon iconName='minus' className='fs-2' />
                    </button>
                  </div>
                </div>
              ))}
              <div className='col-lg-2 mb-6'>
                <button
                  type='button'
                  className='btn btn-primary btn-sm'
                  onClick={() => {
                    var trake = formik.values.trake ?? []
                    trake.push({
                      TrakaId: '',
                      KrajnjaKolicina: '',
                    })
                    formik.setFieldValue('trake', trake)
                  }}
                >
                  <KTIcon iconName='plus' className='fs-2' />
                </button>
              </div>
            </div>
            {/* end : dynamic rows */}
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && 'Potvrdi'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      )}
    </div>
  )
})

export default KrajTraka
