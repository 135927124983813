import React, {useState, useEffect} from 'react'
import SearchableSelect from '../SearchableSelect/SearchableSelect'
import {Option} from '../../models/BojaModel'
import {getDokumentiDropdown} from '../../api'

// Option type as per the API response

// DropdownProps for the component
interface DropdownProps {
  value?: string | number
  initialValue?: string | number
  onChange?: (name: string, value: any) => void
  errors?: string | undefined
  touched?: boolean
  selectName?: string
}

const mapDropdown = (data) => {
  return data.map((item) => ({label: item.Naziv, value: item.Id.toString()}))
}

const DokumentiDropdown: React.FC<DropdownProps> = ({
  value,
  initialValue,
  onChange,
  errors,
  touched,
  selectName,
}) => {
  const [dokumentiDropdown, setDokumentiDropdown] = useState<Array<Option>>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [internalValue, setInternalValue] = useState<string | number | null>(initialValue ?? null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDokumentiDropdown()
        setDokumentiDropdown(mapDropdown(response.data))
      } catch (error) {
        console.error('Error fetching drzava dropdown data:', error)
      }
    }
    fetchData()
  }, [])

  const finalValue = typeof value !== 'undefined' ? value : internalValue

  return (
    <SearchableSelect
      options={dokumentiDropdown}
      value={finalValue?.toString() ?? ''}
      onChange={onChange!}
      name={selectName ?? 'Dokumenti'}
      errors={errors}
      placeholder='Odaberi Dokumenti...'
      touched={touched}
    />
  )
}

export default DokumentiDropdown
