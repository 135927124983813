import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {PakingListeModel} from '../models/PaneliPaketi'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/panelPakovanje`
const API_IZBRISI = `${API_URL}/paneli/izbrisiStavkuPaketa`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getAllPaketiPanela(brojNaloga, stavka) {
  const params = {brojNaloga, stavka}
  return await axios.get(API, {headers: headers, params: params})
}

export async function updatePaketi(Data: any) {
  try {
    const response = await axios.post(`${API_URL}/paneli/updatePaketi`, Data, {headers: headers})

    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function isporuciPaketeApi(params: any) {
  try {
    const response = await axios.put(`${API_URL}/paneli/isporuciPakete`, params, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function isporuciPaketeQRApi(params: any) {
  try {
    const response = await axios.put(`${API_URL}/paneli/isporuciPaketeQR`, params, {
      headers: headers,
    })
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function vratiPaketeApi(params: any) {
  try {
    const response = await axios.put(`${API_URL}/paneli/vratiPakete`, params, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
export async function pakingListeApi(params: any) {
  try {
    const response = await axios.get<Array<PakingListeModel>>(`${API_URL}/paneli/pakingListe`, {
      headers: headers,
      params: params,
    })
    return response.data
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function deletePaket(Id: number) {
  try {
    const response = await axios.delete(`${API_IZBRISI}/${Id}`, {headers: headers})
    if (response.data && response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to delete.')
    }
  } catch (error) {
    console.error('Error deleting data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateLokacijePaketa(params: any) {
  try {
    const response = await axios.put(`${API_URL}/paneli/updateLokacijePaketa`, params, {
      headers: headers,
    })
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
