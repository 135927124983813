import React, {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_websoft/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_websoft/assets/ts/_utils'
import {WithChildren} from '../../_websoft/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import ArtikliRoutes from '../pages/artikli/ArtikliRoutes'
import PaneliRoutes from '../pages/paneli/PaneliRoutes'
import ThermoBoardRoutes from '../pages/thermoboard/ThermoBoardRoutes'
import FmkNaloziRoutes from '../pages/fmk-nalozi/FmkNaloziRoutes'
import SredstvaRoutes from '../pages/sredstva/SredstvaRoutes'
import GalanterijaRoutes from '../pages/galanterija/GalanterijaRoutes'
import TerminaliRoutes from '../pages/terminali/TerminaliRoutes'
import KreirajQRcode from '../pages/kreirajQRcode/KreirajQRcode'
import ChatWithGPT from '../pages/ai-chat/ChatWithGPT'
import {useAuth} from '../modules/auth'
import RequireAuth from '../modules/auth/RequireAuth'
import PartneriRoutes from '../pages/partneri/PartneriRoutes'
import InventureRoutes from "../pages/inventure/InventureRoutes";
import ProdajaNajamRoutes from "../pages/prodaja-najam/ProdajaNajamRoute";
import ProdajaNajamDetails from "../pages/prodaja-najam/ProdajaNajamDetails";

const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    const UposleniciRoutes = lazy(() => import('../pages/uposlenici/UposleniciRoutes'))
    const SifarniciRoutes = lazy(() => import('../pages/sifarnici/SifarniciRoutes'))
    const RepromaterijaliRoutes = lazy(() => import('../pages/repromaterijali/RepromaterijaliRoutes'))
    const TableRoutes = lazy(() => import('../pages/table-rs/TableRoutes'))
    const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
    const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
    const PartneriPaneliRoutes = lazy(() => import('../pages/partneri/paneli/PartneriPaneliRoutes'))
    const PartneriThermoboardRoutes = lazy(
        () => import('../pages/partneri/thermoboard/PartneriThermoboardRoutes')
    )
    const PartneriReprmatRoutes = lazy(
        () => import('../pages/partneri/repromaterijali/PartneriReprmatRoutes')
    )
    const {currentUser} = useAuth()

    const initalRoutes = {
        0: '/dashboard',
        1: '/dashboard',
        2: '/terminali/pocetna',
        3: '/partneri/pocetna',
        4: '/login',
    }
    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route
                    path='auth/*'
                    element={<Navigate to={initalRoutes[currentUser?.Privilegija ?? 4]}/>}
                />
                {/* Pages */}

                {/* Begin: Privilege 1 routes*/}
                <Route element={<RequireAuth allowedRoles={[0, 1]}/>}>
                    <Route path='dashboard' element={<DashboardWrapper/>}/>
                    <Route
                        path='sifarnici/*'
                        element={
                            <SuspensedView>
                                <SifarniciRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='repromaterijal/*'
                        element={
                            <SuspensedView>
                                <RepromaterijaliRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route path='kreiraj-qr-code' element={<KreirajQRcode/>}/>
                    <Route
                        path='table-rs/*'
                        element={
                            <SuspensedView>
                                <TableRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='artikli/*'
                        element={
                            <SuspensedView>
                                <ArtikliRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='paneli/*'
                        element={
                            <SuspensedView>
                                <PaneliRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='thermoboard/*'
                        element={
                            <SuspensedView>
                                <ThermoBoardRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='fmk-nalozi/*'
                        element={
                            <SuspensedView>
                                <FmkNaloziRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='galanterija/*'
                        element={
                            <SuspensedView>
                                <GalanterijaRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='sredstva/*'
                        element={
                            <SuspensedView>
                                <SredstvaRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='uposlenici/*'
                        element={
                            <SuspensedView>
                                <UposleniciRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='prodaja-najam/*'
                        element={
                            <SuspensedView>
                                <ProdajaNajamRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='inventure/*'
                        element={
                            <SuspensedView>
                                <InventureRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='chat/*'
                        element={
                            <SuspensedView>
                                <ChatWithGPT/>
                            </SuspensedView>
                        }
                    />

                </Route>
                {/* End: Privilege 1 routes*/}

                {/* Begin: Privilege 2 routes*/}
                <Route element={<RequireAuth allowedRoles={[0, 2]}/>}>
                    <Route
                        path='terminali/*'
                        element={
                            <SuspensedView>
                                <TerminaliRoutes/>
                            </SuspensedView>
                        }
                    />
                </Route>
                {/* End: Privilege 2 routes*/}

                {/* Begin: Privilege 3 routes*/}
                <Route element={<RequireAuth allowedRoles={[0, 3]}/>}>
                    <Route
                        path='partneri/*'
                        element={
                            <SuspensedView>
                                <PartneriRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='partneri/paneli/*'
                        element={
                            <SuspensedView>
                                <PartneriPaneliRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='partneri/thermoboard/*'
                        element={
                            <SuspensedView>
                                <PartneriThermoboardRoutes/>
                            </SuspensedView>
                        }
                    />
                    <Route
                        path='partneri/repromaterijali/*'
                        element={
                            <SuspensedView>
                                <PartneriReprmatRoutes/>
                            </SuspensedView>
                        }
                    />
                </Route>
                {/* End: Privilege 3 routes*/}

                <Route
                    path='builder'
                    element={
                        <SuspensedView>
                            <BuilderPageWrapper/>
                        </SuspensedView>
                    }
                />
                <Route path='menu-test' element={<MenuTestPage/>}/>
                {/* Lazy Modules */}
                <Route
                    path='crafted/pages/profile/*'
                    element={
                        <SuspensedView>
                            <ProfilePage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/pages/wizards/*'
                    element={
                        <SuspensedView>
                            <WizardsPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/widgets/*'
                    element={
                        <SuspensedView>
                            <WidgetsPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/account/*'
                    element={
                        <SuspensedView>
                            <AccountPage/>
                        </SuspensedView>
                    }
                />

                <Route
                    path='apps/user-management/*'
                    element={
                        <SuspensedView>
                            <UsersPage/>
                        </SuspensedView>
                    }
                />
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404'/>}/>
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
