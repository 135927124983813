import {useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../../_websoft/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {StepperComponent} from '../../../../_websoft/assets/ts/components'
import {Form, Formik, FormikValues} from 'formik'
import {
  createAccountSchemas,
  ICreateAccount,
  inits,
} from '../../../modules/wizards/components/CreateAccountWizardHelper'
import {getFmkNalogaStavkaData} from '../../../api/fmkNalozi'
import {FmkNalogiStavkeModel} from '../../../models/FmkNaloziModel'
import notifyToast from '../../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import {useNavigate} from 'react-router-dom'

const Profilacija: React.FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [naloga, setNaloga] = useState<string>()
  const [dataStavka, setDataStavka] = useState<FmkNalogiStavkeModel>()
  const [loading, setLoading] = useState(false)
  const ref = useRef<HTMLButtonElement>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    console.log('submit')
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      if (stepper.current?.currentStepIndex === 1 && naloga) {
        setLoading(true)
        await getFmkNalogaStavkaData({nalog: naloga})
          .then((response) => {
            if (response.nalog) {
              setDataStavka(response)
              stepper.current!.goNext()
            } else {
              notifyToast(false, response['message'])
              return
            }
          })
          .catch((error) => notifyToast(false, error.response.data.message))
          .finally(() => setLoading(false))
      }
    } else {
      // @ts-ignore
      ref.current.saveData()
    }

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      <ToastContainer />
      {/* begin::Aside*/}
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        {/* begin::Wrapper*/}
        <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
          {/* begin::Nav*/}
          <div className='stepper-nav'>
            {/* begin::Step 1*/}
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Broj naloga</h3>

                  <div className='stepper-desc fw-semibold'>Upiši/Skeniraj broj naloga</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 1*/}

            {/* begin::Step 2*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Rolna/Traka</h3>
                  <div className='stepper-desc fw-semibold'>Setup Your Account Settings</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 2*/}
          </div>
          {/* end::Nav*/}
        </div>
        {/* end::Wrapper*/}
      </div>
      {/* begin::Aside*/}

      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
          {() => {
            return (
              <Form className='py-20 w-100 w-xl-1100px px-9' noValidate id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step1 setNaloga={setNaloga} naloga={naloga} />
                </div>
                <div data-kt-stepper-element='content'>
                  {' '}
                  {dataStavka && <Step2 ref={ref} naloga={naloga!} dataStavka={dataStavka} />}
                </div>
                <div className='d-flex flex-stack pt-10'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                      Back
                    </button>
                  </div>
                  <div>
                    <button type='submit' className='btn btn-lg btn-primary me-3'>
                      {loading ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <span className='indicator-label'>
                          {stepper.current?.currentStepIndex !==
                            stepper.current?.totalStepsNumber! && 'Continue'}
                          {stepper.current?.currentStepIndex ===
                            stepper.current?.totalStepsNumber! && 'Submit'}
                          <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
export default Profilacija
