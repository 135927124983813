import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import notifyToast from '../../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../../components/TitleComponent/TitleComponent'

import {
    proizvediThermoBoradPaketeQR, rezervisiThermoBoradPaketeQR
} from "../../../api/thermoBoardPaketi";
import {dnevniIzvjestajOtprema, dnevniIzvjestajProizvodnja} from "../../../api/proizvodnja";

const TermopaneliDnevniIzvjestaj:React.FC = () => {

    const [loading, setLoading] = useState(false)
    const [proizvedeno, setProizvedeno] = useState('');
    const [skart, setSkart] = useState('');
    const [skartProfilisaniLimovi, setSkartProfilisaniLimovi] = useState('');
    const [komentar, setKomentar] = useState('');
    const [isporukaDomace, setisporukaDomace] = useState('');
    const [isporukaStrano, setisporukaStrano] = useState('');
    const [istovareno, setistovareno] = useState('');

    const [prikaziStavke, setprikaziStavke] = useState(true);
    const [proizvodnja, setProizvodnja] = useState(false);
    const [otprema, setOtprema] = useState(false);

    const proizvodnjaShow = () =>{
        setProizvodnja(true)
        setprikaziStavke(false)
    }
    const otpremaShow = () =>{
        setOtprema(true)
        setprikaziStavke(false)
    }

    const handleProizvedno = async (event) => {
        event.preventDefault();
        setLoading(true)
        const payload = {
            proizvedeno: proizvedeno,
            skart: skart,
            skartProfLimovi:skartProfilisaniLimovi,
            komentar:komentar,
        };

        try {
            const response = await dnevniIzvjestajProizvodnja(payload);

            if (response.error === 0) {
                notifyToast(true, response.message);
            } else {
                notifyToast(false, response.message || 'An unexpected error occurred.');
            }
        } catch (error: any) {
            console.error('Error: ', error)
            notifyToast(false, error.response.data.message)
        }
        setLoading(false)
    };

    const handleOtprema = async (event) => {
        event.preventDefault();
        setLoading(true)
        const payload = {
            isporukaDomace: isporukaDomace,
            isporukaStrano: isporukaStrano,
            istovareno:istovareno,
            komentar:komentar,
        };

        try {
            const response = await dnevniIzvjestajOtprema(payload);

            if (response.error === 0) {
                notifyToast(true, response.message);
            } else {
                notifyToast(false, response.message || 'An unexpected error occurred.');
            }
        } catch (error: any) {
            console.error('Error: ', error)
            notifyToast(false, error.response.data.message)
        }
        setLoading(false)
    };
    return (
        <div className='row'>
            <ToastContainer/>
            <div className='card mb-5 mb-xl-10 col-lg-5 col-sm-12'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title mb-5'>
                        <TitleComponent name='Thermopaneli Dnevni Izvještaji'/>
                    </div>
                </div>
                {prikaziStavke && (
                    <div className='col-6 mb-6'>
                        <div className="d-grid gap-5">
                            <button className="btn btn-warning p-5" onClick={proizvodnjaShow} type="button">
                                Proizvodnja
                            </button>
                            <button className="btn btn-success p-5" onClick={otpremaShow} type="button">
                                Otprema
                            </button>
                        </div>
                    </div>
                )}
                {proizvodnja && (
                <div className="row">
                    <h2>Proizvodnja</h2>
                    <div className="separator mb-5"></div>
                    <div className="col-lg-6 mt-4">
                        <input
                            type='text'
                            placeholder='Proizvedeno'
                            onChange={(e) => setProizvedeno(e.target.value)}
                            value={proizvedeno}
                            className='form-control'
                        />
                    </div>
                    <div className="col-lg-6 mt-4">
                        <input
                            type='text'
                            placeholder='Škart'
                            onChange={(e) => setSkart(e.target.value)}
                            value={skart}
                            className='form-control'
                        />
                    </div>
                    <div className="col-lg-12 mt-4">
                        <input
                            type='text'
                            placeholder='Škart Profilisani limovi m1'
                            onChange={(e) => setSkartProfilisaniLimovi(e.target.value)}
                            value={skartProfilisaniLimovi}
                            className='form-control'
                        />
                    </div>
                    <div className="col-lg-12 mt-4">
                        <textarea className="form-control"  onChange={(e) => setKomentar(e.target.value)}
                                  value={komentar}
                                  placeholder="Komentar"></textarea>
                    </div>
                    <div className="col-lg-12 mt-4">
                        <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={handleProizvedno}
                            disabled={loading}
                        >
                            {!loading && 'Potvrdi'}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                                        Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                            )}
                        </button>
                    </div>
                </div>
                    )}
                {otprema && (
                    <div className="row">
                        <h2>Otprema</h2>
                        <div className="separator mb-5"></div>
                        <div className="col-lg-6 mt-4">
                            <input
                                type='text'
                                placeholder='Isporučeno BiH'
                                onChange={(e) => setisporukaDomace(e.target.value)}
                                value={isporukaDomace}
                                className='form-control'
                            />
                        </div>
                        <div className="col-lg-6 mt-4">
                            <input
                                type='text'
                                placeholder='Isporučeno Izvoz'
                                onChange={(e) => setisporukaStrano(e.target.value)}
                                value={isporukaStrano}
                                className='form-control'
                            />
                        </div>
                        <div className="col-lg-12 mt-4">
                            <input
                                type='text'
                                placeholder='Istovareno'
                                onChange={(e) => setistovareno(e.target.value)}
                                value={istovareno}
                                className='form-control'
                            />
                        </div>
                        <div className="col-lg-12 mt-4">
                        <textarea className="form-control"  onChange={(e) => setKomentar(e.target.value)}
                                  value={komentar}
                                  placeholder="Komentar"></textarea>
                        </div>
                        <div className="col-lg-12 mt-4">
                            <button
                                type="button"
                                className="btn btn-block btn-primary"
                                onClick={handleOtprema}
                                disabled={loading}
                            >
                                {!loading && 'Potvrdi'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                                        Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </div>


        </div>
    )
}
export default TermopaneliDnevniIzvjestaj;
