import React, {useMemo} from 'react'
import {Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer'
import {KreirajQRcodeModel} from '../../models/KreirajQRcodeModel'
import logo from '../../../_websoft/assets/images/logo.jpg'
// Register font

// Create styles
const styles = StyleSheet.create({
  pdf: {width: '100vh', height: '500px'},
  page: {
    flexDirection: 'row',
    flex: 1,
  },
  section: {
    margin: 10,
    padding: 10,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  qrCode: {
    width: '140px',
  },
  seriskiBroj: {
    width: '180px',
  },
  subtitle: {
    fontSize: 26,
    marginTop: 30,
    marginBottom: 12,
    marginHorizontal: 12,
  },
  text: {
    marginVertical: 3,
    marginHorizontal: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Roboto',
  },
  text2: {
    marginVertical: 5,
    marginHorizontal: 12,
    fontSize: 18,
    textAlign: 'justify',
    fontFamily: 'Roboto',
  },
  text3: {
    marginVertical: 3,
    marginHorizontal: 12,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Roboto',
  },
  logo: {
    margin: 10,
    padding: 2,
    width: '200px',
  },
})
interface Props {
  qrData?: Array<KreirajQRcodeModel>
  qrList?: Array<string>
}
const RolnePdf: React.FC<Props> = ({qrData, qrList}) => {
  const data = useMemo(() => ({qrData}), [qrData])
  const list = useMemo(() => ({qrList}), [qrList])

  return (
    <Document>
      {data.qrData &&
        data.qrData.map((page, i) => {
          let sarza = '-'

          if (page.IdStavkeUgovora && page.stavka) {
            sarza = (page.IdStavkeUgovora / page.stavka.ManualId).toFixed(2)
          }
          let bojaLica = page.boja_lica?.BojaNaziv || ""; // Fallback to empty string if undefined
          let partLice = bojaLica.split(' - ');
          let resultBojaLica = partLice.length > 1 ? partLice[0] : bojaLica;

          let bojaNalicja = page.boja_nalicja?.BojaNaziv || ""; // Fallback to empty string if undefined
          let part = bojaNalicja.split(' - ');
          let resultBojaNalicja = part.length > 1 ? part[0] : bojaNalicja;


          return (
            <Page size='A6' orientation='landscape'>
              <View style={{flexDirection: 'row'}}>
                <Image src={logo} style={styles.logo} />
                <Text
                  style={[styles.subtitle, {marginLeft: 30, marginTop: 20, textAlign: 'center'}]}
                >
                  R-{page.ReprMatId}
                </Text>
              </View>
              <View style={styles.page}>
                <View>
                  <Text style={[styles.text2, styles.seriskiBroj, {marginLeft: 20}]}>
                    S/N: {page.ReprMatSeriski}
                  </Text>
                  <Text style={[styles.text2, styles.seriskiBroj, {marginLeft: 20}]}>
                    {page.ReprMatDebljina} / {page.ReprMatSirina}
                  </Text>

                  {/* @ts-ignore */}
                  <Image style={[styles.qrCode, {marginLeft: 10}]} source={{uri: list.qrList[i]}} />
                </View>
                <View style={{marginTop: 5}}>
                  <Text style={styles.text}>
                    {resultBojaLica} / {resultBojaNalicja}
                  </Text>
                  <Text style={styles.text}>BRUTTO: {page.ReprMatBruttoKolicina}</Text>
                  <Text style={styles.text}>NETTO: {page.ReprMatNettoKolicina}</Text>
                  <Text style={styles.text}>DUŽINA: {page.ReprMatDuzina}</Text>
                  <Text style={styles.text}>KVALITET: {page.kvalitet?.MatKvalitetNaziv ?? ''}</Text>
                  <Text style={styles.text}>ŠARŽA: </Text>
                  <Text style={styles.text}>{page.stavka?.ugovor?.BrojUgovora ?? ''} // {page.stavka?.ManualId ?? ''}</Text>
                  <Text style={styles.text}>STRUKTURA: {page.struktura_a?.MetaValue ?? ''}</Text>
                </View>
              </View>
            </Page>
          )
        })}
    </Document>
  )
}

export default RolnePdf
