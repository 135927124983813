/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {ParamsModelProizvodnja} from '../../../../models/ProizvodnjaModel'
import {KTSVG} from '../../../../../_websoft/helpers'
import PanelTipDropdown from '../PanelTipDropdown/PanelTipDropdown'
import KomercijalistePanelaDropdown from '../KomercijalistePanelaDropdown/KomercijalistePanelaDropdown'
import KupciPanelaDropdown from '../KupciPanelaDropdown/KupciPanelaDropdown'
import useDebounce from '../../../../hooks/useDebounce'

const ProizodnjaFilteri = ({onFilter}) => {
  const [filters, setfilters] = useState<Partial<ParamsModelProizvodnja>>({})
  const [brojNalog, setBrojNalog] = useState<string>()
  useEffect(() => {
    onFilter(filters)
  }, [filters])

  const debouncedBrojNalog = useDebounce(brojNalog)

  useEffect(() => {
    // Match regex format to call broj nalog
    const re = new RegExp('^\\s*\\d{6}/\\d{2}\\s*$')
    if (re.test(debouncedBrojNalog)) {
      setfilters({...filters, brojNalog: brojNalog?.trim()})
    } else {
    }
  }, [debouncedBrojNalog])

  return (
    <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
      <div className='mb-5'>
        <div
          className='accordion-header py-3 d-flex '
          data-bs-toggle='collapse'
          data-bs-target='#kt_accordion_2_item_1'
        >
          <span className='accordion-icon'>
            <KTSVG className='svg-icon svg-icon-4' path='/media/icons/duotune/arrows/arr064.svg' />
          </span>
          <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>FILTERI</h3>
        </div>
        <div id='kt_accordion_2_item_1' className='fs-6 ps-10' data-bs-parent='#kt_accordion_1'>
          <div className='row  py-5'>
            <div className='col-lg-3 col-md-4  mb-6'>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setfilters({...filters, statusPro: e.target.value})}
              >
                <option value={undefined}>Select</option>
                <option value='Zavrseno'> Zavrseno</option>
                <option value='Planirano'>Planirano</option>
                <option value='Na čekanju'>Na čekanju</option>
              </select>
            </div>

            <div className='col-lg-3 col-md-4  mb-6'>
              <PanelTipDropdown
                onChange={(name, value) => setfilters({...filters, tipPanela: value})}
              />
            </div>
            <div className='col-lg-3 col-md-4  fv-row mb-6'>
              <KomercijalistePanelaDropdown
                onChange={(name, value) => setfilters({...filters, kreiraoKorisnik: value})}
              />
            </div>
            <div className='col-lg-3 col-md-4  fv-row mb-6'>
              <KupciPanelaDropdown
                onChange={(name, value) => setfilters({...filters, idKupac: value})}
              />
            </div>
            <div className='col-lg-3 col-md-4  fv-row mb-6'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Broj naloga'
                value={brojNalog}
                onChange={(e) => setBrojNalog(e.target.value)}
              />
            </div>
            {/* <button type='button' className='btn btn-primary' onClick={() => onFilter(filters)}>
              Potvrdi
            </button> */}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProizodnjaFilteri
