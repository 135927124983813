import React, {FC, useState} from 'react'
import {Modal, ModalHeader} from 'react-bootstrap'
import notifyToast from '../../../../components/Toast/Toast'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import {snimiSkartAktivnogNaloga} from '../../../../api/zapisiProizvodnje'
import {ZapisiProizvodnjeModel} from '../../../../models/ZapisiProizvodnjeModel'

interface Props {
  isModalOpen: boolean
  setisModalOpen: Function
  nalogData: ZapisiProizvodnjeModel
}
const SkartZaNalogModal: FC<Props> = ({isModalOpen, setisModalOpen, nalogData}) => {
  const initialData = {
    NuzniSkart: undefined,
    NenuzniSkart: undefined,
    RazlogSkarta: undefined,
  }
  const validationSchema = Yup.object({
    NuzniSkart: Yup.number().required('required'),
    NenuzniSkart: Yup.number().required('required'),
    RazlogSkarta: Yup.string().required('required'),
  })
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      try {
        let response
        const data = {
          idZapisa: nalogData.Id,
          NuzniSkart: values.NuzniSkart,
          NenuzniSkart: values.NenuzniSkart,
          RazlogSkarta: values.RazlogSkarta,
        }
        response = await snimiSkartAktivnogNaloga(data)

        if (response.error === 0) {
          notifyToast(true, response.message)
        } else {
          notifyToast(false, response.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  return (
    <Modal className='modal-xl' show={isModalOpen} onHide={() => setisModalOpen(false)}>
      <ModalHeader closeButton={true} className='h3'>
        {' '}
        Kreiraj Škart Za Nalog
      </ModalHeader>
      <div id='kt_account_profile_details' className='collapse show'>
        {loading ? (
          <LoadingComponent />
        ) : (
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className='card-body border-top p-9 row'>
              <h2>Kreirao zapis : {nalogData.uposlenik.UposlenikImePrezime}</h2>
              {/* begin: NuzniSkart */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>Nužni Škart</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder=' '
                    {...formik.getFieldProps('NuzniSkart')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.NuzniSkart && formik.errors.NuzniSkart},
                      {
                        'is-valid': formik.touched.NuzniSkart && !formik.errors.NuzniSkart,
                      }
                    )}
                  />
                  {formik.touched.NuzniSkart && formik.errors.NuzniSkart && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.NuzniSkart}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* end: NuzniSkart */}
              {/* begin: NenuzniSkart */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                  Nenužni Škart
                </label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder=' '
                    {...formik.getFieldProps('NenuzniSkart')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.NenuzniSkart && formik.errors.NenuzniSkart},
                      {
                        'is-valid': formik.touched.NenuzniSkart && !formik.errors.NenuzniSkart,
                      }
                    )}
                  />
                  {formik.touched.NenuzniSkart && formik.errors.NenuzniSkart && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.NenuzniSkart}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* end: NenuzniSkart */}
              {/* begin: RazlogSkarta */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required  fw-bold fs-6'>Razlog Škarta</label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    placeholder=' '
                    {...formik.getFieldProps('RazlogSkarta')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.RazlogSkarta && formik.errors.RazlogSkarta},
                      {
                        'is-valid': formik.touched.RazlogSkarta && !formik.errors.RazlogSkarta,
                      }
                    )}
                  />
                  {formik.touched.RazlogSkarta && formik.errors.RazlogSkarta && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.RazlogSkarta}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* end: RazlogSkarta */}
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={loading || formik.isSubmitting || !formik.isValid}
              >
                {!loading && 'Potvrdi'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </Modal>
  )
}

export default SkartZaNalogModal
