/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import SearchableSelect from '../SearchableSelect/SearchableSelect'
import {getVrstaMaterijalaDropdown} from '../../api'
import {Option} from '../../models/Sektor'

// VrstaMaterijalaDropdown Props for the component
interface DropdownProps {
    value?: string | number
    initialValue?: string | number
    onChange?: (name: string, value: any) => void
    errors?: string | undefined
    touched?: boolean
    selectName?: string
}

const VrstaMaterijalaDropdown: React.FC<DropdownProps> = ({
                                                              value,
                                                              initialValue,
                                                              onChange,
                                                              errors,
                                                              touched,
                                                              selectName,
                                                          }) => {
    const [vrstaMaterijalaDropdown, setVrstaMaterijalaDropdown] = useState<Array<Option>>([])
    const internalValue = initialValue ?? null

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getVrstaMaterijalaDropdown()
                setVrstaMaterijalaDropdown(mapData(response.data))
            } catch (error) {
                console.error('Error fetching Vrsta Materijala dropdown data:', error)
            }
        }
        fetchData()
    }, [])

    const finalValue = value ?? internalValue

    const mapData = (data) => {
        if (!data) {
            return []
        }
        return data.map((item) => ({label: item.Naziv, value: item.Id.toString()}))
    }

    return (
        <SearchableSelect
            options={vrstaMaterijalaDropdown}
            value={finalValue?.toString() ?? ''}
            onChange={onChange!}
            name={selectName ?? 'vrstaMaterijalaDropdown'}
            errors={errors}
            placeholder='Odaberi Vrstu Materijala'
            touched={touched}
        />
    )
}

export default VrstaMaterijalaDropdown
