import axios from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {KvalitetMaterijala} from '../models/KvalitetMaterija'

const API_URL = process.env.REACT_APP_API_URL
const DROPDOWN_API = `${API_URL}/kvalitetMaterijalaDropdown`
const API = `${API_URL}/kvalitetMaterijala`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

// Get request for Kvalitet Materijala Dropdown data.
export async function getKvalitetMaterijalaDropdown() {
  try {
    return await axios.get<Array<Option>>(DROPDOWN_API, {headers: headers})
  } catch (error) {
    console.error('Error fetching Kvalitet Materijala Dropdown data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
export async function getAllKvalitetMaterijala(pageNo: number, perPage: number) {
  const params = {per_page: perPage, page: pageNo}
  return await axios.get<Array<KvalitetMaterijala>>(API, {headers: headers, params: params})
}

export async function saveKvalitetMaterijala(Data: any) {
  try {
    const response = await axios.post(API, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function fetchKvalitetMaterijala(Id: number): Promise<Partial<KvalitetMaterijala>> {
  try {
    const response = await axios.get(`${API}/${Id}`)
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateKvalitetMaterijala(Data: any, id: number) {
  try {
    const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function deleteKvalitetMaterijala(Id: number) {
  try {
    const response = await axios.delete(`${API}/${Id}`, {headers: headers})
    if (response.data) {
      return response
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to delete.')
    }
  } catch (error) {
    console.error('Error deleting data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
