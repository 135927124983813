import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {ProdajaNajamDropdownModel, ProdajaNajamModel} from "../models/ProdajaNajamModel";
import {ArtikliModel, ParamsModelArtikli, RoditeljiDropdownModel} from "../models/ArtikliModel";

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/prodajaNajam`

const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

/*export async function getAllProdajaNajam(params: ProdajaNajamModel) {
    return await axios.get<Array<ProdajaNajamModel>>(API, {headers: headers, params: params})
}*/
export async function getAllProdajaNajam(params: Partial<ProdajaNajamModel>) {
    return await axios.get<Array<ProdajaNajamModel>>(API, { headers: headers, params: params });
}

export async function fetchProdajaNajam(Id: number): Promise<Partial<ProdajaNajamModel>> {
    try {
        const response = await axios.get(`${API}/${Id}`)
        return response.data.data
    } catch (error: any) {
        console.error('Error fetching data:', error)
        return error.response.data
    }
}

export async function saveProdajaNajam(Data: any) {
    try {
        const response = await axios.post(API, Data, {headers: headers})
        if (response.data.error === 0) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function updateProdajaNajam(id: number, Data: any) {
    try {
        const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
        return response.data
    } catch (error: any) {
        console.error('Error fetching data:', error)
        return error.response.data // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function deleteProdajaNajamModel(Id: number) {
    try {
        const response = await axios.delete(`${API}/${Id}`, {headers: headers})
        return response.data
    } catch (error: any) {
        console.error('Error deleting data:', error)
        return error.response.data // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function getKategorijeDropdown() {
    try {
        const response = await axios.get(`${API_URL}/kategorijeProdajaNajam`, {
            headers: headers,
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('API call returned an error:', response)
            return null
        }
    } catch (error) {
        console.error('Error in API call:', error)
        throw error
    }
}
export async function getSubKategorijeDropdown() {
    try {
        const response = await axios.get(`${API_URL}/subKategorijeProdajaNajam`, {
            headers: headers,
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('API call returned an error:', response)
            return null
        }
    } catch (error) {
        console.error('Error in API call:', error)
        throw error
    }
}
