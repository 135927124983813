import React, {useState} from 'react'
import Profilacija from './Profilacija'
import Upjenjivanje from './Upjenjivanje'
import clsx from 'clsx'
import Zaga from './Zaga'
import Pakirnica from './Pakirnica'
import KamenaVuna from './KamenaVuna'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import {ToastContainer} from 'react-toastify'
import {ZapisiProizvodnjeModel} from '../../../../models/ZapisiProizvodnjeModel'
import ProfilacijaRolne from "./ProfilacijaRolne";
import ProfilacijaTrake from "./ProfilacijaTrake";

interface Props {
  initialData: ZapisiProizvodnjeModel
}
const KomponentiKreiraj: React.FC<Props> = ({initialData}) => {
  const [selectedComponent, setselectedComponent] = useState<number>(0)

  const [loading, setloading] = useState(false)

  const components = [
    {
      title: 'PROFILACIJA',
      component: <Profilacija id={initialData.Id} brojNaloga={initialData?.BrojNaloga} />,
    },
    {
      title: 'PROFILACIJA-ROLNE',
      component: <ProfilacijaRolne id={initialData.Id} brojNaloga={initialData?.BrojNaloga} />,
    },
    {
      title: 'PROFILACIJA-TRAKE',
      component: <ProfilacijaTrake id={initialData.Id} brojNaloga={initialData?.BrojNaloga} />,
    },
    {
      title: 'UPJENJIVANJE',
      component: <Upjenjivanje id={initialData.Id} brojNaloga={initialData?.BrojNaloga} />,
    },
    {
      title: 'ŽAGA',
      component: <Zaga id={initialData.Id} brojNaloga={initialData?.BrojNaloga} />,
    },
    {
      title: 'PAKIRNICA',
      component: <Pakirnica id={initialData.Id} brojNaloga={initialData?.BrojNaloga} />,
    },
    {
      title: 'VUNA',
      component: <KamenaVuna id={initialData.Id} brojNaloga={initialData?.BrojNaloga} />,
    },
  ]

  return (
    <div className='card kreirajKomponente pb-4'>
      <ToastContainer />
      <div className='col p-5'>
        <h5 className='col-3 mb-2'>Zapis naloga</h5>
        <span className='col-1 badge bg-warning bg-opacity-1 text-white'>Dodaj</span>
        <hr />
      </div>
      <div className='card-body'>
        <div className='row g-5 mb-5'>
          {components.map((item, index) => (
            <div className='col-lg-3 col-sm-4'>
              <div
                className={clsx('card  text-white p-5', {
                  'bg-success': selectedComponent === index,
                  'bg-dark': selectedComponent !== index,
                })}
                onClick={() => setselectedComponent(index)}
              >
                <h5 className='mb-0 text-white'>{item.title}</h5>
              </div>
            </div>
          ))}
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='row'>
            <div className='col-lg-4 col-sm-4'>
              <label className='col-form-label fw-bold fs-6'> Broj naloga </label>
              <input
                type='text'
                className='form-control text-danger'
                name='brojNaloga'
                disabled
                value={initialData?.BrojNaloga}
              />
            </div>
            <div className='col-lg-3 col-sm-3'>
              <label className='col-form-label fw-bold fs-6'> Linija </label>
              <input
                type='number'
                className='form-control text-danger'
                name='linija'
                disabled
                value={initialData?.Masina}
              />
            </div>
            <div className='col-lg-3 col-sm-3'>
              <label className='col-form-label fw-bold fs-6'> Artikal </label>
              <input
                type='text'
                className='form-control text-danger'
                name='artikal'
                disabled
                value={initialData?.paneli_proizvodnja?.artikal ?? ''}
              />
            </div>
            <div className='col-lg-2 col-sm-2'>
              <label className='col-form-label fw-bold fs-6'> Smjena </label>
              <input
                type='text'
                className='form-control text-danger'
                name='smjena'
                disabled
                value={initialData?.Smjena}
              />
            </div>
          </div>
        )}
        {/* @ts-ignore */}
        {components[selectedComponent].component}
      </div>
    </div>
  )
}

export default KomponentiKreiraj
