import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_websoft/layout/core'
import ArtikliOperacijeLista from "./ArtikliOperacijeLista";
import ArtikliOperacijeDetails from "./ArtikliOperacijeDetails";
import ZapisiProizvodnjeLista from "./ZapisiProizvodnjeLista";
import ZapisiProizvodnjeDetails from "./ZapisiProizvodnjeDetails";

const GalanterijaBreadCrumbs: Array<PageLink> = [
    {
        title: 'Galanterija',
        path: '/galanterija/artikli-operacije',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const GalanterijaRoutes: React.FC = () => {
    return (
        <Routes>
            <Route
                element={
                    <>
                        <Outlet />
                    </>
                }
            >
            <Route
                path='artikli-operacije'
                element={
                    <>
                        <PageTitle breadcrumbs={GalanterijaBreadCrumbs}>Artikli operacije</PageTitle>
                        <ArtikliOperacijeLista />
                    </>
                }
            />
            <Route
                path='artikli-operacije/dodaj'
                element={
                    <>
                        <PageTitle breadcrumbs={GalanterijaBreadCrumbs}>Dodaj</PageTitle>
                        <ArtikliOperacijeDetails />
                    </>
                }
            />
            <Route
                path='artikli-operacije/izmjena/:id'
                element={
                    <>
                        <PageTitle breadcrumbs={GalanterijaBreadCrumbs}>Izmjena</PageTitle>
                        <ArtikliOperacijeDetails />
                    </>
                }
            />
                <Route
                    path='zapisi-proizvodnje'
                    element={
                        <>
                            <PageTitle breadcrumbs={GalanterijaBreadCrumbs}>Zapisi proizodnje</PageTitle>
                            <ZapisiProizvodnjeLista />
                        </>
                    }
                />
                <Route
                    path='zapisi-proizvodnje/izmjena/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={GalanterijaBreadCrumbs}>Zapisi proizodnje izmjena</PageTitle>
                            <ZapisiProizvodnjeDetails />
                        </>
                    }
                />
                <Route index element={<Navigate to='/galanterija/lista' />} />
            </Route>
    </Routes>
    )
}

export default GalanterijaRoutes
