/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {ProizvodnjaModel} from '../../models/ProizvodnjaModel'
import {fetchProizvodnja, saveProizvodnja, updateProizvodnja} from '../../api/proizvodnja'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import clsx from 'clsx'
import PanelTipDropdown from './components/PanelTipDropdown/PanelTipDropdown'
import PanelIzolacijaDropdown from './components/PanelIzolacijaDropdown/PanelIzolacijaDropdown'
import PanelOblogaDropdown from './components/PanelOblogaDropdown/PanelOblogaDropdown'
import BojaDropdown from '../../components/BojaDropdown/BojaDropdown'
import PanelDebljinaDropdown from './components/PanelDebljinaDropdown/PanelDebljinaDropdown'
import PanelSirinaDropdown from "./components/PanelSirinaDropdown/PanelSirinaDropdown";
const NalogDetails: React.FC = () => {
  const {id} = useParams()
  const idFromURL = Number(id)

  const initialValues = {brojNaloga: undefined}
  const [loading, setLoading] = useState(true)
  const [initialProizodnja, setInitialProizodnja] =
    useState<Partial<ProizvodnjaModel>>(initialValues)

  const navigate = useNavigate();
  useEffect(() => {
    if (idFromURL) {
      fetchProizvodnja(idFromURL).then((data) => {
        setInitialProizodnja(data)
        formik.resetForm({values: data}) // <-- reset formik state here
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [idFromURL])

  const validationSchema = Yup.object({
    brojNaloga: Yup.string().min(4, 'Must be at least 4 characters').required('Required'),
    kupac: Yup.string().min(4, 'Must be at least 4 characters').required('Required'),
    grad: Yup.string().required('Required'),
    adresa: Yup.string().required('Required'),
    drzava: Yup.string().required('Required'),
    kontaktTel: Yup.string().required('Required'),
    tip: Yup.string().required('Required'),
    brtvenaTraka: Yup.number().required('Required'),
    izolacija: Yup.string().required('Required'),
    prepust: Yup.number().required('Required'),
    duzinaPrepusta: Yup.number().notRequired(),
    stavka: Yup.number().notRequired(),
    masina: Yup.number().required('Required'),
    sirinaNalog: Yup.number().required('Required'),
    vanjskaOblogaG: Yup.number().required('Required'),
    bojaG: Yup.string().required('Required'),
    debljinaG: Yup.number().required('Required'),
    //rolneG: Yup.string().required('Required'),
    folijaG: Yup.number().required('Required'),
    vanjskaOblogaD: Yup.number().required('Required'),
    bojaD: Yup.string().required('Required'),
    debljinaD: Yup.number().required('Required'),
    //rolneD: Yup.string().required('Required'),
    folijaD: Yup.number().required('Required'),
    kolicina: Yup.number().required('Required'),
    //brojPanela: Yup.number().required('Required'),
    //vrijemeProizvodnje: Yup.string().required('Required'),
    isporukaFMK: Yup.string().required('Required'),
    napomena: Yup.string().notRequired(),
    statusPro: Yup.string().required('Required'),
    statusProDatum: Yup.string().required('Required'),
    status: Yup.number().required('Required'),
  })

  const formik = useFormik({
    initialValues: initialProizodnja,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response

        if (idFromURL) {
          // Update existing
          response = await updateProizvodnja(idFromURL, values)
          setTimeout(() => {
            navigate(-1);
          }, 3000)
        } else {
          // Save new
          response = await saveProizvodnja(values)
        }

        const responseData = response

        if (responseData.error === 0) {
          notifyToast(true, responseData.message)
        } else {
          notifyToast(false, responseData.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <ToastContainer />
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <TitleComponent name='Proizvodnja' />
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        {loading ? (
          <LoadingComponent />
        ) : (
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='row card-body border-top p-9'>
              {/* Broj naloga  */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-4 col-form-label  fw-bold fs-6'>Broj naloga</label>

                <div className='col-auto fv-row'>
                  <input
                    type='text'
                    disabled={true}
                    placeholder='Broj naloga '
                    {...formik.getFieldProps('brojNaloga')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.brojNaloga && formik.errors.brojNaloga},
                      {
                        'is-valid': formik.touched.brojNaloga && !formik.errors.brojNaloga,
                      }
                    )}
                  />
                  {formik.touched.brojNaloga && formik.errors.brojNaloga && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.brojNaloga}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* kupac */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Kupac</label>

                <div className='col-auto fv-row'>
                  <input
                    type='text'
                    placeholder='kupac'
                    {...formik.getFieldProps('kupac')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.kupac && formik.errors.kupac},
                      {
                        'is-valid': formik.touched.kupac && !formik.errors.kupac,
                      }
                    )}
                  />
                  {formik.touched.kupac && formik.errors.kupac && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.kupac}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Grad */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Grad</label>

                <div className='col-auto fv-row'>
                  <input
                    type='text'
                    placeholder='Grad'
                    {...formik.getFieldProps('grad')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.grad && formik.errors.grad},
                      {
                        'is-valid': formik.touched.grad && !formik.errors.grad,
                      }
                    )}
                  />
                  {formik.touched.grad && formik.errors.grad && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.grad}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Adresa - Grad */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                  Adresa - Grad
                </label>

                <div className='col-auto fv-row'>
                  <input
                    type='text'
                    placeholder='adresa'
                    {...formik.getFieldProps('adresa')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.adresa && formik.errors.adresa},
                      {
                        'is-valid': formik.touched.adresa && !formik.errors.adresa,
                      }
                    )}
                  />
                  {formik.touched.adresa && formik.errors.adresa && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.adresa}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Država */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Država</label>

                <div className='col-auto fv-row'>
                  <input
                    type='text'
                    placeholder='drzava'
                    {...formik.getFieldProps('drzava')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.drzava && formik.errors.drzava},
                      {
                        'is-valid': formik.touched.drzava && !formik.errors.drzava,
                      }
                    )}
                  />
                  {formik.touched.drzava && formik.errors.drzava && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.drzava}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Kontakt tel */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Kontakt tel</label>

                <div className='col-auto fv-row'>
                  <input
                    type='text'
                    placeholder='Kontakt tel'
                    {...formik.getFieldProps('kontaktTel')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.kontaktTel && formik.errors.kontaktTel},
                      {
                        'is-valid': formik.touched.kontaktTel && !formik.errors.kontaktTel,
                      }
                    )}
                  />
                  {formik.touched.kontaktTel && formik.errors.kontaktTel && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.kontaktTel}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Krajni kupac */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  Krajni kupac
                </label>

                <div className='col-auto fv-row'>
                  <input
                    type='text'
                    placeholder='Krajni kupac'
                    {...formik.getFieldProps('krajnjiKupac')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.krajnjiKupac && formik.errors.krajnjiKupac},
                      {
                        'is-valid': formik.touched.krajnjiKupac && !formik.errors.krajnjiKupac,
                      }
                    )}
                  />
                  {formik.touched.krajnjiKupac && formik.errors.krajnjiKupac && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.krajnjiKupac}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Tip */}
              <div className='col-lg-2 col-md-6 mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Tip panela</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <PanelTipDropdown
                    value={formik.values.tip}
                    onChange={(name, value) => formik.setFieldValue('tip', value)}
                    errors={formik.errors.tip}
                    touched={formik.touched.tip}
                    selectName='tip'
                  />
                </div>
              </div>
              {/* Tip */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Artikal</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                      type='text'
                      placeholder='Artikal'
                      {...formik.getFieldProps('artikal')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.artikal && formik.errors.artikal},
                          {
                            'is-valid': formik.touched.artikal && !formik.errors.artikal,
                          }
                      )}
                  />
                </div>
              </div>
              {/* brtvenaTraka */}
              <div className='col-lg-2 col-md-6 mb-6'>
                <label className='col-lg-8 col-form-label fw-bold fs-6'>
                  <span className='required'>Brtvena traka</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                      {...formik.getFieldProps('brtvenaTraka')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.brtvenaTraka && formik.errors.brtvenaTraka},
                          {
                            'is-valid': formik.touched.brtvenaTraka && !formik.errors.brtvenaTraka,
                          }
                      )}
                  >
                    <option value=''>Odaberi...</option>
                    <option value='0'>Ne</option>
                    <option value='1'>Da</option>
                  </select>
                  {formik.touched.brtvenaTraka && formik.errors.brtvenaTraka && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.brtvenaTraka}</div>
                      </div>
                  )}
                </div>
              </div>

              {/* PanelIzolacija */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>Panel Izolacija</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <PanelIzolacijaDropdown
                    value={formik.values.izolacija}
                    onChange={(name, value) => formik.setFieldValue('izolacija', value)}
                    errors={formik.errors.izolacija}
                    touched={formik.touched.izolacija}
                    selectName='izolacija'
                  />
                </div>
              </div>
              {/* Prepust */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-8 col-form-label fw-bold fs-6'>
                  <span className='required'>Prepust</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                      {...formik.getFieldProps('prepust')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.prepust && formik.errors.prepust},
                          {
                            'is-valid': formik.touched.prepust && !formik.errors.prepust,
                          }
                      )}
                  >
                    <option value=''>Odaberi...</option>
                    <option value='0'>Ne</option>
                    <option value='1'>Da</option>
                  </select>
                  {formik.touched.prepust && formik.errors.prepust && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.prepust}</div>
                      </div>
                  )}
                </div>
              </div>
              {/* Dužina prepusta */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Dužina prepusta</label>

                <div className='col-auto fv-row'>
                  <input
                    type='number'
                    placeholder='Dužina prepusta'
                    {...formik.getFieldProps('duzinaPrepusta')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.duzinaPrepusta && formik.errors.duzinaPrepusta},
                      {
                        'is-valid': formik.touched.duzinaPrepusta && !formik.errors.duzinaPrepusta,
                      }
                    )}
                  />
                  {formik.touched.duzinaPrepusta && formik.errors.duzinaPrepusta && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.duzinaPrepusta}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Stavka */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Stavka</label>

                <div className='col-auto fv-row'>
                  <input
                    type='number'
                    placeholder='Stavka'
                    {...formik.getFieldProps('stavka')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.stavka && formik.errors.stavka},
                      {
                        'is-valid': formik.touched.stavka && !formik.errors.stavka,
                      }
                    )}
                  />
                  {formik.touched.stavka && formik.errors.stavka && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.stavka}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Masina */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-8 col-form-label fw-bold fs-6'>
                  <span className='required'>Linija</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    {...formik.getFieldProps('masina')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.masina && formik.errors.masina},
                      {
                        'is-valid': formik.touched.masina && !formik.errors.masina,
                      }
                    )}
                  >
                    <option value=''>Odaberi...</option>
                    <option value='1'>Linija 1</option>
                    <option value='2'>Linija 2</option>
                  </select>
                  {formik.touched.masina && formik.errors.masina && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.masina}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Širina panela */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Širina panela
                </label>
                <div className='col-auto fv-row'>
                  <PanelSirinaDropdown
                      value={formik.values.sirinaNalog}
                      onChange={(name, value) => formik.setFieldValue('sirinaNalog', value)}
                      errors={formik.errors.sirinaNalog}
                      touched={formik.touched.sirinaNalog}
                      selectName='sirina'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    GORNJI LIM (LICE)
                  </label>
                  {/* Vanjska obloga */}
                  <div className='col-lg-6 col-md-6 mb-6'>
                    <label className='col-auto col-form-label fw-bold fs-6'>
                      <span className='required'>Vanjska obloga</span>
                    </label>
                    <div className='col-lg-9 fv-row'>
                      <PanelOblogaDropdown
                        value={formik.values.vanjskaOblogaG}
                        onChange={(name, value) => formik.setFieldValue('vanjskaOblogaG', value)}
                        errors={formik.errors.vanjskaOblogaG}
                        touched={formik.touched.vanjskaOblogaG}
                        selectName='vanjskaOblogaG'
                      />
                    </div>
                  </div>
                  {/* Boja */}
                  <div className='col-lg-4 col-md-6 mb-6'>
                    <label className='col-auto col-form-label fw-bold fs-6'>
                      <span className='required'>Boja</span>
                    </label>
                    <div className='col-lg-9 fv-row'>
                      <BojaDropdown
                        value={formik.values.bojaG}
                        onChange={(name, value) => formik.setFieldValue('bojaG', value)}
                        errors={formik.errors.bojaG}
                        touched={formik.touched.bojaG}
                        selectName='bojaG'
                      />
                    </div>
                  </div>
                  {/* Debljina */}
                  <div className='col-lg-4 col-md-6 mb-6'>
                    <label className='col-auto col-form-label fw-bold fs-6'>
                      <span className='required'>Debljina</span>
                    </label>
                    <div className='col-lg-9 fv-row'>
                      <PanelDebljinaDropdown
                        value={formik.values.debljinaG}
                        onChange={(name, value) => formik.setFieldValue('debljinaG', value)}
                        errors={formik.errors.debljinaG}
                        touched={formik.touched.debljinaG}
                        selectName='debljinaG'
                      />
                    </div>
                  </div>
                  {/* Broj rolne */}
                  <div className='col-lg-9 col-md-6 mb-6'>
                    <label className='col-auto col-form-label fw-bold fs-6'>
                      <span className=''>Broj rolne</span>
                    </label>
                    <div className='col-lg-9 fv-row'>
                      <input
                        type='text'
                        placeholder='Broj rolne'
                        {...formik.getFieldProps('rolneG')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.rolneG && formik.errors.rolneG},
                          {
                            'is-valid': formik.touched.rolneG && !formik.errors.rolneG,
                          }
                        )}
                      />
                      {formik.touched.rolneG && formik.errors.rolneG && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.rolneG}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Folija */}
                  <div className='col-lg-9 col-md-6 mb-6'>
                    <label className='col-lg-8 col-form-label fw-bold fs-6'>
                      <span className='required'>Folija</span>
                    </label>
                    <div className='col-lg-9 fv-row'>
                      <select
                        {...formik.getFieldProps('folijaG')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.folijaG && formik.errors.folijaG},
                          {
                            'is-valid': formik.touched.folijaG && !formik.errors.folijaG,
                          }
                        )}
                      >
                        <option value=''>Odaberi...</option>
                        <option value='0'>Ne</option>
                        <option value='1'>Da</option>
                      </select>
                      {formik.touched.folijaG && formik.errors.folijaG && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.folijaG}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    DONJI LIM (NALICJE)
                  </label>
                  {/* Vanjska obloga */}
                  <div className='col-lg-9 col-md-6 mb-6'>
                    <label className='col-auto col-form-label fw-bold fs-6'>
                      <span className='required'>Vanjska obloga</span>
                    </label>
                    <div className='col-lg-9 fv-row'>
                      <PanelOblogaDropdown
                        value={formik.values.vanjskaOblogaD}
                        onChange={(name, value) => formik.setFieldValue('vanjskaOblogaD', value)}
                        errors={formik.errors.vanjskaOblogaD}
                        touched={formik.touched.vanjskaOblogaD}
                        selectName='vanjskaOblogaD'
                      />
                    </div>
                  </div>
                  {/* Boja */}
                  <div className='col-lg-9 col-md-6 mb-6'>
                    <label className='col-auto col-form-label fw-bold fs-6'>
                      <span className='required'>Boja</span>
                    </label>
                    <div className='col-lg-9 fv-row'>
                      <BojaDropdown
                        value={formik.values.bojaD}
                        onChange={(name, value) => formik.setFieldValue('bojaD', value)}
                        errors={formik.errors.bojaD}
                        touched={formik.touched.bojaD}
                        selectName='bojaD'
                      />
                    </div>
                  </div>
                  {/* Debljina */}
                  <div className='col-lg-9 col-md-6 mb-6'>
                    <label className='col-auto col-form-label fw-bold fs-6'>
                      <span className='required'>Debljina</span>
                    </label>
                    <div className='col-lg-9 fv-row'>
                      <PanelDebljinaDropdown
                        value={formik.values.debljinaD}
                        onChange={(name, value) => formik.setFieldValue('debljinaD', value)}
                        errors={formik.errors.debljinaD}
                        touched={formik.touched.debljinaD}
                        selectName='debljinaD'
                      />
                    </div>
                  </div>
                  {/* Broj rolne */}
                  <div className='col-lg-9 col-md-6 mb-6'>
                    <label className='col-lg-9 col-form-label fw-bold fs-6'>
                      <span className=''>Broj rolne</span>
                    </label>
                    <div className='col-auto fv-row'>
                      <input
                        type='text'
                        placeholder='Broj rolne'
                        {...formik.getFieldProps('rolneD')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.rolneD && formik.errors.rolneD},
                          {
                            'is-valid': formik.touched.rolneD && !formik.errors.rolneD,
                          }
                        )}
                      />
                      {formik.touched.rolneD && formik.errors.rolneD && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.rolneD}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Folija */}
                  <div className='col-lg-9 col-md-6 mb-6'>
                    <label className='col-lg-8 col-form-label fw-bold fs-6'>
                      <span className='required'>Folija</span>
                    </label>
                    <div className='col-lg-9 fv-row'>
                      <select
                        {...formik.getFieldProps('folijaD')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.folijaD && formik.errors.folijaD},
                          {
                            'is-valid': formik.touched.folijaD && !formik.errors.folijaD,
                          }
                        )}
                      >
                        <option value=''>Odaberi...</option>
                        <option value='0'>Ne</option>
                        <option value='1'>Da</option>
                      </select>
                      {formik.touched.folijaD && formik.errors.folijaD && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.folijaD}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Količina / m2 */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-auto col-form-label fw-bold fs-6'>
                  <span className='required'>Količina / m2</span>
                </label>
                <div className='col-auto fv-row'>
                  <input
                    type='number'
                    placeholder='kolicina'
                    {...formik.getFieldProps('kolicina')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.kolicina && formik.errors.kolicina},
                      {
                        'is-valid': formik.touched.kolicina && !formik.errors.kolicina,
                      }
                    )}
                  />
                  {formik.touched.kolicina && formik.errors.kolicina && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.kolicina}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Broj panela (kom) */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-auto col-form-label fw-bold fs-6'>
                  <span className=''>Broj panela (kom)</span>
                </label>
                <div className='col-auto fv-row'>
                  <input
                    type='number'
                    placeholder='brojPanela'
                    {...formik.getFieldProps('brojPanela')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.brojPanela && formik.errors.brojPanela},
                      {
                        'is-valid': formik.touched.brojPanela && !formik.errors.brojPanela,
                      }
                    )}
                  />
                  {formik.touched.brojPanela && formik.errors.brojPanela && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.brojPanela}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* vrijemeProizvodnje*/}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-auto col-form-label fw-bold fs-6'>
                  <span className=''>
                    Vrijeme potrebno za proizvodnju naloga | broj sati
                  </span>
                </label>
                <div className='col-auto fv-row'>
                  <input
                    type='text'
                    placeholder='vrijemeProizvodnje'
                    {...formik.getFieldProps('vrijemeProizvodnje')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid':
                          formik.touched.vrijemeProizvodnje && formik.errors.vrijemeProizvodnje,
                      },
                      {
                        'is-valid':
                          formik.touched.vrijemeProizvodnje && !formik.errors.vrijemeProizvodnje,
                      }
                    )}
                  />
                  {formik.touched.vrijemeProizvodnje && formik.errors.vrijemeProizvodnje && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.vrijemeProizvodnje}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* isporukaFMK*/}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-auto col-form-label fw-bold fs-6'>
                  <span className='required'>Isporuka datum isporuka prema FMK nalogu</span>
                </label>
                <div className='col-auto fv-row'>
                  <input
                    type='date'
                    placeholder='isporukaFMK'
                    {...formik.getFieldProps('isporukaFMK')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.isporukaFMK && formik.errors.isporukaFMK,
                      },
                      {
                        'is-valid': formik.touched.isporukaFMK && !formik.errors.isporukaFMK,
                      }
                    )}
                  />
                  {formik.touched.isporukaFMK && formik.errors.isporukaFMK && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.isporukaFMK}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Napomena*/}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-auto col-form-label fw-bold fs-6'>
                  <span>Napomena</span>
                </label>
                <div className='col-auto fv-row'>
                  <input
                    type='text'
                    placeholder='napomena'
                    {...formik.getFieldProps('napomena')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.napomena && formik.errors.napomena,
                      },
                      {
                        'is-valid': formik.touched.napomena && !formik.errors.napomena,
                      }
                    )}
                  />
                  {formik.touched.napomena && formik.errors.napomena && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.napomena}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Status proizvodnje */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-8 col-form-label fw-bold fs-6'>
                  <span className='required'>Status proizvodnje</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    {...formik.getFieldProps('statusPro')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.statusPro && formik.errors.statusPro},
                      {
                        'is-valid': formik.touched.statusPro && !formik.errors.statusPro,
                      }
                    )}
                  >
                    <option value=''>Odaberi...</option>
                    <option value='Planirano'>Proizvodnja planirana</option>
                    <option value='Zavrseno'>Proizvodnja završena</option>
                  </select>
                  {formik.touched.statusPro && formik.errors.statusPro && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.statusPro}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Datum statusa*/}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-auto col-form-label fw-bold fs-6'>
                  <span className='required'>Datum statusa</span>
                </label>
                <div className='col-auto fv-row'>
                  <input
                    type='date'
                    placeholder='statusProDatum'
                    {...formik.getFieldProps('statusProDatum')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.statusProDatum && formik.errors.statusProDatum,
                      },
                      {
                        'is-valid': formik.touched.statusProDatum && !formik.errors.statusProDatum,
                      }
                    )}
                  />
                  {formik.touched.statusProDatum && formik.errors.statusProDatum && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.statusProDatum}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Status */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-8 col-form-label fw-bold fs-6'>
                  <span className='required'>Status</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    {...formik.getFieldProps('status')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.status && formik.errors.status},
                      {
                        'is-valid': formik.touched.status && !formik.errors.status,
                      }
                    )}
                  >
                    <option value=''>Odaberi...</option>
                    <option value='0'>Aktivan</option>
                    <option value='1'>Arhiviran</option>
                  </select>
                  {formik.touched.status && formik.errors.status && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.status}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={loading || formik.isSubmitting || !formik.isValid}
              >
                {!loading && 'Potvrdi'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}
export default NalogDetails
