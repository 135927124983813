/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  getFilteredRowModel,
} from '@tanstack/react-table'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import {PaneliPaketi} from '../../models/PaneliPaketi'
import {TableActionButton} from '../../components/TableActionBtn/TableActionButton'
import {
  deletePaket,
  getAllPaketiPanela,
  isporuciPaketeApi,
  pakingListeApi,
  updateLokacijePaketa,
  vratiPaketeApi,
} from '../../api/paketiPanela'
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox/IndeterminateCheckbox'
import {usePDF} from '@react-pdf/renderer'
import PakingListePdf from '../../components/QrCodeGenerator/PakingListePdf'
import QRCode from 'qrcode'
import PaneliPaketiModal from './components/PaneliPaketiModal/PaneliPaketiModal'
import LokacijePaketaDropdown from '../../components/LokacijePaketaDropdown/LokacijePaketaDropdown'

const PregledPaketa: React.FC = () => {
  const [data, setData] = useState<PaneliPaketi[]>([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)

  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [rowSelection, setRowSelection] = useState({})
  const [qrList, setqrList] = useState<string[]>()
  const [isModalOpen, setisModalOpen] = useState(false)
  const [lokacijeData, setlokacijeData] = useState()
  const handleBtnClick = () => {
    setLoading(true) // Assuming you have a setLoading method for loading state

    const parts = inputValue.split('-')
    if (parts.length < 1 || parts.length > 2) {
      notifyToast(false, 'Niste dobro upisali format')
      setLoading(false) // Reset loading state
      return
    }

    // Using as strings
    const brojNaloga = parts[0]
    const stavka = parts.length === 2 ? parts[1] : null // Set to null if not provided

    getAllPaketiPanela(brojNaloga, stavka)
      .then((response) => {
        setData(response.data['data'])
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error.response?.data?.message || 'An error occurred')
      })
      .finally(() => {
        setLoading(false)
        setRowSelection({})
      })
  }

  const handleisporuciPaketeBtnClick = () => {
    setLoading(true)
    const idList = table.getSelectedRowModel().rows.map((item) => item.original.id)
    const params = {
      stavkaId: idList,
    }
    isporuciPaketeApi(params)
      .then((response) => {
        notifyToast(true, response.message)
        handleBtnClick()
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error.response?.data?.message || 'An error occurred')
      })
      .finally(() => setLoading(false))
  }
  const handlevratiPaketeBtnClick = () => {
    setLoading(true)
    const idList = table.getSelectedRowModel().rows.map((item) => item.original.id)
    const params = {
      stavkaId: idList,
    }
    vratiPaketeApi(params)
      .then((response) => {
        notifyToast(true, response.message)
        handleBtnClick()
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error.response?.data?.message || 'An error occurred')
      })
      .finally(() => setLoading(false))
  }

  const handlePakingListeBtnClick = () => {
    setLoading(true)
    const params = {
      idProizvodnje: data[0].idProizvodnje,
    }
    pakingListeApi(params)
      .then(async (response) => {
        convertQrSvgToImg(response['data'])
        notifyToast(true, 'Success')
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error.response?.data?.message || 'An error occurred')
      })
      .finally(() => setLoading(false))
  }

  const handleLokacijeBtnClick = () => {
    setLoading(true)
    const idList = table.getSelectedRowModel().rows.map((item) => item.original.id)
    const params = {
      stavkaId: idList,
      lokacija: lokacijeData,
    }
    updateLokacijePaketa(params)
      .then((response) => {
        notifyToast(true, response.message)
        handleBtnClick()
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error.response?.data?.message || 'An error occurred')
      })
      .finally(() => setLoading(false))
  }

  const convertQrSvgToImg = async (pakingListe) => {
    if (pakingListe) {
      var list: Array<string> = []
      await pakingListe.map((item) =>
        QRCode.toDataURL(item.qrCode).then((url) => {
          list.push(url)
        })
      )
      setqrList(list)
      updateInstance(<PakingListePdf qrData={pakingListe} qrList={list} />)
    }
  }

  const [instance, updateInstance] = usePDF({})

  // Open Pdf when intance link is generated.
  useEffect(() => {
    if (!instance.loading && instance.url && qrList?.length) {
      window.open(instance.url, '_blank')?.focus()
    }
  }, [instance])

  useEffect(() => {
    const {id} = sorting[0] ?? ''
    data.sort((a, b) => a[id] - b[id])
  }, [sorting])

  const onDelete = (id) => {
    deletePaket(id)
      .then((response) => {
        notifyToast(true, response.message)
        handleBtnClick()
      })
      .catch((error) => {
        notifyToast(false, error.data['message'])
      })
  }

  const columns = [
    {
      id: 'id',
      header: ({table}) => (
        <div className='row p-2'>
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      cell: ({row, getValue}) => (
        <div className='col'>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: 'Nalog',
      accessorKey: 'proizvodnja.brojNaloga',
    },
    {
      header: 'Stavka',
      accessorKey: 'proizvodnja.stavka',
    },
    {
      header: 'Kupac',
      accessorKey: 'proizvodnja.kupac',
    },
    {
      header: 'Artikal',
      accessorKey: 'proizvodnja.artikal',
    },
    {
      header: 'Dužina',
      accessorKey: 'duzina',
    },
    {
      header: 'Širina',
      accessorKey: 'sirinaPaketa',
    },
    {
      header: 'Kom.',
      accessorKey: 'brPanela',
    },
    {
      header: 'Naziv paketa',
      accessorKey: 'nazivPanela',
    },
    {
      header: 'Kontrola',
      accessorKey: 'kontrolisao_uposlenik.UposlenikImePrezime',
    },
    {
      header: 'Otp',
      accessorKey: 'paketOtpremljen',
      cell: ({row}) => (
        <KTIcon
          iconName={row.original.paketOtpremljen === 0 ? 'cross' : 'check'}
          className={row.original.paketOtpremljen === 0 ? 'text-danger fs-1' : 'text-success fs-1'}
        />
      ),
    },
    {
      header: 'Isp',
      accessorKey: 'isporucen',
      cell: ({row}) => (
        <KTIcon
          iconName='truck'
          className={row.original.isporucen === 0 ? 'text-danger fs-1' : 'text-success fs-1'}
        />
      ),
    },
    {
      header: 'Lokacija',
      accessorKey: 'lokacijaPaketa',
    },
    {
      header: 'Akcija',
      accessorKey: 'Action',
      cell: ({row}) => (
        <TableActionButton
          onEdit={() => {
            setisModalOpen(true)
          }}
          onDelete={() => {
            onDelete(row.original.id)
          }}
        />
      ),
    },
  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
      rowSelection: rowSelection,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
  })

  return (
    <KTCard>
      <ToastContainer />
      {data && (
        <PaneliPaketiModal
          isModalOpen={isModalOpen}
          setisModalOpen={setisModalOpen}
          paketiData={data}
          update={handleBtnClick}
        />
      )}
      <KTCardBody className='py-4'>
        <h2 className='fw-bold'>Upišite broj naloga/godina-stavka</h2>
        <div className='row'>
          <div className='col-lg-2 col-md-3 col-8 mb-6'>
            <input
              name='text'
              className='form-control mt-4'
              placeholder='Broj naloga/godina - stavka'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
          <div className='col-lg-1 col-md-1 col-4 align-self-end mb-6'>
            <button
              type='button'
              className='btn btn-primary p-3'
              onClick={handleBtnClick}
              disabled={loading}
            >
              <i className='bi bi-search fs-2x'></i>
            </button>
          </div>
        </div>
        <div className='separator'></div>
        <div className='row mt-5'>
          <div className='col-lg-2 col-md-4 col-6 align-self-end mb-6'>
            <button
              type='button'
              className='btn btn-danger p-3'
              onClick={handleisporuciPaketeBtnClick}
              disabled={!table.getSelectedRowModel().rows.length || loading}
            >
              Isporuči pakete
            </button>
          </div>
          <div className='col-lg-2 col-md-4 col-6 align-self-end mb-6'>
            <button
              type='button'
              className='btn btn-warning p-3'
              onClick={handlevratiPaketeBtnClick}
              disabled={!table.getSelectedRowModel().rows.length || loading}
            >
              Vrati pakete
            </button>
          </div>
          <div className='col-lg-3 col-md-4 col-6 align-self-end mb-6'>
            <button
              type='button'
              className='btn btn-info p-3'
              onClick={handlePakingListeBtnClick}
              disabled={loading}
            >
              Print paking liste
            </button>
          </div>

          {/* Lokacije */}
          <div className='col-lg-3 col-md-4 col-6 align-self-end mb-6'>
            <LokacijePaketaDropdown
              value={lokacijeData}
              onChange={(name, value) => setlokacijeData(value)}
              selectName='Lokacije '
            />
          </div>

          <div className='col-lg-1 col-md-4 col-6 align-self-end mb-6'>
            <button
              type='button'
              className='btn btn-success p-3'
              onClick={handleLokacijeBtnClick}
              disabled={!table.getSelectedRowModel().rows.length || !lokacijeData || loading}
            >
              Potvrdi
            </button>
          </div>
        </div>
        <div className='separator'></div>
        <div className='d-flex justify-content-between mt-5'>
          <div data-kt-user-table-toolbar='base'>
            <TableSearch onSearch={(text) => setGlobalFilter(text)} />
          </div>
          <div data-kt-user-table-toolbar='base'>
            <ExportButton table={table} filename={'lezarina_paketa'} />
          </div>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3 '>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className='text-white'
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* @ts-ignore */}
                        {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </KTCardBody>
    </KTCard>
  )
}
export default PregledPaketa
