import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
const UPOSLENIK = `${API_URL}/uposlenici`

const auth: UposlenikModel = getAuth()!

const headers = {Accept: 'application/json', Authorization: `Bearer ${auth?.access_token ?? ''}`}

export async function uposleniciIzvjestajRadUNormi(params) {
  return await axios.get(`${UPOSLENIK}/uposleniciIzvjestajRadUNormi`, {
    headers: headers,
    params: params,
  })
}
