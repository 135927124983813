import {KTCard, KTCardBody} from '../../../../_websoft/helpers'
import {ToastContainer} from 'react-toastify'
import React from 'react'

const Termopaneli: React.FC = () => {
    return (
        <KTCard>
            <ToastContainer/>
            <KTCardBody className='py-4'>
                <div className='row'>
                    <div className='col-xl-4 mt-2'>
                        <a href='termopaneli/lista' className='card bg-primary hoverable card-xl-stretch mb-xl-8'>
                            <div className='card-body'>
                                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                    <span className='path4'></span>
                                </i>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Proizvodnja</div>
                            </div>
                        </a>
                    </div>
                    <div className='col-xl-4 mt-2'>
                        <a
                            href='termopaneli/glavna-knjiga-dodaj'
                            className='card bg-primary hoverable card-xl-stretch mb-xl-8'
                        >
                            <div className='card-body'>
                                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                    <span className='path4'></span>
                                </i>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Glavna knjiga proizvodnje</div>
                            </div>
                        </a>
                    </div>
                    <div className='col-xl-4 mt-2'>
                        <a
                            href='termopaneli/dnevni-izvjestaj'
                            className='card bg-primary hoverable card-xl-stretch mb-xl-8'
                        >
                            <div className='card-body'>
                                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                    <span className='path4'></span>
                                </i>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Dnevni izvještaj</div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-4 mt-2'>
                        <a
                            href='termopaneli/nalog-start'
                            className='card bg-black hoverable card-xl-stretch mb-xl-8'
                        >
                            <div className='card-body'>
                                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                    <span className='path4'></span>
                                </i>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Nalog | Start</div>
                            </div>
                        </a>
                    </div>
                    <div className='col-xl-4 mt-2'>
                        <a
                            href='termopaneli/aktivni-nalozi'
                            className='card bg-black hoverable card-xl-stretch mb-xl-8'
                        >
                            <div className='card-body'>
                                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                    <span className='path4'></span>
                                </i>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Aktivni nalozi</div>
                            </div>
                        </a>
                    </div>
                    <div className='col-xl-4 mt-2'>
                        <a
                            href='termopaneli/promet-rolne'
                            className='card bg-black hoverable card-xl-stretch mb-xl-8'
                        >
                            <div className='card-body'>
                                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                    <span className='path4'></span>
                                </i>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Promet rolne</div>
                            </div>
                        </a>
                    </div>
                    <div className='col-xl-4 mt-2'>
                        <a
                            href='termopaneli/isporuci-paket'
                            className='card bg-info hoverable card-xl-stretch mb-xl-8'
                        >
                            <div className='card-body'>
                                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                    <span className='path4'></span>
                                </i>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Isporuči paket</div>
                            </div>
                        </a>
                    </div>
                    <div className='col-xl-4 mt-2'>
                        <a
                            href='termopaneli/thermoboard-paketi'
                            className='card bg-warning hoverable card-xl-stretch mb-xl-8'
                        >
                            <div className='card-body'>
                                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                    <span className='path4'></span>
                                </i>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>
                                    Isporuči / Proizvedi termoboard paket
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className='col-xl-4 mt-2'>
                        <a
                            href='termopaneli/isporuci-paket-druga-klasa'
                            className='card bg-success hoverable card-xl-stretch mb-xl-8'
                        >
                            <div className='card-body'>
                                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                    <span className='path4'></span>
                                </i>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>
                                    Isporuči Paket 2 klase
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </KTCardBody>
        </KTCard>
    )
}
export default Termopaneli
