import axios from 'axios'
import {
  DimenzijePaketaModel,
  PaketiDrugaKlasaQrModel,
  ParamsModelSkartPaketi,
  SkartPaketiModel,
} from '../models/SkartPaketiModel'
import {getAuth} from '../modules/auth'
import {UposlenikModel} from '../models/UposlenikModel'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/paneli/skartPaketi`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: `Bearer ${auth?.access_token ?? ''}`}

export async function getSkartPaketi(params: ParamsModelSkartPaketi) {
  return await axios.get<Array<SkartPaketiModel>>(API, {headers: headers, params: params})
}

export async function saveSkartPaketi(Data: any) {
  try {
    const response = await axios.post(API, Data, {headers: headers})

    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function fetchSkartPaketi(Id: number): Promise<Partial<SkartPaketiModel>> {
  try {
    const response = await axios.get(`${API}/${Id}`)
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function isporuciSkartPaket(Data: any) {
  try {
    const response = await axios.post(`${API_URL}/paneli/skartPaketiIzlaz`, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      return response.data || 'Greska, provjerite status paketa'
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    return error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateSkartPaketi(id: number, Data: any) {
  try {
    const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function deleteSkartPaketi(Id: number) {
  try {
    const response = await axios.delete(`${API}/${Id}`, {headers: headers})
    if (response.data) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to delete.')
    }
  } catch (error) {
    console.error('Error deleting data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function getDimenzijePaketa(params: any) {
  return await axios.get<Array<DimenzijePaketaModel>>(`${API_URL}/paneli/getDimenzijePaketa`, {
    headers: headers,
    params: params,
  })
}
export async function getPaketiDrugaKlasaQr(params: any) {
  return await axios.get<PaketiDrugaKlasaQrModel>(`${API_URL}/paneli/paketiDrugaKlasaQr`, {
    headers: headers,
    params: params,
  })
}
