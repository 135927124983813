import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_websoft/layout/core'
import FMKNaloziLista from './FMKNaloziLista'
import StavkeUProizvodnji from './StavkeUProizvodnji'

const ArtikliBreadCrumbs: Array<PageLink> = [
  {
    title: 'Fmk Nalozi',
    path: '/fmk-nalozi/dodaj',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const FmkNaloziRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='lista'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>FMK Nalozi Lista</PageTitle>
              <FMKNaloziLista />
            </>
          }
        />
        <Route
          path='stavke-u-proizvodnji'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Stavke u proizvodnji</PageTitle>
              <StavkeUProizvodnji />
            </>
          }
        />

        <Route index element={<Navigate to='/fmk-nalozi/Lista' />} />
      </Route>
    </Routes>
  )
}

export default FmkNaloziRoutes
