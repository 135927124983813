import React, {useState, useEffect} from 'react'

const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setdebouncedValue] = useState(value)

  useEffect(() => {
    const id = setTimeout(() => {
      setdebouncedValue(value)
    }, [delay])
    return () => {
      clearTimeout(id)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
