/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useMemo, useState} from 'react'
import {RolnaDetaljiProizvodnja} from '../../../../models/FmkNaloziModel'
import * as Yup from 'yup'
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik'
import {krajRadnogNaloga, mojeStavkeNaNalogu} from '../../../../api/radniNalozi'
import notifyToast from '../../../../components/Toast/Toast'
import {rolnaDetaljiProizvodnja} from '../../../../api/fmkNalozi'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import QrCodeScanner from '../../../../components/QrCodeScanner/QrCodeScanner'

import LokacijeRepromaterijalaDropdown from '../../../../components/LokacijeRepromaterijalaDropdown/LokacijeRepromaterijalaDropdown'
import {useNavigate} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'

const KrajRolne = ({nalogaId}) => {
  const nalog = useMemo(() => nalogaId, [nalogaId])
  // const stavka=useMemo(() => dataStavka, [dataStavka])
  const formRef = useRef()
  const initialValues = {
    rolne: [
      {
        BrojRolne: undefined,
        KrajnjaKolicina: undefined,
        Lokacija: undefined,
      },
    ],
  }
  const [loading, setLoading] = useState(false)
  const [rolnaDetaljiInfo, setrolnaDetalji] = useState<RolnaDetaljiProizvodnja[]>([])
  const validationSchema = Yup.object({
    rolne: Yup.array().of(
      Yup.object().shape({
        BrojRolne: Yup.string().required(),
        KrajnjaKolicina: Yup.number() // @ts-ignore
          // .max(parseFloat(rolnaDetaljiInfo[this.path.split('.')[1]].ReprMatTrenutnaKolicina??'0'))
          .required('required'),
        Lokacija: Yup.string(),
      })
    ),
  })
  // const [initialData, setInitialData] = useState<rolnaModel[]>(initialValues)
  const navigate = useNavigate()
  const onSubmit = (values) => {
    setLoading(true)
    const payloadData = {
      IdNaloga: nalog,
      rolne: values.rolne,
    }

    krajRadnogNaloga(payloadData)
      .then((response) => {
        notifyToast(true, response.message)
        setTimeout(() => {
          navigate(-1)
        }, 3000)
      })
      .catch((error) => {
        console.log(error)
        notifyToast(false, error.message)
      })
      .finally(() => setLoading(false))
  }

  const inputRef = useRef<HTMLInputElement>(null)

  // Focus the input element once the component is mounted
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const [dataStavka, setDataStavka] = useState<any[]>()
  useEffect(() => {
    const params = {idNaloga: nalogaId}
    mojeStavkeNaNalogu(params)
      .then((response) => {
        console.log({response})
        setDataStavka(response.data)
      })
      .catch((error) => notifyToast(false, error.message))
      .finally(() => setLoading(false))
  }, [])

  const [kolicinaRepromaterijala, setkolicinaRepromaterijala] = useState<number>()
  const [KrajnjaKolicina, setKrajnjaKolicina] = useState<number[]>([])
  const calculateKolicinaRepromaterijala = () => {
    if (dataStavka) {
      console.log({dataStavka})
      var kolcina = dataStavka.reduce((kolcina, item) => (kolcina += item.Kolicina), 0)
      var total = 0
      if (rolnaDetaljiInfo[0].ReprMatDebljina) {
        const calc =
          (parseFloat(rolnaDetaljiInfo[0].ReprMatDebljina) *
            parseFloat(rolnaDetaljiInfo[0].ReprMatSirina)) /
          1000
        total = calc * 7.8 * kolcina
        var kranjnja = KrajnjaKolicina.reduce((total, item) => (total += item), 0) ?? 0
        console.log({kranjnja})
        setkolicinaRepromaterijala(total - kranjnja)
      }
    }
  }
  useEffect(() => {
    console.log({KrajnjaKolicina})
    calculateKolicinaRepromaterijala()
  }, [KrajnjaKolicina])

  const GetRolnaDetaljiProizvodnja = (data, index) => {
    rolnaDetaljiProizvodnja({rolnaId: data})
      .then((response) => {
        console.log({response})
        if (response.data) {
          rolnaDetaljiInfo[index] = response.data
          setrolnaDetalji(rolnaDetaljiInfo)
          calculateKolicinaRepromaterijala()
        } else {
          setrolnaDetalji(response)
        }
      })
      .catch((error) => notifyToast(false, error.response.data.message))
  }

  return (
    <div className='w-100 bg-white'>
      <ToastContainer />
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Rolna Info</h2>
      </div>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Form>
              <FieldArray name='rolne'>
                {(fieldArrayProps) => {
                  const {form, push, remove, insert, replace} = fieldArrayProps
                  const {values} = form
                  const {rolne} = values

                  return (
                    <div>
                      {rolne.length > 0 ? (
                        rolne.map((item, index) => (
                          <div key={index} className='row p-5'>
                            {/* Begin: BrojRolne */}
                            <div className='col-sm-4 col-md-2 col-lg-1'>
                              <label className='form-label required'>Skeniraj</label>
                              <QrCodeScanner
                                onScan={(data) => {
                                  replace(index, {...rolne, BrojRolne: data})
                                  GetRolnaDetaljiProizvodnja(data, index)
                                }}
                              />
                            </div>
                            <div className='col-sm-4 col-md-2 col-lg-2'>
                              <label className='form-label required'>BrojRolne</label>
                              <Field
                                name={`rolne[${index}].BrojRolne`}
                                type='text'
                                className='form-control form-control-lg form-control-solid col-3 m-1'
                                placeholder='BrojRolne'
                              >
                                {({
                                  field, // { name, value, onChange, onBlur }
                                  form: {touched, errors, setFieldValue},
                                  meta,
                                }) => (
                                  <input
                                    className='form-control form-control-lg form-control-solid col-3 m-1'
                                    {...field}
                                    type='text'
                                    onBlur={(e) => {
                                      GetRolnaDetaljiProizvodnja(field.value, index)
                                    }}
                                  />
                                )}
                              </Field>
                              <ErrorMessage name={`rolne[${index}].BrojRolne`}>
                                {(msg) => <div className='text-danger'>{msg}</div>}
                              </ErrorMessage>
                            </div>
                            {/* End: BrojRolne */}

                            {/* Begin: KrajnjaKolicina */}
                            <div className='col-sm-4 col-md-2 col-lg-2'>
                              <label className='form-label required'>KrajnjaKolicina</label>
                              <Field
                                name={`rolne[${index}].KrajnjaKolicina`}
                                type='number'
                                placeholder='KrajnjaKolicina'
                                className='form-control form-control-lg form-control-solid col-3 m-1'
                                innerRef={formRef}
                              >
                                {({
                                  field, // { name, value, onChange, onBlur }
                                  form: {touched, errors, setFieldValue},
                                  meta,
                                }) => (
                                  <input
                                    className='form-control form-control-lg form-control-solid col-3 m-1'
                                    {...field}
                                    type='number'
                                    onChange={(e) => {
                                        // Parse the new value to a number
                                        const newValue = parseInt(e.target.value, 10);
                                        // Get the maximum value allowed and ensure it's treated as a number
                                        const maxValue = parseInt(rolnaDetaljiInfo[index].ReprMatTrenutnaKolicina, 10);

                                        if (newValue > maxValue) {
                                            // If new value exceeds the max, set the field value to max allowed value
                                            setFieldValue(field.name, maxValue);
                                        } else {
                                            // Otherwise, just set it to the new value
                                            setFieldValue(field.name, newValue);
                                        }
                                    }}
                                  />
                                )}
                              </Field>
                              <ErrorMessage name={`rolne[${index}].KrajnjaKolicina`}>
                                {(msg) => <div className='text-danger'>{msg}</div>}
                              </ErrorMessage>
                            </div>
                            {/* End: KrajnjaKolicina */}

                            {/* Begin: Lokacija */}
                            <div className='col-sm-4 col-md-2 col-lg-2'>
                              <label className='form-label required'>Lokacija</label>
                              <Field
                                name={`rolne[${index}].Lokacija`}
                                type='text'
                                placeholder='Lokacija'
                                // className='form-control form-control-lg form-control-solid col-3 m-1'
                              >
                                {({
                                  field, // { name, value, onChange, onBlur }
                                  form: {touched, errors, setFieldValue},
                                  meta,
                                }) => (
                                  <LokacijeRepromaterijalaDropdown
                                    value={field.value}
                                    onChange={(name, value) =>
                                      setFieldValue(`rolne[${index}].Lokacija`, value)
                                    }
                                    errors={errors}
                                    // touched={touched}
                                    selectName='Lokacija'
                                  />
                                )}
                              </Field>
                              <ErrorMessage name={`rolne[${index}].Lokacija`}>
                                {(msg) => <div className='text-danger'>{msg}</div>}
                              </ErrorMessage>
                            </div>
                            {/* End: Lokacija */}

                            <div className='col-1 mt-5'>
                              <button
                                className='btn btn-danger btn-lg m-1'
                                onClick={() => {
                                  remove(index)
                                  if (rolnaDetaljiInfo[index]) {
                                    rolnaDetaljiInfo.splice(index, 1)
                                    setrolnaDetalji(rolnaDetaljiInfo)
                                    calculateKolicinaRepromaterijala()
                                  }
                                }}
                                type='button'
                              >
                                -
                              </button>
                            </div>
                            <div className='col-1 mt-5'>
                              <button
                                className='btn btn-success btn-lg m-1'
                                onClick={() => {
                                  insert(index + 1, {
                                    BrojRolne: '',
                                    KrajnjaKolicina: '',
                                    Lokacija: '',
                                  })
                                  // @ts-ignore
                                  rolnaDetaljiInfo.splice(index, 1, 1, undefined)
                                  setrolnaDetalji(rolnaDetaljiInfo)
                                }}
                                type='button'
                              >
                                +
                              </button>
                            </div>
                            <div className='row mb-10'>
                              <div className='col'>
                                {rolnaDetaljiInfo[index] ? (
                                  <div className='col-9 border rounded border-danger p-2'>
                                    {rolnaDetaljiInfo[index].message ? (
                                      <h3>Poruka: {rolnaDetaljiInfo[index].message}</h3>
                                    ) : (
                                      <div>
                                        Težina:{' '}
                                        {rolnaDetaljiInfo[index].ReprMatTrenutnaKolicina || 'N/A'} |
                                        Debljina: {rolnaDetaljiInfo[index].ReprMatDebljina || 'N/A'}{' '}
                                        | Sirina: {rolnaDetaljiInfo[index].ReprMatSirina || 'N/A'} |
                                        Boja:{' '}
                                        {rolnaDetaljiInfo[index].boja_lica?.BojaNaziv || 'N/A'} |
                                        Proizvođač:{' '}
                                        {rolnaDetaljiInfo[index].proizvodjac?.PartnerNaziv || 'N/A'}{' '}
                                        | Šarža:{' '}
                                        {rolnaDetaljiInfo[index].proizvodjac?.PartnerNaziv || 'N/A'}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>Upisite podatke o rolni...</div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <button
                          className='btn btn-success'
                          onClick={() => push({BrojRolne: '', KrajnjaKolicina: '', Lokacija: ''})}
                        >
                          +
                        </button>
                      )}
                    </div>
                  )
                }}
              </FieldArray>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  // disabled={loading  || props.isValid}
                >
                  {!loading && 'Potvrdi'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default KrajRolne
