/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {KreirajQRcodePalete, ParamsModelKreirajQRcodePalete} from '../../models/KreirajQrCodePalete'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import notifyToast from '../Toast/Toast'
import QRCode from 'qrcode'
import {usePDF} from '@react-pdf/renderer'
import PaletePdf from './PaletePdf'
import {Modal, ModalHeader} from 'react-bootstrap'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import clsx from 'clsx'
import {KTIcon} from '../../../_websoft/helpers/components/KTIcon'
import {getQrCodePaleteData} from '../../api/kreirajQrCodePalete'
const PaleteQrCodeModal = ({setisModalOpen, isModalOpen}) => {
  const [loading, setLoading] = useState(false)
  const initialValues = {
    paletaOd: undefined,
    paletaDo: undefined,
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialData, setinitialData] = useState<ParamsModelKreirajQRcodePalete>(initialValues)
  const [qrData, setqrData] = useState<Array<KreirajQRcodePalete>>()
  const validationSchema = Yup.object({
    paletaOd: Yup.string().required('Required'),
    paletaDo: Yup.string().required('Required'),
  })

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response
        response = await getQrCodePaleteData(values)

        const responseData = response.data

        if (responseData.error === 0) {
          setqrData(response.data['data'])
          convertQrSvgToImg()

          notifyToast(true, responseData.message)
        } else {
          notifyToast(false, responseData.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  useEffect(() => {
    convertQrSvgToImg()
  }, [qrData])

  const convertQrSvgToImg = async () => {
    if (qrData) {
      var list: Array<string> = []
      await qrData.map((item) =>
        QRCode.toDataURL(
          `${item.Id} | ${item.Sirina} | ${item.Debljina} | ${item.boja.BojaNaziv} | Alternativa `.toString()
            //`${item.Id}`.toString()
        ).then((url) => {
          list.push(url)
        })
      )
      updateInstance(<PaletePdf qrData={qrData} qrList={list} />)
    }
  }

  const [instance, updateInstance] = usePDF({})
  return (
    <Modal show={isModalOpen} onHide={() => setisModalOpen(false)}>
      <ModalHeader closeButton={true} className='h3'>
        Palete (Rš)
      </ModalHeader>
      {loading ? (
        <LoadingComponent />
      ) : (
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row'>
              {/* paletaOd */}
              <div className='col-6 mb-6'>
                <label className='col-auto col-form-label fw-bold fs-6'>Paleta od</label>

                <div className='col-auto fv-row'>
                  <input
                    type='number'
                    placeholder='Od'
                    {...formik.getFieldProps('paletaOd')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.paletaOd && formik.errors.paletaOd,
                      },
                      {
                        'is-valid': formik.touched.paletaOd && !formik.errors.paletaOd,
                      }
                    )}
                  />
                  {formik.touched.paletaOd && formik.errors.paletaOd && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.paletaOd}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* paletaDo */}
              <div className='col-6 mb-6'>
                <label className='col-auto col-form-label fw-bold fs-6'>Paleta do</label>

                <div className='col-auto fv-row'>
                  <input
                    type='number'
                    placeholder='Do'
                    {...formik.getFieldProps('paletaDo')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.paletaDo && formik.errors.paletaDo,
                      },
                      {
                        'is-valid': formik.touched.paletaDo && !formik.errors.paletaDo,
                      }
                    )}
                  />
                  {formik.touched.paletaDo && formik.errors.paletaDo && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.paletaDo}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={loading || formik.isSubmitting || !formik.isValid}
                >
                  {!loading && 'Potvrdi'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
      {!instance.loading && instance.url && qrData && (
        <div className='p-5'>
          <label>Preuzmi PDF</label>
          <br />
          <a href={instance.url} target='_blank' rel='noreferrer'>
            <KTIcon iconName='document' className='fs-6tx text-primary me-2' />
          </a>
        </div>
      )}
    </Modal>
  )
}

export default PaleteQrCodeModal
