import {useFormik} from 'formik'
import React, {useEffect, useMemo, useState} from 'react'
import {Modal, ModalHeader} from 'react-bootstrap'
import * as Yup from 'yup'
import {saveSkartDimenzije} from '../../../../api/glavnaKnjigaProizvodnje'
import notifyToast from '../../../../components/Toast/Toast'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import BojaDropdown from '../../../../components/BojaDropdown/BojaDropdown'
import PanelIzolacijaDropdown from '../PanelIzolacijaDropdown/PanelIzolacijaDropdown'
import clsx from 'clsx'
import {KTIcon} from '../../../../../_websoft/helpers'
import PaneliSkartDropdown from '../PaneliSkartDropdown/PaneliSkartDropdown'

const KreirajDimenzijeModal = ({isModalOpen, setisModalOpen, stavkaID}) => {
  const validationSchema = Yup.object({})
  const id = useMemo(() => stavkaID, [stavkaID])

  const initialValues = {
    stavkaID: id,
    duzina: [],
    sirina: [],
    bojaLica: undefined,
    bojaNalicja: undefined,
    izolacija: undefined,
    paketID: [],
  }
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response
        response = await saveSkartDimenzije(values)

        if (response.error === 0) {
          notifyToast(true, response.message)
        } else {
          notifyToast(false, response.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  return (
    <Modal className='modal-lg' show={isModalOpen} onHide={() => setisModalOpen(false)}>
      <ModalHeader closeButton={true} className='h3'>
        Kreiraj Dimenzije
      </ModalHeader>
      {loading ? (
        <LoadingComponent />
      ) : (
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className='card-body border-top p-9 row'>
            {/* bojaGore */}
            <div className='col-lg-4 col-md-6 '>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Boja gore</label>
              <div className='col-lg-8 fv-row'>
                <BojaDropdown
                  value={formik.values.bojaLica}
                  onChange={(name, value) => formik.setFieldValue('bojaLica', value)}
                  errors={formik.errors.bojaLica}
                  touched={formik.touched.bojaLica}
                  selectName='bojaLica'
                />
              </div>
            </div>
            {/* bojaDole */}
            <div className='col-lg-4 col-md-6 '>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Boja dole</label>
              <div className='col-lg-8 fv-row'>
                <BojaDropdown
                  value={formik.values.bojaNalicja}
                  onChange={(name, value) => formik.setFieldValue('bojaNalicja', value)}
                  errors={formik.errors.bojaNalicja}
                  touched={formik.touched.bojaNalicja}
                  selectName='bojaNalicja'
                />
              </div>
            </div>
            {/* Izolacija */}
            <div className='col-lg-4 col-md-6 '>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Izolacija</label>
              <div className='col-lg-8 fv-row'>
                <PanelIzolacijaDropdown
                  value={formik.values.izolacija}
                  onChange={(name, value) => formik.setFieldValue('izolacija', value)}
                  errors={formik.errors.izolacija}
                  touched={formik.touched.izolacija}
                  selectName='izolacija'
                />
              </div>
            </div>
            <div className='separator mt-4 mb-2'></div>
            <div className='row mb-6'>
              {Array.from({length: formik.values.duzina!.length}, (_, i) => (
                <div className='row'>
                  {/* Duzina */}
                  <div className='col-lg-3 col-md-6 mb-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Dužina</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Duzina'
                        className={clsx('form-control form-control-lg form-control-solid')}
                        value={formik.values.duzina![i]}
                        onChange={(e) => {
                          var al = formik.values.duzina ?? []
                          // @ts-ignore
                          al.splice(i, 1, e.target.value)
                          formik.setFieldValue('duzina', al)
                        }}
                      />
                    </div>
                  </div>
                  {/* sirina */}
                  <div className='col-lg-3 col-md-6 mb-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Širina</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='sirina'
                        className={clsx('form-control form-control-lg form-control-solid')}
                        value={formik.values.sirina![i]}
                        onChange={(e) => {
                          var al = formik.values.sirina ?? []
                          // @ts-ignore
                          al.splice(i, 1, e.target.value)
                          formik.setFieldValue('sirina', al)
                        }}
                      />
                      {formik.touched.sirina && formik.errors.sirina && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.sirina}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* paneliSkartDropdown */}
                  <div className='col-lg-4 col-md-6 '>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Paket</label>
                    <div className='col-lg-8 fv-row'>
                      <PaneliSkartDropdown
                        value={formik.values.paketID![i]}
                        onChange={(name, value) => {
                          var al = formik.values.paketID ?? []
                          // @ts-ignore
                          al.splice(i, 1, value)
                          formik.setFieldValue('paketID', al)
                        }}
                        selectName='paketID'
                      />
                    </div>
                  </div>

                  <div className='col-lg-2 align-self-center'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm'
                      onClick={() => {
                        var duzinaL = formik.values.duzina ?? []
                        var sirinaL = formik.values.sirina ?? []
                        var paketIDL = formik.values.paketID ?? []
                        duzinaL.splice(i, 1)
                        sirinaL.splice(i, 1)
                        paketIDL.splice(i, 1)
                        formik.setFieldValue('duzina', duzinaL)
                        formik.setFieldValue('sirina', sirinaL)
                        formik.setFieldValue('paketID', paketIDL)
                      }}
                    >
                      <KTIcon iconName='minus' className='fs-2' />
                    </button>
                  </div>
                </div>
              ))}
              <div className='col-lg-2 mb-6 py-5'>
                <button
                  type='button'
                  className='btn btn-primary btn-sm'
                  onClick={() => {
                    var duzinaL = formik.values.duzina ?? []
                    var sirinaL = formik.values.sirina ?? []
                    var paketIDL = formik.values.paketID ?? []
                    // @ts-ignore
                    duzinaL.push(undefined)
                    // @ts-ignore
                    sirinaL.push(undefined)
                    // @ts-ignore
                    paketIDL.push(undefined)
                    formik.setFieldValue('duzina', duzinaL)
                    formik.setFieldValue('sirina', sirinaL)
                    formik.setFieldValue('paketID', paketIDL)
                  }}
                >
                  <KTIcon iconName='plus' className='fs-2' />
                </button>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && 'Potvrdi'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      )}
    </Modal>
  )
}

export default KreirajDimenzijeModal
