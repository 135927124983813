import axios from 'axios'
import {UposlenikModel, Option, PrijedloziZaPoboljsanja} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {ViljuskariModel} from "../models/VIljuskariModel";

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/viljuskari`
const API_ZAPISI = `${API_URL}/storeDodatniZapisViljuskara`
const API_AKTIVAN_ZAPIS = `${API_URL}/postojiLiAktivanZapisZaUposlenika`
const auth: UposlenikModel = getAuth()!

const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getAllZapisiViljuskara(per_page: number, page: number) {
    const params = {per_page: per_page, page: page}
    return await axios.get<Array<ViljuskariModel>>(API, {headers: headers, params: params})
}

export async function saveViljuskarData(Data: any) {
    try {
        const response = await axios.post(API, Data, {headers: headers})
        if (response.data.error === 0) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function fetchVisljuskarData(Id: number): Promise<Partial<ViljuskariModel>> {
    try {
        const response = await axios.get(`${API}/${Id}`)
        if (response.data && response.data.error === 0) {
            return response.data.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function updateVisljuskarData(Data: any, id: number) {
    try {
        const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
        if (response.data.error === 0) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function deleteVisljuskarData(Id: number) {
    try {
        const response = await axios.delete(`${API}/${Id}`, {headers: headers})
        if (response.data) {
            return response
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to delete.')
        }
    } catch (error) {
        console.error('Error deleting data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function saveViljuskarZapisiData(Data: any) {
    try {
        const response = await axios.post(API_ZAPISI, Data, {headers: headers})
        if (response.data.error === 0) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function postojiLiAktivanZapisZaUposlenika(params = {}) {
    try {
        const response = await axios.get(API_AKTIVAN_ZAPIS, {
            headers: headers,
            params: params
        });
        if (response.data.error === 0) {
            return response.data;
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.');
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Re-throw the error so it can be handled by the caller if necessary
    }
}
