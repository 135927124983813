import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {ParamsModelTable, Table} from '../models/Table'
import {Status} from '../models/Status'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/rasjeceneTrakeRolne`
const STATUS_DROPDOWN_API = `${API_URL}/statusiRasjeceniRolni`
const LOKACIJE_DROPDOWN_API = `${API_URL}/trake/lokacijeTraka`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getAllTable(params: ParamsModelTable) {
  return await axios.get<Array<Table>>(API, {headers: headers, params: params})
}

export async function saveTable(Data: any) {
  try {
    const response = await axios.post(API, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function fetchTable(Id: number): Promise<Partial<Table>> {
  try {
    const response = await axios.get(`${API}/${Id}`)
    return response.data.data
  } catch (error: any) {
    console.error('Error fetching data:', error)
    throw new Error(error.response.data.message) // Re-throw the error so it can be handled by the caller if necessary
  }
}
export async function fetchTableByPalete(paleteId: number) {
  try {
    const params = {paletaId: paleteId}
    const response = await axios.get<Array<Table>>(`${API}`, {headers: headers, params: params})
    return response
  } catch (error: any) {
    console.error('Error fetching data:', error)
    return error.response // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateTable(Data: any, id: number) {
  try {
    const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
    return response.data
  } catch (error: any) {
    console.error('Error fetching data:', error)
    return error.response.data // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function deleteTable(Id: number) {
  try {
    const response = await axios.delete(`${API}/${Id}`, {headers: headers})
    return response.data
  } catch (error: any) {
    console.error('Error deleting data:', error)
    return error.response.data // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function getStatusTableDropdown() {
  return await axios.get<Array<Status>>(STATUS_DROPDOWN_API, {headers: headers})
}

interface LokacijeResponse {
  data: Array<{ Naziv: string; Id: number }>
}

export async function getLokacijeTableDropdown() {
  return await axios.get<LokacijeResponse>(LOKACIJE_DROPDOWN_API, { headers: headers })
}