/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {getQrCodeData} from '../../api/kreirajQRcode'
import notifyToast from '../../components/Toast/Toast'
import clsx from 'clsx'
import {KreirajQRcodeModel, ParamsModelKreirajQRcode} from '../../models/KreirajQRcodeModel'
import {KTIcon} from '../../../_websoft/helpers'
import QRCode from 'qrcode'
import {usePDF} from '@react-pdf/renderer'
import RolnePdf from './RolnePdf'
import {Modal, ModalHeader} from 'react-bootstrap'
import LokacijeRepromaterijalaDropdown from "../LokacijeRepromaterijalaDropdown/LokacijeRepromaterijalaDropdown";
const RolneQrCodeModal = ({setisModalOpen, isModalOpen}) => {
    const [loading, setLoading] = useState(false)
    const initialValues = {
        rolnaOd: undefined,
        rolnaDo: undefined,
    }
    const [initialData, setinitialData] = useState<ParamsModelKreirajQRcode>(initialValues)
    const [qrData, setqrData] = useState<Array<KreirajQRcodeModel>>()
    const validationSchema = Yup.object({
        //rolnaOd: Yup.string().required('Required'),
       // rolnaDo: Yup.string().required('Required'),
    })

    const formik = useFormik({
        initialValues: initialData,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                let response
                response = await getQrCodeData(values)

                const responseData = response.data

                if (responseData.error === 0) {
                    setqrData(response.data['data'])
                    convertQrSvgToImg()

                    notifyToast(true, responseData.message)
                } else {
                    notifyToast(false, responseData.message || 'An unexpected error occurred.')
                }
            } catch (error: any) {
                console.error('Error: ', error)
                notifyToast(false, error.response.data.message)
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        },
    })
    const [qrList, setqrList] = useState<Array<string>>([])
    useEffect(() => {
        convertQrSvgToImg()
    }, [qrData])

    const convertQrSvgToImg = async () => {
        if (qrData) {
            var list: Array<string> = []
            await qrData.map((item) =>
                QRCode.toDataURL(
                    `${item.ReprMatId}; ${item.ReprMatSeriski}; ${item.ReprMatDebljina}; ${item.ReprMatSirina}; Alternativa d.o.o`.toString()
                ).then((url) => {
                    list.push(url)
                })
            )
            setqrList(list)
            updateInstance(<RolnePdf qrData={qrData} qrList={list}/>)
        }
    }

    const [instance, updateInstance] = usePDF({
        document: <RolnePdf qrData={qrData} qrList={qrList}/>,
    })
    return (
        <Modal show={isModalOpen} onHide={() => setisModalOpen(false)}>
            <ModalHeader closeButton={true} className='h3'>
                Odaberi Rolne
            </ModalHeader>
            {loading ? (
                <LoadingComponent/>
            ) : (
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row'>
                            {/* rolnaOd */}
                            <div className='col-6 mb-6'>
                                <label className='col-auto col-form-label fw-bold fs-6'>Rolna Od</label>

                                <div className='col-auto fv-row'>
                                    <input
                                        type='number'
                                        placeholder='Od'
                                        {...formik.getFieldProps('rolnaOd')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {
                                                'is-invalid': formik.touched.rolnaOd && formik.errors.rolnaOd,
                                            },
                                            {
                                                'is-valid': formik.touched.rolnaOd && !formik.errors.rolnaOd,
                                            }
                                        )}
                                    />
                                    {formik.touched.rolnaOd && formik.errors.rolnaOd && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.rolnaOd}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* rolnaDo */}
                            <div className='col-6 mb-6'>
                                <label className='col-auto col-form-label fw-bold fs-6'>Rolna Do</label>

                                <div className='col-auto fv-row'>
                                    <input
                                        type='number'
                                        placeholder='Do'
                                        {...formik.getFieldProps('rolnaDo')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {
                                                'is-invalid': formik.touched.rolnaDo && formik.errors.rolnaDo,
                                            },
                                            {
                                                'is-valid': formik.touched.rolnaDo && !formik.errors.rolnaDo,
                                            }
                                        )}
                                    />
                                    {formik.touched.rolnaDo && formik.errors.rolnaDo && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.rolnaDo}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <hr/>
                            <label>Odaberi lokaciju</label>
                            <LokacijeRepromaterijalaDropdown
                                onChange={(name, value) => formik.setFieldValue('LokacijaRolne', value)}
                                selectName='LokacijaRolne'
                            />
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={loading || formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && 'Potvrdi'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            {!instance.loading && instance.url && qrData && (
                <div className='p-5'>
                    <label>Preuzmi PDF</label>
                    <br/>
                    <a href={instance.url} target='_blank' rel='noreferrer'>
                        <KTIcon iconName='document' className='fs-6tx text-primary me-2'/>
                    </a>
                </div>
            )}
        </Modal>
    )
}

export default RolneQrCodeModal
