import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, {useState} from 'react'

const UtovarKamionaTable = ({data, kamion}) => {
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const columns = [
    {
      id: 'Nalog',
      header: '#Nalog (Stavka)',
      accessorFn: (row) => row.id.split('-')[0],
    },
    {
      id: 'Ponude',
      header: 'Broj Ponude',
      accessorFn: (row) => row.id.split('-')[2],
    },
    {
      id: 'Paket',
      header: 'Paket#',
      accessorFn: (row) => row.id.split('-')[1],
    },
    {
      id: 'Dimenzije',
      header: 'Dimenzije Paketa',
      accessorFn: (row) => `${row.w} X ${row.h} X ${row.d}`,
    },
    {
      id: 'Utovar',
      header: 'Utovar',
      cell: ({row}) => <img src={row.original.image_separated} alt='' />,
    },
    {
      id: 'Korak',
      header: 'Korak po korak',
      cell: ({row}) => <img src={row.original.image_sbs} alt='' />,
    },
  ]
  const table = useReactTable({
    data: data.items,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })
  return (
    <div className='col py-10'>
      <div className='col mb-6 h1'>Kamion: {kamion}</div>
      <div className='col-3 p-5'>
        <img src={data.image_complete} alt='' />
      </div>
      <div className='table-responsive mt-3 '>
        <table className='table align-middle table-bordered gy-5 no-footer'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
              >
                {headerGroup.headers.map((header) => (
                  <th
                    className='text-white'
                    key={header.id}
                    colSpan={header.colSpan}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder ? null : (
                      <span>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* @ts-ignore */}
                        {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='text-gray-600'>
            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default UtovarKamionaTable
