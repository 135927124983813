import React from 'react';
import exportExcel, { HeaderFormatData } from './export'; // Adjust the path as necessary
import { Table } from '@tanstack/react-table';
import { KTIcon } from '../../../_websoft/helpers';

interface ExportButtonProps {
    table: Table<any>;
    filename: string;
    extraData?: HeaderFormatData;
}

const ExportButton: React.FC<ExportButtonProps> = ({table, filename, extraData}) => {
    return (
        <button
            className='btn btn-light-primary me-3'
            onClick={() => exportExcel(table, filename, true, extraData)}
        >
            <KTIcon iconName='exit-up' className='fs-2' />
            Export to Excel
        </button>
    );
};

export default ExportButton;
