/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  getFilteredRowModel,
} from '@tanstack/react-table'
import TablePagination from '../../components/TablePagination/TablePagination'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import {TableActionButton} from '../../components/TableActionBtn/TableActionButton'
import {Link, useNavigate} from 'react-router-dom'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import {PaginationModel} from '../../models/PaginationModel'
import moments from 'moment/moment'
import {ParamsModelTroskoviSredstava, TroskoviSredstava} from '../../models/TroskoviSredstava'
import {deleteTrosak, getAllTroskovi} from '../../api/troskoviSredstava'
import SredstvaDropdown from '../../components/SredstvaDropdown/SredstvaDropdown'
import {Modal, ModalHeader} from 'react-bootstrap'

const TroskoviSredstavaLista: React.FC = () => {
  const [data, setData] = useState<TroskoviSredstava[]>([])
  const [loading, setLoading] = useState(true)
  const [pagination, setpagination] = useState<PaginationModel>({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setparams] = useState<ParamsModelTroskoviSredstava>({per_page: 10, page: 1})
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [suma, setSuma] = useState(0)
  const [isSumaOpen, setisSumaOpen] = useState(false)

  // Suma.
  const handleSuma = () => {
    var sum = table.getRowModel().rows.reduce((total, row) => {
      const cur_val = row
        .getVisibleCells()
        .find((cell) => cell.column.id === 'Trosak')
        ?.getValue()
      // @ts-ignore
      return total + parseFloat(cur_val)
    }, 0)
    setSuma(sum)
  }

  const updatePage = () => {
    setLoading(true)
    getAllTroskovi(params)
      .then((response) => {
        setData(response.data['data'])
        const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
        setpagination({
          curPage: response.data['current_page'],
          totalPage: totalPage,
          perPage: params.per_page,
        })
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    updatePage()
  }, [params])

  useEffect(() => {
    const {id} = sorting[0] ?? ''
    data.sort((a, b) => a[id] - b[id])
  }, [sorting])

  const columns = [
    {
      id: 'Id',
      header: 'Id',
      accessorKey: 'Id',
    },
    {
      id: 'Sredstvo',
      header: 'Sredstvo',
      accessorKey: 'sredstvo.SredstvoNaziv',
    },
    {
      id: 'Datum',
      header: 'Datum',
      accessorFn: (row) => `${moments(row.Datum).format('DD-MM-YYYY')} `,
    },
    {
      id: 'Intervencija',
      header: 'Intervencija',
      accessorKey: 'IntervencijaId',
    },
    {
      id: 'Trosak',
      header: 'Trosak',
      accessorKey: 'Trosak',
    },

    {
      id: 'Opis',
      header: 'Opis',
      accessorKey: 'Opis',
    },
    {
      header: 'Akcija',
      accessorKey: 'Action',
      cell: ({row}) => (
        <TableActionButton
          onDelete={() => onDelete(row.original.Id)}
          onEdit={() => {
            onEdit(row.original.Id)
          }}
        />
      ),
    },
  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })
  const onDelete = (id) => {
    deleteTrosak(id)
      .then((response) => {
        setparams({per_page: pagination.perPage, page: pagination.curPage})
        notifyToast(true, response.data['message'])
      })
      .catch((error) => {
        notifyToast(false, error.data['message'])
      })
  }
  const navigate = useNavigate()

  const onEdit = (rowData) => {
    navigate(`/sredstva/troskovi/izmjena/${rowData}`)
  }

  return (
    <KTCard>
      <ToastContainer />
      <Modal show={isSumaOpen} onHide={() => setisSumaOpen(false)}>
        <ModalHeader closeButton={true} className='h3'>
          Suma: {suma}
        </ModalHeader>
      </Modal>
      <KTCardBody className='py-4'>
        <div className='col-6 mt-5 mb-5'>
          <SredstvaDropdown onChange={(name, value) => setparams({...params, sredstvoId: value})} />
        </div>
        <div className='separator m-5'></div>
        <div className='d-flex justify-content-between'>
          <div data-kt-user-table-toolbar='base'>
            <TableSearch onSearch={(text) => setGlobalFilter(text)} />
          </div>
          <div data-kt-user-table-toolbar='base'>
            <button
              className='btn btn-light-primary me-3'
              onClick={() => {
                handleSuma()
                setisSumaOpen(true)
              }}
            >
              <KTIcon iconName='calculator' className='fs-2' />
              Suma
            </button>
            <ExportButton table={table} filename={'zapisnici_obucavanja'} />
            <Link to='/sredstva/troskovi/dodaj'>
              <button type='button' className='btn btn-primary'>
                <KTIcon iconName='plus' className='fs-2' />
                Dodaj
              </button>
            </Link>
          </div>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3 '>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className='text-white'
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* @ts-ignore */}
                        {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <TablePagination
          updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
          pagination={pagination}
        />
      </KTCardBody>
    </KTCard>
  )
}
export default TroskoviSredstavaLista
