import {RepromaterijalIzmjena, RepromaterijaliModel} from './../models/RepromaterijaliModel'
import axios from 'axios'
import {getAuth} from '../modules/auth'
import {UposlenikModel} from '../models/UposlenikModel'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/repromaterijal`
const UPDATE_API = `${API_URL}`
const auth: UposlenikModel = getAuth()!

const headers = {
  Accept: 'application/json',
  Authorization: auth?.access_token ?? '',
}

export async function getRepromaterijali(params: any) {
  return await axios.get<Array<RepromaterijaliModel>>(API, {headers: headers, params: params})
}

export async function saveRepromaterijali(Data: any) {
  return await axios.post(API, Data, {headers: headers})
}

export async function fetchRepromaterijali(Id: number): Promise<Partial<RepromaterijaliModel>> {
  try {
    const response = await axios.get(`${API}/${Id}`)
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch Repromaterijal data.')
    }
  } catch (error) {
    console.error('Error fetching Repromaterijal data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateRepromaterijali(
  reprId: number,
  Data: Partial<RepromaterijaliModel>
): Promise<any> {
  try {
    const response = await axios.put(`${API}/${reprId}`, Data, {headers: headers})

    if (response.data && response.data.error === 0) {
      return response
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to update Repromaterijal data.')
    }
  } catch (error) {
    console.error('Error updating Repromaterijal data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function deleteRepromaterijali(id: number) {
  try {
    const response = await axios.delete(`${API}/${id}`, {headers: headers})
    if (response.data && response.data.error === 0) {
      return response
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to delete Repromaterijal.')
    }
  } catch (error) {
    console.error('Error deleting Repromaterijal data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateStatusRepromaterijala(
  Data: Partial<RepromaterijalIzmjena>
): Promise<any> {
  try {
    const response = await axios.put(`${UPDATE_API}/updateStatusRepromaterijala`, Data, {
      headers: headers,
    })
    if (response.data && response.data.error === 0) {
      return response.data
    } else {
      throw Error(response.data.message)
    }
  } catch (error: any) {
    console.error('Error updating Repromaterijal data:', error)
    throw Error(error.response.data.message) // Re-throw the error so it can be handled by the caller if necessary
  }
}
export async function updateSkladisteRepromaterijala(
  Data: Partial<RepromaterijalIzmjena>
): Promise<any> {
  try {
    const response = await axios.put(`${UPDATE_API}/updateSkladisteRepromaterijala`, Data, {
      headers: headers,
    })

    if (response.data && response.data.error === 0) {
      return response.data
    }
  } catch (error) {
    console.error('Error updating Repromaterijal data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
export async function updateLokacijaRolneRepromaterijala(
  Data: Partial<RepromaterijalIzmjena>
): Promise<any> {
  try {
    const response = await axios.put(`${UPDATE_API}/updateLokacijaRolne`, Data, {
      headers: headers,
    })

    if (response.data && response.data.error === 0) {
      return response.data
    }
  } catch (error) {
    console.error('Error updating Repromaterijal data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function stanjeRepromaterijalaPoProizvodjacu(params) {
  try {
    const response = await axios.get(
      `${API_URL}/repromaterijali/stanjeRepromaterijalaPoProizvodjacu`,
      {
        headers: headers,
        params,
      }
    )
    if (response.data && response.data.error === 0) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error: any) {
    console.error('Error:', error)
    throw new Error(error.response.data.message) // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function zadnjaRolnaUpisana() {
  try {
    const response = await axios.get(`${API_URL}/repromaterijali/zadnjaRolnaUpisana`, {
      headers: headers,
    })
    if (response.data && response.data.error === 0) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error: any) {
    console.error('Error:', error)
    throw new Error(error.response.data.message) // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function repromaterijaliGlavniIzvjestaj(params: any) {
  try {
    const response = await axios.get(`${API_URL}/repromaterijali/glavniIzvjestaj`, {
      headers: headers,
      params,
    })
    if (response.data && response.data.error === 0) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  } catch (error: any) {
    console.error('Error:', error)
    throw new Error(error.response.data.message) // Re-throw the error so it can be handled by the caller if necessary
  }
}
