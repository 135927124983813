import React, {useState, useEffect} from "react";
import notifyToast from "../../../components/Toast/Toast";
import {ToastContainer} from "react-toastify";
import TitleComponent from "../../../components/TitleComponent/TitleComponent";
import QrCodeScanner from "../../../components/QrCodeScanner/QrCodeScanner";
import {sredstvoInfo} from "../../../api/fmkNalozi";
import {useNavigate, useParams} from "react-router-dom";
import {fetchVisljuskarData, saveViljuskarData, updateVisljuskarData} from "../../../api/viljuskari";

const ViljuskariDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const IdFromUrl = Number(id);

    const [loading, setLoading] = useState(false);
    const [naziv, setNaziv] = useState("");
    const [pocetakSati, setPocetakSati] = useState("");
    const [sredstvoId, setSredstvoId] = useState("");
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        BrojSatiPocetak: '',
        BrojSatiKraj: '',
        ProvjerioUlje: false,
        ProvjerioVodu: false,
        ProvjerioHidrol: false,
        sredstvo: '',
        SredstvoId: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            if (IdFromUrl) {
                try {
                    const data = await fetchVisljuskarData(IdFromUrl);
                    if (data) {
                        setPocetakSati(data.BrojSatiPocetak ?? '');
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };
        fetchData();
    }, [IdFromUrl]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));

        if (name === "sredstvo" && value.length >= 3) {
            checkInventurniBroj(value);
        }
    };

    const handleQrScan = (data: string) => {
        setFormData(prevData => ({ ...prevData, sredstvo: data }));
        checkInventurniBroj(data);
    };

    const checkInventurniBroj = async (invBroj: string) => {
        try {
            const data = await sredstvoInfo({ invBroj });
            if (data) {
                setNaziv(data.SredstvoNaziv);
                setSredstvoId(data.SredstvoId);
                setFormData(prevData => ({
                    ...prevData,
                    sredstvo: invBroj,
                    SredstvoId: data.SredstvoId
                }));
            } else {
                setNaziv("");
                setFormData(prevData => ({
                    ...prevData,
                    sredstvo: invBroj,
                    SredstvoId: ""
                }));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setNaziv("");
            setFormData(prevData => ({
                ...prevData,
                sredstvo: invBroj,
                SredstvoId: ""
            }));
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        try {
            let response;
            if (IdFromUrl) {
                const { BrojSatiKraj } = formData;
                response = await updateVisljuskarData({ BrojSatiKraj }, IdFromUrl);
            } else {
                response = await saveViljuskarData(formData);
            }

            if (response.error === 0) {
                notifyToast(true, response.message);
                setTimeout(() => {
                    navigate(-2);
                }, 2000)
            } else {
                notifyToast(false, response.message || "An unexpected error occurred.");
            }
        } catch (error: any) {
            console.error("Error: ", error);
            notifyToast(false, error.response?.data?.message || "An unexpected error occurred.");
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="row">
            <ToastContainer/>
            <div className="card mb-5 mb-xl-10 col-12">
                <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                >
                    <div className="card-title m-0">
                        <TitleComponent name="Zapis"/>
                    </div>
                </div>
                <div id="kt_account_profile_details" className="collapse show">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="card-body border-top p-9 row">
                            <div className="col-lg-6 col-sm-12">
                                {!IdFromUrl && (
                                    <>
                                        <div className="row mb-5">
                                            <label className="col-12 col-form-label required fw-bold fs-6">Skeniraj</label>
                                            <div className="col-3 mb-sm-10 fv-row">
                                                <QrCodeScanner onScan={handleQrScan}/>
                                            </div>
                                            <div className="col-9 fv-row">
                                                <input
                                                    type="text"
                                                    placeholder="Sredstvo"
                                                    name="sredstvo"
                                                    value={formData.sredstvo}
                                                    onChange={handleInputChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-12 mb-sm-10 fv-row">
                                                <label className="col-auto col-form-label required fw-bold fs-2">{naziv}</label>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-lg-9 col-sm-12 fv-row">
                                                <input
                                                    type="number"
                                                    placeholder="Radni sati početak"
                                                    name="BrojSatiPocetak"
                                                    value={formData.BrojSatiPocetak}
                                                    onChange={handleInputChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {!IdFromUrl && (
                                    <>
                                        <div className="row mt-10">
                                            <label className="col-auto col-form-label required fw-bold fs-6">DNEVNE
                                                PROVJERE VILJUŠKARA</label>
                                            <div className="col-lg-9 col-sm-12 mt-3 fv-row">
                                                <label style={{display: "inline-flex", alignItems: "center"}}>
                                                    <input
                                                        type="checkbox"
                                                        name="ProvjerioVodu"
                                                        checked={formData.ProvjerioVodu}
                                                        onChange={handleInputChange}
                                                        className="form-check-input"
                                                        style={{
                                                            transform: "scale(1.5)",
                                                            marginRight: "10px",
                                                        }}
                                                    />
                                                    <span style={{fontWeight: "bold"}}>Voda provjerena</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mt-10">
                                            <div className="col-lg-9 col-sm-12 mt-3 fv-row">
                                                <label style={{display: "inline-flex", alignItems: "center"}}>
                                                    <input
                                                        type="checkbox"
                                                        name="ProvjerioUlje"
                                                        checked={formData.ProvjerioUlje}
                                                        onChange={handleInputChange}
                                                        className="form-check-input"
                                                        style={{
                                                            transform: "scale(1.5)",
                                                            marginRight: "10px",
                                                        }}
                                                    />
                                                    <span style={{fontWeight: "bold"}}>Ulje provjereno</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mt-10">
                                            <div className="col-lg-9 col-sm-12 mt-3 fv-row">
                                                <label style={{display: "inline-flex", alignItems: "center"}}>
                                                    <input
                                                        type="checkbox"
                                                        name="ProvjerioHidrol"
                                                        checked={formData.ProvjerioHidrol}
                                                        onChange={handleInputChange}
                                                        className="form-check-input"
                                                        style={{
                                                            transform: "scale(1.5)",
                                                            marginRight: "10px",
                                                        }}
                                                    />
                                                    <span style={{fontWeight: "bold"}}>Hidrol provjeren</span>
                                                </label>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {!IdFromUrl ? (
                                    <div className="row mt-5">
                                        <div className="col-lg-8 mt-5 fv-row">
                                            <div className="d-grid gap-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-block btn-success"
                                                    disabled={loading}
                                                >
                                                    {!loading && "Start"}
                                                    {loading && (
                                                        <span className="indicator-progress" style={{display: "block"}}>
                                                            Please wait...{" "}
                                                            <span
                                                                className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="col-12 mb-sm-10 fv-row">
                                            <label className="col-12 col-form-label required mt-5 fw-bold fs-2">Radni Sati Početak: {pocetakSati}</label>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-lg-9 col-sm-12 fv-row">
                                                <input
                                                    type="number"
                                                    placeholder="Radni sati kraj"
                                                    name="BrojSatiKraj"
                                                    value={formData.BrojSatiKraj}
                                                    onChange={handleInputChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-lg-8 mt-5 fv-row">
                                                <div className="d-grid gap-2">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-block btn-danger"
                                                        disabled={loading}
                                                    >
                                                        {!loading && "Kraj"}
                                                        {loading && (
                                                            <span className="indicator-progress" style={{display: "block"}}>
                                                                Please wait...{" "}
                                                                <span
                                                                    className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ViljuskariDetails;

