/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import 'react-toastify/dist/ReactToastify.css'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import {useParams} from 'react-router-dom'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import {RepromaterijalPromet} from '../../models/RepromaterijaliPromet'
import PoslovniceDropdown from '../../components/PoslovniceDropdown/PoslovniceDropdown'
import LokacijeRepromaterijalaDropdown from '../../components/LokacijeRepromaterijalaDropdown/LokacijeRepromaterijalaDropdown'
import QrCodeScanner from '../../components/QrCodeScanner/QrCodeScanner'
import {
  fetchRepromaterijalPromet,
  saveRepromaterijalPromet,
  updateRepromaterijalPromet,
} from '../../api/prometRepromaterijala'
import useDebounce from '../../hooks/useDebounce'
import OdjeliDropdown from '../../components/OdjeliDropdown/OdjeliDropdown'
import {rolnaDetaljiProizvodnja} from '../../api/fmkNalozi'
import {RolnaDetaljiProizvodnja} from '../../models/FmkNaloziModel'

interface PrometDetailsProps {
  // other potential props go here
}

const PrometDetails: React.FC<PrometDetailsProps> = () => {
  const {id} = useParams()
  const idFromURL = Number(id)

  const initialValues = {
    Datum: '',
    Repromaterijal: '',
    VrstaIzlaza: '',
    BrojDokumentaIzlaza: '',
    Krajnje: '',
    LokacijaRolne: '',
    Odjel: '',
    Poslovnica: '',
    Napomene: '',
    Naziv: '',
    Promet: ''
  }
  const [loading, setLoading] = useState(true)
  const [initialData, setInitialData] = useState<Partial<RepromaterijalPromet>>(initialValues)
  const [rolnaDetaljiInfo, setrolnaDetalji] = useState<RolnaDetaljiProizvodnja>()

  useEffect(() => {
    if (idFromURL) {
      fetchRepromaterijalPromet(idFromURL)
        .then((data) => {
          setInitialData(data)
          formik.resetForm({values: data}) // <-- reset formik state here
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [idFromURL])

  const validationSchema = Yup.object({
    Krajnje: Yup.number().required('Required'),
    BrojDokumentaIzlaza: Yup.string().min(5, 'Must be at least 4 characters').required('Required'),
    Datum: Yup.string().required('Required'),
    Repromaterijal: Yup.number().required('Required'),
    Odjel: Yup.number().required('Required'),
    VrstaIzlaza: Yup.number().required('Required'),
    Poslovnica: Yup.number().required('Required'),
    LokacijaRolne: Yup.string().required('Required'),
  })

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response

        if (idFromURL) {
          // Update existing
          response = await updateRepromaterijalPromet(values, idFromURL)
        } else {
          // Save new
          response = await saveRepromaterijalPromet(values)
        }

        const responseData = response

        if (responseData.error === 0) {
          notifyToast(true, responseData.message)
        } else {
          notifyToast(false, responseData.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const debouncedRolna = useDebounce(formik.values.Repromaterijal)
  useEffect(() => {
    if (debouncedRolna) {
      rolnaDetaljiProizvodnja({rolnaId: debouncedRolna})
        .then((response) => {
          if (response.data) {
            setrolnaDetalji(response.data)
          } else {
            setrolnaDetalji(response)
          }
        })
        .catch((error) => notifyToast(false, error.response.data.message))
    }
  }, [debouncedRolna])

  return (
    <div className='card mb-5 mb-xl-10'>
      <ToastContainer />
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <TitleComponent name='promet' />
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        {loading ? (
          <LoadingComponent />
        ) : (
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              {/* ROLNA */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>ROLNA</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-2'>
                      <QrCodeScanner
                          onScan={(data) => {
                            const text = data.split(';')[0]
                            formik.setFieldValue('Repromaterijal', text)
                          }}
                      />
                    </div>
                    <div className='col-4'>
                      <input
                          type='text'
                          placeholder='Rolna'
                          {...formik.getFieldProps('Repromaterijal')}
                          className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {
                                'is-invalid':
                                    formik.touched.Repromaterijal && formik.errors.Repromaterijal,
                              },
                              {
                                'is-valid':
                                    formik.touched.Repromaterijal && !formik.errors.Repromaterijal,
                              }
                          )}
                      />
                      {formik.touched.Repromaterijal && formik.errors.Repromaterijal && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.Repromaterijal}</div>
                          </div>
                      )}
                    </div>
                    <div className='col-4'>
                      {rolnaDetaljiInfo ? (
                          <div className='col-12 border rounded border-success p-2'>
                            {rolnaDetaljiInfo.message ? (
                                <h3>Poruka: {rolnaDetaljiInfo.message}</h3>
                            ) : (
                                <div>
                                  Težina: {rolnaDetaljiInfo.ReprMatTrenutnaKolicina || 'N/A'} |
                                  Debljina: {rolnaDetaljiInfo.ReprMatDebljina || 'N/A'} | Sirina:{' '}
                                  {rolnaDetaljiInfo.ReprMatSirina || 'N/A'} | Boja:{' '}
                                  {rolnaDetaljiInfo.boja_lica?.BojaNaziv || 'N/A'} | Proizvođač:{' '}
                                  {rolnaDetaljiInfo.proizvodjac?.PartnerNaziv || 'N/A'} | Šarža:{' '}
                                  {rolnaDetaljiInfo.proizvodjac?.PartnerNaziv || 'N/A'}
                                </div>
                            )}
                          </div>
                      ) : (
                          <div>Loading or no data...</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* BROJ NALOGA */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>BROJ NALOGA</label>
                <div className='col-1 col-xs-3 '>
                  <QrCodeScanner
                      onScan={(data) => {
                        const text = data.split(';')[0]
                        formik.setFieldValue('BrojDokumentaIzlaza', text)
                      }}
                  />
                </div>
                <div className='col-6'>
                  <input
                      type='string'
                      placeholder='Broj naloga'
                      {...formik.getFieldProps('BrojDokumentaIzlaza')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                                formik.touched.BrojDokumentaIzlaza && formik.errors.BrojDokumentaIzlaza,
                          },
                          {
                            'is-valid':
                                formik.touched.BrojDokumentaIzlaza && !formik.errors.BrojDokumentaIzlaza,
                          }
                      )}
                  />
                  {formik.touched.Repromaterijal && formik.errors.Repromaterijal && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Repromaterijal}</div>
                      </div>
                  )}
                </div>
              </div>
              {/* Odjel */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>ODJEL</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <OdjeliDropdown
                      value={formik.values.Odjel}
                      onChange={(name, value) => formik.setFieldValue('Odjel', value)}
                      errors={formik.errors.Odjel}
                      touched={formik.touched.Odjel}
                      selectName='Odjel'
                  />
                </div>
              </div>
              {/* Poslovnica */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>POSLOVNICA</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <PoslovniceDropdown
                      value={formik.values.Poslovnica}
                      onChange={(name, value) => formik.setFieldValue('Poslovnica', value)}
                      errors={formik.errors.Poslovnica}
                      touched={formik.touched.Poslovnica}
                      selectName='Poslovnica'
                  />
                </div>
              </div>
              {/* Lokacije */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>LOKACIJA</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <LokacijeRepromaterijalaDropdown
                      value={formik.values.LokacijaRolne}
                      onChange={(name, value) => formik.setFieldValue('LokacijaRolne', value)}
                      errors={formik.errors.LokacijaRolne}
                      touched={formik.touched.LokacijaRolne}
                      selectName='LokacijaRolne'
                  />
                </div>
              </div>
              {/* Agent */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  KRAJNJA KOLIČINA
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                      type='number'
                      placeholder='Krajnje'
                      {...formik.getFieldProps('Krajnje')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.Krajnje && formik.errors.Krajnje},
                          {
                            'is-valid': formik.touched.Krajnje && !formik.errors.Krajnje,
                          }
                      )}
                  />
                  {formik.touched.Krajnje && formik.errors.Krajnje && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Krajnje}</div>
                      </div>
                  )}
                </div>
              </div>
              {idFromURL && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  PROMET
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                      type='number'
                      placeholder='PROMET'
                      {...formik.getFieldProps('Promet')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.Promet && formik.errors.Promet},
                          {
                            'is-valid': formik.touched.Promet && !formik.errors.Promet,
                          }
                      )}
                  />
                  {formik.touched.Promet && formik.errors.Promet && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Promet}</div>
                      </div>
                  )}
                </div>
              </div>
              )}
              {/* DATUM */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>DATUM</label>
                <div className='col-lg-8 fv-row'>
                  <input
                      type='date'
                      placeholder='Agent'
                      {...formik.getFieldProps('Datum')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.Datum && formik.errors.Datum},
                          {
                            'is-valid': formik.touched.Datum && !formik.errors.Datum,
                          }
                      )}
                  />
                  {formik.touched.Datum && formik.errors.Datum && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Datum}</div>
                      </div>
                  )}
                </div>
              </div>

              {/* Osigurano */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>VRSTA IZLAZA</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                      {...formik.getFieldProps('VrstaIzlaza')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.VrstaIzlaza && formik.errors.VrstaIzlaza},
                          {
                            'is-valid': formik.touched.VrstaIzlaza && !formik.errors.VrstaIzlaza,
                          }
                      )}
                  >
                    <option value=''>Vrsta Izlaza...</option>
                    <option value='1'>Proizvodnja</option>
                    <option value='2'>Direktna prodaja</option>
                  </select>
                  {formik.touched.VrstaIzlaza && formik.errors.VrstaIzlaza && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.VrstaIzlaza}</div>
                      </div>
                  )}
                </div>
              </div>
              {/* Napomena */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Napomena</label>

                <div className='col-lg-8 fv-row'>
                  <input
                      type='text'
                      placeholder='Napomena'
                      {...formik.getFieldProps('Napomene')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.Napomene && formik.errors.Napomene},
                          {
                            'is-valid': formik.touched.Napomene && !formik.errors.Napomene,
                          }
                      )}
                  />
                  {formik.touched.Napomene && formik.errors.Napomene && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Napomene}</div>
                      </div>
                  )}
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                  type='submit'
                  className='btn btn-primary'
                  //disabled={loading || formik.isSubmitting || !formik.isValid}
                  disabled={
                      loading ||
                      formik.isSubmitting ||
                      !formik.isValid ||
                      !!rolnaDetaljiInfo?.message // Explicitly cast to boolean
                  }
              >
                {!loading && 'Potvrdi'}
                {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}
export default PrometDetails
