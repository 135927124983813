/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import 'react-toastify/dist/ReactToastify.css'
import notifyToast from '../../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../../components/TitleComponent/TitleComponent'
import {useParams} from 'react-router-dom'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
import {PanelObloge} from '../../../models/PanelSifarniciModel'

import {fetchOblogaPanela, saveOblogaPanela, updateOblogaPanela} from '../../../api/panelObloga'

interface Props {
  // other potential props go here
}
const OblogeDodaj: React.FC<Props> = () => {
  const {id} = useParams()
  const IdFromURL = Number(id)

  const [loading, setLoading] = useState(true)

  const initialValues = {
    naziv: '',
    opis: '',
  }
  const [initialData, setInitialData] = useState<Partial<PanelObloge>>(initialValues)

  useEffect(() => {
    if (IdFromURL) {
      fetchOblogaPanela(IdFromURL)
        .then((data) => {
          setInitialData(data)
          formik.resetForm({values: data}) // <-- reset formik state here
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [IdFromURL])

  const validationSchema = Yup.object({
    naziv: Yup.string().min(2, 'Must be at least 2 characters').required('Required'),
  })

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response
        if (IdFromURL) {
          response = await updateOblogaPanela(values, IdFromURL)
        } else {
          response = await saveOblogaPanela(values)
        }
        if (response.error === 0) {
          notifyToast(true, response.message)
        } else {
          notifyToast(false, response.message || 'An unexpected error occurred.')
        }
      } catch (error) {
        console.error('Error: ', error)
        notifyToast(false, 'Error')
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  return (
    <div className='card mb-5 mb-xl-10'>
      <ToastContainer />
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <TitleComponent name=' ' />
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        {loading ? (
          <LoadingComponent />
        ) : (
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              {/* Naziv */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Vanjska obloga{' '}
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    placeholder='Vanjska obloga'
                    {...formik.getFieldProps('naziv')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.naziv && formik.errors.naziv},
                      {
                        'is-valid': formik.touched.naziv && !formik.errors.naziv,
                      }
                    )}
                  />
                  {formik.touched.naziv && formik.errors.naziv && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.naziv}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Opis */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Opis</label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    placeholder='Opis'
                    {...formik.getFieldProps('opis')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.opis && formik.errors.opis},
                      {
                        'is-valid': formik.touched.opis && !formik.errors.opis,
                      }
                    )}
                  />
                  {formik.touched.opis && formik.errors.opis && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.opis}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={loading || formik.isSubmitting || !formik.isValid}
              >
                {!loading && 'Potvrdi'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}
export default OblogeDodaj
