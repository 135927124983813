import React from 'react';
import TreeNode from './TreeNode'; // Ensure this path is correct

const Tree = ({ data }) => {
    return (
        <table className="table table-bordered">
            <thead>
            <tr className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'>
                <th className='text-white'>Šifra</th>
                <th className='text-white'>Naziv</th>
                <th className='text-white'>Stanje SA</th>
                <th className='text-white'>Aktivan</th>
            </tr>
            </thead>
            <tbody>
            {data.map(node => (
                <TreeNode key={node.ArtId} node={node} />
            ))}
            </tbody>
        </table>
    );
};

export default Tree;
