import {useFormik} from 'formik'
import React, {useState} from 'react'
import {saveZapisiStavke} from '../../../../api/zapisiProizvodnje'
import notifyToast from '../../../../components/Toast/Toast'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'

const Upjenjivanje = ({id, brojNaloga}) => {
  const initialValues = {
    'TEMPERTATURA-AMBIJENTA-PRESE': undefined,
    POKERI: undefined,
    'POTVRDJUJEM-ISPRAVNOST-POZICIJE-POKERA': undefined,
    RECEPTURA: undefined,
    IZO: undefined,
    'IZO-PROIZVODJAC': undefined,
    'IZO-PRITISAK': undefined,
    POLIOL: undefined,
    'POLIOL-PROIZVODJAC': undefined,
    'CAT1-GRAMAZA': undefined,
    'CAT1-PROIZVODJAC': undefined,
    'CAT1-PRITISAK': undefined,
    'CAT2-GRAMAZA': undefined,
    'CAT2-PROIZVODJAC': undefined,
    'CAT2-PRITISAK': undefined,
    'CAT3-GRAMAZA': undefined,
    'CAT3-PROIZVODJAC': undefined,
    'CAT3-PRITISAK': undefined,
    'CAT4-GRAMAZA': undefined,
    'CAT4-PROIZVODJAC': undefined,
    'CAT4-PRITISAK': undefined,
    'EXP-PRITISAK': undefined,
    EXP: undefined,
    'PRAJMER-KOLICINA': undefined,
    'PRAJMER-POLIOL-IZO': undefined,
    'KROSBAR-POZICIJA': undefined,
    BRZINA: undefined,
    GUSTOCA: undefined,
    'G-T': undefined,
    'T-T': undefined,
  }
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response

        let data: any = []
        // Format data as per required format of api.
        Object.entries(values).map(([key, value]) => {
          if (value) {
            const dataSet = {
              IdZapisa: id,
              BrojNaloga: brojNaloga,
              MetaKey: key,
              MetaValue: value,
            }
            data.push(dataSet)
          }
        })
        response = await saveZapisiStavke(data)

        const responseData = response

        if (responseData.error === 0) {
          notifyToast(true, responseData.message)
        } else {
          notifyToast(false, responseData.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error.response.data.message)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  return (
    <div>
      {' '}
      {loading ? (
        <LoadingComponent />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div id='upjenjivanje'>
            <div className='row mt-3'>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Temperatura ambijenta prese </label>
                <input
                  type='text'
                  className='form-control'
                  {...formik.getFieldProps('TEMPERTATURA-AMBIJENTA-PRESE')}
                  placeholder='Temp prese'
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Pokeri - tip | L/D </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Pokeri'
                  {...formik.getFieldProps('POKERI')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'>
                  POTVRĐUJEM ISPRAVNOST POZICIJE POKERA
                </label>
                <select
                  className='form-control'
                  {...formik.getFieldProps('POTVRDJUJEM-ISPRAVNOST-POZICIJE-POKERA')}
                >
                  <option value=''>ODABERI</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-12'>
                <label className='col-form-label fw-bold fs-6'> Receptura - Ukupno [g/min] </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Receptura'
                  {...formik.getFieldProps('RECEPTURA')}
                />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Izo [g/min] </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Izo količina'
                  {...formik.getFieldProps('IZO')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Izo [g/min] </label>
                <select className='form-control' {...formik.getFieldProps('IZO-PROIZVODJAC')}>
                  <option value=''>ODABERI</option>
                  <option value='BASF'>BASF</option>
                  <option value='BORSCHEM'>BORSCHEM</option>
                  <option value='MIX/NIJE-POZNAT'>MIX/NIJE-POZNAT</option>
                </select>
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Izo pritisak [bar] </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Izo pritisak'
                  {...formik.getFieldProps('IZO-PRITISAK')}
                />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Poliol [g/min] </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Poliol količina'
                  {...formik.getFieldProps('POLIOL')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Poliol [g/min] </label>
                <select className='form-control' {...formik.getFieldProps('POLIOL-PROIZVODJAC')}>
                  <option value=''>ODABERI</option>
                  <option value='BASF'>BASF</option>
                  <option value='BORSCHEM'>BORSCHEM</option>
                  <option value='MIX/NIJE-POZNAT'>MIX/NIJE-POZNAT</option>
                  <option value='TEST-NOVI POLIOL'>TEST-NOVI POLIOL</option>
                </select>
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Poliol pritisak [bar] </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Poliol pritisak'
                  {...formik.getFieldProps('POLIOL-PRITISAK')}
                />
              </div>
            </div>
            {/* UPJENJIVANJE */}
            <div className='row mt-3'>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> CAT 1 </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='CAT1 Gramaza'
                  {...formik.getFieldProps('CAT1-GRAMAZA')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Proizvođač </label>
                <select className='form-control' {...formik.getFieldProps('CAT1-PROIZVODJAC')}>
                  <option value=''>ODABERI</option>
                  <option value='KX216'>KX216</option>
                  <option value='KX133'>KX133</option>
                  <option value='KX340'>KX340</option>
                  <option value='ZM99'>Zm99</option>
                  <option value='ZM99-H2O'>Zm99-H2O</option>
                </select>
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> CAT 1 - pritisak </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Kx216 pritisak'
                  {...formik.getFieldProps('CAT1-PRITISAK')}
                />
              </div>
            </div>
            {/* UPJENJIVANJE  */}
            <div className='row mt-3'>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> CAT 2</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='CAT2 Gramaza'
                  {...formik.getFieldProps('CAT2-GRAMAZA')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Proizvođač </label>
                <select className='form-control' {...formik.getFieldProps('CAT2-PROIZVODJAC')}>
                  <option value=''>ODABERI</option>
                  <option value='KX216'>KX216</option>
                  <option value='KX133'>KX133</option>
                  <option value='KX340'>KX340</option>
                  <option value='ZM99'>Zm99</option>
                  <option value='ZM99-H2O'>Zm99-H2O</option>
                </select>
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> CAT 2 - pritisak </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Kx133 pritisak'
                  {...formik.getFieldProps('CAT2-PRITISAK')}
                />
              </div>
            </div>
            {/* UPJENJIVANJE  */}
            <div className='row mt-3'>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> CAT 3 - Kx340 </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='CAT3 Gramaza'
                  {...formik.getFieldProps('CAT3-GRAMAZA')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Proizvođač </label>
                <select className='form-control' {...formik.getFieldProps('CAT3-PROIZVODJAC')}>
                  <option value=''>ODABERI</option>
                  <option value='KX216'>KX216</option>
                  <option value='KX133'>KX133</option>
                  <option value='KX340'>KX340</option>
                  <option value='ZM99'>Zm99</option>
                  <option value='ZM99-H2O'>Zm99-H2O</option>
                </select>
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> CAT 3 - pritisak </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Cat3 KX340 pritisak'
                  {...formik.getFieldProps('CAT3-PRITISAK')}
                />
              </div>
            </div>
            {/* UPJENJIVANJE  */}
            <div className='row mt-3'>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> CAT 4 - Zm99 </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Cat4 Gramaza'
                  {...formik.getFieldProps('CAT4-GRAMAZA')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Proizvođač </label>
                <select className='form-control' {...formik.getFieldProps('CAT4-PROIZVODJAC')}>
                  <option value=''>ODABERI</option>
                  <option value='KX216'>KX216</option>
                  <option value='KX133'>KX133</option>
                  <option value='KX340'>KX340</option>
                  <option value='ZM99'>Zm99</option>
                  <option value='ZM99-H2O'>Zm99-H2O</option>
                </select>
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> CAT 4 - Zm99 pritisak </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Cat4 Zm99 pritisak'
                  {...formik.getFieldProps('CAT4-PRITISAK')}
                />
              </div>
            </div>
            {/* UPJENJIVANJE  */}
            <div className='row mt-3'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> Exp [g/min] </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Exp'
                  {...formik.getFieldProps('EXP')}
                />
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> Exp pritisak </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Exp pritisak'
                  {...formik.getFieldProps('EXP-PRITISAK')}
                />
              </div>
            </div>
            {/* UPJENJIVANJE  */}
            <div className='row mt-3'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> Prajmer količina </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Prajmer kolicina'
                  {...formik.getFieldProps('PRAJMER-KOLICINA')}
                />
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> Prajmer poliol/izo </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Prajmer poliol izo'
                  {...formik.getFieldProps('PRAJMER-POLIOL-IZO')}
                />
              </div>
            </div>
            {/* UPJENJIVANJE  */}
            <div className='row mt-3'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> Krosbar - pozicija </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Krosbar - pozicija'
                  {...formik.getFieldProps('KROSBAR-POZICIJA')}
                />
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> Brzina [/m/min] </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='BRZINA'
                  {...formik.getFieldProps('BRZINA')}
                />
              </div>
            </div>
            {/* UPJENJIVANJE  */}
            <div className='row mt-3'>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Gustoća [kg/m3] </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Gustoca'
                  {...formik.getFieldProps('GUSTOCA')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> G.T [s] </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='G.T'
                  {...formik.getFieldProps('G-T')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> T.T [s] </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='T-T'
                  {...formik.getFieldProps('T-T')}
                />
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-lg-3 offset-9'>
              <button type='submit' className='btn btn-block btn-primary'>
                Potvrdi
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

export default Upjenjivanje
