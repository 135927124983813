import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react'
import QrCodeScanner from '../../../components/QrCodeScanner/QrCodeScanner'
import {Form, useFormik} from 'formik'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
import * as Yup from 'yup'
import notifyToast from '../../../components/Toast/Toast'
import clsx from 'clsx'
import {fetchTable} from '../../../api/tableRS'
import useDebounce from '../../../hooks/useDebounce'
import {KTIcon} from '../../../../_websoft/helpers'
import {saveRadniNalozi} from '../../../api/radniNalozi'
import {FmkNalogiStavkeModel, SredstvoInfoModel} from '../../../models/FmkNaloziModel'
import {sredstvoInfo} from '../../../api/fmkNalozi'
import RadniNaloziOdjeliDropdown from '../../../components/RadniNaloziOdjeliDropdown/RadniNaloziOdjeliDropdown'
interface Props {
  nalogaId: string
  selectedStavka: FmkNalogiStavkeModel['stavka']
}
export type Ref = any
const PrometTraka = forwardRef<Ref, Props>(({nalogaId, selectedStavka}, ref) => {
  const nalog = useMemo(() => nalogaId, [nalogaId])
  const stavka = useMemo(() => selectedStavka, [selectedStavka])
  const initialValues = {
    trakaId: undefined,
    invbroj: undefined,
    trake: [],
    Odjel: undefined,
  }
  const [loading, setLoading] = useState(false)
  const validationSchema = Yup.object({
    trakaId: Yup.string().required('required'),
  })
  const [initialData, setInitialData] = useState<any>(initialValues)
  const [sredstvoInfoData, setsredstvoInfoData] = useState<SredstvoInfoModel>()

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {},
  })

  useImperativeHandle(ref, () => ({
    saveData,
  }))

  const saveData = () => {
    setLoading(true)
    if (stavka.length > 0) {
      const payloadData = {
        BrojNaloga: nalog,
        MasinaId: formik.values.invbroj,
        OdjelId: formik.values.Odjel,
        BrojRolne: '',
        KrajnjaKolicina: '',
        Lokacija: '',
        stavke: stavka,
        trake: formik.values.trake,
      }

      saveRadniNalozi(payloadData)
        .then((response) => {
          notifyToast(true, response.message)
        })
        .catch((error) => {
          console.log(error)
          notifyToast(false, error.message)
        })
        .finally(() => setLoading(false))
    } else {
      stavka.length === 0 && notifyToast(false, 'Select values from table')
      setLoading(false)
    }
  }

  const getTrakeData = (trakeId, rowId) =>
    fetchTable(trakeId)
      .then((response) => {
        console.log({response})
        formik.values.trake![rowId].duzina = response.TrenutnaDuzina
        formik.values.trake![rowId].tezina = response?.Tezina
        formik.setFieldValue('trake', formik.values.trake)
      })
      .catch((error) => notifyToast(false, error.message))

  const debouncedInv = useDebounce(formik.values.invbroj)
  useEffect(() => {
    if (debouncedInv) {
      sredstvoInfo({invBroj: debouncedInv})
        .then((response) => {
          setsredstvoInfoData(response!)
        })
        .catch((error) => notifyToast(false, error.response.data.message))
    }
  }, [debouncedInv])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Trake Info</h2>
      </div>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Form onSubmit={formik.handleSubmit} noValidate>
          <div className='row mb-10'>
            {/* begin: Odjel */}
            <div className='col-6 mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Odjel</label>
              <div className='col-lg-8 fv-row'>
                <RadniNaloziOdjeliDropdown
                  value={formik.values.Odjel}
                  onChange={(name, value) => formik.setFieldValue('Odjel', value)}
                  selectName='Odjel'
                />
              </div>
            </div>
            {/* end: Odjel */}

            <div className='row mb-10'>
              <div className='col-6'>
                <label className='form-label required'>Inv. broj mašine</label>
                <input
                  type='text'
                  placeholder='Inv Broj'
                  {...formik.getFieldProps('invbroj')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.invbroj && formik.errors.invbroj},
                    {
                      'is-valid': formik.touched.invbroj && !formik.errors.invbroj,
                    }
                  )}
                />
                {/* {formik.touched.invbroj && formik.errors.invbroj && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.invbroj}</div>
                  </div>
                )} */}
              </div>
              <div className='col-6 mt-5'>
                {sredstvoInfoData ? (
                  <label className='form-label border rounded border-success p-2'>
                    {sredstvoInfoData?.SredstvoNaziv}
                  </label>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* begin : dynamic rows */}
            <div className='row mb-6'>
              {Array.from({length: formik.values.trake!.length}, (_, i) => (
                <div className='row'>
                  {/* begin: Broj Trake */}
                  <div className='col-lg-6 col-md-6 mb-6'>
                    <label className='col-lg-8 col-form-label required fw-bold fs-6'>
                      Broj Trake
                    </label>
                    <div className='row justify-content-between'>
                      <div className='col-2'>
                        <QrCodeScanner
                          onScan={(data) => {
                            formik.values.trake![i].TrakaId = data
                            formik.setFieldValue('trake', formik.values.trake)
                          }}
                        />
                      </div>
                      <div className='col-8'>
                        <input
                          type='number'
                          placeholder='Broj Trake'
                          className={clsx('form-control form-control-lg form-control-solid')}
                          value={formik.values.trake![i].TrakaId}
                          onChange={(e) => {
                            formik.values.trake![i].TrakaId = e.target.value
                            formik.setFieldValue('trake', formik.values.trake)
                            getTrakeData(formik.values.trake![i].TrakaId, i)
                          }}
                        />
                      </div>
                    </div>

                    <label className='col-lg-8 col-form-label  fw-bold fs-6'>
                      Duzina: {formik.values.trake![i].duzina} Tezina:{' '}
                      {formik.values.trake![i].tezina}
                    </label>
                  </div>
                  {/* end: Broj Traka */}
                  {/* begin: Tezina */}
                  <div className='col-lg-3 col-md-6 mb-6'>
                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Težina</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Tezina'
                        className={clsx('form-control form-control-lg form-control-solid')}
                        value={formik.values.trake![i].KrajnjaKolicina}
                        onChange={(e) => {
                          formik.values.trake![i].KrajnjaKolicina = e.target.value
                          formik.setFieldValue('trake', formik.values.trake)
                        }}
                      />
                    </div>
                  </div>
                  {/* end: Tezina */}

                  <div className='col-lg-2 align-self-center mt-5'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm'
                      onClick={() => {
                        var trake = formik.values.trake ?? []

                        trake.splice(i, 1)

                        formik.setFieldValue('trake', trake)
                      }}
                    >
                      <KTIcon iconName='minus' className='fs-2' />
                    </button>
                  </div>
                </div>
              ))}
              <div className='col-lg-2 mb-6'>
                <button
                  type='button'
                  className='btn btn-primary btn-sm'
                  onClick={() => {
                    var trake = formik.values.trake ?? []
                    trake.push({
                      TrakaId: '',
                      KrajnjaKolicina: '',
                    })
                    formik.setFieldValue('trake', trake)
                  }}
                >
                  <KTIcon iconName='plus' className='fs-2' />
                </button>
              </div>
            </div>
            {/* end : dynamic rows */}
          </div>
        </Form>
      )}
    </div>
  )
})

export default PrometTraka
