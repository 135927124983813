/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import clsx from 'clsx'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const image = currentUser?.UposlenikSlikaPath
  const name = currentUser?.UposlenikImePrezime ?? ''
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
      data-popper-placement='bottom-start'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <a href='#'>
              {image ? (
                <div className='symbol-label'>
                  <img src={image} alt='' className='w-100' />
                </div>
              ) : (
                <div
                  className={clsx(
                    'symbol-label fs-3',
                    `bg-light-${name.charAt(0)}`,
                    `text-${name.charAt(0)}`
                  )}
                >
                  {name.charAt(0)}
                </div>
              )}
            </a>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.UposlenikImePrezime}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.UposlenikEmail}
            </a>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5 my-1'>
        <Link to={`uposlenici/izmjena/${currentUser?.UposlenikId}`} className='menu-link px-5'>
          Postavke
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
