/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import 'react-toastify/dist/ReactToastify.css'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import {useParams} from 'react-router-dom'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import {DozvoleAtesti} from '../../models/DozvoleAtesti'
import {fetchDokument, saveDokument, updateDokument} from '../../api/dozvoleAtesti'
import SredstvaDropdown from '../../components/SredstvaDropdown/SredstvaDropdown'
import DocumentComponent from '../../components/DocumentComponent/DocumentComponent'

interface DozvoleAtestiProps {
  // other potential props go here
}

const DozvoleAtestiDetails: React.FC<DozvoleAtestiProps> = () => {
  const {id} = useParams()
  const IdFromURL = Number(id)

  const [loading, setLoading] = useState(true)

  const initialValues = {
    DokNaziv: '',
    DokBroj: '',
    DokSredsvo: '',
    DokDatum: '',
    DokDatumIsteka: '',
    DokPrilog: '',
    DokNapomene: '',
    DokStatus: '',
    DokLokacija: '',
    DokKreirao: '',
  }
  const [initialData, setInitialData] = useState<Partial<DozvoleAtesti>>(initialValues)
  const [isFileValid, setisFileValid] = useState(true)
  const [file, setfile] = useState()

  useEffect(() => {
    if (IdFromURL) {
      fetchDokument(IdFromURL)
        .then((data) => {
          setInitialData(data)
          formik.resetForm({values: data}) // <-- reset formik state here
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [IdFromURL])

  const validationSchema = Yup.object({
    DokNaziv: Yup.string().min(4, 'Must be at least 4 characters').required('Required'),
    DokDatumIsteka: Yup.date().required('Required'),
  })
  const getFormData = (values) => {
    const formData = new FormData()
    if (file) {
      formData.append('document', file)
    }
    formData.append('DokNaziv', values.DokNaziv)
    formData.append('DokBroj', values.DokBroj)
    formData.append('DokSredsvo', values.DokSredsvo)
    formData.append('DokDatum', values.DokDatum)
    formData.append('DokDatumIsteka', values.DokDatumIsteka)
    formData.append('DokPrilog', values.DokPrilog)
    formData.append('DokNapomene', values.DokNapomene)
    formData.append('DokStatus', values.DokStatus)
    formData.append('DokLokacija', values.DokLokacija)
    formData.append('DokKreirao', values.DokKreirao)
    return formData
  }
  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      const formdata = getFormData(values)
      try {
        let response
        if (IdFromURL) {
          response = await updateDokument(formdata, IdFromURL)
        } else {
          response = await saveDokument(formdata)
        }

        if (response.error === 0) {
          notifyToast(true, response.message)
        } else {
          notifyToast(false, response.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  return (
    <div className='card mb-5 mb-xl-10'>
      <ToastContainer />
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <TitleComponent name='dozvola / atest' />
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        {loading ? (
          <LoadingComponent />
        ) : (
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className='card-body border-top p-9'>
              {/* Naziv */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Naziv</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    placeholder='Naziv dokumenta'
                    {...formik.getFieldProps('DokNaziv')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.DokNaziv && formik.errors.DokNaziv},
                      {
                        'is-valid': formik.touched.DokNaziv && !formik.errors.DokNaziv,
                      }
                    )}
                  />
                  {formik.touched.DokNaziv && formik.errors.DokNaziv && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.DokNaziv}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Naziv */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Broj dokumenta
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    placeholder='Broj dokumenta'
                    {...formik.getFieldProps('DokBroj')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.DokBroj && formik.errors.DokBroj},
                      {
                        'is-valid': formik.touched.DokBroj && !formik.errors.DokBroj,
                      }
                    )}
                  />
                  {formik.touched.DokBroj && formik.errors.DokBroj && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.DokBroj}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Sredstvo */}
              <div className='col mb-6'>
                <div className='row'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Sredstvo</label>
                  <div className='col-lg-8 fv-row'>
                    <SredstvaDropdown
                      value={formik.values.DokSredsvo}
                      onChange={(name, value) => formik.setFieldValue('DokSredsvo', value)}
                      errors={formik.errors.DokSredsvo}
                      touched={formik.touched.DokSredsvo}
                      selectName='DokSredsvo'
                    />
                  </div>
                </div>
              </div>
              {/* Naziv */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Datum</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='date'
                    placeholder='Datum'
                    {...formik.getFieldProps('DokDatum')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.DokDatum && formik.errors.DokDatum},
                      {
                        'is-valid': formik.touched.DokDatum && !formik.errors.DokDatum,
                      }
                    )}
                  />
                  {formik.touched.DokDatum && formik.errors.DokDatum && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.DokDatum}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* DokDatumIsteka */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Datum isteka
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='date'
                    placeholder='Datum isteka'
                    {...formik.getFieldProps('DokDatumIsteka')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.DokDatumIsteka && formik.errors.DokDatumIsteka},
                      {
                        'is-valid': formik.touched.DokDatumIsteka && !formik.errors.DokDatumIsteka,
                      }
                    )}
                  />
                  {formik.touched.DokDatumIsteka && formik.errors.DokDatumIsteka && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.DokDatumIsteka}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Lokacija dokumenta */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  Lokacija dokumenta
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    placeholder='Lokacija dokumenta'
                    {...formik.getFieldProps('DokLokacija')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.DokLokacija && formik.errors.DokLokacija},
                      {
                        'is-valid': formik.touched.DokLokacija && !formik.errors.DokLokacija,
                      }
                    )}
                  />
                  {formik.touched.DokLokacija && formik.errors.DokLokacija && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.DokLokacija}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Napomena */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Napomena</label>

                <div className='col-lg-8 fv-row'>
                  <textarea
                    placeholder='Napomena'
                    {...formik.getFieldProps('DokNapomene')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.DokNapomene && formik.errors.DokNapomene},
                      {
                        'is-valid': formik.touched.DokNapomene && !formik.errors.DokNapomene,
                      }
                    )}
                  />
                  {formik.touched.DokNapomene && formik.errors.DokNapomene && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.DokNapomene}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Napomena */}
              <div className='row mb-6'>
                <DocumentComponent
                  label='Document'
                  setFile={(val) => {
                    setfile(val)
                  }}
                  isFileValid={(val) => setisFileValid(val)}
                />
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={loading || formik.isSubmitting || !formik.isValid || !isFileValid}
              >
                {!loading && 'Potvrdi'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}
export default DozvoleAtestiDetails
