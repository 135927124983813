/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import TablePagination from '../../components/TablePagination/TablePagination'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import {
  SortingState,
  VisibilityState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {TableActionButton} from '../../components/TableActionBtn/TableActionButton'
import notifyToast from '../../components/Toast/Toast'
import {useNavigate} from 'react-router-dom'
import {Modal, ModalHeader} from 'react-bootstrap'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import {RolneUDolaskuModel} from '../../models/RolneUDolasku'
import {
  deleteRolneUDolasku,
  getAllRolneUDolasku,
  prebaciRolneNaStanje,
} from '../../api/rolneUDolasku'
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox/IndeterminateCheckbox'
import {ParamsModelReprmat} from '../../models/RepromaterijaliModel'
import ColumnSearch from '../../components/ColumnSearch/ColumnSearch'
import {PaginationModel} from '../../models/PaginationModel'
import {ToastContainer} from 'react-toastify'
import RolneUDolaskuHiddenColumns from '../../components/RolneUDolaskuHiddenColumns/RolneUDolaskuHiddenColumns'

const RolneUDolasku = () => {
  const [dolaskuData, setdolaskuData] = useState<RolneUDolaskuModel[]>([])
  const [loading, setLoading] = useState(true)
  const [pagination, setpagination] = useState<PaginationModel>({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  })

  const [params, setparams] = useState<ParamsModelReprmat>({
    per_page: 10,
    page: 1,
    brojRolne: '',
    kategorija: '',
    boja: '',
    debljina: '',
    sirina: '',
    kodBoje: '',
    materijal: '',
    kvalitet: '',
    proizvodjac: '',
    dobavljac: '',
    status: '',
    skladiste: '',
    lokacija: '',
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isModalOpen, setisModalOpen] = useState(false)
  const [isHiddenColumns, setisHiddenColumns] = useState(false)
  const [isSumaOpen, setisSumaOpen] = useState(false)
  const [suma, setSuma] = useState(0)
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [columnFilters, setColumnFilters] = useState([])
  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({
    Boja_b: false,
    Boja_nalicja: false,
    Struktura_b: false,
  })
  const [stavkaTextArea, setstavkaTextArea] = useState<string>()
  const [stavkaLoading, setstavkaLoading] = useState(false)

  // Suma.
  const handleSuma = () => {
    var sum = table.getRowModel().rows.reduce((total, row) => {
      const cur_val = row
        .getVisibleCells()
        .find((cell) => cell.column.id === 'kolicina')
        ?.getValue()
      // @ts-ignore
      return total + parseFloat(cur_val)
    }, 0)
    setSuma(sum)
  }

  // Set cell color according to condtions.
  const getCellColor = (cell, row) => {
    // Color for status
    if (cell.column.id === 'status') return row.original.status?.Boja
    // Color for skaldiste
    if (cell.column.id === 'skladiste') return row.original.skladiste?.SkladisteBoja ?? '#FFFFFF'
    // Color default
    return '#FFFFFF'
  }

  //prebaciRolneNaStanje
  const onClickPrebaci = () => {
    setstavkaLoading(true)
    const payload = {
      // Convert textarea to array of string.
      stavkaId: stavkaTextArea?.replaceAll(' ', '').split(','),
    }
    prebaciRolneNaStanje(payload)
      .then((response) => {
        console.log({response})
        notifyToast(true, response.message)
      })
      .catch((error) => notifyToast(false, error.message))
      .finally(() => {
        setstavkaLoading(false)
        setisModalOpen(false)
        setstavkaTextArea(undefined)
      })
  }
  const updatePage = () => {
    setLoading(true)
    getAllRolneUDolasku(params)
      .then((response) => {
        setdolaskuData(response.data['data'])
        const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
        setpagination({
          curPage: response.data['current_page'],
          totalPage: totalPage,
          perPage: params.per_page,
        })
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    // @ts-ignore
    setparams({...params, per_page: pagination.perPage, page: pagination.curPage})
    getAllRolneUDolasku(params).then((response) => {
      setdolaskuData(response.data['data'])
      const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
      setpagination({
        curPage: response.data['current_page'],
        totalPage: totalPage,
        perPage: pagination.perPage,
      })
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    const {id} = sorting[0] ?? ''
    dolaskuData.sort((a, b) => a[id] - b[id])
  }, [sorting])

  const columnHelper = createColumnHelper<RolneUDolaskuModel>()

  const garancijaColorMap = {
    NEDEFINISANO: 'badge badge-dark',
    'SUPERIOR - sa garancijom': 'badge badge-primary',
    'UNI - limitirana garancija': 'badge badge-warning',
    'PNU - paneli unutarnji lim': 'badge badge-success',
    'ECO - BEZ GARANCIJE': 'badge badge-danger',
  }
  const SNCell = ({row}) => (
      <div className='col'>
        <div><span className='text-primary'>{row.original.proizvodjac.PartnerNaziv}</span></div>
        <div>{row.original.ReprMatSeriski}</div>
        <div className={garancijaColorMap[row.original.stavka.ugovor.garancija?.MetaValue]}>
          {row.original.stavka.ugovor.garancija?.MetaValue ?? ''}
        </div>
      </div>
  )
  const columns = [
    {
      id: 'id',
      header: ({table}) => (
        <div className='row p-2'>
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      cell: ({row, getValue}) => (
        <div className='col'>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    {
      id: 'Stavkaid',
      header: 'Stavka id',
      accessorFn: (row) => row.stavka.Id.toString(),
    },
    {
      id: 'materijal',
      header: 'materijal',
      accessorKey: 'materijal.MetaValue',
    },
    {
      header: 'S/N',
      cell: SNCell,
      accessorKey: 'ReprMatSeriski',
    },
    {
      header: 'Debljina',
      accessorKey: 'ReprMatDebljina',
    },
    {
      header: 'Sirina',
      accessorKey: 'ReprMatSirina',
    },
    {
      id: 'kvalitet',
      header: 'kvalitet',
      accessorKey: 'kvalitet.MatKvalitetNaziv',
    },
    {
      header: 'zastita',
      accessorKey: 'zastita.MetaValue',
    },
    {
      header: 'Duzina',
      accessorKey: 'ReprMatDuzina',
    },
    {
      id: 'kodBoje',
      header: 'Kod Boje',
      accessorKey: 'kod_boje.KodBojeNaziv',
    },
    columnHelper.group({
      header: 'Strana A',
      // footer: props => props.column.id,
      columns: [
        columnHelper.accessor('struktura_lica.MetaValue', {
          id: 'Struktura_a',
          cell: (info) => info.getValue(),
          header: () => <span>struktura</span>,
        }),
        columnHelper.accessor('boja_lica.BojaNaziv', {
          id: 'Boja_lica',
          cell: (info) => info.getValue(),
          header: () => <span>boja lica</span>,
        }),
        columnHelper.accessor('debljina_lica.MetaValue', {
          id: 'Boja_a',
          cell: (info) => info.getValue(),
          header: () => <span>debljina</span>,
        }),
      ],
    }),
    columnHelper.group({
      header: 'Strana B',
      // footer: props => props.column.id,
      columns: [
        columnHelper.accessor('struktura_nalicja.MetaValue', {
          id: 'Struktura_b',
          cell: (info) => info.getValue(),
          header: () => <span>struktura</span>,
        }),
        columnHelper.accessor('boja_nalicja.BojaNaziv', {
          id: 'Boja_nalicja',
          cell: (info) => info.getValue(),
          header: () => <span>boja nalicja</span>,
        }),
        columnHelper.accessor('debljina_nalicja.MetaValue', {
          id: 'Boja_b',
          cell: (info) => info.getValue(),
          header: () => <span>debljina</span>,
        }),
      ],
    }),
    {
      id: 'kolicina',
      header: 'Kolicina',
      accessorKey: 'ReprMatTrenutnaKolicina',
    },
    {
      header: 'Akcija',
      cell: ({row}) => (
        <TableActionButton
          onDelete={() => onDelete(row.original.ReprMatId)}
          onEdit={() => {
            onEdit(row.original.ReprMatId)
          }}
        />
      ),
    },
  ]
  useEffect(() => {
    updatePage()
  }, [params])

  const table = useReactTable({
    data: dolaskuData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
      columnFilters: columnFilters,
      rowSelection: rowSelection,
      columnVisibility: columnVisibility,
    },
    // @ts-ignore
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
    onColumnVisibilityChange: setColumnVisibility,
  })
  const onDelete = (id) => {
    deleteRolneUDolasku(id)
      .then((response) => {
        setparams({...params, per_page: pagination.perPage, page: pagination.curPage})
        notifyToast(true, response.data['message'])
      })
      .catch((error) => {
        notifyToast(false, error.data['message'])
      })
  }
  const navigate = useNavigate()

  const onEdit = (rowData) => {
    navigate(`/repromaterijal/rolne-u-dolasku/izmjena/${rowData}`)
  }
  const onBulkUpload = (rowData) => {
    navigate(`/repromaterijal/rolne-u-dolasku/bulk-upload`)
  }

  return (
    <KTCard>
      <ToastContainer />

      {/* Modal for prebaciRolneNaStanje  */}
      <Modal show={isModalOpen} onHide={() => setisModalOpen(false)}>
        <ModalHeader closeButton={true} className='h3'>
          Prebaci repromaterijali na stanju
        </ModalHeader>
        <div className='col p-10'>
          <div className='col mb-6'>
            <textarea
              className='form-control'
              value={stavkaTextArea}
              onChange={(e) => setstavkaTextArea(e.target.value)}
            />
          </div>
          <div className='col-4'>
            <button
              className='btn btn-primary'
              disabled={stavkaLoading || !stavkaTextArea}
              onClick={onClickPrebaci}
            >
              {!stavkaLoading && 'Potvrdi'}
              {stavkaLoading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </Modal>
      {/* Modal to show suma */}
      <Modal show={isSumaOpen} onHide={() => setisSumaOpen(false)}>
        <ModalHeader closeButton={true} className='h3'>
          Suma: {suma} kg, {suma / 1000} t.
        </ModalHeader>
      </Modal>
      {/* Modal for hidden columns */}
      <RolneUDolaskuHiddenColumns
        isModalOpen={isHiddenColumns}
        setisModalOpen={setisHiddenColumns}
        setToggleColumns={setColumnVisibility}
      />
      <KTCardBody className='py-4'>
        <div className='d-flex justify-content-start'>
          <button className='btn btn-light-success me-3' onClick={() => setisModalOpen(true)}>
            <KTIcon iconName='calculator' className='fs-2' />
            Prebaci repromaterijale na stanje
          </button>
        </div>
        <div className='separator mt-4 mb-4'></div>
        <div className='d-flex justify-content-end'>
          <div data-kt-user-table-toolbar='base'>
            <button
              className='btn btn-light-info me-3 m-6'
              onClick={() => {
                setisHiddenColumns(true)
              }}
            >
              <KTIcon iconName='plus' className='fs-2' />
              Dodaj Kolone
            </button>
            <button
              className='btn btn-light-primary me-3'
              onClick={() => {
                handleSuma()
                setisSumaOpen(true)
              }}
            >
              <KTIcon iconName='calculator' className='fs-2' />
              Suma
            </button>
            <button className='btn btn-light-primary me-3' onClick={onBulkUpload}>
              <KTIcon iconName='up-square' className='fs-2' />
              Bulk Upload
            </button>
            <ExportButton table={table} filename={'RolneUDolasku'} />
          </div>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3 '>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0  p-2'
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          className='text-white text-center bg-dark p-2'
                          key={header.id}
                          colSpan={header.colSpan}
                          style={
                            header.column.id === 'id'
                              ? {
                                  position: 'sticky',
                                  left: '0',
                                  top: '0',
                                  zIndex: 1,
                                }
                              : {}
                          }
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              <span onClick={header.column.getToggleSortingHandler()}>
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {/* @ts-ignore */}
                                {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                              </span>
                              {header.column.getCanFilter() ? (
                                <div>
                                  <ColumnSearch
                                    onSearch={(text) => header.column.setFilterValue(text)}
                                  />
                                </div>
                              ) : null}
                            </>
                          )}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          // style={{
                          //   backgroundColor: getCellColor(cell, row),
                          // }}
                          style={
                            cell.column.id === 'id'
                              ? {
                                  position: 'sticky',
                                  left: '0',
                                  top: '0',
                                  zIndex: 0.8,
                                  backgroundColor: '#FFFFFF',
                                }
                              : {backgroundColor: getCellColor(cell, row)}
                          }
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <TablePagination
          updatePage={(curPage, perPage) =>
            setparams({...params, per_page: perPage, page: curPage})
          }
          pagination={pagination}
        />
      </KTCardBody>
    </KTCard>
  )
}

export default RolneUDolasku
