/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {KTCard, KTCardBody} from '../../../_websoft/helpers'
import TablePagination from '../../components/TablePagination/TablePagination'
import {FmkNaloziModel, ParamsModelFmkNalozi} from '../../models/FmkNaloziModel'
import {PaginationModel} from '../../models/PaginationModel'
import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import {
    arhivirajNalog,
    getFmkNalozi,
    naloziSpremniZaIsporuku,
    naloziUProizvodnji,
    saveFMKNalozi,
    updateFMKNalozi
} from '../../api/fmkNalozi'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import TableSearch from '../../components/TableSearch/TableSearch'
import moments from 'moment'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import FmkNaloziModal from './components/FmkNaloziModal'

import IndeterminateCheckbox from '../../components/IndeterminateCheckbox/IndeterminateCheckbox'

const FMKNaloziLista = () => {
    const [data, setData] = useState<FmkNaloziModel[]>([])
    const [loading, setLoading] = useState(true)
    const [brojNaloga, setBrojNaloga] = useState('');
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedId, setselectedId] = useState()
    const [isModalOpen, setisModalOpen] = useState(false)
    const [params, setparams] = useState<ParamsModelFmkNalozi>({per_page: 10, page: 1, arhiviran: 0})
    const [rowSelection, setRowSelection] = useState({})
    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')

    // Set cell color according to condtions.
    const getCellColor = (cell, row) => {
        // Color
        if (cell.column.id === 'Status') {
            return row.original.status.Boja
        }
        // Color default
        return '#FFFFFF'
    }

    const updatePage = () => {
        setLoading(true)
        getFmkNalozi(params)
            .then((response) => {
                const data = response.data
                setData(data)
                const totalPage = Math.floor(response['total'] / response['per_page'] + 1)
                console.log('toa', totalPage)
                setpagination({
                    curPage: response['current_page'],
                    totalPage: totalPage,
                    perPage: params.per_page,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        updatePage()
    }, [params])

    useEffect(() => {
        const {id} = sorting[0] ?? ''
        data.sort((a, b) => a[id] - b[id])
    }, [sorting])

    const columns = [
        {
            id: 'id',
            header: ({table}) => (
                <div className='row p-2'>
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </div>
            ),
            cell: ({row, getValue}) => (
                <div className='col'>
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),
        },
        {
            id: 'Broj Naloga',
            header: 'Broj Naloga',
            accessorKey: 'brojNaloga',
        },
        {
            id: 'Kupac',
            header: 'Kupac',
            accessorKey: 'Kupac',
        },

        {
            id: 'datumStampe',
            header: 'Štampan',
            accessorFn: (row) => moments(row.datumStampe).format('DD-MM-YYYY'),
        },
        {
            id: 'Isporuka',
            header: 'Isporuka',
            accessorKey: 'rokIsporuke',
        },
        {
            id: 'Napomena',
            header: 'Napomena',
            accessorKey: 'napomena',
        },
        {
            id: 'korisnik',
            header: 'Korisnik',
            accessorKey: 'korisnik',
        },
        {
            id: 'Status',
            header: 'Status',
            accessorKey: 'status.Naziv',
        },
        {
            id: 'Stavke ',
            header: 'Stavke',
            cell: ({row}) => (
                <button
                    className='btn btn-primary'
                    onClick={() => {
                        setselectedId(row.original.id)
                        setisModalOpen(true)
                    }}
                >
                    Pregled
                </button>
            ),
        },
    ]

    const handleUpisiFMKNalog = async () => {
        setLoading(true)
        try {
            const response = await saveFMKNalozi()
            notifyToast(true, response.message)
            updatePage()
        } catch (error: any) {
            // Handle the error here
            notifyToast(false, error)
            console.error('Error in handleUpisiNalog:', error)
        } finally {
            setLoading(false)
        }
    }
    const handleInputChange = (e) => {
        setBrojNaloga(e.target.value);
    };

    const handleUpdateFMKNalog = async () => {
        setLoading(true);

        try {
            // Assuming updateFMKNalozi function now accepts brojNaloga as a parameter
            const response = await updateFMKNalozi({ brojNaloga: brojNaloga });
            notifyToast(true, response.message);
            updatePage();
        } catch (error: any) {
            notifyToast(false, error.message || 'An error occurred');
            console.error('Error in handleUpdateFMKNalog:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleArhivirajNalog = async () => {
        setLoading(true)
        try {
            const payload = {
                stavkaId: table.getSelectedRowModel().rows.map((item) => item.original.id),
            }
            const response = await arhivirajNalog(payload)
            notifyToast(true, response.message)
            updatePage()
            setRowSelection({})
        } catch (error: any) {
            // Handle the error here
            notifyToast(false, error.message)
            console.error('Error in handleArhivirajNalog:', error)
        } finally {
            setLoading(false)
        }
    }

    const handleNalogUProizvodnji = async () => {
        setLoading(true)
        try {
            const payload = {
                stavkaId: table.getSelectedRowModel().rows.map((item) => item.original.id),
            }
            const response = await naloziUProizvodnji(payload)
            notifyToast(true, response.message)
            updatePage()
            setRowSelection({})
        } catch (error: any) {
            // Handle the error here
            notifyToast(false, error.message)
            console.error('Error in handlenaloziUProizvodnji:', error)
        } finally {
            setLoading(false)
        }
    }

    const handleSpremanZaIsporuku = async () => {
        setLoading(true)
        try {
            const payload = {
                stavkaId: table.getSelectedRowModel().rows.map((item) => item.original.id),
            }
            const response = await naloziSpremniZaIsporuku(payload)
            notifyToast(true, response.message)
            updatePage()
            setRowSelection({})
        } catch (error: any) {
            // Handle the error here
            notifyToast(false, error.message)
            console.error('Error in handlenaloziUProizvodnji:', error)
        } finally {
            setLoading(false)
        }
    }

    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
            rowSelection: rowSelection,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
        onRowSelectionChange: setRowSelection,
        enableRowSelection: true,
    })

    return (
        <KTCard>
            <ToastContainer/>
            {selectedId && (
                <FmkNaloziModal
                    isModalOpen={isModalOpen}
                    setisModalOpen={setisModalOpen}
                    selectedId={selectedId}
                />
            )}

            <KTCardBody className='py-4'>
                <div className='row mt-3 mb-6'>
                    <div className='col-8 mb-4'>
                        <h4>Izmjena statusa naloga | Upiši nalog</h4>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-lg-2 col-sm-6 mb-4'>
                        <button
                            type='button'
                            className='btn btn-block btn-danger float-right'
                            onClick={handleArhivirajNalog}
                        >
                            {!loading && 'Arhiviraj nalog'}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                          Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                    <div className='col-lg-2 col-sm-6'>
                        <button
                            type='button'
                            className='btn btn-block btn-warning'
                            onClick={handleNalogUProizvodnji}
                        >
                            {!loading && 'U proizvodnji'}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                    <div className='col-lg-2 col-sm-6'>
                        <button
                            type='button'
                            className='btn btn-block btn-success'
                            onClick={handleSpremanZaIsporuku}
                        >
                            {!loading && 'Spreman za isporuku FMK'}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                    <div className='col-lg-3 col-sm-6'>
                        <input
                            type='text'
                            className='form-control'
                            placeholder='Broj naloga'
                            value={brojNaloga}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='col-lg-3 col-sm-6'>
                        <button
                            type='button'
                            className='btn btn-block btn-light-primary'
                            onClick={handleUpdateFMKNalog}
                        >
                            {!loading && 'Upiši nalog'}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                            )}
                        </button>
                    </div>
                </div>
                <hr/>
                <div className='d-flex justify-content-between'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)}/>
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <button
                            type='button'
                            className='btn btn-block btn-primary'
                            onClick={handleUpisiFMKNalog}
                        >
                            {!loading && 'Osvježi naloge'}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                            )}
                        </button>
                        <ExportButton table={table} filename={'FMK_Nalozi'}/>
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}

export default FMKNaloziLista
