import {Font, Page, View, Image, Text, Document, StyleSheet} from '@react-pdf/renderer'
import {KreirajQRcodePalete} from '../../models/KreirajQrCodePalete'
import {useMemo} from 'react'
import logo from '../../../_websoft/assets/images/logo.jpg'

// Register font
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
})
// Create styles
const styles = StyleSheet.create({
  pdf: {width: '100vh', height: '500px'},
  page: {
    flexDirection: 'column',
    flex: 1,
    height: '300px',
    padding: 10,
  },
  section: {
    margin: 2,
    padding: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  section2: {
    margin: 2,
    padding: 2,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  section3: {
    margin: 2,
    padding: 2,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  section4: {
    margin: 20,
    padding: 2,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  qrCode: {
    margin: 2,
    padding: 2,
    width: '100px',
  },
  logo: {
    margin: 2,
    padding: 2,
    width: '200px',
  },

  text: {
    marginVertical: 2,
    marginHorizontal: 12,
    fontSize: 16,
    textAlign: 'justify',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  boldtext: {
    marginVertical: 2,
    marginHorizontal: 2,
    fontSize: 20,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    alignContent: 'center',
  },
})
interface Props {
  qrData: Array<KreirajQRcodePalete>
  qrList: Array<string>
}
const PaletePdf: React.FC<Props> = ({qrData, qrList}) => {
  const data = useMemo(() => ({qrData}), [qrData])

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Document>
      {data.qrData &&
        data.qrData.map((page, i) => (
          <Page size='A6' style={styles.page} orientation='landscape'>
            <Image src={logo} style={styles.logo} />
            <View style={styles.section}>
              {/* @ts-ignore */}
              <Image style={styles.qrCode} source={{uri: qrList[i]}} />
              <View style={styles.section2}>
                <Text style={styles.boldtext}>Paleta: {page.Id}</Text>
              </View>
            </View>
            <View style={styles.section3}>
              <Text style={styles.text}>Debljina: {page.Debljina}  | Širina: {page.Sirina}</Text>
              <Text style={styles.text}>Boja: {page.boja.BojaNaziv}</Text>
              <Text style={styles.text}>Kvalitet: {page.kvalitet.MatKvalitetNaziv}</Text>
              <Text style={styles.text}>Količina: {page.NaStanju} kom |  Dužina: {page.Duzina} m </Text>
            </View>
          </Page>
        ))}
    </Document>
  )
}

export default PaletePdf
