import axios from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {PakovanjaModel, ParamsModelPakovanja} from '../models/PakovanjaModel'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/pakovanja`
const API_DROPDOWN = `${API_URL}/pakovanjaDropdown`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: `Bearer ${auth?.access_token ?? ''}`}

// Get request for pakovanja Dropdown data.
export async function getPakovanjaDropdown() {
  return await axios.get<Array<Option>>(API_DROPDOWN, {headers: headers})
}

export async function getPakovanja(params: ParamsModelPakovanja) {
  return await axios.get<Array<PakovanjaModel>>(API, {headers: headers, params: params})
}

export async function savePakovanja(Data: any) {
  try {
    const response = await axios.post(API, Data, {headers: headers})

    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function fetchPakovanja(Id: number): Promise<Partial<PakovanjaModel>> {
  try {
    const response = await axios.get(`${API}/${Id}`)
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updatePakovanja(id: number, Data: any) {
  try {
    const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function deletePakovanja(Id: number) {
  try {
    const response = await axios.delete(`${API}/${Id}`, {headers: headers})
    if (response.data) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to delete.')
    }
  } catch (error) {
    console.error('Error deleting data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
