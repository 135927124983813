import {useFormik} from 'formik'
import {FC} from 'react'
import {Modal, ModalHeader} from 'react-bootstrap'

interface Props {
  isModalOpen: boolean
  setisModalOpen: Function
  setToggleColumns: Function
}
const initialValues = {
  Struktura_b: false,
  Boja_nalicja: false,
  Boja_b: false,
}
const RolneUDolaskuHiddenColumns: FC<Props> = ({
  isModalOpen = false,
  setisModalOpen,
  setToggleColumns,
}) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      setToggleColumns(values)
      setisModalOpen(false)
    },
  })

  return (
    <Modal show={isModalOpen} onHide={() => setisModalOpen(false)}>
      <ModalHeader closeButton={true} className='h3'>
        Toggle Columns
      </ModalHeader>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='row p-10 gy-5 '>
          <div className='form-check col-4 mb-6'>
            <input
              className='form-check-input'
              type='checkbox'
              id='flexCheckDefault'
              {...formik.getFieldProps('Boja_b')}
              defaultChecked={formik.values.Boja_b}
            />
            <label className='form-check-label fw-bold fs-6' htmlFor='flexCheckDefault'>
              Debljina
            </label>
          </div>
          <div className='form-check col-4 mb-6'>
            <input
              className='form-check-input'
              type='checkbox'
              id='flexCheckDefault'
              {...formik.getFieldProps('Boja_nalicja')}
              defaultChecked={formik.values.Boja_nalicja}
            />
            <label className='form-check-label fw-bold fs-6' htmlFor='flexCheckDefault'>
              Boja Naličja
            </label>
          </div>
          <div className='form-check col-4 mb-6'>
            <input
              className='form-check-input'
              type='checkbox'
              id='flexCheckDefault'
              {...formik.getFieldProps('Struktura_b')}
              defaultChecked={formik.values.Struktura_b}
            />
            <label className='form-check-label fw-bold fs-6' htmlFor='flexCheckDefault'>
              Struktura B
            </label>
          </div>

          <button type='submit' className='btn btn-primary' data-kt-menu-dismiss='true'>
            Toggle
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default RolneUDolaskuHiddenColumns
