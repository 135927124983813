import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {ParamsModelTable, Table} from '../models/Table'
import {Status} from '../models/Status'
import {ThermoBoardNalozi} from "../models/ThermoBoardNalozi";
import {ThermoBoardPaketiModel} from "../models/ThermoBoardPaketi";

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/thermoboard/paketi`
const REZERVISI_API = `${API_URL}/thermoboard/rezervisiPaket`
const PROIZVEDI_API = `${API_URL}/thermoboard/proizvediPaket`
const PROIZVEDI_QR_API = `${API_URL}/thermoboard/proizvediPaketQR`
const ISPORUCI_API = `${API_URL}/thermoboard/isporuciPaket`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getAllTBPaketi(params: ParamsModelTable) {
    return await axios.get<Array<ThermoBoardPaketiModel>>(API, {headers: headers, params: params})
}

export async function saveTBPaket(Data: any) {
    try {
        const response = await axios.post(API, Data, {headers: headers})
        if (response.data.error === 0) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function fetchTBPaket(Id: number): Promise<Partial<ThermoBoardNalozi>> {
    try {
        const response = await axios.get(`${API}/${Id}`)
        return response.data
    } catch (error: any) {
        console.error('Error fetching data:', error)
        return error.response.data // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function updateTBPaket(Data: any, id: number) {
    try {
        const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
        return response.data
    } catch (error: any) {
        console.error('Error fetching data:', error)
        return error.response.data // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function rezervisiThermoBoradPakete(data: any) {
    try {
        const response = await axios.put(REZERVISI_API, data, {headers: headers})
        return response.data
    } catch (error: any) {
        console.error('Error Updating data:', error)
        return error.response.data // Re-throw the error so it can be handled by the caller if necessary
    }
}
export async function isporuciThermoBoradPakete(data: any) {
    try {
        const response = await axios.put(ISPORUCI_API, data, {headers: headers})
        return response.data
    } catch (error: any) {
        console.error('Error Updating data:', error)
        return error.response.data // Re-throw the error so it can be handled by the caller if necessary
    }
}
export async function proizvediThermoBoradPakete(data: any) {
    try {
        const response = await axios.put(PROIZVEDI_API, data, {headers: headers})
        return response.data
    } catch (error: any) {
        console.error('Error Updating data:', error)
        return error.response.data // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function proizvediThermoBoradPaketeQR(data: any) {
    try {
        const response = await axios.put(PROIZVEDI_QR_API, data, {headers: headers})
        return response.data
    } catch (error: any) {
        console.error('Error Updating data:', error)
        return error.response.data // Re-throw the error so it can be handled by the caller if necessary
    }
}
export async function rezervisiThermoBoradPaketeQR(data: any) {
    try {
        const response = await axios.put(`${API_URL}/thermoboard/rezervisiPaketQR`, data, {headers: headers})
        return response.data
    } catch (error: any) {
        console.error('Error Updating data:', error)
        return error.response.data // Re-throw the error so it can be handled by the caller if necessary
    }
}
export async function isporuciThermoBoradPaketeQR(data: any) {
    try {
        const response = await axios.put(`${API_URL}/thermoboard/isporuciPaketQR`, data, {headers: headers})
        return response.data
    } catch (error: any) {
        console.error('Error Updating data:', error)
        return error.response.data // Re-throw the error so it can be handled by the caller if necessary
    }
}