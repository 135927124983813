/* eslint-disable react-hooks/exhaustive-deps */
import {HTMLProps, useEffect, useRef} from 'react'

function IndeterminateCheckbox({
                                   indeterminate,
                                   className = 'form-check-input',
                                   ...rest
                               }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = useRef<HTMLInputElement>(null!)

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
    }, [ref, indeterminate])

    return (
        <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input
                type='checkbox'
                ref={ref}
                className={className + ' cursor-pointer'}
                {...rest}
                data-kt-check='false'
                data-kt-check-target='.widget-9-check'
            />
        </div>
    )
}

export default IndeterminateCheckbox
