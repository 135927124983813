import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {ParamsModelArhiviranje} from '../models/PaketLezarina'
import {NaloziPanelaZaArhiviranje} from '../models/NaloziPanelaZaArhiviranje'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/paneli/nalogePotrebnoArhivirati`
const API_ARHIVA = `${API_URL}/paneli/arhivirajNaloge`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getAllNaloziZaArhiviranje(params: ParamsModelArhiviranje) {
  return await axios.get<Array<NaloziPanelaZaArhiviranje>>(API, {headers: headers, params: params})
}

export async function pokreniServisZaArhivuNaloga(){
  return await axios.post(API_ARHIVA, {headers: headers})
}
