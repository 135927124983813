/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import TablePagination from '../TablePagination/TablePagination'
import {KTCard, KTCardBody} from '../../../_websoft/helpers'
import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import notifyToast from '../Toast/Toast'
import {PaleteUlaziModel, ParamsModelPaleteUlazi} from '../../models/PaleteUlaziModel'
import {getPaleteUlazi} from '../../api/PaleteUlazi'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import {PaginationModel} from '../../models/PaginationModel'

const PaleteUlaziTableComponent = ({ refreshTable, setRefreshTable }) => {
    const [data, setData] = useState<PaleteUlaziModel[]>([])
    const [loading, setLoading] = useState(true)
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })

    const [params, setparams] = useState<ParamsModelPaleteUlazi>({per_page: 10, page: 1})

    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')

    useEffect(() => {
        if (table) {
            updatePage();
            setRefreshTable();
        }
    }, [refreshTable]);

    const updatePage = () => {
        setLoading(true)
        getPaleteUlazi(params)
            .then((response) => {
                console.log(response.data['data'])
                const data = response.data['data']
                setData(data)
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                setpagination({
                    curPage: response.data['current_page'],
                    totalPage: totalPage,
                    perPage: params.per_page,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error.response.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
        console.log(data)
    }

    useEffect(() => {
        updatePage()
    }, [params])

    useEffect(() => {
        const {id} = sorting[0] ?? ''
        data.sort((a, b) => a[id] - b[id])
    }, [sorting])
    const columns = [
        {
            id: 'Artikal',
            header: 'Artikal',
            accessorKey: 'artikal.ArtSifra',
        },
        {
            id: 'Paleta br.',
            header: 'Paleta br.',
            accessorKey: 'ArtPaletaId',
        },
        {
            id: 'Ulaz',
            header: 'Ulaz',
            accessorKey: 'ArtPaletaUlaz',
        },
        {
            id: 'Datum',
            header: 'Datum',
            accessorKey: 'ArtPaletaDatum',
        },
        {
            id: 'Korisnik',
            header: 'Korisnik',
            accessorKey: 'uposlenik.UposlenikImePrezime',
        },
    ]
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
    })
    return (
        <KTCard>
            <KTCardBody className='py-4'>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}

export default PaleteUlaziTableComponent
