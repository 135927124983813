import React, {useState} from 'react'
import {useZxing} from 'react-zxing'
import {Button} from 'react-bootstrap' // Import Bootstrap Button

interface QrCodeScannerProps {
  onScan: (data: string) => void
}

const QrCodeScanner: React.FC<QrCodeScannerProps> = ({onScan}) => {
  const [data, setData] = useState('')
  const [paused, setPaused] = useState(true) // Start with the scanner paused
  const [showScanner, setShowScanner] = useState(false) // Control visibility of the scanner

  const {ref} = useZxing({
    paused,
    onDecodeResult(result) {
      setData(result.getText().split(';')[0])
      onScan(result.getText().split(';')[0]) // Invoke the onScan prop with the decoded data
      setPaused(true) // Pause the camera
      setShowScanner(false) // Hide the scanner after successful scan
    },
    onDecodeError(error) {
      console.log(error)
    },
    onError(error) {
      console.error(error)
    },
  })

  const handleButtonClick = () => {
    setShowScanner(true)
    setPaused(false)
  }

  return (
    <div>
      <Button className='success' onClick={handleButtonClick}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          fill='currentColor'
          className='bi bi-qr-code-scan'
          viewBox='0 0 16 16'
        >
          <path d='M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5M.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5M4 4h1v1H4z' />
          <path d='M7 2H2v5h5zM3 3h3v3H3zm2 8H4v1h1z' />
          <path d='M7 9H2v5h5zm-4 1h3v3H3zm8-6h1v1h-1z' />
          <path d='M9 2h5v5H9zm1 1v3h3V3zM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8zm2 2H9V9h1zm4 2h-1v1h-2v1h3zm-4 2v-1H8v1z' />
          <path d='M12 9h2V8h-2z' />
        </svg>
      </Button>
      {showScanner && (
        <>
          <div
            style={{
              position: 'fixed',
              alignSelf: 'center',
              top: 10,
              zIndex: 1000,
            }}
            onClick={() => {
              setPaused(true)
              setShowScanner(false)
            }}
          >
            <i className='bi bi-dash-square-fill text-danger fs-5x ms-n1' />
          </div>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 800,
            }}
          >
            <video ref={ref} style={{width: '100%', height: '100%'}} />
          </div>
        </>
      )}
      {/*<input type='text' value={data} onChange={(e) => setData(e.target.value)} />*/}
    </div>
  )
}

export default QrCodeScanner
