import React, {useState} from 'react'
import {useFormik} from 'formik'
import {saveZapisiStavke} from '../../../../api/zapisiProizvodnje'
import notifyToast from '../../../../components/Toast/Toast'
import {ZapisiProizvodnjeModel} from '../../../../models/ZapisiProizvodnjeModel'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import {ToastContainer} from 'react-toastify'

interface Props {
    initialData: ZapisiProizvodnjeModel
}

const KontrolaKavliteta: React.FC<Props> = ({initialData}) => {
    const initialValues = {
        'KONTORLA-RADJENA-NA-KVADRATURI': undefined,
        'OVJERENO-KK': undefined,
        'UKLOPILI-PANELE-KONTROLA': undefined,
        'POTREBNA-KOREKCIJA-NA-PANELU-KONTROLA': undefined,
        'SIRINA-PJENE-ZAGA-KONTROLA': undefined,
        'DEBLJINA-PJENE-ZAGA-KONTROLA': undefined,
        'SIRINA-PJENE-HLADJENJE-KONTROLA': undefined,
        'DEBLJINA-PJENE-HLADJENJE-KONTROLA': undefined,
        'GORNJA-TABLA-KONTROLA': undefined,
        'DONJA-TABLA-KONTROLA': undefined,
        'AMBIJENTALNA-TEMPERATURA': undefined,
        'VLAZNOST-ZRAKA': undefined,
        'PROVJERILI DUŽINU I POKRIVNU ŠRINU': undefined,
        'KONTROLA-PAKETA-OD': undefined,
        'KONTROLA-PAKETA-DO': undefined,
        'KONTROLA-SVIH-PAKETA': undefined
    }

    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        initialValues: initialValues,
        // validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                let response
                let data: any = []
                // Format data as per required format of api.
                Object.entries(values).map(([key, value]) => {
                    if (value) {
                        const dataSet = {
                            IdZapisa: initialData.Id,
                            BrojNaloga: initialData.BrojNaloga,
                            MetaKey: key,
                            MetaValue: value,
                        }
                        data.push(dataSet)
                    }
                })
                response = await saveZapisiStavke(data)

                const responseData = response

                if (responseData.error === 0) {
                    notifyToast(true, responseData.message)
                } else {
                    notifyToast(false, responseData.message || 'An unexpected error occurred.')
                }
            } catch (error: any) {
                console.error('Error: ', error.response.data.message)
                notifyToast(false, error.response.data.message)
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        },
    })
    return (
        <div>
            <ToastContainer/>
            {loading ? (
                <LoadingComponent/>
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className='card kontrolaKvaliteta pb-4'>
                        <div className='p-5'>
                            <h5 className='mb-2'>Kontrola kvaliteta</h5>
                            <span className='badge bg-warning bg-opacity-1 text-white'>Dodaj</span>
                            <hr/>
                        </div>
                        <div className='card-body'>
                            <div className='row mt-3'>
                                <div className='col-lg-6'>
                                    <label className='col-form-label fw-bold fs-6'>
                                        {' '}
                                        Kontrola rađena na kvadraturi:{' '}
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        {...formik.getFieldProps('KONTORLA-RADJENA-NA-KVADRATURI')}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='row mt-4'>
                                    <div className='col-lg-4'>
                                        <label className='col-form-label fw-bold fs-6'> UKLOPILI-PANELE</label>
                                        <select
                                            className='form-control'
                                            {...formik.getFieldProps('UKLOPILI-PANELE-KONTROLA')}
                                        >
                                            <option value=''>Odaberi</option>
                                            <option value='DA'>DA</option>
                                            <option value='NE'>NE</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-4'>
                                        <label className='col-form-label fw-bold fs-6'> PROVJERILI DUŽINU I POKRIVNU
                                            ŠRINU</label>
                                        <select
                                            className='form-control'
                                            {...formik.getFieldProps('PROVJERILI DUŽINU I POKRIVNU ŠRINU')}
                                        >
                                            <option value=''>Odaberi</option>
                                            <option value='DA'>DA</option>
                                            <option value='NE'>NE</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-4'>
                                        <label className='col-form-label fw-bold fs-6'>
                                            {' '}
                                            POTREBNA KOREKCIJA NA PANELU
                                        </label>
                                        <select
                                            className='form-control'
                                            {...formik.getFieldProps('POTREBNA-KOREKCIJA-NA-PANELU-KONTROLA')}
                                        >
                                            <option value=''>Odaberi</option>
                                            <option value='DA'>DA</option>
                                            <option value='NE'>NE</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-lg-6'>
                                        <label className='col-form-label fw-bold fs-6'> Širina pjene (žaga) </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Širina pjene (žaga)'
                                            {...formik.getFieldProps('SIRINA-PJENE-ZAGA-KONTROLA')}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label className='col-form-label fw-bold fs-6'> Debljina pjene (žaga) </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Debljina pjene (zaga)'
                                            {...formik.getFieldProps('DEBLJINA-PJENE-ZAGA-KONTROLA')}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-lg-6'>
                                        <label className='col-form-label fw-bold fs-6'> Širina pjene (hlađenje) </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Širina pjene (hlađenje)'
                                            {...formik.getFieldProps('SIRINA-PJENE-HLADJENJE-KONTROLA')}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label className='col-form-label fw-bold fs-6'>
                                            {' '}
                                            Debljina pjene (hlađenje){' '}
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Debljina pjene (hlađenje)'
                                            {...formik.getFieldProps('DEBLJINA-PJENE-HLADJENJE-KONTROLA')}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-lg-6'>
                                        <label className='col-form-label fw-bold fs-6'> Gornja tabla [mm] </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Gornja tabla'
                                            {...formik.getFieldProps('GORNJA-TABLA-KONTROLA')}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label className='col-form-label fw-bold fs-6'> Donja tabla [mm] </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder=' Donja tabla'
                                            {...formik.getFieldProps('DONJA-TABLA-KONTROLA')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-lg-6'>
                                    <label className='col-form-label fw-bold fs-6'> Ovjereno KK: </label>
                                    <select className='form-control' {...formik.getFieldProps('OVJERENO-KK')}>
                                        <option value=''>ODABERI</option>
                                        <option value='DA'>DA</option>
                                        <option value='NE'>NE</option>
                                    </select>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='row mt-3 mb-5'>
                                    <div className='col-lg-6'>
                                        <label className='col-form-label fw-bold fs-6'> Ambijentalna
                                            temperatura </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Ambijentalna temperatura'
                                            {...formik.getFieldProps('AMBIJENTALNA-TEMPERATURA')}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <label className='col-form-label fw-bold fs-6'> Vlažnost zraka </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder=' Vlaznost zraka'
                                            {...formik.getFieldProps('VLAZNOST-ZRAKA')}
                                        />
                                    </div>
                                </div>
                                <hr/>
                                <hr/>
                              <div className='row mt-3'>
                                <div className='col-lg-12'>
                                  <h3>Kontrolor popunjava na kraju svoje radne smjene i na kraju svakog
                                    završenog naloga.</h3>
                                </div>
                                <div className='col-lg-6'>
                                  <label className='col-form-label fw-bold fs-6'> Kontrolisao sam sve pakete: </label>
                                  <select className='form-control' {...formik.getFieldProps('KONTROLA-SVIH-PAKETA')}>
                                    <option value=''>ODABERI</option>
                                    <option value='DA'>DA</option>
                                    <option value='NE'>NE</option>
                                  </select>
                                </div>
                                <div className='col-lg-6'>
                                  <h3 className='mt-10'>Ukoliko ste odabrali opciju "DA", ne treba upisivat pakete ispod.</h3>
                                </div>
                                <div className='col-lg-12 mt-3 mb-3'>
                                    <hr/>
                                </div>
                                <div className='col-lg-6'>
                                  <label className='col-form-label fw-bold fs-6'> Kontrolisao pakete od </label>
                                  <input
                                      type='text'
                                      className='form-control'
                                      placeholder='Paket od'
                                      {...formik.getFieldProps('KONTROLA-PAKETA-OD')}
                                  />
                                </div>
                                <div className='col-lg-6'>
                                  <label className='col-form-label fw-bold fs-6'> Kontrolisao pakete do </label>
                                  <input
                                      type='text'
                                      className='form-control'
                                      placeholder='Paket do'
                                      {...formik.getFieldProps('KONTROLA-PAKETA-DO')}
                                  />
                                </div>
                              </div>
                            </div>
                        </div>
                      <div className='row mt-4'>
                        <div className='col-lg-3 offset-9'>
                          <button
                              type='submit'
                              className='btn btn-block btn-primary submitBtn'
                              name='dodajKontrolu'
                              id='submitBtn'
                          >
                            Potvrdi <i className='ph-paper-plane-tilt ms-2'></i>
                          </button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </div>
    )
}

export default KontrolaKavliteta
