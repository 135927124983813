import axios from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
const DROPDOWN_API = `${API_URL}/kodBojeDropdown`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

// Get request for kodBoje Dropdown data.
export async function getKodBojeDropdown() {
  try {
    return await axios.get<Array<Option>>(DROPDOWN_API, {headers: headers})
  } catch (error) {
    console.error('Error fetching kodBoje Dropdown data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
