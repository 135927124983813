import React, {useState, useEffect} from 'react'
import {
    flexRender,
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    getFilteredRowModel,
} from '@tanstack/react-table'
import TablePagination from '../../components/TablePagination/TablePagination'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import {useNavigate} from 'react-router-dom'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import {PaginationModel} from '../../models/PaginationModel'
import {PaketLezarina} from "../../models/PaketLezarina";
import {getAllLezarinaPaketa, getLezarinaPaketaZaKupcaNaDatum} from "../../api/lezarinaPaketa";
import KupciPanelaDropdown from "./components/KupciPanelaDropdown/KupciPanelaDropdown";
import {usePDF} from "@react-pdf/renderer";
import LezarinaPdf from "./components/LezarinaPdf/LezarinaPdf";

const LezarinaPaketa:React.FC = () => {
    const [data, setData] = useState<PaketLezarina[]>([])
    const [loading, setLoading] = useState(true)
    const [kupci, setkupci] = useState<any>()
    const [inoKupac, setInoKupac] = useState('');
    const [datum, setDatum] = useState('');
    const [lezarinaData, setLezarinaData] = useState(null);
    const [instance, updateInstance] = usePDF({})

    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })

    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')

    const updatePage = (pageNo, perPage) => {
        setLoading(true)
        getAllLezarinaPaketa(pageNo, perPage)
            .then((response) => {
                setData(response.data['data'])
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                setpagination({
                    curPage: response.data['current_page'],
                    totalPage: totalPage,
                    perPage: perPage,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getAllLezarinaPaketa(pagination.curPage, pagination.perPage).then((response) => {
            setData(response.data['data'])
            const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
            setpagination({
                curPage: response.data['current_page'],
                totalPage: totalPage,
                perPage: pagination.perPage,
            })
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        const {id} = sorting[0] ?? ''
        data.sort((a, b) => a[id] - b[id])
    }, [sorting])

    const columns = [
        {
            header: 'Id',
            accessorKey: 'id',
        },
        {
            header: 'Nalog',
            accessorKey: 'brojNaloga',
        },
        {
            header: 'Stavka',
            accessorKey: 'stavka',
        },
        {
            header: 'Ponuda',
            accessorKey: 'brojPonude',
        },
        {
            header: 'Kupac',
            accessorKey: 'kupac',
        },
        {
            header: 'Paket',
            accessorKey: 'paket',
        },
        {
            header: '+ Dana',
            accessorKey: 'lezaoDana',
        },
        {
            header: 'Cijena',
            accessorKey: 'cijena',
        },
        {
            header: 'Nalog zavrsen',
            accessorKey: 'zavrsenDatum',
        },
        {
            header: 'Paket isporučen',
            accessorKey: 'paket_info.isporucenDatum',
        },
    ]
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
    })

    const navigate = useNavigate()

    const obracunajLezarinu = async () => {
        try {
            const data = await getLezarinaPaketaZaKupcaNaDatum(kupci.value, datum);
            if (data && data.data.length > 0) { // Check if data is defined and has elements
                setLezarinaData(data);
                console.log(data, 'data')
                updateInstance(<LezarinaPdf qrData={data} inoKupac={inoKupac} />);
            } else {
                notifyToast(false, "Nema obračunata lezarina na odabrani datum");
            }
        } catch (error) {
            console.error("Error when calculating lezarina:", error);
            notifyToast(false, "Failed to fetch lezarina data");
        }
    }
    useEffect(() => {
        if (!instance.loading && instance.url) {
            window.open(instance.url, '_blank')?.focus()
        }
    }, [instance])
    return (
        <KTCard>
            <ToastContainer />
            <KTCardBody className='py-4'>
                <div className='row'>
                    <h3 className='col-lg-12'>Obračnaj ležarinu za kupca na dan: </h3>
                    <code className='col-lg-12'><h4 className='text-danger'>Obračun ležarine za kupca nemoj raditi na trenutni dan, ukoliko svi paketi za taj dan nisu isporuceni.</h4></code>
                    <div className='col-5 mt-4'>
                        <KupciPanelaDropdown
                            value={kupci}
                            onChange={(name, value) => setkupci({name: name, value: value})}
                            selectName='Kupci'
                        />
                    </div>
                    <div className='col-2 mt-4'>
                        <select
                            className='form-control'
                            value={inoKupac}
                            onChange={(e)=> setInoKupac(e.target.value)}
                        >
                            <option value=''>INO KUPAC</option>
                            <option value='DA'>DA</option>
                            <option value='NE'>NE</option>
                        </select>
                    </div>

                    <div className='col-3 mt-4'>
                        <input
                            type="date"
                            className='form-control'
                            value={datum}
                            onChange={(e) => setDatum(e.target.value)} />
                    </div>
                    <div className='col-2 mt-4'>
                        <button
                            className='btn btn-success'
                            onClick={obracunajLezarinu}
                        >
                            {!loading && (
                                <>
                                    <KTIcon iconName='truck' className='fs-1'/>
                                    <br/>
                                    Pregled
                                </>
                            )}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                    <div className='separator mt-5 mb-5'></div>
                </div>
                <div className='d-flex justify-content-between mt-5'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)} />
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <ExportButton table={table} filename={'lezarina_paketa'} />
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent />
                ) : (

                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination updatePage={updatePage} pagination={pagination} />
            </KTCardBody>
        </KTCard>
    )
}
export default LezarinaPaketa;
