import axios from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {ParamsModelZahtjevZaNabavu, ZahtjevZaNabavu} from '../models/ZahtevZaNabavu'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/zahtjevZaNabavu`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getAllZahtjevZaNabavu(params: ParamsModelZahtjevZaNabavu) {
  return await axios.get<Array<ZahtjevZaNabavu>>(API, {headers: headers, params: params})
}

export async function saveZahtjevZaNabavu(Data: any) {
  try {
    const response = await axios.post(API, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      return response.data.message || 'Failed to fetch.'
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    return error // Re-throw the error so it can be handled by the caller if necessary
  }
}

// @ts-ignore
export async function fetchZahtjevZaNabavu(Id: number): Promise<Partial<ZahtjevZaNabavu>> {
  try {
    const response = await axios.get(`${API}/${Id}`)
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      return response.data.message || 'Failed to fetch.'
    }
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export async function updateZahtjevZaNabavu(Data: any, id: number) {
  try {
    const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      return response.data.message || 'Failed to fetch.'
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    return error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function deleteZahtjevZaNabavu(Id: number) {
  try {
    const response = await axios.delete(`${API}/${Id}`, {headers: headers})
    if (response.data) {
      return response
    } else {
      // Handle any other type of response structure or errors returned from the server here
      return response.data.message || 'Failed to delete.'
    }
  } catch (error) {
    console.error('Error deleting data:', error)
    return error // Re-throw the error so it can be handled by the caller if necessary
  }
}
