import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import React, {useEffect, useMemo, useState} from 'react'
import {Modal, ModalHeader} from 'react-bootstrap'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import {ZapisiStavkeModel} from '../../../../models/ZapisiProizvodnjeModel'
import {getZapisiStavke} from '../../../../api/zapisiProizvodnje'
import notifyToast from '../../../../components/Toast/Toast'
import moments from 'moment'

const StavkeModal = ({isModalOpen, setisModalOpen, idZapisa}) => {
  const [loading, setLoading] = useState(false)
  const id = useMemo(() => idZapisa, [idZapisa])
  const [data, setData] = useState<ZapisiStavkeModel[]>([])

  useEffect(() => {
    setLoading(true)
    const params = {idZapisa: id}
    getZapisiStavke(params)
      .then((response) => {
        const data = response.data['data']
        setData(data)
      })
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => setLoading(false))
  }, [id])

  const columns = [
    {
      id: 'Stavka',
      header: 'Stavka',
      accessorKey: 'MetaKey',
    },
    {
      id: 'Vrijednost',
      header: 'Vrijednost',
      accessorKey: 'MetaValue',
    },
    {
      id: 'uposlenik',
      header: 'uposlenik',
      accessorKey: 'uposlenik.UposlenikImePrezime',
    },
    {
      id: 'Kreirano',
      header: 'Kreirano',
      accessorFn: (row) => `${moments(row.Kreirano).format('DD-MM-YYYY H:M:SS')}`,
    },
  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Modal className='modal-lg' show={isModalOpen} onHide={() => setisModalOpen(false)}>
      <ModalHeader closeButton={true} className='h3'>
        Stavke
      </ModalHeader>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className='table-responsive mt-3 p-10'>
          <table className='table align-middle table-bordered gy-5 no-footer'>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      className='text-white'
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {/* @ts-ignore */}
                      {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='text-gray-600'>
              {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </Modal>
  )
}

export default StavkeModal
