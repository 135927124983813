import React, {useState} from 'react'
import * as Yup from 'yup'
import {ToastContainer} from 'react-toastify'
import {useFormik} from 'formik'
import clsx from 'clsx'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import notifyToast from '../../components/Toast/Toast'
import {
  isporuciThermoBoradPakete,
  proizvediThermoBoradPakete,
  rezervisiThermoBoradPakete,
} from '../../api/thermoBoardPaketi'

type Data = {
  stavkaId: number[]
  kupac?: string
}

const UpdateStatusComponent = ({table, update}) => {
  const initialValues = {
    Kupac: undefined,
  }

  const [loading, setLoading] = useState(false)
  const [initialData, setinitialData] = useState(initialValues)
  const [activeButton, setActiveButton] = useState('')

  const validationSchema = Yup.object({
    Kupac: Yup.string().required('Required Kupac'),
  })

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType)
    formik.submitForm()
  }

  function getValidationSchema(buttonType) {
    // Return a different validation schema based on the button clicked
  }

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: getValidationSchema(activeButton), // Function that returns schema based on activeButton
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      const selectedRows = table.getSelectedRowModel().rows.map((row) => row.original.Id)
      try {
        let response

        let data: Data = {
          stavkaId: selectedRows,
        }
        if (activeButton !== 'proizvedi') {
          data.kupac = values.Kupac
        }
        if (activeButton === 'rezervisi') {
          response = await rezervisiThermoBoradPakete(data)
        } else if (activeButton === 'isporuci') {
          response = await isporuciThermoBoradPakete(data)
        } else if (activeButton === 'proizvedi') {
          response = await proizvediThermoBoradPakete(data)
        }

        if (response.error === 0) {
          notifyToast(true, response.message)
          update()
        } else {
          notifyToast(false, response.message || 'An unexpected error occurred.')
        }
      } catch (error) {
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  return (
    <div className='card mb-5 mb-xl-10'>
      <ToastContainer />
      {loading ? (
        <LoadingComponent />
      ) : (
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='row mb-5'>
            <div className='col-lg-3 col-xs-9'>
              <h2 className='fw-bold'>Kupac</h2>
              <input
                type='text'
                placeholder='kupac'
                className='form-control'
                {...formik.getFieldProps('Kupac')}
              />
            </div>
            <div className='col-lg-2 col-sm-3'>
              <button
                type='button'
                className='btn btn-primary mt-10'
                onClick={() => handleButtonClick('rezervisi')}
              >
                Rezerviši
              </button>
            </div>
            <div className='col-lg-2 col-sm-6'>
              <button
                type='button'
                className='btn btn-success mt-10'
                onClick={() => handleButtonClick('isporuci')}
              >
                Isporuči
              </button>
            </div>
            <div className='col-lg-2 offset-lg-2 col-xs-6'>
              <button
                type='button'
                className='btn btn-warning mt-10'
                onClick={() => handleButtonClick('proizvedi')}
              >
                Proizvedi pakete
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}
export default UpdateStatusComponent
