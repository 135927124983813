/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from 'react'
import {KTCard, KTCardBody} from '../../../../_websoft/helpers'
import {ToastContainer} from 'react-toastify'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
import {RolneZaProizvodnjuModel} from '../../../models/RolneZaProizvodnju'
import {
  getRolneUProizvodnju,
  getRolneZaProizvodnju,
  updateProizvediRolnu,
} from '../../../api/rolneProizvodnju'
import notifyToast from '../../../components/Toast/Toast'
import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import KrajRasjecanjeModal from './components/KrajRasjecanjeModal'
import {useParams} from 'react-router-dom'

const Rasjecanje = () => {
  const {odjelId} = useParams()
  const id = useMemo(() => Number(odjelId), [odjelId])
  const [dataTable1, setDataTable1] = useState<RolneZaProizvodnjuModel[]>([])
  const [dataTable2, setDataTable2] = useState<RolneZaProizvodnjuModel[]>([])
  const [loading, setLoading] = useState(true)
  const [isModalOpen, setisModalOpen] = useState(false)
  const [krajData, setKrajData] = useState()

  const getStatusData = (status) => {
    if (status === 0)
      return <span className='col-auto badge bg-warning bg-opacity-1 text-white'>Na čekanju</span>
    else
      return (
        <span className='col-auto badge bg-success bg-opacity-1 text-white'>U proizvodnji</span>
      )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   Data for table 1
  const updateTable1 = () => {
    setLoading(true)
    getRolneZaProizvodnju({odjelId: id})
      .then((response) => {
        const data = response.data['data']
        setDataTable1(data)
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  //   Data for table 2
  const updateTable2 = () => {
    setLoading(true)
    getRolneUProizvodnju({odjelId: id})
      .then((response) => {
        const data = response.data['data']
        setDataTable2(data)
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  //   Initialize both tables
  useEffect(() => {
    updateTable1()
    updateTable2()
  }, [])

  const onClickStart = (rolnaId) => {
    updateProizvediRolnu({rolnaId})
      .then((response) => notifyToast(true, response.message))
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => {
        updateTable1()
        updateTable2()
      })
  }

  const columnsTable1 = [
    {
      id: 'RŠ#',
      header: 'RŠ#',
      accessorKey: 'Id',
    },
    {
      id: 'Z',
      header: 'Z-',
      accessorKey: 'ZahtjevID',
    },
    {
      id: 'Broj rolne',
      header: 'R-Rolna',
      accessorKey: 'BrojRolne',
    },
    {
      id: 'Šir/Dužina',
      header: 'Šir/Dužina',
      cell: ({row}) => row.original.Sirina ?? row.original.Duzina,
    },
    {
      id: 'Paleta',
      header: 'Paleta',
      cell: ({row}) => <span className='badge badge-danger'>{row.original.Pakovanje}</span>,
    },
    {
      id: 'Napomena',
      header: 'Napomena',
      accessorKey: 'Napomena',
    },

    {
      id: 'Status',
      header: 'Status',
      cell: ({row}) => getStatusData(row.original.Status),
    },
    {
      id: 'btn',
      header: '',
      cell: ({row}) => (
        <button className='btn btn-success btn-sm' onClick={() => onClickStart(row.original.Id)}>
          START
        </button>
      ),
    },
  ]
  const columnsTable2 = [
    {
      id: 'RŠ#',
      header: 'RŠ#',
      accessorKey: 'Id',
    },
    {
      id: 'Broj rolne',
      header: 'R-Rolna',
      accessorKey: 'BrojRolne',
    },
    {
      id: 'Šir/Dužina',
      header: 'Šir/Dužina',
      cell: ({row}) => row.original.Sirina ?? row.original.Duzina,
    },
    {
      id: 'Status',
      header: 'Status',
      cell: ({row}) => getStatusData(row.original.Status),
    },
    {
      id: 'btn',
      header: '',
      cell: ({row}) => (
        <button
          className='btn btn-primary btn-sm'
          onClick={() => {
            setKrajData(row.original)
            setisModalOpen(true)
          }}
        >
          KRAJ
        </button>
      ),
    },
  ]

  //   Table 1
  const table1 = useReactTable({
    data: dataTable1,
    columns: columnsTable1,
    getCoreRowModel: getCoreRowModel(),
  })

  //   Table 2
  const table2 = useReactTable({
    data: dataTable2,
    columns: columnsTable2,
    getCoreRowModel: getCoreRowModel(),
  })
  return (
    <div className='row justify-content-between'>
      <ToastContainer />
      {krajData && (
        <KrajRasjecanjeModal
          isModalOpen={isModalOpen}
          setisModalOpen={setisModalOpen}
          data={krajData}
          update={updateTable2}
        />
      )}
      <div className='col-6 px-2'>
        <KTCard>
          <div className='col p-5'>
            <h5 className='col-auto mb-2'>Stavke</h5>
            <span className='col-auto badge bg-warning bg-opacity-1 text-white'>Na čekanju</span>
            <hr />
          </div>
          <KTCardBody className='py-4'>
            {loading ? (
              <LoadingComponent />
            ) : (
              <div className='table-responsive'>
                <table className='table align-middle  no-footer'>
                  <thead className='border-bottom-2'>
                    {table1.getHeaderGroups().map((headerGroup) => (
                      <tr
                        key={headerGroup.id}
                        className='text-start fw-bolder fs-7 text-uppercase gs-0  p-2'
                      >
                        {headerGroup.headers.map((header) => (
                          <th
                            // className='text-white'
                            key={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {/* @ts-ignore */}
                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className='text-gray-600'>
                    {table1.getRowModel().rows && table1.getRowModel().rows.length > 0 ? (
                      table1.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          ))}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No matching records found
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </KTCardBody>
        </KTCard>
      </div>
      <div className='col-6 px-2'>
        <KTCard>
          <div className='col p-5'>
            <h5 className='col-auto mb-2'>Stavka za {id === 1 ? 'rasjecanje' : 'odmotavanje'}</h5>
            <span className='col-auto badge bg-success bg-opacity-1 text-white'>U proizvodnji</span>
            <hr />
          </div>
          <KTCardBody className='py-4'>
            {loading ? (
              <LoadingComponent />
            ) : (
              <div className='table-responsive'>
                <table className='table align-middle  no-footer'>
                  <thead className='border-bottom-2'>
                    {table2.getHeaderGroups().map((headerGroup) => (
                      <tr
                        key={headerGroup.id}
                        className='text-start fw-bolder fs-7 text-uppercase gs-0  p-2'
                      >
                        {headerGroup.headers.map((header) => (
                          <th
                            // className='text-white'
                            key={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {/* @ts-ignore */}
                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className='text-gray-600'>
                    {table2.getRowModel().rows && table2.getRowModel().rows.length > 0 ? (
                      table2.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          ))}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No matching records found
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </KTCardBody>
        </KTCard>
      </div>
    </div>
  )
}

export default Rasjecanje
