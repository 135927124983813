/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import SearchableSelect from '../SearchableSelect/SearchableSelect'
import {Option} from '../../models/Sektor'
import {getPrijavaKvaraDropdown} from "../../api/prijavaKvara";

interface DropdownProps {
    value?: string | number
    initialValue?: string | number
    onChange?: (name: string, value: any) => void
    errors?: string | undefined
    touched?: boolean
    selectName?: string
}

const PrijavaKvaraDropdown: React.FC<DropdownProps> = ({
                                                                  value,
                                                                  initialValue,
                                                                  onChange,
                                                                  errors,
                                                                  touched,
                                                                  selectName,
                                                              }) => {
    const [prijavaKvaraDropdown, setPrijavaKvaraDropdown] = useState<Array<Option>>([])
    const internalValue = initialValue ?? null

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getPrijavaKvaraDropdown()
                setPrijavaKvaraDropdown(mapData(response.data))
            } catch (error) {
                console.error('Error fetching Povrsinska Struktura dropdown data:', error)
            }
        }
        fetchData()
    }, [])

    const finalValue = value ?? internalValue

    const mapData = (data) => {
        if (!data) {
            return []
        }
        return data.map((item) => ({label: item.Naziv, value: item.Id.toString()}))
    }

    return (
        <SearchableSelect
            options={prijavaKvaraDropdown}
            value={finalValue?.toString() ?? ''}
            onChange={onChange!}
            name={selectName ?? 'prijavaKvaraDropdown'}
            errors={errors}
            placeholder='Odaberi Prijavu Kvara.'
            touched={touched}
        />
    )
}

export default PrijavaKvaraDropdown
