import axios from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {ParamsModelPromet, RepromaterijalPromet} from '../models/RepromaterijaliPromet'
const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/repromaterijalPromet`
const API_DIREKTAN_IZLAZ = `${API_URL}/direktanIzlazRolne`
const API_IZVJESTAJ = `${API_URL}/promet/izvjestajPoMjesecu`
const API_IZVJESTAJ2 = `${API_URL}/promet/izvjestajPoGodini`

const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getAllRepromaterijalPromet(params: ParamsModelPromet) {
  return await axios.get<Array<RepromaterijalPromet>>(API, {headers: headers, params: params})
}

export async function saveRepromaterijalPromet(Data: any) {
  try {
    const response = await axios.post(API, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function direktanIzlazRolne(Data: any) {
  try {
    const response = await axios.post(API_DIREKTAN_IZLAZ, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function fetchRepromaterijalPromet(
  Id: number
): Promise<Partial<RepromaterijalPromet>> {
  try {
    const response = await axios.get(`${API}/${Id}`)
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateRepromaterijalPromet(Data: any, id: number) {
  try {
    const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function deleteRepromaterijalPromet(Id: number) {
  try {
    const response = await axios.delete(`${API}/${Id}`, {headers: headers})

    // Check if the response status is success
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle the error based on the message from the server
      return response.data
    }
  } catch (error) {
    console.error('Error deleting data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
export async function izvjestajPoMjesecu() {
  try {
    const response = await axios.get(`${API_IZVJESTAJ}`, {headers: headers})
    if (response.status === 200) {
      return response.data; // Make sure you return the actual data here
    } else {
      console.error('API call returned an error:', response);
      return null;
    }
  } catch (error) {
    console.error('Error in API call:', error);
    throw error;
  }
}

export async function izvjestajPoGodini() {
  try {
    const response = await axios.get(`${API_IZVJESTAJ2}`, {headers: headers})
    if (response.status === 200) {
      return response.data; // Make sure you return the actual data here
    } else {
      console.error('API call returned an error:', response);
      return null;
    }
  } catch (error) {
    console.error('Error in API call:', error);
    throw error;
  }
}
