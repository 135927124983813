/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect} from 'react'
import {AktivnePaleteModel} from '../../models/AktivnePaleteModel'
import AktivnePaletaPdf from './AktivnePaletaPdf'
import {usePDF} from '@react-pdf/renderer'
import QRCode from 'qrcode'
import {KTIcon} from '../../../_websoft/helpers'
interface Props {
  data: AktivnePaleteModel
}
const AktivnePaletaQrCode: FC<Props> = ({data}) => {
  const [instance, updateInstance] = usePDF({})

  useEffect(() => {
    convertQrSvgToImg()
  }, [data])

  const convertQrSvgToImg = async () => {
    if (data) {
      QRCode.toDataURL(
        `${data.ArtPaletaId}; ${data.artikal.ArtNaziv}; Alternativa d.o.o`.toString()
      ).then((url) => {
        updateInstance(<AktivnePaletaPdf qrData={data} qrUrl={url} />)
      })
    }
  }
  return (
    <>
      {instance && instance.url ? (
        <a href={instance.url} target='_blank'>
          <KTIcon iconName='document' className='fs-2tx text-primary me-2' />
        </a>
      ) : (
        <div>Loading</div>
      )}
    </>
  )
}

export default AktivnePaletaQrCode
