import {Blocks} from 'react-loader-spinner'
const LoadingComponent:React.FC = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh'
        }}>
            <Blocks
                visible={true}
                height="100"
                width="100"
                ariaLabel="blocks-loading"
            />
        </div>
    );
}
export default LoadingComponent;
