import React, {FC, useState} from 'react'
import {KTIcon} from '../../../_websoft/helpers'
type Props = {
  onSearch: Function
}
const ColumnSearch: FC<Props> = ({onSearch}) => {
  const [searchTerm, setSearchTerm] = useState('')
  return (
    <div className='d-flex card-title justify-content-center'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-1' />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-100px ps-10 h-30px'
          placeholder='Trazi'
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            onSearch(e.target.value)
          }}
        />
      </div>
      {/* end::Search */}
    </div>
  )
}

export default ColumnSearch
