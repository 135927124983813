/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react'
import {Modal, ModalHeader} from 'react-bootstrap'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import notifyToast from '../../../../components/Toast/Toast'
import {zavrsiProizvodnjuRolne} from '../../../../api/rolneProizvodnju'
import {RolneZaProizvodnjuModel} from '../../../../models/RolneZaProizvodnju'
import clsx from 'clsx'
import LokacijeRepromaterijalaDropdown from '../../../../components/LokacijeRepromaterijalaDropdown/LokacijeRepromaterijalaDropdown'

interface Props {
  isModalOpen: boolean
  setisModalOpen: Function
  data: RolneZaProizvodnjuModel
  update: Function
}

const KrajRasjecanjeModal: FC<Props> = ({isModalOpen, setisModalOpen, data, update}) => {
  const [loading, setLoading] = useState(false)

  const [orginalnaRolna, setOrginalnaRolna] = useState<string>()

  const initialValues = {
    rolnaId: data.Id,
    duzina: undefined,
    tezina: undefined,
    lokacija: undefined,
    napomena: undefined,
    rolnaPotrosena: undefined,
    krajnjaTezinaRolne: undefined,
  }

  const validationSchema = Yup.object({
    duzina: Yup.number().required('Required'),
    tezina: Yup.number().required('Required'),
    lokacija: Yup.string().required('Required'),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        let response

        response = await zavrsiProizvodnjuRolne(values)

        const responseData = response

        if (responseData.error === 0) {
          notifyToast(true, responseData.message)
          update()
          setisModalOpen(false)
          resetForm();
        } else {
          notifyToast(false, responseData.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error.response.data.message)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    if (formik.values.rolnaPotrosena !== 'DA') formik.setFieldValue('krajnjaTezinaRolne', undefined)
  }, [formik.values.rolnaPotrosena])

  return (
    <Modal className='modal-lg' show={isModalOpen} onHide={() => setisModalOpen(false)}>
      <ModalHeader closeButton={true} className='h3'>
        Kreiraj RS!
      </ModalHeader>
      {loading ? (
        <LoadingComponent />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className='row p-5'>
            {/* RS BR */}
            <div className='col-lg-4 col-md-6 mb-6'>
              <label className='col-lg-6 col-form-label required fw-bold fs-6'>RS BR:</label>
              <div className='col-lg-6 fv-row'>
                <input
                  type='number'
                  placeholder='Rolna Id'
                  {...formik.getFieldProps('rolnaId')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.rolnaId && formik.errors.rolnaId},
                    {
                      'is-valid': formik.touched.rolnaId && !formik.errors.rolnaId,
                    }
                  )}
                  disabled
                />
                {formik.touched.rolnaId && formik.errors.rolnaId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.rolnaId}</div>
                  </div>
                )}
              </div>
            </div>
            {/* Rolna Br */}
            <div className='col-lg-4 col-md-6 mb-6'>
              <label className='col-lg-6 col-form-label  fw-bold fs-6'>ROLNA BR:</label>
              <div className='col-lg-6 fv-row'>
                <input
                  type='number'
                  placeholder='ROLNA BR'
                  value={data.BrojRolne}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  disabled
                />
              </div>
            </div>
            {/* Trenutna tezina rolne (kg) */}
            <div className='col-lg-4 col-md-6 mb-6'>
              <label className='col-lg-10 col-form-label fw-bold fs-6'>
                Trenutna tezina rolne (kg):
              </label>
              <div className='col-lg-6 fv-row'>
                <input
                  type='number'
                  placeholder='Trenutna Tezina'
                  value={data.repromaterijal.ReprMatTrenutnaKolicina}
                  className={clsx('form-control form-control-lg form-control-solid')}
                  disabled
                />
              </div>
            </div>
            {/* Duzina RŠ-a (m) */}
            <div className='col-lg-4 col-md-6 mb-6'>
              <label className='col col-form-label required fw-bold fs-6'>Duzina RŠ-a (m):</label>
              <div className='col-lg-6 fv-row'>
                <input
                  type='number'
                  placeholder='Duzina'
                  {...formik.getFieldProps('duzina')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.duzina && formik.errors.duzina},
                    {
                      'is-valid': formik.touched.duzina && !formik.errors.duzina,
                    }
                  )}
                />
                {formik.touched.duzina && formik.errors.duzina && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.duzina}</div>
                  </div>
                )}
              </div>
            </div>

            {/* Težina RŠ-a (kg) */}
            <div className='col-lg-4 col-md-6 mb-6'>
              <label className='col col-form-label required fw-bold fs-6'>Težina RŠ-a (kg):</label>
              <div className='col-lg-6 fv-row'>
                <input
                  type='number'
                  placeholder='tezina'
                  {...formik.getFieldProps('tezina')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.tezina && formik.errors.tezina},
                    {
                      'is-valid': formik.touched.tezina && !formik.errors.tezina,
                    }
                  )}
                />
                {formik.touched.tezina && formik.errors.tezina && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.tezina}</div>
                  </div>
                )}
              </div>
            </div>
            {/* Lokacija */}
            <div className='col-lg-4 col-md-6 mb-6'>
              <div className='col'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Lokacija</label>
                <div className='col-lg-8 fv-row'>
                  <LokacijeRepromaterijalaDropdown
                    value={formik.values.lokacija}
                    onChange={(name, value) => formik.setFieldValue('lokacija', value)}
                    errors={formik.errors.lokacija}
                    touched={formik.touched.lokacija}
                    selectName='lokacija'
                  />
                </div>
              </div>
            </div>
            {/* Prometuj orginalnu rolnu */}
            <div className='col-lg-4 col-md-6 mb-6'>
              <label className='col col-form-label  fw-bold fs-6'>Prometuj orginalnu rolnu</label>
              <select
                className={clsx('form-control form-control-lg form-control-solid')}
                value={orginalnaRolna}
                onChange={(e) => setOrginalnaRolna(e.target.value)}
              >
                <option value=''>Odaberi...</option>
                <option value='NE'>Ne</option>
                <option value='DA'>Da</option>
              </select>
            </div>
            {orginalnaRolna === 'DA' && (
              <>
                <div className='col-lg-4 col-md-6 mb-6'>
                  <label className='col col-form-label  fw-bold fs-6'>
                    Orginalna rolna potrošena
                  </label>
                  <select
                    {...formik.getFieldProps('rolnaPotrosena')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.rolnaPotrosena && formik.errors.rolnaPotrosena},
                      {
                        'is-valid': formik.touched.rolnaPotrosena && !formik.errors.rolnaPotrosena,
                      }
                    )}
                  >
                    <option value=''>Odaberi...</option>
                    <option value='NE'>Ne</option>
                    <option value='DA'>Da</option>
                  </select>
                  {formik.touched.rolnaPotrosena && formik.errors.rolnaPotrosena && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.rolnaPotrosena}</div>
                    </div>
                  )}
                </div>
                {formik.values.rolnaPotrosena === 'NE' && (
                  <div className='col-lg-4 col-md-6 mb-6'>
                    <label className='col col-form-label  fw-bold fs-6'>
                      Upisite preostalu kolicinu org rolne:
                    </label>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type='number'
                        placeholder='krajnjaTezinaRolne'
                        {...formik.getFieldProps('krajnjaTezinaRolne')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                              formik.touched.krajnjaTezinaRolne && formik.errors.krajnjaTezinaRolne,
                          },
                          {
                            'is-valid':
                              formik.touched.krajnjaTezinaRolne &&
                              !formik.errors.krajnjaTezinaRolne,
                          }
                        )}
                      />
                      {formik.touched.krajnjaTezinaRolne && formik.errors.krajnjaTezinaRolne && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.krajnjaTezinaRolne}</div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {/* Upisite preostalu kolicinu org rolne*/}
            {/* Napomena */}
            <div className='col-lg-8 col-md-6 mb-6'>
              <label className='col col-form-label  fw-bold fs-6'>Napomena:</label>
              <div className='col fv-row'>
                <textarea
                  placeholder='Napomena'
                  {...formik.getFieldProps('napomena')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.napomena && formik.errors.napomena},
                    {
                      'is-valid': formik.touched.napomena && !formik.errors.napomena,
                    }
                  )}
                />
                {formik.touched.napomena && formik.errors.napomena && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.napomena}</div>
                  </div>
                )}
              </div>
            </div>
            {/* End */}
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && 'Potvrdi'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      )}
    </Modal>
  )
}

export default KrajRasjecanjeModal
