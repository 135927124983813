import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../../components/TitleComponent/TitleComponent'
import QrCodeScanner from '../../../components/QrCodeScanner/QrCodeScanner'
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import notifyToast from '../../../components/Toast/Toast'
import {getRolnaDetalji} from '../../../api/rolneDetalji'
import clsx from 'clsx'
import {RepromaterijaliGreske} from '../../../models/RepromaterijaliGreske'
import {saveGreska} from '../../../api/greskeRepromaterijala'

const PrijaviGresku: React.FC = () => {
  const initialValues = {
    rolnaID: '',
    prijavaKomentar: '',
  }
  const validationSchema = Yup.object({
    rolnaID: Yup.number().required('Required'),
    prijavaKomentar: Yup.string().required('Required'),
  })

  const [loading, setLoading] = useState(false)
  const [dostupnostRolne, setDostupnostRolne] = useState<string>('')
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      try {
        const data: Partial<RepromaterijaliGreske> = {
          rolnaID: values.rolnaID,
          prijavaKomentar: values.prijavaKomentar,
        }

        // Call the API function
        const response = await saveGreska(data)

        // Process the response
        if (response && response.error === 0) {
          notifyToast(true, response.message)
          resetForm()
          setDostupnostRolne('')
        } else {
          notifyToast(false, response.message || 'An unexpected error occurred.')
        }
      } catch (error) {
        console.error('Error: ', error)
        notifyToast(false, 'Error')
        setStatus(error)
      } finally {
        setSubmitting(false)
      }
      setLoading(false)
    },
  })
  useEffect(() => {
    const fetchRolnaDetalji = async () => {
      // Ensure stavkaId is a string and has at least 5 characters
      if (typeof formik.values.rolnaID === 'string' && formik.values.rolnaID.length >= 5) {
        const reprMatIdNum = parseInt(formik.values.rolnaID, 10) // Convert to integer

        if (!isNaN(reprMatIdNum) && reprMatIdNum > 0) {
          // Check if it's a valid number
          try {
            const response = await getRolnaDetalji(reprMatIdNum)
            setDostupnostRolne(response.data.ReprMatTrenutnaKolicina)
          } catch (error) {
            console.error('Error fetching Rolna details:', error)
            // Handle error appropriately
          } finally {
          }
        }
      }
    }

    fetchRolnaDetalji()
  }, [formik.values.rolnaID])

  return (
    <div className='card mb-5 mb-xl-10'>
      <ToastContainer />
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <TitleComponent name='Prijavi grešku rolne' />
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/* ROLNA */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>ROLNA</label>
              <div className='col-lg-8'>
                <div className='row mb-5'>
                  <div className='col-2'>
                    <QrCodeScanner onScan={(data) => formik.setFieldValue('rolnaID', data)} />
                  </div>
                  <div className='col-4'>
                    <input
                      type='text'
                      placeholder='Rolna'
                      {...formik.getFieldProps('rolnaID')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.rolnaID && formik.errors.rolnaID},
                        {
                          'is-valid': formik.touched.rolnaID && !formik.errors.rolnaID,
                        }
                      )}
                    />
                    {formik.touched.rolnaID && formik.errors.rolnaID && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.rolnaID}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-4'>
                    <input
                      type='text'
                      placeholder={loading ? 'Loading...' : 'Težina rolne'}
                      className='form-control form-control-lg form-control-solid col-lg-3 disabled'
                      value={loading ? '' : dostupnostRolne}
                      readOnly={loading}
                    />
                  </div>
                </div>
                <div className='row mt-5'>
                  <div className='col-8'>
                    <textarea
                      placeholder='Komentar'
                      {...formik.getFieldProps('prijavaKomentar')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid':
                            formik.touched.prijavaKomentar && formik.errors.prijavaKomentar,
                        },
                        {
                          'is-valid':
                            formik.touched.prijavaKomentar && !formik.errors.prijavaKomentar,
                        }
                      )}
                    />
                    {formik.touched.prijavaKomentar && formik.errors.prijavaKomentar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.prijavaKomentar}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={loading || formik.isSubmitting || !formik.isValid}
            >
              {!loading && 'Potvrdi'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default PrijaviGresku
