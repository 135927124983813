import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react'
import LokacijeRepromaterijalaDropdown from '../../../components/LokacijeRepromaterijalaDropdown/LokacijeRepromaterijalaDropdown'
import QrCodeScanner from '../../../components/QrCodeScanner/QrCodeScanner'
import {Form, useFormik} from 'formik'
import * as Yup from 'yup'
import notifyToast from '../../../components/Toast/Toast'
import {rolnaDetaljiProizvodnja, sredstvoInfo} from '../../../api/fmkNalozi'
import {
  FmkNalogiStavkeModel,
  RolnaDetaljiProizvodnja,
  SredstvoInfoModel,
} from '../../../models/FmkNaloziModel'
import clsx from 'clsx'
import useDebounce from '../../../hooks/useDebounce'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
import {saveRadniNalozi} from '../../../api/radniNalozi'
import RadniNaloziOdjeliDropdown from '../../../components/RadniNaloziOdjeliDropdown/RadniNaloziOdjeliDropdown'
import {useNavigate} from 'react-router-dom'

interface Props {
  nalogaId: string
  selectedStavka: FmkNalogiStavkeModel['stavka']
}

export type Ref = any
const PrometRolne = forwardRef<Ref, Props>(({nalogaId, selectedStavka}, ref) => {
  const stavka = useMemo(() => selectedStavka, [selectedStavka])
  const initialData = {
    Repromaterijal: undefined,
    Krajnje: undefined,
    LokacijaRolne: undefined,
    Odjel: undefined,
    invbroj: undefined,
  }
  const [loading, setLoading] = useState(false)
  const [rolnaDetaljiInfo, setrolnaDetalji] = useState<RolnaDetaljiProizvodnja>()
  const [sredstvoInfoData, setsredstvoInfoData] = useState<SredstvoInfoModel>()
  const validationSchema = Yup.object({
    Repromaterijal: Yup.string().required('required'),
    Krajnje: Yup.number()
      .max(parseFloat(rolnaDetaljiInfo?.ReprMatTrenutnaKolicina ?? '0'))
      .required('required'),
  })

  useImperativeHandle(ref, () => ({
    saveData,
  }))
  const navigate = useNavigate()
  const saveData = () => {
    setLoading(true)
    if (stavka.length > 0 && rolnaDetaljiInfo?.message === undefined) {
      const payloadData = {
        BrojNaloga: nalogaId,
        MasinaId: formik.values.invbroj,
        OdjelId: formik.values.Odjel,
        BrojRolne: formik.values.Repromaterijal,
        KrajnjaKolicina: formik.values.Krajnje,
        Lokacija: formik.values.LokacijaRolne,
        stavke: stavka,
        trake: [],
      }

      saveRadniNalozi(payloadData)
        .then((response) => notifyToast(true, response.message))
        .catch((error) => notifyToast(false, error.message))
        .finally(() => {
          setLoading(false)
          setTimeout(() => {
            navigate(-1)
          }, 3000)
        })
    } else {
      selectedStavka.length === 0 && notifyToast(false, 'Select values from table')
      rolnaDetaljiInfo?.message && notifyToast(false, rolnaDetaljiInfo?.message)
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {},
  })

  const debouncedRolna = useDebounce(formik.values.Repromaterijal)
  useEffect(() => {
    if (debouncedRolna) {
      rolnaDetaljiProizvodnja({rolnaId: debouncedRolna})
        .then((response) => {
          if (response.data) {
            setrolnaDetalji(response.data)
          } else {
            setrolnaDetalji(response)
          }
        })
        .catch((error) => notifyToast(false, error.response.data.message))
    }
  }, [debouncedRolna])

  const debouncedInv = useDebounce(formik.values.invbroj)
  useEffect(() => {
    if (debouncedInv) {
      sredstvoInfo({invBroj: debouncedInv})
        .then((response) => {
          setsredstvoInfoData(response!)
        })
        .catch((error) => notifyToast(false, error.response.data.message))
    }
  }, [debouncedInv])
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Rolna Info</h2>
      </div>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Form onSubmit={formik.handleSubmit} noValidate>
          <div className='row mb-10'>
            <label className='form-label required'>Broj rolne</label>
            <div className='col-2'>
              <QrCodeScanner onScan={(data) => formik.setFieldValue('Repromaterijal', data)} />
            </div>
            {/* Repromaterijal */}
            <div className='col-4 mb-6'>
              <div className='col-lg-6 fv-row'>
                <input
                  type='text'
                  placeholder='Broj rolne'
                  {...formik.getFieldProps('Repromaterijal')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.Repromaterijal && formik.errors.Repromaterijal},
                    {
                      'is-valid': formik.touched.Repromaterijal && !formik.errors.Repromaterijal,
                    }
                  )}
                />
                {formik.touched.Repromaterijal && formik.errors.Repromaterijal && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.Repromaterijal}</div>
                  </div>
                )}
              </div>
            </div>
            {rolnaDetaljiInfo ? (
              <div className='col-4 border rounded border-success p-2'>
                {rolnaDetaljiInfo.message ? (
                  <h3>Poruka: {rolnaDetaljiInfo.message}</h3>
                ) : (
                  <div>
                    Debljina: {rolnaDetaljiInfo.ReprMatDebljina || 'N/A'} | Sirina:{' '}
                    {rolnaDetaljiInfo.ReprMatSirina || 'N/A'} | Boja Naziv:{' '}
                    {rolnaDetaljiInfo.boja_lica?.BojaNaziv || 'N/A'} | Proizvodjac:{' '}
                    {rolnaDetaljiInfo.proizvodjac?.PartnerNaziv || 'N/A'}
                  </div>
                )}
              </div>
            ) : (
              <div>Loading or no data...</div>
            )}
          </div>
          {/* begin: Odjel */}
          <div className='col-6 mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Odjel</label>
            <div className='col-lg-8 fv-row'>
              <RadniNaloziOdjeliDropdown
                value={formik.values.Odjel}
                onChange={(name, value) => formik.setFieldValue('Odjel', value)}
                errors={formik.errors.Odjel}
                touched={formik.touched.Odjel}
                selectName='Odjel'
              />
            </div>
          </div>
          {/* end: Odjel */}
          <div className='row mb-10'>
            <div className='col-6'>
              <label className='form-label required'>Inv. broj mašine</label>
              <input
                type='text'
                placeholder='Inv Broj'
                {...formik.getFieldProps('invbroj')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.invbroj && formik.errors.invbroj},
                  {
                    'is-valid': formik.touched.invbroj && !formik.errors.invbroj,
                  }
                )}
              />
              {formik.touched.invbroj && formik.errors.invbroj && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.invbroj}</div>
                </div>
              )}
            </div>
            <div className='col-6'>
              {sredstvoInfoData ? (
                <label className='form-label  border rounded border-success p-2 '>
                  {sredstvoInfoData?.SredstvoNaziv}
                </label>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className='row mb-10'>
            <div className='col-4'>
              <label className='form-label required'>Trenutna težina rolne</label>
              <input
                type='text'
                placeholder='Broj rolne'
                value={rolnaDetaljiInfo?.ReprMatTrenutnaKolicina}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.Repromaterijal && formik.errors.Repromaterijal},
                  {
                    'is-valid': formik.touched.Repromaterijal && !formik.errors.Repromaterijal,
                  }
                )}
              />
            </div>
            <div className='col-4'>
              <label className='form-label required'>Krajnja težina rolne</label>
              <input
                type='number'
                placeholder='Krajnje'
                {...formik.getFieldProps('Krajnje')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.Krajnje && formik.errors.Krajnje},
                  {
                    'is-valid': formik.touched.Krajnje && !formik.errors.Krajnje,
                  }
                )}
              />
              {formik.touched.Krajnje && formik.errors.Krajnje && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.Krajnje}</div>
                </div>
              )}
            </div>
            <div className='col-4'>
              <label className='form-label required'>Lokacija</label>
              <LokacijeRepromaterijalaDropdown
                value={formik.values.LokacijaRolne}
                onChange={(name, value) => formik.setFieldValue('LokacijaRolne', value)}
                errors={formik.errors.LokacijaRolne}
                touched={formik.touched.LokacijaRolne}
                selectName='LokacijaRolne'
              />
            </div>
          </div>
          <div className='separator my-10'></div>
        </Form>
      )}
    </div>
  )
})
export default PrometRolne
