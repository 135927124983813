/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {zadnjiNalozi} from '../../../../app/api/fmkNalozi'
import {ProizvodnjaModel} from '../../../../app/models/ProizvodnjaModel'
import notifyToast from '../../../../app/components/Toast/Toast'
import FmkNaloziComponent from '../../../../app/components/FmkNaloziComponent/FmkNaloziComponent'
import {urgentniNaloziZaTransport} from "../../../../app/api/proizvodnja";
import { useNavigate } from 'react-router-dom';

type Props = {
  className: string
}

const ListsWidget1: React.FC<Props> = ({className}) => {
  const [data, setdata] = useState<Array<ProizvodnjaModel>>([])

  useEffect(() => {
    urgentniNaloziZaTransport()
      .then((response) => setdata(response['data']['data']['data']))
      .catch((error) => notifyToast(false, error.response.data.message))
  }, [])
  const navigate = useNavigate();
  const urgentanTransport = () => {
    navigate('/paneli/urgentan-transport');
  };
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Proizvodnja panela</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Urgentni nalozi za transport</span>
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
              className='btn btn-sm btn-light-danger'
              onClick={urgentanTransport}
          >
            <KTIcon iconName='category' className='fs-3' />
            Ostali nalozi
          </button>

          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5'>
        {data.length > 0 ? (
            data.map((item) => (
                <FmkNaloziComponent key={item.id} iconName={'pencil'} title={item.brojNaloga} subtitle={item.kreiraoKorisnik} />
            ))
        ) : null}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget1}
