import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {InventureModel, InventureRolneModel, InventureTrakeModel, ParamsModelInventure} from "../models/inventure";

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/inventura/rolneITrake`
const API_ROLNE = `${API_URL}/inventura/rolneITrake`
const API_TRAKE = `${API_URL}/inventura/rolneITrake?vrsta=Trake`
const API_AKTIVNA_INV_ROLNE = `${API_URL}/inventura/aktivnaInventura?vrsta=Rolne`
const API_AKTIVNA_INV_TRAKE = `${API_URL}/inventura/aktivnaInventura?vrsta=Trake`
const API_ZADNJA_UPISANA_ROLNA = `${API_URL}/inventura/zadnjaUpisanaRolna`

const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getInventureRolni(params: ParamsModelInventure) {
    return await axios.get<Array<InventureModel>>(API_ROLNE, {headers: headers, params: params})
}

export async function saveInventura(Data: any) {
    try {
        const response = await axios.post(API, Data, {headers: headers})

        if (response.data.error === 0) {
            return response.data
        } else {
            return response.data
        }
    } catch (error) {
        console.error('Error Saving data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function fetchInventura(Id: number): Promise<Partial<InventureModel>> {
    try {
        const response = await axios.get(`${API}/${Id}`)
        if (response.data && response.data.error === 0) {
            return response.data.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function aktivnaInventura(): Promise<Partial<InventureModel>> {
    try {
        const response = await axios.get(`${API_AKTIVNA_INV_ROLNE}`)
        if (response.data && response.data.error === 0) {
            return response.data.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function aktivnaInventuraTrake(): Promise<Partial<InventureModel>> {
    try {
        const response = await axios.get(`${API_AKTIVNA_INV_TRAKE}`)
        if (response.data && response.data.error === 0) {
            return response.data.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function zadnjaUpisanaRolna(inventuraId: number): Promise<Partial<InventureRolneModel>> {
    try {
        const url = `${API_ZADNJA_UPISANA_ROLNA}?vrsta=Rolne&InventuraId=${inventuraId}`;
        const response = await axios.get(url);
        if (response.data && response.data.error === 0) {
            return response.data.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function zadnjaUpisanaTraka(inventuraId: number): Promise<Partial<InventureTrakeModel>> {
    try {
        const url = `${API_ZADNJA_UPISANA_ROLNA}?vrsta=Trake&InventuraId=${inventuraId}`;
        const response = await axios.get(url);
        if (response.data && response.data.error === 0) {
            return response.data.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}


export async function updateInventura(id: number, Data: any) {
    try {
        const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
        if (response.data.error === 0) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error Updating data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function deleteInventura(Id: number) {
    try {
        const response = await axios.delete(`${API}/${Id}`, {headers: headers})
        if (response.data) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to delete.')
        }
    } catch (error) {
        console.error('Error deleting data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function inventuraSveInformacijeTacne(Data: any) {
    try {
        const response = await axios.post(`${API_URL}/inventura/sveInformacijeTacne`, Data, {headers: headers})

        if (response.data.error === 0) {
            return response.data
        } else {
            return response.data
        }
    } catch (error) {
        console.error('Error Saving data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function sveInformacijeTacneTraka(Data: any) {
    try {
        const response = await axios.post(`${API_URL}/inventura/sveInformacijeTacneTraka`, Data, {headers: headers})

        if (response.data.error === 0) {
            return response.data
        } else {
            return response.data
        }
    } catch (error) {
        console.error('Error Saving data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function inventuraRolneGreskaLokacija(Data: any) {
    try {
        const response = await axios.post(`${API_URL}/inventura/inventuraRolneGreskaLokacija`, Data, {headers: headers})

        if (response.data.error === 0) {
            return response.data
        } else {
            return response.data
        }
    } catch (error) {
        console.error('Error Saving data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}
export async function inventuraRolneGreskaTezinaLokacija(Data: any) {
    try {
        const response = await axios.post(`${API_URL}/inventura/inventuraRolneGreskaTezinaLokacija`, Data, {headers: headers})

        if (response.data.error === 0) {
            return response.data
        } else {
            return response.data
        }
    } catch (error) {
        console.error('Error Saving data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function inventuraRolneGreskaTraka(Data: any) {
    try {
        const response = await axios.post(`${API_URL}/inventura/inventuraRolneGreskaTraka`, Data, {headers: headers})

        if (response.data.error === 0) {
            return response.data
        } else {
            return response.data
        }
    } catch (error) {
        console.error('Error Saving data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function izvjestajZaInventuruRolni(Data: any) {
    try {
        // Here, ensure Data is the object you want to pass as query parameters
        const queryString = new URLSearchParams(Data).toString();
        const response = await axios.get(`${API_URL}/inventura/izvjestajZaInventuruRolni?${queryString}`, { headers: headers });

        if (response.data.error === 0) {
            return response.data;
        } else {
            return response.data; // Consider throwing an error or handling this case differently
        }
    } catch (error) {
        console.error('Error Saving data:', error);
        throw error; // This rethrows the caught error, making it available to the caller
    }
}


export async function getSumaRolniInventurePremaFmkSifri(Data: any) {
    try {
        const queryString = new URLSearchParams(Data).toString(); // Use 'Data' instead of 'params'
        const response = await axios.get(`${API_URL}/inventura/getSumaRolniInventurePremaFmkSifri?${queryString}`, { headers: headers });

        if (response.data.error === 0) {
            return response.data;
        } else {
            return response.data; // This might need to handle errors differently, depending on your needs.
        }
    } catch (error) {
        console.error('Error Saving data:', error);
        throw error; // This will propagate the error to the caller.
    }
}

export async function izvjestajPremaLokaciji(Data: any) {
    try {
        // Assuming Data contains the query parameters you want to send
        const config = {
            headers: headers,
            params: Data // Passing the data as parameters
        };
        const response = await axios.get(`${API_URL}/inventura/izvjestajPremaLokaciji`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}