import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ProdajaNajamModel} from "../../models/ProdajaNajamModel";
import {fetchProdajaNajam, saveProdajaNajam, updateProdajaNajam} from "../../api/ProdajaNajam";
import * as Yup from "yup";
import {useFormik} from "formik";
import notifyToast from "../../components/Toast/Toast";
import {ToastContainer} from "react-toastify";
import TitleComponent from "../../components/TitleComponent/TitleComponent";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import clsx from "clsx";
import ReactQuill from "react-quill";
import KategorijeProdajeNajam from "../../components/KategorijeProdajaNajam/KategorijeProdajaNajam";
import SubKategorijeProdajeNajam from "../../components/KategorijeProdajaNajam/SubKategorijeProdajaNajam";
import JmRepromaterijalaDropdown from "../../components/JmRepromaterijalaDropdown/JmRepromaterijalaDropdown";
import UposlenikDropdown from "../../components/UposlenikDropdown/UposlenikDropdown";
import ImageComponent from "../../components/ImageComponet/ImageComponent";
import {ReklamacijeModel} from "../../models/ReklamacijeModel";
import {fetchPaneliReklamacije, savePaneliReklamacije, updatePaneliReklamacije} from "../../api/reklamacijePanela";

const isValidUrl = (url) => {
    return url && url.trim() !== '' && url.includes('amazon');
};

const ReklamacijeDetails: React.FC = () => {
    const {id} = useParams();
    const IdFromURL = Number(id);
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState<File | null>(null);
    const [file2, setFile2] = useState<File | null>(null);
    const [file3, setFile3] = useState<File | null>(null);
    const [file4, setFile4] = useState<File | null>(null);
    const [file5, setFile5] = useState<File | null>(null);
    const [imageData, setimageData] = useState({
        Slika1: undefined,
        Slika2: undefined,
        Slika3: undefined,
        Slika4: undefined,
        Slika5: undefined,
        Slika6: undefined,
        Slika7: undefined,
        Slika8: undefined,
        Slika9: undefined,
        Slika10: undefined,
    })
    const navigate = useNavigate();

    const initialValues: Partial<ReklamacijeModel> = {
        BrojRacuna: undefined,
        RadniNalog: undefined,
        IdProizvodnje: undefined,
        StavkaNaloga: 0,
        RacunKojiSeReklamira: undefined,
        VrijednostRacunaReklamacije: undefined,
        ReklamiranaKolicina: undefined,
        OpisReklamacije: undefined,
        TroskoviReklamacije: undefined,
        NacinRjesavanjaReklamacije: undefined,
        OznakaRegistratora: undefined,
        ZahtjevZaReklamacijuDoc: undefined,
        ZapisnikSaTerenaDoc: undefined,
        OpravdanostReklamacijeDoc: undefined,
        ZapisnikReklamacijeDoc: undefined,
        KnjiznoOdobenjeDoc: undefined,
        Realizovano: undefined,
        Slika1: undefined,
        Slika2: undefined,
        Slika3: undefined,
        Slika4: undefined,
        Slika5: undefined,
        Slika6: undefined,
        Slika7: undefined,
        Slika8: undefined,
        Slika9: undefined,
        Slika10: undefined,
        Napomena: undefined,
    };

    const [initialData, setInitialData] = useState<Partial<ReklamacijeModel>>(initialValues);

    useEffect(() => {
        if (IdFromURL) {
            fetchPaneliReklamacije(IdFromURL)
                .then((data) => {
                    setInitialData(data);
                    formik.resetForm({values: data}); // <-- reset formik state here
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [IdFromURL]);

    const validationSchema = Yup.object({
        RadniNalog: Yup.string().required('Required'),
        ReklamiranaKolicina: Yup.string().required('Required'),
        OpisReklamacije: Yup.string().required('Required'),
    });

    const getFormData = (values: any) => {
        const formData = new FormData();
        if (file) {
            formData.append('ZahtjevZaReklamacijuDoc', file);
        }
        if (file2) {
            formData.append('ZapisnikSaTerenaDoc', file2);
        }
        if (file3) {
            formData.append('OpravdanostReklamacijeDoc', file3);
        }
        if (file4) {
            formData.append('ZapisnikReklamacijeDoc', file4);
        }
        if (file5) {
            formData.append('KnjiznoOdobenjeDoc', file5);
        }
        values.BrojRacuna && formData.append('BrojRacuna', values.BrojRacuna);
        values.RadniNalog && formData.append('RadniNalog', values.RadniNalog);
        values.IdProizvodnje && formData.append('IdProizvodnje', values.IdProizvodnje);
        values.RacunKojiSeReklamira && formData.append('RacunKojiSeReklamira', values.RacunKojiSeReklamira);
        values.VrijednostRacunaReklamacije && formData.append('VrijednostRacunaReklamacije', values.VrijednostRacunaReklamacije);
        values.ReklamiranaKolicina && formData.append('ReklamiranaKolicina', values.ReklamiranaKolicina);
        values.OpisReklamacije && formData.append('OpisReklamacije', values.OpisReklamacije);
        values.TroskoviReklamacije && formData.append('TroskoviReklamacije', values.TroskoviReklamacije);
        values.NacinRjesavanjaReklamacije && formData.append('NacinRjesavanjaReklamacije', values.NacinRjesavanjaReklamacije);
        values.ZahtjevZaReklamacijuDoc && formData.append('ZahtjevZaReklamacijuDoc', values.ZahtjevZaReklamacijuDoc);
        values.ZapisnikSaTerenaDoc && formData.append('ZapisnikSaTerenaDoc', values.ZapisnikSaTerenaDoc);
        values.OpravdanostReklamacijeDoc && formData.append('OpravdanostReklamacijeDoc', values.OpravdanostReklamacijeDoc);
        values.ZapisnikReklamacijeDoc && formData.append('ZapisnikReklamacijeDoc', values.ZapisnikReklamacijeDoc);
        values.KnjiznoOdobenjeDoc && formData.append('KnjiznoOdobenjeDoc', values.KnjiznoOdobenjeDoc);
        values.OznakaRegistratora && formData.append('OznakaRegistratora', values.OznakaRegistratora);
        values.Realizovano && formData.append('Realizovano', values.Realizovano);
        values.StavkaNaloga && formData.append('StavkaNaloga', values.StavkaNaloga);
        values.StavkaNaloga && formData.append('Napomena', values.Napomena);
        if (imageData.Slika1) formData.append('AddSlika1', imageData.Slika1)
        if (imageData.Slika2) formData.append('AddSlika2', imageData.Slika2)
        if (imageData.Slika3) formData.append('AddSlika3', imageData.Slika3)
        if (imageData.Slika4) formData.append('AddSlika4', imageData.Slika4)
        if (imageData.Slika5) formData.append('AddSlika5', imageData.Slika5)
        if (imageData.Slika6) formData.append('AddSlika6', imageData.Slika6)
        if (imageData.Slika7) formData.append('AddSlika7', imageData.Slika7)
        if (imageData.Slika8) formData.append('AddSlika8', imageData.Slika8)
        if (imageData.Slika9) formData.append('AddSlika9', imageData.Slika9)
        if (imageData.Slika10) formData.append('AddSlika10', imageData.Slika10)

        console.log(imageData.Slika9)
        return formData;
    };

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true);
            const formdata = getFormData(values);
            let response;
            try {

                if (IdFromURL) {
                    response = await updatePaneliReklamacije(IdFromURL, formdata);
                } else {
                    response = await savePaneliReklamacije(formdata);
                }

                if (response && response.error === 0) {
                    notifyToast(true, response.message);
                } else {
                    notifyToast(false, response?.message || 'An unexpected error occurred.');
                }
            } catch (error: any) {
                console.error('Error: ', error);
                notifyToast(false, error.response.data.message);
                setStatus(error);
            } finally {
                setLoading(false);
                setSubmitting(false);
                /*   setTimeout(() => {
                       navigate(-1);
                   }, 1000);*/
            }
        },
    });

    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer/>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name=''/>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className='card-body border-top p-9 row'>
                            <div className='col-6 p-3 mb-6'>
                                {/* Sifra */}
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    Broj Računa
                                </label>
                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Broj Racuna'
                                        {...formik.getFieldProps('BrojRacuna')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.BrojRacuna && formik.errors.BrojRacuna},
                                            {
                                                'is-valid': formik.touched.BrojRacuna && !formik.errors.BrojRacuna,
                                            }
                                        )}
                                    />
                                    {formik.touched.BrojRacuna && formik.errors.BrojRacuna && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.BrojRacuna}</div>
                                        </div>
                                    )}
                                </div>

                                {/* Naziv */}
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <label className='col-lg-4 col-form-label  mt-4  required fw-bold fs-6'>
                                            Radni Nalog
                                        </label>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='text'
                                                placeholder='Radni Nalog'
                                                {...formik.getFieldProps('RadniNalog')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {'is-invalid': formik.touched.RadniNalog && formik.errors.RadniNalog},
                                                    {
                                                        'is-valid': formik.touched.RadniNalog && !formik.errors.RadniNalog,
                                                    }
                                                )}
                                            />
                                            {formik.touched.RadniNalog && formik.errors.RadniNalog && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.RadniNalog}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <label className='col-lg-4 col-form-label  mt-4  required fw-bold fs-6'>
                                            Stavka Naloga
                                        </label>
                                        <div className='col-lg-12 fv-row'>
                                            <input
                                                type='text'
                                                placeholder='Stavka Naloga'
                                                {...formik.getFieldProps('StavkaNaloga')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {'is-invalid': formik.touched.StavkaNaloga && formik.errors.StavkaNaloga},
                                                    {
                                                        'is-valid': formik.touched.StavkaNaloga && !formik.errors.StavkaNaloga,
                                                    }
                                                )}
                                            />
                                            {formik.touched.StavkaNaloga && formik.errors.StavkaNaloga && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.StavkaNaloga}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* Naziv */}
                                <label className='col-lg-4 col-form-label  mt-4  required fw-bold fs-6'>
                                    Racun Koji Se Reklamira
                                </label>
                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Racun Koji Se Reklamira'
                                        {...formik.getFieldProps('RacunKojiSeReklamira')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.RacunKojiSeReklamira && formik.errors.RacunKojiSeReklamira},
                                            {
                                                'is-valid': formik.touched.RacunKojiSeReklamira && !formik.errors.RacunKojiSeReklamira,
                                            }
                                        )}
                                    />
                                    {formik.touched.RacunKojiSeReklamira && formik.errors.RacunKojiSeReklamira && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.RacunKojiSeReklamira}</div>
                                        </div>
                                    )}
                                </div>

                                {/* VrijednostRacunaReklamacije */}
                                <label className='col-lg-8 col-form-label  mt-4  required fw-bold fs-6'>
                                    Vrijednost Racuna Reklamacije
                                </label>
                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Vrijednost Racuna Reklamacije'
                                        {...formik.getFieldProps('VrijednostRacunaReklamacije')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.VrijednostRacunaReklamacije && formik.errors.VrijednostRacunaReklamacije},
                                            {
                                                'is-valid': formik.touched.VrijednostRacunaReklamacije && !formik.errors.VrijednostRacunaReklamacije,
                                            }
                                        )}
                                    />
                                    {formik.touched.VrijednostRacunaReklamacije && formik.errors.VrijednostRacunaReklamacije && (
                                        <div className='fv-plugins-message-container'>
                                            <div
                                                className='fv-help-block'>{formik.errors.VrijednostRacunaReklamacije}</div>
                                        </div>
                                    )}
                                </div>
                                {/* VrijednostRacunaReklamacije */}
                                <label className='col-lg-4 col-form-label  mt-4  required fw-bold fs-6'>
                                    Reklamirana Kolicina
                                </label>
                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Reklamirana Kolicina'
                                        {...formik.getFieldProps('ReklamiranaKolicina')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.ReklamiranaKolicina && formik.errors.ReklamiranaKolicina},
                                            {
                                                'is-valid': formik.touched.ReklamiranaKolicina && !formik.errors.ReklamiranaKolicina,
                                            }
                                        )}
                                    />
                                    {formik.touched.ReklamiranaKolicina && formik.errors.ReklamiranaKolicina && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.ReklamiranaKolicina}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                {/* Opis */}
                                <div className="row p-3">
                                    <div className='col-lg-12 fv-row'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Opis
                                            Reklamacije</label>
                                        <div className='col-lg-12 fv-row'>
                                            <ReactQuill
                                                value={formik.values.OpisReklamacije || ''}
                                                onChange={(value) => formik.setFieldValue('OpisReklamacije', value)}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {'is-invalid': formik.touched.OpisReklamacije && formik.errors.OpisReklamacije},
                                                    {
                                                        'is-valid': formik.touched.OpisReklamacije && !formik.errors.OpisReklamacije,
                                                    }
                                                )}
                                                placeholder="Opis Reklamacije"
                                            />
                                            {formik.touched.OpisReklamacije && formik.errors.OpisReklamacije && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.OpisReklamacije}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* TroskoviReklamacije */}
                                    <label className='col-lg-4 col-form-label  mt-4 fw-bold fs-6'>
                                        Troskovi Reklamacije
                                    </label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            placeholder='Troskovi Reklamacije'
                                            {...formik.getFieldProps('TroskoviReklamacije')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.TroskoviReklamacije && formik.errors.TroskoviReklamacije},
                                                {
                                                    'is-valid': formik.touched.TroskoviReklamacije && !formik.errors.TroskoviReklamacije,
                                                }
                                            )}
                                        />
                                        {formik.touched.TroskoviReklamacije && formik.errors.TroskoviReklamacije && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.TroskoviReklamacije}</div>
                                            </div>
                                        )}
                                    </div>
                                    {/* TroskoviReklamacije */}
                                    <label className='col-lg-12 col-form-label  mt-4 fw-bold fs-6'>
                                        Nacin Rjesavanja Reklamacije
                                    </label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            placeholder='Nacin Rjesavanja Reklamacije'
                                            {...formik.getFieldProps('NacinRjesavanjaReklamacije')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.NacinRjesavanjaReklamacije && formik.errors.NacinRjesavanjaReklamacije},
                                                {
                                                    'is-valid': formik.touched.NacinRjesavanjaReklamacije && !formik.errors.NacinRjesavanjaReklamacije,
                                                }
                                            )}
                                        />
                                        {formik.touched.NacinRjesavanjaReklamacije && formik.errors.NacinRjesavanjaReklamacije && (
                                            <div className='fv-plugins-message-container'>
                                                <div
                                                    className='fv-help-block'>{formik.errors.NacinRjesavanjaReklamacije}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='separator mt-5'></div>
                                    <div className='col-6 mb-6'>
                                        <label className='col-lg-8 col-form-label fw-bold fs-6'>
                                            Realizovana reklamacija
                                        </label>
                                        <select
                                            {...formik.getFieldProps('Realizovano')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.Realizovano && formik.errors.Realizovano},
                                                {
                                                    'is-valid': formik.touched.Realizovano && !formik.errors.Realizovano,
                                                }
                                            )}
                                        >
                                            <option value=''>Odaberi status...</option>
                                            <option value='Na čekanju' selected>Na čekanju...</option>
                                            <option value='Ne'>Ne</option>
                                            <option value='Da'>Da</option>
                                        </select>
                                        {formik.touched.Realizovano && formik.errors.Realizovano && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.Realizovano}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 fv-row'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Napomena</label>
                                <div className='col-lg-12 fv-row'>
                                    <ReactQuill
                                        value={formik.values.Napomena || ''}
                                        onChange={(value) => formik.setFieldValue('Napomena', value)}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.Napomena && formik.errors.Napomena},
                                            {
                                                'is-valid': formik.touched.Napomena && !formik.errors.Napomena,
                                            }
                                        )}
                                        placeholder="Napomena"
                                    />
                                    {formik.touched.Napomena && formik.errors.Napomena && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Napomena}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* Slika */}
                            <div className='separator mt-5 mb-5'></div>
                            <div className='row mb-6'>
                                <div className='col-lg-4 mb-6'>
                                    <h3>Zahtjev Za Reklamaciju Dokument</h3>
                                    <div className='d-flex col-lg-6 mt-4 col-sm-12'>
                                        <input
                                            type="file"
                                            className='d-flex col-lg-12 col-sm-12'
                                            onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
                                        />
                                    </div>
                                    <div className='d-flex col-lg-6 col-sm-12'>
                                        <div className="d-flex col-lg-6 col-sm-12">
                                            {isValidUrl(initialData?.ZahtjevZaReklamacijuDoc) && (
                                                <>
                                                    <span>Uploadovan dokument</span>
                                                    <a href={initialData.ZahtjevZaReklamacijuDoc} target="_blank"
                                                       rel="noopener noreferrer">
                                                        <i className="bi bi-file-earmark-pdf"
                                                           style={{fontSize: '4rem', color: 'green'}}></i>
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 mb-6'>
                                    <h3>Zapisnik Sa Terena Dokument</h3>
                                    <div className='d-flex col-lg-6 mt-4 col-sm-12'>
                                        <input
                                            type="file"
                                            className='d-flex col-lg-12 col-sm-12'
                                            onChange={(e) => setFile2(e.target.files ? e.target.files[0] : null)}
                                        />
                                    </div>
                                    <div className='d-flex col-lg-6 col-sm-12'>
                                        <div className="d-flex col-lg-6 col-sm-12">
                                            {isValidUrl(initialData?.ZapisnikSaTerenaDoc) && (
                                                <>
                                                    <span>Uploadovan dokument</span>
                                                    <a href={initialData.ZapisnikSaTerenaDoc} target="_blank"
                                                       rel="noopener noreferrer">
                                                        <i className="bi bi-file-earmark-pdf"
                                                           style={{fontSize: '4rem', color: 'green'}}></i>
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 mb-6'>
                                    <h3>Opravdanost Reklamacije Dokument</h3>
                                    <div className='d-flex col-lg-6 mt-4 col-sm-12'>
                                        <input
                                            type="file"
                                            className='d-flex col-lg-12 col-sm-12'
                                            onChange={(e) => setFile3(e.target.files ? e.target.files[0] : null)}
                                        />
                                    </div>
                                    <div className='d-flex col-lg-6 col-sm-12'>
                                        <div className="d-flex col-lg-6 col-sm-12">
                                            {isValidUrl(initialData?.OpravdanostReklamacijeDoc) && (
                                                <>
                                                    <span>Uploadovan dokument</span>
                                                    <a href={initialData.OpravdanostReklamacijeDoc} target="_blank"
                                                       rel="noopener noreferrer">
                                                        <i className="bi bi-file-earmark-pdf"
                                                           style={{fontSize: '4rem', color: 'green'}}></i>
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 mt-5 mb-6'>
                                    <h3>Zapisnik Reklamacije Dokument</h3>
                                    <div className='d-flex col-lg-6 mt-4 col-sm-12'>
                                        <input
                                            type="file"
                                            className='d-flex col-lg-12 col-sm-12'
                                            onChange={(e) => setFile4(e.target.files ? e.target.files[0] : null)}
                                        />
                                    </div>
                                    <div className='d-flex col-lg-6 col-sm-12'>
                                        <div className="d-flex col-lg-6 col-sm-12">
                                            {isValidUrl(initialData?.ZapisnikReklamacijeDoc) && (
                                                <>
                                                    <span>Uploadovan dokument</span>
                                                    <a href={initialData.ZapisnikReklamacijeDoc} target="_blank"
                                                       rel="noopener noreferrer">
                                                        <i className="bi bi-file-earmark-pdf"
                                                           style={{fontSize: '4rem', color: 'green'}}></i>
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 mt-5 mb-6'>
                                    <h3>Knjizno Odobrenje Dokument</h3>
                                    <div className='d-flex col-lg-6 mt-4 col-sm-12'>
                                        <input
                                            type="file"
                                            className='d-flex col-lg-12 col-sm-12'
                                            onChange={(e) => setFile5(e.target.files ? e.target.files[0] : null)}
                                        />
                                    </div>
                                    <div className='d-flex col-lg-6 col-sm-12'>
                                        <div className="d-flex col-lg-6 col-sm-12">
                                            {isValidUrl(initialData?.KnjiznoOdobenjeDoc) && (
                                                <>
                                                    <span>Uploadovan dokument</span>
                                                    <a href={initialData.KnjiznoOdobenjeDoc} target="_blank"
                                                       rel="noopener noreferrer">
                                                        <i className="bi bi-file-earmark-pdf"
                                                           style={{fontSize: '4rem', color: 'green'}}></i>
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='separator mt-5 mb-5'></div>
                                <div className='row mb-6'>
                                    <ImageComponent
                                        slikaUrl={formik.values.Slika1}
                                        label={'Slika 1'}
                                        setFile={(value) => {
                                            formik.setFieldValue('Slika1', value)
                                            setimageData({...imageData, Slika1: value})
                                        }}
                                        isFileValid={(val) => formik.setErrors({Slika1: val})}
                                    />
                                    <ImageComponent
                                        slikaUrl={formik.values.Slika2}
                                        label={'Slika 2'}
                                        setFile={(value) => {
                                            formik.setFieldValue('Slika2', value)
                                            setimageData({...imageData, Slika2: value})
                                        }}
                                        isFileValid={(val) => formik.setErrors({Slika2: val})}
                                    />
                                    <ImageComponent
                                        slikaUrl={formik.values.Slika3}
                                        label={'Slika 3'}
                                        setFile={(value) => {
                                            formik.setFieldValue('Slika3', value)
                                            setimageData({...imageData, Slika3: value})
                                        }}
                                        isFileValid={(val) => formik.setErrors({Slika3: val})}
                                    />
                                    <ImageComponent
                                        slikaUrl={formik.values.Slika4}
                                        label={'Slika 4'}
                                        setFile={(value) => {
                                            formik.setFieldValue('Slika4', value)
                                            setimageData({...imageData, Slika4: value})
                                        }}
                                        isFileValid={(val) => formik.setErrors({Slika4: val})}
                                    />
                                    <ImageComponent
                                        slikaUrl={formik.values.Slika5}
                                        label={'Slika 5'}
                                        setFile={(value) => {
                                            formik.setFieldValue('Slika5', value)
                                            setimageData({...imageData, Slika5: value})
                                        }}
                                        isFileValid={(val) => formik.setErrors({Slika5: val})}
                                    />
                                    <ImageComponent
                                        slikaUrl={formik.values.Slika6}
                                        label={'Slika 6'}
                                        setFile={(value) => {
                                            formik.setFieldValue('Slika6', value)
                                            setimageData({...imageData, Slika6: value})
                                        }}
                                        isFileValid={(val) => formik.setErrors({Slika6: val})}
                                    />
                                    <ImageComponent
                                        slikaUrl={formik.values.Slika7}
                                        label={'Slika 7'}
                                        setFile={(value) => {
                                            formik.setFieldValue('Slika7', value)
                                            setimageData({...imageData, Slika7: value})
                                        }}
                                        isFileValid={(val) => formik.setErrors({Slika7: val})}
                                    />
                                    <ImageComponent
                                        slikaUrl={formik.values.Slika8}
                                        label={'Slika 8'}
                                        setFile={(value) => {
                                            formik.setFieldValue('Slika8', value)
                                            setimageData({...imageData, Slika8: value})
                                        }}
                                        isFileValid={(val) => formik.setErrors({Slika8: val})}
                                    />
                                    <ImageComponent
                                        slikaUrl={formik.values.Slika9}
                                        label={'Slika 9'}
                                        setFile={(value) => {
                                            formik.setFieldValue('Slika9', value)
                                            setimageData({...imageData, Slika9: value})
                                        }}
                                        isFileValid={(val) => formik.setErrors({Slika9: val})}
                                    />
                                    <ImageComponent
                                        slikaUrl={formik.values.Slika10}
                                        label={'Slika 10'}
                                        setFile={(value) => {
                                            formik.setFieldValue('Slika10', value)
                                            setimageData({...imageData, Slika10: value})
                                        }}
                                        isFileValid={(val) => formik.setErrors({Slika10: val})}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={loading || formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && 'Potvrdi'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
}
export default ReklamacijeDetails;
