import clsx from 'clsx'
import React, {FC, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {PaginationModel} from '../../models/PaginationModel'
type Props = {
  updatePage: Function
  pagination: PaginationModel
  isLoading?: boolean
}
const TablePagination: FC<Props> = ({updatePage, pagination, isLoading = false}) => {
  const [perpage, setperpage] = useState(10)
  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
        <Dropdown>
          <Dropdown.Toggle id='dropdown-basic' size='sm' variant='secondary'>
            {perpage <= 100 ? perpage : 'All'}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              href='#/action-1'
              onClick={() => {
                setperpage(10)
                updatePage(pagination.curPage, 10)
              }}
            >
              10
            </Dropdown.Item>
            <Dropdown.Item
              href='#/action-2'
              onClick={() => {
                setperpage(50)
                updatePage(pagination.curPage, 50)
              }}
            >
              50
            </Dropdown.Item>
            <Dropdown.Item
              href='#/action-3'
              onClick={() => {
                setperpage(100)
                updatePage(pagination.curPage, 100)
              }}
            >
              100
            </Dropdown.Item>
            <Dropdown.Item
              href='#/action-3'
              onClick={() => {
                setperpage(100000)
                updatePage(pagination.curPage, 100000)
              }}
            >
              All
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            <li
              className={clsx('page-item', {
                disabled: isLoading || pagination.curPage === 1,
              })}
              style={{display: 'flex'}}
            >
              <button
                onClick={() => updatePage(1, perpage)}
                style={{cursor: 'pointer'}}
                className='page-link'
              >
                First
              </button>
              <button
                onClick={() => updatePage(pagination.curPage - 1, perpage)}
                style={{cursor: 'pointer'}}
                className='page-link'
              >
                Previous
              </button>
            </li>
            {
              <li
                key={pagination.curPage}
                className={clsx('page-item', {
                  active: true,
                  disabled: isLoading,
                })}
              >
                <button
                  className={clsx('page-link', {})}
                  onClick={() => updatePage(pagination.curPage, perpage)}
                  style={{cursor: 'pointer'}}
                >
                  {pagination.curPage}
                </button>
                <div>of {pagination.totalPage}</div>
              </li>
              // ))
            }
            <li
              style={{display: 'flex'}}
              className={clsx('page-item', {
                disabled: isLoading || pagination.curPage === pagination.totalPage,
              })}
            >
              <button
                onClick={() => updatePage(pagination.curPage + 1, perpage)}
                style={{cursor: 'pointer'}}
                className='page-link'
              >
                Next
              </button>
              <button
                onClick={() => updatePage(pagination.totalPage, perpage)}
                style={{cursor: 'pointer'}}
                className='page-link'
              >
                Last
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default TablePagination
