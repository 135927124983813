import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {RepromaterijalPromet} from "../../../models/RepromaterijaliPromet";
import {RolnaDetaljiProizvodnja} from "../../../models/FmkNaloziModel";
import {
    direktanIzlazRolne,
    fetchRepromaterijalPromet,
    saveRepromaterijalPromet,
    updateRepromaterijalPromet
} from "../../../api/prometRepromaterijala";
import * as Yup from "yup";
import {useFormik} from "formik";
import notifyToast from "../../../components/Toast/Toast";
import useDebounce from "../../../hooks/useDebounce";
import {rolnaDetaljiProizvodnja} from "../../../api/fmkNalozi";
import {ToastContainer} from "react-toastify";
import TitleComponent from "../../../components/TitleComponent/TitleComponent";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import QrCodeScanner from "../../../components/QrCodeScanner/QrCodeScanner";
import clsx from "clsx";
import OdjeliDropdown from "../../../components/OdjeliDropdown/OdjeliDropdown";
import PoslovniceDropdown from "../../../components/PoslovniceDropdown/PoslovniceDropdown";
import LokacijeRepromaterijalaDropdown
    from "../../../components/LokacijeRepromaterijalaDropdown/LokacijeRepromaterijalaDropdown";

const PrometRolneDirektnaProdaja:React.FC = () => {
    const {id} = useParams()
    const idFromURL = Number(id)

    const initialValues = {
        Repromaterijal: '',
        BrojDokumentaIzlaza: '',
        Napomene: '',
    }
    const [loading, setLoading] = useState(true)
    const [initialData, setInitialData] = useState<Partial<RepromaterijalPromet>>(initialValues)
    const [rolnaDetaljiInfo, setrolnaDetalji] = useState<RolnaDetaljiProizvodnja>()

    useEffect(() => {
        if (idFromURL) {
            fetchRepromaterijalPromet(idFromURL)
                .then((data) => {
                    setInitialData(data)
                    formik.resetForm({values: data}) // <-- reset formik state here
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [idFromURL])

    const validationSchema = Yup.object({
        BrojDokumentaIzlaza: Yup.string().min(5, 'Must be at least 4 characters').required('Required'),
        Repromaterijal: Yup.string().required('Required'),
    })

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm }) => {
            setLoading(true);
            try {
                const response = await direktanIzlazRolne(values);
                // Assuming response is defined correctly within try block
                const responseData = response;

                if (responseData.error === 0) {
                    notifyToast(true, responseData.message);
                    resetForm();
                    setrolnaDetalji(undefined)
                } else {
                    notifyToast(false, responseData.message || 'An unexpected error occurred.');
                }
            } catch (error: any) {
                console.error('Error: ', error);
                notifyToast(false, error.response?.data.message || 'An error occurred');
                setStatus(error);
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });
    const debouncedRolna = useDebounce(formik.values.Repromaterijal)
    useEffect(() => {
        if (debouncedRolna) {
            rolnaDetaljiProizvodnja({rolnaId: debouncedRolna})
                .then((response) => {
                    if (response.data) {
                        setrolnaDetalji(response.data)
                    } else {
                        setrolnaDetalji(response)
                    }
                })
                .catch((error) => notifyToast(false, error.response.data.message))
        }
    }, [debouncedRolna])

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer />
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name='Promet rolne, direktna prodaja' />
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                {loading ? (
                    <LoadingComponent />
                ) : (
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body border-top p-9'>
                            {/* ROLNA */}
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>ROLNA</label>

                                <div className='col-lg-8'>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <QrCodeScanner
                                                onScan={(data) => {
                                                    const text = data.split(';')[0]
                                                    formik.setFieldValue('Repromaterijal', text)
                                                }}
                                            />
                                        </div>
                                        <div className='col-10'>
                                            <input
                                                type='text'
                                                placeholder='Rolna'
                                                {...formik.getFieldProps('Repromaterijal')}
                                                ref={inputRef}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {
                                                        'is-invalid':
                                                            formik.touched.Repromaterijal && formik.errors.Repromaterijal,
                                                    },
                                                    {
                                                        'is-valid':
                                                            formik.touched.Repromaterijal && !formik.errors.Repromaterijal,
                                                    }
                                                )}
                                            />
                                            {formik.touched.Repromaterijal && formik.errors.Repromaterijal && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.Repromaterijal}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='col-8 mt-6'>
                                            {rolnaDetaljiInfo ? (
                                                <div className='col-12 border rounded border-success p-2'>
                                                    {rolnaDetaljiInfo.message ? (
                                                        <h3>Poruka: {rolnaDetaljiInfo.message}</h3>
                                                    ) : (
                                                        <div>
                                                            <h2>
                                                                Težina: {rolnaDetaljiInfo.ReprMatTrenutnaKolicina || 'N/A'}
                                                                <br/>
                                                                Debljina: {rolnaDetaljiInfo.ReprMatDebljina || 'N/A'} <br/>
                                                                Sirina:{' '}
                                                                {rolnaDetaljiInfo.ReprMatSirina || 'N/A'} <br/>
                                                                Lokacija:{' '}
                                                                {rolnaDetaljiInfo.ReprMatLokacija || 'N/A'} <br/>
                                                                Red:{' '}
                                                                {rolnaDetaljiInfo.ReprMatLokacija2 || 'N/A'} <br/>
                                                                <hr/>
                                                                Boja:{' '}
                                                                {rolnaDetaljiInfo.boja_lica?.BojaNaziv || 'N/A'} <br/>
                                                                Proizvođač:{' '}
                                                                {rolnaDetaljiInfo.proizvodjac?.PartnerNaziv || 'N/A'} <br/>
                                                                Šarža:{' '}
                                                                {rolnaDetaljiInfo.proizvodjac?.PartnerNaziv || 'N/A'} <br/>
                                                            </h2>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div>Loading or no data...</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* BROJ NALOGA */}
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>BROJ NALOGA</label>
                                <div className='col-1 col-xs-3 '>
                                    <QrCodeScanner
                                        onScan={(data) => {
                                            const text = data.split(';')[0]
                                            formik.setFieldValue('BrojDokumentaIzlaza', text)
                                        }}
                                    />
                                </div>
                                <div className='col-6'>
                                    <input
                                        type='string'
                                        placeholder='Broj naloga'
                                        {...formik.getFieldProps('BrojDokumentaIzlaza')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {
                                                'is-invalid':
                                                    formik.touched.BrojDokumentaIzlaza && formik.errors.BrojDokumentaIzlaza,
                                            },
                                            {
                                                'is-valid':
                                                    formik.touched.BrojDokumentaIzlaza && !formik.errors.BrojDokumentaIzlaza,
                                            }
                                        )}
                                    />
                                    {formik.touched.Repromaterijal && formik.errors.Repromaterijal && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Repromaterijal}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* Napomena */}
                            <hr/>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Napomena</label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Napomena'
                                        {...formik.getFieldProps('Napomene')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.Napomene && formik.errors.Napomene},
                                            {
                                                'is-valid': formik.touched.Napomene && !formik.errors.Napomene,
                                            }
                                        )}
                                    />
                                    {formik.touched.Napomene && formik.errors.Napomene && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Napomene}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                //disabled={loading || formik.isSubmitting || !formik.isValid}
                                disabled={
                                    loading ||
                                    formik.isSubmitting ||
                                    !formik.isValid ||
                                    !!rolnaDetaljiInfo?.message // Explicitly cast to boolean
                                }
                            >
                                {!loading && 'Potvrdi'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                                )}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}
export default PrometRolneDirektnaProdaja;
