import React from 'react'
import {KTCard, KTCardBody} from '../../../../_websoft/helpers'

const RadniNaloziDashboard = () => {
  return (
    <KTCard>
      <KTCardBody className='py-4'>
        <div className='row'>
          <div className='col-xl-4 mt-2'>
            <a href='spremi-nalog' className='card bg-success hoverable card-xl-stretch mb-xl-8'>
              <div className='card-body'>
                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
                <div className='text-white fw-bold fs-2 mb-2 mt-5 text-uppercase'>Spremi Nalog</div>
              </div>
            </a>
          </div>
          <div className='col-xl-4 mt-2'>
            <a href='isporuci-nalog' className='card bg-info hoverable card-xl-stretch mb-xl-8'>
              <div className='card-body'>
                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
                <div className='text-white fw-bold fs-2 mb-2 mt-5 text-uppercase'>
                  {' '}
                  Isporuci Nalog
                </div>
              </div>
            </a>
          </div>
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export default RadniNaloziDashboard
