import {KTCard, KTCardBody} from '../../../../_websoft/helpers'
import {ToastContainer} from 'react-toastify'
import React from 'react'

const PrometRolneTerminal: React.FC = () => {
    return (
    <KTCard>
        <ToastContainer />
        <KTCardBody className='py-4'>
            <div className='row'>
                <div className='col-xl-4 mt-2'>
                    <a
                        href='promet-proizvodnja'
                        className='card bg-primary hoverable card-xl-stretch mb-xl-8'
                    >
                        <div className='card-body'>
                            <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                                <span className='path4'></span>
                            </i>
                            <div className='text-white fw-bold fs-2 mb-2 mt-5'>Promet rolne kroz proizvodnju</div>
                        </div>
                    </a>
                </div>
                <div className='col-xl-4 mt-2'>
                    <a
                        href='promet-prodaja'
                        className='card bg-success hoverable card-xl-stretch mb-xl-8'
                    >
                        <div className='card-body'>
                            <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                                <span className='path4'></span>
                            </i>
                            <div className='text-white fw-bold fs-2 mb-2 mt-5'>Promet rolne direktna prodaja</div>
                        </div>
                    </a>
                </div>
            </div>
        </KTCardBody>
    </KTCard>
)
}
export default PrometRolneTerminal;
