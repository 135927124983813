import React, {useEffect, useState} from 'react'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import {
    SortingState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import TableSearch from '../../components/TableSearch/TableSearch'
import {Modal, ModalHeader, ToastContainer} from 'react-bootstrap'
import DimenzijeModal from '../../components/DimenzijeModal/DimenzijeModal'
import PakModal from '../../components/PakModal/PakModal'
import notifyToast from '../../components/Toast/Toast'
import moments from 'moment'
import {ArhivaModel, ParamsModelArhiva} from '../../models/ArhivaModel'
import {getArhiva} from '../../api/arhiva'
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox/IndeterminateCheckbox'
import TablePagination from "../../components/TablePagination/TablePagination";
import {PaginationModel} from "../../models/PaginationModel";

const Arhiva = () => {
    const [data, setData] = useState<ArhivaModel[]>([])
    const [loading, setLoading] = useState(false)

    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })

    const [stavkaId, setstavkaId] = useState<number>()
    const [isModalOpen, setisModalOpen] = useState(false)
    const [isDimenzijeModalOpen, setisDimenzijeModalOpen] = useState(false)
    const [params, setparams] = useState<ParamsModelArhiva>({per_page: 10, page: 1})

    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')
    const [rowSelection, setRowSelection] = useState({})
    const [inputValue, setInputValue] = useState<string>('')
    const [inputValue2, setInputValue2] = useState<string>('')
    // Set cell color according to condtions.
    const getCellColor = (cell, row) => {
        // Color for Tr.roba
        if (cell.column.id === 'Status')
            return row.original.statusPro === 'Planirano' ? '#FFFF00' : '#008000'
        // Color default
        return '#FFFFFF'
    }

    const onPakClick = (id) => {
        setisModalOpen(true)
        setstavkaId(id)
    }
    const onDimenzijeClick = (id) => {
        setisDimenzijeModalOpen(true)
        setstavkaId(id)
    }

    const updatePage = () => {
        setLoading(true);
        getArhiva(params)
            .then((response) => {
                const fetchedData = response.data['data']['data'];
                setData(fetchedData);

                const fetchedTotalPages = response.data['data']['last_page'];
                const fetchedCurrentPage = response.data['data']['current_page'];
                const fetchedPerPage = response.data['data']['per_page'] || 10; // Providing a fallback value

                setpagination(prev => ({
                    ...prev,
                    curPage: fetchedCurrentPage,
                    totalPage: fetchedTotalPages,
                    perPage: fetchedPerPage, // Ensures perPage is always a number
                }));
            })
            .catch((error) => {
                console.error('Error: ', error);
                notifyToast(false, error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (params.brojNaloga || params.sifraArtikla) updatePage()
    }, [params])

    const columnHelper = createColumnHelper<ArhivaModel>()
    const columns = [
        {
            id: 'id',
            header: ({table}) => (
                <div className='row p-2'>
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </div>
            ),
            cell: ({row, getValue}) => (
                <div className='col'>
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),
        },
        {
            id: 'Nalog',
            header: 'Nalog',
            accessorKey: 'Nalog',
            cell: ({row}) => (
                <div>
                    <div>
                        N:{row.original.brojNaloga}({row.original.stavka})
                    </div>
                    <div>
                        Vr.n:
                        <KTIcon
                            iconName={row.original.nalogVracen === 0 ? 'cross' : 'check'}
                            className={row.original.nalogVracen === 0 ? 'text-danger fs-2' : 'text-success fs-2'}
                        />
                    </div>
                    <div>P:{row.original.brojPonude}</div>
                </div>
            ),
        },
        {
            id: 'Artikal',
            header: 'Artikal',
            accessorKey: 'artikal',
        },
        {
            id: 'Kupac',
            header: 'Kupac/kreirao',
            cell: ({row}) => (
                <div>
                    {row.original.kupac}
                    <div style={{color: 'red'}}>({row.original.kreiraoKorisnik})</div>
                </div>
            ),
        },
        {
            id: 'Panel',
            header: 'Panel',
            accessorKey: 'tip_panela.Naziv',
        },
        {
            id: 'izolacija',
            header: 'Izolacija',
            accessorKey: 'izolacija_panela.naziv',
        },
        columnHelper.group({
            header: 'Gornji lim (lice)',
            // footer: props => props.column.id,
            columns: [
                columnHelper.accessor('obloga_gore.naziv', {
                    id: 'obloga_gore',
                    cell: (info) => info.getValue(),
                    header: () => <span>Obloga</span>,
                }),
                columnHelper.accessor('debljina_gore.debljina', {
                    id: 'debljina_gore',
                    cell: (info) => info.getValue(),
                    header: () => <span>Debljina</span>,
                }),
                columnHelper.accessor('boja_gore.BojaNaziv', {
                    id: 'boja_gore',
                    cell: (props) => (
                        <div>
                            <span> {props.getValue()}</span>
                            <div className='badge badge-success'>{props.row.original.rolneG}</div>
                        </div>
                    ),
                    header: () => <span>Boja</span>,
                }),
                columnHelper.accessor('folijaG', {
                    id: 'folijaG',
                    cell: (info) => info.getValue(),
                    header: () => <span>Folija</span>,
                }),
            ],
        }),
        columnHelper.group({
            header: 'Donji lim (nalicje)',
            // footer: props => props.column.id,
            columns: [
                columnHelper.accessor('obloga_dole.naziv', {
                    id: 'obloga_dole',
                    cell: (info) => info.getValue(),
                    header: () => <span>Obloga</span>,
                }),
                columnHelper.accessor('debljina_dole.debljina', {
                    id: 'debljina_dole',
                    cell: (info) => info.getValue(),
                    header: () => <span>Debljina</span>,
                }),
                columnHelper.accessor('boja_dole.BojaNaziv', {
                    id: 'boja_dole',
                    cell: (props) => (
                        <div>
                            <span> {props.getValue()}</span>
                            <div className='badge badge-success'>{props.row.original.rolneD}</div>
                        </div>
                    ),
                    header: () => <span>Boja</span>,
                }),
                columnHelper.accessor('folijaD', {
                    id: 'folijaD',
                    cell: (info) => info.getValue(),
                    header: () => <span>Folija</span>,
                }),
            ],
        }),
        {
            id: 'Kolcina',
            header: 'Kolcina [m2]',
            accessorKey: 'kolicina',
            cell: ({row}) => (
                <div>
                    {row.original.kolicina}
                    {row.original.kolicina === row.original.kolicinaPaketa && (
                        <div className='text-danger'>Upozornje</div>
                    )}
                </div>
            ),
        },
        {
            id: 'Brtvena',
            header: 'Brtvena traka',
            accessorKey: 'brtvenaTraka',
        },
        {
            id: 'Status',
            header: 'Status - Proizvodnje',
            accessorFn: (row) => `${row.statusPro} | ${moments(row.statusProDatum).format('DD-MM-YYYY')}`,
        },
        {
            id: 'isporuka',
            header: 'Isporuka',
            accessorFn: (row) => `${moments(row.isporukaFMK).format('DD-MM-YYYY')}`,
        },
        {
            id: 'pak',
            header: 'Pak',
            cell: ({row}) => (
                <button
                    className='btn btn-primary'
                    onClick={() => {
                        onPakClick(row.original.id)
                    }}
                >
                    Pak
                </button>
            ),
        },
        {
            id: 'Dim',
            header: 'Dim',
            cell: ({row}) => (
                <button
                    className='btn btn-primary'
                    onClick={() => {
                        onDimenzijeClick(row.original.id)
                    }}
                >
                    Dim
                </button>
            ),
        },
    ]
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
            rowSelection: rowSelection,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
        onRowSelectionChange: setRowSelection,
        enableRowSelection: true,
    })

    return (
        <KTCard>
            <ToastContainer/>
            <PakModal isModalOpen={isModalOpen} setisModalOpen={setisModalOpen} stavkaId={stavkaId}/>
            <DimenzijeModal
                isModalOpen={isDimenzijeModalOpen}
                setisModalOpen={setisDimenzijeModalOpen}
                stavkaId={stavkaId}
            />
            <KTCardBody className='py-4'>
                <div className='row'>
                    <h3>Pretraga po broju naloga ili šifri artikla</h3>
                    <div className='col-lg-2 col-md-3 col-8 mb-6'>
                        <input
                            name='text'
                            className='form-control mt-4'
                            placeholder='Broj naloga/godina - stavka'
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                    </div>
                    <div className='col-lg-2 col-md-3 col-8 mb-6'>
                        <input
                            name='text'
                            className='form-control mt-4'
                            placeholder='Šifra artikla'
                            value={inputValue2}
                            onChange={(e) => setInputValue2(e.target.value)}
                        />
                    </div>
                    <div className='col-lg-1 col-md-1 col-4 align-self-end mb-6'>
                        <button
                            type='button'
                            className='btn btn-primary p-3'
                            onClick={() => setparams({brojNaloga: inputValue, sifraArtikla: inputValue2})}
                        >
                            <i className='bi bi-search fs-2x'></i>
                        </button>
                    </div>
                </div>
                <div className='d-flex justify-content-between'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)}/>
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <ExportButton table={table} filename={'arhiva'}/>
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <span>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {/* @ts-ignore */}
                                                    {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                          </span>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}

export default Arhiva
