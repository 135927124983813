/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {ArtikliModel, ParamsModelArtikli} from '../../models/ArtikliModel'
import {
    SortingState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import React from 'react'
import notifyToast from '../../components/Toast/Toast'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import {TableActionButton} from '../../components/TableActionBtn/TableActionButton'
import {Link, useNavigate} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import TablePagination from '../../components/TablePagination/TablePagination'
import {PaginationModel} from '../../models/PaginationModel'
import {Modal, ModalHeader} from 'react-bootstrap'
import {ThermoBoardNalozi} from '../../models/ThermoBoardNalozi'
import {deleteTBNalog, getAllNalozi, getPakingListeThermoboard} from '../../api/thermoboardNalozi'
import ThermoBoardPakModal from './components/ThermoBoardPakModal/ThermoBoardPakModal'
import QRCode from 'qrcode'
import {usePDF} from '@react-pdf/renderer'
import ThermoboardPakingListePdf from '../../components/QrCodeGenerator/ThermoboardPakingListePdf'

const ThermoBoardLista: React.FC = () => {
    const [data, setData] = useState<ThermoBoardNalozi[]>([])
    const [loading, setLoading] = useState(true)
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isModalOpen, setisModalOpen] = useState(false)
    const [selectedNalogId, setselectedNalogId] = useState()
    const [params, setparams] = useState<ParamsModelArtikli>({per_page: 10, page: 1, aktivan: 1})

    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')
    const [qrList, setqrList] = useState<string[]>()

    // Set cell color according to condtions.
    const getCellColor = (cell, row) => {
        // Color for Tr.roba
        if (cell.column.id === 'ArtTrgRoba')
            return row.original.ArtTrgRoba === 0 ? '#FF0000' : '#00FF00'
        // Color default
        return '#FFFFFF'
    }

    const updatePage = () => {
        setLoading(true)
        getAllNalozi(params)
            .then((response) => {
                const data = response.data['data']
                setData(data)
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                setpagination({
                    curPage: response.data['current_page'],
                    totalPage: totalPage,
                    perPage: params.per_page,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handlePakingListeBtnClick = (id) => {
        setLoading(true)
        const params = {
            idNaloga: id,
        }
        getPakingListeThermoboard(params)
            .then(async (response) => {
                convertQrSvgToImg(response.data['data'])
                notifyToast(true, 'Success')
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error.response?.data?.message || 'An error occurred')
            })
            .finally(() => setLoading(false))
    }

    const convertQrSvgToImg = async (pakingListe) => {
        if (pakingListe) {
            var list: Array<string> = []
            console.log('paking', pakingListe)
            await pakingListe.map((item) =>
                QRCode.toDataURL(item.qrcode).then((url) => {
                    list.push(url)
                })
            )
            setqrList(list)
            updateInstance(<ThermoboardPakingListePdf qrData={pakingListe} qrList={list}/>)
        }
    }

    const [instance, updateInstance] = usePDF({})

    // Open Pdf when intance link is generated.
    useEffect(() => {
        if (!instance.loading && instance.url && qrList?.length) {
            window.open(instance.url, '_blank')?.focus()
        }
    }, [instance])

    useEffect(() => {
        updatePage()
    }, [params])

    useEffect(() => {
        const {id} = sorting[0] ?? ''
        data.sort((a, b) => a[id] - b[id])
    }, [sorting])

    const columns = [
        {
            id: 'Id',
            header: 'Id',
            accessorKey: 'Id',
        },
        {
            id: 'Vrsta',
            header: 'Vrsta',
            accessorKey: 'tip_panela.Naziv',
        },
        {
            id: 'Nalog',
            header: 'Nalog',
            accessorKey: 'BrojNaloga',
        },
        {
            id: 'Količina',
            header: 'Količina',
            accessorKey: 'Kolicina',
        },
        {
            header: 'Paket',
            accessorKey: 'TipPaketa',
            cell: ({row}) => (
                <div
                    style={{
                        color: row.original.TipPaketa === 1 ? 'green' : 'blue',
                    }}
                >
                    {row.original.TipPaketa === 1 ? 'MEGA' : 'OBICNO'}
                </div>
            ),
        },
        {
            id: 'Broj paketa',
            header: 'Broj paketa',
            accessorKey: 'BrojPaketa',
        },
        {
            id: 'Proizvedeno PAK',
            header: 'Proizvedeno PAK',
            accessorKey: 'ProizvedenoCount',
        },
        {
            id: 'Isporučeno PAK',
            header: 'Isporučeno PAK',
            accessorKey: 'IsporucenoCount',
        },
        {
            id: 'Ostalo',
            header: 'Total [PR-IS]',
            accessorKey: 'Total', // This can be any unique string
            cell: ({row}) => {
                // Perform subtraction here
                const total = row.original.ProizvedenoCount - row.original.IsporucenoCount
                return <div>{total}</div>
            },
        },
        {
            id: 'Kreirao',
            header: 'Kreirao',
            accessorKey: 'uposlenik.UposlenikImePrezime',
        },
        {
            id: 'Pak',
            header: 'Pak',
            cell: ({row}) => (
                <div
                    className='btn btn-primary'
                    onClick={() => {
                        setselectedNalogId(row.original.Id)
                        setisModalOpen(true)
                    }}
                >
                    Pak
                </div>
            ),
        },
        {
            id: 'pdf',
            header: 'pdf',
            cell: ({row}) => (
                <button
                    onClick={() => {
                        handlePakingListeBtnClick(row.original.Id)
                    }}
                >
                    {' '}
                    <KTIcon iconName={'document'} className='fs-1'/>
                </button>
            ),
        },
        {
            header: 'Akcija',
            accessorKey: 'Akcija',
            cell: ({row}) => (
                <TableActionButton
                    onDelete={() => onDelete(row.original.Id)}
                    onEdit={() => {
                        onEdit(row.original.Id)
                    }}
                />
            ),
        },
    ]
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
    })
    const onDelete = (id) => {
        deleteTBNalog(id)
            .then((response) => {
                if (response.error === 0) {
                    notifyToast(true, response.message)
                } else {
                    notifyToast(false, response.message)
                }
                setparams({per_page: pagination.perPage, page: pagination.curPage})
            })
            .catch((error) => {
                notifyToast(false, error.message)
            })
    }
    const navigate = useNavigate()

    const onEdit = (rowData) => {
        navigate(`/thermoboard/izmjena/${rowData}`)
    }

    return (
        <KTCard>
            <ToastContainer/>
            <ThermoBoardPakModal
                isModalOpen={isModalOpen && selectedNalogId}
                setisModalOpen={setisModalOpen}
                idNaloga={selectedNalogId}
            />

            <KTCardBody className='py-4'>
                <div className='d-flex justify-content-between'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)}/>
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <ExportButton table={table} filename={'thermoboard_nalozi'}/>
                        <Link to='/thermoboard/dodaj'>
                            <button type='button' className='btn btn-primary'>
                                <KTIcon iconName='plus' className='fs-2'/>
                                Dodaj
                            </button>
                        </Link>
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}
export default ThermoBoardLista
