import React, { useState } from 'react';
import axios from 'axios';

// Ensure the API URL is correctly set in your environment variables
const API_URL = process.env.REACT_APP_API_URL;
const CHAT_API = `${API_URL}/chat`;

interface IMessage {
    sender: 'user' | 'gpt';
    text: string;
}

export default function ChatWithGPT() {
    const [input, setInput] = useState('');
    const [conversation, setConversation] = useState<IMessage[]>([]);

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInput(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!input.trim()) return;

        const userMessage: IMessage = { sender: 'user', text: input };
        setConversation(prevConversation => [...prevConversation, userMessage]);

        setInput(''); // Clear input field

        try {
            const response = await axios.post(CHAT_API, { prompt: input });
            // Parse the response from the OpenAI chat model
            const gptResponseText = response.data.choices[0].message.content;
            const gptResponse: IMessage = { sender: 'gpt', text: gptResponseText };

            // Update conversation with GPT response
            setConversation(prevConversation => [...prevConversation, gptResponse]);
        } catch (error) {
            console.error('Error:', error);
            // Optionally handle errors, e.g., show an error message
        }
    };

    return (
        <div className="d-flex flex-column justify-content-end h-100">
            <div className="mb-3">
                {conversation.map((msg, index) => (
                    <div key={index} className={`text-${msg.sender === 'user' ? 'primary' : 'danger'}`}>
                        <p>{msg.text}</p>
                    </div>
                ))}
            </div>
            <form onSubmit={handleSubmit} className="d-flex">
                <textarea
                    className="form-control"
                    value={input}
                    onChange={handleInputChange}
                    placeholder="Type your message..."
                    rows={3}
                ></textarea>
                <button type="submit" className="btn btn-primary ms-2">Potvrdi</button>
            </form>
            Modul u izradi
        </div>
    );
}
