import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_websoft/layout/core'
import SredstvaLista from './SredstvaLista'
import SredstvoDetails from './SredstvoDetails'
import PrijavaKvaraLista from './PrijavaKvaraLista'
import DozvoleAtestiLista from './DozvoleAtestiLista'
import DozvoleAtestiDetails from './DozvoleAtestiDetails'
import InterencijeOdrzavanjaLista from './IntervencijeOdrzavanjaLista'
import ZahtjevZaNabavuLista from './ZahtjevZaNabavuLista'
import IntervencijeOdrzavanjaDetails from './IntervencijeOdrzavanjaDetails'
import IntervencijaIzvrsiociLista from './IntervencijaIzvrsiociLista'
import TroskoviSredstavaLista from './TroskoviSredstavaLista'
import TroskoviSredstavaDetails from './TroskoviSredstavaDetails'
import PrijavaKvaraDetails from './PrijavaKvaraDetails'
import ZahtjevZaNabavuDetails from './ZahtjevZaNabavuDetails'
import ViljuskariZapisiLista from "./ViljuskariZapisiLista";
import SredstvaQrCodePdf from "../../components/QrCodeGenerator/SredstvaQRCodePdf";

const SredstvaBreadCrumbs: Array<PageLink> = [
  {
    title: 'Sredstva',
    path: '/Sredstva/dodaj',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const data = {
    SredstvoInvBroj : '',
    SredstvoNaziv : ''
};

const SredstvaRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='lista'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Lista</PageTitle>
              <SredstvaLista />
            </>
          }
        />
        <Route
          path='dodaj'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Dodaj</PageTitle>
              <SredstvoDetails />
            </>
          }
        />
        <Route
          path='izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Izmjena</PageTitle>
              <SredstvoDetails />
            </>
          }
        />
        <Route
          path='prijava-kvara'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Prijave kvarova</PageTitle>
              <PrijavaKvaraLista />
            </>
          }
        />
        <Route
          path='prijava-kvara/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Dodaj</PageTitle>
              <PrijavaKvaraDetails />
            </>
          }
        />

        <Route
          path='dozvole-atesti'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>
                Dozvole, Atesti, Upotrebne dozvole
              </PageTitle>
              <DozvoleAtestiLista />
            </>
          }
        />
        <Route
          path='dozvole-atesti/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Dodaj dokument</PageTitle>
              <DozvoleAtestiDetails />
            </>
          }
        />
        <Route
          path='dozvole-atesti/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Izmjena dokumenta</PageTitle>
              <DozvoleAtestiDetails />
            </>
          }
        />
        <Route
          path='intervencije-odrzavanja'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Intervencije održavanja</PageTitle>
              <InterencijeOdrzavanjaLista />
            </>
          }
        />
        <Route
          path='intervencije-odrzavanja/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Kreiraj Intervencije</PageTitle>
              <IntervencijeOdrzavanjaDetails />
            </>
          }
        />
        <Route
          path='intervencije-odrzavanja/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Izmjena Intervencije</PageTitle>
              <IntervencijeOdrzavanjaDetails />
            </>
          }
        />
        <Route
          path='intervencije-izvrsioci'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Intervencije Izvrsioci</PageTitle>
              <IntervencijaIzvrsiociLista />
            </>
          }
        />
        <Route
          path='zahtjev-za-nabavu'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Zahtjev za nabavu</PageTitle>
              <ZahtjevZaNabavuLista />
            </>
          }
        />
        <Route
          path='zahtjev-za-nabavu/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Zahtjev za nabavu</PageTitle>
              <ZahtjevZaNabavuDetails />
            </>
          }
        />
          <Route
              path='zapisi-viljuskara'
              element={
                  <>
                      <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Zapisi viljuškara</PageTitle>
                      <ViljuskariZapisiLista />
                  </>
              }
          />
        <Route
          path='zahtjev-za-nabavu/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Izmjena dokumenta</PageTitle>
              <ZahtjevZaNabavuDetails />
            </>
          }
        />
        <Route
          path='troskovi'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Sredstva Troškovi</PageTitle>
              <TroskoviSredstavaLista />
            </>
          }
        />
        <Route
          path='troskovi/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Sredstva Troškovi Dodaj</PageTitle>
              <TroskoviSredstavaDetails />
            </>
          }
        />
        <Route
          path='troskovi/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Sredstva Troškovi Izmjena</PageTitle>
              <TroskoviSredstavaDetails />
            </>
          }
        />
          <Route
              path='sredstvo-qr-code'
              element={
                  <>
                      <PageTitle breadcrumbs={SredstvaBreadCrumbs}>Sredstva Troškovi Izmjena</PageTitle>
                      <SredstvaQrCodePdf qrData={data} />
                  </>
              }
          />

        <Route index element={<Navigate to='/sredstva/Lista' />} />
      </Route>
    </Routes>
  )
}

export default SredstvaRoutes
