/* eslint-disable react-hooks/exhaustive-deps */
import React, {Suspense, lazy, useEffect, useState} from 'react'
import KupciPanelaDropdown from '../KupciPanelaDropdown/KupciPanelaDropdown'
import {KTCard, KTCardBody, KTIcon} from '../../../../../_websoft/helpers'
import {ToastContainer} from 'react-toastify'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import {getKupciPregled, getServisUtovarPaketa, utovariPakete} from '../../../../api/proizvodnja'
import notifyToast from '../../../../components/Toast/Toast'
import {PrikolicaKamionaModel, ServisUtovarPaketaModel} from '../../../../models/ProizvodnjaModel'
import {useNavigate} from 'react-router-dom'
import {usePDF} from '@react-pdf/renderer'

const KupciPregled = () => {
    const [kupci, setkupci] = useState<any>()
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState({})
    const [sirina, setsirina] = useState<string>()
    const [visina, setvisina] = useState<string>()
    const [duzina, setduzina] = useState<string>()
    const [prikolicaKamiona, setprikolicaKamiona] = useState<PrikolicaKamionaModel>()
    const [apiCreds, setapiCreds] = useState<ServisUtovarPaketaModel>()

    const [instance, updateInstance] = usePDF({})
    useEffect(() => {
        if (kupci) {
            setloading(true)
            getKupciPregled(kupci.value)
                .then((response) => {
                    setdata(response)
                })
                .catch((error) => {
                    console.error(error)
                    notifyToast(false, error?.response.data.message ?? error)
                })
                .finally(() => setloading(false))
        }
    }, [kupci])

    useEffect(() => {
        getServisUtovarPaketa()
            .then((response) => {
                setapiCreds(response)
            })
            .catch((error) => notifyToast(false, error.response.data.message))
            .finally(() => setloading(false))
    }, [])

    const [checkboxArray, setcheckboxArray] = useState<String[]>([])
    const checkboxKeys: String[] = []

    const [selectedRadio, setselectedRadio] = useState<number>()

    useEffect(() => {
        if (selectedRadio === 1) {
            setprikolicaKamiona({w: '245', h: '270', d: '1360', max_wg: '15000', id: 1})
        } else if (selectedRadio === 2) {
            setprikolicaKamiona({w: '245', h: '300', d: '1360', max_wg: '15000', id: 1})
        } else if (selectedRadio === 3) {
            setprikolicaKamiona({
                w: sirina ?? '',
                h: visina ?? '',
                d: duzina ?? '',
                max_wg: '15000',
                id: 1,
            })
        }
    }, [selectedRadio, sirina, visina, duzina])
    const navigate = useNavigate()
    const onUtovariClick = () => {
        setloading(true)
        const paketi: any = []
        checkboxArray.forEach((item) => {
            const a: any = item.split('-')
            paketi.push({
                w: a[2] / 10,
                h: a[4] / 10,
                d: a[3] / 10,
                q: '1',
                vr: '0',
                wg: '0',
                id: a[0] + '-' + a[1] + '-' + a[5],
            })
        })
        const params = {
            username: apiCreds?.username,
            api_key: apiCreds?.apiKey,
            bins: [prikolicaKamiona],
            items: paketi,

            params: {
                images_background_color: '255,255,255',
                images_bin_border_color: '59,59,59',
                images_bin_fill_color: '230,230,230',
                images_item_border_color: '214,79,79',
                images_item_fill_color: '177,14,14',
                images_item_back_border_color: '215,103,103',
                images_sbs_last_item_fill_color: '99,93,93',
                images_sbs_last_item_border_color: '145,133,133',
                images_width: '100',
                images_height: '100',
                images_source: 'file',
                images_sbs: '1',
                stats: '1',
                item_coordinates: '1',
                images_complete: '1',
                images_separated: '1',
            },
        }

        utovariPakete(params)
            .then((response) => {
                navigate(`/paneli/lista/kupci-pregled/utovar-kamiona`, {
                    state: {data: response.response, kupac: kupci.name},
                })
            })
            .catch((error) => notifyToast(false, error))
            .finally(() => setloading(false))
    }

    const processPdf = () => {
        navigate(`/paneli/lista/kupci-pregled/process-pdf/${kupci.value}`, {
            state: {
                kupac: kupci.name,
            },
        })
    }

    return (
        <KTCard>
            <ToastContainer/>
            <KTCardBody className='py-4'>
                <div className='card-body border-top p-9 row'>
                    {/* Kupci dropdown */}
                    <div className='col-lg-8 col-md-6 '>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Kupci Panela</label>
                        <div className='col-lg-8 fv-row'>
                            <KupciPanelaDropdown
                                value={kupci}
                                onChange={(name, value) => setkupci({name: name, value: value})}
                                selectName='Kupci'
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <button
                            className='btn btn-success'
                            onClick={onUtovariClick}
                            disabled={!checkboxArray.length || !selectedRadio}
                        >
                            {!loading && (
                                <>
                                    <KTIcon iconName='truck' className='fs-1'/>
                                    <br/>
                                    Utovari U Kamion
                                </>
                            )}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                            )}
                        </button>
                    </div>
                    {kupci && (
                        <form className='py-5'>
                            <label className='col-lg-4 col-form-label'>Izaberi dimenzije prikolice</label>
                            <div className='d-flex justify-content-between form-check p-5'>
                                <div className='col-3' id='radioId'>
                                    <input
                                        className='form-check-input'
                                        type='radio'
                                        name='flexRadioDefault'
                                        id='flexRadioDefault1'
                                        onChange={(e) => setselectedRadio(1)}
                                    />
                                    <label className='form-check-label'>STANDARDNI KAMION</label>
                                </div>
                                {selectedRadio === 1 && (
                                    <div className='row'>
                                        <div className='col-2'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid '
                                                disabled
                                                value={245}
                                            />
                                        </div>
                                        <div className='col-2'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid '
                                                disabled
                                                value={270}
                                            />
                                        </div>
                                        <div className='col-2'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid '
                                                disabled
                                                value={1360}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='d-flex justify-content-between form-check p-5'>
                                <div className='col-3'>
                                    <input
                                        className='form-check-input'
                                        type='radio'
                                        name='flexRadioDefault'
                                        id='flexRadioDefault2'
                                        value={1}
                                        onChange={(e) => setselectedRadio(2)}
                                    />
                                    <label className='form-check-label'>MAXI/MEGA KAMION</label>
                                </div>
                                {selectedRadio === 2 && (
                                    <div className='row'>
                                        <div className='col-2'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid '
                                                disabled
                                                value={245}
                                            />
                                        </div>
                                        <div className='col-2'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid '
                                                disabled
                                                value={300}
                                            />
                                        </div>
                                        <div className='col-2'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid '
                                                disabled
                                                value={1360}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='d-flex justify-content-between form-check p-5'>
                                <div className='col-3'>
                                    <input
                                        className='form-check-input'
                                        type='radio'
                                        name='flexRadioDefault'
                                        id='flexRadioDefault3'
                                        value={3}
                                        onChange={(e) => setselectedRadio(3)}
                                    />
                                    <label className='form-check-label'>OPCIONALNO [cm]</label>
                                </div>
                                {selectedRadio === 3 && (
                                    <div className='row'>
                                        <div className='col-2'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid '
                                                value={sirina}
                                                onChange={(e) => setsirina(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-2'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid '
                                                value={visina}
                                                onChange={(e) => setvisina(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-2'>
                                            <input
                                                type='number'
                                                className='form-control form-control-lg form-control-solid '
                                                value={duzina}
                                                onChange={(e) => setduzina(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </form>
                    )}
                </div>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <>
                        <button
                            className='btn btn-primary m-6'
                            onClick={() => {
                                if (checkboxArray.length === 0) {
                                    setcheckboxArray(checkboxKeys)
                                } else {
                                    setcheckboxArray([])
                                }
                            }}
                            hidden={!kupci}
                        >
                            {checkboxArray.length === 0 ? 'Select all' : 'Deselect all'}
                        </button>

                        {kupci && (
                            <button className='btn btn-dark m-6' onClick={processPdf}>
                                Kreiraj PDF
                            </button>
                        )}

                        {/* @ts-ignore */}
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <tbody className='text-gray-600'>
                            {Object.values(data).length > 0 && (
                                <div style={{height: '100vh', width: '100%', overflow: 'scroll'}}>
                                    {Object.values(data).map((item, index) => (
                                        <div key={index}>
                                            <div>
                                                <div className='bg-success col'>
                                                    <div className='col'>
                                                        {' '}
                                                        <div className='h5 d-flex justify-content-center py-5'>
                                                            Stanje paketa prema nalogu/ponudi
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-4 h6'>
                                                                {/* @ts-ignore */}
                                                                Ponuda: {item?.shared.brojPonude ?? ''}
                                                            </div>
                                                            <div className='col-4 h6'>
                                                                {/* @ts-ignore */}
                                                                Nalog: {item?.shared.brojNaloga ?? ''}  ({item?.shared.stavka ?? ''})
                                                            </div>
                                                            <div className='col-4 h6'>
                                                                {/* @ts-ignore */}
                                                                Vrsta: {item?.shared.vrsta ?? ''}  Boja:  {item?.shared.boja ?? ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* @ts-ignore */}
                                            {Object.entries(item).map(([key, value]) => {
                                                var val = ''
                                                if (key !== 'shared') {
                                                    // @ts-ignore
                                                    val = `${
                                                        // @ts-ignore
                                                        item.shared.brojNaloga
                                                    } | ${
                                                        // @ts-ignore
                                                        item.shared.stavka
                                                        // @ts-ignore
                                                    }-${key}-${value.gabarit.replaceAll(
                                                        ' X ',
                                                        '-' //@ts-ignore
                                                    )}-${item.shared.brojPonude}`
                                                    checkboxKeys.push(val)
                                                }
                                                if (key !== 'shared')
                                                    return (
                                                        <div className='row'>
                                                            <div className='col-1 align-content-center'>
                                                                <input
                                                                    type='checkbox'
                                                                    // key={`${outerKey}_${key}`}
                                                                    key={val}
                                                                    value={val}
                                                                    checked={checkboxArray.includes(val)}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setcheckboxArray([...checkboxArray, val])
                                                                        } else {
                                                                            const ca = checkboxArray.filter(
                                                                                (e) => e !== val
                                                                            )
                                                                            setcheckboxArray(ca)
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='bg-secondary col-3'>
                                                                {/* @ts-ignore */}
                                                                <div className='h6'>Paket: {key}</div>
                                                                {/* @ts-ignore */}
                                                                <div className='h6'>[S x D x V]: {value.gabarit}</div>
                                                            </div>
                                                            {/* <td> */}
                                                            <div className='col-8'>
                                                                <table className='table table-bordered no-footer '>
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Dužina</th>
                                                                        <th>Količina</th>
                                                                        <th>M2</th>
                                                                        <th>Status</th>
                                                                        <th>Isporučen</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        // @ts-ignore
                                                                        Object.entries(value.data).map(([key, val]) => (
                                                                            <tr>
                                                                                {/* @ts-ignore */}
                                                                                <td>{val.duzina}</td>
                                                                                {/* @ts-ignore */}
                                                                                <td>{val.brPanela}</td>
                                                                                {/* @ts-ignore */}
                                                                                <td>{val.kvadratura}</td>
                                                                                <td
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                        //@ts-ignore
                                                                                        item.shared.statusBoja,
                                                                                    }}
                                                                                >
                                                                                    {/* @ts-ignore */}
                                                                                    {item.shared.status}
                                                                                </td>
                                                                                {/* @ts-ignore */}
                                                                                <td
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                        // @ts-ignore
                                                                                        item.shared
                                                                                            .isporucenBoja2,
                                                                                    }}
                                                                                >
                                                                                    {/* @ts-ignore */}
                                                                                    {item.shared.isporucen}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            {/* </td> */}
                                                        </div>
                                                    )
                                            })}
                                            <tr>
                                                <td colSpan={2} className='h6'>
                                                    Ukupna Količina stavke Na Nalogu
                                                </td>
                                                <td className='h6'>
                                                    {/* @ts-ignore */}
                                                    {item.shared.totalKvadratura}
                                                </td>
                                            </tr>
                                        </div>
                                    ))
                                    }
                                </div>
                            )}
                            </tbody>
                        </table>
                    </>
                )}
            </KTCardBody>
        </KTCard>
    )
}

export default KupciPregled
