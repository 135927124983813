/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'

import {PaginationModel} from '../../../models/PaginationModel'
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {getAktivniZapisiProizvodnje} from '../../../api/zapisiProizvodnje'
import notifyToast from '../../../components/Toast/Toast'
import {KTCard, KTCardBody, KTIcon} from '../../../../_websoft/helpers'
import {ToastContainer} from 'react-toastify'
import TableSearch from '../../../components/TableSearch/TableSearch'
import ExportButton from '../../../components/TableExportToExcel/ExportButton'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
import TablePagination from '../../../components/TablePagination/TablePagination'
import {
  ParamsModelZapisiProizvodnje,
  ZapisiProizvodnjeModel,
} from '../../../models/ZapisiProizvodnjeModel'
import {useNavigate} from 'react-router-dom'
import SkartZaNalogModal from './components/SkartZaNalogModal'

const AktivniNalozi: React.FC = () => {
  const [data, setData] = useState<ZapisiProizvodnjeModel[]>([])
  const [loading, setLoading] = useState(true)
  const [pagination, setpagination] = useState<PaginationModel>({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setparams] = useState<ParamsModelZapisiProizvodnje>({per_page: 10, page: 1})
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')

  const [isModalOpen, setisModalOpen] = useState(false)
  const [selectedRow, setselectedRow] = useState<ZapisiProizvodnjeModel>()

  const updatePage = () => {
    setLoading(true)
    getAktivniZapisiProizvodnje(params)
      .then((response) => {
        const data = response.data['data']
        setData(data)
        const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
        setpagination({
          curPage: response.data['current_page'],
          totalPage: totalPage,
          perPage: params.per_page,
        })
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Set cell color according to condtions.
  const getCellColor = (cell, row) => {
    // Color for Tr.roba
    if (cell.column.id === 'Status') return row.original.Aktivan === 1 ? '#008000' : '#FF0000'
    // Color default
    return '#FFFFFF'
  }
  const navigate = useNavigate()
  const RedirectToComponents = (id) => {
    const handleClick = () => {
      navigate(`${id}`)
    }
    return (
      <button onClick={handleClick} className='btn btn-info'>
        <i className='bi bi-database-add'></i>ADD
      </button>
    )
  }

  useEffect(() => {
    updatePage()
  }, [params])
  useEffect(() => {
    const {id} = sorting[0] ?? ''
    data.sort((a, b) => a[id] - b[id])
  }, [sorting])

  const columns = [
    {
      id: 'Id',
      header: 'Id',
      accessorKey: 'Id',
    },
    {
      id: 'BrojNaloga',
      header: 'Broj Naloga',
      accessorKey: 'BrojNaloga',
    },
    {
      id: 'Stavka',
      header: 'Stavka',
      accessorKey: 'Stavka',
    },
    {
      id: 'Kupac',
      header: 'Kupac',
      accessorKey: 'proizvodnja.kupac',
    },
    {
      id: 'Komercijalista',
      header: 'Komercijalista',
      accessorKey: 'proizvodnja.kreiraoKorisnik',
    },
    {
      id: 'Smjena',
      header: 'Smjena',
      accessorKey: 'Smjena',
    },
    {
      id: 'Masina',
      header: 'Mašina',
      accessorKey: 'Masina',
    },
    {
      id: 'NalogZavrsen',
      header: 'Završen',
      accessorKey: 'NalogZavrsen',
    },
    {
      id: 'Kreirao',
      header: 'Kreirao',
      accessorKey: 'uposlenik.UposlenikImePrezime',
    },
    {
      id: 'plus',
      header: 'Plus',
      cell: ({row}) => RedirectToComponents(row.original.Id),
    },
    {
      id: 'skart',
      header: 'Škart',
      cell: ({ row }) => (
          row.original.NalogZavrsen === "DA" ? (
              <button
                  onClick={() => {
                    setselectedRow(row.original)
                    setisModalOpen(true)
                  }}
                  className='btn btn-danger'
              >
                <KTIcon iconName='check' className='fs-2' />
              </button>
          ) : null // or <></>, which is a shorthand for null in JSX
      ),
    }

  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })

  return (
    <KTCard>
      <ToastContainer />
      {selectedRow && (
        <SkartZaNalogModal
          isModalOpen={isModalOpen}
          setisModalOpen={setisModalOpen}
          nalogData={selectedRow}
        />
      )}
      <KTCardBody className='py-4'>
        {/* Broj naloga */}

        <div className='d-flex justify-content-between'>
          <div data-kt-user-table-toolbar='base'>
            <TableSearch onSearch={(text) => setGlobalFilter(text)} />
          </div>
          <div data-kt-user-table-toolbar='base'>
            <ExportButton table={table} filename={'zapisi_broizvodnje'} />
          </div>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3 '>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className='text-white'
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* @ts-ignore */}
                        {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <TablePagination
          updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
          pagination={pagination}
        />
      </KTCardBody>
    </KTCard>
  )
}
export default AktivniNalozi
