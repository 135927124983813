import axios from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
const GRADOVI_DROPDOWN = `${API_URL}/gradoviDropdown`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

// Get request for Gradovi dropdown data.
export async function getGradovi() {
  return await axios.get<Array<Option>>(GRADOVI_DROPDOWN, {headers: headers})
}
