// PageTemplateLandscape.js
import { Page, Image, View, StyleSheet } from '@react-pdf/renderer';
import memorandum from '../../../../../_websoft/assets/images/Memorandum_2023-04.jpg';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    backgroundImage: {
        position: 'absolute',  // Ensures the image covers the full page
        minWidth: '100%',       // Minimum width to 100% of the page
        minHeight: '100%',      // Minimum height to 100% of the page
        top: 0,
        left: 0,
        width: '100%',          // Width to 100% of the page
        height: '100%',         // Height to 100% of the page
        zIndex: 1,              // Ensures it's under the content
    },
    content: {
        marginTop: 0,  // Adjust if necessary
        marginHorizontal: 0,  // Adjust if necessary
        zIndex: 2,
        flex: 1,  // Takes up all available space
    },
});


const PageTemplateLandscape = ({ children }) => (
    <Page size='A4' orientation='landscape' style={styles.page}>
        <View style={styles.content}>
            <Image style={styles.backgroundImage} src={memorandum} />
            {children}
        </View>
    </Page>
);

export default PageTemplateLandscape;
