import React from 'react'
import {KTIcon} from '../../../_websoft/helpers'

const FmkNaloziComponent = ({iconName, title, subtitle}) => {
  return (
    <div className='d-flex align-items-center mb-7'>
      {/* begin::Symbol */}
      <div className='symbol symbol-50px me-5'>
        <span className='symbol-label bg-light-warning'>
          <KTIcon iconName={iconName} className='fs-2x text-warning' />
        </span>
      </div>
      {/* end::Symbol */}
      {/* begin::Text */}
      <div className='d-flex flex-column'>
        <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
          {title}
        </a>
        <span className='text-muted fw-semibold'>{subtitle}</span>
      </div>
      {/* end::Text */}
    </div>
  )
}

export default FmkNaloziComponent
