import React from 'react';
import {Document, Text, View, StyleSheet, Font, Page, Image} from '@react-pdf/renderer';
import PageTemplateLandscapeNoFooter from "../PdfPageTemplate/PageTmplLandscapeNoFooter";
import memorandum from '../../../../../_websoft/assets/images/Memorandum-landscape-bez-footera.jpg'
import moment from 'moment'
import PageTemplatePortrait from "../PdfPageTemplate/PageTemplatePortrait";

Font.register({
    family: 'Roboto',
    src: 'https://path/to/roboto-regular-webfont.ttf',
});

const styles = StyleSheet.create({
    page: {
        fontFamily: "Roboto",
        padding: 20,
    },
    header: {
        fontSize: 18,
        marginBottom: 10,
        textAlign: 'left',
        fontWeight: 'bold',
    },
    header2: {
        fontSize: 18,
        marginTop: 10,
        textAlign: 'left',
        fontWeight: 'bold',
    },
    table: {
        width: '100%',
        marginTop: 5,
        borderWidth: 1,
        borderColor: '#000000',
        borderStyle: 'solid',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#000000',
        borderBottomStyle: 'solid',
        alignItems: 'center',
        height: 30,
        fontSize: 10,
        textAlign: 'center',
    },
    tableColumnHeader: {
        flex: 1,
        textAlign: 'center',
        fontWeight: 'bold',
        backgroundColor: '#FF0000',
        color: '#FFFFFF',
        padding: 5,
        borderRightWidth: 1,
        borderRightColor: '#000000',
        borderRightStyle: 'solid',
    },
    tableColumn: {
        flex: 1,
        textAlign: 'center',
        padding: 5,
        borderRightWidth: 1,
        borderRightColor: '#000000',
        borderRightStyle: 'solid',
    },
    tableColumn2: {
        flex: 1,
        textAlign: 'center',
        padding: 5,
        borderRightWidth: 1,
        borderRightColor: '#000000',
        borderRightStyle: 'solid',
    },
    lastColumn: {
        borderRightWidth: 0,
    },
});

const preparePageData = (nalog: Nalog): PageData[] => {
    const pages: PageData[] = [];

    nalog.paketi.forEach((paket, index) => {
        pages.push({
            proizvodnja: nalog.proizvodnja,
            paket: paket,
            zapisi: nalog.zapisi[index],
            zapisiStavke: nalog.zapisiStavke[index],
            kontrolaKvaliteta: nalog.kontrolaKvaliteta
        });
    });

    return pages;
};

interface Proizvodnja {
    brojNaloga: string;
    stavka: string;
    brojPonude: string;
    artikal: string;
    kreiraoKorisnik: string;
    kupac: string;
    statusPro: string;
    statusProDatum: Date;
}

interface Paket {
    nazivPanela: string;
    duzina: number;
    sirinaPaketa: number;
    visinaPaketa: number;
    brPanela: number;
    uposlenik?: { UposlenikImePrezime: string };
    kontrolisao_uposlenik?: { UposlenikImePrezime: string };
    isporucenDatum: Date;
}

interface Nalog {
    proizvodnja: Proizvodnja;
    paketi: Paket[];
    zapisi: any[];  // Define this more specifically if possible
    zapisiStavke: any[];  // Define this more specifically if possible
    kontrolaKvaliteta: any;  // Define this more specifically if possible
}

interface PageData {
    proizvodnja: Proizvodnja;
    paket: Paket;
    zapisi: any;  // Define this more specifically if possible
    zapisiStavke: any;  // Define this more specifically if possible
    kontrolaKvaliteta: any;  // Define this more specifically if possible
}

const NalogIzvjestajPdf = ({nalog}) => {
    console.log(nalog)
    const {proizvodnja, paketi, zapisi, zapisiStavke, kontrolaKvaliteta} = nalog;
    const pageData = preparePageData(nalog); // Use the function to prepare the data
    return (
        <Document style={styles.page}>
            <Page size='A4' orientation='landscape' style={styles.page}>
                <View >
                   {/* <Image style={styles.backgroundImage} src={memorandum}/>*/}

                    <Text style={styles.header}>Nalog: {proizvodnja.brojNaloga} / {proizvodnja.stavka}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableColumnHeader}>Broj Naloga</Text>
                            <Text style={styles.tableColumnHeader}>Ponuda</Text>
                            <Text style={styles.tableColumnHeader}>Artikal</Text>
                            <Text style={styles.tableColumnHeader}>Komercijalista</Text>
                            <Text style={styles.tableColumnHeader}>Kupac</Text>
                            <Text style={styles.tableColumnHeader}>Status</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableColumn}>{proizvodnja.brojNaloga} / {proizvodnja.stavka}</Text>
                            <Text style={styles.tableColumn}>{proizvodnja.brojPonude}</Text>
                            <Text style={styles.tableColumn}>{proizvodnja.artikal}</Text>
                            <Text style={styles.tableColumn}>{proizvodnja.kreiraoKorisnik}</Text>
                            <Text style={styles.tableColumn}>{proizvodnja.kupac}</Text>
                            <Text
                                style={styles.tableColumn}>{proizvodnja.statusPro} / {moment(proizvodnja.statusProDatum).format('DD-MM-YYYY HH:mm')}</Text>
                        </View>
                    </View>
                    <Text style={styles.header2}>Paketi</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableColumnHeader}>Paket #</Text>
                            <Text style={styles.tableColumnHeader}>Dimenzije</Text>
                            <Text style={styles.tableColumnHeader}>Komada</Text>
                            <Text style={styles.tableColumnHeader}>Isporučio</Text>
                            <Text style={styles.tableColumnHeader}>Kontrolisao</Text>
                            <Text style={styles.tableColumnHeader}>Isporučen</Text>
                        </View>
                        {paketi.map((paket, index) => (
                            <View key={index} style={styles.tableRow}>
                                <Text style={styles.tableColumn}>{paket.nazivPanela}</Text>
                                <Text
                                    style={styles.tableColumn}>{paket.duzina}/{paket.sirinaPaketa}/{paket.visinaPaketa}</Text>
                                <Text style={styles.tableColumn}>{paket.brPanela}</Text>
                                <Text
                                    style={styles.tableColumn}>{paket.uposlenik ? paket.uposlenik.UposlenikImePrezime : 'N/A'}</Text>
                                <Text
                                    style={styles.tableColumn}>{paket.kontrolisao_uposlenik ? paket.kontrolisao_uposlenik.UposlenikImePrezime : 'N/A'}</Text>
                                <Text
                                    style={styles.tableColumn}>{moment(paket.isporucenDatum).format('DD-MM-YYYY HH:mm')}</Text>
                            </View>
                        ))}
                    </View>
                    <Text style={styles.header2}>Zapisi proizvodnje</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableColumnHeader}>Smjena</Text>
                            <Text style={styles.tableColumnHeader}>Linija</Text>
                            <Text style={styles.tableColumnHeader}>Nalog Završen</Text>
                            <Text style={styles.tableColumnHeader}>Nužni Škart</Text>
                            <Text style={styles.tableColumnHeader}>Škart Upisan</Text>
                            <Text style={styles.tableColumnHeader}>Razlog Škarta</Text>
                            <Text style={styles.tableColumnHeader}>Upisao Škart</Text>
                        </View>
                        {zapisi.map((zapis, index) => (
                            <View key={index} style={styles.tableRow}>
                                <Text style={styles.tableColumn}>{zapis.Smjena}</Text>
                                <Text style={styles.tableColumn}>{zapis.Masina}</Text>
                                <Text style={styles.tableColumn}>{zapis.NalogZavrsen}</Text>
                                <Text style={styles.tableColumn}>{zapis.NuzniSkart}</Text>
                                <Text style={styles.tableColumn}>{zapis.SkartUpisan}</Text>
                                <Text style={styles.tableColumn}>{zapis.RazlogSkarta}</Text>
                                <Text style={styles.tableColumn}>{zapis.UpisaoSkart}</Text>
                            </View>
                        ))}
                    </View>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableColumnHeader}>Parametar</Text>
                            <Text style={styles.tableColumnHeader}>Vrijednost</Text>
                            <Text style={styles.tableColumnHeader}>Uposlenik</Text>
                            <Text style={styles.tableColumnHeader}>Datum</Text>
                        </View>
                        {Object.keys(zapisiStavke).map((key) => (
                            zapisiStavke[key].map((stavka, index) => (
                                <View key={index} style={styles.tableRow}>
                                    <Text style={styles.tableColumn2}>{stavka.MetaKey}</Text>
                                    <Text style={styles.tableColumn2}>{stavka.MetaValue}</Text>
                                    <Text style={styles.tableColumn2}>{stavka.uposlenik.UposlenikImePrezime}</Text>
                                    <Text
                                        style={styles.tableColumn2}>{moment(stavka.Kreirano).format('DD-MM-YYYY HH:mm')}</Text>
                                </View>
                            ))
                        ))}
                    </View>
                    <Text style={styles.header2}>Kontrola kvaliteta zapisi</Text>
                    {kontrolaKvaliteta.map((item, index) => (
                        <View key={index} style={styles.table}>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>Uzorak na dužnom m:</Text>
                                <Text style={styles.tableColumn}>{item.UzorakNaDuznomM}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>Gustoca Uzorka 1:</Text>
                                <Text style={styles.tableColumn}>{item.GustocaUzorka1}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>Gustoca Uzorka 2:</Text>
                                <Text style={styles.tableColumn}>{item.GustocaUzorka2}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>Gustoca Uzorka 3:</Text>
                                <Text style={styles.tableColumn}>{item.GustocaUzorka3}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>Gusina pjene:</Text>
                                <Text style={styles.tableColumn}>{item.A8GustinaPjene}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A1 Zatezna Cvrstoca U1:</Text>
                                <Text style={styles.tableColumn}>{item.A1ZateznaCvrstocaU1}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A1 Zatezna Cvrstoca U2:</Text>
                                <Text style={styles.tableColumn}>{item.A1ZateznaCvrstocaU2}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A1 Zatezna Cvrstoca U3:</Text>
                                <Text style={styles.tableColumn}>{item.A1ZateznaCvrstocaU3}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A1 Zatezni Modul Ecc:</Text>
                                <Text style={styles.tableColumn}>{item.A1ZatezniModulEcc}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A2 PritisnaCvrstoca Uzorak 2:</Text>
                                <Text style={styles.tableColumn}>{item.A2PritisnaCvrstocaUzorak2}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A2 PritisnaCvrstoca Uzorak 3:</Text>
                                <Text style={styles.tableColumn}>{item.A2PritisnaCvrstocaUzorak3}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A2 Modul Ect:</Text>
                                <Text style={styles.tableColumn}>{item.A2ModulEct}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A3 CvrstocaSmicanja 1:</Text>
                                <Text style={styles.tableColumn}>{item.A3CvrstocaSmicanja1}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A3 CvrstocaSmicanja 2:</Text>
                                <Text style={styles.tableColumn}>{item.A3CvrstocaSmicanja2}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A3 CvrstocaSmicanja 3:</Text>
                                <Text style={styles.tableColumn}>{item.A3CvrstocaSmicanja3}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A3 ModulSmicanja:</Text>
                                <Text style={styles.tableColumn}>{item.A3ModulSmicanja}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A4 Cvrstoca Na Smicanje:</Text>
                                <Text style={styles.tableColumn}>{item.A4CvrstocaNaSmicanje}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A4 Modul Smicanja:</Text>
                                <Text style={styles.tableColumn}>{item.A4ModulSmicanja}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A5 Napon Guzvanja:</Text>
                                <Text style={styles.tableColumn}>{item.A5NaponGuzvanja}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A10 Lamda:</Text>
                                <Text style={styles.tableColumn}>{item.A10Lamda}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>A10 Gustoca Uzorka:</Text>
                                <Text style={styles.tableColumn}>{item.A10GustocaUzorka}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>Uzorak Zadovoljava:</Text>
                                <Text style={styles.tableColumn}>{item.UzorakZadovoljava}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>Radila Smjena:</Text>
                                <Text style={styles.tableColumn}>{item.RadilaSmjena}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColumnHeader}>Komentar:</Text>
                                <Text style={styles.tableColumn}>{item.Komentar}</Text>
                            </View>
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    );
};

export default NalogIzvjestajPdf;
