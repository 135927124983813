import {ToastContainer} from "react-toastify";
import TitleComponent from "../../../components/TitleComponent/TitleComponent";
import QrCodeScanner from "../../../components/QrCodeScanner/QrCodeScanner";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import {isporuciPaketeQRApi} from "../../../api/paketiPanela";
import notifyToast from "../../../components/Toast/Toast";
import {useNavigate, useParams} from "react-router-dom";
import {saveViljuskarZapisiData} from "../../../api/viljuskari";
import {sredstvoInfo} from "../../../api/fmkNalozi";

interface MetaData {
    meta_key: string;
    meta_value: string;
}

const ViljuskariZapisiDetails:React.FC = () => {

    const [loading, setLoading] = useState(false);
    const [naziv, setNaziv] = useState("");
    const [pocetakSati, setPocetakSati] = useState("");
    const [sredstvoId, setSredstvoId] = useState("");
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        GORIVO: '',
        PLIN: '',
        VANJSKO_PRANJE_VILJUSKARA: false,
        ZAMJENA_ULJA: false,
        PRANJE_HLADNJAKA: false,
        sredstvo: '',
        SredstvoId: '',
        RadniSati: ''
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));

        if (name === "sredstvo" && value.length >= 3) {
            checkInventurniBroj(value);
        }
    };

    const handleQrScan = (data: string) => {
        setFormData(prevData => ({ ...prevData, sredstvo: data }));
        checkInventurniBroj(data);
    };

    const checkInventurniBroj = async (invBroj: string) => {
        try {
            const data = await sredstvoInfo({ invBroj });
            if (data) {
                setNaziv(data.SredstvoNaziv);
                setSredstvoId(data.SredstvoId);
                setFormData(prevData => ({
                    ...prevData,
                    sredstvo: invBroj,
                    SredstvoId: data.SredstvoId
                }));
            } else {
                setNaziv("");
                setFormData(prevData => ({
                    ...prevData,
                    sredstvo: invBroj,
                    SredstvoId: ""
                }));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setNaziv("");
            setFormData(prevData => ({
                ...prevData,
                sredstvo: invBroj,
                SredstvoId: ""
            }));
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        // Transform the formData into the required format for the API, excluding SredstvoId and RadniSati
        const transformedMeta: MetaData[] = Object.entries(formData).reduce((acc: MetaData[], [key, value]) => {
            if (key !== 'SredstvoId' && key !== 'RadniSati') {
                let metaValue;
                if (typeof value === "boolean") {
                    metaValue = value ? "DA" : "NE";
                } else {
                    metaValue = value;
                }
                acc.push({ meta_key: key, meta_value: metaValue.toString() });
            }
            return acc;
        }, []);

        // Include SredstvoId and RadniSati as independent fields
        const transformedData = {
            SredstvoId: formData.SredstvoId,
            RadniSati: formData.RadniSati,
            meta: transformedMeta
        };

        try {
            console.log(transformedData, 'transformedData')
            const response = await saveViljuskarZapisiData(transformedData);

            if (response.error === 0) {
                notifyToast(true, response.message);
                setTimeout(() => {
                    navigate(-1);
                }, 2000);
            } else {
                notifyToast(false, response.message || "An unexpected error occurred.");
            }
        } catch (error: any) {
            console.error("Error: ", error);
            notifyToast(false, error.response?.data?.message || "An unexpected error occurred.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="row">
            <ToastContainer/>
            <div className="card mb-5 mb-xl-10 col-12">
                <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                >
                    <div className="card-title m-0">
                        <TitleComponent name="Zapis"/>
                    </div>
                </div>
                <div id="kt_account_profile_details" className="collapse show">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="card-body border-top p-9 row">
                            <div className="col-lg-6 col-sm-12">
                                    <>
                                        <div className="row mb-5">
                                            <label className="col-12 col-form-label required fw-bold fs-6">Skeniraj</label>
                                            <div className="col-3 mb-sm-10 fv-row">
                                                <QrCodeScanner onScan={handleQrScan}/>
                                            </div>
                                            <div className="col-9 fv-row">
                                                <input
                                                    type="text"
                                                    placeholder="Sredstvo"
                                                    name="sredstvo"
                                                    value={formData.sredstvo}
                                                    onChange={handleInputChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-12 mb-sm-10 fv-row">
                                                <label className="col-auto col-form-label required fw-bold fs-2">{naziv}</label>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-lg-9 col-sm-12 fv-row">
                                                <label className="mb-2 fv-row">  Upisati broj radni sati</label>
                                                <input
                                                    type="number"
                                                    placeholder="Radni sati"
                                                    name="RadniSati"
                                                    value={formData.RadniSati}
                                                    onChange={handleInputChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </>
                                    <>
                                        <div className="row mt-10">
                                            <div className="col-lg-9 col-sm-12 mt-3 fv-row">
                                                <label className="mb-2 fv-row"> GORIVO (Upisati samo nasutu količinu)</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Gorivo"
                                                        name="GORIVO"
                                                        value={formData.GORIVO}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                    />
                                            </div>
                                        </div>
                                        <div className="row mt-10">
                                            <div className="col-lg-9 col-sm-12 mt-3 fv-row">
                                                <label className="mb-2 fv-row"> PLIN (Upisati samo nasutu količinu)</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Plin"
                                                        name="PLIN"
                                                        value={formData.PLIN}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                    />
                                            </div>
                                        </div>
                                        <div className="row mt-10">
                                            <div className="col-lg-9 col-sm-12 mt-3 fv-row">
                                                <label style={{display: "inline-flex", alignItems: "center"}}>
                                                    <input
                                                        type="checkbox"
                                                        name="VANJSKO_PRANJE_VILJUSKARA"
                                                        checked={formData.VANJSKO_PRANJE_VILJUSKARA}
                                                        onChange={handleInputChange}
                                                        className="form-check-input"
                                                        style={{
                                                            transform: "scale(1.5)",
                                                            marginRight: "10px",
                                                        }}
                                                    />
                                                    <span style={{fontWeight: "bold"}}>Vanjsko pranje viljuškara</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mt-10">
                                            <div className="col-lg-9 col-sm-12 mt-3 fv-row">
                                                <label style={{display: "inline-flex", alignItems: "center"}}>
                                                    <input
                                                        type="checkbox"
                                                        name="PRANJE_HLADNJAKA"
                                                        checked={formData.PRANJE_HLADNJAKA}
                                                        onChange={handleInputChange}
                                                        className="form-check-input"
                                                        style={{
                                                            transform: "scale(1.5)",
                                                            marginRight: "10px",
                                                        }}
                                                    />
                                                    <span style={{fontWeight: "bold"}}>Pranje hladnjaka</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mt-10">
                                            <div className="col-lg-9 col-sm-12 mt-3 fv-row">
                                                <label style={{display: "inline-flex", alignItems: "center"}}>
                                                    <input
                                                        type="checkbox"
                                                        name="ZAMJENA_ULJA"
                                                        checked={formData.ZAMJENA_ULJA}
                                                        onChange={handleInputChange}
                                                        className="form-check-input"
                                                        style={{
                                                            transform: "scale(1.5)",
                                                            marginRight: "10px",
                                                        }}
                                                    />
                                                    <span style={{fontWeight: "bold"}}>Zamjena ulja</span>
                                                </label>
                                            </div>
                                        </div>
                                    </>
                                    <div className="row mt-5">
                                        <div className="col-lg-8 mt-5 fv-row">
                                            <div className="d-grid gap-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-block btn-success"
                                                    disabled={loading}
                                                >
                                                    {!loading && "Start"}
                                                    {loading && (
                                                        <span className="indicator-progress" style={{display: "block"}}>
                                                            Please wait...{" "}
                                                            <span
                                                                className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default ViljuskariZapisiDetails;
