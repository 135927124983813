import axios from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {BojaModel, ParamsModelBoja} from '../models/BojaModel'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/boja`
const SELECT = `${API_URL}/bojaDropdown`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: `Bearer ${auth?.access_token ?? ''}`}

export async function getBojeDropdown() {
  return await axios.get<Array<Option>>(SELECT, {headers: headers})
}

export async function getBoje(params: ParamsModelBoja) {
  return await axios.get<Array<BojaModel>>(API, {headers: headers, params: params})
}

export async function saveBoja(Data: any) {
  return await axios.post(API, Data, {headers: headers})
}

export async function fetchBojaData(bojaId: number): Promise<Partial<BojaModel>> {
  try {
    const response = await axios.get(`${API}/${bojaId}`, {headers: headers})
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch Boja data.')
    }
  } catch (error) {
    console.error('Error fetching Boja data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateBoja(bojaId: number, BojaData: Partial<BojaModel>): Promise<any> {
  try {
    const response = await axios.put(`${API}/${bojaId}`, BojaData, {headers: headers})

    if (response.data && response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to update Boja data.')
    }
  } catch (error) {
    console.error('Error updating Boja data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function deleteBoja(bojaId: number) {
  try {
    const response = await axios.delete(`${API}/${bojaId}`, {headers: headers})
    if (response.data && response.data.error === 0) {
      return response
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to delete Boja.')
    }
  } catch (error: any) {
    console.error('Error deleting Boja data:', error.response.data.message)
    throw new Error(error.response.data.message) // Re-throw the error so it can be handled by the caller if necessary
  }
}
