import axios, {AxiosResponse} from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
const UPOSLENIK = `${API_URL}/uposlenik`
const UPOSLENIK_HAS_ROLE = `${API_URL}/uposlenici/uposlenikImaRolu`
const SELECT = `${API_URL}/uposlenikDropdown`
const auth: UposlenikModel = getAuth()!

const headers = {Accept: 'application/json', Authorization: `Bearer ${auth?.access_token ?? ''}`}

export async function getUposlenikDropdown(): Promise<AxiosResponse<Array<Option>>> {
  return await axios.get<Array<Option>>(SELECT, {headers: headers})
}

// Save Uposlenik request to save Uposlenik data.
export async function save_uposlenik(UposlenikData: any) {
  try {
    const response = await axios.post(UPOSLENIK, UposlenikData, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

// Get Uposlenici list.
export async function getUposlenici(params) {
  return await axios.get<Array<UposlenikModel>>(UPOSLENIK, {headers: headers, params: params})
}

// Delete Uposlenici.
export async function deleteUposlenici(id: number) {
  try {
    const response = await axios.delete(`${UPOSLENIK}/${id}`, {headers: headers})
    if (response.data) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to delete.')
    }
  } catch (error) {
    console.error('Error deleting data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
// Update Uposlenici.
export async function updateUposlenici(UposlenikData: any, id: number) {
  try {
    const response = await axios.post(`${UPOSLENIK}/${id}?_method=PATCH`, UposlenikData, {
      headers: headers,
    })
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function fetchUposlenici(Id: number) {
  try {
    const response = await axios.get(`${UPOSLENIK}/${Id}`)
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function userHasRole(UposlenikId, rola) {
  try {
    const url = `${UPOSLENIK_HAS_ROLE}?uposlenikId=${encodeURIComponent(UposlenikId)}&rola=${encodeURIComponent(rola)}`;
    const response = await axios.get(url, { headers });

    if (response.data && response.data.error === 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}