import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import notifyToast from '../../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../../components/TitleComponent/TitleComponent'
import QrCodeScanner from "../../../components/QrCodeScanner/QrCodeScanner";
import {
    isporuciThermoBoradPaketeQR,
    proizvediThermoBoradPaketeQR, rezervisiThermoBoradPaketeQR
} from "../../../api/thermoBoardPaketi";

const ThermoBoardPaket:React.FC = () => {

    const [loading, setLoading] = useState(false)
    const [prikaziStavke, setprikaziStavke] = useState(true)
    const [proizvediPaket, setproizvediPaket] = useState(false)
    const [rezervisiPaket, setrezervisiPaket] = useState(false)
    const [isporuciPaket, setisporuciPaket] = useState(false)

    const [stavkaId, setStavkaId] = useState('');
    const [kupac, setKupac] = useState('');

    const handleQrScan = (data: string) => {
        setStavkaId(data);
    }

    const setProizvediPaket = () =>{
        setproizvediPaket(true)
        setprikaziStavke(false)
    }
    const setIsporuciPaket = () =>{
        setisporuciPaket(true)
        setprikaziStavke(false)
    }
    const setRezervisiPaket = () =>{
        setrezervisiPaket(true)
        setprikaziStavke(false)
    }

    const handleProizvediPaket = async (event) => {
        event.preventDefault();
        setLoading(true)
        const payload = {
            stavkaId: stavkaId
        };

        try {
            const response = await proizvediThermoBoradPaketeQR(payload);

            if (response.error === 0) {
                notifyToast(true, response.message);
            } else {
                notifyToast(false, response.message || 'An unexpected error occurred.');
            }
        } catch (error: any) {
            console.error('Error: ', error)
            notifyToast(false, error.response.data.message)
        }
        setLoading(false)
    };

    const handleIsporuciPaket = async (event) => {
        event.preventDefault();
        setLoading(true)
        const payload = {
            stavkaId: stavkaId,
            kupac: kupac,
        };

        try {
            const response = await isporuciThermoBoradPaketeQR(payload);

            if (response.error === 0) {
                notifyToast(true, response.message);
            } else {
                notifyToast(false, response.message || 'An unexpected error occurred.');
            }
        } catch (error: any) {
            console.error('Error: ', error)
            notifyToast(false, error.response.data.message)
        }
        setLoading(false)
    };

    const handleRezervisiPaket = async (event) => {
        event.preventDefault();
        setLoading(true)
        const payload = {
            stavkaId: stavkaId,
            kupac: kupac,
        };

        try {
            const response = await rezervisiThermoBoradPaketeQR(payload);

            if (response.error === 0) {
                notifyToast(true, response.message);
            } else {
                notifyToast(false, response.message || 'An unexpected error occurred.');
            }
        } catch (error: any) {
            console.error('Error: ', error)
            notifyToast(false, error.response.data.message)
        }
        setLoading(false)
    };

    return (
        <div className='row'>
            <ToastContainer/>
            <div className='card mb-5 mb-xl-10 col-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <TitleComponent name='Thermo Board Paketi'/>
                    </div>
                </div>
                <div id='kt_account_profile_details' className='collapse show'>
                    <form noValidate>
                        <div className='card-body border-top p-9 row'>
                            {prikaziStavke && (
                            <div className='col-6 mb-6'>
                                <div className="d-grid gap-5">
                                    <button className="btn btn-warning p-5" onClick={setProizvediPaket} type="button">
                                        Proizvedi paket
                                    </button>
                                    <button className="btn btn-success p-5" onClick={setIsporuciPaket} type="button">
                                        Isporuči paket
                                    </button>
                                    <button className="btn btn-primary p-5" onClick={setRezervisiPaket} type="button">
                                        Rezerviši paket
                                    </button>
                                </div>
                            </div>
                            )}
                            {proizvediPaket && (
                                <div className='col-12 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>Skeniraj</label>
                                    <div className='row mb-5'>
                                        <div className='col-3 fv-row'>
                                            <QrCodeScanner onScan={handleQrScan}/>
                                        </div>
                                        <div className='col-9 fv-row'>
                                            <input
                                                type='text'
                                                placeholder='paket'
                                                onChange={(e) => setStavkaId(e.target.value)}
                                                value={stavkaId}
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-lg-8 mt-5 fv-row'>
                                            <div className="d-grid gap-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-block btn-primary"
                                                    onClick={handleProizvediPaket}
                                                    disabled={loading}
                                                >
                                                    {!loading && 'Potvrdi'}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                        Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                            {isporuciPaket && (
                                <div className='col-12 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>Skeniraj</label>
                                    <div className='row mb-5'>
                                        <div className='col-3 fv-row'>
                                            <QrCodeScanner onScan={handleQrScan}/>
                                        </div>
                                        <div className='col-6 fv-row'>
                                            <input
                                                type='text'
                                                placeholder='Paket'
                                                onChange={(e) => setStavkaId(e.target.value)}
                                                value={stavkaId}
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='col-9 offset-3 fv-row mt-5'>
                                            <input
                                                type='text'
                                                placeholder='kupac'
                                                value={kupac}
                                                onChange={(e) => setKupac(e.target.value)}
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-lg-8 mt-5 fv-row'>
                                            <div className="d-grid gap-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-block btn-primary"
                                                    onClick={handleIsporuciPaket}
                                                    disabled={loading}
                                                >
                                                    {!loading && 'Potvrdi'}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                        Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {rezervisiPaket && (
                                <div className='col-12 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>Skeniraj</label>
                                    <div className='row mb-5'>
                                        <div className='col-3 fv-row'>
                                            <QrCodeScanner onScan={handleQrScan}/>
                                        </div>
                                        <div className='col-9 fv-row'>
                                            <input
                                                type='text'
                                                placeholder='Paket'
                                                onChange={(e) => setStavkaId(e.target.value)}
                                                value={stavkaId}
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='col-9 offset-3 fv-row mt-5'>
                                            <input
                                                type='text'
                                                placeholder='kupac'
                                                value={kupac}
                                                onChange={(e) => setKupac(e.target.value)}
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-lg-8 mt-5 fv-row'>
                                            <div className="d-grid gap-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-block btn-primary"
                                                    onClick={handleRezervisiPaket}
                                                    disabled={loading}
                                                >
                                                    {!loading && 'Potvrdi'}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                                                        Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default ThermoBoardPaket;
