import React, {useState} from 'react'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import {ToastContainer} from 'react-toastify'
import {kreirajOtpremnicuPanela, prikaziOtpremnicuPanela} from '../../api/kreirajOtpremnicu'
import notifyToast from '../../components/Toast/Toast'
import {IsporuceniPaketiModel, IsporuceniPaketiSumaModel} from '../../models/KreirajOtpremnicuModel'
import {PDFViewer} from '@react-pdf/renderer'
import KreirajOtpremnicuPdf from './components/KreirajOtpremnicuPdf/KreirajOtpremnicuPdf'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'

const KreirajOtpremnicu = () => {
  const [brojNaloga, setbrojNaloga] = useState<string>()
  const [datum, setdatum] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [isporuceniPaketi, setisporuceniPaketi] = useState<IsporuceniPaketiModel[]>()
  const [isporuceniPaketiSuma, setisporuceniPaketiSuma] = useState<IsporuceniPaketiSumaModel[]>()
  const [nalogIsporucen, setnalogIsporucen] = useState(false);
  // Get Data for Naloga and generate PDF.
  const onBtnClick = () => {
    setLoading(true)
    const payload = {
      brojNaloga,
      datum,
    }
    prikaziOtpremnicuPanela(payload)
      .then((response) => {
        if (response.data) {
          setisporuceniPaketi(response.data['isporuceniPaketi'])
          setisporuceniPaketiSuma(response.data['isporuceniPaketiSuma'])
          setnalogIsporucen(response.data['nalogIsporucen'])
        } else {
          notifyToast(false, response.message)
        }
      })
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => setLoading(false))
  }
  // Kreiraj btn click
  const onKreirajBtnClick = () => {
    setLoading(true)
    kreirajOtpremnicuPanela({brojNaloga})
      .then((response) => {
        onBtnClick()
        notifyToast(true, response.message)
      })
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => setLoading(false))
  }

  return (
    <div className='card mb-5 mb-xl-10 p-5'>
      <ToastContainer />
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <TitleComponent name='Kreiraj Otpremnicu' />
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='row'>
            {/* begin: BrojNaloga */}
            <div className='col-lg-2 col-md-4 col-6 mb-6'>
              <label className='col-lg-6 col-form-label required fw-bold fs-6'>Broj Nalog</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                value={brojNaloga}
                onChange={(e) => setbrojNaloga(e.target.value)}
              />
            </div>
            {/* end: BrojaNaloga */}

            {/* begin: Datum */}
            <div className='col-lg-2 col-md-4 col-6 mb-6'>
              <label className='col-lg-6 col-form-label required fw-bold fs-6'>Datum</label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                value={datum}
                onChange={(e) => setdatum(e.target.value)}
              />
            </div>
            {/* end: Datum */}

            <div className='col-4 align-self-center mt-5'>
              <button
                className='btn btn-primary'
                onClick={onBtnClick}
                disabled={loading || !datum || !brojNaloga}
              >
                Potvrdi
              </button>
            </div>
            {/* end: BrojNaloga */}
            <hr />
            {loading && <LoadingComponent />}
            {/* PDF viewer */}
            {isporuceniPaketi && isporuceniPaketiSuma && (
              <>
                <div className='col-4 align-self-center mb-6'>
                  <button className='btn btn-info' onClick={onKreirajBtnClick} disabled={loading}>
                    Kreiraj Otpremnicu:
                  </button>
                </div>
                <PDFViewer height={1200}>
                  <KreirajOtpremnicuPdf
                    isporuceniPaketi={isporuceniPaketi}
                    isporuceniPaketiSuma={isporuceniPaketiSuma}
                    nalogIsporucen={nalogIsporucen}
                  />
                </PDFViewer>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default KreirajOtpremnicu
