import axios from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
const DROPDOWN_API = `${API_URL}/lokacijeDropdown`
const LOKACIJE_PAKETA = `${API_URL}/paneli/lokacijePaketa`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

// Get request for Lokacije Dropdown data.
export async function getLokacijeDropdown() {
  try {
    return await axios.get<Array<Option>>(DROPDOWN_API, {headers: headers})
  } catch (error) {
    console.error('Error fetching lokacije Dropdown data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

// Get request for Lokacije Dropdown data.
export async function getLokacijePaketaDropdown() {
  try {
    return await axios.get(LOKACIJE_PAKETA, {headers: headers})
  } catch (error) {
    console.error('Error fetching lokacije Dropdown data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
