import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_websoft/layout/core'
import Proizvodnja from './Proizodnja'
import NalogDetails from './NalogDetails'
import LezarinaPaketa from './LezarinaPaketa'
import PregledPaketa from './PregledPaketa'
import TipPanela from './TipPanela'
import TipPanelaDetails from './TipPanelaDetails'
import Arhiva from './Arhiva'
import SifreLista from './sifarnici/SifreLista'
import SifreDodaj from './sifarnici/SifreDodaj'
import DebljineLista from './sifarnici/DebljineLista'
import DebljinaDodaj from './sifarnici/DebljinaDodaj'
import SirineLista from './sifarnici/SirineLista'
import SirineDodaj from './sifarnici/SirineDodaj'
import IzolacijeLista from './sifarnici/IzolacijeLista'
import IzolacijeDodaj from './sifarnici/IzolacijeDodaj'
import OblogeLista from './sifarnici/OblogeLista'
import OblogeDodaj from './sifarnici/OblogeDodaj'
import GlavnaKnjiga from './GlavnaKnjiga'
import DrugaKlasaDimenzije from './DrugaKlasaDimenzije'
import DrugaKlasaPaketi from './DrugaKlasaPaketi'
import GlavnaKnjigaDetails from './GlavnaKnjigaDetails'
import DrugaKlasaPaketiDetails from './DrugaKlasaPaketiDetails'
import Izvjestaji from './Izvjestaji'
import GlavniIzvjestaj from './components/GlavniIzvjestaj/GlavniIzvjestaj'
import KupciPregled from './components/KupciPregled/KupciPregled'
import UtovarKamiona from './components/UtovarKamiona/UtovarKamiona'
import ZapisiProizvodnja from './ZapisiProizvodnja'
import PlanProizvodnje from './PlanProizvodnje'
import KreirajOtpremnicu from './KreirajOtpremnicu'
import KupciPdfProcessor from './components/KupciPregled/KupciPdfProcessor'
import NaloziZaArhiviranje from "./NaloziZaArhiviranje";
import UrgentanTransportLista from "./UrgentanTransportLista";
import KontrolaKvalitetaDetails from "./KontrolaKvalitetaDetais";
import KontrolaKvalitetaLista from "./KontrolaKvalitetaLista";
import GlavniIzvjestajV2 from "./components/GlavniIzvjestaj/GlavniIzvjestajV2";
import ReklamacijeLista from "./ReklamacijeLista";
import ReklamacijeDetails from "./ReklamacijeDetails";

const PaneliBreadCrumbs: Array<PageLink> = [
  {
    title: 'Paneli',
    path: '/Paneli/lista',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PaneliRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='lista'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Proizvodnja</PageTitle>
              <Proizvodnja />
            </>
          }
        />
        <Route
          path='dodaj'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Dodaj</PageTitle>
              <NalogDetails />
            </>
          }
        />
        <Route
          path='izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Izmjena</PageTitle>
              <NalogDetails />
            </>
          }
        />
        <Route
          path='pregled-paketa'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Pregled paketa</PageTitle>
              <PregledPaketa />
            </>
          }
        />
        <Route
          path='lezarina-paketa'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Ležarina paketa</PageTitle>
              <LezarinaPaketa />
            </>
          }
        />
          <Route
              path='urgentan-transport'
              element={
                  <>
                      <PageTitle breadcrumbs={PaneliBreadCrumbs}>Urgentan transport</PageTitle>
                      <UrgentanTransportLista />
                  </>
              }
          />
        <Route
          path='tip-panela'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Tip panela</PageTitle>
              <TipPanela />
            </>
          }
        />
        <Route
          path='tip-panela/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Dodaj</PageTitle>
              <TipPanelaDetails />
            </>
          }
        />
        <Route
          path='tip-panela/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Izmjena</PageTitle>
              <TipPanelaDetails />
            </>
          }
        />
        <Route
          path='arhiva'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Arhiva</PageTitle>
              <Arhiva />
            </>
          }
        />
        <Route
          path='glavna-knjiga'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Glavna Knjiga</PageTitle>
              <GlavnaKnjiga />
            </>
          }
        />
        <Route
          path='glavna-knjiga/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Dodaj</PageTitle>
              <GlavnaKnjigaDetails />
            </>
          }
        />
        <Route
          path='glavna-knjiga/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Izmjena</PageTitle>
              <GlavnaKnjigaDetails />
            </>
          }
        />
        <Route
          path='druga-klasa-dimenzije'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Druga klasa dimenzije</PageTitle>
              <DrugaKlasaDimenzije />
            </>
          }
        />
        <Route
          path='druga-klasa-paketi'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Druga klasa paketi</PageTitle>
              <DrugaKlasaPaketi />
            </>
          }
        />
        <Route
          path='druga-klasa-paketi/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Dodaj</PageTitle>
              <DrugaKlasaPaketiDetails />
            </>
          }
        />
        <Route
          path='druga-klasa-paketi/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Izmjena</PageTitle>
              <DrugaKlasaPaketiDetails />
            </>
          }
        />
        <Route
          path='izvjestaji'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Izvjestaji</PageTitle>
              <Izvjestaji />
            </>
          }
        />
        <Route
          path='izvjestaji/glavni-izvjestaj'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Glavni Izvjestaj</PageTitle>
              <GlavniIzvjestaj />
            </>
          }
        />
          <Route
              path='izvjestaji/glavni-izvjestaj-v2'
              element={
                  <>
                      <PageTitle breadcrumbs={PaneliBreadCrumbs}>Glavni Izvjestaj</PageTitle>
                      <GlavniIzvjestajV2 />
                  </>
              }
          />
        <Route
          path='lista/kupci-pregled'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Kupci Pregled</PageTitle>
              <KupciPregled />
            </>
          }
        />
        <Route
          path='lista/kupci-pregled/process-pdf/:id'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Kupci Pregled</PageTitle>
              <KupciPdfProcessor />
            </>
          }
        />
        <Route
          path='lista/kupci-pregled/utovar-kamiona'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Utovar Kamiona</PageTitle>
              <UtovarKamiona />
            </>
          }
        />
        <Route
          path='sifre'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Šifre panela</PageTitle>
              <SifreLista />
            </>
          }
        />
        <Route
          path='sifre/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Dodaj šifru</PageTitle>
              <SifreDodaj />
            </>
          }
        />
        <Route
          path='sifre/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Izmjeni šifru</PageTitle>
              <SifreDodaj />
            </>
          }
        />
        <Route
          path='debljine'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Debljine lima paneli</PageTitle>
              <DebljineLista />
            </>
          }
        />
        <Route
          path='debljine/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Dodaj debljinu lima</PageTitle>
              <DebljinaDodaj />
            </>
          }
        />
        <Route
          path='debljine/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Izmjeni debljinu lima</PageTitle>
              <DebljinaDodaj />
            </>
          }
        />
        <Route
          path='sirine'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Širine panela</PageTitle>
              <SirineLista />
            </>
          }
        />
        <Route
          path='sirine/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Dodaj širinu</PageTitle>
              <SirineDodaj />
            </>
          }
        />
        <Route
          path='sirine/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Izmjeni širinu</PageTitle>
              <SirineDodaj />
            </>
          }
        />
        <Route
          path='izolacije'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Izolacija panela</PageTitle>
              <IzolacijeLista />
            </>
          }
        />
        <Route
          path='izolacije/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Dodaj Izolaciju</PageTitle>
              <IzolacijeDodaj />
            </>
          }
        />
        <Route
          path='izolacije/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Izmjeni Izolaciju</PageTitle>
              <IzolacijeDodaj />
            </>
          }
        />
        <Route
          path='obloge'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Obloga</PageTitle>
              <OblogeLista />
            </>
          }
        />
        <Route
          path='obloge/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Dodaj oblogu</PageTitle>
              <OblogeDodaj />
            </>
          }
        />
        <Route
          path='obloge/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Izmjeni oblogu</PageTitle>
              <OblogeDodaj />
            </>
          }
        />
        <Route
          path='zapisi-proizvodnje'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Zapisi Proizvodnje</PageTitle>
              <ZapisiProizvodnja />
            </>
          }
        />
        <Route
          path='plan-proizvodnje'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Plan Proizvodnje</PageTitle>
              <PlanProizvodnje />
            </>
          }
        />
        <Route
          path='kreiraj-otpremnicu'
          element={
            <>
              <PageTitle breadcrumbs={PaneliBreadCrumbs}>Kreiraj Otpremnicu</PageTitle>
              <KreirajOtpremnicu />
            </>
          }
        />
          <Route
              path='arhiviraj-naloge'
              element={
                  <>
                      <PageTitle breadcrumbs={PaneliBreadCrumbs}>Arhiviraj naloge</PageTitle>
                      <NaloziZaArhiviranje />
                  </>
              }
          />
          <Route
              path='kontrola-kvaliteta'
              element={
                  <>
                      <PageTitle breadcrumbs={PaneliBreadCrumbs}>Paneli Kontrola Kvaliteta</PageTitle>
                      <KontrolaKvalitetaLista />
                  </>
              }
          />
          <Route
              path='kontrola-kvaliteta/dodaj'
              element={
                  <>
                      <PageTitle breadcrumbs={PaneliBreadCrumbs}>Paneli Kontrola Kvaliteta Dodaj Stavku</PageTitle>
                      <KontrolaKvalitetaDetails />
                  </>
              }
          />
          <Route
              path='kontrola-kvaliteta/izmjena/:id'
              element={
                  <>
                      <PageTitle breadcrumbs={PaneliBreadCrumbs}>Paneli Kontrola Kvaliteta Izmjena</PageTitle>
                      <KontrolaKvalitetaDetails />
                  </>
              }
          />

          <Route
              path='reklamacije'
              element={
                  <>
                      <PageTitle breadcrumbs={PaneliBreadCrumbs}>Reklamacije Lista</PageTitle>
                      <ReklamacijeLista />
                  </>
              }
          />
          <Route
              path='reklamacije/dodaj'
              element={
                  <>
                      <PageTitle breadcrumbs={PaneliBreadCrumbs}>Reklamacija Dodaj</PageTitle>
                      <ReklamacijeDetails />
                  </>
              }
          />
          <Route
              path='reklamacije/izmjena/:id'
              element={
                  <>
                      <PageTitle breadcrumbs={PaneliBreadCrumbs}>Reklamacija Izmjena</PageTitle>
                      <ReklamacijeDetails />
                  </>
              }
          />
     {/*     <Route
              path='izvjestaji/nalog-izvjestaj'
              element={
                  <>
                      <PageTitle breadcrumbs={PaneliBreadCrumbs}>Paneli Kontrola Kvaliteta Izmjena</PageTitle>
                      <KontrolaKvalitetaDetails />
                  </>
              }
          />*/}
        <Route index element={<Navigate to='/thermoboard/Lista' />} />
      </Route>
    </Routes>
  )
}

export default PaneliRoutes
