import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'

import {ArtikliOperacijeModel, ParamsModelArtikliOperacije} from '../models/ArtikliOperacije'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/artikliOperacije`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getArtikliOperacije(params: ParamsModelArtikliOperacije) {
    return await axios.get<Array<ArtikliOperacijeModel>>(API, {headers: headers, params: params})
}

export async function saveArtikliOperacije(Data: any) {
    try {
        const response = await axios.post(API, Data, {headers: headers})

        if (response.data.error === 0) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error Saving data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function fetchArtikliOperacije(Id: number): Promise<Partial<ArtikliOperacijeModel>> {
    try {
        const response = await axios.get(`${API}/${Id}`)
        if (response.data && response.data.error === 0) {
            return response.data.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function updateArtikliOperacije(id: number, Data: any) {
    try {
        const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
        if (response.data.error === 0) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error Updating data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function deleteArtikliOperacije(Id: number) {
    try {
        const response = await axios.delete(`${API}/${Id}`, {headers: headers})
        if (response.data) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to delete.')
        }
    } catch (error) {
        console.error('Error deleting data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}
