export const bosnianLocaleText = {
    // Filter panel
    page: 'stranica',
    more: 'više',
    to: 'do',
    of: 'od',
    next: 'sljedeća',
    last: 'zadnja',
    first: 'prva',
    previous: 'prethodna',
    loadingOoo: 'učitavanje...',

    // For set filter
    selectAll: 'odaberi sve',
    searchOoo: 'pretražuj...',
    blanks: 'prazno',

    // For number filter and text filter
    filterOoo: 'filtriraj...',
    applyFilter: 'primijeni filter...',
    equals: 'jednako',
    notEqual: 'nije jednako',

    // Number filter
    lessThan: 'manje od',
    greaterThan: 'veće od',
    lessThanOrEqual: 'manje ili jednako',
    greaterThanOrEqual: 'veće ili jednako',
    inRange: 'u opsegu',

    // Text filter
    contains: 'sadrži',
    notContains: 'ne sadrži',
    startsWith: 'počinje s',
    endsWith: 'završava s',

    // Date filter
    dateFormatOoo: 'dd.mm.yyyy',

    // Filter conditions
    andCondition: 'i',
    orCondition: 'ili',

    // Filter buttons
    apply: 'Primijeni',
    reset: 'Resetuj',
    clear: 'Očisti'
};