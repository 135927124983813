import React, {useMemo} from 'react'
import {Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer'
import logo from '../../../../../_websoft/assets/images/logo.jpg'
// Register font
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
})
// Create styles
const styles = StyleSheet.create({
  pdf: {width: '100vh', height: '500px'},
  page: {
    flexDirection: 'column',
    flex: 1,
    height: '300px',
    padding: 30,
    paddingBottom: 50,
  },
  section: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  section2: {
    margin: 2,
    padding: 2,
    flexDirection: 'column',
    justifyContent: 'center',
    border: '2px solid #212121',
  },
  section3: {
    margin: 2,
    padding: 2,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  qrCode: {
    margin: 2,
    padding: 2,
    width: '100px',
  },

  text: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Roboto',
    padding: 5,
  },
  textSmall: {
    fontSize: 8,
    textAlign: 'center',
    fontFamily: 'Roboto',
    padding: 5,
  },
  boldtext: {
    fontSize: 20,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: 10,
  },
  table: {
    width: '100%',
    margin: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  bold: {
    fontWeight: 'bold',
  },
  header: {
    backgroundColor: '#32de84',
  },
  border: {
    border: '1px solid #212121',
  },
  bottom: {
    borderBottom: '1px solid #212121',
  },
  right: {
    borderRight: '1px solid #212121',
  },
  alignEnd: {
    alignSelf: 'flex-end',
  },
  logo: {
    margin: 2,
    padding: 2,
    width: '200px',
  },
  row1: {
    width: '30%',
    paddingTop: 8,
    paddingBottom: 8,
  },
  row2: {
    width: '20%',
    paddingTop: 8,
    paddingBottom: 8,
  },
  row3: {
    width: '50%',
    paddingTop: 8,
    paddingBottom: 8,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    padding: 5,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'right',
  },
})

const KupciPregledPdf = ({pregledData, kupac}) => {
  const data = useMemo(() => ({pregledData}), [pregledData])

  return (
    <Document>
      <Page size='A4' style={styles.page} orientation='landscape'>
        <Image src={logo} style={styles.logo} />
        {/* @ts-ignore */}
        <Text style={styles.boldtext}>Kupac: {kupac}</Text>
        {/* begin:Table */}
        <View style={[styles.table]}>
          {Object.entries(data.pregledData).map(([outerKey, outerValue]) => (
            <View>
              {/* begin: header */}
              <View style={[styles.header]}>
                <Text style={[styles.text, styles.bold]}>Stanje paketa prema nalogu / ponudi</Text>
                <View style={[styles.row, styles.bold]}>
                  <Text style={[styles.text, styles.bold]}>
                    {/* @ts-ignore */}
                    Ponuda: {outerValue.shared.brojPonude}
                  </Text>
                  <Text style={[styles.text, styles.bold]}>
                    {/* @ts-ignore */}
                    Nalog: {outerValue.shared.brojNaloga} ({outerValue.shared.stavka})
                  </Text>
                  <Text style={[styles.text, styles.bold]}>
                    {/* @ts-ignore */}
                    Vrsta {outerValue.shared.vrsta} Boja: {outerValue.shared.boja}
                  </Text>
                </View>
              </View>
              {/* end: header */}

              {/* @ts-ignore */}
              {Object.entries(outerValue).map(([key, value]) => {
                if (key !== 'shared')
                  return (
                    <View style={[styles.row, styles.bold, {marginTop: 3}]}>
                      <View style={[styles.row1, {backgroundColor: '#F5F5F5'}]}>
                        {/* @ts-ignore */}
                        <Text style={[styles.text, styles.bold]}>Paket: {key}</Text>
                        {/* @ts-ignore */}
                        <Text style={[styles.text, styles.bold]}>[Š x D x V]: {value.gabarit}</Text>
                      </View>
                      {/* begin:Table2 */}
                      <View style={[styles.table, styles.border, {width: '70%'}]}>
                        <View style={[styles.row, styles.bold, styles.bottom]}>
                          <Text style={[styles.row2, styles.right, styles.textSmall, styles.bold]}>
                            Dužina
                          </Text>
                          <Text style={[styles.row2, styles.right, styles.textSmall, styles.bold]}>
                            Količina
                          </Text>
                          <Text style={[styles.row2, styles.right, styles.textSmall, styles.bold]}>
                            M2
                          </Text>
                          <Text style={[styles.row2, styles.right, styles.textSmall, styles.bold]}>
                            Status
                          </Text>
                          <Text style={[styles.row2, styles.textSmall, styles.bold]}>
                            Isporučen
                          </Text>
                        </View>

                        {/* @ts-ignore */}
                        {
                          // @ts-ignore
                          Object.entries(value.data).map(([key, val]) => (
                            <View style={[styles.row, styles.bold, styles.bottom]} wrap={false}>
                              <Text style={[styles.row2, styles.right, styles.textSmall]}>
                                {/* @ts-ignore */}
                                {val.duzina}
                              </Text>
                              <Text style={[styles.row2, styles.right, styles.textSmall]}>
                                {/* @ts-ignore */}
                                {val.brPanela}
                              </Text>
                              <Text style={[styles.row2, styles.right, styles.textSmall]}>
                                {/* @ts-ignore */}
                                {val.kvadratura}
                              </Text>
                              <Text
                                style={[
                                  styles.row2,
                                  styles.right,
                                  styles.textSmall,

                                  {backgroundColor: '#FFFF99'},
                                ]}
                              >
                                {/* @ts-ignore */}
                                {outerValue.shared.status}
                              </Text>
                              <Text
                                style={[
                                  styles.row2,
                                  styles.textSmall,
                                  {backgroundColor: '#fd5c63'},
                                ]}
                              >
                                {/* @ts-ignore */}
                                {outerValue.shared.isporucen}
                              </Text>
                            </View>
                          ))
                        }
                      </View>
                      {/* end:Table2 */}
                    </View>
                  )
              })}
              {/* begin: footer */}
              <View
                style={[
                  styles.row,
                  styles.bold,
                  styles.border,
                  {marginBottom: 30, justifyContent: 'flex-start'},
                ]}
              >
                <Text style={[styles.text, styles.bold, styles.row1]}>
                  Ukupna Količina Stavke Na Nalogu:
                </Text>
                <Text style={[styles.text, styles.bold, {alignSelf: 'flex-start'}]}>
                  {/* @ts-ignore */}
                  {outerValue.shared.totalKvadratura}
                </Text>
              </View>
              {/* end:footer */}
            </View>
          ))}
          {/* end:Table */}
        </View>
        <Text
          style={styles.pageNumber}
          render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  )
}

export default KupciPregledPdf
