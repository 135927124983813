import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import notifyToast from '../../../../components/Toast/Toast'
import {saveZapisiStavke} from '../../../../api/zapisiProizvodnje'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import {getRolnaDetalji} from '../../../../api/rolneDetalji'
import useDebounce from '../../../../hooks/useDebounce'
import clsx from 'clsx'

const Profilacija = ({id, brojNaloga}) => {
  const initialValues = {
    'PROVJERIO-GEOMETRIJU-GORNJEG-LIMA-SA-SABLONOM': undefined,
    'PROVJERIO-GEOMETRIJU-DONJEG-LIMA-SA-SABLONOM': undefined,
    'GORNJA-TABLA-PROFILACIJA': undefined,
    'DONJA-TABLA-PROFILACIJA': undefined,
  }
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response
        let data: any = []
        // Format data as per required format of api.
        Object.entries(values).map(([key, value]) => {
          if (value) {
            const dataSet = {
              IdZapisa: id,
              BrojNaloga: brojNaloga,
              MetaKey: key,
              MetaValue: value,
            }
            data.push(dataSet)
          }
        })
        response = await saveZapisiStavke(data)

        const responseData = response

        if (responseData.error === 0) {
          notifyToast(true, responseData.message)
        } else {
          notifyToast(false, responseData.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error.response.data.message)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  return (
    <div>
      {loading ? (
        <LoadingComponent />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div id='profilacija'>
            {/* PROFILACIJA  */}
            <div className='row mt-4'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  PROVJERIO GEOMETRIJU GORNJEG LIMA SA ŠABLONOM
                </label>
                <select
                  className='form-control'
                  {...formik.getFieldProps('PROVJERIO-GEOMETRIJU-GORNJEG-LIMA-SA-SABLONOM')}
                >
                  <option value=''>ODABERI</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  PROVJERIO GEOMETRIJU DONJEG LIMA SA ŠABLONOM
                </label>
                <select
                  className='form-control'
                  {...formik.getFieldProps('PROVJERIO-GEOMETRIJU-DONJEG-LIMA-SA-SABLONOM')}
                >
                  <option value=''>ODABERI</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> GORNJA TABLA [mm] </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Gornja tabla'
                  {...formik.getFieldProps('GORNJA-TABLA-PROFILACIJA')}
                />
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> DONJA TABLA [mm] </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder=' Donja tabla'
                  {...formik.getFieldProps('DONJA-TABLA-PROFILACIJA')}
                />
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'>
                  LIM IMA VIDLJIVE NEDOSTATKE NA BOJI
                </label>
                <select
                  className='form-control'
                  {...formik.getFieldProps('LIM-IMA-VIDLJIVE-NEDOSTATKE-NA-BOJI-PROFILACIJA')}
                >
                  <option value=''>ODABERI</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'>
                  {' '}
                  Pozicija zažtite folije uredu{' '}
                </label>
                <select
                  className='form-control'
                  {...formik.getFieldProps('POZICIJA-ZASTITNE-FOLIJE-UREDU-PROFILACIJA')}
                >
                  <option value=''>Odaberi</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-lg-3 offset-9'>
              <button type='submit' className='btn btn-block btn-primary'>
                Potvrdi
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

export default Profilacija
