/* eslint-disable react-hooks/exhaustive-deps */
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {KTCard, KTCardBody} from '../../../_websoft/helpers'
import TablePagination from '../../components/TablePagination/TablePagination'
import {getAllPrijavaKvara, startPrijavaKvara} from '../../api/prijavaKvara'
import {ParamsModelPrijavaKvara, PrijavaKvaraModel} from '../../models/PrijavaKvara'
import {useEffect, useState} from 'react'
import {PaginationModel} from '../../models/PaginationModel'
import React from 'react'
import notifyToast from '../../components/Toast/Toast'
import moment from 'moment'
import {ToastContainer} from 'react-toastify'
import TableSearch from '../../components/TableSearch/TableSearch'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import {useAuth} from '../../modules/auth'
import {useNavigate} from "react-router-dom";

const PrijavaKvara: React.FC = () => {
  const [data, setData] = useState<PrijavaKvaraModel[]>([])
  const [loading, setLoading] = useState(true)
  const [pagination, setpagination] = useState<PaginationModel>({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setparams] = useState<ParamsModelPrijavaKvara>({
    per_page: 10,
    page: 1,
    nisuRealizovane: 1,
  })
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')

  const {currentUser} = useAuth()
  const updatePage = () => {
    setLoading(true)
    getAllPrijavaKvara(params)
      .then((response) => {
        const data = response.data['data']
        setData(data)
        const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
        setpagination({
          curPage: response.data['current_page'],
          totalPage: totalPage,
          perPage: params.per_page,
        })
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Set cell color according to condtions.
  const getCellColor = (cell, row) => {
    // Color for Tr.roba
    if (cell.column.id === 'Status') return row.original.status.Boja
    // Color default
    return '#FFFFFF'
  }

  useEffect(() => {
    updatePage()
  }, [params])

  useEffect(() => {
    const {id} = sorting[0] ?? ''
    data.sort((a, b) => a[id] - b[id])
  }, [sorting])

  // function call on start
  const onStartClick = (row) => {
    setLoading(true)
    startPrijavaKvara({PrijavaId: row.original.PrijavaId})
      .then((response) => {
        notifyToast(true, response.data.message)
        updatePage()
      })
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => setLoading(false))
  }
  const navigate = useNavigate();

  const handleKreirajIntervenciju = (row) => {
    navigate(`/terminali/prijava-kvara/dodaj-intervenciju/${row.original.PrijavaId}`);
  };
  const TableCell = (row) => {
    const startao = row.original.uposlenik_startao
    if (startao) {
      // If signed in user is equal to startao
      if (startao.UposlenikImePrezime === currentUser?.UposlenikImePrezime) {
        return <button className='btn btn-success' onClick={() => handleKreirajIntervenciju(row)}>Kreiraj Intervenciju</button>
      }
      // If signed in user is not equal to startao
      else if (startao.UposlenikImePrezime !== currentUser?.UposlenikImePrezime) {
        return <button className='btn btn-warning'>Preuzeo Dr uposlenik</button>
      }
    }
    // If startao is null
    else {
      return (
        <button className='btn btn-primary' onClick={() => onStartClick(row)}>
          Start
        </button>
      )
    }
  }

  const columns = [
    {
      id: 'Id',
      header: 'ID Prijave',
      accessorKey: 'PrijavaId',
    },
    {
      id: 'Datum',
      header: 'Datum',
      accessorFn: (row) => moment(row.PrijavaDatum).format('DD-MM-YYYY'),
    },
    {
      id: 'sredstva',
      header: 'Naziv sredstva',
      accessorKey: 'sredstvo.SredstvoNaziv',
    },
    {
      id: 'Kvar',
      header: 'Kvar',
      accessorKey: 'PrijavaOpisKvara',
    },
    {
      id: 'Podnio',
      header: 'Podnio',
      accessorKey: 'uposlenik_kreirao.UposlenikImePrezime',
    },
    {
      id: 'Preuzeo',
      header: 'Preuzeo',
      accessorKey: 'uposlenik_startao.UposlenikImePrezime',
    },
    {
      id: 'Status',
      header: 'Status',
      accessorKey: 'status.Naziv',
    },

    {
      id: 'Preuzmi',
      header: 'Preuzmi',
      cell: ({row}) => TableCell(row),
    },
  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })

  return (
    <KTCard>
      <ToastContainer />

      <KTCardBody className='py-4'>
        {/* Broj naloga */}

        <div className='d-flex justify-content-between'>
          <div data-kt-user-table-toolbar='base'>
            <TableSearch onSearch={(text) => setGlobalFilter(text)} />
          </div>
          <div data-kt-user-table-toolbar='base'>
            <ExportButton table={table} filename={'Prijava-kvara'} />
          </div>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3 '>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className='text-white'
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* @ts-ignore */}
                        {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <TablePagination
          updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
          pagination={pagination}
        />
      </KTCardBody>
    </KTCard>
  )
}
export default PrijavaKvara
