import axios from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'

import {
  IntervencijaIzvrsioci,
  ParamsModelIntervencijeIzvrsioci,
} from '../models/IntervencijaIzvrsioci'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/intervencijaIzvrsioci`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getAllIntervencijeIzvrsioci(params: ParamsModelIntervencijeIzvrsioci) {
  return await axios.get<Array<IntervencijaIzvrsioci>>(API, {headers: headers, params: params})
}
