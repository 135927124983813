/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  getFilteredRowModel,
} from '@tanstack/react-table'
import TablePagination from '../../components/TablePagination/TablePagination'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import {Link} from 'react-router-dom'
import {TableActionButton} from '../../components/TableActionBtn/TableActionButton'
import {useNavigate} from 'react-router-dom'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import {
  FilteriZapisiProizvodnjeGalanterija,
  ParamsModelZapisiProizvodnjeGalanterija,
  ZapisiProizvodnjeGalanterija,
} from '../../models/ZapisiProizvodnjeGalanterija'

import UposlenikDropdown from '../../components/UposlenikDropdown/UposlenikDropdown'
import {PaginationModel} from '../../models/PaginationModel'

import {
  deleteZapisiProizvodnjeGalanterija,
  getAllZapisiProizvodnjeGalanterija,
} from '../../api/zapisiProizvodnjeGalanterija'
import moments from 'moment/moment'
import moment from 'moment/moment'
import ArtikliGalanterija from '../../components/ArtikliGalanterija/ArtikliGalanterija'
import {Modal, ModalHeader} from 'react-bootstrap'

const ZapisiProizvodnjeLista: React.FC = () => {
  const [data, setData] = useState<ZapisiProizvodnjeGalanterija[]>([])
  const [loading, setLoading] = useState(true)
  const [pagination, setpagination] = useState<PaginationModel>({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  })
  const [params, setparams] = useState<ParamsModelZapisiProizvodnjeGalanterija>({
    per_page: 10,
    page: 1,
  })
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [filters, setfilters] = useState<FilteriZapisiProizvodnjeGalanterija>({})

  const [suma, setSuma] = useState(0)
  const [isSumaOpen, setisSumaOpen] = useState(false)

  // Sum of Kolicina.
  const handleSuma = () => {
    var sum = 0
    table.getRowModel().rows.map((row) =>
      row.getVisibleCells().map((cell) => {
        if (cell.column.id === 'kolicina') {
          // @ts-ignore
          sum += parseFloat(cell.getValue() ?? '0')
        }
      })
    )
    setSuma(sum)
  }
  const [prosjek, setProsjek] = useState(0)
  const [isProsjekOpen, setisProsjekOpen] = useState(false)

  // Sum of Realizacija.
  const handleProsjek = async () => {
    let sum = 0
    let count = 0
    await table.getRowModel().rows.map((row) => {
      count += 1
      if (row.original.endTime) {
        // @ts-ignore
        sum += Math.round((row.original.kolicina / calculateNorma(row.original)) * 100)
      }
    })
    setProsjek(sum / count)
  }

  const updatePage = () => {
    setLoading(true)
    getAllZapisiProizvodnjeGalanterija(params)
      .then((response) => {
        setData(response.data['data'])
        const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
        setpagination({
          curPage: response.data['current_page'],
          totalPage: totalPage,
          perPage: params.per_page,
        })
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    updatePage()
  }, [params])

  useEffect(() => {
    const {id} = sorting[0] ?? ''
    data.sort((a, b) => a[id] - b[id])
  }, [sorting])
  function calculateNorma(row) {
    const timeDifferenceInMinutes = (row.endTime - row.startTime) / 60
    return Math.round((timeDifferenceInMinutes * row.norma) / row.operacije.normaMinuta)
  }
  const columns = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: 'Uposlenik',
      accessorKey: 'uposlenik.UposlenikImePrezime',
    },
    {
      header: 'Operacija',
      accessorKey: 'operacije.naziv',
    },
    {
      header: 'Datum',
      accessorKey: 'datum',
      accessorFn: (row) => `${moments(row.datum).format('DD-MM-YYYY')}`,
    },
    {
      header: 'Početak',
      accessorKey: 'startTime',
      accessorFn: (row) => moment.unix(row.startTime).format('HH:mm'),
    },
    {
      header: 'Kraj',
      accessorKey: 'endTime',
      accessorFn: (row) => (row.endTime ? moment.unix(row.endTime).format('HH:mm') : ''),
    },
    {
      header: 'Radio min:',
      accessorFn: (row) => (row.endTime ? Math.round((row.endTime - row.startTime) / 60) : ''),
    },
    {
      id: 'kolicina',
      header: 'Količina',
      accessorKey: 'kolicina',
    },
    {
      header: 'Norma',
      accessorFn: (row) => (row.endTime ? calculateNorma(row) : ''),
    },
    {
      id: 'Realizacija',
      header: 'Realizacija',
      accessorFn: (row) =>
        row.endTime ? Math.round((row.kolicina / calculateNorma(row)) * 100) : '',
    },
    {
      header: 'Akcija',
      accessorKey: 'Action',
      cell: ({row}) => (
        <TableActionButton
          onDelete={() => onDelete(row.original.id)}
          onEdit={() => {
            onEdit(row.original.id)
          }}
        />
      ),
    },
  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })
  const onDelete = (id) => {
    deleteZapisiProizvodnjeGalanterija(id)
      .then((response) => {
        setparams({per_page: pagination.perPage, page: pagination.curPage})
        notifyToast(true, response)
      })
      .catch((error) => {
        notifyToast(false, error.data)
      })
  }
  const navigate = useNavigate()

  const onEdit = (rowData) => {
    navigate(`/galanterija/zapisi-proizvodnje/izmjena/${rowData}`)
  }

  const onFilter = (range) => {
    setparams({
      ...params,
      datumOd: filters.datumOd,
      datumDo: filters.datumDo,
      uposlenik: filters.uposlenik,
      artikal: filters.artikal,
    })
  }
  // @ts-ignore
  return (
    <KTCard>
      <ToastContainer />
      <Modal show={isSumaOpen} onHide={() => setisSumaOpen(false)}>
        <ModalHeader closeButton={true} className='h3'>
          Suma: {suma} kom
        </ModalHeader>
      </Modal>
      <Modal show={isProsjekOpen} onHide={() => setisProsjekOpen(false)}>
        <ModalHeader closeButton={true} className='h3'>
          Prosjek: {prosjek}
        </ModalHeader>
      </Modal>
      <KTCardBody className='py-4'>
        <div className='row mt-3 py-10'>
          <h2>Filteri</h2>
          <div className='d-flex justify-content-between py-5'>
            <div className='col-2 fv-row'>
              <input
                type='date'
                className='form-control'
                name='datumOd'
                placeholder='Datum Od'
                onChange={(e) => setfilters({...filters, datumOd: e.target.value})}
              />
            </div>
            <div className='col-2 fv-row'>
              <input
                type='date'
                className='form-control'
                name='datumDo'
                placeholder='Datum Do'
                onChange={(e) => setfilters({...filters, datumDo: e.target.value})}
              />
            </div>
            <div className='col-2 fv-row'>
              <UposlenikDropdown
                onChange={(name, value) => setfilters({...filters, uposlenik: value})}
              />
            </div>
            <div className='col-2 fv-row'>
              <ArtikliGalanterija
                onChange={(name, value) => setfilters({...filters, artikal: value})}
              />
            </div>
            <button type='button' className='btn btn-primary' onClick={onFilter}>
              Potvrdi
            </button>
          </div>
        </div>
        <div className='d-flex justify-content-between mt-2'>
          <div data-kt-user-table-toolbar='base'>
            <TableSearch onSearch={(text) => setGlobalFilter(text)} />
          </div>
          <div data-kt-user-table-toolbar='base'>
            <button
              className='btn btn-light-primary me-3'
              onClick={() => {
                handleSuma()
                setisSumaOpen(true)
              }}
            >
              {' '}
              <KTIcon iconName='calculator' className='fs-2' />
              Suma količine
            </button>
            <button
              className='btn btn-light-info me-3'
              onClick={() => {
                handleProsjek()
                setisProsjekOpen(true)
              }}
            >
              {' '}
              <KTIcon iconName='calculator' className='fs-2' />
              Prosjek
            </button>
            <ExportButton table={table} filename={'promet_rolni'} />
           {/* <Link to='/repromaterijal/promet/dodaj'>
              <button type='button' className='btn btn-primary'>
                <KTIcon iconName='plus' className='fs-2' />
                Novi promet
              </button>
            </Link>*/}
          </div>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3 '>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className='text-white'
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* @ts-ignore */}
                        {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <TablePagination
          updatePage={(page, per_page) => setparams({...params, per_page: per_page, page: page})}
          pagination={pagination}
        />
      </KTCardBody>
    </KTCard>
  )
}
export default ZapisiProizvodnjeLista
