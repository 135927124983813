import axios, {AxiosResponse} from 'axios'
import {UposlenikModel, Option, PrijedloziZaPoboljsanja} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/uposlenici/prijedloziZaPoboljsanja`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getAllPrijedloziPoboljsanja(per_page: number, page: number) {
    const params = {per_page: per_page, page: page}
    return await axios.get<Array<PrijedloziZaPoboljsanja>>(API, {headers: headers, params: params})
}

export async function savePrijedlog(Data: any) {
    try {
        const response = await axios.post(API, Data, {headers: headers})
        return response.data
    } catch (error) {
        console.error('Error Saving Prijedlog data:', error)
        return error // Re-throw the error so it can be handled by the caller if necessary
    }
}