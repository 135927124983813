/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, forwardRef, useEffect, useMemo, useState} from 'react'
import FmkNalog from '../../terminali-components/FmkNalog'
import {FmkNalogiStavkeModel} from '../../../../models/FmkNaloziModel'
import {ToastContainer} from 'react-toastify'
import RadniNalogComponent from '../components/radniNalogComponent'
import UposlenikDropdown from "../../../../components/UposlenikDropdown/UposlenikDropdown";
import {KTIcon} from "../../../../../_websoft/helpers";
import {
    jelNalogNaPauzi,
    mojeStavkeNaNalogu,
    pauzirajRadniNalog,
    ponovoStartajNalog
} from "../../../../api/radniNalozi";
import notifyToast from "../../../../components/Toast/Toast";
import {useNavigate} from "react-router-dom";

interface Props {
    naloga: string
    dataStavka: FmkNalogiStavkeModel
}

export type Ref = HTMLButtonElement

const Step2 = forwardRef<Ref, Props>(({naloga, dataStavka}, ref) => {
    const nalogaId = useMemo(() => naloga, [naloga])
    const stavka = useMemo(() => dataStavka, [dataStavka])
    const [selectedStavka, setSelectedStavka] = useState<FmkNalogiStavkeModel['stavka']>([])
    const [nalogPostoji, startNalogPostoji] = useState(false)
    const [loading, setLoading] = useState(false)

    const [izvrsilac, setIzvrsilac] = useState<(string | null)[]>([null]); // Initialize with one item as null

    const handleIzvrsilacChange = (index, selectedId) => {
        const newIzvrsilac = [...izvrsilac];
        newIzvrsilac[index] = selectedId; // Update the ID at the specific index
        setIzvrsilac(newIzvrsilac);
    };

    const addIzvrsilac = () => {
        setIzvrsilac([...izvrsilac, null]); // Add a new null entry for a new dropdown
    };

    const removeIzvrsilac = (index) => {
        const newIzvrsilac = izvrsilac.filter((_, i) => i !== index); // Remove the entry at the specific index
        setIzvrsilac(newIzvrsilac);
    };

    const [nalog, startNalog] = useState(false)
    const navigate = useNavigate()
    const handlePonovoStartajNalog = async () => {
        try {
            setLoading(true); // Start loading
            const params = {idNaloga: nalogaId, izvrsilac: izvrsilac}
            ponovoStartajNalog(params)
                .then((response) => {
                    console.log({response})
                    notifyToast(true, response.message)
                 setTimeout(() => {
                        navigate(-1)
                    }, 3000)
                })
                .catch((error) => notifyToast(false, error.message))
                .finally(() => setLoading(false))
        } catch (error) {
            console.error('API call failed:', error);
        } finally {
            setLoading(false); // End loading
        }
    };
    {izvrsilac.map((value, index) => {
        console.log(`Index: ${index}, Value: `, value);
        // The rest of your mapping logic...
    })}
    useEffect(() => {
        if (nalogaId) {
            setLoading(true)
            const params = {idNaloga: nalogaId}
            jelNalogNaPauzi(params)
                .then((response) => {
                    if (response.error === 0)
                        startNalogPostoji(true)

                })
                .catch((error) => notifyToast(false, error.message))
                .finally(() => setLoading(false))
        }
    }, [nalogaId])
    return (
        <div className='w-100'>
            <ToastContainer/>
            <div className='row mt-5'>
                {nalogPostoji ? (
                    <>
                        <div className='col-12 mb-4'>
                            <h2>Ponovo započni proizvodnju radnog naloga</h2>
                            <hr/>
                        </div>
                        <div className='col-lg-6 col-sm-12 mt-4'>
                            <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                                Ostali Izvršioci  <code>Voditelj izrade naloga (onaj koji upisuje) ne upisuje sebe, on je automatski upisan</code>
                            </label>
                            {izvrsilac.map((value, index) => (
                                <div key={index} className='row'>
                                    <div className='col-lg-6 fv-row mb-6'>
                                        <UposlenikDropdown
                                            value={value ?? ''}
                                            onChange={(name, selectedId) => handleIzvrsilacChange(index, selectedId)}
                                        />
                                    </div>
                                    <div className='col-lg-2'>
                                        <button
                                            type='button'
                                            className='btn btn-danger'
                                            onClick={() => removeIzvrsilac(index)}
                                        >
                                            <KTIcon iconName='minus' className='fs-2'/>
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <div className='col-lg-2 mt-4'> {/* Adjusted for alignment */}
                                <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={addIzvrsilac}
                                >
                                    <KTIcon iconName='plus' className='fs-2'/>
                                </button>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <button
                                type='button'
                                className='btn btn-success col-12 p-5 mt-5 mb-10' // Adjusted button classes for full width in its column
                                onClick={handlePonovoStartajNalog}
                            >
                                {!loading && 'NALOG START'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                                )}
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='col-12'>
                            <RadniNalogComponent ref={ref} nalogaId={nalogaId} selectedStavka={selectedStavka}/>
                        </div>
                        <div className='col-12'>
                            <FmkNalog data={stavka} setStavkaData={setSelectedStavka}/>
                        </div>
                    </>
                )}
            </div>
        </div>

    );
})

export {Step2}
