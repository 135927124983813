import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {ProdajaNajamDropdownModel, ProdajaNajamModel} from "../models/ProdajaNajamModel";
import {ArtikliModel, ParamsModelArtikli, RoditeljiDropdownModel} from "../models/ArtikliModel";
import {ParamsModelReklamacije, ReklamacijeModel} from "../models/ReklamacijeModel";
import {ParamsModelKontrola} from "../models/PaneliKontrolaKvalitetaModel";

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/paneli/reklamacije`

const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

/*export async function getAllProdajaNajam(params: ProdajaNajamModel) {
    return await axios.get<Array<ProdajaNajamModel>>(API, {headers: headers, params: params})
}*/
export async function getAllPaneliReklamacije(params: ParamsModelReklamacije) {
    return await axios.get<Array<ReklamacijeModel>>(API, { headers: headers, params: params });
}

export async function fetchPaneliReklamacije(Id: number): Promise<Partial<ReklamacijeModel>> {
    try {
        const response = await axios.get(`${API}/${Id}`)
        return response.data.data
    } catch (error: any) {
        console.error('Error fetching data:', error)
        return error.response.data
    }
}

export async function savePaneliReklamacije(Data: any) {
    try {
        const response = await axios.post(API, Data, {headers: headers})
        if (response.data.error === 0) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function updatePaneliReklamacije(id: number, Data: any) {
    try {
        const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
        return response.data
    } catch (error: any) {
        console.error('Error fetching data:', error)
        return error.response.data // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function deletePaneliReklamacije(Id: number) {
    try {
        const response = await axios.delete(`${API}/${Id}`, {headers: headers})
        return response.data
    } catch (error: any) {
        console.error('Error deleting data:', error)
        return error.response.data // Re-throw the error so it can be handled by the caller if necessary
    }
}
