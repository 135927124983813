import {KTCard, KTCardBody} from "../../../../_websoft/helpers";
import {ToastContainer} from "react-toastify";
import React, {useEffect, useState} from "react";
import {postojiLiAktivanZapisZaUposlenika} from "../../../api/viljuskari";

const Viljuskari:React.FC = () => {

    const [zapisiId, setZapisiId] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchAktivanZapis = async () => {
            try {
                const response = await postojiLiAktivanZapisZaUposlenika({});
                if (response.data && response.data.Id) {
                    setZapisiId(response.data.Id);
                }
            } catch (error) {
                console.error('Error fetching active record:', error);
                setZapisiId(null);
            } finally {
                setLoading(false);
            }
        };

        fetchAktivanZapis();
    }, []);

    const postojiZapis = zapisiId ? `dodaj/${zapisiId}` : 'dodaj';

    console.log(postojiZapis, 'postojiZapis')
    return (
        <KTCard>
            <ToastContainer/>
            <KTCardBody className='py-4'>
                <div className='row'>
                    <div className='col-xl-4 mt-2'>
                        <a href={postojiZapis} className='card bg-success hoverable card-xl-stretch mb-xl-8'>
                            <div className='card-body'>
                                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                    <span className='path4'></span>
                                </i>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Početak / Kraj rada</div>
                            </div>
                        </a>
                    </div>
                    <div className='col-xl-4 mt-2'>
                        <a
                            href='zapisi/dodaj'
                            className='card bg-warning hoverable card-xl-stretch mb-xl-8'
                        >
                            <div className='card-body'>
                                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                    <span className='path4'></span>
                                </i>
                                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Dodatne akcije</div>
                            </div>
                        </a>
                    </div>
                </div>
            </KTCardBody>
        </KTCard>
    )
}
export default Viljuskari;
