import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../../components/TitleComponent/TitleComponent'
import QrCodeScanner from '../../../components/QrCodeScanner/QrCodeScanner'
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import notifyToast from '../../../components/Toast/Toast'
import LokacijeRepromaterijalaDropdown
    from '../../../components/LokacijeRepromaterijalaDropdown/LokacijeRepromaterijalaDropdown'
import {RepromaterijaliModel, RepromaterijalIzmjena} from '../../../models/RepromaterijaliModel'
import {getRolnaDetalji} from '../../../api/rolneDetalji'
import {updateLokacijaRolneRepromaterijala, updateRepromaterijali} from '../../../api'
import clsx from 'clsx'
import StatusComponent from "../../../components/GetFilterRepromaterijala/StatusComponent";
import StatusRolneDropdown from "../../../components/StatusDropdown/StatusRolneDropdown";

const IzmjenaLokacije: React.FC = () => {
    const initialValues = {
        stavkaId: '',
        lokacija: '',
        red: '',
        reprMatStatus:''
    }
    const validationSchema = Yup.object({
        stavkaId: Yup.string().required('Required'),
        lokacija: Yup.string().required('Required'),
        red: Yup.string().notRequired(),
    })

    const [loading, setLoading] = useState(false)
    const [dostupnostRolne, setDostupnostRolne] = useState<string>('')
    const [reprMatLokacija, setReprMatLokacija] = useState<string>('')
    const [reprMatLokacija2, setReprMatLokacija2] = useState<string>('')

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
            setLoading(true)
            try {
                // Assuming stavkaId is a single numeric ID
                const stavkaIdNum = parseInt(values.stavkaId, 10)
                if (!isNaN(stavkaIdNum)) {
                    // Prepare the data object
                    const data: Partial<RepromaterijalIzmjena> = {
                        stavkaId: [stavkaIdNum],
                        lokacija: values.lokacija,
                        red: values.red,
                        ReprMatStatus: values.reprMatStatus
                    }

                    // Call the API function
                    const response = await updateLokacijaRolneRepromaterijala(data)

                    // Process the response
                    if (response && response.error === 0) {
                        notifyToast(true, response.message)
                        resetForm();
                        setDostupnostRolne("")
                        setReprMatLokacija("")
                        setReprMatLokacija2("")
                    } else {
                        notifyToast(false, response.message || 'An unexpected error occurred.')
                    }
                } else {
                    notifyToast(false, 'Invalid stavkaId')
                }
            } catch (error) {
                console.error('Error: ', error)
                notifyToast(false, 'Error')
                setStatus(error)
            } finally {
                setSubmitting(false)
            }
            setLoading(false)
        },
    })

    useEffect(() => {
        const fetchRolnaDetalji = async () => {
            // Ensure stavkaId is a string and has at least 5 characters
            if (typeof formik.values.stavkaId === 'string' && formik.values.stavkaId.length >= 5) {
                const reprMatIdNum = parseInt(formik.values.stavkaId, 10) // Convert to integer

                if (!isNaN(reprMatIdNum) && reprMatIdNum > 0) {
                    // Check if it's a valid number
                    try {
                        const response = await getRolnaDetalji(reprMatIdNum)
                        setDostupnostRolne(response.data.ReprMatTrenutnaKolicina)
                        setReprMatLokacija(response.data.ReprMatLokacija)
                        setReprMatLokacija2(response.data.ReprMatLokacija2)
                    } catch (error) {
                        console.error('Error fetching Rolna details:', error)
                        // Handle error appropriately
                    } finally {
                    }
                }
            }
        }
        fetchRolnaDetalji()
    }, [formik.values.stavkaId])

    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer/>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name='lokacije i statusa rolne'/>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        {/* ROLNA */}
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>ROLNA</label>
                            <div className='col-lg-12'>
                                <div className='row mb-5'>
                                    <div className='col-sm-2 col-xs-4'>
                                        <QrCodeScanner onScan={(data) => formik.setFieldValue('stavkaId', data)}/>
                                    </div>
                                    <div className='col-sm-4 col-xs-6'>
                                        <input
                                            type='text'
                                            placeholder='Rolna'
                                            {...formik.getFieldProps('stavkaId')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.stavkaId && formik.errors.stavkaId},
                                                {
                                                    'is-valid': formik.touched.stavkaId && !formik.errors.stavkaId,
                                                }
                                            )}
                                            autoFocus
                                        />
                                        {formik.touched.stavkaId && formik.errors.stavkaId && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.stavkaId}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-sm-4 col-xs-6'>
                                        <input
                                            type='text'
                                            placeholder={loading ? 'Loading...' : 'Težina rolne'}
                                            className='form-control form-control-lg form-control-solid col-lg-3 disabled'
                                            value={loading ? '' : dostupnostRolne}
                                            readOnly={loading}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-5'>
                                    <div className='col-6'>
                                        <input
                                            type='text'
                                            placeholder={loading ? 'Loading...' : 'Trenutna lokacija'}
                                            className='form-control form-control-lg form-control-solid col-lg-3 disabled'
                                            value={loading ? '' : reprMatLokacija}
                                            readOnly={loading}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <input
                                            type='text'
                                            placeholder={loading ? 'Loading...' : 'Red'}
                                            className='form-control form-control-lg form-control-solid col-lg-3 disabled'
                                            value={loading ? '' : reprMatLokacija2}
                                            readOnly={loading}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-8 mt-5'>
                                <div className='row'>
                                    <div className='col-12 mb-5'>
                                        <label className='required fw-bold fs-6'>Izmjena lokacije</label>
                                    </div>
                                    <div className='col-6'>
                                        <LokacijeRepromaterijalaDropdown
                                            value={formik.values.lokacija}
                                            onChange={(name, value) => formik.setFieldValue('lokacija', value)}
                                            errors={formik.errors.lokacija}
                                            touched={formik.touched.lokacija}
                                            selectName='lokacija'
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <div className='col-lg-8 fv-row'>
                                            <select
                                                {...formik.getFieldProps('red')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {
                                                        'is-invalid': formik.touched.red && formik.errors.red,
                                                    },
                                                    {
                                                        'is-valid': formik.touched.red && !formik.errors.red,
                                                    }
                                                )}
                                            >
                                                <option value=''>Red..</option>
                                                <option value='0'>1</option>
                                                <option value='1'>2</option>
                                                <option value='1'>3</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                        <div className="col-6">
                                            <div className='col-12 mb-5'>
                                                <label className='fw-bold fs-6'>Izmjena statusa rolne</label>
                                            </div>
                                            <StatusRolneDropdown
                                                selectName='ReprMatStatus'
                                                onChange={(name, value) => formik.setFieldValue('reprMatStatus', value)}
                                                errors={formik.errors.reprMatStatus}
                                                touched={formik.touched.reprMatStatus}
                                            />
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={loading || formik.isSubmitting || !formik.isValid}
                        >
                            {!loading && 'Potvrdi'}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default IzmjenaLokacije
