import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
const API_PRIKAZI = `${API_URL}/paneli/prikaziOtpremnicuPanela`
const API_KREIRAJ = `${API_URL}/paneli/kreirajOtpremnicuPanela`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function prikaziOtpremnicuPanela(params: any) {
  try {
    return (await axios.get(API_PRIKAZI, {headers: headers, params: params})).data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function kreirajOtpremnicuPanela(Data: any) {
  try {
    const response = await axios.post(API_KREIRAJ, Data, {headers: headers})

    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
