/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import SearchableSelect from '../../../components/SearchableSelect/SearchableSelect'
import {getKcerkeSifreDropdown} from '../../../api/fmkNalozi'
import {Option} from '../../../models/Sektor'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'

interface DropdownProps {
  value?: string | number
  initialValue?: string | number
  onChange?: (name: string, value: any) => void
  errors?: string | undefined
  touched?: boolean
  selectName?: string
  sifra: number
}

const KcerkeSifreDropdown: React.FC<DropdownProps> = ({
  value,
  initialValue,
  onChange,
  errors,
  touched,
  selectName,
  sifra,
}) => {
  const [kcerkeSifreDropdown, setKcerkeSifreDropdown] = useState<Array<Option>>([])
  const internalValue = initialValue ?? null
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await getKcerkeSifreDropdown({sifra: sifra})
        setKcerkeSifreDropdown(mapData(response))
      } catch (error) {
        console.error('Error fetching Kvalitet Materijala dropdown data:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const finalValue = value ?? internalValue

  const mapData = (data) => {
    if (!data) {
      return []
    }
    return data.map((item) => ({label: item.ArtSifra.toString() + ' - ' +item.ArtStanjeSA.toString(), value: item.ArtId.toString()}))
  }

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {kcerkeSifreDropdown && kcerkeSifreDropdown.length > 0 ? (
            <SearchableSelect
              options={kcerkeSifreDropdown}
              value={finalValue?.toString() ?? ''}
              onChange={onChange!}
              name={selectName ?? 'KcerkeSifreDropdown'}
              errors={errors}
              placeholder='Odaberi Kcerke Sifre.'
              touched={touched}
            />
          ) : (
            <div className='d-flex justify-content-center'>No Data</div>
          )}
        </>
      )}
    </>
  )
}

export default KcerkeSifreDropdown
