/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from 'react'
import {Modal, ModalHeader} from 'react-bootstrap'
import notifyToast from '../Toast/Toast'
import {getDimenzijeNaloga, updateDimenzijeNaloga} from '../../api/proizvodnja'
import {DimenzijeNalogaModel} from '../../models/ProizvodnjaModel'
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import {KTIcon} from '../../../_websoft/helpers'

const DimenzijeModal = ({isModalOpen, setisModalOpen, stavkaId}) => {
  const stavka = useMemo(() => stavkaId, [stavkaId])
  const [loading, setloading] = useState(false)
  const [dimenzijeNaloga, setDimenzijeNaloga] = useState<DimenzijeNalogaModel[]>([])
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')

  const getDimenzije = () => {
    if (stavka) {
      setloading(true)
      getDimenzijeNaloga(stavka)
        .then((data) => {
          setDimenzijeNaloga(data)
        })
        .catch((error) => notifyToast(false, error.response.data.message))
        .finally(() => setloading(false))
    }
  }
  useEffect(() => {
    getDimenzije()
  }, [stavka])
  useEffect(() => {
    const {id} = sorting[0] ?? ''
    dimenzijeNaloga.sort((a, b) => a[id] - b[id])
  }, [sorting])

  // Set cell color according to condtions.
  const getCellColor = (cell, row) => {
    // Color for Tr.roba
    if (cell.column.id === 'isp')
      return row.original.isporucenDatum === null ? '#FF0000' : '#008000'
    // Color default
    return '#FFFFFF'
  }

  const columns = [
    {
      id: '#',
      header: '#',
      accessorFn: (row, index) => index + 1,
    },
    {
      id: 'Duzina',
      header: 'Dužina',
      accessorKey: 'Duzina',
      cell: TableCell,
    },
    {
      id: 'Sirina',
      header: 'Širina',
      accessorKey: 'Sirina',
      cell: TableCell,
    },
    {
      id: 'Prepust',
      header: 'Prepust',
      accessorFn: (row) => (row.Prepust === 0 ? 'Ne' : 'Da'),
      cell: TableCell,
    },
    {
      id: 'DuPrepusta',
      header: 'DuPrepusta',
      accessorKey: 'DuPrepusta',
      cell: TableCell,
    },
    {
      id: 'Kolicina',
      header: 'Kolicina',
      accessorKey: 'Kolicina',
      cell: TableCell,
    },
    {
      header: 'Action',
      cell: DeleteBtn,
    },
  ]
  const table = useReactTable({
    data: dimenzijeNaloga,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    meta: {
      updateData: (rowIndex: number, columnId: string, value: string) => {
        setDimenzijeNaloga((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          })
        )
      },
      addRow: () => {
        const newRow: DimenzijeNalogaModel = {IdProizvodnje: dimenzijeNaloga[0].IdProizvodnje}
        const setFunc = (old: DimenzijeNalogaModel[]) => [...old, newRow]
        setDimenzijeNaloga(setFunc)
      },
      removeRow: (rowIndex: number) => {
        const setFilterFunc = (old: DimenzijeNalogaModel[]) =>
          old.filter((_row: DimenzijeNalogaModel, index: number) => index !== rowIndex)
        setDimenzijeNaloga(setFilterFunc)
      },
    },
  })

  const onSubmit = () => {
    setloading(true)
    const payload = {
      IdProizvodnje: stavkaId.toString(),
      stavke: dimenzijeNaloga,
    }
    updateDimenzijeNaloga(payload)
      .then((response) => {
        notifyToast(true, response.message)
        // update()
        setisModalOpen(false)
      })
      .catch((error) => {
        console.log({error})
        notifyToast(false, error.message)
      })
      .finally(() => setloading(false))
  }

  return (
    <Modal className='modal-xl' show={isModalOpen} onHide={() => setisModalOpen(false)}>
      <ModalHeader closeButton={true} className='h3'>
        Dimenzije
      </ModalHeader>

      {loading ? (
        <LoadingComponent />
      ) : (
        <div className='table-responsive mt-3 p-10'>
          <div className='row justify-content-between'>
            <div className='col-6 p-5'>
              {dimenzijeNaloga.length > 0 && (
                // @ts-ignore
                <button className='btn btn-info' onClick={table.options.meta!.addRow}>
                  <KTIcon iconName='plus' className='fs-4 me-1' />
                  Dodaj novu
                </button>
              )}
            </div>
            <div className='col-3 p-5'>
              <button className='btn btn-primary' onClick={onSubmit}>
                Submit
              </button>
            </div>
          </div>
          <table className='table align-middle table-bordered gy-5 no-footer'>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      className='text-white'
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder ? null : (
                        <span>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {/* @ts-ignore */}
                          {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='text-gray-600'>
              {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </Modal>
  )
}

export default DimenzijeModal

const TableCell = ({getValue, row, column, table}) => {
  const initialValue = getValue()
  const [value, setValue] = useState(initialValue)
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])
  const onBlur = () => {
    table.options.meta?.updateData(row.index, column.id, value)
  }
  return (
    <input
      className='col-8 form-control'
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
    />
  )
}

const DeleteBtn = ({getValue, row, column, table}) => {
  return (
    <button className='btn btn-danger' onClick={() => table.options.meta?.removeRow(row.index)}>
      <KTIcon iconName='minus' />
    </button>
  )
}
