import React, {useEffect, useState} from 'react'
import SearchableSelect from '../../../../components/SearchableSelect/SearchableSelect'
import {getMasinePanelaDropdown, getSmjenePanelaDropdown} from '../../../../api/glavnaKnjigaProizvodnje'
import {Option} from '../../../../models/Sektor'
interface DropdownProps {
    value?: string | number
    initialValue?: string | number
    onChange?: (name: string, value: any) => void
    errors?: string | undefined
    touched?: boolean
    selectName?: string
}

const MasinePanelaDropdown: React.FC<DropdownProps> = ({
                                                           value,
                                                           initialValue,
                                                           onChange,
                                                           errors,
                                                           touched,
                                                           selectName,
                                                       }) => {
    const [MasinePanelaDropdown, setMasinePanelaDropdown] = useState<Array<Option>>([])
    const internalValue = initialValue ?? null

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getMasinePanelaDropdown()
                setMasinePanelaDropdown(mapData(response.data.data))
            } catch (error) {
                console.error('Error fetching dropdown data:', error)
            }
        }
        fetchData()
    }, [])

    const finalValue = value ?? internalValue

    const mapData = (data) => {
        if (!data) {
            return []
        }
        return data.map((item) => ({label: item.Naziv, value: item.Id.toString()}))
    }

    return (
        <SearchableSelect
            options={MasinePanelaDropdown}
            value={finalValue?.toString() ?? ''}
            onChange={onChange!}
            name={selectName ?? 'SmjenePanelaDropdown'}
            errors={errors}
            placeholder='Odaberi Liniju'
            touched={touched}
        />
    )
}

export default MasinePanelaDropdown
