import React, {useEffect, useMemo, useState} from 'react'
import {FmkNalogiStavkeModel} from '../../../models/FmkNaloziModel'
import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
import IndeterminateCheckbox from '../../../components/IndeterminateCheckbox/IndeterminateCheckbox'
import {StavkaRadniNaloziModel} from '../../../models/RadniNaloziModel'

interface Props {
  data: FmkNalogiStavkeModel
  setStavkaData: Function
}
const FmkNalog: React.FC<Props> = ({data, setStavkaData}) => {
  const tableData = useMemo(() => data.stavka, [data])
  const nalogData = useMemo(() => data.nalog, [data])

  const [loading, setLoading] = useState(false)
  const [rowSelection, setRowSelection] = useState({})
  const columns = [
    {
      id: 'id',
      header: ({table}) => (
        <div className='row p-2'>
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      cell: ({row, getValue}) => (
        <div className='col'>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    {
      id: '#',
      header: '#',
      accessorFn: (row, index) => index + 1,
    },
    {
      id: 'Šifra',
      header: 'Šifra',
      accessorKey: 'IdArtikla',
    },
    {
      id: 'Naziv',
      header: 'Naziv',
      accessorKey: 'Naziv',
    },
    {
      id: 'Norma',
      header: 'Norma',
      accessorKey: 'NormaMin',
      cell: ({row}: {row: any}) =>
          row.original.NormaMin > 0 ? (
              <span className='badge bg-success'>DA</span>
          ) : (
              <span className='badge bg-danger'>NE</span>
          ),
    },
    {
      id: 'JM',
      header: 'JM',
      accessorKey: 'IdJedMjere',
    },
    {
      id: 'Količina',
      header: 'Količina',
      accessorKey: 'Kolicina',
    },
    {
      id: 'Status',
      header: 'Status',
      accessorKey: 'statusi.Naziv',
      cell: ({ row }) => (
          <div
              style={{
                backgroundColor: row.original.statusi.Boja,
                color: '#fff', // Set text color to white for contrast, adjust as needed
                padding: '5px', // Add some padding, adjust as needed
                borderRadius: '5px', // Optional: add rounded corners
              }}
          >
            <span>{row.original.statusi.Naziv}</span>
          </div>
      ),
    },
  ]
  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      rowSelection: rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
  })

  useEffect(() => {
    const stavka = table.getSelectedRowModel().rows.map((item) => {
      return {
        Id: item.original.Id.toString(),
        SifraArtikla: item.original.IdArtikla,
        NazivArtikla: item.original.Naziv,
        Kolicina: item.original.Kolicina,
        Jm: item.original.IdJedMjere,
      }
    })
    setStavkaData(stavka)
  }, [rowSelection])

  return (
    <div className='table-responsive'>
      <h2>Kupac: {nalogData.Kupac}</h2>
      <h2>Nalog kreirao: {nalogData.Korisnik}</h2>

      {loading ? (
        <LoadingComponent />
      ) : (
        <div className='table-responsive mt-3 '>
          <table className='table align-middle table-bordered gy-5 no-footer'>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-primary p-2'
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      className='text-white'
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {/* @ts-ignore */}
                      {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='text-gray-600'>
              {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
export default FmkNalog
