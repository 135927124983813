import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import notifyToast from "../../components/Toast/Toast";
import {ToastContainer} from "react-toastify";
import TitleComponent from "../../components/TitleComponent/TitleComponent";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import clsx from "clsx";
import {PrijedloziZaPoboljsanja} from "../../models/UposlenikModel";
import {savePrijedlog} from "../../api/prijedloziZaPoboljsanja";

const PrijedloziZaPoboljsanjeDetails:React.FC = () => {

    const [loading, setLoading] = useState(false)

    const initialValues = {
        Naslov: '',
        Opis: '',
    }
    const [initialData, setInitialData] = useState<Partial<PrijedloziZaPoboljsanja>>(initialValues)

    const validationSchema = Yup.object({
        Naslov: Yup.string().min(2, 'Must be at least 2 characters').required('Required'),
        Opis: Yup.string().min(2, 'Must be at least 10 characters').required('Required'),
    })
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
            setLoading(true)
            try {
                let response
                response = await savePrijedlog(values)
                if (response.error === 0) {
                    notifyToast(true, response.message)
                    resetForm();
                    setTimeout(() => {
                        navigate(-1)
                    }, 3000)
                } else {
                    notifyToast(false, response.message || 'An unexpected error occurred.')
                }
            } catch (error) {
                console.error('Error: ', error)
                notifyToast(false, 'Error')
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        }
    })
    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer />
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name=' ' />
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                {loading ? (
                    <LoadingComponent />
                ) : (
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body border-top p-9'>
                            {/* Naziv */}
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Prijedlog </label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Prijedlog'
                                        {...formik.getFieldProps('Naslov')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.Naslov && formik.errors.Naslov},
                                            {
                                                'is-valid': formik.touched.Naslov && !formik.errors.Naslov,
                                            }
                                        )}
                                    />
                                    {formik.touched.Naslov && formik.errors.Naslov && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Naslov}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Opis */}
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Opis Prijedloga</label>
                                <div className='col-lg-8 fv-row'>
                                      <textarea
                                          placeholder='Opis'
                                          {...formik.getFieldProps('Opis')}
                                          className={clsx(
                                              'form-control form-control-lg form-control-solid',
                                              {'is-invalid': formik.touched.Opis && formik.errors.Opis},
                                              {
                                                  'is-valid': formik.touched.Opis && !formik.errors.Opis,
                                              }
                                          )}
                                      />
                                    {formik.touched.Opis && formik.errors.Opis && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Opis}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={loading || formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && 'Potvrdi'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}
export default PrijedloziZaPoboljsanjeDetails;

