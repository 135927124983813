import React, {useMemo} from 'react'
import {PakingListeModel} from '../../models/PaneliPaketi'
import {Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer'
import piktogrami from '../../../_websoft/assets/images/piktogrami.jpg'
import moments from 'moment'
// Register font
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
})
Font.register({
  family: 'Roboto Bold',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
});
// Create styles
const styles = StyleSheet.create({
  pdf: {width: '100vh', height: '500px'},
  page: {
    flexDirection: 'column',
    flex: 1,
    height: '1300px',
    padding: 20,
  },
  section: {
    marginTop: 80,
    marginBottom: 10,
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  section2: {
    marginLeft: 5,
    marginTop:-20,
    padding: 10,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignText: 'flex-end',
    width: '250px',
  },

  tableText: {
    borderStyle: 'solid',
  },
  qrCode: {
    width: '75px',
  },
  title: {
    fontSize: 30,
    marginBottom: 5,
    textAlign: 'justify',
    fontFamily: 'Roboto',
  },
  subtitle: {
    fontSize: 12,
    fontFamily: 'Roboto Bold',
    marginVertical: 2.5,
    textAlign: 'left',
  },
  text: {
    fontSize: 10,
    textAlign: 'center',
    fontFamily: 'Roboto',
  },
  textLeft: {
    fontSize: 10,
    textAlign: 'left',
  },
  table: {
    width: '100%',
    paddingHorizontal: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #212121',
    borderRight: '1px solid #212121',
    borderLeft: '1px solid #212121',
  },
  bottom: {
    borderBottom: '1px solid #212121',
  },
  bold: {
    fontFamily: 'Roboto Bold',
  },
  row1: {
    width: '10%',
    paddingTop: 4,
    paddingBottom: 4,
  },
  row2: {
    width: '40%',
    borderRight: '1px solid #212121',
    borderLeft: '1px solid #212121',
    paddingTop: 4,
    paddingBottom: 4,
  },
  row3: {
    width: '50%',
    paddingTop: 4,
    paddingBottom: 4,
  },
  row4: {
    width: '50%',
    borderRight: '1px solid #212121',
    paddingTop: 4,
    paddingBottom: 4,
  },
  row5: {
    width: '50%',
    paddingTop: 4,
    paddingBottom: 4,
  },
  section3: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    paddingLeft: 20,
  },
  section3Row: {
    flexDirection: 'row',
    marginBottom:10,
  },
  section4Row: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    // flex: '1',
  },
  text2: {
    width: '50%',
    paddingVertical: 15,
    marginRight: '10%',
    borderBottom: '1px solid #212121',
  },
  pictogrami: {
    height: '55px',
    border: '1px solid #212121',
    padding: 10,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    padding: 5,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'right',
  },
  text3: {
    border: '2px solid #212121',
    alignSelf: 'center',
    padding: 15,
    fontSize: 24,
  },
  section4: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    marginBottom: 30,// Increase this value to push footer content further down
    paddingHorizontal: 20,
  },
})
interface Props {
  qrData: PakingListeModel[]
  qrList: Array<string>
}
const PakingListePdf: React.FC<Props> = ({qrData, qrList}) => {
  const data = useMemo(() => ({qrData}), [qrData])
  const list = useMemo(() => ({qrList}), [qrList])
  const pdfName = `${data.qrData[0].kupac ?? ''}-Paking-Lista`
  return (
      <Document style={{height: '1200px'}} title={pdfName}>
        {data.qrData &&
            data.qrData.map((page, i) => (
                <Page size='A4' style={styles.page} orientation='portrait'>
                  <View style={styles.section}>
                    {/* Section 1 */}
                    <View>
                      <Text style={styles.title}>PAKING LISTA</Text>
                      <Text style={styles.subtitle}>
                        Broj: {page.nalog} ({page.stavka})
                      </Text>
                      <Text style={styles.subtitle}>
                        Datum: {moments(page.datum).format('DD-MM-YYYY')}
                      </Text>
                      <View style={{flexDirection: 'row'}}>
                        {/* @ts-ignore */}
                        <Image style={styles.qrCode} source={{uri: list.qrList[i]}} />
                        <Text style={styles.text3}
                              render={({pageNumber, totalPages}) => `${page.paket} / ${totalPages}`}
                        />
                      </View>
                    </View>

                    {/* Section 2 */}
                    <View style={styles.section2}>
                      <Text style={styles.subtitle}>{page.kupac}</Text>
                      <Text style={styles.subtitle}>{page.adresa} </Text>
                      <Text style={styles.subtitle}>{page.grad} </Text>
                      <Text style={styles.subtitle}>{page.drzava} </Text>
                    </View>
                  </View>

                  {/* Table */}
                  <View style={styles.table}>
                    <View style={[styles.row, styles.bold]}>
                      <Text style={[styles.row1, styles.text, styles.bold]}>#</Text>
                      <Text style={[styles.row2, styles.text,styles.bold]}> ARTIKAL</Text>
                      <Text style={[styles.row3, styles.text, styles.bold]}>[mm x kom]</Text>
                    </View>

                    {page.dimenzije.map((val, i) => (
                        <View style={styles.row} wrap={false}>
                          <Text style={[styles.row1, styles.text]}>{i + 1}</Text>
                          <Text style={[styles.row2, styles.text]}>{page.artikal}</Text>
                          <Text style={[styles.row3, styles.text]}>
                            {val.duzina} x {val.brojPanela}
                          </Text>
                        </View>
                    ))}
                    <View style={styles.row} wrap={false}>
                      <Text style={[styles.row4, styles.text, styles.bold]}>Količina ukupno(m2)</Text>
                      <Text style={[styles.row5, styles.text]}>{page.kvadratura}</Text>
                    </View>
                    <View style={styles.row} wrap={false}>
                      <Text style={[styles.row4, styles.text, styles.bold]}>Gabarit Š/D/V</Text>
                      <Text style={[styles.row5, styles.text]}>{page.gabarit}</Text>
                    </View>
                    <View style={styles.row} wrap={false}>
                      <Text style={[styles.row4, styles.text, styles.bold]}>
                        Težina NETTO ukupno [kg]
                      </Text>
                      <Text style={[styles.row5, styles.text]}>{page.netto}</Text>
                    </View>
                    <View style={[styles.row, styles.bottom]} wrap={false}>
                      <Text style={[styles.row4, styles.text]}>
                        Težina BRUTTO ukupno [kg]
                      </Text>
                      <Text style={[styles.row5, styles.text]}>{page.brutto}</Text>
                    </View>
                  </View>
                  <View style={[styles.section3]}>
                    <Text style={styles.subtitle}>{page.krajnjiKupac} </Text>
                    <Text style={[styles.subtitle, styles.textLeft]}>Napomena: {page.napomena}</Text>

                  </View>
                  {/* Section 3*/}
                  {/* <View style={styles.section3}>
            </View> */}

                  {/* Section 4 */}
                  {/* Existing footer content */}
                  <View fixed style={styles.section4}>
                    <View style={styles.section3Row}>
                      <Text style={[styles.subtitle, styles.text2]}>Pakovao: </Text>
                      <Text style={[styles.subtitle, styles.text2]}>Proizvedeno: </Text>
                    </View>
                    <Image src={piktogrami} style={styles.pictogrami} />
                    <View style={styles.section4Row}>
                      <Text style={[styles.subtitle, styles.subtitle]}>OB-854/01</Text>
                      <Text style={[styles.subtitle, styles.subtitle]}>Izdanje 2: 30-12-2022</Text>
                      <Text style={[styles.subtitle, styles.subtitle]}>Rev: 1</Text>
                    </View>
                  </View>

                  {/* Additional footer content here, if any */}
                  {/* For example, adding a dynamic page number and total pages */}
                  <Text
                      style={styles.pageNumber}
                      render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
                      fixed
                  />
                </Page>
            ))}
      </Document>
  )
}

export default PakingListePdf
