/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {ErrorMessage} from 'formik'
import QrCodeScanner from '../../../../components/QrCodeScanner/QrCodeScanner'

interface Props {
  naloga: string | undefined
  setNaloga: Function
}
const Step1: FC<Props> = ({naloga, setNaloga}) => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          UPIŠI / SKENIRAJ BROJ NALOGA
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>
      </div>

      <div className='fv-row'>
        <div className='d-flex justify-content-between'>
          <QrCodeScanner onScan={(value) => setNaloga(value)} />
          <div className='col-8'>
            <input
              type='text'
              className='form-control'
              placeholder='Broj Naloga'
              value={naloga}
              onChange={(e) => setNaloga(e.target.value)}
            />
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='accountType' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
