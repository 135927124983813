/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useRef} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {KTIcon} from '../../../_websoft/helpers'

const MAX_FILE_SIZE = 2048000 //2048KB

const validFileExtensions = {application: ['pdf', 'doc', 'docx', 'xls', 'xlsx']}
function isValidFileType(fileName, fileType) {
  return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1
}

interface Props {
  setFile: Function
  label: string
  isFileValid: Function
}
const DocumentComponent: FC<Props> = ({setFile, label = 'Document', isFileValid}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const initialValues = {Document: undefined}
  const validationSchema = Yup.object({
    Document: Yup.mixed()
      .notRequired()
      .nullable()
      .test(
        'FILE_SIZE',
        'The File size must be lesser than 300kb', // @ts-ignoree
        (value) => !value || value.size <= MAX_FILE_SIZE
      )
      .test(
        'is-valid-type',
        'The document field must be a file of type: pdf, doc, docx, xls, xlsx.', // @ts-ignoree
        (value) => !value || isValidFileType(value && value.name.toLowerCase(), 'application')
      ),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {},
  })
  // Function to set  file.
  function handleChange(e) {
    setFile(e.target.files[0])
    formik.setFieldValue('Document', e.target.files[0])
  }
  useEffect(() => {
    isFileValid(formik.isValid)
  }, [formik.isValid])

  // Function for handling click event on input.
  const handleBtnClick = (event) => {
    if (hiddenFileInput.current) hiddenFileInput.current.click()
  }
  return (
    <div className='col mb-6'>
      <label className='col-lg-4 col-form-label fw-bold fs-6'>{label}</label>
      <div className='col-lg-4'>
        <div
          data-kt-image-input='true'
          onClick={handleBtnClick}
          className={clsx(
            'image-input-wrapper w-250px h-250px',
            {
              'is-invalid': formik.touched.Document && formik.errors.Document,
            },
            {
              'is-valid': formik.touched.Document && !formik.errors.Document,
            }
          )}
        >
          <KTIcon iconName='document' className='fs-6tx text-primary me-2' />
          {formik.errors.Document && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.Document}</div>
            </div>
          )}
          {/* @ts-ignore */}
          <div className='p-5 h3'>{formik.values.Document ? formik.values.Document.name : ''}</div>
        </div>

        <input
          type='file'
          onChange={handleChange}
          ref={hiddenFileInput}
          style={{display: 'none'}}
        />
      </div>
    </div>
  )
}

export default DocumentComponent
