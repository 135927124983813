import React, {useMemo, useState, useEffect} from "react";
import {Document, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import logo from "../../../_websoft/assets/images/logo.jpg";
import {KreirajQRcodeModel} from "../../models/KreirajQRcodeModel";
import {Sredstva} from "../../models/Sredstva";
import QRCode from 'qrcode';

const styles = StyleSheet.create({
    pdf: {width: '100vh', height: '500px'},
    page: {
        flexDirection: 'row',
        flex: 1,
    },
    section: {
        margin: 10,
        padding: 10,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    qrCode: {
        width: '140px',
    },
    text2: {
        marginVertical: 5,
        marginHorizontal: 12,
        fontSize: 18,
        textAlign: 'left',
        fontFamily: 'Roboto',
    },

    logo: {
        margin: 10,
        padding: 2,
        width: '200px',
    },
})

interface Props {
    qrData: any;
}

const SredstvaQrCodePdf: React.FC<Props> = ({ qrData }) => {
    const [qrUrl, setQrUrl] = useState('');

    useEffect(() => {
        const generateQR = async () => {
            const qrString = `${qrData.SredstvoInvBroj}; ${qrData.SredstvoNaziv}; ${qrData.Alternativa}`;
            const url = await QRCode.toDataURL(qrString);
            setQrUrl(url);
        };

        if (qrData) {
            generateQR();
        }
    }, [qrData]);
    return (
        <Document>
            <Page size='A6' orientation='landscape'>
                <View style={{ flexDirection: 'row' }}>
                    <Image src={logo} style={styles.logo} />
                </View>
                <View style={styles.page}>
                    <View>
                        <Text style={[styles.text2, { marginLeft: 20 }]}>
                            INV BROJ: {qrData.SredstvoInvBroj}
                        </Text>
                        <Text style={[styles.text2, { marginLeft: 20, fontSize:14 }]}>
                            {qrData.SredstvoNaziv}
                        </Text>
                        {/* Assume qrData has a qrList property */}
                        {qrUrl && <Image key={qrData.SredstvoInvBroj} style={[styles.qrCode,{ marginLeft: 20 }]} src={qrUrl} />}
                    </View>
                </View>
            </Page>
        </Document>
    );
}
export default SredstvaQrCodePdf;
