import React from 'react'
import {useAuth} from './core/Auth'
import {Navigate, Outlet, useLocation} from 'react-router-dom'

const RequireAuth = ({allowedRoles}) => {
  const {currentUser} = useAuth()
  const location = useLocation()

  return allowedRoles.includes(currentUser!.Privilegija) ? (
    <Outlet />
  ) : (
    <Navigate to='/unauthorized' state={{from: location}} replace />
  )
}

export default RequireAuth
