import React, { useEffect, useState } from 'react';
import { KTCard, KTCardBody, KTIcon } from '../../../../_websoft/helpers';
import { useParams } from 'react-router-dom';
import { Table, Form, InputGroup, Button } from 'react-bootstrap';
import { getNaloziZaTraku } from "../../../api/paneliPromet";
import { PripremaPrometaModel } from "../../../models/PripremaPrometaModel";
import { ToastContainer } from "react-toastify";
import {prometRolnePaneli, prometTrakePaneli} from "../../../api/thermoPrometRolne";
import notifyToast from "../../../components/Toast/Toast";
import { useNavigate } from 'react-router-dom';

const PaneliPrometTraka = () => {
    const { id } = useParams<{ id: string }>();
    const [data, setData] = useState<PripremaPrometaModel[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [tezinaRolne, setTezinaRolne] = useState<number>(0);
    const [brojRolne, setBrojRolne] = useState<number>(0);
    const [krajnjaKolicinaRolne, setKrajnjaKolicinaRolne] = useState<string>('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = { trakaId: id };
                const result = await getNaloziZaTraku(params);
                setData(result.data);
                setTezinaRolne(result.data[0].traka.Tezina);
                setBrojRolne(result.data[0].repromaterijal.ReprMatId);
                calculateTotal(result.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    const calculateTotal = (data) => {
        const total = data.reduce((acc, item) => acc + parseFloat(item.KolicinaRolnePotrebna), 0);
        setTotal(total);
    };

    const handleKolicinaChange = (index, value) => {
        const newData = [...data];
        newData[index].KolicinaRolnePotrebna = value;
        setData(newData);
        calculateTotal(newData);
    };

    const adjustQuantities = (data, krajnjaKolicina, tezinaRolne) => {
        const currentTotal = data.reduce((acc, item) => acc + parseFloat(item.KolicinaRolnePotrebna), 0);
        const suggestedWeight = Math.round(tezinaRolne - currentTotal);
        const difference2 = tezinaRolne - krajnjaKolicina;
        const difference = difference2 - currentTotal;
        const adjustment = difference / data.length;

        return data.map(item => ({
            ...item,
            KolicinaRolnePotrebna: (parseFloat(item.KolicinaRolnePotrebna) + adjustment).toFixed(2)
        }));
    };

    const handleKrajnjaKolicinaChange = (e) => {
        const value = e.target.value;

        setKrajnjaKolicinaRolne(value);

        if (value) {
            const adjustedData = adjustQuantities(data, parseFloat(value), tezinaRolne);
            setData(adjustedData);
            calculateTotal(adjustedData);
        }
    };

    const handleButtonClick = async () => {
        const additionalData = {
            brojTrake: id,
            krajnjaKolicina: krajnjaKolicinaRolne,
        };

        const payload = data.map(item => ({
            brojNaloga: item.BrojNaloga,
            stavka: item.Stavka,
            gornjiLim: item.RolnaG,
            KolicinaRolnePotrebna: item.KolicinaRolnePotrebna,
            ...additionalData,
        }));

        try {
            const response = await prometTrakePaneli(payload);
            const responseData = response;

            if (responseData.error === 0) {
                notifyToast(true, responseData.message);
                setTimeout(() => {
                    navigate(-1);
                    window.location.reload();
                }, 3000);
            } else {
                notifyToast(false, responseData.message || 'An unexpected error occurred.');
            }
        } catch (error) {
            notifyToast(false, 'An unexpected error occurred.');
        }
    };

    return (
        <KTCard>
            <ToastContainer />
            <KTCardBody className='py-4'>
                <div className='container'>
                    <h1>Promet trake: <span className='text-success'>{id}</span></h1>
                    <div className='separator mt-5 mb-5'></div>
                    <div className='row mt-10'>
                        <div className='col-xl-6 col-md-6 mt-2'>
                            <h3>Rolna #: <span className='text-success'>R-{brojRolne}</span></h3>
                            <h3>Trenutna Težina Trake: <span className='text-success'>{tezinaRolne} kg</span></h3>
                            <h3>Prijedlog Krajnje Težine: <span className='text-danger'>{Math.round(tezinaRolne - total)} kg</span></h3>
                        </div>
                        <div className='col-xl-6 col-md-6 mt-2'>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Krajnja Težina Trake</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    value={krajnjaKolicinaRolne}
                                    onChange={handleKrajnjaKolicinaChange}
                                />
                            </InputGroup>
                        </div>
                    </div>
                    <div className='separator mt-5 mb-5'></div>
                    <div className='row mt-5'>
                        <h3>Traka korištena za izradu sljedećih naloga:</h3>
                        <div className='col-xl-12 mt-5'>
                            <Table striped bordered hover>
                                <thead>
                                <tr className='bg-primary text-white'>
                                    <th>Broj Naloga</th>
                                    <th>Stavka</th>
                                    <th>Gornji Lim</th>
                                    <th>Donji Lim</th>
                                    <th>Kolicina</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((item, index) => (
                                    <tr key={item.Id}>
                                        <td>{item.BrojNaloga}</td>
                                        <td>{item.Stavka}</td>
                                        <td>{item.RolnaG === 1 ? <i className="bi bi-check fs-2 text-success"></i> : <i className="bi bi-x fs-2 text-danger"></i>}</td>
                                        <td>{item.RolnaG === 0 ? <i className="bi bi-check fs-2 text-success"></i> : <i className="bi bi-x fs-2 text-danger"></i>}</td>
                                        <td>
                                            <Form.Control
                                                type="number"
                                                value={item.KolicinaRolnePotrebna}
                                                onChange={(e) => handleKolicinaChange(index, e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={4} className="text-right"><h3>Total:</h3></td>
                                    <td><h3>{total}</h3></td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <Button onClick={handleButtonClick} className="btn btn-btn-block btn-success mt-3">
                            Kreiraj promet trake
                        </Button>
                    </div>
                </div>
            </KTCardBody>
        </KTCard>
    );
};

export default PaneliPrometTraka;
