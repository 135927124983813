import {UposlenikModel} from "../models/UposlenikModel";
import {getAuth} from "../modules/auth";
import axios from "axios";
import {PaneliKontrolaKvalitetaModel, ParamsModelKontrola} from "../models/PaneliKontrolaKvalitetaModel";
import {ParamsModelUgovori, RolneUgovori} from "../models/RolneUgovori";

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/paneli/kontrolaKvaliteta`

const auth: UposlenikModel = getAuth()!

const headers = {
    Accept: 'application/json',
    Authorization: auth?.access_token ?? '',
}


export async function getPaneliKontrolaKvaliteta(params: ParamsModelKontrola) {
    return await axios.get<Array<RolneUgovori>>(API, {headers: headers, params: params})
}
export async function fetchPaneliKontrolaKvaliteta(Id: number): Promise<Partial<PaneliKontrolaKvalitetaModel>> {
    try {
        const response = await axios.get(`${API}/${Id}`)
        if (response.data && response.data.error === 0) {
            return response.data.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            return response.data.message || 'Failed to fetch.'
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}


export async function updatePaneliKontrolaKvaliteta(Data: any, id: number) {
    try {
        const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
        return response.data
    } catch (error) {
        console.error('Error:', error)
        return error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function savePaneliKontrolaKvaliteta(Data: any) {
    try {
        const response = await axios.post(API, Data, {headers: headers})
        return response.data
    } catch (error) {
        throw error
    }
}

export async function deletePaneliKontrolaKvaliteta(Id: number) {
    try {
        const response = await axios.delete(`${API}/${Id}`, {headers: headers})
        return response.data
    } catch (error) {
        return error
    }
}