import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {fetchPanelTip, savePanelTip, updatePanelTip} from '../../api/panelTip'
import {PanelTipModel} from '../../models/PanelTipModel'
import {useFormik} from 'formik'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
const TipPanelaDetails = () => {
  const {id} = useParams()
  const IdFromURL = Number(id)

  const [loading, setLoading] = useState(true)

  const initialValues = {
    Naziv: undefined,
    MetaraPoMinuti: undefined,
    Sifra: undefined,
    KrovniPanel: undefined,
    SirinaGlima: undefined,
    SirinaDlima: undefined,
    Debljina: undefined,
    Ispuna: undefined,
    Paket: undefined,
    PaketH3000: undefined,
    TezinaGLima: undefined,
    TezinaDLima: undefined,
    KolicinaPjene: undefined,
    Stiropol: undefined,
    StrecFolija: undefined,
    LjepiloPoliuretan: undefined,
    BrtvenaTraka: undefined,
    ZastitnaFolija: undefined,
    PoliolPur: undefined,
    IsocijanPur: undefined,
    PentanPur: undefined,
    Kx216: undefined,
    Zm99: undefined,
    IsocijanPir: undefined,
    PentanPir: undefined,
    Kx340: undefined,
    Kx133: undefined,
    PoliolEcoPur: undefined,
    IsocijanEcoPur: undefined,
    PentanEcoPur: undefined,
    Kx216EcoPur: undefined,
    Zm99EcoPur: undefined,
    LjepiloRW: undefined,
    KolicinaVune: undefined,
    Opis: undefined,
  }
  const [initialData, setInitialData] = useState<Partial<PanelTipModel>>(initialValues)

  useEffect(() => {
    if (IdFromURL) {
      fetchPanelTip(IdFromURL)
        .then((data) => {
          setInitialData(data)
          formik.resetForm({values: data}) // <-- reset formik state here
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [IdFromURL])

  const validationSchema = Yup.object({
    Naziv: Yup.string().required('Required'),
    MetaraPoMinuti: Yup.string().required('Required'),
    KrovniPanel: Yup.number().integer().required('Required'),
    SirinaGlima: Yup.number().required('Required'),
    SirinaDlima: Yup.number().required('Required'),
    Debljina: Yup.number().required('Required'),
    Ispuna: Yup.number().required('Required'),
    Paket: Yup.number().integer().required('Required'),
    PaketH3000: Yup.number().integer().required('Required'),
    TezinaGLima: Yup.number().notRequired(),
    TezinaDLima: Yup.number().notRequired(),
    KolicinaPjene: Yup.number().notRequired(),
    Stiropol: Yup.number().notRequired(),
    StrecFolija: Yup.number().notRequired(),
    LjepiloPoliuretan: Yup.number().notRequired(),
    BrtvenaTraka: Yup.number().notRequired(),
    ZastitnaFolija: Yup.number().notRequired(),
    PoliolPur: Yup.number().notRequired(),
    IsocijanPur: Yup.number().notRequired(),
    PentanPur: Yup.number().notRequired(),
    Kx216: Yup.number().notRequired(),
    Zm99: Yup.number().notRequired(),
    PoliolPir: Yup.number().notRequired(),
    IsocijanPir: Yup.number().notRequired(),
    PentanPir: Yup.number().notRequired(),
    Kx340: Yup.number().notRequired(),
    Kx133: Yup.number().notRequired(),
    PoliolEcoPur: Yup.number().notRequired(),
    IsocijanEcoPur: Yup.number().notRequired(),
    PentanEcoPur: Yup.number().notRequired(),
    Kx216EcoPur: Yup.number().notRequired(),
    Zm99EcoPur: Yup.number().notRequired(),
    LjepiloRW: Yup.number().notRequired(),
    KolicinaVune: Yup.number().notRequired(),
    Opis: Yup.string().notRequired(),
  })

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      try {
        let response
        if (IdFromURL) {
          response = await updatePanelTip(IdFromURL, values)
          //   window.location.reload()
        } else {
          response = await savePanelTip(values)
        }
      console.log(response.message)
        if (response.error === 0) {
          notifyToast(true, response.message)
        } else {
          notifyToast(false, response.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error)
        notifyToast(false, error)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  return (
    <div className='card mb-5 mb-xl-10'>
      <ToastContainer />
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <TitleComponent name='Tip-Panela' />
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        {loading ? (
          <LoadingComponent />
        ) : (
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className='card-body border-top p-9 row'>
              {/* Naziv */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>Naziv</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='text'
                    placeholder='Naziv'
                    {...formik.getFieldProps('Naziv')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.Naziv && formik.errors.Naziv},
                      {
                        'is-valid': formik.touched.Naziv && !formik.errors.Naziv,
                      }
                    )}
                  />
                  {formik.touched.Naziv && formik.errors.Naziv && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.Naziv}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* MetaraPoMinuti */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                  Metara Po Minuti
                </label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Metara Po Minuti'
                    {...formik.getFieldProps('MetaraPoMinuti')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.MetaraPoMinuti && formik.errors.MetaraPoMinuti},
                      {
                        'is-valid': formik.touched.MetaraPoMinuti && !formik.errors.MetaraPoMinuti,
                      }
                    )}
                  />
                  {formik.touched.MetaraPoMinuti && formik.errors.MetaraPoMinuti && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.MetaraPoMinuti}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Sifra */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>Sifra</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='text'
                    placeholder='Sifra'
                    {...formik.getFieldProps('Sifra')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.Sifra && formik.errors.Sifra},
                      {
                        'is-valid': formik.touched.Sifra && !formik.errors.Sifra,
                      }
                    )}
                  />
                  {formik.touched.Sifra && formik.errors.Sifra && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.Sifra}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Krovni panel */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-8 col-form-label fw-bold fs-6'>
                  <span className='required'>Krovni panel</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    {...formik.getFieldProps('KrovniPanel')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.KrovniPanel && formik.errors.KrovniPanel},
                      {
                        'is-valid': formik.touched.KrovniPanel && !formik.errors.KrovniPanel,
                      }
                    )}
                  >
                    <option value=''>Odaberi...</option>
                    <option value='1'>Da</option>
                    <option value='0'>Ne</option>
                  </select>
                  {formik.touched.KrovniPanel && formik.errors.KrovniPanel && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.KrovniPanel}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* SirinaGlima */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                  Sirina G lima
                </label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Sirina G lima'
                    {...formik.getFieldProps('SirinaGlima')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.SirinaGlima && formik.errors.SirinaGlima},
                      {
                        'is-valid': formik.touched.SirinaGlima && !formik.errors.SirinaGlima,
                      }
                    )}
                  />
                  {formik.touched.SirinaGlima && formik.errors.SirinaGlima && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.SirinaGlima}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* SirinaDlima */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                  Sirina D lima
                </label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Sirina D lima'
                    {...formik.getFieldProps('SirinaDlima')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.SirinaDlima && formik.errors.SirinaDlima},
                      {
                        'is-valid': formik.touched.SirinaDlima && !formik.errors.SirinaDlima,
                      }
                    )}
                  />
                  {formik.touched.SirinaDlima && formik.errors.SirinaDlima && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.SirinaDlima}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Debljina */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>Debljina</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Debljina'
                    {...formik.getFieldProps('Debljina')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.Debljina && formik.errors.Debljina},
                      {
                        'is-valid': formik.touched.Debljina && !formik.errors.Debljina,
                      }
                    )}
                  />
                  {formik.touched.Debljina && formik.errors.Debljina && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.Debljina}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Ispuna */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>Ispuna</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Ispuna'
                    {...formik.getFieldProps('Ispuna')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.Ispuna && formik.errors.Ispuna},
                      {
                        'is-valid': formik.touched.Ispuna && !formik.errors.Ispuna,
                      }
                    )}
                  />
                  {formik.touched.Ispuna && formik.errors.Ispuna && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.Ispuna}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Paket */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>Paket</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Paket'
                    {...formik.getFieldProps('Paket')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.Paket && formik.errors.Paket},
                      {
                        'is-valid': formik.touched.Paket && !formik.errors.Paket,
                      }
                    )}
                  />
                  {formik.touched.Paket && formik.errors.Paket && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.Paket}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* PaketH3000 */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                  Paket H 3000
                </label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Paket H 3000'
                    {...formik.getFieldProps('PaketH3000')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.PaketH3000 && formik.errors.PaketH3000},
                      {
                        'is-valid': formik.touched.PaketH3000 && !formik.errors.PaketH3000,
                      }
                    )}
                  />
                  {formik.touched.PaketH3000 && formik.errors.PaketH3000 && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.PaketH3000}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* TezinaGLima */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label  fw-bold fs-6'>Tezina G Lima</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Tezina G Lima'
                    {...formik.getFieldProps('TezinaGLima')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.TezinaGLima && formik.errors.TezinaGLima},
                      {
                        'is-valid': formik.touched.TezinaGLima && !formik.errors.TezinaGLima,
                      }
                    )}
                  />
                  {formik.touched.TezinaGLima && formik.errors.TezinaGLima && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.TezinaGLima}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* TezinaDLima */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label  fw-bold fs-6'>Tezina D Lima</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Tezina D Lima'
                    {...formik.getFieldProps('TezinaDLima')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.TezinaDLima && formik.errors.TezinaDLima},
                      {
                        'is-valid': formik.touched.TezinaDLima && !formik.errors.TezinaDLima,
                      }
                    )}
                  />
                  {formik.touched.TezinaDLima && formik.errors.TezinaDLima && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.TezinaDLima}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* KolicinaPjene */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>Kolicina Pjene</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Kolicina Pjene'
                    {...formik.getFieldProps('KolicinaPjene')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.KolicinaPjene && formik.errors.KolicinaPjene},
                      {
                        'is-valid': formik.touched.KolicinaPjene && !formik.errors.KolicinaPjene,
                      }
                    )}
                  />
                  {formik.touched.KolicinaPjene && formik.errors.KolicinaPjene && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.KolicinaPjene}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Stiropol */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>Stiropol</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='decimal'
                    placeholder='Stiropol'
                    {...formik.getFieldProps('Stiropol')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.Stiropol && formik.errors.Stiropol},
                      {
                        'is-valid': formik.touched.Stiropol && !formik.errors.Stiropol,
                      }
                    )}
                  />
                  {formik.touched.Stiropol && formik.errors.Stiropol && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.Stiropol}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* StrecFolija */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>Strec Folija</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Strec Folija'
                    {...formik.getFieldProps('StrecFolija')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.StrecFolija && formik.errors.StrecFolija},
                      {
                        'is-valid': formik.touched.StrecFolija && !formik.errors.StrecFolija,
                      }
                    )}
                  />
                  {formik.touched.StrecFolija && formik.errors.StrecFolija && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.StrecFolija}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Ljepilo */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>Ljepilo</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Ljepilo'
                    {...formik.getFieldProps('LjepiloPoliuretan')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid':
                          formik.touched.LjepiloPoliuretan && formik.errors.LjepiloPoliuretan,
                      },
                      {
                        'is-valid':
                          formik.touched.LjepiloPoliuretan && !formik.errors.LjepiloPoliuretan,
                      }
                    )}
                  />
                  {formik.touched.LjepiloPoliuretan && formik.errors.LjepiloPoliuretan && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.LjepiloPoliuretan}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Brtvena Traka */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>Brtvena Traka</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Brtvena Traka'
                    {...formik.getFieldProps('BrtvenaTraka')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.BrtvenaTraka && formik.errors.BrtvenaTraka,
                      },
                      {
                        'is-valid': formik.touched.BrtvenaTraka && !formik.errors.BrtvenaTraka,
                      }
                    )}
                  />
                  {formik.touched.BrtvenaTraka && formik.errors.BrtvenaTraka && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.BrtvenaTraka}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* Zastitna Folija */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>Zastitna Folija</label>
                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    placeholder='Zastitna Folija'
                    {...formik.getFieldProps('ZastitnaFolija')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.ZastitnaFolija && formik.errors.ZastitnaFolija,
                      },
                      {
                        'is-valid': formik.touched.ZastitnaFolija && !formik.errors.ZastitnaFolija,
                      }
                    )}
                  />
                  {formik.touched.ZastitnaFolija && formik.errors.ZastitnaFolija && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.ZastitnaFolija}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* End */}
              <div className='card-footer d-flex justify-content-end py-6 px-9'></div>
              <div className='row'>
                {/* Pur */}
                <div className='col-lg-3 col-md-6 mb-6'>
                  <h3>PUR</h3>
                  {/* PoliolPur */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Poliol (PUR)</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Poliol Pur'
                        {...formik.getFieldProps('PoliolPur')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.PoliolPur && formik.errors.PoliolPur,
                          },
                          {
                            'is-valid': formik.touched.PoliolPur && !formik.errors.PoliolPur,
                          }
                        )}
                      />
                      {formik.touched.PoliolPur && formik.errors.PoliolPur && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.PoliolPur}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Isocijan */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Isocijan</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Isocijan'
                        {...formik.getFieldProps('IsocijanPur')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.IsocijanPur && formik.errors.IsocijanPur,
                          },
                          {
                            'is-valid': formik.touched.IsocijanPur && !formik.errors.IsocijanPur,
                          }
                        )}
                      />
                      {formik.touched.IsocijanPur && formik.errors.IsocijanPur && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.IsocijanPur}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Pentan */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Pentan</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Pentan'
                        {...formik.getFieldProps('PentanPur')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.PentanPur && formik.errors.PentanPur,
                          },
                          {
                            'is-valid': formik.touched.PentanPur && !formik.errors.PentanPur,
                          }
                        )}
                      />
                      {formik.touched.PentanPur && formik.errors.PentanPur && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.PentanPur}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Kx216 */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Kx216</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Kx216'
                        {...formik.getFieldProps('Kx216')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.Kx216 && formik.errors.Kx216,
                          },
                          {
                            'is-valid': formik.touched.Kx216 && !formik.errors.Kx216,
                          }
                        )}
                      />
                      {formik.touched.Kx216 && formik.errors.Kx216 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.Kx216}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Zm 99 */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Zm 99</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Zm 99'
                        {...formik.getFieldProps('Zm99')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.Zm99 && formik.errors.Zm99,
                          },
                          {
                            'is-valid': formik.touched.Zm99 && !formik.errors.Zm99,
                          }
                        )}
                      />
                      {formik.touched.Zm99 && formik.errors.Zm99 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.Zm99}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* End */}
                </div>
                {/* Pir */}
                <div className='col-lg-3 col-md-6 mb-6'>
                  <h3>PIR</h3>
                  {/* Poliol Pir */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Poliol (Pir)</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Poliol Pir'
                        {...formik.getFieldProps('PoliolPir')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.PoliolPir && formik.errors.PoliolPir,
                          },
                          {
                            'is-valid': formik.touched.PoliolPir && !formik.errors.PoliolPir,
                          }
                        )}
                      />
                      {formik.touched.PoliolPir && formik.errors.PoliolPir && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.PoliolPir}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Isocijan */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Isocijan </label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Isocijan'
                        {...formik.getFieldProps('IsocijanPir')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.IsocijanPir && formik.errors.IsocijanPir,
                          },
                          {
                            'is-valid': formik.touched.IsocijanPir && !formik.errors.IsocijanPir,
                          }
                        )}
                      />
                      {formik.touched.IsocijanPir && formik.errors.IsocijanPir && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.IsocijanPir}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Pentan */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Pentan </label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Pentan'
                        {...formik.getFieldProps('PentanPir')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.PentanPir && formik.errors.PentanPir,
                          },
                          {
                            'is-valid': formik.touched.PentanPir && !formik.errors.PentanPir,
                          }
                        )}
                      />
                      {formik.touched.PentanPir && formik.errors.PentanPir && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.PentanPir}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Kx340/1 */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Kx340/1 </label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Kx340/1'
                        {...formik.getFieldProps('Kx340')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.Kx340 && formik.errors.Kx340,
                          },
                          {
                            'is-valid': formik.touched.Kx340 && !formik.errors.Kx340,
                          }
                        )}
                      />
                      {formik.touched.Kx340 && formik.errors.Kx340 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.Kx340}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Kx 133 */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Kx 133 </label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Kx 133'
                        {...formik.getFieldProps('Kx133')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.Kx133 && formik.errors.Kx133,
                          },
                          {
                            'is-valid': formik.touched.Kx133 && !formik.errors.Kx133,
                          }
                        )}
                      />
                      {formik.touched.Kx133 && formik.errors.Kx133 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.Kx133}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* End */}
                </div>
                {/* Ecopur */}
                <div className='col-lg-3 col-md-6 mb-6'>
                  <h3>ECOPUR</h3>
                  {/* PoliolEcoPur */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Poliol (EcoPur) </label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='PoliolEcoPur'
                        {...formik.getFieldProps('PoliolEcoPur')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.PoliolEcoPur && formik.errors.PoliolEcoPur,
                          },
                          {
                            'is-valid': formik.touched.PoliolEcoPur && !formik.errors.PoliolEcoPur,
                          }
                        )}
                      />
                      {formik.touched.PoliolEcoPur && formik.errors.PoliolEcoPur && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.PoliolEcoPur}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Isocijan */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Isocijan</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Isocijan'
                        {...formik.getFieldProps('IsocijanEcoPur')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                              formik.touched.IsocijanEcoPur && formik.errors.IsocijanEcoPur,
                          },
                          {
                            'is-valid':
                              formik.touched.IsocijanEcoPur && !formik.errors.IsocijanEcoPur,
                          }
                        )}
                      />
                      {formik.touched.IsocijanEcoPur && formik.errors.IsocijanEcoPur && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.IsocijanEcoPur}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Pentan */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Pentan</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Pentan'
                        {...formik.getFieldProps('PentanEcoPur')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.PentanEcoPur && formik.errors.PentanEcoPur,
                          },
                          {
                            'is-valid': formik.touched.PentanEcoPur && !formik.errors.PentanEcoPur,
                          }
                        )}
                      />
                      {formik.touched.PentanEcoPur && formik.errors.PentanEcoPur && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.PentanEcoPur}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Kx216 */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Kx216</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Kx216'
                        {...formik.getFieldProps('Kx216EcoPur')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.Kx216EcoPur && formik.errors.Kx216EcoPur,
                          },
                          {
                            'is-valid': formik.touched.Kx216EcoPur && !formik.errors.Kx216EcoPur,
                          }
                        )}
                      />
                      {formik.touched.Kx216EcoPur && formik.errors.Kx216EcoPur && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.Kx216EcoPur}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Zm 99 */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>ZM 99</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Zm 99'
                        {...formik.getFieldProps('Zm99EcoPur')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.Zm99EcoPur && formik.errors.Zm99EcoPur,
                          },
                          {
                            'is-valid': formik.touched.Zm99EcoPur && !formik.errors.Zm99EcoPur,
                          }
                        )}
                      />
                      {formik.touched.Zm99EcoPur && formik.errors.Zm99EcoPur && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.Zm99EcoPur}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* End */}
                </div>
                {/* RW */}
                <div className='col-lg-3 col-md-6 mb-6'>
                  <h3>RW</h3>
                  {/* Ljepilo RW */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Ljepilo RW</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Ljepilo RW'
                        {...formik.getFieldProps('LjepiloRW')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.LjepiloRW && formik.errors.LjepiloRW,
                          },
                          {
                            'is-valid': formik.touched.LjepiloRW && !formik.errors.LjepiloRW,
                          }
                        )}
                      />
                      {formik.touched.LjepiloRW && formik.errors.LjepiloRW && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.LjepiloRW}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Kolicina Vune */}
                  <div className='col mb-6'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Kolicina Vune</label>
                    <div className='col fv-row'>
                      <input
                        type='number'
                        placeholder='Kolicina Vune'
                        {...formik.getFieldProps('KolicinaVune')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.KolicinaVune && formik.errors.KolicinaVune,
                          },
                          {
                            'is-valid': formik.touched.KolicinaVune && !formik.errors.KolicinaVune,
                          }
                        )}
                      />
                      {formik.touched.KolicinaVune && formik.errors.KolicinaVune && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.KolicinaVune}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* End */}
                </div>
                {/* Opis */}
                <div className='col mb-6'>
                  <label className='col-lg-6 col-form-label fw-bold fs-6'>Opis</label>
                  <div className='col fv-row'>
                    <input
                      type='text'
                      placeholder='Opis'
                      {...formik.getFieldProps('Opis')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.Opis && formik.errors.Opis,
                        },
                        {
                          'is-valid': formik.touched.Opis && !formik.errors.Opis,
                        }
                      )}
                    />
                    {formik.touched.Opis && formik.errors.Opis && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.Opis}</div>
                      </div>
                    )}
                  </div>
                </div>
                {/* End */}
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={loading || formik.isSubmitting || !formik.isValid}
              >
                {!loading && 'Potvrdi'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default TipPanelaDetails
