import React, {useMemo, useState} from 'react'
import {KTSVG} from '../../../../../_websoft/helpers'
import clsx from 'clsx'
import {
  saveProizvodnja,
  updateArhivirajNalog,
  updateIzmjeniStatus,
  updateNalogVracen,
} from '../../../../api/proizvodnja'
import notifyToast from '../../../../components/Toast/Toast'

const PrroizodnjaIzmjena = ({rowSelection, updatePage}) => {
  const stavkaList = useMemo(() => rowSelection.map((item) => item.original.id), [rowSelection])
  const [izmjeniStatus, setizmjeniStatus] = useState<string>()
  const [datum, setDatum] = useState<string>()
  const [nalogVracen, setNalogVracen] = useState<string>()
  const [arhivirajNalog, setArhivirajNalog] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [upisiNalog, setupisiNalog] = useState<string>()

  const onClickIzmjeniStatus = () => {
    setLoading(true)
    const params = {
      stavkaId: stavkaList,
      status: izmjeniStatus,
      datum: datum,
    }
    updateIzmjeniStatus(params)
      .then((response) => {
        notifyToast(true, response.message)
        updatePage()
        setizmjeniStatus('')
        setDatum('')
      })
      .catch((response) => notifyToast(false, response.message))
      .finally(() => {
        setLoading(false)
      })
  }

  const onClickNalogVracen = () => {
    setLoading(true)
    const params = {
      stavkaId: stavkaList,
      status: nalogVracen,
    }
    updateNalogVracen(params)
      .then((response) => {
        notifyToast(true, response.message)
        updatePage()
        setNalogVracen('')
      })
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => {
        setLoading(false)
      })
  }
  const onClickArhivirajNalog = () => {
    setLoading(true)
    const params = {
      stavkaId: stavkaList,
      status: arhivirajNalog,
    }
    updateArhivirajNalog(params)
      .then((response) => {
        notifyToast(true, response.message)
        updatePage()
        setArhivirajNalog('')
      })
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpisiNalog = () => {
    setLoading(true)

    const dataToSend = {brojNaloga: upisiNalog}
    saveProizvodnja(dataToSend)
      .then((response) => {
        notifyToast(true, response.message)
        setupisiNalog('')
        updatePage()
      })
      .catch((error: any) => {
        // Handle the error here
        notifyToast(false, error)
        console.error('Error in handleUpisiNalog:', error)
      })
      .finally(() => setLoading(false))
  }
  return (
    <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
      <div className='mb-5'>
        <div
          className='accordion-header py-3 d-flex collapsed'
          data-bs-toggle='collapse'
          data-bs-target='#kt_accordion_2_item_2'
        >
          <span className='accordion-icon'>
            <KTSVG className='svg-icon svg-icon-4' path='/media/icons/duotune/arrows/arr064.svg' />
          </span>
          <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>IZMJENA</h3>
        </div>
        <div
          id='kt_accordion_2_item_2'
          className='collapse fs-6 ps-10'
          data-bs-parent='#kt_accordion_2'
        >
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-6'>
              <label className='col-auto col-form-label fw-bold fs-6'>
                <span className='required'>Status</span>
              </label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setizmjeniStatus(e.target.value)}
                value={izmjeniStatus}
              >
                <option value={undefined}>Select</option>
                <option value='Zavrseno'> Zavrseno</option>
                <option value='Planirano'>Proizvodnja planirana</option>
                <option value='Na čekanju'>Na čekanju</option>
              </select>
            </div>
            <div className='col-lg-4 col-md-6 mb-6'>
              <label className='col-auto col-form-label fw-bold fs-6'>
                <span className='required'>Datum Statusa</span>
              </label>
              <div className='col-auto fv-row'>
                <input
                  type='date'
                  placeholder='isporukaFMK'
                  className={clsx('form-control form-control-lg form-control-solid')}
                  onChange={(e) => setDatum(e.target.value)}
                  value={datum}
                />
              </div>
            </div>
            <div className='col-lg-4 col-md-6 mb-6 align-self-end'>
              <button
                type='button'
                className='btn btn-success'
                disabled={!datum || !izmjeniStatus || !stavkaList.length || loading}
                onClick={onClickIzmjeniStatus}
              >
                Potvrdi
              </button>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-4 mb-6'>
              <label className='col-auto col-form-label fw-bold fs-6'>
                <span className='required'>Nalog vraćen</span>
              </label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setNalogVracen(e.target.value)}
                value={nalogVracen}
              >
                <option value=''>Nalozi vraćeni</option>
                <option value='1'>Da</option>
                <option value='0'>Ne</option>
              </select>
            </div>
            <div className='col-lg-2 col-md-2 mb-6 align-self-end'>
              <button
                type='button'
                className='btn btn-success'
                disabled={!nalogVracen || !stavkaList.length || loading}
                onClick={onClickNalogVracen}
              >
                Potvrdi
              </button>
            </div>
            <div className='col-lg-4 col-md-4 mb-6'>
              <label className='col-auto col-form-label fw-bold fs-6'>
                <span className='required'>Arhiviraj nalog</span>
              </label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setArhivirajNalog(e.target.value)}
                value={arhivirajNalog}
              >
                <option value=''>Arhiviraj nalog</option>
                <option value='1'>Da</option>
                <option value='0'>Ne</option>
              </select>
            </div>
            <div className='col-lg-2 col-md-2 mb-6 align-self-end'>
              <button
                type='button'
                className='btn btn-success'
                disabled={!arhivirajNalog || !stavkaList.length || loading}
                onClick={onClickArhivirajNalog}
              >
                Potrdi
              </button>
            </div>
            <div className='col-lg-4 col-md-4 mb-6'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Upiši broj naloga'
                value={upisiNalog}
                onChange={(e) => setupisiNalog(e.target.value)}
              />
            </div>
            <div className='col-lg-2 col-md-2 mb-6 align-self-end'>
              <button
                type='button'
                className='btn btn-success'
                onClick={handleUpisiNalog}
                disabled={loading || !upisiNalog}
              >
                Potrdi
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrroizodnjaIzmjena
