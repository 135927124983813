import React, {useEffect, useState} from 'react'
import {Modal, ModalHeader} from 'react-bootstrap'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import {updatePaketi} from '../../../../api/paketiPanela'
import notifyToast from '../../../../components/Toast/Toast'
import {PaneliPaketi} from '../../../../models/PaneliPaketi'
import {KTIcon} from '../../../../../_websoft/helpers'

const PaneliPaketiModal = ({isModalOpen, setisModalOpen, paketiData, update}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<PaneliPaketi[]>(paketiData)

  useEffect(() => {
    setData(paketiData)
  }, [paketiData])

  const columns = [
    {
      header: 'Rb',
      accessorFn: (row, index) => index + 1,
      style: {width: '50px'},
    },
    {
      header: 'Duzina',
      accessorKey: 'duzina',
      cell: TableCell,
    },
    {
      header: 'Sirina',
      accessorKey: 'sirinaPaketa',
      cell: TableCell,
      style: {width: '50px'},
    },
    {
      header: 'Br. panela',
      accessorKey: 'brPanela',
      cell: TableCell,
      width: 100,
    },
    {
      header: 'Paket #',
      accessorKey: 'nazivPanela',
      cell: TableCell,
      width: 150,
    },
    {
      header: 'Visina',
      accessorKey: 'visinaPaketa',
    },
    {
      header: 'Napomena',
      accessorKey: 'napomena',
      cell: TableCell,
      width: 150,
    },
    {
      header: 'Action',
      cell: DeleteBtn,
    },
  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex: number, columnId: string, value: string) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          })
        )
      },
      addRow: () => {
        const newRow: PaneliPaketi = {idProizvodnje: data[0].idProizvodnje}
        const setFunc = (old: PaneliPaketi[]) => [...old, newRow]
        setData(setFunc)
      },
      removeRow: (rowIndex: number) => {
        const setFilterFunc = (old: PaneliPaketi[]) =>
          old.filter((_row: PaneliPaketi, index: number) => index !== rowIndex)
        setData(setFilterFunc)
      },
    },
  })

  const onSubmit = () => {
    setLoading(true)
    updatePaketi(data)
      .then((response) => {
        notifyToast(true, response.message)
        update()
        setisModalOpen(false)
      })
      .catch((error) => {
        console.log({error})
        notifyToast(false, error.message)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal className='modal-xl' show={isModalOpen} onHide={() => setisModalOpen(false)}>
      <ModalHeader closeButton={true} className='h3'>
        Stavke paketa
      </ModalHeader>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className='table-responsive mt-3 p-10'>
          <div className='row justify-content-between'>
            <div className='col-6 p-5'>
              {/* @ts-ignore */}
              <button className='btn btn-primary' onClick={table.options.meta!.addRow}>
                Dodaj novu stavku
              </button>
            </div>
            <div className='col-3 p-5'>
              <button className='btn btn-primary' onClick={onSubmit}>
                Submit
              </button>
            </div>
          </div>
          <table className='table align-middle table-bordered gy-5 no-footer'>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      className='text-white'
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {/* @ts-ignore */}
                      {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='text-gray-600'>
              {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </Modal>
  )
}

export default PaneliPaketiModal

const TableCell = ({getValue, row, column, table}) => {
  const initialValue = getValue()
  const [value, setValue] = useState(initialValue)
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])
  const onBlur = () => {
    table.options.meta?.updateData(row.index, column.id, value)
  }
  return (
    <input
      className='col-8 form-control'
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
    />
  )
}

const DeleteBtn = ({getValue, row, column, table}) => {
  return (
    <button className='btn btn-danger' onClick={() => table.options.meta?.removeRow(row.index)}>
      <KTIcon iconName='minus' />
    </button>
  )
}
