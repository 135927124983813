import {KTCard, KTCardBody} from '../../../../_websoft/helpers'
import {ToastContainer} from 'react-toastify'
import React from 'react'

const Repromaterijali: React.FC = () => {
  return (
    <KTCard>
      <ToastContainer />
      <KTCardBody className='py-4'>
        <div className='row'>
          <div className='col-xl-4 mt-2'>
            <a
              href='repromaterijali/lista'
              className='card bg-black hoverable card-xl-stretch mb-xl-8'
            >
              <div className='card-body'>
                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Lista rolni</div>
              </div>
            </a>
          </div>
          <div className='col-xl-4 mt-2'>
            <a
              href='repromaterijali/rolne-u-dolasku'
              className='card bg-black hoverable card-xl-stretch mb-xl-8'
            >
              <div className='card-body'>
                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Rolne u dolasku</div>
              </div>
            </a>
          </div>
          <div className='col-xl-4 mt-2'>
            <a
              href='repromaterijali/promet'
              className='card bg-black hoverable card-xl-stretch mb-xl-8'
            >
              <div className='card-body'>
                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Promet rolne</div>
              </div>
            </a>
          </div>
          <div className='col-xl-4 mt-2'>
            <a
                href='repromaterijali/promet-trake'
                className='card bg-primary hoverable card-xl-stretch mb-xl-8'
            >
              <div className='card-body'>
                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Promet trake</div>
              </div>
            </a>
          </div>
          <div className='col-xl-4 mt-2'>
            <a
              href='repromaterijali/izmjena-lokacije'
              className='card bg-primary hoverable card-xl-stretch mb-xl-8'
            >
              <div className='card-body'>
                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Izmijeni status / lokaciju</div>
              </div>
            </a>
          </div>
          <div className='col-xl-4 mt-2'>
            <a
              href='repromaterijali/prijavi-gresku'
              className='card bg-primary hoverable card-xl-stretch mb-xl-8'
            >
              <div className='card-body'>
                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Prijavi grešku rolne</div>
              </div>
            </a>
          </div>
          <div className='col-xl-4 mt-2'>
            <a
                href='repromaterijali/kartica-rolne'
                className='card bg-primary hoverable card-xl-stretch mb-xl-8'
            >
              <div className='card-body'>
                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Kartica rolne</div>
              </div>
            </a>
          </div>
          <div className='col-xl-4 mt-2'>
            <a
                href='repromaterijali/inventura-rolni'
                className='card bg-success hoverable card-xl-stretch mb-xl-8'
            >
              <div className='card-body'>
                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Inventura rolni</div>
              </div>
            </a>
          </div>
          <div className='col-xl-4 mt-2'>
            <a
                href='repromaterijali/inventura-traka'
                className='card bg-danger hoverable card-xl-stretch mb-xl-8'
            >
              <div className='card-body'>
                <i className='ki-duotone ki-element-11 text-white fs-3x ms-n1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
                <div className='text-white fw-bold fs-2 mb-2 mt-5'>Inventura traka</div>
              </div>
            </a>
          </div>
        </div>
      </KTCardBody>
    </KTCard>
  )
}
export default Repromaterijali
