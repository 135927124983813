import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {
  ArtikliDropdownModel,
  ArtikliModel, GalanterijaDropdownModel,
  ParamsModelArtikli,
  RoditeljiDropdownModel,
} from '../models/ArtikliModel'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/artikal`
const STABLO_ARTIKALA = `${API_URL}/RoditeljiKcerke`
const API_DROPDOWN = `${API_URL}/artikliDropdown`
const API_REKALKULACIJA = `${API_URL}/rekalkulacijaArtikalaIPaleta`
const RODITELJ_API_DROPDOWN = `${API_URL}/artikliRoditeljiDropdown`
const GALANTERIJA_API_DROPDOWN = `${API_URL}/artikliPresetorium`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}


export async function rekalkulacijaArtikala() {
  try {
    const response = await axios.put(API_REKALKULACIJA, {}, { headers: headers });
    return response.data;
  } catch (error) {
    console.error('Error during rekalkulacija:', error);
    throw error;
  }
}
export async function getArtikliDropdown() {
  return await axios.get<Array<ArtikliDropdownModel>>(API_DROPDOWN, {headers: headers})
}
export async function getGalanterijaDropdown() {
  return await axios.get<Array<GalanterijaDropdownModel>>(GALANTERIJA_API_DROPDOWN, {headers: headers})
}

export async function getRoditeljiDropdown() {
  return await axios.get<Array<RoditeljiDropdownModel>>(RODITELJ_API_DROPDOWN, {headers: headers})
}
export async function getArtikli(params: ParamsModelArtikli) {
  return await axios.get<Array<ArtikliModel>>(API, {headers: headers, params: params})
}

export async function saveArtikli(Data: any) {
  try {
    const response = await axios.post(API, Data, {headers: headers})

    if (response.data.error === 0) {
      return response.data
    } else {
      return response.data
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function fetchArtikli(Id: number): Promise<Partial<ArtikliModel>> {
  try {
    const response = await axios.get(`${API}/${Id}`)
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateArtikli(id: number, Data: any) {
  try {
    const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function deleteArtikli(Id: number) {
  try {
    const response = await axios.delete(`${API}/${Id}`, {headers: headers})
    if (response.data) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to delete.')
    }
  } catch (error) {
    console.error('Error deleting data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function stabloArtikala(){

  try {
    const response = await axios.get(STABLO_ARTIKALA)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
