/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {GlavnaKnjigaModel} from '../../models/GlavnaKnjigaModel'
import {
  fetchGlavnaKnjiga,
  saveGlavnaKnjiga,
  updateGlavnaKnjiga,
} from '../../api/glavnaKnjigaProizvodnje'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import clsx from 'clsx'
import PanelTipDropdown from './components/PanelTipDropdown/PanelTipDropdown'
import {KTIcon} from '../../../_websoft/helpers'
import moments from 'moment'
import SmjenePanelaDropdown from './components/SmjenePanelaDropdown/SmjenePanelaDropdown'
import {useNavigate} from 'react-router-dom'

const GlavnaKnjigaDetails = () => {
  const {id} = useParams()
  const IdFromURL = Number(id)

  const [loading, setLoading] = useState(true)

  const initialValues = {
    tipPanela: undefined,
    brojNaloga: [],
    godina: [],
    stavkaNaloga: [],
    dioNaloga: [1],
    nalogZavrsen: [],
    skart: undefined,
    skartLimova: undefined,
    skartVrstaLima: undefined,
    skartRavniLimova:undefined,
    skartTrapeza:undefined,
    brojStiklanja:undefined,
    ravniLimOdmotanPrijePro:undefined,
    proizvedeno: undefined,
    smjena: undefined,
    izmjenaAlata: 0,
    zastoj: 0,
    sati: undefined,
    minute: undefined,
    komentarZastoja: undefined,
    komentar: undefined,
  }
  const [initialData, setInitialData] = useState<Partial<GlavnaKnjigaModel>>(initialValues)

  const navigate = useNavigate()
  useEffect(() => {
    if (IdFromURL) {
      fetchGlavnaKnjiga(IdFromURL)
        .then((data) => {
          setInitialData(data)
          formik.resetForm({values: data}) // <-- reset formik state here
        })
        .catch((error) => notifyToast(false, error.response.data.message))
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [IdFromURL])

  const validationSchema = Yup.object({
    tipPanela: Yup.number().required('required'),
    proizvedeno: Yup.number().required('required'),
    smjena: Yup.string().required('required'),
  })

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response
        if (IdFromURL) {
          console.log(values, 'values')
          response = await updateGlavnaKnjiga(IdFromURL, values)
         setTimeout(() => {
            navigate(-1)
          }, 3000)
        } else {
          response = await saveGlavnaKnjiga(values)
          setTimeout(() => {
            navigate(-1)
          }, 3000)
        }

        if (response.error === 0) {
          notifyToast(true, response.message)
        } else {
          notifyToast(false, response.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <ToastContainer />
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <TitleComponent name='Glavna Knjiga' />
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        {loading ? (
          <LoadingComponent />
        ) : (
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className='card-body border-top p-9 row'>
              {/* Tip panela */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Tip Panela</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <PanelTipDropdown
                      value={formik.values.tipPanela}
                      onChange={(name, value) => formik.setFieldValue('tipPanela', value)}
                      errors={formik.errors.tipPanela}
                      touched={formik.touched.tipPanela}
                      selectName='tipPanela'
                  />
                </div>
              </div>
             {/* <div className='row mb-6'>
                {formik.values.brojNaloga && formik.values.brojNaloga.length > 0 &&
                    formik.values.brojNaloga.map((_, i) => (
                        <div className='row'>
                          brojNaloga
                          <div className='col-lg-2 col-md-6 mb-6'>
                            <label className='col-lg-6 col-form-label  fw-bold fs-6'>Broj Naloga</label>
                            <div className='col fv-row'>
                              <input
                                  type='text'
                                  placeholder='Broj Naloga'
                                  className={clsx('form-control form-control-lg form-control-solid')}
                                  value={formik.values.brojNaloga![i]}
                                  onChange={(e) => {
                                    var al = formik.values.brojNaloga ?? []
                                    // @ts-ignore
                                    al.splice(i, 1, e.target.value)
                                    formik.setFieldValue('brojNaloga', al)
                                  }}
                              />
                            </div>
                          </div>
                          godina
                          <div className='col-lg-2 col-md-6 mb-6'>
                            <label className='col-lg-6 col-form-label  fw-bold fs-6'>Godina</label>
                            <div className='col fv-row'>
                              <input
                                  type='number'
                                  placeholder='godina'
                                  className={clsx('form-control form-control-lg form-control-solid')}
                                  value={formik.values.godina![i]}
                                  onChange={(e) => {
                                    var al = formik.values.godina ?? []
                                    // @ts-ignore
                                    al.splice(i, 1, e.target.value)
                                    formik.setFieldValue('godina', al)
                                  }}
                              />
                              {formik.touched.godina && formik.errors.godina && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.godina}</div>
                                  </div>
                              )}
                            </div>
                          </div>
                          stavkaNaloga
                          <div className='col-lg-2 col-md-6 mb-6'>
                            <label className='col-lg-6 col-form-label  fw-bold fs-6'>Stavka</label>
                            <div className='col fv-row'>
                              <input
                                  type='number'
                                  placeholder='stavkaNaloga'
                                  className={clsx('form-control form-control-lg form-control-solid')}
                                  value={formik.values.stavkaNaloga![i]}
                                  onChange={(e) => {
                                    var al = formik.values.stavkaNaloga ?? []
                                    // @ts-ignore
                                    al.splice(i, 1, e.target.value)
                                    formik.setFieldValue('stavkaNaloga', al)
                                  }}
                              />
                              {formik.touched.stavkaNaloga && formik.errors.stavkaNaloga && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.stavkaNaloga}</div>
                                  </div>
                              )}
                            </div>
                          </div>
                          dioNaloga
                          <div className='col-lg-2 col-md-6 mb-6'>
                            <label className='col-lg-6 col-form-label  fw-bold fs-6'>Dio Naloga</label>
                            <div className='col fv-row'>
                              <input
                                  type='number'
                                  placeholder='Dio Naloga'
                                  className={clsx('form-control form-control-lg form-control-solid')}
                                  value={formik.values.dioNaloga![i]}
                                  onChange={(e) => {
                                    var al = formik.values.dioNaloga ?? []
                                    // @ts-ignore
                                    al.splice(i, 1, e.target.value)
                                    formik.setFieldValue('dioNaloga', al)
                                  }}
                              />
                              {formik.touched.dioNaloga && formik.errors.dioNaloga && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.dioNaloga}</div>
                                  </div>
                              )}
                            </div>
                          </div>
                          <div className='col-lg-2 col-md-6 mb-6'>
                            <label className='col-lg-8 col-form-label fw-bold fs-6'>
                              <span>Nalog Zavrsen</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                              <select
                                  value={formik.values.nalogZavrsen![i]}
                                  onChange={(e) => {
                                    var al = formik.values.nalogZavrsen ?? []
                                    // @ts-ignore
                                    al.splice(i, 1, e.target.value)
                                    formik.setFieldValue('nalogZavrsen', al)
                                  }}
                                  className={clsx(
                                      'form-control form-control-lg form-control-solid',
                                      {
                                        'is-invalid':
                                            formik.touched.nalogZavrsen && formik.errors.nalogZavrsen,
                                      },
                                      {
                                        'is-valid':
                                            formik.touched.nalogZavrsen && !formik.errors.nalogZavrsen,
                                      }
                                  )}
                              >
                                <option value=''>Odaberi...</option>
                                <option value='0'>Ne</option>
                                <option value='1'>Da</option>
                              </select>
                              {formik.touched.nalogZavrsen && formik.errors.nalogZavrsen && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.nalogZavrsen}</div>
                                  </div>
                              )}
                            </div>
                          </div>

                          <div className='col-lg-2 align-self-center'>
                            <button
                                type='button'
                                className='btn btn-danger btn-sm'
                                onClick={() => {
                                  var brojNalogaL = formik.values.brojNaloga ?? []
                                  var godinaL = formik.values.godina ?? []
                                  var stavkaNalogaL = formik.values.stavkaNaloga ?? []
                                  var dioNalogaL = formik.values.dioNaloga ?? []
                                  var nalogZavrsenL = formik.values.nalogZavrsen ?? []
                                  brojNalogaL.splice(i, 1)
                                  godinaL.splice(i, 1)
                                  stavkaNalogaL.splice(i, 1)
                                  dioNalogaL.splice(i, 1)
                                  nalogZavrsenL.splice(i, 1)
                                  formik.setFieldValue('brojNaloga', brojNalogaL)
                                  formik.setFieldValue('godina', godinaL)
                                  formik.setFieldValue('stavkaNaloga', stavkaNalogaL)
                                  formik.setFieldValue('dioNaloga', dioNalogaL)
                                  formik.setFieldValue('nalogZavrsen', nalogZavrsenL)
                                }}
                            >
                              <KTIcon iconName='minus' className='fs-2'/>
                            </button>
                          </div>
                        </div>
                    ))}
                <div className='col-lg-2 mb-6 py-5'>
                  <button
                      type='button'
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        var brojNalogaL = formik.values.brojNaloga ?? []
                        var godinaL = formik.values.godina ?? []
                        var stavkaNalogaL = formik.values.stavkaNaloga ?? []
                        var dioNalogaL = formik.values.dioNaloga ?? []
                        var nalogZavrsenL = formik.values.nalogZavrsen ?? []
                        //   @ts-ignore
                        brojNalogaL.push(undefined)
                        //   @ts-ignore
                        godinaL.push(moments().format('YY'))
                        //   @ts-ignore
                        stavkaNalogaL.push(0)
                        //   @ts-ignore
                        dioNalogaL.push(undefined)
                        //   @ts-ignore
                        nalogZavrsenL.push(undefined)
                        formik.setFieldValue('brojNaloga', brojNalogaL)
                        formik.setFieldValue('godina', godinaL)
                        formik.setFieldValue('stavkaNaloga', stavkaNalogaL)
                        formik.setFieldValue('dioNaloga', dioNalogaL)
                        formik.setFieldValue('nalogZavrsen', nalogZavrsenL)
                      }}
                  >
                    <KTIcon iconName='plus' className='fs-2'/>
                  </button>
                </div>
              </div>*/}
              {/* Proizvedeno */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label required fw-bold fs-6'>
                  Proizvedeno(m2)
                </label>
                <div className='col-lg-6 fv-row'>
                  <input
                      type='number'
                      placeholder='Proizvedeno'
                      {...formik.getFieldProps('proizvedeno')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.proizvedeno && formik.errors.proizvedeno},
                          {
                            'is-valid': formik.touched.proizvedeno && !formik.errors.proizvedeno,
                          }
                      )}
                  />
                  {formik.touched.proizvedeno && formik.errors.proizvedeno && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.proizvedeno}</div>
                      </div>
                  )}
                </div>
              </div>
              {/* smjenePanela */}
              <div className='col-lg-4 mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Smjena</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <SmjenePanelaDropdown
                      value={formik.values.smjena}
                      onChange={(name, value) => formik.setFieldValue('smjena', value)}
                      errors={formik.errors.smjena}
                      touched={formik.touched.smjena}
                      selectName='smjena'
                  />
                </div>
              </div>
              {/* izmjenaAlata */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label  fw-bold fs-6'>Izmjena Alata</label>
                <div className='col-lg-6 fv-row'>
                  <input
                      type='number'
                      placeholder='izmjenaAlata'
                      {...formik.getFieldProps('izmjenaAlata')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.izmjenaAlata && formik.errors.izmjenaAlata},
                          {
                            'is-valid': formik.touched.izmjenaAlata && !formik.errors.izmjenaAlata,
                          }
                      )}
                  />
                  {formik.touched.izmjenaAlata && formik.errors.izmjenaAlata && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.izmjenaAlata}</div>
                      </div>
                  )}
                </div>
              </div>
              {/* Zastoj */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-8 col-form-label fw-bold fs-6'>
                  <span>Zastoj</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                      {...formik.getFieldProps('zastoj')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.zastoj && formik.errors.zastoj,
                          },
                          {
                            'is-valid': formik.touched.zastoj && !formik.errors.zastoj,
                          }
                      )}
                  >
                    <option value=''>Odaberi...</option>
                    <option
                        value='0'
                        onClick={() => {
                          formik.setFieldValue('sati', undefined)
                          formik.setFieldValue('minute', undefined)
                        }}
                    >
                      Ne
                    </option>
                    <option value='1'>Da</option>
                  </select>
                  {formik.touched.zastoj && formik.errors.zastoj && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.zastoj}</div>
                      </div>
                  )}
                </div>
              </div>
              {/* Trajanje zastoja */}
              {formik.values.zastoj && formik.values.zastoj.toString() === '1' && (
                  <div className='col-lg-4 col-md-6 mb-6'>
                    <label className='col-lg-6 col-form-label  fw-bold fs-6'>Trajanje zastoja</label>
                    <div className='col-lg-6 fv-row mb-6'>
                      <input
                          type='text'
                          placeholder='sati'
                          {...formik.getFieldProps('sati')}
                          className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {'is-invalid': formik.touched.sati && formik.errors.sati},
                              {
                                'is-valid': formik.touched.sati && !formik.errors.sati,
                              }
                          )}
                      />
                      {formik.touched.sati && formik.errors.sati && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.sati}</div>
                          </div>
                      )}
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <input
                          type='text'
                          placeholder='minute'
                          {...formik.getFieldProps('minute')}
                          className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {'is-invalid': formik.touched.minute && formik.errors.minute},
                              {
                                'is-valid': formik.touched.minute && !formik.errors.minute,
                              }
                          )}
                      />
                      {formik.touched.minute && formik.errors.minute && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.minute}</div>
                          </div>
                      )}
                    </div>
                  </div>
              )}
              {/* komentarZastoja */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label  fw-bold fs-6'>Komentar Zastoja</label>
                <div className='col-lg-6 fv-row'>
                  <textarea
                      placeholder='Komentar Zastoja'
                      {...formik.getFieldProps('komentarZastoja')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                                formik.touched.komentarZastoja && formik.errors.komentarZastoja,
                          },
                          {
                            'is-valid':
                                formik.touched.komentarZastoja && !formik.errors.komentarZastoja,
                          }
                      )}
                  />
                  {formik.touched.komentarZastoja && formik.errors.komentarZastoja && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.komentarZastoja}</div>
                      </div>
                  )}
                </div>
              </div>
              {/* brojStiklanja */}
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label  fw-bold fs-6'>Broj štiklanja</label>
                <div className='col-lg-6 fv-row'>
                  <input
                      type='number'
                      placeholder='Broj Stiklanja'
                      {...formik.getFieldProps('brojStiklanja')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.brojStiklanja && formik.errors.brojStiklanja},
                          {
                            'is-valid': formik.touched.brojStiklanja && !formik.errors.brojStiklanja,
                          }
                      )}
                  />
                  {formik.touched.brojStiklanja && formik.errors.brojStiklanja && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.brojStiklanja}</div>
                      </div>
                  )}
                </div>
              </div>
              {/* Ravni lim odmotan prije proizvodnje */}
              <div className='col-lg-6 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label  fw-bold fs-6'>Ravni lim odmotan prije starta
                  proizvodnje</label>
                <div className='col-lg-6 fv-row'>
                  <input
                      type='number'
                      placeholder='Ravni lim'
                      {...formik.getFieldProps('ravniLimOdmotanPrijePro')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.ravniLimOdmotanPrijePro && formik.errors.ravniLimOdmotanPrijePro},
                          {
                            'is-valid': formik.touched.ravniLimOdmotanPrijePro && !formik.errors.ravniLimOdmotanPrijePro,
                          }
                      )}
                  />
                  {formik.touched.ravniLimOdmotanPrijePro && formik.errors.ravniLimOdmotanPrijePro && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.ravniLimOdmotanPrijePro}</div>
                      </div>
                  )}
                </div>
              </div>
              {/* komentar */}
              <div className='col-lg-12 col-md-12 mb-6'>
                <label className='col-lg-6 col-form-label  fw-bold fs-6'>Komentar</label>
                <div className='col-lg-6 fv-row'>
                  <textarea
                      placeholder='Komentar'
                      {...formik.getFieldProps('komentar')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.komentar && formik.errors.komentar,
                          },
                          {
                            'is-valid': formik.touched.komentar && !formik.errors.komentar,
                          }
                      )}
                  />
                  {formik.touched.komentar && formik.errors.komentar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.komentar}</div>
                      </div>
                  )}
                </div>
              </div>
              {/* skart */}
              <div className='separator mt-2 mb-2' style={{height: '20px', backgroundColor: '#000'}}></div>
              <h2 className='mt-2 mb-2'>Podatke ispod upisuju kontrolori</h2>
              <div className='col-lg-3 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label  fw-bold fs-6'>Skart panela</label>
                <div className='col-lg-6 fv-row'>
                  <input
                      type='number'
                      placeholder='Skart'
                      {...formik.getFieldProps('skart')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.skart && formik.errors.skart},
                          {
                            'is-valid': formik.touched.skart && !formik.errors.skart,
                          }
                      )}
                  />
                  {formik.touched.skart && formik.errors.skart && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.skart}</div>
                      </div>
                  )}
                </div>
              </div>
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label  fw-bold fs-6'>Škart ravnog lima</label>
                <div className='col-lg-6 fv-row'>
                  <input
                      type='number'
                      placeholder='Skart Ravni Limova'
                      {...formik.getFieldProps('skartRavniLimova')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.skartLimova && formik.errors.skartRavniLimova},
                          {
                            'is-valid': formik.touched.skartRavniLimova && !formik.errors.skartRavniLimova,
                          }
                      )}
                  />
                  {formik.touched.skartRavniLimova && formik.errors.skartRavniLimova && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.skartRavniLimova}</div>
                      </div>
                  )}
                </div>
              </div>
              <div className='col-lg-4 col-md-6 mb-6'>
                <label className='col-lg-6 col-form-label  fw-bold fs-6'>Škart Trapeza</label>
                <div className='col-lg-6 fv-row'>
                  <input
                      type='number'
                      placeholder='Skart Trapeza'
                      {...formik.getFieldProps('skartTrapeza')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.skartTrapeza && formik.errors.skartTrapeza},
                          {
                            'is-valid': formik.touched.skartTrapeza && !formik.errors.skartTrapeza,
                          }
                      )}
                  />
                  {formik.touched.skartTrapeza && formik.errors.skartTrapeza && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.skartTrapeza}</div>
                      </div>
                  )}
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={loading || formik.isSubmitting || !formik.isValid}
              >
                {!loading && 'Potvrdi'}
                {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default GlavnaKnjigaDetails
