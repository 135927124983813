/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import TablePagination from '../../components/TablePagination/TablePagination'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import TableSearch from '../../components/TableSearch/TableSearch'
import {ToastContainer} from 'react-toastify'
import notifyToast from '../../components/Toast/Toast'
import QRCode from 'qrcode'
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {ParamsModelSkartPaketi, SkartPaketiModel} from '../../models/SkartPaketiModel'
import {PaginationModel} from '../../models/PaginationModel'
import {deleteSkartPaketi, getPaketiDrugaKlasaQr, getSkartPaketi} from '../../api/skartPaketi'
import DimenzijePaketaModal from './components/DimenzijePaketaModal/DimenzijePaketaModal'
import {usePDF} from '@react-pdf/renderer'
import DrugaKlasaPakingListePdf from '../../components/QrCodeGenerator/DrugaKlasaPakingListePdf'
import {Link, useNavigate} from 'react-router-dom'
import {TableActionButton} from '../../components/TableActionBtn/TableActionButton'

const DrugaKlasaPaketi = () => {
  const [data, setData] = useState<SkartPaketiModel[]>([])
  const [loading, setLoading] = useState(true)
  const [pagination, setpagination] = useState<PaginationModel>({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setparams] = useState<ParamsModelSkartPaketi>({per_page: 10, page: 1})
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [isModalOpen, setisModalOpen] = useState(false)
  const [rowId, setrowId] = useState()
  const [qrList, setqrList] = useState<string[]>()
  const updatePage = () => {
    setLoading(true)
    getSkartPaketi(params)
      .then((response) => {
        const data = response.data['data']
        setData(data)
        const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
        setpagination({
          curPage: response.data['current_page'],
          totalPage: totalPage,
          perPage: params.per_page,
        })
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    updatePage()
  }, [params])

  const handlePakingListeBtnClick = (id) => {
    setLoading(true)
    const params = {
      paketId: id,
    }
    getPaketiDrugaKlasaQr(params)
      .then(async (response) => {
        convertQrSvgToImg(response.data)
        notifyToast(true, 'Success')
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error.response?.data?.message || 'An error occurred')
      })
      .finally(() => setLoading(false))
  }

  const convertQrSvgToImg = async (pakingListe) => {
    if (pakingListe) {
      var list: Array<string> = []
      await pakingListe.details.map((item) =>
        QRCode.toDataURL(item.qrCode).then((url) => {
          list.push(url)
        })
      )
      setqrList(list)
      updateInstance(<DrugaKlasaPakingListePdf qrData={pakingListe} qrList={list} />)
    }
  }

  const [instance, updateInstance] = usePDF({})

  // Open Pdf when intance link is generated.
  useEffect(() => {
    if (!instance.loading && instance.url && qrList?.length) {
      window.open(instance.url, '_blank')?.focus()
    }
  }, [instance])

  useEffect(() => {
    const {id} = sorting[0] ?? ''
    data.sort((a, b) => a[id] - b[id])
  }, [sorting])

  const StatusMap = {
    0: 'Aktivan',
    1: 'Raspoloživ',
    2: 'Rezervisan',
    3: 'Potrošen',
  }

  const columns = [
    {
      id: 'Paket',
      header: 'Paket',
      accessorKey: 'id',
    },
    {
      id: 'Limit',
      header: 'Limit',
      accessorFn: (row) => `${row.limitKomada} / ${row.trenutnoKomada}`,
    },
    {
      id: 'Klasa',
      header: 'Klasa',
      accessorKey: 'klasa',
    },

    {
      id: 'Lokacija',
      header: 'Lokacija',
      accessorKey: 'lokacija',
    },
    {
      id: 'Status',
      header: 'Status',
      accessorFn: (row) => StatusMap[row.status],
    },
    {
      id: 'dimenzije',
      header: 'dimenzije',
      cell: ({row}) => (
        <button
          onClick={() => {
            setisModalOpen(true)
            setrowId(row.original.id)
          }}
        >
          {' '}
          <KTIcon iconName={'grid'} className='fs-1' />
        </button>
      ),
    },
    {
      id: 'pdf',
      header: 'pdf',
      cell: ({row}) => (
        <button
          onClick={() => {
            handlePakingListeBtnClick(row.original.id)
          }}
        >
          {' '}
          <KTIcon iconName={'document'} className='fs-1' />
        </button>
      ),
    },
    {
      header: 'Akcija',
      accessorKey: 'Akcija',
      cell: ({row}) => (
        <TableActionButton
          onDelete={() => onDelete(row.original.id)}
          onEdit={() => {
            onEdit(row.original.id)
          }}
        />
      ),
    },
  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })

  const onDelete = (id) => {
    deleteSkartPaketi(id)
      .then((response) => {
        notifyToast(true, response.message)
        setparams({per_page: pagination.perPage, page: pagination.curPage})
      })
      .catch((error) => {
        notifyToast(false, error.data['message'])
      })
  }
  const navigate = useNavigate()

  const onEdit = (rowData) => {
    navigate(`/paneli/druga-klasa-paketi/izmjena/${rowData}`)
  }

  return (
    <KTCard>
      <ToastContainer />
      <DimenzijePaketaModal
        isModalOpen={isModalOpen}
        setisModalOpen={setisModalOpen}
        paketId={rowId}
      />
      <KTCardBody className='py-4'>
        <div className='col-lg-4 col-md-6 mb-6'>
          <label className='col-lg-8 col-form-label fw-bold fs-6'>
            <span className='required'>Status</span>
          </label>
          <div className='col-lg-8 fv-row'>
            <select
              className='form-control form-control-lg form-control-solid'
              onChange={(e) => setparams({...params, status: parseFloat(e.target.value)})}
            >
              <option value=''>Odaberi...</option>
              <option value='0'>Aktivan</option>
              <option value='1'>Raspoloživ</option>
              <option value='2'>Rezervisan</option>
              <option value='3'>Potrošen</option>
            </select>
          </div>
        </div>

        <div className='d-flex justify-content-between'>
          <div data-kt-user-table-toolbar='base'>
            <TableSearch onSearch={(text) => setGlobalFilter(text)} />
          </div>
          <div data-kt-user-table-toolbar='base'>
            <ExportButton table={table} filename={'Druga_klasa_paketi'} />
            <Link to='dodaj'>
              <button type='button' className='btn btn-primary'>
                <KTIcon iconName='plus' className='fs-2' />
                Dodaj
              </button>
            </Link>
          </div>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3 '>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className='text-white'
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* @ts-ignore */}
                        {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <TablePagination
          updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
          pagination={pagination}
        />
      </KTCardBody>
    </KTCard>
  )
}

export default DrugaKlasaPaketi
