import React, {useEffect, useMemo, useState} from 'react'
import {Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer'
import logo from '../../../../../_websoft/assets/images/logo.jpg'
// Register font
Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
})
// Create styles
const styles = StyleSheet.create({
    pdf: {width: '100vh', height: '500px'},
    page: {
        flexDirection: 'column',
        flex: 1,
        height: '300px',
        padding: 30,
        paddingBottom: 50,
    },
    text: {
        fontSize: 12,
        textAlign: 'center',
        fontFamily: 'Roboto',
        padding: 5,
    },
    table: {
        width: '100%',
        margin: 0,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    bold: {
        fontWeight: 'bold',
    },

    border: {
        border: '1px solid #212121',
    },
    bottom: {
        borderBottom: '1px solid #212121',
    },
    right: {
        borderRight: '1px solid #212121',
    },
    alignEnd: {
        alignSelf: 'flex-end',
    },
    logo: {
        margin: 2,
        padding: 2,
        width: '200px',
    },
    row1: {
        width: '30%',
        paddingTop: 8,
        paddingBottom: 8,
    },
    row2: {
        width: '20%',
        borderRight: '1px solid #212121',
        paddingTop: 8,
        paddingBottom: 8,
    },
    row3: {
        width: '50%',
        paddingTop: 8,
        paddingBottom: 8,
    },
    row4: {
        width: '10%',
        borderRight: '1px solid #212121',
        paddingTop: 8,
        paddingBottom: 8,
    },
    row5: {
        width: '30%',
        borderRight: '1px solid #212121',
        paddingTop: 8,
        paddingBottom: 8,
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        padding: 5,
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'right',
    },
})
const KreirajOtpremnicuPdf = ({isporuceniPaketi, isporuceniPaketiSuma, nalogIsporucen}) => {
    const table1 = useMemo(() => ({isporuceniPaketi}), [isporuceniPaketi])
    const table2 = useMemo(() => ({isporuceniPaketiSuma}), [isporuceniPaketiSuma])
    const [brPaketaMap, setbrPaketaMap] = useState({});

    const BrPaketaCalc = (paketi) => {
        const newBrPaketaMap = { ...brPaketaMap }; // Creating a new object for the state

        paketi.forEach((item) => {
            const nalogKey = `${item.proizvodnja.brojNaloga}-${item.proizvodnja.stavka}`;
            if (!newBrPaketaMap[nalogKey]) {
                newBrPaketaMap[nalogKey] = [];
            }
            if (!newBrPaketaMap[nalogKey].includes(item.nazivPanela)) {
                newBrPaketaMap[nalogKey].push(item.nazivPanela);
            }
        });

        setbrPaketaMap(newBrPaketaMap);
    }

    useEffect(() => {
        BrPaketaCalc(table1.isporuceniPaketi)
    }, [])

    return (
        <Document title='otpremnicu_pdf'>
            <Page size='A4' style={styles.page} orientation='landscape'>
                <Image src={logo} style={styles.logo} />
                {/* @ts-ignore */}
                {/* begin:Table1 */}
                <Text style={[{marginTop: 20}]}>{nalogIsporucen}</Text>
                <View style={[styles.table, styles.border, {marginTop: 20}]}>
                    <View style={[styles.row, styles.bold, styles.bottom, {backgroundColor: '#7CB9E8'}]}>
                        <Text style={[styles.row4, styles.text, styles.bold]}>#</Text>
                        <Text style={[styles.row5, styles.text, styles.bold]}>Kupac</Text>
                        <Text style={[styles.row5, styles.text, styles.bold]}>Nalog</Text>
                        <Text style={[styles.row2, styles.text, styles.bold]}>Panel</Text>
                        <Text style={[styles.row4, styles.text, styles.bold]}>Paket</Text>
                        <Text style={[styles.row2, styles.text, styles.bold]}>Dužina</Text>
                        <Text style={[styles.row2, styles.text, styles.bold]}>Širina</Text>
                        <Text style={[styles.row4, styles.text, styles.bold]}>Kom</Text>
                        <Text style={[styles.row2, styles.text, styles.bold]}>Kvadratura</Text>
                        <Text style={[styles.row1, styles.text, styles.bold]}>Isporučio</Text>
                    </View>

                    {table1.isporuceniPaketi.map((val, i) => {
                        const Kvadratura = (
                            (parseFloat(val.duzina) * parseFloat(val.sirinaPaketa) * parseFloat(val.brPanela)) /
                            1000 /
                            1000
                        ).toFixed(2)
                        return (
                            <View key={i}>
                                <View style={[styles.row, styles.bold, styles.bottom]} wrap={false}>
                                    <Text style={[styles.row4, styles.text]}>{i + 1}</Text>
                                    <Text style={[styles.row5, styles.text]}>{val.proizvodnja.kupac}</Text>
                                    <Text style={[styles.row5, styles.text]}>
                                        {val.proizvodnja.brojNaloga}{' '}
                                        {val.proizvodnja.stavka ? `- ${val.proizvodnja.stavka}` : ''}
                                    </Text>
                                    <Text style={[styles.row2, styles.text]}>{val.proizvodnja.tip_panela.Naziv}</Text>
                                    <Text style={[styles.row4, styles.text]}>{val.nazivPanela}</Text>
                                    <Text style={[styles.row2, styles.text]}>
                                        {parseFloat(val.duzina).toFixed(2)}
                                    </Text>
                                    <Text style={[styles.row2, styles.text]}>{val.sirinaPaketa}</Text>
                                    <Text style={[styles.row4, styles.text]}>{val.brPanela}</Text>
                                    <Text style={[styles.row2, styles.text]}>{Kvadratura}</Text>
                                    <Text style={[styles.row1, styles.text]}>
                                        {val.uposlenik.UposlenikImePrezime}
                                    </Text>
                                </View>
                            </View>
                        )
                    })}
                </View>
                {/* begin:Table1 */}
                {/* begin:Table2 */}
                <View style={[styles.table, styles.border, {marginTop: 20}]}>
                    <View style={[styles.row, styles.bold, styles.bottom, {backgroundColor: '#7CB9E8'}]}>
                        <Text style={[styles.row2, styles.text, styles.bold]}>Nalog</Text>
                        <Text style={[styles.row2, styles.text, styles.bold]}>Panel</Text>
                        <Text style={[styles.row2, styles.text, styles.bold]}>Kvadratura</Text>
                        <Text style={[styles.row2, styles.text, styles.bold]}>Netto</Text>
                        <Text style={[styles.row2, styles.text, styles.bold]}>Brutto</Text>
                        <Text style={[styles.row2, styles.text, styles.bold]}>Br.paketa</Text>
                    </View>

                    {table2.isporuceniPaketiSuma.map((val, i) => {
                        const netto = (
                            parseFloat(val.suma) * parseFloat(val.proizvodnja.artikal.tezina)
                        ).toFixed(2);
                        const nalogKey = `${val.proizvodnja.brojNaloga}-${val.proizvodnja.stavka}`;
                        const brPaketa = brPaketaMap[nalogKey] ? brPaketaMap[nalogKey].length : 0; // Correctly calculating brPaketa
                        const brutto = (parseFloat(netto) + 10 * brPaketa).toFixed(2);


                        return (
                            <View>
                                <View style={[styles.row, styles.bold, styles.bottom]} wrap={false}>
                                    <Text style={[styles.row2, styles.text]}>{nalogKey}</Text>
                                    <Text style={[styles.row2, styles.text]}>{val.proizvodnja.tip_panela.Naziv}</Text>
                                    <Text style={[styles.row2, styles.text]}>{parseFloat(val.suma).toFixed(2)}</Text>
                                    <Text style={[styles.row2, styles.text]}>{netto}</Text>
                                    <Text style={[styles.row2, styles.text]}>{brutto}</Text>
                                    <Text style={[styles.row2, styles.text]}>{brPaketa}</Text>
                                </View>
                            </View>
                        )
                    })}
                </View>
                {/* begin:Table1 */}
                <Text
                    style={styles.pageNumber}
                    render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </Page>
        </Document>
    )
}

export default KreirajOtpremnicuPdf
