import axios from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {
    ParamsModelZapisiProizvodnjeGalanterija,
    ZapisiProizvodnjeGalanterija
} from "../models/ZapisiProizvodnjeGalanterija";
const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/artikliZapisiProizvodnje`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getAllZapisiProizvodnjeGalanterija(params: ParamsModelZapisiProizvodnjeGalanterija) {
    return await axios.get<Array<ZapisiProizvodnjeGalanterija>>(API, {headers: headers, params: params})
}

export async function saveZapisiProizvodnjeGalanterija(Data: any) {
    try {
        const response = await axios.post(API, Data, {headers: headers})
        if (response.data.error === 0) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function fetchZapisiProizvodnjeGalanterija(
    Id: number
): Promise<Partial<ZapisiProizvodnjeGalanterija>> {
    try {
        const response = await axios.get(`${API}/${Id}`)
        if (response.data && response.data.error === 0) {
            return response.data.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function updateZapisiProizvodnjeGalanterija(Data: any, id: number) {
    try {
        const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
        if (response.data.error === 0) {
            return response.data
        } else {
            // Handle any other type of response structure or errors returned from the server here
            throw new Error(response.data.message || 'Failed to fetch.')
        }
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}

export async function deleteZapisiProizvodnjeGalanterija(Id: number) {
    try {
        const response = await axios.delete(`${API}/${Id}`, {headers: headers})

        // Check if the response status is success
        if (response.data.status === 'success') {
            return response.data.message
        } else {
            // Handle the error based on the message from the server
            return response.data.message
        }
    } catch (error) {
        console.error('Error deleting data:', error)
        throw error // Re-throw the error so it can be handled by the caller if necessary
    }
}
