import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_websoft/layout/core'
import ThermoBoardLista from './ThermoBoardLista'
import ThermoBoardDetails from "./ThermoBoardDetails";
import ThermoBoardPaketi from "./ThermoBoardPaketi";

const ThermoBoardBreadCrumbs: Array<PageLink> = [
    {
        title: 'ThermoBoard',
        path: '/ThermoBoard/dodaj',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ThermoBoardRoutes: React.FC = () => {
    return (
        <Routes>
            <Route
                element={
                    <>
                        <Outlet />
                    </>
                }
            >
                <Route
                    path='lista'
                    element={
                        <>
                            <PageTitle breadcrumbs={ThermoBoardBreadCrumbs}>Lista naloga</PageTitle>
                            <ThermoBoardLista />
                        </>
                    }
                />
                <Route
                    path='paketi'
                    element={
                        <>
                            <PageTitle breadcrumbs={ThermoBoardBreadCrumbs}>Lista paketa</PageTitle>
                            <ThermoBoardPaketi />
                        </>
                    }
                />
                <Route
                    path='dodaj'
                    element={
                        <>
                            <PageTitle breadcrumbs={ThermoBoardBreadCrumbs}>Dodaj</PageTitle>
                            <ThermoBoardDetails />
                        </>
                    }
                />
                <Route
                    path='izmjena/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={ThermoBoardBreadCrumbs}>Izmjena</PageTitle>
                            <ThermoBoardDetails />
                        </>
                    }
                />
                <Route index element={<Navigate to='/thermoboard/Lista' />} />
            </Route>
        </Routes>
    )
}

export default ThermoBoardRoutes
