import React, {useEffect, useState} from "react";
import {AktivnePaleteModel, ParamsModelAktivnePalete} from "../../models/AktivnePaleteModel";
import {PaginationModel} from "../../models/PaginationModel";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from "@tanstack/react-table";
import notifyToast from "../../components/Toast/Toast";
import {KTCard, KTCardBody, KTIcon} from "../../../_websoft/helpers";
import {ToastContainer} from "react-toastify";
import {Modal, ModalHeader} from "react-bootstrap";
import TableSearch from "../../components/TableSearch/TableSearch";
import ExportButton from "../../components/TableExportToExcel/ExportButton";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import TablePagination from "../../components/TablePagination/TablePagination";
import {ParamsModelProizvodnja, ProizvodnjaModel} from "../../models/ProizvodnjaModel";
import {urgentniNaloziZaTransportAll} from "../../api/proizvodnja";
import KomercijalistePanelaDropdown from "./components/KomercijalistePanelaDropdown/KomercijalistePanelaDropdown";
import KupciPanelaDropdown from "./components/KupciPanelaDropdown/KupciPanelaDropdown";

const UrgentanTransportLista:React.FC = () => {
    const [data, setData] = useState<ProizvodnjaModel[]>([])
    const [loading, setLoading] = useState(true)
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [params, setparams] = useState<ParamsModelProizvodnja>({per_page: 10, page: 1})

    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')

    const updatePage = () => {
        setLoading(true)
        urgentniNaloziZaTransportAll(params)
            .then((response) => {
                const data = response.data['data']['data']
                setData(data)
                const totalPage = Math.floor(response.data['data']['total'] / response.data['data']['per_page'] + 1)
                setpagination({
                    curPage: response.data['data']['current_page'],
                    totalPage: totalPage,
                    perPage: params.per_page,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        updatePage()
    }, [params])

    const columns = [
        {
            id: 'id',
            header: 'id',
            accessorKey: 'id',
        },
        {
            id: 'Artikal',
            header: 'Artikal',
            accessorKey: 'artikal',
        },
        {
            id: 'Broj naloga',
            header: 'Broj naloga',
            accessorKey: 'brojNaloga',
        },
        {
            id: 'Broj ponude',
            header: 'Broj ponude',
            accessorKey: 'brojPonude',
        },
        {
            id: 'Kupac',
            header: 'Kupac',
            accessorKey: 'kupac',
        },
        {
            id: 'Krajni Kupac',
            header: 'Krajni Kupac',
            accessorKey: 'krajnjiKupac',
        },
        {
            id: 'Kreirao',
            header: 'Kreirao',
            accessorKey: 'kreiraoKorisnik',
        },
        {
            id: 'Zavrsen nalog',
            header: 'Zavrsen nalog',
            accessorKey: 'statusProDatum',
        },
    ]
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
    })

    return (
        <KTCard>
            <ToastContainer />
            <KTCardBody className='py-4'>
                <div className='row'>
                    <div className='col-3 mb-6'>
                        <h3 className="fw-bolder mt-3 mb-3">Odaberi komercijalistu</h3>
                        <KomercijalistePanelaDropdown
                            onChange={(name, value) => setparams({...params, kreiraoKorisnik: value})}
                        />
                    </div>
                    <div className='col-3 mb-6'>
                        <h3 className="fw-bolder mt-3 mb-3">Odaberi kupca</h3>
                        <KupciPanelaDropdown
                            onChange={(name, value) => setparams({...params, idKupac: value})}
                        />
                    </div>
                </div>
                <div className='d-flex justify-content-between'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)} />
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <ExportButton table={table} filename={'aktivne-palete'} />
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent />
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}
export default UrgentanTransportLista;
