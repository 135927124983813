import React, {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import clsx from 'clsx'
import UposlenikDropdown from '../../components/UposlenikDropdown/UposlenikDropdown'
import ImageComponent from '../../components/ImageComponet/ImageComponent'
import {Sredstva} from '../../models/Sredstva'
import {fetchSredstvo, saveSredstvo, updateSredstvo} from '../../api/sredstva'
import KategorijaSredstvaDropdown from '../../components/KategorijaSredstvaDropdown/KategorijaSredstvaDropdown'
import PoslovniceDropdown from '../../components/PoslovniceDropdown/PoslovniceDropdown'
import OdjeliDropdown from '../../components/OdjeliDropdown/OdjeliDropdown'
import StatusSredstvaDropdown from '../../components/StatusDropdown/StatusSredstvaDropdown'

const SredstvoDetails: React.FC = () => {
  const {id} = useParams()
  const idFromURL = Number(id)

  const initialValues = {
    SredstvoInvBroj: undefined,
    SredstvoNaziv: undefined,
    SredstvoPuniNaziv: undefined,
    SredstvoKategorija: undefined,
    SredstvoProizvodjac: undefined,
    SredstvoMarka: undefined,
    SredstvoGodProizv: undefined,
    SredstvoTvorBr: undefined,
    SredstvoLokacija: undefined,
    SredstvoKarakteristika: undefined,
    SredstvoOdjel: undefined,
    SredstvoZaduzen: undefined,
    SredstvoServisiranje: undefined,
    SredstvoOdrzavanje: undefined,
    SredstvoAtest: undefined,
    SredstvoUpotrDozvola: undefined,
    SredstvoUputstvo: undefined,
    SredstvoSeVodi: undefined,
    SredstvoSlika: undefined,
    SredstvoSlika2: undefined,
    SredstvoStatus: undefined,
    SredstvoPrevAtest: undefined,
    SredstvoPrevPodmazivanje: undefined,
    SredstvoPrevRemont: undefined,
    SredstvoTehDok: undefined,
    SredstvoRegistrator: undefined,
    SredstvoDokZaduzio: undefined,
    SredstvoDokZadDatum: undefined,
    SredstvoNapomene: undefined,
    SredstvoPresetorijum: undefined,
  }
  const [loading, setLoading] = useState(true)
  const [initialData, setInitialData] = useState<Partial<Sredstva>>(initialValues)
  const [image, setImage] = useState()
  const [image2, setImage2] = useState()

  useEffect(() => {
    if (idFromURL) {
      fetchSredstvo(idFromURL).then((data) => {
        setInitialData(data)
        formik.resetForm({values: data}) // <-- reset formik state here
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [idFromURL])

  const validationSchema = Yup.object({
    SredstvoInvBroj: Yup.string().required('Required RolnaBr'),
    SredstvoNaziv: Yup.string().required('Required Tezina'),
    SredstvoStatus: Yup.string().required('Required Status'),
    SredstvoKategorija: Yup.string().required('Required Kategorija'),
    SredstvoSeVodi: Yup.string().required('Required Vodi se sredstvo'),
    SredstvoZaduzen: Yup.string().required('Required Zaduzio sredstvo'),
    SredstvoTehDok: Yup.string().required('Required Tahnička dokumentacija'),
    SredstvoPresetorijum: Yup.string().required('Required Koristi se u galanteriji'),
  })

  const getFormData = (values: Partial<Sredstva>) => {
    const formdata = new FormData()
    formdata.append('SredstvoInvBroj', values.SredstvoInvBroj ?? '')
    formdata.append('SredstvoNaziv', values.SredstvoNaziv ?? '')
    formdata.append('SredstvoPuniNaziv', values.SredstvoPuniNaziv ?? '')
    formdata.append('SredstvoKategorija', values.SredstvoKategorija ?? '')
    formdata.append('SredstvoProizvodjac', values.SredstvoProizvodjac ?? '')
    formdata.append('SredstvoMarka', values.SredstvoMarka ?? '')
    formdata.append('SredstvoGodProizv', values.SredstvoGodProizv ?? '')
    formdata.append('SredstvoTvorBr', values.SredstvoTvorBr ?? '')
    formdata.append('SredstvoKarakteristika', values.SredstvoKarakteristika ?? '')
    formdata.append('SredstvoOdjel', values.SredstvoOdjel ?? '')
    formdata.append('SredstvoZaduzen', values.SredstvoZaduzen ?? '')
    formdata.append('SredstvoServisiranje', values.SredstvoServisiranje ?? '')
    formdata.append('SredstvoOdrzavanje', values.SredstvoOdrzavanje ?? '')
    formdata.append('SredstvoAtest', values.SredstvoAtest ?? '')
    formdata.append('SredstvoUpotrDozvola', values.SredstvoUpotrDozvola ?? '')
    formdata.append('SredstvoUputstvo', values.SredstvoUputstvo ?? '')
    formdata.append('SredstvoSeVodi', values.SredstvoSeVodi ?? '')
    formdata.append('SredstvoSlika', values.SredstvoSlika ?? '')
    formdata.append('SredstvoSlika', values.SredstvoSlika2 ?? '')
    formdata.append('SredstvoStatus', values.SredstvoStatus ?? '')
    formdata.append('SredstvoPrevAtest', values.SredstvoPrevAtest ?? '')
    formdata.append('SredstvoPrevPodmazivanje', values.SredstvoPrevPodmazivanje ?? '')
    formdata.append('SredstvoPrevRemont', values.SredstvoPrevRemont ?? '')
    formdata.append('SredstvoTehDok', values.SredstvoTehDok ?? '')
    formdata.append('SredstvoRegistrator', values.SredstvoRegistrator ?? '')
    formdata.append('SredstvoDokZaduzio', values.SredstvoDokZaduzio ?? '')
    formdata.append('SredstvoDokZadDatum', values.SredstvoDokZadDatum ?? '')
    formdata.append('SredstvoNapomene', values.SredstvoNapomene ?? '')
    formdata.append('SredstvoPresetorijum', values.SredstvoPresetorijum ?? '')
    formdata.append('SredstvoLokacija', values.SredstvoLokacija ?? '')
    image && formdata.append('AddSlika', image ?? '')
    image2 && formdata.append('AddSlika2', image2 ?? '')
    return formdata
  }

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response
        const formData = getFormData(values)
        if (idFromURL) {
          // Update existing
          response = await updateSredstvo(formData, idFromURL)
        } else {
          // Save new
          response = await saveSredstvo(formData)
        }

        const responseData = response
        if (responseData.error === 0) {
          notifyToast(true, responseData.message)
        } else {
          notifyToast(false, responseData.message || 'An unexpected error occurred.')
        }
      } catch (error) {
        console.error('Error: ', error)
        notifyToast(false, 'Error')
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <ToastContainer />
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <TitleComponent name='Sredstvo' />
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        {loading ? (
          <LoadingComponent />
        ) : (
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row'>
                {/* Inv broj */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label required fw-bold fs-6'>Inventurni broj</label>
                  <input
                      type='text'
                      placeholder='Inv broj'
                      {...formik.getFieldProps('SredstvoInvBroj')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                                formik.touched.SredstvoInvBroj && formik.errors.SredstvoInvBroj,
                          },
                          {
                            'is-valid':
                                formik.touched.SredstvoInvBroj && !formik.errors.SredstvoInvBroj,
                          }
                      )}
                  />
                  {formik.touched.SredstvoInvBroj && formik.errors.SredstvoInvBroj && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoInvBroj}</div>
                      </div>
                  )}
                </div>
                {/* Naziv */}
                <div className='col-lg-8 mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Naziv sredstva
                  </label>
                  <input
                      type='text'
                      placeholder='Naziv'
                      {...formik.getFieldProps('SredstvoNaziv')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.SredstvoNaziv && formik.errors.SredstvoNaziv,
                          },
                          {
                            'is-valid': formik.touched.SredstvoNaziv && !formik.errors.SredstvoNaziv,
                          }
                      )}
                  />
                  {formik.touched.SredstvoNaziv && formik.errors.SredstvoNaziv && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoNaziv}</div>
                      </div>
                  )}
                </div>
                <div className='col-lg-8 mb-6'>
                  <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                    Puni Naziv Sredstva (puni) koristi se za određene dokumente....
                  </label>
                  <input
                      type='text'
                      placeholder='Puni naziv'
                      {...formik.getFieldProps('SredstvoPuniNaziv')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.SredstvoPuniNaziv && formik.errors.SredstvoPuniNaziv,
                          },
                          {
                            'is-valid': formik.touched.SredstvoPuniNaziv && !formik.errors.SredstvoPuniNaziv,
                          }
                      )}
                  />
                  {formik.touched.SredstvoPuniNaziv && formik.errors.SredstvoPuniNaziv && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoPuniNaziv}</div>
                      </div>
                  )}
                </div>
                {/* Kategorija */}
                <div className='col-lg-6 mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Kategorija
                  </label>
                  <KategorijaSredstvaDropdown
                      value={formik.values.SredstvoKategorija}
                      onChange={(name, value) => formik.setFieldValue('SredstvoKategorija', value)}
                      errors={formik.errors.SredstvoKategorija}
                      touched={formik.touched.SredstvoKategorija}
                      selectName='SredstvoKategorija'
                  />
                </div>
                {/* Proizvodjac */}
                <div className='col-lg-6 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>Proizvođač</label>
                  <input
                      type='text'
                      placeholder='Proizodjac'
                      {...formik.getFieldProps('SredstvoProizvodjac')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                                formik.touched.SredstvoProizvodjac && formik.errors.SredstvoProizvodjac,
                          },
                          {
                            'is-valid':
                                formik.touched.SredstvoProizvodjac && !formik.errors.SredstvoProizvodjac,
                          }
                      )}
                  />
                  {formik.touched.SredstvoProizvodjac && formik.errors.SredstvoProizvodjac && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoProizvodjac}</div>
                      </div>
                  )}
                </div>
                {/* SredstvoMarka */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>Marka / Tip</label>
                  <input
                      type='number'
                      placeholder='Marka'
                      {...formik.getFieldProps('SredstvoMarka')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.SredstvoMarka && formik.errors.SredstvoMarka,
                          },
                          {
                            'is-valid': formik.touched.SredstvoMarka && !formik.errors.SredstvoMarka,
                          }
                      )}
                  />
                  {formik.touched.SredstvoMarka && formik.errors.SredstvoMarka && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoMarka}</div>
                      </div>
                  )}
                </div>
                {/* SredstvoMarka */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>Godina proizvodnje</label>
                  <input
                      type='number'
                      placeholder='Godina proizvodnje'
                      {...formik.getFieldProps('SredstvoGodProizv')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                                formik.touched.SredstvoGodProizv && formik.errors.SredstvoGodProizv,
                          },
                          {
                            'is-valid':
                                formik.touched.SredstvoGodProizv && !formik.errors.SredstvoGodProizv,
                          }
                      )}
                  />
                  {formik.touched.SredstvoGodProizv && formik.errors.SredstvoGodProizv && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoGodProizv}</div>
                      </div>
                  )}
                </div>
                {/* SredstvoTvorBr */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>Tvornički broj</label>
                  <input
                      type='text'
                      placeholder='Tvornički broj'
                      {...formik.getFieldProps('SredstvoTvorBr')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.SredstvoTvorBr && formik.errors.SredstvoTvorBr,
                          },
                          {
                            'is-valid': formik.touched.SredstvoTvorBr && !formik.errors.SredstvoTvorBr,
                          }
                      )}
                  />
                  {formik.touched.SredstvoTvorBr && formik.errors.SredstvoTvorBr && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoTvorBr}</div>
                      </div>
                  )}
                </div>
                {/* SredstvoKarakteristika */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>Osobina / karakteristika</label>
                  <input
                      type='text'
                      placeholder='Osobina/karakteriskita'
                      {...formik.getFieldProps('SredstvoKarakteristika')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                                formik.touched.SredstvoKarakteristika &&
                                formik.errors.SredstvoKarakteristika,
                          },
                          {
                            'is-valid':
                                formik.touched.SredstvoKarakteristika &&
                                !formik.errors.SredstvoKarakteristika,
                          }
                      )}
                  />
                  {formik.touched.SredstvoKarakteristika && formik.errors.SredstvoKarakteristika && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoKarakteristika}</div>
                      </div>
                  )}
                </div>
                {/* Kategorija */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Poslovnica
                  </label>
                  <PoslovniceDropdown
                      value={formik.values.SredstvoLokacija}
                      onChange={(name, value) => formik.setFieldValue('SredstvoLokacija', value)}
                      errors={formik.errors.SredstvoLokacija}
                      touched={formik.touched.SredstvoLokacija}
                      selectName='SredstvoLokacija'
                  />
                </div>
                {/* Odjel */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label required fw-bold fs-6'>Odjel</label>
                  <OdjeliDropdown
                      value={formik.values.SredstvoOdjel}
                      onChange={(name, value) => formik.setFieldValue('SredstvoOdjel', value)}
                      errors={formik.errors.SredstvoOdjel}
                      touched={formik.touched.SredstvoOdjel}
                      selectName='SredstvoOdjel'
                  />
                </div>
                {/* SredstvoZaduzen */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label required fw-bold fs-6'>Zadužio sredstvo</label>
                  <UposlenikDropdown
                      value={formik.values.SredstvoZaduzen}
                      onChange={(name, value) => formik.setFieldValue('SredstvoZaduzen', value)}
                      errors={formik.errors.SredstvoZaduzen}
                      touched={formik.touched.SredstvoZaduzen}
                      selectName='SredstvoZaduzen'
                  />
                </div>
                {/* SredstvoServisiranje */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>Sredstvo servisira</label>
                  <UposlenikDropdown
                      value={formik.values.SredstvoServisiranje}
                      onChange={(name, value) => formik.setFieldValue('SredstvoServisiranje', value)}
                      errors={formik.errors.SredstvoServisiranje}
                      touched={formik.touched.SredstvoServisiranje}
                      selectName='SredstvoServisiranje'
                  />
                </div>
                {/* SredstvoOdrzavanje */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>Sredstvo održava</label>
                  <UposlenikDropdown
                      value={formik.values.SredstvoOdrzavanje}
                      onChange={(name, value) => formik.setFieldValue('SredstvoOdrzavanje', value)}
                      errors={formik.errors.SredstvoOdrzavanje}
                      touched={formik.touched.SredstvoOdrzavanje}
                      selectName='SredstvoOdrzavanje'
                  />
                </div>
                {/* Potreban atest */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className=''>Potreban atest</span>
                  </label>
                  <select className='form-control' {...formik.getFieldProps('SredstvoAtest')}>
                    <option value=''>Odaberi...</option>
                    <option value='1'>Da</option>
                    <option value='0'>Ne</option>
                  </select>
                  {formik.touched.SredstvoAtest && formik.errors.SredstvoAtest && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoAtest}</div>
                      </div>
                  )}
                </div>
                {/* Upotrebna dozvola */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className=''>Upotrebna dozvola</span>
                  </label>
                  <select
                      className='form-control'
                      {...formik.getFieldProps('SredstvoUpotrDozvola')}
                  >
                    <option value=''>Odaberi...</option>
                    <option value='1'>Da</option>
                    <option value='0'>Ne</option>
                  </select>
                  {formik.touched.SredstvoUpotrDozvola && formik.errors.SredstvoUpotrDozvola && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoUpotrDozvola}</div>
                      </div>
                  )}
                </div>
                {/* Uputstvo za rad */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className=''>Uputstvo za rad</span>
                  </label>
                  <select className='form-control' {...formik.getFieldProps('SredstvoUputstvo')}>
                    <option value=''>Odaberi...</option>
                    <option value='1'>Da</option>
                    <option value='0'>Ne</option>
                  </select>
                  {formik.touched.SredstvoUputstvo && formik.errors.SredstvoUputstvo && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoUputstvo}</div>
                      </div>
                  )}
                </div>
                {/* Sredstvo se vodi */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Sredstvo se vodi</span>
                  </label>
                  <select className='form-control' {...formik.getFieldProps('SredstvoSeVodi')}>
                    <option value=''>Odaberi...</option>
                    <option value='1'>Da</option>
                    <option value='0'>Ne</option>
                  </select>
                  {formik.touched.SredstvoSeVodi && formik.errors.SredstvoSeVodi && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoSeVodi}</div>
                      </div>
                  )}
                </div>
                {/* Teh dokumentacija */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Tehnička dokumentacija</span>
                  </label>
                  <select className='form-control' {...formik.getFieldProps('SredstvoTehDok')}>
                    <option value=''>Odaberi...</option>
                    <option value='1'>Da</option>
                    <option value='0'>Ne</option>
                  </select>
                  {formik.touched.SredstvoTehDok && formik.errors.SredstvoTehDok && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoTehDok}</div>
                      </div>
                  )}
                </div>
                {/* Registrator */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>Registrator</label>
                  <input
                      type='text'
                      placeholder='Registrator'
                      className='form-control'
                      {...formik.getFieldProps('SredstvoRegistrator')}
                  />
                </div>
                {/* SredstvoZaduzen */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>Dokumentaciju zadužio</label>
                  <UposlenikDropdown
                      value={formik.values.SredstvoDokZaduzio}
                      onChange={(name, value) => formik.setFieldValue('SredstvoDokZaduzio', value)}
                      errors={formik.errors.SredstvoDokZaduzio}
                      touched={formik.touched.SredstvoDokZaduzio}
                      selectName='SredstvoDokZaduzio'
                  />
                  {formik.touched.SredstvoDokZaduzio && formik.errors.SredstvoDokZaduzio && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoDokZaduzio}</div>
                      </div>
                  )}
                </div>
                {/* Registrator */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>Dokumentaciju zadužio datum</label>
                  <input
                      type='date'
                      placeholder='Datum zaduženja'
                      {...formik.getFieldProps('SredstvoDokZadDatum')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                                formik.touched.SredstvoDokZadDatum && formik.errors.SredstvoDokZadDatum,
                          },
                          {
                            'is-valid':
                                formik.touched.SredstvoDokZadDatum && !formik.errors.SredstvoDokZadDatum,
                          }
                      )}
                  />
                  {formik.touched.SredstvoDokZadDatum && formik.errors.SredstvoDokZadDatum && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoDokZadDatum}</div>
                      </div>
                  )}
                </div>
                {/* Galanterija */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Sredstvo se koristi u galanteriji</span>
                  </label>
                  <select
                      className='form-control'
                      {...formik.getFieldProps('SredstvoPresetorijum')}
                  >
                    <option value=''>Odaberi...</option>
                    <option value='1'>Da</option>
                    <option value='0'>Ne</option>
                  </select>
                  {formik.touched.SredstvoPresetorijum && formik.errors.SredstvoPresetorijum && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoPresetorijum}</div>
                      </div>
                  )}
                </div>
                {/* SredstvoStatus */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label required fw-bold fs-6'>Status sredstva</label>
                  <StatusSredstvaDropdown
                      value={formik.values.SredstvoStatus}
                      onChange={(name, value) => formik.setFieldValue('SredstvoStatus', value)}
                      errors={formik.errors.SredstvoStatus}
                      touched={formik.touched.SredstvoStatus}
                      selectName='SredstvoStatus'
                  />
                </div>
                {/* Podmazivanje */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>Podmazivanje</label>
                  <input
                      type='text'
                      placeholder='Podmazivanje'
                      {...formik.getFieldProps('SredstvoPrevPodmazivanje')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                                formik.touched.SredstvoPrevPodmazivanje &&
                                formik.errors.SredstvoPrevPodmazivanje,
                          },
                          {
                            'is-valid':
                                formik.touched.SredstvoPrevPodmazivanje &&
                                !formik.errors.SredstvoPrevPodmazivanje,
                          }
                      )}
                  />
                  {formik.touched.SredstvoPrevPodmazivanje &&
                      formik.errors.SredstvoPrevPodmazivanje && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              {formik.errors.SredstvoPrevPodmazivanje}
                            </div>
                          </div>
                      )}
                </div>
                {/* Remont */}
                <div className='col-lg-4 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>Remont</label>
                  <input
                      type='text'
                      placeholder='Remont'
                      {...formik.getFieldProps('SredstvoPrevRemont')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                                formik.touched.SredstvoPrevRemont && formik.errors.SredstvoPrevRemont,
                          },
                          {
                            'is-valid':
                                formik.touched.SredstvoPrevRemont && !formik.errors.SredstvoPrevRemont,
                          }
                      )}
                  />
                  {formik.touched.SredstvoPrevRemont && formik.errors.SredstvoPrevRemont && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoPrevRemont}</div>
                      </div>
                  )}
                </div>
                {/* Napomena */}
                <div className='col-lg-12 mb-6'>
                  <label className='col-form-label fw-bold fs-6'>Napomena</label>
                  <textarea
                      placeholder='Komentar'
                      {...formik.getFieldProps('SredstvoNapomene')}
                      className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                                formik.touched.SredstvoNapomene && formik.errors.SredstvoNapomene,
                          },
                          {
                            'is-valid':
                                formik.touched.SredstvoNapomene && !formik.errors.SredstvoNapomene,
                          }
                      )}
                  />
                  {formik.touched.SredstvoNapomene && formik.errors.SredstvoNapomene && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.SredstvoNapomene}</div>
                      </div>
                  )}
                </div>
                <div className='d-flex col-lg-6 col-sm-12'>
                  <ImageComponent
                      slikaUrl={formik.values.SredstvoSlika}
                      label={'Sredstvo Slika'}
                      setFile={(value) => {
                        formik.setFieldValue('SredstvoSlika', value)
                        setImage(value)
                      }}
                      isFileValid={(val) => formik.setErrors({SredstvoSlika: val})}
                  />
                </div>
                <div className='d-flex col-lg-6 col-sm-12'>
                  <ImageComponent
                      slikaUrl={formik.values.SredstvoSlika2}
                      label={'Sredstvo Slika 2'}
                      setFile={(value) => {
                        formik.setFieldValue('SredstvoSlika2', value)
                        setImage2(value)
                      }}
                      isFileValid={(val) => formik.setErrors({SredstvoSlika2: val})}
                  />
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={loading || formik.isSubmitting || !formik.isValid}
                >
                  {!loading && 'Potvrdi'}
                  {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}
export default SredstvoDetails
