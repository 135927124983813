import axios from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {ParamsModelSredstva, Sredstva} from '../models/Sredstva'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/sredstvo`
const DROPDOWN = `${API_URL}/sredstvaDropdown`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: `Bearer ${auth?.access_token ?? ''}`}

export async function getSredstvaDropdown() {
  try {
    return await axios.get<Array<Option>>(DROPDOWN, {headers: headers})
  } catch (error) {
    console.error('Error fetching DATA Dropdown  data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
export async function getAllSredstva(params: ParamsModelSredstva) {
  return await axios.get<Array<Sredstva>>(API, {headers: headers, params: params})
}

export async function saveSredstvo(Data: any) {
  try {
    const response = await axios.post(API, Data, {headers: headers})
    return response.data
  } catch (error) {
    console.error('Error Saving Sredstva data:', error)
    return error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function fetchSredstvo(Id: number): Promise<Partial<Sredstva>> {
  try {
    const response = await axios.get(`${API}/${Id}`)

    return response.data
  } catch (error) {
    console.error('Error fetching Sredstva data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateSredstvo(Data: any, id: number) {
  try {
    const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
    return response.data
  } catch (error) {
    console.error('Error Updating Sredstva data:', error)
    return error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function deleteSredstvo(Id: number) {
  try {
    const response = await axios.delete(`${API}/${Id}`, {headers: headers})
    return response.data
  } catch (error) {
    console.error('Error deleting Sredstva data:', error)
    return error
  }
}
