import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'

import {getAuth} from '../modules/auth'
import {
  ParamsModelZapisiProizvodnje,
  ZapisiProizvodnjeModel,
  ZapisiStavkeModel,
} from '../models/ZapisiProizvodnjeModel'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/paneli/zapisiProizvodnje`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getZapisiProizvodnje(params: ParamsModelZapisiProizvodnje) {
  return await axios.get<Array<ZapisiProizvodnjeModel>>(API, {headers: headers, params: params})
}

export async function getAktivniZapisiProizvodnje(params: ParamsModelZapisiProizvodnje) {
  return await axios.get<Array<ZapisiProizvodnjeModel>>(`${API_URL}/paneli/aktivniNaloziPanela`, {
    headers: headers,
    params: params,
  })
}
export async function getZapisiStavke(params: any) {
  return await axios.get<Array<ZapisiStavkeModel>>(`${API_URL}/paneli/zapisiProizvodnjeStavke`, {
    headers: headers,
    params: params,
  })
}

export async function saveZapisiProizvodnje(Data: any) {
  try {
    const response = await axios.post(API, Data, {headers: headers})

    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function fetchZapisiProizvodnje(Id: number): Promise<Partial<ZapisiProizvodnjeModel>> {
  try {
    const response = await axios.get(`${API}/${Id}`)
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateZapisiProizvodnje(id: number, Data: any) {
  try {
    const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function deleteZapisiProizvodnje(Id: number) {
  try {
    const response = await axios.delete(`${API}/${Id}`, {headers: headers})
    if (response.data) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to delete.')
    }
  } catch (error) {
    console.error('Error deleting data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function getZapisiNalogInfo(params: any) {
  return await axios.get(`${API_URL}/paneli/zapisNalogInfo`, {
    headers: headers,
    params: params,
  })
}

export async function saveZapisiStavke(Data: any) {
  try {
    const response = await axios.post(`${API_URL}/paneli/zapisiProizvodnjeStavke`, Data, {
      headers: headers,
    })

    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function snimiSkartAktivnogNaloga(Data: any) {
  try {
    const response = await axios.post(`${API_URL}/paneli/snimiSkartAktivnogNaloga`, Data, {
      headers: headers,
    })

    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
