import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {InventureTrakeModel} from "../../../models/inventure";
import {RolnaDetaljiProizvodnja} from "../../../models/FmkNaloziModel";
import {
    aktivnaInventura, aktivnaInventuraTrake,
    inventuraRolneGreskaLokacija, inventuraRolneGreskaTezinaLokacija, inventuraRolneGreskaTraka,
    inventuraSveInformacijeTacne, sveInformacijeTacneTraka,
    zadnjaUpisanaRolna, zadnjaUpisanaTraka
} from "../../../api/inventure";
import * as Yup from "yup";
import notifyToast from "../../../components/Toast/Toast";
import {useFormik} from "formik";
import {direktanIzlazRolne} from "../../../api/prometRepromaterijala";
import useDebounce from "../../../hooks/useDebounce";
import {rolnaDetaljiProizvodnjaV3} from "../../../api/fmkNalozi";
import {ToastContainer} from "react-toastify";
import TitleComponent from "../../../components/TitleComponent/TitleComponent";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import LokacijeRepromaterijalaDropdown
    from "../../../components/LokacijeRepromaterijalaDropdown/LokacijeRepromaterijalaDropdown";
import clsx from "clsx";
import QrCodeScanner from "../../../components/QrCodeScanner/QrCodeScanner";
import {Table} from "../../../models/Table";
import {fetchTable} from "../../../api/tableRS";

const InventuraTrake:React.FC = () => {
    const {id} = useParams()

    const initialValues = {
        InventuraId: undefined,
        TrakaId: undefined,
        SkeniranaTezina: undefined,
    }

    const [loading, setLoading] = useState(false)
    const [novaTezina, setNovaTezina] = useState(false)
    const [idInventure, setIdInventure] = useState('')
    const [zadnjaTraka, setZadnjaTraka] = useState('')
    const [initialData, setInitialData] = useState<Partial<InventureTrakeModel>>(initialValues)
    const [trakaDetaljiInfo, setTrakaDetalji] = useState<Partial<Table> | undefined>();
    const [refreshCounter, setRefreshCounter] = useState(0);

    useEffect(() => {
        aktivnaInventuraTrake()
            .then((data) => {
                console.log(data);
                if (typeof data.Id !== 'undefined') {
                    const idString = data.Id.toString();
                    setIdInventure(idString); // Set state
                    // Call the next function in the chain after converting Id
                    return zadnjaUpisanaTraka(data.Id); // Directly use data.Id since state update is asynchronous
                } else {
                    setIdInventure('defaultId'); // Set a default or handle error
                    return Promise.reject('Invalid Inventura ID'); // Skip the next API call
                }
            })
            .then((rolnaData) => {
                // This code executes after zadnjaUpisanaRolna successfully returns
                if (rolnaData && rolnaData.TrakaId) {
                    setZadnjaTraka(rolnaData.TrakaId.toString());
                }
            })
            .catch((error) => {
                console.error('An error occurred:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [refreshCounter]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const validationSchema = Yup.object({
        TrakaId: Yup.string().min(1, 'Must be at least 1 characters').required('Required'),
        InventuraId: Yup.number().required('Required'),
    })

    const handleGreskaTezina = () => {
        setNovaTezina(prevNovaTezina => !prevNovaTezina);
    }

    const handleSveOk = () => {
        const data = {
            IdInventure: idInventure,
            TrakaId: formik.values.TrakaId
        };

        sveInformacijeTacneTraka(data)
            .then(response => {
                formik.setFieldValue('TrakaId', '');
                setTrakaDetalji(undefined)
                setRefreshCounter(prevCounter => prevCounter + 1);
                if (inputRef.current) inputRef.current.focus();
                notifyToast(true, response.message)
            })
            .catch(error => {
                console.error("Error:", error);
                notifyToast(false, error.message)
            });
    }
    const greskaTezinaLokacija  = () => {
        const data = {
            IdInventure: idInventure,
            TrakaId: formik.values.TrakaId,
            SkeniranaTezina: formik.values.SkeniranaTezina,
        };

        inventuraRolneGreskaTraka(data)
            .then(response => {
                formik.setFieldValue('TrakaId', '');
                setTrakaDetalji(undefined)
                setRefreshCounter(prevCounter => prevCounter + 1);
                if (inputRef.current) inputRef.current.focus();
                notifyToast(true, response.message)
            })
            .catch(error => {
                console.error("Error:", error);
                notifyToast(false, error.message)
            });
    }

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
            setLoading(true);
            try {
                const response = await direktanIzlazRolne(values);
                // Assuming response is defined correctly within try block
                const responseData = response;

                if (responseData.error === 0) {
                    notifyToast(true, responseData.message);
                    resetForm();
                } else {
                    notifyToast(false, responseData.message || 'An unexpected error occurred.');
                }
            } catch (error: any) {
                console.error('Error: ', error);
                notifyToast(false, error.response?.data.message || 'An error occurred');
                setStatus(error);
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });
    const debouncedTraka = useDebounce(formik.values.TrakaId)
    useEffect(() => {
        if (debouncedTraka) {
            fetchTable(debouncedTraka)
                .then((response) => {
                    if (response) {
                        setTrakaDetalji(response)
                    } else {
                        setTrakaDetalji(response)
                    }
                })
                .catch((error) => notifyToast(false, error.response.data.message))
        }
    }, [debouncedTraka])

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer/>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name='Inventura rolni'/>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body border-top p-9'>
                            {/* ROLNA */}
                            <h3>ID INVENTURE: <span className='text-danger'>{idInventure}</span></h3><br/>
                            <h3>ZADNJA UPISANA TRAKA: <span className='text-danger'>{zadnjaTraka}</span></h3>
                            {idInventure ? (
                                <div className='col-lg-12'>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <QrCodeScanner
                                                onScan={(data) => {
                                                    const text = data.split(';')[0]
                                                    formik.setFieldValue('TrakaId', text)
                                                }}
                                            />
                                        </div>
                                        <div className='col-10'>
                                            <input
                                                type='text'
                                                placeholder='Traka'
                                                {...formik.getFieldProps('TrakaId')}
                                                ref={inputRef}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {
                                                        'is-invalid':
                                                            formik.touched.TrakaId && formik.errors.TrakaId,
                                                    },
                                                    {
                                                        'is-valid':
                                                            formik.touched.TrakaId && !formik.errors.TrakaId,
                                                    }
                                                )}
                                            />
                                            {formik.touched.TrakaId && formik.errors.TrakaId && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.TrakaId}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className='col-5'>
                                            {trakaDetaljiInfo ? (
                                                <div className='col-12 border rounded border-success p-2'>
                                                    <div>
                                                        <h2 className='mt-2'>Težina: {trakaDetaljiInfo.Tezina || 'N/A'} </h2>
                                                        <h2 className='mt-2'>Broj rolne: {trakaDetaljiInfo.BrojRolne || 'N/A'}</h2>
                                                        <h2 className='mt-2'>Dužina: {trakaDetaljiInfo.TrenutnaDuzina || 'N/A'} </h2>
                                                    </div>

                                                </div>
                                            ) : (
                                                <div>Loading or no data...</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="alert alert-danger">
                                    Nema aktivna inventura
                                </div>
                            )
                            }
                            <hr/>
                            <div className='row mb-5'>
                                {!novaTezina && (
                                    <button
                                        type='button'
                                        className='btn btn-success col-3'
                                        onClick={handleSveOk}
                                    >
                                        {!loading && 'Sve Ok'}
                                        {loading && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                                <span
                                                    className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                        )}
                                    </button>
                                )}
                                <button
                                    type='button'
                                    className='btn btn-danger col-3 offset-1'
                                    onClick={handleGreskaTezina}
                                >
                                    GREŠKA TEŽINA
                                </button>
                            </div>
                            {novaTezina && (
                                <div className='row mt-5'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Težina rolne</label>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='decimal'
                                            placeholder='Tezina'
                                            {...formik.getFieldProps('SkeniranaTezina')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.SkeniranaTezina && formik.errors.SkeniranaTezina,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.SkeniranaTezina && !formik.errors.SkeniranaTezina,
                                                }
                                            )}
                                        />
                                        {formik.touched.SkeniranaTezina && formik.errors.SkeniranaTezina && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.SkeniranaTezina}</div>
                                            </div>
                                        )}
                                    </div>
                                    <button
                                        type='button'
                                        className='btn btn-primary col-4 mt-4'
                                        onClick={greskaTezinaLokacija}
                                    >
                                        {!loading && 'Potvrdi'}
                                        {loading && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                            Please wait...{' '}
                                                <span
                                                    className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}
export default InventuraTrake;
