import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {ArhivaModel, ParamsModelArhiva} from '../models/ArhivaModel'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/paneli/arhivaNaloga`
const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getArhiva(params: ParamsModelArhiva) {
  return await axios.get<Array<ArhivaModel>>(API, {headers: headers, params: params})
}
