/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {KTCard, KTCardBody} from '../../../_websoft/helpers'
import TablePagination from '../../components/TablePagination/TablePagination'
import {
  ParamsModelStavkeNalogaUProizvodnji,
  stavkeNalogaUProizvodnjiModel,
} from '../../models/FmkNaloziModel'
import {PaginationModel} from '../../models/PaginationModel'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import {stavkeNalogaUProizvodnji} from '../../api/fmkNalozi'
import notifyToast from '../../components/Toast/Toast'
import useDebounce from '../../hooks/useDebounce'

const StavkeUProizvodnji = () => {
  const [data, setData] = useState<stavkeNalogaUProizvodnjiModel[]>([])
  const [loading, setLoading] = useState(true)

  const [pagination, setpagination] = useState<PaginationModel>({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  })
  const [params, setparams] = useState<ParamsModelStavkeNalogaUProizvodnji>({per_page: 10, page: 1})
  useEffect(() => {
    updatePage()
  }, [params])

  const updatePage = () => {
    setLoading(true)
    stavkeNalogaUProizvodnji(params)
      .then((response) => {
        const data = response.data
        setData(data)
        const totalPage = Math.floor(response['total'] / response['per_page'] + 1)
        console.log('toa', totalPage)
        setpagination({
          curPage: response['current_page'],
          totalPage: totalPage,
          perPage: params.per_page,
        })
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const columns = [
    {
      id: 'Id',
      header: 'Id',
      accessorKey: 'id',
    },
    {
      id: 'Šifra',
      header: 'Šifra',
      accessorKey: 'idArtikla',
    },

    {
      id: 'Isporuka',
      header: 'Isporuka',
      accessorKey: 'rokIsporuke',
    },
    {
      id: 'Naziv',
      header: 'Naziv',
      accessorKey: 'Naziv',
    },
    {
      id: 'JM',
      header: 'JM',
      accessorKey: 'idJedMjere',
    },
    {
      id: 'Količina',
      header: 'Količina',
      accessorKey: 'kolicina',
    },
  ]

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const [filter, setfilter] = useState<string>()
  const debouncedFilter = useDebounce(filter, 1000)
  useEffect(() => {
    if (debouncedFilter) {
      setparams({...params, idArtikla: debouncedFilter})
    } else {
      setparams({per_page: params.per_page, page: params.page})
    }
  }, [debouncedFilter])

  return (
    <KTCard>
      <KTCardBody>
        {/* Filter */}
        <div className='col mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>Filter</label>
          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              placeholder='filter'
              className='form-control form-control-lg form-control-solid'
              onChange={(e) => setfilter(e.target.value)}
            />
          </div>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3 '>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className='text-white'
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* @ts-ignore */}
                        {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <TablePagination
          updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
          pagination={pagination}
        />
      </KTCardBody>
    </KTCard>
  )
}

export default StavkeUProizvodnji
