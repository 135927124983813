import {useFormik} from 'formik'
import React, {FC, useState} from 'react'
import {saveZapisiStavke} from '../../../../api/zapisiProizvodnje'
import notifyToast from '../../../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import {ZapisiProizvodnjeModel} from '../../../../models/ZapisiProizvodnjeModel'

interface Props {
  initialData: ZapisiProizvodnjeModel
}
const ZagaInfo: FC<Props> = ({initialData}) => {
  const initialValues = {
    'ZAGA-PROIZVODJAC': undefined,
    'ZAGA-VRSTA': undefined,
    'BROJ-REZOVA': undefined,
    'POJAVA PUCNE NA BROJU REZOVA': undefined,
    'ZAGA-PUKLA': undefined,
  }

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response
        let data: any = []
        // Format data as per required format of api.
        Object.entries(values).map(([key, value]) => {
          if (value) {
            const dataSet = {
              IdZapisa: initialData.Id,
              BrojNaloga: initialData.BrojNaloga,
              MetaKey: key,
              MetaValue: value,
            }
            data.push(dataSet)
          }
        })
        response = await saveZapisiStavke(data)

        const responseData = response

        if (responseData.error === 0) {
          notifyToast(true, responseData.message)
        } else {
          notifyToast(false, responseData.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error.response.data.message)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })
  return (
    <div>
      <ToastContainer />
      {loading ? (
        <LoadingComponent />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className='card zagaInfo pb-4'>
            <div className='p-5'>
              <h5 className='mb-2'>Žaga info</h5>
              <span className='badge bg-warning bg-opacity-1 text-white'>Dodaj</span>
              <hr />
            </div>
            <div className='card-body'>
              <div className='row mt-3'>
                <div className='col-lg-6'>
                  <label className='col-form-label fw-bold fs-6'>Proizvođač</label>
                  <select className='form-control' {...formik.getFieldProps('ZAGA-PROIZVODJAC')}>
                    <option value=''>ODABERI</option>
                    <option value='FIZETA'>FIZETA</option>
                    <option value='WIKUS'>WIKUS</option>
                    <option value='TEST'>TEST</option>
                  </select>
                </div>
                <div className='col-lg-6'>
                  <label className='col-form-label fw-bold fs-6'>ŽAGA VRSTA</label>
                  <select className='form-control' {...formik.getFieldProps('ZAGA-VRSTA')}>
                    <option value=''>ODABERI</option>
                    <option value='7220X27-10/14'>7220X27-10/14</option>
                    <option value='7220X27-12/14'>7220X27-12/14</option>
                    <option value='7220X20-10/14'>7220X20-10/14</option>
                  </select>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-lg-4'>
                  <label className='col-form-label fw-bold fs-6'>BROJ REZOVA</label>
                  <input
                    type='text'
                    className='form-control text-danger'
                    id='BROJ-REZOVA'
                    placeholder='Upišite broj rezova'
                    {...formik.getFieldProps('BROJ-REZOVA')}
                  />
                </div>
                <div className='col-lg-4'>
                  <label className='col-form-label fw-bold fs-6'>
                    POJAVA PUCNE NA BROJU REZOVA
                  </label>
                  <input
                    type='text'
                    className='form-control text-danger'
                    placeholder='Pojava pucne na broju rezova'
                    {...formik.getFieldProps('POJAVA PUCNE NA BROJU REZOVA')}
                  />
                </div>
                <div className='col-lg-4'>
                  <label className='col-form-label fw-bold fs-6'>ŽAGA PUKLA</label>
                  <select className='form-control' {...formik.getFieldProps('ZAGA-PUKLA')}>
                    <option value=''>ODABERI</option>
                    <option value='DA'>DA</option>
                    <option value='NE'>NE</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-lg-3 offset-9'>
                <button
                  type='submit'
                  className='btn btn-block btn-primary submitBtn'
                  name='dodajZagu'
                  id='submitBtn'
                >
                  Potvrdi <i className='ph-paper-plane-tilt ms-2'></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

export default ZagaInfo
