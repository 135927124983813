/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import SearchableSelect from '../SearchableSelect/SearchableSelect'
import {getDobavljacpartnerDropdown} from '../../api'
import {Option} from '../../models/Sektor'

// dobavljacPartnerDropdown Props for the component
interface DropdownProps {
  value?: string | number
  initialValue?: string | number
  onChange?: (name: string, value: any) => void
  errors?: string | undefined
  touched?: boolean
  selectName?: string
}

const DobavljacPartnerDropdown: React.FC<DropdownProps> = ({
  value,
  initialValue,
  onChange,
  errors,
  touched,
  selectName,
}) => {
  const [dobavljacPartnerDropdown, setDobavljacPartnerDropdown] = useState<Array<Option>>([])
  const internalValue = initialValue ?? null

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDobavljacpartnerDropdown()
        setDobavljacPartnerDropdown(mapData(response.data))
      } catch (error) {
        console.error('Error fetching Kvalitet Materijala dropdown data:', error)
      }
    }
    fetchData()
  }, [])

  const finalValue = value ?? internalValue

  const mapData = (data) => {
    if (!data) {
      return []
    }
    return data.map((item) => ({label: item.Naziv, value: item.Id.toString()}))
  }

  return (
    <SearchableSelect
      options={dobavljacPartnerDropdown}
      value={finalValue?.toString() ?? ''}
      onChange={onChange!}
      name={selectName ?? 'dobavljacPartnerDropdown'}
      errors={errors}
      placeholder='Odaberi'
      touched={touched}
    />
  )
}

export default DobavljacPartnerDropdown
