/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import 'react-toastify/dist/ReactToastify.css'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import {useParams} from 'react-router-dom'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import {useNavigate} from "react-router-dom";
import {TroskoviSredstava} from "../../models/TroskoviSredstava";
import {fetchTrosak, saveTrosak, updateTrosak} from "../../api/troskoviSredstava";
import SredstvaDropdown from "../../components/SredstvaDropdown/SredstvaDropdown";

interface Props {
    // other potential props go here
}

const TroskoviSredstavaDetails:React.FC<Props> = () => {
    const {id} = useParams()
    const IdFromURL = Number(id)

    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()
    const initialValues = {
        Id: '',
        IdSredstva: '',
        Trosak: '',
        Opis: '',
        IntervencijaId: '',
        Datum: '',
        Napomena: '',
    }

    const [initialData, setInitialData] = useState<Partial<TroskoviSredstava>>(initialValues)

    useEffect(() => {
        if (IdFromURL) {
            fetchTrosak(IdFromURL)
                .then((data) => {
                    setInitialData(data)
                    formik.resetForm({values: data}) // <-- reset formik state here
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [IdFromURL])

    const validationSchema = Yup.object({
        Trosak: Yup.number().required('Required'),
        Opis: Yup.string().required('Required'),
    })

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)

            try {
                let response
                if (IdFromURL) {
                    response = await updateTrosak(values, IdFromURL)
                    setTimeout(() => {
                        navigate('/sredstva/troskovi')
                    }, 3000)
                } else {
                    response = await saveTrosak(values)
                    setTimeout(() => {
                        navigate('/sredstva/troskovi')
                    }, 3000)
                }

                if (response.error === 0) {
                    notifyToast(true, response.message)
                } else {
                    notifyToast(false, response.message || 'An unexpected error occurred.')
                }
            } catch (error: any) {
                console.error('Error: ', error)
                notifyToast(false, error.response.data.message)
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        },
    })

    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer />
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name='' />
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                {loading ? (
                    <LoadingComponent />
                ) : (
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body border-top p-9'>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                    <span className='required'>Sredstvo</span>
                                </label>
                                <div className='col-lg-8 fv-row'>
                                    <SredstvaDropdown
                                        value={formik.values.IdSredstva}
                                        onChange={(name, value) => formik.setFieldValue('IdSredstva', value)}
                                        errors={formik.errors.IdSredstva}
                                        touched={formik.touched.IdSredstva}
                                        selectName='IdSredstva'
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Datum</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='date'
                                        placeholder='Datum'
                                        {...formik.getFieldProps('Datum')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.Datum && formik.errors.Datum},
                                            {
                                                'is-valid': formik.touched.Datum && !formik.errors.Datum,
                                            }
                                        )}
                                    />
                                    {formik.touched.Datum && formik.errors.Datum && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Datum}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Intervencija Id</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='number'
                                        placeholder='Intervencija Id'
                                        {...formik.getFieldProps('IntervencijaId')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.IntervencijaId && formik.errors.IntervencijaId},
                                            {
                                                'is-valid': formik.touched.IntervencijaId && !formik.errors.Datum,
                                            }
                                        )}
                                    />
                                    {formik.touched.IntervencijaId && formik.errors.IntervencijaId && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.IntervencijaId}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Opis troška</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Opis'
                                        {...formik.getFieldProps('Opis')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.Opis && formik.errors.Opis},
                                            {
                                                'is-valid': formik.touched.Opis && !formik.errors.Opis,
                                            }
                                        )}
                                    />
                                    {formik.touched.Opis && formik.errors.Opis && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Opis}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Iznos troška</label>
                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='number'
                                        placeholder='Iznos Trosak'
                                        {...formik.getFieldProps('Trosak')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.Trosak && formik.errors.Trosak},
                                            {
                                                'is-valid': formik.touched.Trosak && !formik.errors.Trosak,
                                            }
                                        )}
                                    />
                                    {formik.touched.Trosak && formik.errors.Trosak && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Trosak}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-12 mb-6'>
                                <label className='col-form-label required fw-bold fs-6'>Napomena</label>
                                <textarea
                                    placeholder='Napomena'
                                    {...formik.getFieldProps('Napomena')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid':
                                                formik.touched.Napomena && formik.errors.Napomena,
                                        },
                                        {
                                            'is-valid':
                                                formik.touched.Napomena && !formik.errors.Napomena,
                                        }
                                    )}
                                />
                                {formik.touched.Napomena && formik.errors.Napomena && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.Napomena}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={loading || formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && 'Potvrdi'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}
export default TroskoviSredstavaDetails;
