import React from 'react'
import PlanProizvodnje from '../paneli/PlanProizvodnje'

const PartneriDashboard = () => {
  return (
    <div>
      <PlanProizvodnje />
    </div>
  )
}

export default PartneriDashboard
