import React from 'react'
import {useLocation} from 'react-router-dom'
import {UtovarKamionaModel} from '../../../../models/ProizvodnjaModel'
import {KTCard, KTCardBody} from '../../../../../_websoft/helpers'

import {ToastContainer} from 'react-toastify'
import UtovarKamionaTable from './UtovarKamionaTable'
import logo from '../../../../../_websoft/assets/images/logo.jpg'

const UtovarKamiona = () => {
  const location = useLocation()
  const data: UtovarKamionaModel = location.state.data
  const kupac: string = location.state.kupac
  const bin_data = data.bins_packed[0].bin_data

  return (
    <KTCard>
      <ToastContainer />

      <KTCardBody className='py-4'>
        <div className='col p-5 mb-6'>
          <img src={logo} alt='' width={300} className='py-5' />
          <div className='col mb-6 h1 py-5'>Kupac: {kupac}</div>

          <div className='col mb-6 h1'>
            Dimenzije prikolice: {bin_data.w} X {bin_data.h} X {bin_data.d}
          </div>
          {data.bins_packed.map((item, index) => (
            <>
              <UtovarKamionaTable data={item} kamion={index + 1} />
              <div className='separator'></div>
            </>
          ))}
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export default UtovarKamiona
