import axios, {AxiosResponse} from 'axios'
import {UposlenikModel, Option} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
const UPOSLENIK = `${API_URL}/uposlenik`
const SELECT = `${API_URL}/poslovnicaDropdown`
const auth: UposlenikModel = getAuth()!

const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getPoslovniceDropdown(): Promise<AxiosResponse<Array<Option>>> {
  return await axios.get<Array<Option>>(SELECT, {headers: headers})
}
