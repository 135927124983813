import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
const API = `${API_URL}/radniNalozi`

const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function saveRadniNalozi(Data: any) {
  try {
    const response = await axios.post(API, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message)
    }
  } catch (error: any) {
    console.error('Error fetching data:', error)
    throw new Error(error) // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function startRadnogNaloga(Data: any) {
  try {
    const response = await axios.post(`${API}/startRadnogNaloga`, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message)
    }
  } catch (error: any) {
    console.error('Error fetching data:', error)
    throw new Error(error) // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function krajRadnogNaloga(Data: any) {
  try {
    const response = await axios.put(`${API}/krajRadnogNaloga`, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message)
    }
  } catch (error: any) {
    console.error('Error fetching data:', error)
    throw new Error(error) // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function imaLiAktivanZapis() {
  try {
    const response = await axios.get(`${API}/imaLiAktivanZapis`, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message)
    }
  } catch (error: any) {
    console.error('Error fetching data:', error)
    throw new Error(error) // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function mojeStavkeNaNalogu(params: any) {
  try {
    const response = await axios.get(`${API}/mojeStavkeNaNalogu`, {headers: headers, params})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message)
    }
  } catch (error: any) {
    console.error('Error fetching data:', error)
    throw new Error(error) // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function pauzirajRadniNalog(params: any) {
  try {
    const response = await axios.post(`${API}/pauzirajRadniNalog`, params, { headers: headers });

    // Check the response structure to handle success/error accordingly
    if (response.data.error === 0) {
      return response.data;
    } else {
      // Handle any other type of response structure or errors returned from the server here
      return response.data.message;
    }
  } catch (error: any) {
    console.error('Error fetching data:', error);
    throw new Error(error.message || 'Unknown error'); // Ensure error has a message property
  }
}


export async function ponovoStartajNalog(params: any) {
  try {
    const response = await axios.post(`${API}/ponovoStartajNalog`, params, { headers: headers });

    // Check the response structure to handle success/error accordingly
    if (response.data.error === 0) {
      return response.data;
    } else {
      // Handle any other type of response structure or errors returned from the server here
      return response.data.message;
    }
  } catch (error: any) {
    console.error('Error fetching data:', error);
    throw new Error(error.message || 'Unknown error'); // Ensure error has a message property
  }
}

export async function jelNalogNaPauzi(params: any) {
  try {
    const response = await axios.get(`${API}/jelNalogPauziran`, {headers: headers, params})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message)
    }
  } catch (error: any) {
    console.error('Error fetching data:', error)
    throw new Error(error) // Re-throw the error so it can be handled by the caller if necessary
  }
}