import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ArtikliOperacijeModel} from "../../models/ArtikliOperacije";
import {fetchArtikliOperacije, saveArtikliOperacije, updateArtikliOperacije} from "../../api/artikliOperacije";
import * as Yup from "yup";
import {useFormik} from "formik";
import notifyToast from "../../components/Toast/Toast";
import {ToastContainer} from "react-toastify";
import TitleComponent from "../../components/TitleComponent/TitleComponent";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import ArtikliGalanterija from "../../components/ArtikliGalanterija/ArtikliGalanterija";
import clsx from "clsx";
import SredstvaDropdown from "../../components/SredstvaDropdown/SredstvaDropdown";
import {fetchInventura, saveInventura, updateInventura} from "../../api/inventure";
import {InventureModel} from "../../models/inventure";

const RepromaterijaliTrakeDetails: React.FC = () => {
    const {id} = useParams()
    const IdFromURL = Number(id)

    const [loading, setLoading] = useState(true)

    const initialValues = {
        Pocetak: undefined,
        Kraj: undefined,
        Komentar: undefined,
        Status: undefined,
        Vrsta: undefined,
    }
    const [initialData, setInitialData] = useState<Partial<InventureModel>>(initialValues)

    useEffect(() => {
        if (IdFromURL) {
            fetchInventura(IdFromURL)
                .then((data) => {
                    setInitialData(data)
                    formik.resetForm({values: data}) // <-- reset formik state here
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [IdFromURL])

    const validationSchema = Yup.object({
        Pocetak: Yup.string().required('Required'),
        Vrsta: Yup.string().required('Required'),
    })

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                let response
                if (IdFromURL) {
                    response = await updateInventura(IdFromURL, values)
                } else {
                    response = await saveInventura(values)
                }

                if (response.error === 0) {
                    notifyToast(true, response.message)
                } else {
                    notifyToast(false, response.message || 'An unexpected error occurred.')
                }
            } catch (error: any) {
                console.error('Error: ', error)
                notifyToast(false, error.response.data.message)
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        },
    })
    return (
        <div className='row'>
            <ToastContainer/>
            <div className='card mb-5 mb-xl-10 col-6'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <TitleComponent name=''/>
                    </div>
                </div>
                <div id='kt_account_profile_details' className='collapse show'>
                    {loading ? (
                        <LoadingComponent/>
                    ) : (
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className='card-body border-top p-9 row'>
                                {/* Artikal */}
                                <div className='col-12 mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Početak</label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='date'
                                            placeholder='Početak'
                                            {...formik.getFieldProps('Pocetak')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.Pocetak && formik.errors.Pocetak,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.Pocetak && !formik.errors.Pocetak,
                                                }
                                            )}
                                        />
                                        {formik.touched.Pocetak && formik.errors.Pocetak && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.Pocetak}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-12 mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Kraj</label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='date'
                                            placeholder='Kraj'
                                            {...formik.getFieldProps('Kraj')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.Kraj && formik.errors.Kraj,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.Kraj && !formik.errors.Kraj,
                                                }
                                            )}
                                        />
                                        {formik.touched.Kraj && formik.errors.Kraj && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.Kraj}</div>
                                            </div>
                                        )}
                                    </div>
                                    {!IdFromURL && (
                                    <div className='col-12 mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Kategorija</label>

                                        <div className='col-lg-8 fv-row'>
                                            <select
                                                {...formik.getFieldProps('Vrsta')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {'is-invalid': formik.touched.Vrsta && formik.errors.Vrsta},
                                                    {
                                                        'is-valid': formik.touched.Vrsta && !formik.errors.Vrsta,
                                                    }
                                                )}
                                            >
                                                <option value=''>Odaberi...</option>
                                                <option value='Rolne'>Rolne</option>
                                                <option value='Trake'>Trake</option>
                                            </select>
                                            {formik.touched.Vrsta && formik.errors.Vrsta && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.Vrsta}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                        )}
                                    {IdFromURL && (
                                        <div className='col-12 mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Status</label>

                                            <div className='col-lg-8 fv-row'>
                                                <select
                                                    {...formik.getFieldProps('Status')}
                                                    className={clsx(
                                                        'form-control form-control-lg form-control-solid',
                                                        {'is-invalid': formik.touched.Status && formik.errors.Status},
                                                        {
                                                            'is-valid': formik.touched.Status && !formik.errors.Status,
                                                        }
                                                    )}
                                                >
                                                    <option value=''>Odaberi...</option>
                                                    <option value='36'>Aktivan</option>
                                                    <option value='37'>Završen</option>
                                                </select>
                                                {formik.touched.Status && formik.errors.Status && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.Status}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Napomena */}
                                <div className='col mb-6'>
                                    <label className='col-auto col-form-label fw-bold fs-6'>Napomena</label>

                                    <div className='col-auto fv-row'>
                                        <textarea
                                            placeholder='Komentar'
                                            {...formik.getFieldProps('Komentar')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.Komentar && formik.errors.Komentar,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.Komentar && !formik.errors.Komentar,
                                                }
                                            )}
                                        />
                                        {formik.touched.Komentar && formik.errors.Komentar && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.Komentar}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={loading || formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && 'Potvrdi'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
            <div className='card mb-5 mb-xl-10 col-5 offset-1'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h2>Uputstvo</h2>
                    </div>
                </div>
                <div id='kt_account_profile_details' className='collapse show'>
                    <p>
                        Moguće je imati samo jednu aktivnu inventuru za rolne i trake. Kad se završi inventura, potrebnu je otići na izmjenu, podesiti Kraj inventure i podesiti status na Završen

                    </p>
                    <p>
                        Nakon sto se kreira inventura, rolne se dodaju u sistem. Terminali,  Repromaterijali, Inventura
                    </p>
                </div>
            </div>
        </div>
    )
}
export default RepromaterijaliTrakeDetails;
