import React, {useEffect, useState} from 'react'
import TitleComponent from '../../../components/TitleComponent/TitleComponent'
import KomponentiPregled from './components/KomponentiPregled'
import {useParams} from 'react-router-dom'
import KomponentiKreiraj from './components/KomponentiKreiraj'
import clsx from 'clsx'
import KontrolaKavliteta from './components/KontrolaKvaliteta'
import ZagaInfo from './components/ZagaInfo'
import {ZapisiProizvodnjeModel, ZapisiStavkeModel} from '../../../models/ZapisiProizvodnjeModel'
import {getZapisiNalogInfo} from '../../../api/zapisiProizvodnje'
import notifyToast from '../../../components/Toast/Toast'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'

const AktivniNaloziDetails = () => {
  const {id} = useParams()
  const IdFromURL = Number(id)

  // Action that is being selected.
  const [selectedAction, setSelectedAction] = useState<number>(0)

  const [stavkeData, setstavkeData] = useState<Array<ZapisiStavkeModel>>([])
  const [data, setdata] = useState<ZapisiProizvodnjeModel>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const params = {idZapisa: IdFromURL}
    setLoading(true)
    getZapisiNalogInfo(params)
      .then((response) => {
        setdata(response.data.data['0'])
        setstavkeData(response.data.data['stavke'])
      })
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => setLoading(false))
  }, [IdFromURL])

  //  List of Actions that can be perfomed
  const actionsList = [
    {
      title: 'KOMPONENTE',
      subTitle: 'Pregled',
      component: <KomponentiPregled data={data} stavkeData={stavkeData} />,
    },
    {
      title: 'KOMPONENTE',
      subTitle: 'Kreiraj',
      component: <KomponentiKreiraj initialData={data!} />,
    },
    {
      title: 'KONTROLA KVALITETA',
      subTitle: 'Kreiraj',
      component: <KontrolaKavliteta initialData={data!} />,
    },
    {
      title: 'ŽAGA INFO',
      subTitle: 'Kreiraj',
      component: <ZagaInfo initialData={data!} />,
    },
   /* {
      title: 'UVEŽI PRIJAVU KVARA',
      subTitle: 'Pregled | Upisuje kontrolor kvaliteta',
      component: <>hell0</>,
    },*/
  ]
  return (
    <div className='row'>
      <div className='card mb-5 col-lg-3 p-5'>
        <div className='card-header'>
          <TitleComponent name='Odaberi akciju' />
        </div>
        <div className='row p-5'>
          {actionsList.map((action, index) => (
            <div
              className={clsx(
                'card hoverable m-1 p-5',
                {'bg-primary': selectedAction === index},
                {'bg-black': selectedAction !== index}
              )}
              onClick={() => setSelectedAction(index)}
            >
              <div className='text-white fw-bold fs-2 mb-2 mt-5'>{action.title}</div>
              <div className='text-white fw-bold fs-6 mb-2 mt-1'>{action.subTitle}</div>
            </div>
          ))}
        </div>
      </div>

      <div className='col-lg-9'>
        {loading ? <LoadingComponent /> : actionsList[selectedAction].component}
      </div>
    </div>
  )
}

export default AktivniNaloziDetails
