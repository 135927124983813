/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../helpers'
import {
  getProizvodnjaNaredniPetDana,
  getProizvodnjaPlan,
  sumaNalogaNaCekanjuIAktivni
} from '../../../../app/api/proizvodnja'
import moments from 'moment'
import LoadingComponent from '../../../../app/components/LoadingComponent/LoadingComponent'
import NaloziUProizvodnji from '../../../../app/components/NaloziUProizvodnji/NaloziUProizvodnji'
import {formatNumber} from "../../../helpers/formatNumber";
import {useAuth} from "../../../../app/modules/auth";

type Props = {
  className: string
  isPage?: boolean
}

type ProizvodnjaModel = {
  id: number
  fmkStavkaId: number
  baza: string
  artikal: string
  brojNaloga: string
  brojPonude: string
  data: string
}
interface ApiResponse {
  data: {
    error: number
    message: string
    data: ProizvodnjaModel[] // Assuming this is the correct type for your data array
  }
  // Include other properties from the response if necessary
}
const TablesWidget10: React.FC<Props> = ({className, isPage = false}) => {
  // State with the correct type
  const [apiData, setApiData] = useState<any>([])
  const [loading, setloading] = useState(false)
  const [isModalOpen, setisModalOpen] = useState(false)

  const [data, setData] = useState({
    sumNaCekanju: "",
    sumPlanirano: "",
    sumCombined: ""
  });

  useEffect(() => {
    const fetchData = async () => {
      setloading(true)
      try {
        var response
        if (isPage) response = await getProizvodnjaPlan()
        else response = await getProizvodnjaNaredniPetDana()

        const apiResponse: ApiResponse = response as unknown as ApiResponse

        const responseData = apiResponse.data.data
        setApiData(responseData)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setloading(false)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setloading(true) // Set loading state to true while fetching data
      try {
        const response = await sumaNalogaNaCekanjuIAktivni();
        console.log(response, 'respo');
        setData(response.data.data);  // Set state with the data from the response
        setloading(false);  // Set loading state to false once data is received
      } catch (err) {
        setloading(false)
      }
    };

    fetchData();
  }, []);
  const {currentUser} = useAuth()

  console.log( currentUser!.Privilegija, 'privilegija' );
  return (
    <div className={`card ${className} `}>
      <NaloziUProizvodnji isModalOpen={isModalOpen} setisModalOpen={setisModalOpen} />
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Paneli plan proizvodnje</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <button
            className='btn btn-sm btn-light-success'
            hidden={isPage}
            onClick={() => setisModalOpen(true)}
          >
            <KTIcon iconName='data' className='fs-3' />
            Nalozi U Proizvodnji
          </button>
          <a
            href='paneli/arhiviraj-naloge'
            className='btn btn-sm btn-light-danger'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
            hidden={isPage}
          >
            <KTIcon iconName='data' className='fs-3' />
            Arhiviraj naloge
          </a>
          <a
            href='paneli/plan-proizvodnje'
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
            hidden={isPage}
          >
            <KTIcon iconName='data' className='fs-3' />
            Plan proizvodnje
          </a>
        </div>
      </div>

      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {
            ![2,3].includes(currentUser!.Privilegija) && (
        <div className="row">
          <div className="col-lg-3 col-sm-12 bg-light-warning px-6 py-8 rounded-2 me-7 mb-7">
            <i className="ki-duotone ki-abstract-26 fs-3x text-warning d-block my-2">
              <span className="path1"> {formatNumber(data.sumNaCekanju)}</span>
              <span className="path2"></span>
            </i>
            <a href="#" className="text-warning fw-semibold fs-6">Nalozi na čekanju [m2] : </a>
          </div>
          <div className="col-lg-4 col-sm-12 bg-light-success px-6 py-8 rounded-2 me-7 mb-7">
            <i className="ki-duotone ki-abstract-26 fs-3x text-success d-block my-2">
              <span className="path1"> {formatNumber(data.sumPlanirano)} </span>
              <span className="path2"></span>
            </i>
            <a href="#" className="text-success fw-semibold fs-6">Aktivni nalozi [m2]: </a>
          </div>
          <div className="col-lg-4 col-sm-12 bg-light-primary px-6 py-8 rounded-2 me-7 mb-7">
            <i className="ki-duotone ki-abstract-26 fs-3x text-primary d-block my-2">
              <span className="path1"> {formatNumber(data.sumCombined)}</span>
              <span className="path2"></span>
            </i>
            <a href="#" className="text-primary fw-semibold fs-6">Total [m2]: </a>
          </div>
        </div>
            )}
        {/* begin::Table container */}
        {loading ? (
          <LoadingComponent />
        ) : (
          <div
            className='table-responsive'
            style={{height: isPage ? '100%' : '300px', overflowY: 'auto'}}
          >
            {/* begin::Table */}
            <table className='table table-striped table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  {Object.entries(apiData).map(([keys, values]) => (
                    <th className='min-w-150px'>{moments(keys).format('DD-MM-YYYY')}</th>
                  ))}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr>
                  {Object.entries(apiData).map(([key, value]) => (
                    <td style={{verticalAlign: 'top'}}>
                      {/* @ts-ignore */}

                      {value.length > 0 &&
                        // @ts-ignore
                        value.map((record) => (
                          <ul>
                            <div>
                              {/* ... other code ... */}
                              <div>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                  {record.brojNaloga} {/* Display brojNaloga from payload */}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                  {record.artikal} {/* Display artikal from payload */}
                                </span>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                  {record.kupac} {/* Display artikal from payload */}
                                </span>
                              </div>
                            </div>
                          </ul>
                        ))}
                    </td>
                  ))}
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget10}
