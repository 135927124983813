import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import clsx from 'clsx';
import {fetchProdajaNajam, saveProdajaNajam, updateProdajaNajam} from '../../api/ProdajaNajam';
import {ProdajaNajamModel} from '../../models/ProdajaNajamModel';
import notifyToast from '../../components/Toast/Toast';
import {ToastContainer} from 'react-toastify';
import TitleComponent from '../../components/TitleComponent/TitleComponent';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import KategorijeProdajeNajam from '../../components/KategorijeProdajaNajam/KategorijeProdajaNajam';
import SubKategorijeProdajeNajam from '../../components/KategorijeProdajaNajam/SubKategorijeProdajaNajam';
import ImageComponent from '../../components/ImageComponet/ImageComponent';
import UposlenikDropdown from "../../components/UposlenikDropdown/UposlenikDropdown";
import JmRepromaterijalaDropdown from "../../components/JmRepromaterijalaDropdown/JmRepromaterijalaDropdown";

const ProdajaNajamDetails: React.FC = () => {
    const {id} = useParams();
    const IdFromURL = Number(id);
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState<File | null>(null);
    const [file2, setFile2] = useState<File | null>(null);
    const [file3, setFile3] = useState<File | null>(null);
    const navigate = useNavigate();

    const initialValues: Partial<ProdajaNajamModel> = {
        Naziv: undefined,
        SifraArtikla: undefined,
        Cijena: undefined,
        JedMjere: undefined,
        Kategorija: undefined,
        SubKategorija: undefined,
        Opis: undefined,
        KomentariRukovodioca: undefined,
        Slika: undefined,
        Slika2: undefined,
        Slika3: undefined,
        WebProdaja: undefined,
        WebLink:   undefined,
        Realizovano: undefined,
        Prodao: undefined,
    };

    const [initialData, setInitialData] = useState<Partial<ProdajaNajamModel>>(initialValues);

    useEffect(() => {
        if (IdFromURL) {
            fetchProdajaNajam(IdFromURL)
                .then((data) => {
                    setInitialData(data);
                    formik.resetForm({values: data}); // <-- reset formik state here
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [IdFromURL]);

    const validationSchema = Yup.object({
        Naziv: Yup.string().required('Required'),
        SifraArtikla: Yup.string().required('Required'),
        Opis: Yup.string().required('Required'),
        Cijena: Yup.string().required('Required'),
        Kategorija: Yup.number().required('Required')
    });

    const getFormData = (values: any) => {
        const formData = new FormData();
        if (file) {
            formData.append('Slika', file);
        }
        if (file2) {
            formData.append('Slika2', file2);
        }
        if (file3) {
            formData.append('Slika3', file3);
        }
        values.Naziv && formData.append('Naziv', values.Naziv);
        values.SifraArtikla && formData.append('SifraArtikla', values.SifraArtikla);
        values.Cijena && formData.append('Cijena', values.Cijena);
        values.JedMjere && formData.append('JedMjere', values.JedMjere);
        values.Kategorija && formData.append('Kategorija', values.Kategorija);
        values.SubKategorija && formData.append('SubKategorija', values.SubKategorija);
        values.Opis && formData.append('Opis', values.Opis);
        values.KomentariRukovodioca && formData.append('KomentariRukovodioca', values.KomentariRukovodioca);
        values.Slika && formData.append('Slika', values.Slika);
        values.Slika2 && formData.append('Slika2', values.Slika3);
        values.Slika3 && formData.append('Slika3', values.Slika3);
        values.WebProdaja && formData.append('WebProdaja', values.WebProdaja);
        values.WebLink && formData.append('WebLink', values.WebLink);
        values.Realizovano && formData.append('Realizovano', values.Realizovano);
        values.Prodao && formData.append('Prodao', values.Prodao);
        return formData;
    };

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true);
            const formdata = getFormData(values);
            try {
                let response;
                if (IdFromURL) {
                    response = await updateProdajaNajam(IdFromURL, formdata);
                } else {
                    response = await saveProdajaNajam(formdata);
                }

                if (response.error === 0) {
                    notifyToast(true, response.message);
                } else {
                    notifyToast(false, response.message || 'An unexpected error occurred.');
                }
            } catch (error: any) {
                console.error('Error: ', error);
                notifyToast(false, error.response.data.message);
                setStatus(error);
            } finally {
                setLoading(false);
                setSubmitting(false);
                setTimeout(() => {
                    navigate(-1);
                }, 1000);
            }
        },
    });

    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer/>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name=''/>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className='card-body border-top p-9 row'>
                            <div className='col-6 mb-6'>
                                {/* Sifra */}
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                    Šifra artikla
                                </label>
                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Sifra Artikla'
                                        {...formik.getFieldProps('SifraArtikla')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.SifraArtikla && formik.errors.SifraArtikla},
                                            {
                                                'is-valid': formik.touched.SifraArtikla && !formik.errors.SifraArtikla,
                                            }
                                        )}
                                    />
                                    {formik.touched.SifraArtikla && formik.errors.SifraArtikla && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.SifraArtikla}</div>
                                        </div>
                                    )}
                                </div>

                                {/* Naziv */}
                                <label className='col-lg-4 col-form-label  mt-4  required fw-bold fs-6'>
                                    Naziv
                                </label>
                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        placeholder='Naziv'
                                        {...formik.getFieldProps('Naziv')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.Naziv && formik.errors.Naziv},
                                            {
                                                'is-valid': formik.touched.Naziv && !formik.errors.Naziv,
                                            }
                                        )}
                                    />
                                    {formik.touched.Naziv && formik.errors.Naziv && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Naziv}</div>
                                        </div>
                                    )}
                                </div>

                                {/* Opis */}
                                <div className='col-lg-12 mt-6 fv-row'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Opis</label>
                                    <div className='col-lg-12 fv-row'>
                                        <ReactQuill
                                            value={formik.values.Opis || ''}
                                            onChange={(value) => formik.setFieldValue('Opis', value)}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.Opis && formik.errors.Opis},
                                                {
                                                    'is-valid': formik.touched.Opis && !formik.errors.Opis,
                                                }
                                            )}
                                            placeholder="Napomena"
                                        />
                                        {formik.touched.Opis && formik.errors.Opis && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.Opis}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Komentar rukovodioca */}
                                <div className='col-lg-12 mt-10 fv-row'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Komentar rukovodioca</label>
                                    <div className='col-lg-12 fv-row'>
                                        <ReactQuill
                                            value={formik.values.KomentariRukovodioca || ''}
                                            onChange={(value) => formik.setFieldValue('KomentariRukovodioca', value)}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.KomentariRukovodioca && formik.errors.KomentariRukovodioca},
                                                {
                                                    'is-valid': formik.touched.KomentariRukovodioca && !formik.errors.KomentariRukovodioca,
                                                }
                                            )}
                                            placeholder="Napomena"
                                        />
                                        {formik.touched.KomentariRukovodioca && formik.errors.KomentariRukovodioca && (
                                            <div className='fv-plugins-message-container'>
                                                <div
                                                    className='fv-help-block'>{formik.errors.KomentariRukovodioca}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Kategorija */}
                            <div className='col-6 mb-6'>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <label
                                            className='col-lg-4 col-form-label required fw-bold fs-6'>Kategorija</label>
                                        <div className='col-lg-8 fv-row'>
                                            <KategorijeProdajeNajam
                                                value={formik.values.Kategorija}
                                                onChange={(name, value) => formik.setFieldValue('Kategorija', value)}
                                                errors={formik.errors.Kategorija}
                                                touched={formik.touched.Kategorija}
                                                selectName='Kategorija'
                                            />
                                        </div>
                                    </div>
                                    {Number(formik.values.Kategorija) === 3 && (
                                        <div className='col-lg-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Sub
                                                Kategorija</label>
                                            <div className='col-lg-8 fv-row'>
                                                <SubKategorijeProdajeNajam
                                                    value={formik.values.SubKategorija}
                                                    onChange={(name, value) => formik.setFieldValue('SubKategorija', value)}
                                                    errors={formik.errors.SubKategorija}
                                                    touched={formik.touched.SubKategorija}
                                                    selectName='SubKategorija'
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {/* Cijena */}
                                <div className='col-lg-12 mt-6 fv-row'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Cijena sa PDV-om</label>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            placeholder='Cijena'
                                            {...formik.getFieldProps('Cijena')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.Cijena && formik.errors.Cijena},
                                                {
                                                    'is-valid': formik.touched.Cijena && !formik.errors.Cijena,
                                                }
                                            )}
                                        />
                                        {formik.touched.Cijena && formik.errors.Cijena && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.Cijena}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-lg-6 mt-6 fv-row'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Jedinica Mjere</label>
                                        <JmRepromaterijalaDropdown
                                            value={formik.values.JedMjere}
                                            onChange={(name, value) => formik.setFieldValue('JedMjere', value)}
                                            errors={formik.errors.JedMjere}
                                            touched={formik.touched.JedMjere}
                                            selectName='JedMjere'
                                        />
                                    </div>
                                </div>
                                <div className='separator mt-5'></div>
                                <div className='col-6 mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        Realizovano
                                    </label>
                                    <select
                                        {...formik.getFieldProps('Realizovano')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.Realizovano && formik.errors.Realizovano},
                                            {
                                                'is-valid': formik.touched.Realizovano && !formik.errors.Realizovano,
                                            }
                                        )}
                                    >
                                        <option value=''>Odaberi status...</option>
                                        <option value='0' selected>Ne</option>
                                        <option value='1'>Da</option>
                                    </select>
                                    {formik.touched.Realizovano && formik.errors.Realizovano && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.Realizovano}</div>
                                        </div>
                                    )}
                                </div>
                                <div className='col-lg-8 mb-6'>
                                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                        Realizovao uposlenik
                                    </label>
                                    <UposlenikDropdown
                                        value={formik.values.Prodao}
                                        onChange={(name, value) => formik.setFieldValue('Prodao', value)}
                                        errors={formik.errors.Prodao}
                                        touched={formik.touched.Prodao}
                                        selectName='Prodao'
                                    />
                                </div>
                                <div className='col-6 mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        Web prodaja
                                    </label>
                                    <select
                                        {...formik.getFieldProps('WebProdaja')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {'is-invalid': formik.touched.WebProdaja && formik.errors.WebProdaja},
                                            {
                                                'is-valid': formik.touched.WebProdaja && !formik.errors.WebProdaja,
                                            }
                                        )}
                                    >
                                        <option value=''>Odaberi status...</option>
                                        <option value='0' selected>Ne</option>
                                        <option value='1'>Da</option>
                                    </select>
                                    {formik.touched.WebProdaja && formik.errors.WebProdaja && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.WebProdaja}</div>
                                        </div>
                                    )}
                                    {/* Sifra */}
                                    <label className='col-lg-4 col-form-label mt-4 required fw-bold fs-6'>
                                        Web link
                                    </label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            placeholder='Link'
                                            {...formik.getFieldProps('WebLink')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.WebLink && formik.errors.WebLink},
                                                {
                                                    'is-valid': formik.touched.WebLink && !formik.errors.WebLink,
                                                }
                                            )}
                                        />
                                        {formik.touched.WebLink && formik.errors.WebLink && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.WebLink}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Napomena */}
                            {/* Slika */}
                            <div className='separator mt-5 mb-5'></div>
                            <div className='row mb-6'>
                                <div className='col-lg-4 mb-6'>
                                    <ImageComponent
                                        label='Slika'
                                        setFile={(val) => {
                                            setFile(val);
                                        }}
                                        slikaUrl={formik.values.Slika}
                                        isFileValid={(val) => formik.setErrors({Slika: val})}
                                    />
                                </div>
                                <div className='col-lg-4 mb-6'>
                                    <ImageComponent
                                        label='Slika2'
                                        setFile={(val) => {
                                            setFile2(val);
                                        }}
                                        slikaUrl={formik.values.Slika2}
                                        isFileValid={(val) => formik.setErrors({Slika2: val})}
                                    />
                                </div>
                                <div className='col-lg-4 mb-6'>
                                    <ImageComponent
                                        label='Slika3'
                                        setFile={(val) => {
                                            setFile3(val);
                                        }}
                                        slikaUrl={formik.values.Slika3}
                                        isFileValid={(val) => formik.setErrors({Slika3: val})}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={loading || formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && 'Potvrdi'}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default ProdajaNajamDetails;
