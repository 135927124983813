/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from 'react'
import {Modal, ModalHeader} from 'react-bootstrap'
import {getFmkNalogaStavkaData, izlazStavkiFmkNaloga} from '../../../api/fmkNalozi'
import {FmkNalogiStavkeModel} from '../../../models/FmkNaloziModel'
import notifyToast from '../../../components/Toast/Toast'
import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
import TitleComponent from '../../../components/TitleComponent/TitleComponent'
import KcerkeSifreDropdown from './KcerkeSifreDropdown'
import IndeterminateCheckbox from '../../../components/IndeterminateCheckbox/IndeterminateCheckbox'

const FmkNaloziModal = ({isModalOpen, setisModalOpen, selectedId}) => {
  const nalogId = useMemo(() => selectedId, [selectedId])
  const [dataNaloga, setDataNaloga] = useState<FmkNalogiStavkeModel['nalog'][]>([])
  const [dataStavka, setDataStavka] = useState<FmkNalogiStavkeModel['stavka']>([])
  const [loading, setLoading] = useState(true)
  const [rowSelection, setRowSelection] = useState({})

  const updateData = () => {
    getFmkNalogaStavkaData({idNaloga: nalogId})
      .then((response) => {
        if (response) {
          const naloga: any = []
          naloga.push(response.nalog)
          setDataNaloga(naloga)
          setDataStavka(response.stavka)
          setRowSelection({})
        }
      })
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => setLoading(false))
  }
  useEffect(() => {
    updateData()
  }, [nalogId])

  // Set cell color according to condtions.
  const getCellColor = (cell, row) => {
    // Color
    if (cell.column.id === 'Status') {
      return row.original.statusi.Boja
    }
    // Color default
    return '#FFFFFF'
  }

  // Rows where dropdown is displayed are valid rows for update.
  const [validRows, setvalidRows] = useState<any>([])

  // on Potvrdi
  const onClickPotvrdi = () => {
    setLoading(true)
    // Payload for api.
    const payload = validRows.map((row) => {
      const item = row.original
      const dataSet = {
        Artikal: item.ArtikalWebsoft,
        VrstaDokumentaBroj: dataNaloga[0].BrojNaloga,
        JM: item.IdJedMjere,
        Izlaz: item.Kolicina,
      }
      return dataSet
    })

    // Update api.
    izlazStavkiFmkNaloga(payload)
      .then((response) => {
        notifyToast(true, response.message)
        updateData()
      })
      .catch((error) => notifyToast(false, error.response.data.message))
      .finally(() => setLoading(false))
  }
  const columns1 = [
    {
      id: 'Nalog br',
      header: 'Nalog br',
      accessorKey: 'BrojNaloga',
    },
    {
      id: 'Kupac',
      header: 'Kupac',
      accessorKey: 'Kupac',
    },

    {
      id: 'Kreirao',
      header: 'Kreirao',
      accessorKey: 'Korisnik',
    },
  ]
  const table1 = useReactTable({
    data: dataNaloga,
    columns: columns1,
    getCoreRowModel: getCoreRowModel(),
  })

  const columns2 = [
    {
      id: 'id',
      header: ({table}) => (
        <div className='row p-2'>
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      cell: ({row, getValue}) => (
        <div className='col'>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected() && row.original.ArtikalWebsoft,
              indeterminate: row.getIsSomeSelected() && row.original.ArtikalWebsoft,
              onChange: row.original.ArtikalWebsoft ? row.getToggleSelectedHandler() : {},
            }}
            disabled={!row.original.ArtikalWebsoft}
          />
        </div>
      ),
    },
    {
      id: 'Šifra',
      header: 'Šifra',
      accessorKey: 'IdArtikla',
    },
    {
      id: 'Naziv',
      header: 'Naziv',
      accessorKey: 'Naziv',
    },

    {
      id: 'Mjera',
      header: 'Mjera',
      accessorKey: 'IdJedMjere',
    },
    {
      id: 'Kolicina',
      header: 'Količina',
      accessorKey: 'Kolicina',
    },
    {
      id: 'Status',
      header: 'Status',
      accessorKey: 'statusi.Naziv',
    },
    {
      id: 'ArtikalWebsoft',
      header: 'Artikal Websoft',
      cell: TableCell,
    },
  ]
  const table2 = useReactTable({
    data: dataStavka,
    columns: columns2,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: rowSelection,
    },
    meta: {
      updateData: (rowIndex: number, columnId: string, value: string) => {
        setDataStavka((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          })
        )
      },
    },
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
  })

  useEffect(() => {
    const rows = table2.getSelectedRowModel().rows.filter((row) => row.original.ArtikalWebsoft)
    setvalidRows(rows)
  }, [table2.getSelectedRowModel().rows])

  return (
    <Modal
      className='modal-xl'
      show={isModalOpen && selectedId}
      onHide={() => setisModalOpen(false)}
    >
      <ModalHeader closeButton={true}>
        <TitleComponent name='Stavke Naloga' />
      </ModalHeader>
      <div className='p-5'>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3'>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table1.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className='text-white'
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* @ts-ignore */}
                        {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table1.getRowModel().rows && table1.getRowModel().rows.length > 0 ? (
                  table1.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3 '>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table2.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className='text-white'
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* @ts-ignore */}
                        {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table2.getRowModel().rows && table2.getRowModel().rows.length > 0 ? (
                  table2.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={loading || !validRows.length}
                onClick={onClickPotvrdi}
              >
                {!loading && 'Potvrdi'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default FmkNaloziModal

const TableCell = ({getValue, row, column, table}) => {
  const initialValue = getValue()
  const [value, setValue] = useState(initialValue)
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    table.options.meta?.updateData(row.index, column.id, value)
  }, [value])

  return (
    <KcerkeSifreDropdown
      sifra={row.original.IdArtikla}
      value={value}
      onChange={(name, value) => setValue(value)}
    />
  )
}
