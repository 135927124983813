import React, {useEffect, useState} from 'react'
import KrajRolne from './components/KrajRolne'
import {ToastContainer} from 'react-toastify'
import KrajTraka from './components/KrajTraka'
import {useNavigate, useParams} from 'react-router-dom'
import notifyToast from '../../../components/Toast/Toast'
import {mojeStavkeNaNalogu, pauzirajRadniNalog} from '../../../api/radniNalozi'
import {FmkNalogiStavkeModel} from '../../../models/FmkNaloziModel'
import KrajTable from './components/KrajTable'

const RadniNalogKraj = () => {
    const {id} = useParams()
    const [selectedComponent, setselectedComponent] = useState<number>(0)
    const [dataStavka, setDataStavka] = useState<FmkNalogiStavkeModel>()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        if (id) {
            setLoading(true)
            const params = {idNaloga: id}
            mojeStavkeNaNalogu(params)
                .then((response) => {
                    console.log({response})
                    setDataStavka(response.data)
                })
                .catch((error) => notifyToast(false, error.message))
                .finally(() => setLoading(false))
        }
    }, [id])

    const handlePauzaRadnogNaloga = async () => {
        try {
            setLoading(true) // Start loading
            const params = {idNaloga: id}
            pauzirajRadniNalog(params)
                .then((response) => {
                    notifyToast(true, response.message)
                    setTimeout(() => {
                        navigate(-1)
                    }, 3000)
                })
                .catch((error) => notifyToast(false, error.message))
                .finally(() => setLoading(false))
        } catch (error) {
            console.error('API call failed:', error)
        } finally {
            setLoading(false) // End loading
        }
    }

    const components = [
        {component: <></>},
        {
            // @ts-ignore
            component: <KrajRolne nalogaId={id} stavka={dataStavka}/>,
        },
        {
            component: <KrajTraka nalogaId={id!}/>,
        },
    ]

    return (
        <div className='w-100'>
            <ToastContainer/>
            <div className='row'>
                <div className='col-md-4'>
                    <button type='button' className='btn btn-danger col-10' onClick={handlePauzaRadnogNaloga}>
                        {!loading && 'Pauziraj radni nalog'}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                        )}
                    </button>
                </div>
                <div className='col-sm-6 col-md-8'>
                    <div className='alert alert-danger'>
                        <h3>Ukoliko pauzirate radni nalog, potrebno je ponovo pokrenuti proizvodnju istog.</h3>
                    </div>
                </div>
            </div>
            <div className='mb-10 mt-10 fv-row'>
                <label className='d-flex align-items-center form-label mb-3'>
                    Odaberi rolnu / traku
                    <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip'></i>
                </label>

                <div className='row mb-2' data-kt-buttons='true'>
                    <div className='col'>
                        <input
                            type='radio'
                            className='btn-check'
                            name='accountTeamSize'
                            value='1-1'
                            id='kt_account_team_size_select_1'
                            onChange={() => setselectedComponent(1)}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='kt_account_team_size_select_1'
                        >
                            <span className='fw-bolder fs-3'>ROLNA</span>
                        </label>
                    </div>
                    <div className='col'>
                        <input
                            type='radio'
                            className='btn-check'
                            name='accountTeamSize'
                            value='2-10'
                            id='kt_account_team_size_select_2'
                            onChange={() => setselectedComponent(2)}
                        />
                        <label
                            className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                            htmlFor='kt_account_team_size_select_2'
                        >
                            <span className='fw-bolder fs-3'>TRAKA</span>
                        </label>
                    </div>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-12'>{dataStavka && components[selectedComponent].component}</div>
                <div className='col-12'>{dataStavka && <KrajTable data={dataStavka}/>}</div>
            </div>
        </div>
    )
}

export default RadniNalogKraj
