import React, {useState, useEffect} from 'react'
import SearchableSelect from '../SearchableSelect/SearchableSelect'
import {Option} from '../../models/BojaModel'
import {getArtikliDropdown, getGalanterijaDropdown} from '../../api'

// Option type as per the API response

interface DropdownProps {
    value?: string | number
    initialValue?: string | number
    onChange?: (name: string, value: any) => void
    errors?: string | undefined
    touched?: boolean
    selectName?: string
}

const mapDropdown = (data) => {
    return data.map((item) => ({label: item.Naziv, value: item.Id.toString()}))
}

const ArtikliGalanterija: React.FC<DropdownProps> = ({
                                                      value,
                                                      initialValue,
                                                      onChange,
                                                      errors,
                                                      touched,
                                                      selectName,
                                                  }) => {
    const [ArtikliDropdown, setArtikliDropdown] = useState<Array<Option>>([])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [internalValue, setInternalValue] = useState<string | number | null>(initialValue ?? null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getGalanterijaDropdown()
                setArtikliDropdown(mapDropdown(response.data))
            } catch (error) {
                console.error('Error fetching Artikli dropdown data:', error)
            }
        }
        fetchData()
    }, [])

    const finalValue = typeof value !== 'undefined' ? value : internalValue

    return (
        <SearchableSelect
            options={ArtikliDropdown}
            value={finalValue?.toString() ?? ''}
            onChange={onChange!}
            name={selectName ?? 'Artikli'}
            errors={errors}
            placeholder='Odaberi Artikal...'
            touched={touched}
        />
    )
}

export default ArtikliGalanterija
