import React, {useState, useEffect} from 'react'
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  getFilteredRowModel,
} from '@tanstack/react-table'
import TablePagination from '../../components/TablePagination/TablePagination'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import {Link} from 'react-router-dom'
import {TableActionButton} from '../../components/TableActionBtn/TableActionButton'
import {useNavigate} from 'react-router-dom'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import moments from 'moment/moment'
import {DozvoleAtesti, ParamsModelDozvoleAtesti} from '../../models/DozvoleAtesti'
import {deleteDokument, getAllDokumentiUputstva} from '../../api/dozvoleAtesti'
import {PaginationModel} from '../../models/PaginationModel'

const DozvoleAtestiLista: React.FC = () => {
  const [data, setData] = useState<DozvoleAtesti[]>([])
  const [initalData, setInitalData] = useState<DozvoleAtesti[]>([])
  const [loading, setLoading] = useState(true)
  const [pagination, setpagination] = useState<PaginationModel>({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filter, setfilter] = useState('')
  const [params, setparams] = useState<ParamsModelDozvoleAtesti>({per_page: 10, page: 1, active: 1})

  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')

  const updatePage = () => {
    setLoading(true)
    getAllDokumentiUputstva(params)
      .then((response) => {
        console.log(response.data['data'])
        const data = response.data['data']
        setData(data)
        setInitalData(data)
        const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
        setpagination({
          curPage: response.data['current_page'],
          totalPage: totalPage,
          perPage: params.per_page,
        })
      })
      .catch((error) => {
        console.error('Error: ', error)
        notifyToast(false, error)
      })
      .finally(() => {
        setLoading(false)
      })
    console.log(data)
  }

  const onFilter = (filter = undefined) => {
    if (filter === 1 || filter === 0)
      setparams({per_page: pagination.perPage, page: 1, active: filter})
    else setparams({per_page: pagination.perPage, page: 1})
  }

  useEffect(() => {
    updatePage()
  }, [params])

  useEffect(() => {
    const {id} = sorting[0] ?? ''
    data.sort((a, b) => a[id] - b[id])
  }, [sorting])

  const columns = [
    {
      id: 'DokSredId',
      header: 'Id',
      accessorKey: 'DokId',
    },
    {
      id: 'Naziv',
      header: 'Naziv',
      accessorKey: 'DokNaziv',
    },
    {
      id: 'Sredstvo',
      header: 'Sredstvo',
      accessorKey: 'sredstvo.SredstvoNaziv',
    },
    {
      id: 'Datum',
      header: 'Datum',
      accessorFn: (row) => `${moments(row.DokSredDatum).format('DD-MM-YYYY')}`,
    },
    {
      id: 'Datum isteka',
      header: 'Datum isteka',
      accessorFn: (row) => `${moments(row.DokDatumIsteka).format('DD-MM-YYYY')}`,
    },
    {
      id: 'Preuzmi',
      header: 'Preuzmi',
      cell: ({row}) =>
        row.original.DokPrilog && (
          <a href={row.original.DokPrilog}>
            {row.original.DokPrilog}
            <KTIcon iconName='document' className='fs-2tx text-primary me-2' />
          </a>
        ),
    },
    {
      header: 'Akcija',
      accessorKey: 'Akcija',
      cell: ({row}) => (
        <TableActionButton
          onDelete={() => onDelete(row.original.DokId)}
          onEdit={() => {
            onEdit(row.original.DokId)
          }}
        />
      ),
    },
  ]
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
  })
  const onDelete = (id) => {
    deleteDokument(id)
      .then((response) => {
        notifyToast(true, response)
        setparams({per_page: pagination.perPage, page: pagination.curPage})
      })
      .catch((error) => {
        notifyToast(false, error.data['message'])
      })
  }
  const navigate = useNavigate()

  const onEdit = (rowData) => {
    navigate(`/sredstva/dozvole-atesti/izmjena/${rowData}`)
  }

  return (
    <KTCard>
      <ToastContainer />
      <KTCardBody className='py-4'>
        <div className='d-flex justify-content-between'>
          <div data-kt-user-table-toolbar='base'>
            <TableSearch onSearch={(text) => setGlobalFilter(text)} />
          </div>
          <div data-kt-user-table-toolbar='base'>
            <ExportButton table={table} filename={'dokumenti_uputstva'} />
            <Link to='/sredstva/dozvole-atesti/dodaj'>
              <button type='button' className='btn btn-primary'>
                <KTIcon iconName='plus' className='fs-2' />
                Dodaj
              </button>
            </Link>
          </div>
        </div>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className='table-responsive mt-3 '>
            <table className='table align-middle table-bordered gy-5 no-footer'>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        className='text-white'
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* @ts-ignore */}
                        {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='text-gray-600'>
                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <TablePagination
          updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
          pagination={pagination}
        />
      </KTCardBody>
    </KTCard>
  )
}
export default DozvoleAtestiLista
