/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {PaleteUlaziModel} from '../../models/PaleteUlaziModel'
import {fetchPaleteUlazi, savePaleteUlazi, updatePaleteUlazi} from '../../api/PaleteUlazi'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import ArtikliDropdown from '../../components/ArtikliDropdown/ArtikliDropdown'
import ProizvodjacPartnerDropdown from '../../components/ProizvodjacPartnerDropdown/ProizvodjacPartnerDropdown'
import DobavljacPartnerDropdown from '../../components/DobavljacPartnerDropdown/DobavljacPartnerDropdown'
import KodBojeRepromaterijalaDropdown
    from '../../components/KodBojeRepromaterijalaDropdown/KodBojeRepromaterijalaDropdown'
import PaleteUlaziTableComponent from '../../components/PaleteUlaziTableComponent/PaleteUlaziTableComponent'
import JmRepromaterijalaDropdown from '../../components/JmRepromaterijalaDropdown/JmRepromaterijalaDropdown'
import DokumentiDropdown from '../../components/DokumentiDropdown/DokumentiDropdown'

const PaleteUlaziDetails = () => {
    const {id} = useParams()
    const IdFromURL = Number(id)

    const [loading, setLoading] = useState(true)
    const [refreshTable, setRefreshTable] = useState(false)

    const initialValues = {
        ArtPaletaArtikal: undefined,
        ArtPaletaDatum: undefined,
        ArtPaletaDokument: undefined,
        ArtPaletaBrDokumenta: undefined,
        Proizvodjac: undefined,
        Dobavljac: undefined,
        ArtPaletaKodBoje: undefined,
        ArtPaletaLokacija: undefined,
        UlaznaKalkulacija: undefined,
        GodinaProizvodnje: undefined,
        GodinaUvoza: undefined,
        ArtPaletaNapomena: undefined,
        ArtPaletaRaspolozivost: undefined,
        ArtPaletaStatus: undefined,
        ArtPaletaUlazJm: undefined,
    }
    const [initialData, setInitialData] = useState<Partial<PaleteUlaziModel>>(initialValues)

    useEffect(() => {
        if (IdFromURL) {
            fetchPaleteUlazi(IdFromURL)
                .then((data) => {
                    setInitialData(data)
                    formik.resetForm({values: data}) // <-- reset formik state here
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [IdFromURL])

    const validationSchema = Yup.object({
        ArtPaletaArtikal: Yup.string().required('Required'),
        ArtPaletaDatum: Yup.string().required('Required'),
        ArtPaletaDokument: Yup.number().required('Required'),
        ArtPaletaBrDokumenta: Yup.string().required('Required'),
        ArtPaletaUlaz: Yup.number().required('Required'),
        ArtPaletaUlazJm: Yup.number().required('Required'),
        ArtPaletaLokacija: Yup.string().required('Required'),
        ArtPaletaStatus: Yup.number().required('Required'),
    })

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
            setLoading(true)
            try {
                let response
                values.GodinaProizvodnje = values.GodinaProizvodnje?.toString()
                values.GodinaUvoza = values.GodinaUvoza?.toString()
                if (IdFromURL) {
                    response = await updatePaleteUlazi(IdFromURL, values)
                } else {
                    response = await savePaleteUlazi(values)
                }

                if (response.error === 0) {
                    notifyToast(true, response.message)
                    setRefreshTable(true);
                    resetForm();
                } else {
                    notifyToast(false, response.message || 'An unexpected error occurred.')
                }
            } catch (error: any) {
                console.error('Error: ', error)
                notifyToast(false, error.response.data.message)
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        },
    })
    return (
        <div className='row'>
            <ToastContainer/>
            <div className='card mb-5 mb-xl-10 col-6'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <TitleComponent name=' '/>
                    </div>
                </div>
                <div id='kt_account_profile_details' className='collapse show'>
                    {loading ? (
                        <LoadingComponent/>
                    ) : (
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className='card-body border-top p-9 row'>
                                {/* Artikal */}
                                <div className='col-6 mb-6'>
                                    <div className='col'>
                                        <label className='col-auto col-form-label required fw-bold fs-6'>Artikal</label>
                                        <div className='col-auto fv-row'>
                                            <ArtikliDropdown
                                                value={formik.values.ArtPaletaArtikal}
                                                onChange={(name, value) => formik.setFieldValue('ArtPaletaArtikal', value)}
                                                errors={formik.errors.ArtPaletaArtikal}
                                                touched={formik.touched.ArtPaletaArtikal}
                                                selectName='ArtPaletaArtikal'
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Datum */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>Datum</label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='date'
                                            placeholder='Datum'
                                            {...formik.getFieldProps('ArtPaletaDatum')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.ArtPaletaDatum && formik.errors.ArtPaletaDatum,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.ArtPaletaDatum && !formik.errors.ArtPaletaDatum,
                                                }
                                            )}
                                        />
                                        {formik.touched.ArtPaletaDatum && formik.errors.ArtPaletaDatum && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.ArtPaletaDatum}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Kolcina Ulaz */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>
                                        Količina Ulaza
                                    </label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='number'
                                            placeholder='Kolicina Ulaza'
                                            {...formik.getFieldProps('ArtPaletaUlaz')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {'is-invalid': formik.touched.ArtPaletaUlaz && formik.errors.ArtPaletaUlaz},
                                                {
                                                    'is-valid': formik.touched.ArtPaletaUlaz && !formik.errors.ArtPaletaUlaz,
                                                }
                                            )}
                                        />
                                        {formik.touched.ArtPaletaUlaz && formik.errors.ArtPaletaUlaz && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.ArtPaletaUlaz}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Document */}
                                <div className='col-6 mb-6'>
                                    <div className='col'>
                                        <label className='col-auto col-form-label required fw-bold fs-6'>
                                            Dokument
                                        </label>
                                        <div className='col-auto fv-row'>
                                            <DokumentiDropdown
                                                value={formik.values.ArtPaletaDokument}
                                                onChange={(name, value) => formik.setFieldValue('ArtPaletaDokument', value)}
                                                errors={formik.errors.ArtPaletaDokument}
                                                touched={formik.touched.ArtPaletaDokument}
                                                selectName='ArtPaletaDokument'
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Br. Dokumenta */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>Br.dokumenta</label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='text'
                                            placeholder='Br.dokumenta'
                                            {...formik.getFieldProps('ArtPaletaBrDokumenta')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.ArtPaletaBrDokumenta &&
                                                        formik.errors.ArtPaletaBrDokumenta,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.ArtPaletaBrDokumenta &&
                                                        !formik.errors.ArtPaletaBrDokumenta,
                                                }
                                            )}
                                        />
                                        {formik.touched.ArtPaletaBrDokumenta && formik.errors.ArtPaletaBrDokumenta && (
                                            <div className='fv-plugins-message-container'>
                                                <div
                                                    className='fv-help-block'>{formik.errors.ArtPaletaBrDokumenta}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Ulazna kalulicija */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label fw-bold fs-6'>Ulazna Kalkulacija</label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='text'
                                            placeholder='Ulazna kalkulacija'
                                            {...formik.getFieldProps('UlaznaKalkulacija')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.UlaznaKalkulacija && formik.errors.UlaznaKalkulacija,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.UlaznaKalkulacija && !formik.errors.UlaznaKalkulacija,
                                                }
                                            )}
                                        />
                                        {formik.touched.UlaznaKalkulacija && formik.errors.UlaznaKalkulacija && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.UlaznaKalkulacija}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Godina Proizvodnje  */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label fw-bold fs-6'>Godina Proizvodnje</label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='number'
                                            placeholder='2023'
                                            {...formik.getFieldProps('GodinaProizvodnje')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.GodinaProizvodnje && formik.errors.GodinaProizvodnje,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.GodinaProizvodnje && !formik.errors.GodinaProizvodnje,
                                                }
                                            )}
                                        />
                                        {formik.touched.GodinaProizvodnje && formik.errors.GodinaProizvodnje && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.GodinaProizvodnje}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Godina Uvoza  */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label fw-bold fs-6'>Godina Uvoza</label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='number'
                                            placeholder='2023'
                                            {...formik.getFieldProps('GodinaUvoza')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid': formik.touched.GodinaUvoza && formik.errors.GodinaUvoza,
                                                },
                                                {
                                                    'is-valid': formik.touched.GodinaUvoza && !formik.errors.GodinaUvoza,
                                                }
                                            )}
                                        />
                                        {formik.touched.GodinaUvoza && formik.errors.GodinaUvoza && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.GodinaUvoza}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Proizvodjac */}
                                <div className='col-6 mb-6'>
                                    <div className='col'>
                                        <label className='col-auto col-form-label fw-bold fs-6'>Proizvodjač</label>
                                        <div className='col-auto fv-row'>
                                            <ProizvodjacPartnerDropdown
                                                value={formik.values.Proizvodjac}
                                                onChange={(name, value) => formik.setFieldValue('Proizvodjac', value)}
                                                errors={formik.errors.Proizvodjac}
                                                touched={formik.touched.Proizvodjac}
                                                selectName='Proizvodjac'
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Dobavljac */}
                                <div className='col-6 mb-6'>
                                    <div className='col'>
                                        <label className='col-auto col-form-label fw-bold fs-6'>Dobavljač</label>
                                        <div className='col-auto fv-row'>
                                            <DobavljacPartnerDropdown
                                                value={formik.values.Dobavljac}
                                                onChange={(name, value) => formik.setFieldValue('Dobavljac', value)}
                                                errors={formik.errors.Dobavljac}
                                                touched={formik.touched.Dobavljac}
                                                selectName='Dobavljac'
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* ArtPaletaKodBoje */}
                                <div className='col-6 mb-6'>
                                    <div className='col'>
                                        <label className='col-auto col-form-label fw-bold fs-6'>Kod Boje</label>
                                        <div className='col-auto fv-row'>
                                            <KodBojeRepromaterijalaDropdown
                                                value={formik.values.ArtPaletaKodBoje}
                                                onChange={(name, value) => formik.setFieldValue('ArtPaletaKodBoje', value)}
                                                errors={formik.errors.ArtPaletaKodBoje}
                                                touched={formik.touched.ArtPaletaKodBoje}
                                                selectName='ArtPaletaKodBoje'
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* ArtPaletaUlazJm */}
                                <div className='col-6 mb-6'>
                                    <div className='col'>
                                        <label className='col-auto col-form-label required fw-bold fs-6'>
                                            Jedinica mjere
                                        </label>
                                        <div className='col-auto fv-row'>
                                            <JmRepromaterijalaDropdown
                                                value={formik.values.ArtPaletaUlazJm}
                                                onChange={(name, value) => formik.setFieldValue('ArtPaletaUlazJm', value)}
                                                errors={formik.errors.ArtPaletaUlazJm}
                                                touched={formik.touched.ArtPaletaUlazJm}
                                                selectName='ArtPaletaUlazJm'
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Paleta Lokacija */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>
                                        Lokacija Palete
                                    </label>

                                    <div className='col-auto fv-row'>
                                        <input
                                            type='text'
                                            placeholder='Lokacija Paleta'
                                            {...formik.getFieldProps('ArtPaletaLokacija')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.ArtPaletaLokacija && formik.errors.ArtPaletaLokacija,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.ArtPaletaLokacija && !formik.errors.ArtPaletaLokacija,
                                                }
                                            )}
                                        />
                                        {formik.touched.ArtPaletaLokacija && formik.errors.ArtPaletaLokacija && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.ArtPaletaLokacija}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Raspolozivost Status */}
                                <div className='col-6 mb-6'>
                                    <label className='col-auto col-form-label required fw-bold fs-6'>
                                        Raspoloživost Status
                                    </label>
                                    <select
                                        {...formik.getFieldProps('ArtPaletaStatus')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {
                                                'is-invalid':
                                                    formik.touched.ArtPaletaStatus && formik.errors.ArtPaletaStatus,
                                            },
                                            {
                                                'is-valid':
                                                    formik.touched.ArtPaletaStatus && !formik.errors.ArtPaletaStatus,
                                            }
                                        )}
                                    >
                                        <option value='0'>Izaberi status</option>
                                        <option value='1'>Raspoloživ</option>
                                        <option value='2'>Upozorenje</option>
                                        <option value='3'>Višak / Otpis</option>
                                        <option value='4'>Carinsko skl.</option>
                                    </select>
                                    {formik.touched.ArtPaletaStatus && formik.errors.ArtPaletaStatus && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.ArtPaletaStatus}</div>
                                        </div>
                                    )}
                                </div>

                                {/* Napomena */}
                                <div className='col mb-6'>
                                    <label className='col-auto col-form-label fw-bold fs-6'>Napomena</label>

                                    <div className='col-auto fv-row'>
                    <textarea
                        placeholder='Napomena'
                        {...formik.getFieldProps('ArtPaletaNapomena')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid':
                                    formik.touched.ArtPaletaNapomena && formik.errors.ArtPaletaNapomena,
                            },
                            {
                                'is-valid':
                                    formik.touched.ArtPaletaNapomena && !formik.errors.ArtPaletaNapomena,
                            }
                        )}
                    />
                                        {formik.touched.ArtPaletaNapomena && formik.errors.ArtPaletaNapomena && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.ArtPaletaNapomena}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={loading || formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && 'Potvrdi'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
            <div className='col-6'>
                <PaleteUlaziTableComponent
                    refreshTable={refreshTable}
                    setRefreshTable={() => setRefreshTable(false)}
                />
            </div>
        </div>
    )
}

export default PaleteUlaziDetails
