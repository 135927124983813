export interface PaketLezarina {
  id: string
  idProizvodnje?: string
  brojNaloga?: string
  stavka?: string
  brojPonude?: string
  paket?: string
  lezaoDana?: string
  kvadratura?: string
  cijena?: string
  pdv?: string
  totalCijena?: number
  kupacID?: string
  kupac?: string
  zavrsenDatum?: string
  datum?: string
  korisnik?: string
  isporucenDatum?: string
}
export interface Option {
  value: string
  label: string
}

export interface ParamsModelArhiviranje {
  per_page: number
  page: number
}

export const splitDataForPages = (data: PaketLezarina[], itemsPerPage: number): PaketLezarina[][] => {
  let pages: PaketLezarina[][] = [];
  for (let i = 0; i < data.length; i += itemsPerPage) {
    pages.push(data.slice(i, i + itemsPerPage));
  }
  return pages;
};
