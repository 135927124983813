import React, {useState} from 'react'
import {KTSVG} from '../../../_websoft/helpers'
import UposlenikDropdown from '../UposlenikDropdown/UposlenikDropdown'
import SredstvaDropdown from '../SredstvaDropdown/SredstvaDropdown'
import {FilteriIntervencijeOdrzavanja} from '../../models/IntervencijeOdrzavanja'

const IntervencijeFilteri = ({onFilter}) => {
  const [filters, setfilters] = useState<FilteriIntervencijeOdrzavanja>({})
  return (
    <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
      <div className='mb-5'>
        <div
          className='accordion-header py-3 d-flex collapsed'
          data-bs-toggle='collapse'
          data-bs-target='#kt_accordion_2_item_2'
        >
          <span className='accordion-icon'>
            <KTSVG className='svg-icon svg-icon-4' path='/media/icons/duotune/arrows/arr064.svg' />
          </span>
          <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>FILTERI</h3>
        </div>
        <div
          id='kt_accordion_2_item_2'
          className='collapse fs-6 ps-10'
          data-bs-parent='#kt_accordion_2'
        >
          <div className='d-flex justify-content-between py-5'>
            <div className='col-2 fv-row'>
              <input
                type='date'
                className='form-control'
                name='datumOd'
                placeholder='Datum Od'
                onChange={(e) => setfilters({...filters, datumOd: e.target.value})}
              />
            </div>
            <div className='col-2 fv-row'>
              <input
                type='date'
                className='form-control'
                name='datumDo'
                placeholder='Datum Do'
                onChange={(e) => setfilters({...filters, datumDo: e.target.value})}
              />
            </div>
            <div className='col-3 fv-row'>
              <UposlenikDropdown
                onChange={(name, value) => setfilters({...filters, uposlenik: value})}
              />
            </div>
            <div className='col-3 fv-row'>
              <SredstvaDropdown
                onChange={(name, value) => setfilters({...filters, sredstvo: value})}
              />
            </div>

            <button type='button' className='btn btn-primary' onClick={() => onFilter(filters)}>
              Potvrdi
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntervencijeFilteri
