import React, {useEffect, useState} from 'react'
import TablePagination from '../../components/TablePagination/TablePagination'
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    useReactTable,
} from '@tanstack/react-table'
import * as Yup from 'yup'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import clsx from 'clsx'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import moments from 'moment'
import {useNavigate, useParams} from 'react-router-dom'
import {PaginationModel} from '../../models/PaginationModel'
import {ParamsModelZahtjevZaNabavu, ZahtjevZaNabavu} from '../../models/ZahtevZaNabavu'
import {
    fetchZahtjevZaNabavu,
    getAllZahtjevZaNabavu,
    saveZahtjevZaNabavu,
} from '../../api/zahtjevZaNabavu'
import notifyToast from '../../components/Toast/Toast'
import {useFormik} from 'formik'
import useDebounce from '../../hooks/useDebounce'
import {getSredstvoInfo, getSredstvoIzPrijaveInfo} from '../../api/prijavaKvara'
import JmRepromaterijalaDropdown from '../../components/JmRepromaterijalaDropdown/JmRepromaterijalaDropdown'

interface NabavuDetailsModel {
    Naziv?: string
    Razlog?: string
    SredstvoINVbroj?: string
    JedMjere?: string
    Kolicina?: string
    IdPrijavaKvara?: string
    Urgentno?: string
}

const ZahtjevZaNabavuDetails = () => {
    const [loading, setLoading] = useState(false)
    const {id} = useParams()
    const idFromURL = Number(id)

    const navigate = useNavigate()
    const initialValues = {
        Naziv: undefined,
        Razlog: undefined,
        SredstvoINVbroj: undefined,
        JedMjere: undefined,
        Kolicina: undefined,
        IdPrijavaKvara: undefined,
        Urgentno: '1',
    }
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })
    const [message, setmessage] = useState<string>()
    const [prijavaMessage, setPrijavaMessage] = useState<string>()
    const [inventurniBroj, setInventurniBroj] = useState<string>()

    const [initialData, setInitialData] = useState<NabavuDetailsModel>(initialValues)
    const [params, setparams] = useState<ParamsModelZahtjevZaNabavu>({
        per_page: 10,
        page: 1,
        active: 1,
    })

    useEffect(() => {
        if (idFromURL) {
            fetchZahtjevZaNabavu(idFromURL).then((data) => {
                setInitialData(data)
                formik.resetForm({values: data}) // <-- reset formik state here
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [idFromURL])
    const updatePage = () => {
        setLoading(true)
        getAllZahtjevZaNabavu(params)
            .then((response) => {
                const data = response.data['data']
                settableData(data)
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        updatePage()
    }, [params])

    const validationSchema = Yup.object({
        Naziv: Yup.string().required('Required'),
        Razlog: Yup.string().required('Required'),
        JedMjere: Yup.string().required('Required'),
        Kolicina: Yup.number().required('Required'),
        Urgentno: Yup.string().required('Required'),
    })

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)

            try {
                let response

                response = await saveZahtjevZaNabavu(values)
               /* setTimeout(() => {
                    navigate(-1)
                }, 3000)*/

                if (response.error === 0) {
                    notifyToast(true, response.message)
                } else {
                    notifyToast(false, response.message || 'An unexpected error occurred.')
                }
            } catch (error: any) {
                console.error('Error: ', error)
                notifyToast(false, error.response.data.message)
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        },
    })
    const prijavaKvaraId = useDebounce(formik.values.IdPrijavaKvara)
    useEffect(() => {
        if (prijavaKvaraId) {
            const params = {prijavaId: prijavaKvaraId}
            getSredstvoIzPrijaveInfo(params).then((response: any) => {
                if (response['PrijavaOpisKvara']) {
                    setPrijavaMessage(response['PrijavaOpisKvara'])
                    setInventurniBroj(response['sredstvo']['SredstvoInvBroj']);
                    setmessage(response['sredstvo']['SredstvoNaziv']);
                } else {
                    setmessage(undefined)
                }
            })
        }
    }, [prijavaKvaraId])

    const handleInputChange = (e) => {
        const { value } = e.target;
        setInventurniBroj(value);
        formik.setFieldValue('SredstvoINVbroj', value);
    };

    const invBroj = useDebounce(formik.values.SredstvoINVbroj)
    useEffect(() => {
        if (invBroj) {
            const params = {invBroj: invBroj}
            getSredstvoInfo(params).then((response: any) => {
                if (response['SredstvoNaziv']) {
                    setmessage(response['SredstvoNaziv'])
                } else {
                    setmessage(undefined)
                }
            })
        }
    }, [invBroj])

    const [tableData, settableData] = useState<ZahtjevZaNabavu[]>([])
    const columns = [
        {
            id: 'Id',
            header: 'Id',
            accessorKey: 'Id',
        },
        {
            id: 'Naziv',
            header: 'Naziv',
            accessorKey: 'Naziv',
        },
        {
            id: 'Prijava kvara #',
            header: "Prijava kvara #'",
            accessorKey: 'IdPrijavaKvara',
        },
        {
            id: 'Sredstvo',
            header: 'Sredstvo',
            accessorKey: 'sredstvo.SredstvoNaziv',
        },
        {
            id: 'Datum',
            header: 'Datum',
            accessorFn: (row) => `${moments(row.KreiraoDatum).format('DD-MM-YYYY h:mm:ss')}`,
        },
        {
            id: 'Kreirao',
            header: 'Kreirao',
            accessorKey: 'uposlenik.UposlenikImePrezime',
        },
        {
            header: 'Urgentno',
            accessorKey: 'Urgentno',
            cell: ({row}) =>
                row.original.Urgentno === 1 ? (
                    <span className='badge-sm badge-success p-3'>DA</span>
                ) : (
                    <span className='badge-sm badge-danger p-3'>NE</span>
                ),
        },
        {
            header: 'Status',
            accessorKey: 'Status',
            cell: ({row}) => (
                <span className='badge-sm p-3' style={{backgroundColor: row.original.status.Boja}}>
          {row.original.status.Naziv}
        </span>
            ),
        },
    ]
    const table = useReactTable({
        data: tableData,
        columns,
        getCoreRowModel: getCoreRowModel(),

        getFilteredRowModel: getFilteredRowModel(),
    })

    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer/>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name='Zahtjev Za Nabavu'/>
                </div>
            </div>
            <div className='row'>
                <div id='kt_account_profile_details' className='collapse show col-lg-6'>
                    {loading ? (
                        <LoadingComponent/>
                    ) : (
                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                            <div className='card-body border-top p-9'>
                                {/* Naziv */}
                                <div className='col-12 mb-6'>
                                    <label className='required fw-bold fs-6'>
                                        Naziv Proizvoda / Usluge
                                    </label>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            placeholder='Naziv proizvoda'
                                            {...formik.getFieldProps('Naziv')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid': formik.touched.Naziv && formik.errors.Naziv,
                                                },
                                                {
                                                    'is-valid': formik.touched.Naziv && !formik.errors.Naziv,
                                                }
                                            )}
                                        />
                                        {formik.touched.Naziv && formik.errors.Naziv && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.Naziv}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* IdPrijavaKvara */}
                                <div className='row'>
                                    <div className='col-6 mb-6'>
                                        <label className='col col-form-label required fw-bold fs-6'>
                                            ID Prijave Kvara (veži zahtjev za prijavu kvara)
                                        </label>
                                        <input
                                            type='number'
                                            placeholder='Id Prijava Kvara'
                                            {...formik.getFieldProps('IdPrijavaKvara')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid':
                                                        formik.touched.IdPrijavaKvara && formik.errors.IdPrijavaKvara,
                                                },
                                                {
                                                    'is-valid':
                                                        formik.touched.IdPrijavaKvara && !formik.errors.IdPrijavaKvara,
                                                }
                                            )}
                                        />
                                        {formik.touched.IdPrijavaKvara && formik.errors.IdPrijavaKvara && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.IdPrijavaKvara}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className='col-6 align-self-center border border-success rounded p-2'
                                        hidden={!prijavaMessage}
                                    >
                                        {prijavaMessage}
                                    </div>
                                </div>
                                {/* Sredstvo INV broj */}
                                <div className='row'>
                                    <div className='col-6 mb-6'>
                                        <label className='col col-form-label required fw-bold fs-6'>
                                            Inventurni Broj Sredstva
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                type='text'
                                                placeholder='Inventurni broj'
                                                value={inventurniBroj}
                                                onChange={handleInputChange}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {
                                                        'is-invalid': formik.touched.SredstvoINVbroj && formik.errors.SredstvoINVbroj,
                                                    },
                                                    {
                                                        'is-valid': formik.touched.SredstvoINVbroj && !formik.errors.SredstvoINVbroj,
                                                    }
                                                )}
                                            />
                                            {formik.touched.SredstvoINVbroj && formik.errors.SredstvoINVbroj && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.SredstvoINVbroj}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className='col-6 align-self-center border border-success rounded p-2'
                                        hidden={!message}
                                    >
                                        {message}
                                    </div>
                                </div>

                                {/* JM  */}
                                <div className='col-lg-6 mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>JM</label>
                                    <JmRepromaterijalaDropdown
                                        value={formik.values.JedMjere}
                                        onChange={(name, value) => formik.setFieldValue('JedMjere', value)}
                                        errors={formik.errors.JedMjere}
                                        touched={formik.touched.JedMjere}
                                        selectName='JedMjere'
                                    />
                                </div>

                                {/* Količina */}
                                <div className='col-6 mb-6'>
                                    <label className='col col-form-label required fw-bold fs-6'>Količina</label>
                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='number'
                                            placeholder='Kolicina'
                                            {...formik.getFieldProps('Kolicina')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                {
                                                    'is-invalid': formik.touched.Kolicina && formik.errors.Kolicina,
                                                },
                                                {
                                                    'is-valid': formik.touched.Kolicina && !formik.errors.Kolicina,
                                                }
                                            )}
                                        />
                                        {formik.touched.Kolicina && formik.errors.Kolicina && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.Kolicina}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='col mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                        Razlog Zahtjeva Za Nabavu
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                    <textarea
                        placeholder='Razlog'
                        {...formik.getFieldProps('Razlog')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.Razlog && formik.errors.Razlog},
                            {
                                'is-valid': formik.touched.Razlog && !formik.errors.Razlog,
                            }
                        )}
                    />
                                        {formik.touched.Razlog && formik.errors.Razlog && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.Razlog}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Urgentni zahtjev */}
                                <div className='row py-5 border border-1 rounded'>
                                    <label className='fw-bold fs-6 p-4 required'>Urgentni zahtjev</label>
                                    <div className='col-4'>
                                        <input
                                            id='Da'
                                            type='radio'
                                            value='1'
                                            name='urgentna'
                                            onChange={(e) => formik.setFieldValue('Urgentno', e.target.value)}
                                            // eslint-disable-next-line eqeqeq
                                            checked={formik.values.Urgentno == '1'}
                                        />
                                        <label className='custom-control-label px-5' htmlFor='Da'>
                                            Da
                                        </label>
                                    </div>
                                    <div className='col-4'>
                                        <input
                                            id='Ne'
                                            type='radio'
                                            value='0'
                                            name='urgentna'
                                            onChange={(e) => formik.setFieldValue('Urgentno', e.target.value)}
                                            // eslint-disable-next-line eqeqeq
                                            checked={formik.values.Urgentno == '0'}
                                        />
                                        <label className='custom-control-label px-5' htmlFor='Ne'>
                                            Ne
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={loading || formik.isSubmitting || !formik.isValid || !message}
                                >
                                    {!loading && 'Potvrdi'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
                <div className='col-lg-6'>
                    {loading ? (
                        <LoadingComponent/>
                    ) : (
                        <div className='table-responsive mt-3 '>
                            <table className='table align-middle table-bordered gy-5 no-footer'>
                                <thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr
                                        key={headerGroup.id}
                                        className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                    >
                                        {headerGroup.headers.map((header) => (
                                            <th
                                                className='text-white'
                                                key={header.id}
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {/* @ts-ignore */}
                                                {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>
                                <tbody className='text-gray-600'>
                                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                    table.getRowModel().rows.map((row) => (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map((cell) => (
                                                <td key={cell.id}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={7}>
                                            <div
                                                className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                No matching records found
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            <TablePagination
                                updatePage={(page, per_page) =>
                                    setparams({...params, page: page, per_page: per_page})
                                }
                                pagination={pagination}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ZahtjevZaNabavuDetails
