import React, {useMemo} from 'react'
import {Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer'
import {AktivnePaleteModel} from '../../models/AktivnePaleteModel'
import logo from '../../../_websoft/assets/images/logo.jpg'
// Register font
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
})
// Create styles
const styles = StyleSheet.create({
  pdf: {width: '100vh', height: '300px'},
  page: {
    flexDirection: 'column',
    flex: 1,
    height: '300px',
    padding: 30,
  },
  section: {
    margin: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  section2: {
    marginTop: 20,
    margin: 10,
    padding: 10,
  },
  qrCode: {
    margin: 10,
    padding: 10,
    width: '200px',
  },
  logo: {
    margin: 10,
    padding: 10,
    width: '400px',
  },
  subtitle: {
    fontSize: 36,
    marginTop: 30,
    marginBottom: 12,
    marginHorizontal: 20,
    textAlign: 'center',
    borderWidth: '1px',
    borderStyle: 'solid',
    padding: 10,
  },
  text: {
    marginVertical: 5,
    marginHorizontal: 12,
    fontSize: 20,
    textAlign: 'left',
    fontFamily: 'Roboto',
  },
  paletetext: {
    marginVertical: 5,
    marginHorizontal: 12,
    fontSize: 48,
    fontFamily: 'Roboto',
    alignContent: 'center',
  },
  paleteView: {
    flex: 1,
    textAlign: 'center',
  },
})
interface Props {
  qrData: AktivnePaleteModel
  qrUrl: string
}
const AktivnePaletaPdf: React.FC<Props> = ({qrData, qrUrl}) => {
  const data = useMemo(() => ({qrData}), [qrData])
  return (
    <Document>
      <Page size='A4' style={styles.page} orientation='portrait'>
        <Image src={logo} style={styles.logo} />
        <Text style={styles.subtitle}>KARTICA ARTIKLA</Text>
        <View style={styles.section}>
          {/* @ts-ignore */}
          <Image style={styles.qrCode} source={{uri: qrUrl}} />
          <View style={styles.paleteView}>
            <Text style={styles.paletetext}>PALETA BR</Text>
            <Text style={styles.paletetext}>{data.qrData.ArtPaletaId}</Text>
          </View>
        </View>
        <View style={styles.section2}>
          <Text style={styles.text}>Šifra: {data.qrData.artikal.ArtSifra}</Text>
          <Text style={styles.text}>Artikal: {data.qrData.artikal.ArtNaziv}</Text>
          {data.qrData.Dobavljac && (
              <>
                <Text style={styles.text}>Dobavljač: {data.qrData.dobavljac?.PartnerNaziv}</Text>
                <Text style={styles.text}>Godina Uvoza: {data.qrData.GodinaUvoza}</Text>
              </>
          )}
          {data.qrData.Proizvodjac && (
              <>
                <Text style={styles.text}>Proizvođač: {data.qrData.proizvodjac.PartnerNaziv}</Text>
                <Text style={styles.text}>Godina Proizvodnje: {data.qrData.GodinaProizvodnje}</Text>
              </>
          )}
          <Text style={styles.text}>Ulazna kalkulacija: {data.qrData.UlaznaKalkulacija}</Text>
          <Text style={styles.paletetext}>Stanje: {data.qrData.ArtPaletaTrenutnoStanje}</Text>
        </View>
      </Page>
    </Document>
  )
}

export default AktivnePaletaPdf
