import {useFormik} from 'formik'
import React, {useState} from 'react'
import {saveZapisiStavke} from '../../../../api/zapisiProizvodnje'
import notifyToast from '../../../../components/Toast/Toast'
import LoadingComponent from '../../../../components/LoadingComponent/LoadingComponent'
import UposlenikDropdown from "../../../../components/UposlenikDropdown/UposlenikDropdown";

const Zaga = ({id, brojNaloga}) => {
  const initialValues = {
    kolicinaNaloga: undefined,
    nalogZavrsen: undefined,
    'ZAMJENA-ALATA': undefined,
    'POCETNI-BROJ-REZOVA-ZAGE': undefined,
    'KRAJNJI-BROJ-REZOVA-ZAGE': undefined,
    'ZAGA-PUKLA': undefined,
    'LIM-IMA-VIDLJIVE-NEDOSTATKE-NA-BOJI-GORNJI-LIM-ZAGA': undefined,
    'LIM-IMA-VIDLJIVE-NEDOSTATKE-NA-BOJI-DONJI-LIM-ZAGA': undefined,
    'VIDLJIVI-NEDOSTATCI-LJEPILO-BRTVENA-TRAKA-ZAGA': undefined,
    'UKLOPILI-PANELE-ZAGA': undefined,
    'POTREBNA-KOREKCIJA-NA-PANELU-ZAGA': undefined,
    'SIRINA-PJENE-ZAGA': undefined,
    'DEBLJINA-PJENE-ZAGA': undefined,
    'GORNJA-TABLA-ZAGA': undefined,
    'DONJA-TABLA-ZAGA': undefined,
    'POZICIJA-ZASTITNE-FOLIJE-UREDU-ZAGA': undefined,
    'UZORAK-ZA-KONTROLU-UZET-NA-DUZNOM-METRU':undefined,
    'NAREDIO-UZIMANJE-UZORKA':undefined
  }
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: validationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let response
        let data: any = []
        // Format data as per required format of api.
        Object.entries(values).map(([key, value]) => {
          if (value) {
            const dataSet = {
              IdZapisa: id,
              BrojNaloga: brojNaloga,
              MetaKey: key,
              MetaValue: value,
            }
            data.push(dataSet)
          }
        })
        response = await saveZapisiStavke(data)

        const responseData = response

        if (responseData.error === 0) {
          notifyToast(true, responseData.message)
        } else {
          notifyToast(false, responseData.message || 'An unexpected error occurred.')
        }
      } catch (error: any) {
        console.error('Error: ', error.response.data.message)
        notifyToast(false, error.response.data.message)
        setStatus(error)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div>
      {loading ? (
        <LoadingComponent />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div id='zaga'>
            <div className='row mt-4'>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'>
                  {' '}
                  Urađena količina | Upisuje se na kraju smjene
                </label>
                <input
                  type='text'
                  className='form-control text-danger'
                  placeholder='Upišite urađenu količinu'
                  {...formik.getFieldProps('kolicinaNaloga')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Nalog završen</label>
                <select className='form-control' {...formik.getFieldProps('nalogZavrsen')}>
                  <option value=''>Odaberi</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Zamjena alata završen</label>
                <select className='form-control' {...formik.getFieldProps('ZAMJENA-ALATA')}>
                  <option value=''>Odaberi</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
            </div>
            {/* ZAGA  */}
            <div className='row mt-3'>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Početni broj rezova</label>
                <input
                  type='text'
                  className='form-control text-danger'
                  placeholder='Upišite početni broj rezova'
                  {...formik.getFieldProps('POCETNI-BROJ-REZOVA-ZAGE')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> KRAJNJI broj rezova</label>
                <input
                  type='text'
                  className='form-control text-danger'
                  placeholder='Upišite krajnji broj rezova'
                  {...formik.getFieldProps('KRAJNJI-BROJ-REZOVA-ZAGE')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> ZAGA PUKLA</label>
                <select className='form-control' {...formik.getFieldProps('ZAGA-PUKLA')}>
                  <option value=''>Odaberi</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'>
                  Lim ima vidljive nedostatke na boji GORNJI LIM
                </label>
                <select
                  className='form-control'
                  {...formik.getFieldProps('LIM-IMA-VIDLJIVE-NEDOSTATKE-NA-BOJI-GORNJI-LIM-ZAGA')}
                >
                  <option value=''>ODABERI</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'>
                  Lim ima vidljive nedostatke na boji DONJI LIM
                </label>
                <select
                  className='form-control'
                  {...formik.getFieldProps('LIM-IMA-VIDLJIVE-NEDOSTATKE-NA-BOJI-DONJI-LIM-ZAGA')}
                >
                  <option value=''>ODABERI</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
              {/* ZAGA  */}
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'>
                  Vidljivi nedostatci ljepilo/brtvena traka
                </label>
                <select
                  className='form-control'
                  {...formik.getFieldProps('VIDLJIVI-NEDOSTATCI-LJEPILO-BRTVENA-TRAKA-ZAGA')}
                >
                  <option value=''>ODABERI</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> UKLOPILI-PANELE</label>
                <select className='form-control' {...formik.getFieldProps('UKLOPILI-PANELE-ZAGA')}>
                  <option value=''>Odaberi</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> POTREBNA KOREKCIJA NA PANELU</label>
                <select
                  className='form-control'
                  {...formik.getFieldProps('POTREBNA-KOREKCIJA-NA-PANELU-ZAGA')}
                >
                  <option value=''>Odaberi</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> Širina pjene (žaga) </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Širina pjene (zaga)'
                  {...formik.getFieldProps('SIRINA-PJENE-ZAGA')}
                />
              </div>
              <div className='col-lg-6'>
                <label className='col-form-label fw-bold fs-6'> Debljina pjene (žaga) </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Debljina pjene (zaga)'
                  {...formik.getFieldProps('DEBLJINA-PJENE-ZAGA')}
                />
              </div>
            </div>
            <div className='row mt-3 mb-5'>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Gornja tabla [mm] </label>
                <input
                    type='text'
                    className='form-control'
                    placeholder='Gornja tabla'
                    {...formik.getFieldProps('GORNJA-TABLA-ZAGA')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Donja tabla [mm] </label>
                <input
                    type='text'
                    className='form-control'
                    placeholder=' Donja tabla'
                    {...formik.getFieldProps('DONJA-TABLA-ZAGA')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'>
                  {' '}
                  Pozicija zaštite folije uredu{' '}
                </label>
                <select
                    className='form-control'
                    {...formik.getFieldProps('POZICIJA-ZASTITNE-FOLIJE-UREDU-ZAGA')}
                >
                  <option value=''>Odaberi</option>
                  <option value='DA'>DA</option>
                  <option value='NE'>NE</option>
                </select>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Uzorak uzet na dužnom metru </label>
                <input
                    type='text'
                    className='form-control'
                    placeholder='Uzorak uzet na dužnom metru'
                    {...formik.getFieldProps('UZORAK-ZA-KONTROLU-UZET-NA-DUZNOM-METRU')}
                />
              </div>
              <div className='col-lg-4'>
                <label className='col-form-label fw-bold fs-6'> Uposlenik zahtjeva uzorak </label>
                <UposlenikDropdown
                    onChange={(name, value) => formik.setFieldValue('NAREDIO-UZIMANJE-UZORKA', value)}
                    selectName='Uposlenik'
                />
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-lg-3 offset-9'>
              <button type='submit' className='btn btn-block btn-primary'>
                Potvrdi
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

export default Zaga
