import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {ParamsModelPrijavaKvara, PrijavaKvaraModel} from '../../models/PrijavaKvara'
import {
    getAllPrijavaKvara,
    getSredstvoInfo,
    savePrijavaKvara,
    updatePrijavaKvara,
} from '../../api/prijavaKvara'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import notifyToast from '../../components/Toast/Toast'
import {ToastContainer} from 'react-toastify'
import TitleComponent from '../../components/TitleComponent/TitleComponent'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import clsx from 'clsx'
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    useReactTable,
} from '@tanstack/react-table'
import moments from 'moment'
import TablePagination from '../../components/TablePagination/TablePagination'
import {PaginationModel} from '../../models/PaginationModel'
import useDebounce from '../../hooks/useDebounce'

interface KvaraDetailsModel {
    invBroj?: string
    opisKvara?: string
    urgentnaPrijava?: string
}

const PrijavaKvaraDetails = () => {
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const initialValues = {
        invBroj: undefined,
        opisKvara: undefined,
        urgentnaPrijava: undefined,
    }
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })
    const [message, setmessage] = useState<string>()
    const [initialData, setInitialData] = useState<KvaraDetailsModel>(initialValues)
    const [params, setparams] = useState<ParamsModelPrijavaKvara>({per_page: 10, page: 1, active: 1})

    const updatePage = () => {
        setLoading(true)
        getAllPrijavaKvara(params)
            .then((response) => {
                const data = response.data['data']
                settableData(data)
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        updatePage()
    }, [params])

    const validationSchema = Yup.object({
        invBroj: Yup.string().required('Required'),
        opisKvara: Yup.string().required('Required'),
        urgentnaPrijava: Yup.string().required('Required'),
    })

    const formik = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)

            try {
                let response

                response = await savePrijavaKvara(values)
               setTimeout(() => {
                    navigate(-1)
                }, 3000)

                if (response.error === 0) {
                    notifyToast(true, response.message)
                } else {
                    notifyToast(false, response.message || 'An unexpected error occurred.')
                }
            } catch (error: any) {
                console.error('Error: ', error)
                notifyToast(false, error.response.data.message)
                setStatus(error)
            } finally {
                setLoading(false)
                setSubmitting(false)
            }
        },
    })

    const invBroj = useDebounce(formik.values.invBroj)
    useEffect(() => {
        if (invBroj) {
            const params = {invBroj: invBroj}
            getSredstvoInfo(params).then((response: any) => {
                if (response['SredstvoNaziv']) {
                    setmessage(response['SredstvoNaziv'])
                } else {
                    setmessage(undefined)
                }
            })
        }
    }, [invBroj])

    const [tableData, settableData] = useState<PrijavaKvaraModel[]>([])
    const columns = [
        {
            id: 'Id',
            header: 'Id',
            accessorKey: 'PrijavaId',
        },
        {
            id: 'Datum',
            header: 'Datum',
            accessorFn: (row) => `${moments(row.PrijavaDatum).format('DD-MM-YYYY')}`,
        },
        {
            id: 'Inv broj.',
            header: 'Inv broj.',
            accessorKey: 'PrijavaInvBroj',
        },
        {
            id: 'Sredstvo naziv',
            header: 'Sredstvo naziv',
            accessorKey: 'sredstvo.SredstvoNaziv',
        },
        {
            id: 'Opis kvara',
            header: 'Opis kvara',
            accessorKey: 'PrijavaOpisKvara',
        },
        {
            id: 'Kreirao prijavu',
            header: 'Kreirao prijavu',
            accessorKey: 'uposlenik.UposlenikImePrezime',
        },
        {
            id: 'Datum / Vrijeme',
            header: 'Datum / Vrijeme',
            accessorFn: (row) => `${moments(row.PrijavaDatum).format('DD-MM-YYYY  h:mm:ss')}`,
        },
        {
            id: 'Status',
            header: 'Status',
            accessorKey: 'Status',
            cell: ({row}: { row: any }) => (
                <div
                    style={{
                        backgroundColor: row.original.status.Boja,
                        padding: '15px',
                    }}
                >
                    {row.original.status.Naziv}
                </div>
            ),
        },
    ]
    const table = useReactTable({
        data: tableData,
        columns,
        getCoreRowModel: getCoreRowModel(),

        getFilteredRowModel: getFilteredRowModel(),
    })

    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer/>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <TitleComponent name='Prijavu Kvara'/>
                </div>
            </div>
            <div className='row'>
                <div id='kt_account_profile_details' className='collapse show col-lg-6'>
                    {loading ? (
                        <LoadingComponent/>
                    ) : (
                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                            <div className='card-body border-top p-9'>
                                <div className='row'>
                                    <div className='col-6 mb-6'>
                                        <label className='col col-form-label required fw-bold fs-6'>
                                            Inventurni Broj Sredstva
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                type='text'
                                                placeholder='Inventurni broj '
                                                {...formik.getFieldProps('invBroj')}
                                                className={clsx(
                                                    'form-control form-control-lg form-control-solid',
                                                    {'is-invalid': formik.touched.invBroj && formik.errors.invBroj},
                                                    {
                                                        'is-valid': formik.touched.invBroj && !formik.errors.invBroj,
                                                    }
                                                )}
                                            />
                                            {formik.touched.invBroj && formik.errors.invBroj && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.invBroj}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className='col-6 align-self-center border border-success rounded p-2'
                                        hidden={!message}
                                    >
                                        {message}
                                    </div>
                                </div>

                                <div className='col mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                        Opis Prijave Kvara
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                    <textarea
                        placeholder='opis Kvara'
                        {...formik.getFieldProps('opisKvara')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.opisKvara && formik.errors.opisKvara},
                            {
                                'is-valid': formik.touched.opisKvara && !formik.errors.opisKvara,
                            }
                        )}
                    />
                                        {formik.touched.opisKvara && formik.errors.opisKvara && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.opisKvara}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* urgentna Prijava */}
                                <div className='row py-5 border border-1 rounded'>
                                    <label className='fw-bold fs-6 p-4 required'>Urgentna Prijava</label>
                                    <div className='col-4'>
                                        <input
                                            id='Da'
                                            type='radio'
                                            value='1'
                                            name='urgentna'
                                            onChange={(e) => formik.setFieldValue('urgentnaPrijava', e.target.value)}
                                            defaultChecked={formik.values.urgentnaPrijava === '1'}
                                        />
                                        <label className='custom-control-label px-5' htmlFor='Da'>
                                            Da
                                        </label>
                                    </div>
                                    <div className='col-4'>
                                        <input
                                            id='Ne'
                                            type='radio'
                                            value='0'
                                            name='urgentna'
                                            onChange={(e) => formik.setFieldValue('urgentnaPrijava', e.target.value)}
                                            defaultChecked={formik.values.urgentnaPrijava === '0'}
                                        />
                                        <label className='custom-control-label px-5' htmlFor='Ne'>
                                            Ne
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={loading || formik.isSubmitting || !formik.isValid || !message}
                                >
                                    {!loading && 'Potvrdi'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
                <div className='col-lg-6'>
                    {loading ? (
                        <LoadingComponent/>
                    ) : (
                        <div className='table-responsive mt-3 '>
                            <table className='table align-middle table-bordered gy-5 no-footer'>
                                <thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr
                                        key={headerGroup.id}
                                        className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                    >
                                        {headerGroup.headers.map((header) => (
                                            <th
                                                className='text-white'
                                                key={header.id}
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {/* @ts-ignore */}
                                                {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>
                                <tbody className='text-gray-600'>
                                {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                    table.getRowModel().rows.map((row) => (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map((cell) => (
                                                <td key={cell.id}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={7}>
                                            <div
                                                className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                No matching records found
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            <TablePagination
                                updatePage={(page, per_page) =>
                                    setparams({...params, page: page, per_page: per_page})
                                }
                                pagination={pagination}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PrijavaKvaraDetails
